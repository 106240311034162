import React, { memo } from 'react';
import ItemToSave from './ItemToSave';

const PackingSave = memo(({ items, changeQty, changeMoveType, type, removeItem }) => {
  if (!items) return null;
  let total_price = items.reduce((sum, item) => sum + parseInt(item.qty) * parseFloat(item.price), 0);

  return (
    <div className="new-table">
      <div className="new-table--row">
        <span className="q-view">Quick view</span>
      </div>
      <div className="new-table--row bcg-light">
        <div className="new-table--row--item justify-content-center w25">
          <b>Name</b>
        </div>
        <div className="new-table--row--item justify-content-center w15">
          <b>Price</b>
        </div>
        <div className="new-table--row--item justify-content-center w10">
          <b>Qty</b>
        </div>
        <div className="new-table--row--item justify-content-center w15">
          <b>Type</b>
        </div>
        <div className="new-table--row--item justify-content-center w15">
          <b>Date</b>
        </div>
        <div className="new-table--row--item justify-content-center w10">
          <b>Total</b>
        </div>
        <div className="new-table--row--item justify-content-center w10" />
      </div>
      {items?.map((item) => (
        <ItemToSave
          key={item.pack_id}
          item={item}
          changeMoveType={changeMoveType}
          changeQty={changeQty}
          removeItem={removeItem}
        />
      ))}
      <div className="new-table--row p-3">
        <span className="mx-1">Total Price: </span>
        <b>{total_price.toFixed(2)} </b>
      </div>
    </div>
  );
});

export default PackingSave;

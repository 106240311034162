import React from 'react';
import { useParams } from 'react-router';
import { useInfo } from 'hooks/query/useJob';
import LDServices from './LDServices';
import HouseholdGoods from './HouseholdGoods';

import './style.scss';

const LD = () => {
  const { id } = useParams();

  const {
    data: { job_info },
  } = useInfo(id);

  const isStorageJob = job_info?.type === 3;

  return (
    <div className="ld-container">
      {!isStorageJob && <LDServices />}

      <HouseholdGoods />
    </div>
  );
};

export default LD;

import React, { useState, useCallback, useMemo } from 'react';
import { useFormik } from 'formik';
import { useAuth } from 'context/auth';
import { useModal } from 'context/modal';
import { routePaths } from 'config/routes';
import Stepper from 'react-stepper-horizontal';
import Modal from 'components/newComponents/Modal';
import { useHistory, generatePath } from 'react-router-dom';
import { useQuickMovingQuote } from 'hooks/query/useCommon';
import AdditionalInfo from 'pages/modal/AdditionalInfo/Form';
import { ModalButton } from 'components/newComponents/Buttons';
import { MainInfo, BuildingInfo, ExtraStop, ExtraStopAdditional, Final } from './steps';
import validationSchema from './validationSchema';
import initialValues from './initialValues';
import { useSuggestFollowUpChain } from 'hooks/useSuggestFollowUpChain';

import './styles.scss';

const titles = [
  'Please select company you want',
  'Please enter your contact information',
  'Please enter your extra stop information',
  '',
  'Please enter your extra stop additional information',
  'Confirmation',
];

const FollowUp = () => {
  const { user } = useAuth();
  const history = useHistory();
  const [step, setStep] = useState(0);
  const [distanceList, setDistanceList] = useState([]);

  const { mutate: createJob } = useQuickMovingQuote();
  const { onMutate: suggestFollowUpChain } = useSuggestFollowUpChain(null, 'general', 'followUp');

  const isLastStep = useMemo(() => step === 5, [step]);

  const { modalNames, close, info } = useModal();

  const isVisible = info(modalNames.followUp).visible;

  const closeHandler = useCallback(() => close(modalNames.followUp), [close, modalNames.followUp]);

  const prevStepHandler = useCallback(
    (extraStops) => {
      if (!extraStops && [3, 5].includes(step)) {
        setStep((pre) => pre - 2);
        return;
      }

      setStep((pre) => pre - 1);
    },
    [step]
  );

  const nextStepHandler = useCallback(
    (extraStops) => {
      if (!extraStops && [1, 3].includes(step)) {
        setStep((pre) => pre + 2);
        return;
      }
      setStep((pre) => pre + 1);
    },
    [step]
  );

  const createJobHandler = useCallback(
    async (values, actions) => {
      if (!isLastStep) {
        nextStepHandler(values['extra_stop']);
        actions.setTouched({});
        actions.setSubmitting(false);
        return;
      }
      await createJob(values, {
        onSuccess: ({ data }) => {
          closeHandler();
          history.push(generatePath(routePaths.JOBS, { type: 'followUp', tab: 'general', id: data.job_id }));
          suggestFollowUpChain(data.job_id);
        },
      });
    },
    [closeHandler, createJob, history, isLastStep, nextStepHandler, suggestFollowUpChain]
  );

  const { values, errors, touched, handleSubmit, handleChange, setFieldValue } = useFormik({
    initialValues: { ...initialValues, agent_id: user?.id },
    validateOnMount: false,
    onSubmit: createJobHandler,
    validationSchema: validationSchema[step],
  });

  const isValidHandler = useCallback(
    (name) => {
      if (errors[name] && touched[name]) return errors[name];
      return null;
    },
    [errors, touched]
  );

  return (
    <Modal style={{ width: 700 }} header="Create job" show={isVisible} onHide={closeHandler}>
      <form onSubmit={handleSubmit}>
        <div className="bcg-light create-job-form">
          <Stepper lineMarginOffset={0} steps={titles} size={60} activeStep={step} barStyle="none" />
          <div className="create-job-form--title">{titles[step]}</div>
        </div>

        {step === 0 && (
          <MainInfo key="MainInfo" values={values} handleChange={handleChange} setFieldValue={setFieldValue} isValidHandler={isValidHandler} />
        )}
        {step === 1 && (
          <BuildingInfo
            key="BuildingInfo"
            values={values}
            handleChange={handleChange}
            setFieldValue={setFieldValue}
            isValidHandler={isValidHandler}
          />
        )}
        {step === 2 && (
          <ExtraStop
            key="ExtraStop"
            values={values}
            distanceList={distanceList}
            setDistanceList={setDistanceList}
            handleChange={handleChange}
            setFieldValue={setFieldValue}
            isValidHandler={isValidHandler}
          />
        )}
        {step === 3 && (
          <AdditionalInfo
            key="AdditionalInfo"
            edit={false}
            values={values}
            handleChange={handleChange}
            setFieldValue={setFieldValue}
            isValidHandler={isValidHandler}
          />
        )}
        {step === 4 && (
          <ExtraStopAdditional
            key="ExtraStopAdditional"
            values={values}
            handleChange={handleChange}
            setFieldValue={setFieldValue}
            isValidHandler={isValidHandler}
          />
        )}
        {step === 5 && (
          <Final
            key="Final"
            values={values}
            distanceList={distanceList}
            handleChange={handleChange}
            setFieldValue={setFieldValue}
            isValidHandler={isValidHandler}
          />
        )}
        <div className="footer-buttons">
          <ModalButton
            title={step > 0 ? 'Back' : 'Cancel'}
            variant="cancel"
            onClick={() => (step ? prevStepHandler(values['extra_stops'].length) : closeHandler())}
          />
          <ModalButton title={step === 5 ? 'Complete' : 'Next step'} variant="confirm" type="submit" />
        </div>
      </form>
    </Modal>
  );
};

export default FollowUp;

import * as Yup from 'yup';

export default Yup.object().shape({
  name: Yup.string().required('Enter name!'),
  phone: Yup.string()
    .test('len', 'Invalid phone number', (value) => {
      if (!value) return false;
      return !value.includes('_');
    })
    .required('Phone is required!'),
  carrier_id: Yup.number().required('Select carrier!'),
});

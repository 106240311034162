import React from 'react';
import { useModal } from 'context/modal';
import { formatFullDBDateTime, add } from 'helpers/dateHelper';
import { useForm } from '../../../hooks/useForm';
import { getCurrentMoveSize } from '../../../utils';

function CrewAvailability({ moveSizes }) {
  const { open, modalNames } = useModal();

  const { values } = useForm();

  const currentMoveSize = getCurrentMoveSize(moveSizes, values.move_size_id);

  if (!currentMoveSize) {
    return null;
  }

  return (
    <div className="new-table--row">
      <div className="new-table--row--item no-padding">
        <button
          type="button"
          className="main p20 flex-grow-1"
          onClick={() => {
            open(modalNames.dayAvailability, {
              date: values.pickup_date,
              minimum_hours: values.pickup_time,
              start_date: formatFullDBDateTime(`${values.pickup_date} ${values.pickup_time}`),
              stop_date: add(formatFullDBDateTime(`${values.pickup_date} ${values.pickup_time}`), currentMoveSize.minimum_hour, 'hours'),
            });
          }}
        >
          Check crew availability
        </button>
      </div>
    </div>
  );
}

export default CrewAvailability;

const nameCapitalizer = (name) => {
  if (!name) return '';

  const nameParts = name.split(' ').filter((n) => n);
  const capitalizedParts = nameParts.map((n) => n.charAt(0).toUpperCase() + n.slice(1));

  return capitalizedParts.join(' ');
};

export { nameCapitalizer };

import React, { memo } from 'react';
import Icon from 'components/Icon';

export const ButtonIconItem = memo(({ value, onClick, size, label, className }) => {
  return (
    <button type="button" className="all" onClick={() => onClick(value)}>
      <Icon icon={value?.toLowerCase() !== 'all' ? value?.toLowerCase() : null} title={value} size={size} className={className} />
    </button>
  );
});

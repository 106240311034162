import React from 'react';
import CheckBox from 'components/newComponents/CheckBox';

function Settings({ multiSelect, rangeSelect, onChangeMultiSelect, onChangeRangeSelect, onSave }) {
  return (
    <div className="settings">
      <div className="col-2 p-0 d-flex">
        <CheckBox
          name="multi"
          label="Multi select"
          checked={multiSelect}
          right={false}
          className="justify-content-between"
          onChange={onChangeMultiSelect}
        />

        <CheckBox
          name="range"
          label="Range select"
          checked={rangeSelect}
          right={false}
          className="justify-content-between ml-4"
          onChange={onChangeRangeSelect}
        />
      </div>

      <div className="col-6"></div>

      <div className="col-4 p-0 d-flex align-items-center">
        <button type="button" className="save-btn" onClick={onSave}>
          Save
        </button>
      </div>
    </div>
  );
}

export default Settings;

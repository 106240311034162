import React, { Fragment, useMemo, useCallback } from 'react';
import { components } from 'react-select';
import { useParams } from 'react-router-dom';
import { useInfo } from 'hooks/query/useJob';
import { useDefault } from 'hooks/query/useCommon';
import {
  useMoverRemove,
  useAssignCancel,
  useAssignConfirm,
  useAssignedSchedule,
  useAssignedUnitChange,
  useAvailableUnitsByDate,
} from 'hooks/query/useCrew';
import { formatFullDBDateTime, formatFullDBDate } from 'helpers/dateHelper';
import Select from 'components/newComponents/Select';
import Preloader from 'components/Preloader';

const CrewAssignedInfo = ({ assignedCrew }) => {
  const { id, tab } = useParams();

  const {
    data: { crews },
  } = useDefault();

  const {
    data: { job_info: jobInfo, dates },
  } = useInfo(id);

  const { data: crewSchedule } = useAssignedSchedule({
    date: formatFullDBDate(dates.required_pickup),
    job_id: jobInfo.id,
  });

  const { mutate: changeAssignedUnit } = useAssignedUnitChange(id, tab);
  const { mutate: confirmAssigned, isLoading } = useAssignConfirm(id, tab);
  const { mutate: cancelAssigned } = useAssignCancel(id, tab);
  const { mutate: removeMover } = useMoverRemove(id, tab);

  const { data: availableUnits } = useAvailableUnitsByDate({
    date: formatFullDBDate(dates.required_pickup),
    start_date: formatFullDBDateTime(dates.required_pickup),
    stop_date: formatFullDBDateTime(dates.stop_date),
    job_id: jobInfo.id,
  });

  const crew = useMemo(() => crews.find((crew) => crew.id === jobInfo.crew_id), [crews, jobInfo.crew_id]);

  const getAssignedStatus = useCallback(
    (id, type) => {
      if (!crewSchedule) return '(not found)';
      const fined = crewSchedule.find((e) => e.unit_type === type && e.id === id);

      if (fined) return `(${fined.status})`;
      return '(not confirmed)';
    },
    [crewSchedule]
  );

  const removeMoverHandler = useCallback(
    (id) => {
      const data = {
        mover_id: id,
        job_id: jobInfo.id,
      };
      removeMover(data);
    },
    [jobInfo.id, removeMover]
  );

  const cancelAssignedHandler = useCallback(() => {
    cancelAssigned({
      job_id: jobInfo.id,
      spot: null,
    });
  }, [cancelAssigned, jobInfo.id]);

  const changeAssignedUnitHandler = useCallback(
    (id, type, assignedId) => {
      const data = {
        unit_id: id,
        unit_type: type,
        assigned_mover_id: assignedId,
        job_id: jobInfo.id,
      };
      changeAssignedUnit(data);
    },
    [changeAssignedUnit, jobInfo.id]
  );

  const confirmCrewHandler = useCallback(() => {
    confirmAssigned({
      job_id: jobInfo.id,
      crew_id: jobInfo.crew_id,
    });
  }, [confirmAssigned, jobInfo.crew_id, jobInfo.id]);

  const getOptions = useCallback(
    (key) => {
      if (!availableUnits || !availableUnits[key]) return [];

      return availableUnits[key].map((e) => ({ label: `${e.name} ${e?.userlastname || ''}`, value: e.id }));
    },
    [availableUnits]
  );

  const getMenuList = useCallback(
    (key, id, props) => {
      return (
        <components.MenuList {...props}>
          {key === 'movers' && (
            <button type="button" className="link cancel" onClick={() => removeMoverHandler(id)}>
              Remove
            </button>
          )}

          {props.children}
        </components.MenuList>
      );
    },
    [removeMoverHandler]
  );

  const getCrewElement = useCallback(
    (key, data) => {
      const title = key.charAt(0).toUpperCase() + key.slice(1);

      return (
        <Fragment key={key}>
          <div className="row p20">
            <div className="col-3">{title}</div>
            <div className="col-9">
              {data.map((el) => {
                return (
                  <div key={`${key}${el.id}`} className="row pb20">
                    <span className="col-6 d-flex align-items-center">
                      {`${el.name} ${el?.userlastname || ''} ${key !== 'trucks' ? getAssignedStatus(key) : ''}`}
                    </span>
                    <div className="col-6">
                      <Select
                        components={{ MenuList: (props) => getMenuList(key, el.id, props) }}
                        options={getOptions(key)}
                        placeholder={<span className="main">Change</span>}
                        onChange={(option) => changeAssignedUnitHandler(option.value, key, el.id)}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </Fragment>
      );
    },
    [changeAssignedUnitHandler, getAssignedStatus, getMenuList, getOptions]
  );

  const units = useMemo(() => {
    if (!assignedCrew) return [];
    return Object.keys(assignedCrew).reduce((acc, key) => {
      if (!assignedCrew[key].length) return acc;
      return [...acc, getCrewElement(key, assignedCrew[key])];
    }, []);
  }, [assignedCrew, getCrewElement]);

  return (
    <Fragment>
      {isLoading && <Preloader />}

      <div className="row p20">
        <div className="col-3">Assigned crew</div>
        <div className="col-9">
          <div className="row pb20">
            <span className="col-6 d-flex align-items-center">{crew?.name || ''}</span>
            <button type="button" className="col-6 main" onClick={cancelAssignedHandler}>
              Dismiss
            </button>
          </div>
        </div>
      </div>
      {units}

      <div className="d-flex p20">
        <span className="w30" />
        {jobInfo.crew_id && jobInfo.crew_confirmed !== 1 ? (
          <button type="button" className="w50 p20 confirm" onClick={confirmCrewHandler}>
            Confirm crew
          </button>
        ) : (
          <b className="flex-grow-1 confirmed">Crew confirmed</b>
        )}
        <span />
      </div>
    </Fragment>
  );
};

export default CrewAssignedInfo;

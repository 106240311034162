import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useChangeElevatorStatus } from 'hooks/query/useJob';
import { getElevatorData } from 'helpers/getElevatorData';
import Icon from 'components/Icon';

const styles = {
  display: 'flex',
  gap: 10,
  padding: 15,
  border: '1px solid rgba(0, 0, 0, 0.15)',
  background: 'white',
  zIndex: 9,
  position: 'absolute',
  top: 0,
  left: 35,
};

const ElevatorMenu = ({ data }) => {
  const { id, tab } = useParams();

  const { mutate: changeStatus } = useChangeElevatorStatus(id, tab);

  const [isOpen, setIsOpen] = useState(false);

  const { elevatorStyle } = getElevatorData(data);

  const handleChangeStatus = (elevatorExist, elevatorConfirm) => {
    changeStatus({
      id: data.id,
      elevator_exist: elevatorExist,
      elevator_confirm: elevatorConfirm,
    });
    setIsOpen(false);
  };

  return (
    <div style={{ width: 'auto', position: 'relative' }}>
      <button type="button" disabled={!data?.id} className={`insurance-c ${elevatorStyle.color}`} onClick={() => setIsOpen(!isOpen)}>
        <Icon icon="from_elevator" title={elevatorStyle.title} />
      </button>

      {isOpen && (
        <div style={styles}>
          <button type="button" className="insurance-c" onClick={() => handleChangeStatus(0, 0)}>
            <Icon icon="from_elevator" title="Elevator reservation not required" style={{ marginRight: 0 }} />
          </button>
          <button type="button" className="insurance-c red" onClick={() => handleChangeStatus(1, 0)}>
            <Icon icon="from_elevator" title="Elevator reservation required" style={{ marginRight: 0 }} />
          </button>
          <button type="button" className="insurance-c green" onClick={() => handleChangeStatus(1, 1)}>
            <Icon icon="from_elevator" title="Elevator reservation approved" style={{ marginRight: 0 }} />
          </button>
          <button type="button" className="insurance-c dark-red" onClick={() => handleChangeStatus(1, 2)}>
            <Icon icon="from_elevator" title="Elevator reservation rejected" style={{ marginRight: 0 }} />
          </button>
        </div>
      )}
    </div>
  );
};

export default ElevatorMenu;

import React from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { useField } from 'formik';

const FormikTime = (props) => {
  const { interval = 30, minHours, maxHours, className, disabled, placeholder, newChange } = props;

  const [field, meta, helpers] = useField(props);

  const handleChange = (value) => {
    helpers.setValue(moment(value).format('hh:mm A'));
    if (newChange) newChange(value);
  };

  const min = minHours
    ? moment(new Date()).set('hour', minHours).set('minute', 0)
    : moment(new Date()).set('hour', 6).set('minute', 0);

  const max = !maxHours
    ? moment(new Date()).set('hour', 23).set('minute', 0)
    : moment(new Date()).set('hour', maxHours).set('minute', 0);

  const setHours = (dt, h) => {
    const s = /(\d+):(\d+)(.+)/.exec(h);
    dt.setHours(s[3].trim().toLocaleLowerCase() === 'pm' ? 12 + parseInt(s[1], 10) : parseInt(s[1], 10));
    dt.setMinutes(parseInt(s[2], 10));
  };

  let newTime = new Date();
  if (field.value) {
    setHours(newTime, field.value);
  } else newTime = null;

  return (
    <div className="d-flex flex-column position-relative">
      <DatePicker
        selected={newTime}
        onChange={handleChange}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={interval}
        timeCaption="Time"
        dateFormat="hh:mm a"
        minTime={new Date(min)}
        maxTime={new Date(max)}
        className={className}
        disabled={disabled}
        placeholderText={placeholder}
      />
      {meta.error && meta.touched && (
        <span style={{ position: 'absolute', bottom: -20 }} className="error">
          {meta.error}
        </span>
      )}
    </div>
  );
};

export default FormikTime;

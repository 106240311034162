import React, { Fragment, useState } from 'react';
import classNames from 'classnames';
import BuildingDetail from './BuildingDetail';
import { useGetBuildingTimeInfo } from 'hooks/query/useReport';

const BuildingAddresses = ({ data, items, values }) => {
  const [open, setOpen] = useState(false);
  const { data: buildingTimeInfoResponse, mutate: getBuildingTimeInfo, isLoading } = useGetBuildingTimeInfo();
  const buildingInfo = buildingTimeInfoResponse?.data?.list;

  const handleOpen = () => {
    if (open) {
      setOpen(false);
      return;
    }

    setOpen(true);
    getBuildingTimeInfo({ company_id: values.company_id, from: values.from, to: values.to, building_id: data.id });
  };

  return (
    <Fragment>
      <div className="reports-table">
        <div className="reports-table-row" style={{ cursor: 'pointer' }} onClick={handleOpen}>
          <div className={classNames('reports-table', { 'reports-select': open })}>
            <div className="reports-table-row">
              <div className="reports-table-column reports-table-column--20 reports-table-column--title p5">
                <b>{data.building_address}</b> <br /> <small>{data.building_name}</small>
              </div>
              {items}
            </div>
          </div>
        </div>
      </div>

      {isLoading && (
        <div className="mh200 shadow">
          <div className="preloader" />
        </div>
      )}

      {open && buildingInfo && <BuildingDetail data={buildingInfo} />}
    </Fragment>
  );
};

export default BuildingAddresses;

import React, { Fragment } from 'react';
import Input from 'components/newComponents/Input';
import { useForm } from '../../../hooks/useForm';

function Additional() {
  const { values, actions } = useForm();

  return (
    <Fragment>
      <div className="new-table--row">
        <div className="new-table--row--item">
          <Input name="note" placeholder="Quick note for job" value={values['note']} onChange={actions.handleChange} />
        </div>
      </div>

      <div className="new-table--row">
        <div className="new-table--row--item">
          <Input name="from_email" placeholder="Email" value={values['from_email']} onChange={actions.handleChange} />
        </div>
      </div>

      <div className="new-table--row">
        <label className="new-table--row--item">
          * Phone number
          <Input
            name="from_home_phone"
            error={actions.isValidHandler('from_home_phone')}
            mask="111-111-1111"
            value={values['from_home_phone']}
            onChange={actions.handleChange}
          />
        </label>
      </div>
    </Fragment>
  );
}

export default Additional;

import React from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useGetOngoingStorageJobs, useAddLocalJobInventoryToOngoingStorageJob } from 'hooks/query/useJob';
import { useModal } from 'context/modal';
import history, { updateRouteParams } from 'utils/history';
import { routePaths } from 'config/routes';
import { ModalButton } from 'components/newComponents/Buttons';
import Modal from 'components/newComponents/Modal';
import Select from 'components/newComponents/Select';

const LocalJobInventoryToOngoingStorageJob = () => {
  const { modalNames, close, info } = useModal();

  const isVisible = info(modalNames.localJobInventoryToOngoingStorageJob).visible;
  const jobId = info(modalNames.localJobInventoryToOngoingStorageJob).params.jobId;

  const { data } = useGetOngoingStorageJobs();
  const { mutate: addLocalJobInventoryToOngoingStorageJob, isSuccess: successfullyAdded } = useAddLocalJobInventoryToOngoingStorageJob();

  const ongoingStorageJobs = data?.map((item) => ({ ...item, value: item.id, label: item.title }));

  const closeHandler = () => close(modalNames.localJobInventoryToOngoingStorageJob);
  const submitHandler = (values) => addLocalJobInventoryToOngoingStorageJob(values);

  return (
    <Modal style={{ width: 700 }} header="Add local job inventory to ongoing storage job" show={isVisible} onHide={closeHandler}>
      <Formik
        initialValues={{
          local_job_id: jobId,
          storage_job_id: null,
        }}
        onSubmit={submitHandler}
        validationSchema={Yup.object().shape({
          storage_job_id: Yup.number().nullable().required('Storage Must Be Specified'),
        })}
      >
        {({ values, setFieldValue, handleSubmit, errors, touched }) => {
          const isValidHandler = (name) => {
            if (errors[name] && touched[name]) return errors[name];
            return null;
          };

          const goToStorage = () => {
            history.push({ pathname: updateRouteParams(routePaths.JOBS, { id: values.storage_job_id, tab: 'inventory', type: 'all' }) });
            closeHandler();
          };

          return (
            <form onSubmit={handleSubmit}>
              <div>
                <Select
                  options={ongoingStorageJobs}
                  value={values['storage_job_id']}
                  placeholder="Select Job"
                  name="storage_job_id"
                  onChange={({ value }) => setFieldValue('storage_job_id', value)}
                  error={isValidHandler('storage_job_id')}
                />
              </div>
              <div className="footer-buttons">
                <ModalButton title="Cancel" variant="cancel" onClick={closeHandler} />
                {successfullyAdded ? (
                  <ModalButton title="Go to Storage" variant="confirm" onClick={goToStorage} />
                ) : (
                  <ModalButton title="Add" variant="confirm" type="submit" />
                )}
              </div>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default LocalJobInventoryToOngoingStorageJob;

import React, { memo } from 'react';
import Icon from 'components/Icon';
import Select from 'components/newComponents/Select';
import { preventArrowUpDown } from 'utils/preventArrowUpDown';

const InventoryTotalViewItem = memo((props) => {
  const { item, symbols, onRemoveItem, onChangeSymbol, onChangeDimension, handleChangeCF } = props;

  const [desc = '', except = '', loc = ''] = item.condition.split('/');

  return (
    <div className="new-table--row">
      <div className="new-table--row--item w30">
        <span>{item.name}</span>
      </div>

      <div className="new-table--row--item w15 justify-content-center">
        {item.custom === 1 ? (
          <input
            type="number"
            min="0"
            value={item.amount}
            placeholder="CF"
            style={{ textAlign: 'center' }}
            onChange={handleChangeCF}
            onKeyDown={preventArrowUpDown}
          />
        ) : (
          <span>{item.amount}</span>
        )}
      </div>

      {/* <div className="new-table--row--item w20 justify-content-center">
        <input
          type="number"
          name="width"
          max="99"
          value={width}
          placeholder="W"
          style={{ textAlign: 'center' }}
          onChange={onChangeDimension}
          onKeyDown={preventArrowUpDown}
        />
        <input
          type="number"
          name="length"
          max="99"
          value={length}
          placeholder="L"
          style={{ textAlign: 'center' }}
          onChange={onChangeDimension}
          onKeyDown={preventArrowUpDown}
        />
        <input
          type="number"
          name="high"
          max="99"
          value={high}
          placeholder="H"
          style={{ textAlign: 'center' }}
          onChange={onChangeDimension}
          onKeyDown={preventArrowUpDown}
        />
      </div> */}

      <div className="new-table--row--item justify-content-between select" style={{ gap: 10 }}>
        <Select
          portal={true}
          value={desc}
          options={symbols.descriptiveSymbols}
          placeholder="Desc."
          onChange={({ value }) => onChangeSymbol(value, 0)}
        />

        <Select
          portal={true}
          value={except}
          options={symbols.exceptionSymbols}
          placeholder="Except."
          onChange={({ value }) => onChangeSymbol(value, 1)}
        />

        <Select portal={true} value={loc} options={symbols.locationSymbols} placeholder="Loc." onChange={({ value }) => onChangeSymbol(value, 2)} />
      </div>

      <div className="new-table--row--item w10 justify-content-center">
        <button type="button" onClick={onRemoveItem}>
          <Icon icon="delete" size={18} title="Delete" style={{ marginRight: 0 }} />
        </button>
      </div>
    </div>
  );
});

export default InventoryTotalViewItem;

import React, { useCallback, useMemo } from 'react';
import Icon from 'components/Icon';
import { useLocation } from 'react-router-dom';
import { matchPath } from 'react-router';
import { routePaths } from 'config/routes';
import { useFormik } from 'formik';
import { useModal } from 'context/modal';
import { useList, useApplyChain } from 'hooks/query/useReminder';
import { useTabInfo } from 'hooks/query/useJob';
import Select from 'components/newComponents/Select';

const RemindersCreateForm = ({ jobId }) => {
  const { data } = useList(jobId);
  const { modalNames, open } = useModal();
  const { pathname } = useLocation();

  const {
    params: { id, tab },
  } = matchPath(pathname, { path: routePaths.JOBS });
  const { mutate: apply } = useApplyChain(jobId);

  const {
    data: { moving_info: movingInfo },
  } = useTabInfo(id, tab);

  const chainOptions = useMemo(() => {
    if (!data) return [];
    return data.chains.map((chain) => ({ value: chain.id, label: chain.name }));
  }, [data]);

  const add = useCallback(() => {
    open(modalNames.newRemainder, { email_to: movingInfo.from.email });
  }, [modalNames.newRemainder, movingInfo.from.email, open]);

  const { values, setFieldValue } = useFormik({ initialValues: { chainId: null } });
  const applyChainHandler = useCallback(() => {
    apply({ chain_id: values.chainId, job_id: jobId });
  }, [apply, jobId, values.chainId]);

  return (
    <div className="new-table--row">
      <div className="new-table--row--item flex-grow-1">
        <button type="button" onClick={add} className="default">
          <Icon icon="add" size={18} />
          Add reminder
        </button>
      </div>

      <div className="new-table--row--item w20 select">
        <Select
          name="chainId"
          value={values.chainId}
          options={chainOptions}
          placeholder="Select chain"
          onChange={({ value }) => setFieldValue('chainId', value)}
        />
      </div>

      <div className="new-table--row--item no-padding w20">
        <button type="button" disabled={!values.chainId} className="flex-grow-1 p20 main" onClick={applyChainHandler}>
          Apply chain
        </button>
      </div>
    </div>
  );
};

export default RemindersCreateForm;

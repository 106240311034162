import { useQueryClient } from 'react-query';
import { useAddJobCarrier } from 'hooks/query/useJob';
import queryKeys from 'config/queryKeys';
import { useModal } from 'context/modal';

export const useSubmit = (params) => {
  const { makeApiRequestOnSubmit, onSubmitLocal } = params;

  const { close, modalNames } = useModal();

  const queryClient = useQueryClient();

  const { mutate: addJobCarrier, isLoading: isMutationRunning } = useAddJobCarrier();

  const handleAddCarrier = (values) => {
    if (!values.job_id || !values.cf_price || !values.carrier_id || !values.carrier_driver_id) return;

    addJobCarrier(values, {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKeys.job]);
        queryClient.invalidateQueries([queryKeys.carrierDrivers]);
        queryClient.invalidateQueries([queryKeys.defaultItems]);

        if (typeof onSubmitLocal === 'function') {
          onSubmitLocal(values);
        }

        close(modalNames.changeJobCarrier);
      },
    });
  };

  const handleSubmit = (values) => {
    if (makeApiRequestOnSubmit) {
      handleAddCarrier(values);
      return;
    }

    if (makeApiRequestOnSubmit === false && typeof onSubmitLocal === 'function') {
      onSubmitLocal(values);
      close(modalNames.changeJobCarrier);
    }
  };

  return {
    onSubmit: handleSubmit,
    isMutationRunning,
  };
};

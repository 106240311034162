import React from 'react';
import { useCallback, useMemo, useRef, useState } from 'react';

import './styles.scss';

const Input = (props) => {
  const { onChange, onSelect, name, type, placeholder, value, error, label, options, ...rest } = props;

  const input = useRef();
  const [active, setActive] = useState(false);

  const suggestions = useMemo(() => {
    if (!options.length) return [];

    const regex = new RegExp(`^${value}`, 'i');

    return options.filter((item) => regex.test(item)).slice(0, 6);
  }, [options, value]);

  const handleChange = useCallback(
    (e) => {
      setActive(true);
      onChange(e.target.value);
    },
    [onChange]
  );

  const handleSelect = useCallback(
    (suggestion, name) => {
      setActive(false);
      onSelect(suggestion, name);
    },
    [onSelect]
  );

  const handleBlur = useCallback(() => setTimeout(() => setActive(false), 200), []);

  return (
    <div className="autocomplete-input-wrapper" key={name}>
      <label style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', position: 'relative' }}>
        {label && label}

        <input
          ref={input}
          name={name}
          value={value}
          onChange={handleChange}
          type={type || 'text'}
          placeholder={placeholder}
          style={{ padding: 0, margin: 0 }}
          onBlur={handleBlur}
          {...rest}
        />

        <div className="autocomplete-dropdown-container">
          {value &&
            active &&
            suggestions.map((suggestion, index) => {
              return (
                <div key={index} className={'suggestion-item'} onClick={() => handleSelect(suggestion, name)}>
                  <span>{suggestion}</span>
                </div>
              );
            })}
        </div>

        {error && <span className="error">{error}</span>}
      </label>
    </div>
  );
};
export default Input;

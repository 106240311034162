import React, { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { matchPath } from 'react-router';
import { useFormik } from 'formik';
import { useModal } from 'context/modal';
import { routePaths } from 'config/routes';
import { useDefault } from 'hooks/query/useCommon';
import { useDiscountUpdate } from 'hooks/query/useJob';
import { ModalButton } from 'components/newComponents/Buttons';
import Modal from 'components/newComponents/Modal';
import DiscountItem from './DiscountItem';

const Discount = () => {
  const { modalNames, close, info } = useModal();
  const { pathname } = useLocation();

  const {
    params: { id, tab },
  } = matchPath(pathname, { path: routePaths.JOBS });

  const {
    data: { discounts: defaultItems },
  } = useDefault();

  const { mutate: updateDiscount } = useDiscountUpdate(id, tab);

  const isVisible = info(modalNames.discount).visible;
  const type = info(modalNames.discount)?.params?.type;
  const companyId = info(modalNames.discount)?.params?.companyId;
  const jobDiscounts = info(modalNames.discount)?.params?.jobDiscounts;
  const onSuccess = info(modalNames.discount)?.params?.onSuccess;

  const discounts = useMemo(() => {
    if (!jobDiscounts) return {};
    return jobDiscounts[type].reduce((acc, e) => {
      return { ...acc, [e.discount_id]: e };
    }, {});
  }, [jobDiscounts, type]);

  const defaultDiscounts = useMemo(() => {
    return defaultItems[companyId].reduce((acc, e) => {
      return { ...acc, [e.id]: { ...e, checked: !!discounts[e.id] } };
    }, {});
  }, [defaultItems, companyId, discounts]);

  const closeHandler = useCallback(() => close(modalNames.discount), [close, modalNames.discount]);

  const updateDiscountHandler = useCallback(
    ({ items }) => {
      const isFinal = type === 'final';
      const prepared = Object.values(items)
        .filter((e) => e.checked)
        .map((e) => ({ job_id: id, discount_id: e.id }));

      if (onSuccess) {
        onSuccess(prepared);
        closeHandler();
        return;
      }

      updateDiscount(
        {
          job_id: id,
          job_discount: {
            estimated: isFinal ? jobDiscounts['estimated'] : prepared,
            final: prepared,
          },
        },
        { onSuccess: closeHandler }
      );
    },
    [type, onSuccess, updateDiscount, id, jobDiscounts, closeHandler]
  );

  const { values, setFieldValue, handleSubmit } = useFormik({
    initialValues: {
      items: defaultDiscounts,
    },
    onSubmit: updateDiscountHandler,
  });

  const onChangeHandler = useCallback(
    (id) => {
      const { [id]: current, ...rest } = values.items;

      setFieldValue('items', { ...rest, [id]: { ...current, checked: !current.checked } });
    },
    [setFieldValue, values.items]
  );

  return (
    <Modal style={{ width: 600 }} header="Discount" show={isVisible} onHide={closeHandler}>
      <form onSubmit={handleSubmit}>
        <div className="new-table modal">
          <div className="new-table--row">
            <div className="new-table--row--item">
              <h4 className="subHeader p-0">Name</h4>
            </div>
            <div className="new-table--row--item">
              <h4 className="subHeader p-0">Amount</h4>
            </div>
          </div>
          {Object.values(values.items).map((item) => (
            <DiscountItem key={item.id} item={item} type={type} handleChange={onChangeHandler} />
          ))}
        </div>
        <div className="footer-buttons">
          <ModalButton title="Cancel" variant="cancel" onClick={closeHandler} />
          <ModalButton title="Add" variant="confirm" type="submit" />
        </div>
      </form>
    </Modal>
  );
};

export default Discount;

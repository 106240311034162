function sumFees(data) {
  if (!data) return 0;

  const keys = {
    admin_fee: 'admin_fee',
    delivery_fee: 'delivery_fee',
    redelivery_fee: 'redelivery_fee',
    hold_fee: 'hold_fee',
    insurance_fee: 'insurance_fee',
  };

  const sum =
    parseFloat(data[keys.admin_fee]) +
    parseFloat(data[keys.delivery_fee]) +
    parseFloat(data[keys.redelivery_fee]) +
    parseFloat(data[keys.hold_fee]) +
    parseFloat(data[keys.insurance_fee]);

  return sum;
}

export { sumFees };

import { useMutation, useQuery } from 'react-query';
import { useApi } from 'context/api';
import queryKeys from 'config/queryKeys';

export const useTimeById = () => {
  const { building } = useApi();
  return useMutation(building.timeById);
};

export const useInfoUpdate = () => {
  const { building } = useApi();

  return useMutation(building.updateInfo);
};

export const useInfo = () => {
  const { building } = useApi();

  return useQuery(queryKeys.building, building.updateInfo, { select: (res) => res.data });
};

import { createBrowserHistory } from 'history';
import { matchPath, generatePath } from 'react-router-dom';

const history = createBrowserHistory();

history.listen((location, action) => {
  // const state = location.state || {};
  // scroll to top after route changing
  // state.scrollToTop != false && window.scrollTo(0, 0);
});

function getHistoryParams(routePath) {
  const match = matchPath(history.location.pathname, {
    path: routePath,
  });

  return match == null ? {} : match.params;
}

function updateRouteParams(routePath, nextParams) {
  const currentParams = getHistoryParams(routePath);

  return generatePath(routePath, {
    ...currentParams,
    ...nextParams,
  });
}

export { history as default, getHistoryParams, updateRouteParams };

import axios from 'axios';
import showError from 'utils/errorHandler';

class Api {
  constructor() {
    this.serverApi = process.env.REACT_APP_API_URL;
    this.client = axios.create({ baseURL: this.serverApi });
  }

  checkSign = async (jobId, document) => {
    try {
      const response = await this.client.post(`/api/customerDocuments/bookingSignCheck`, {
        job_id: jobId,
        document,
      });

      return response;
    } catch (error) {
      console.log(error);
    }
  };

  getImportantTerms = async (document) => {
    try {
      const response = await this.client.get('api/customer/getImportantTerms', { params: { document } });

      return response;
    } catch (error) {
      console.log(error);
    }
  };

  sign = async (jobId, document, sign) => {
    try {
      await this.client.post(`/api/customerDocuments/saveSign`, {
        sign,
        job_id: jobId,
        document,
      });

      return true;
    } catch (error) {
      showError(error);
    }
  };
}

export default new Api();

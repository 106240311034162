import React from 'react';
import Item from './Item';

import './styles.scss';

const JobFilterDropdown = ({ statuses, jobOptions, onChange }) => {
  return (
    <div className="job-filters">
      {Object.entries(jobOptions).map((filter, index) => {
        const isChecked = !!statuses?.find((item) => Number(item) === Number(filter[0]));

        return <Item checked={isChecked} onChange={() => onChange(Number(filter[0]), isChecked)} key={`${index}${filter[0]}`} filter={filter} />;
      })}
    </div>
  );
};

export default JobFilterDropdown;

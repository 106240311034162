import { useState, useEffect } from 'react';

export const useDetectOutsideClick = (el, initialState, allowMenuOpen = false) => {
  const [isActive, setIsActive] = useState(initialState);

  useEffect(() => {
    const onClick = (e) => {
      if (el.current !== null && !el.current.contains(e.target) && !allowMenuOpen) {
        setIsActive(false);
      }
    };

    if (isActive) {
      // document.addEventListener('mousedown', onClick);
      window.addEventListener('click', onClick);
      document.addEventListener('touchstart', onClick);
    }

    return () => {
      window.removeEventListener('click', onClick);
      document.removeEventListener('touchstart', onClick);
      // document.addEventListener('mousedown', onClick);
    };
  }, [isActive, el, allowMenuOpen]);

  return [isActive, setIsActive];
};

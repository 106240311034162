import React, { useState, useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { matchPath } from 'react-router';
import { routePaths } from 'config/routes';
import { useFormik } from 'formik';
import { useTabInfo, useInternalNotesHeadersSave } from 'hooks/query/useJob';

const PdfHeaderTab = () => {
  const { pathname } = useLocation();

  const {
    params: { id, tab },
  } = matchPath(pathname, { path: routePaths.JOBS });

  const {
    data: { notes },
  } = useTabInfo(id, tab);

  const { mutate: saveNote } = useInternalNotesHeadersSave(id, tab);

  const [isEditable, setIsEditable] = useState(false);

  const current = useMemo(() => notes?.find((note) => note.type === 3) || null, [notes]);

  const { values, handleChange } = useFormik({ initialValues: { note: current?.note || '' } });

  const save = useCallback(() => {
    saveNote({ custom_header: values.note, job_id: id, id: current?.id || null });
  }, [current, id, saveNote, values.note]);

  return (
    <div className="d-flex p15 pt-0">
      <textarea
        style={{ resize: 'vertical' }}
        placeholder="Pdf header"
        rows={10}
        name="note"
        disabled={!isEditable}
        value={values.note}
        onChange={handleChange}
      />
      <button className="w15 main p20" onClick={() => (isEditable ? save() : setIsEditable((pre) => !pre))}>
        {isEditable ? 'Save' : 'Edit'}
      </button>
    </div>
  );
};

export default PdfHeaderTab;

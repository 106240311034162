import React, { memo } from 'react';

const InsuranceItem = memo(({ type, item, handleChange, checked }) => {
  return (
    <div className="new-table--row">
      <label className="new-table--row--item justify-content-start">
        <input type="checkbox" name="checked" checked={item.checked} onChange={() => handleChange(item.id)} />
        <span>{item.insurance_name}</span>
      </label>
      <div className="new-table--row--item">
        <span>{item.amount} </span>
      </div>
    </div>
  );
});

export default InsuranceItem;

import React, { Component } from 'react';
import SinglePoint from 'components/GoogleMap/SinglePoint';
import MapDirections from 'components/GoogleMap/MapDirections';

class MapsContainer extends Component {
  constructor(props) {
    super(props);
    const { movingInfo, extraStops, officeInfo } = props;

    const waypoints = extraStops.map((item) => ({
      location: `${item.address}, ${item.city}, ${item.state} ${item.zip}`,
    }));
    waypoints.unshift({ location: movingInfo.from.full_address });
    waypoints.push({ location: movingInfo.to.full_address });

    this.state = {
      isHiddenMap: false,
      isHiddenDir: false,
      from_address: officeInfo.full_address,
      to_address: officeInfo.full_address,
      waypoints: waypoints,
      movingInfo,
    };
  }

  toggleHiddenMap = () => this.setState((prevState) => ({ isHiddenMap: !prevState.isHiddenMap }));
  toggleHiddenDir = () => this.setState((prevState) => ({ isHiddenDir: !prevState.isHiddenDir }));

  componentWillUnmount() {
    this.setState({ isHiddenMap: true, isHiddenDir: true });
  }

  render() {
    const { isHiddenMap, isHiddenDir, movingInfo, to_address, from_address, waypoints } = this.state;

    return (
      <div className="maps-wrapper">
        <div className="shadow map-item">
          <h3 className="header" onClick={this.toggleHiddenMap}>
            Google map
          </h3>
          {!isHiddenMap && (
            <div className="map-item-body">
              <SinglePoint title={'From'} marker={movingInfo.from.full_address} />
              <SinglePoint title={'To'} marker={movingInfo.to.full_address} />
            </div>
          )}
        </div>

        <div className="map-item shadow">
          <h3 className="header" onClick={this.toggleHiddenDir}>
            Directions
          </h3>
          {!isHiddenDir && <MapDirections stop={this.props.extraStops} to={to_address} from={from_address} waypoints={waypoints} />}
        </div>
      </div>
    );
  }
}

export default MapsContainer;

import React, { useState, memo, useCallback, Fragment } from 'react';
import classNames from 'classnames';
import CategoryItem from './CategoryItem';

const Category = memo(({ name, items, addItemToJobInventory }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = useCallback(() => setIsOpen(!isOpen), [isOpen]);

  return (
    <Fragment>
      <div className="new-table--row">
        <div className="new-table--row--item light no-shadow w70 fw-700">
          <span>{name}</span>
        </div>
        <div className="new-table--row--item light w10 no-shadow text-center fw-700">
          <span>CF</span>
        </div>
        <div className="new-table--row--item light w10 no-shadow text-center fw-700">
          <span>Qty</span>
        </div>
        <div className="new-table--row--item light w10 no-shadow text-center fw-700" />
      </div>

      <div className={classNames('new-table-row stripped', { open: isOpen })}>
        {items.map((item) => {
          return <CategoryItem key={item.id} id={item.id} item={item} {...item} addItemToJobInventory={addItemToJobInventory} />;
        })}
      </div>

      {items.length > 3 && (
        <div className="new-table--row">
          <button type="button" className="new-table--row--item show-more" onClick={toggleOpen}>
            {!isOpen ? 'Show more' : 'Show less'}
          </button>
        </div>
      )}
    </Fragment>
  );
});

export default Category;

import React, { useState } from 'react';
import Icon from 'components/Icon';
import { preventArrowUpDown } from 'utils/preventArrowUpDown';

const PackingItem = ({ onAdd, item: { size, price, name, id } }) => {
  const [qty, setQty] = useState(1);

  const addToSave = () => {
    const data = {
      pack_id: id,
      name: name,
      type: 1,
      qty: qty,
      price: price,
      date: new Date().toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }),
      move_type: 1,
      new_item: 1,
    };
    onAdd(data);
  };

  return (
    <div className="new-table--row">
      <div className="justify-content-center w35 new-table--row--item select">
        <span> {name} </span>
      </div>
      <div className="justify-content-center w10 new-table--row--item select">
        <span> {price} </span>
      </div>
      <div className="justify-content-center w25 new-table--row--item select">
        <span>
          {size.height} x {size.length} x {size.width}
        </span>
      </div>
      <div className="justify-content-center w15 new-table--row--item select">
        <input
          type="number"
          className="text-center"
          value={qty}
          name="qty"
          onChange={(e) => setQty(Number(e.target.value))}
          onKeyDown={preventArrowUpDown}
        />
      </div>
      <div className="justify-content-center w15 new-table--row--item select no-padding">
        <button onClick={addToSave} className="flex-grow-1">
          <Icon icon="add" size={16} title="Add" />
        </button>
      </div>
    </div>
  );
};

export default PackingItem;

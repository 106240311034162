import React, { Fragment, useCallback } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { reminderTypeOptions } from 'helpers/constants';
import Select from 'components/newComponents/Select';
import Input from 'components/newComponents/Input';

const EditChain = (props) => {
  const { data = {}, onSave } = props;

  const { values, errors, touched, handleSubmit, handleChange, setFieldValue } = useFormik({
    initialValues: {
      id: null,
      name: '',
      type: '',
      ...data,
    },
    enableReinitialize: true,
    onSubmit: onSave,
    validationSchema: Yup.object().shape({ name: Yup.string().required('Enter name!'), type: Yup.string().required('Select type') }),
  });

  const getDeepValue = (obj, path) => {
    return path.split('.').reduce((acc, name, index) => {
      if (index === 0) return obj?.[name];
      return acc?.[name];
    }, obj);
  };

  const isValidHandler = useCallback(
    (name) => {
      const error = getDeepValue(errors, name);
      if (error && getDeepValue(touched, name)) return error;
      return null;
    },
    [errors, touched]
  );

  return (
    <Fragment>
      <div className="new-table--row">
        <div className="new-table--row--item col-2">Chain Name</div>
        <div className="new-table--row--item col-5">
          <Input name="name" placeholder="Enter Chain Name" value={values.name} error={isValidHandler('name')} onChange={handleChange} />
        </div>
        <div className="new-table--row--item col-2">Type</div>
        <div className="new-table--row--item col-3 select">
          <Select
            value={values.type}
            options={reminderTypeOptions}
            error={isValidHandler('type')}
            onChange={({ value }) => setFieldValue('type', value)}
          />
        </div>
      </div>
      <div className="new-table--row">
        <div className="new-table--row--item justify-content-center">
          <button onClick={handleSubmit} type="button" className="save-chain">
            Save
          </button>
        </div>
      </div>
    </Fragment>
  );
};

export default EditChain;

import React from 'react';
import { useIsFetching } from 'react-query';
import Preloader from 'components/Preloader';
import queryKeys from 'config/queryKeys';

const Loader = () => {
  const isFetchingJobPage = useIsFetching([queryKeys.jobPage]);
  const isFetchingJob = useIsFetching([queryKeys.job]);
  const isFetchingDefaultItems = useIsFetching([queryKeys.defaultItems]);
  const isFetchingCommunication = useIsFetching([queryKeys.communication]);
  const isFetchingCommunications = useIsFetching([queryKeys.communications]);
  const isFetchingSelectedMail = useIsFetching([queryKeys.selectedMail]);
  const isFetchingSelectedChat = useIsFetching([queryKeys.selectedChat]);
  const isFetchingAvailableUnitsByDate = useIsFetching([queryKeys.availableUnitsByDate]);
  const isFetchingAssignedCrewSchedule = useIsFetching([queryKeys.assignedCrewSchedule]);
  const isFetchingImportantNotes = useIsFetching([queryKeys.importantNotes]);

  const showPreloader =
    isFetchingJobPage ||
    isFetchingJob ||
    isFetchingDefaultItems ||
    isFetchingCommunication ||
    isFetchingCommunications ||
    isFetchingSelectedMail ||
    isFetchingSelectedChat ||
    isFetchingAvailableUnitsByDate ||
    isFetchingAssignedCrewSchedule ||
    isFetchingImportantNotes;

  return <React.Fragment>{showPreloader ? <Preloader /> : null}</React.Fragment>;
};

export default Loader;

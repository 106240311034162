import React, { Fragment, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useTabInfo, useInfo } from 'hooks/query/useJob';
import { useDefault } from 'hooks/query/useCommon';
import Icon from 'components/Icon';

const ExpResTruck = (props) => {
  const { id, tab } = useParams();
  const {
    data: { assigned_crew: assignedCrew },
  } = useTabInfo(id, tab);

  const {
    data: { job_info: jobInfo, calc_info: calcInfo },
  } = useInfo(id);

  const {
    data: { workers_trucks: workersTrucks },
  } = useDefault();

  const truckInfo = useMemo(() => {
    if (!assignedCrew.trucks.length) return null;
    return workersTrucks[jobInfo.company_id].trucks.find((truck) => truck.id === assignedCrew.trucks[0].id);
  }, [assignedCrew.trucks, jobInfo.company_id, workersTrucks]);

  const gallonsUsed = useMemo(() => {
    if (!truckInfo) return 0;
    return (calcInfo.distance_total / parseFloat(truckInfo.miles_per_gallon)).toFixed(2);
  }, [calcInfo.distance_total, truckInfo]);

  const fuelCost = useMemo(() => {
    if (!truckInfo || !gallonsUsed) return 0;
    return (gallonsUsed * parseFloat(truckInfo.fuel_cost_per_gallon)).toFixed(2);
  }, [gallonsUsed, truckInfo]);

  return (
    <Fragment>
      <div className="new-table--row no-padding bcg-light">
        <div className="new-table--row--item no-side-shadows">
          <b className="disabled">Truck</b>
        </div>
        <div className="new-table--row--item no-side-shadows">
          <Icon
            className="fb3"
            icon="full_distance"
            title="Full Distance: from Office to Pickup, Extra Stops, Destination and back to Office"
            size={20}
          />
          <Icon className="fb3" icon="gallons_used" title="Gallons used" size={20} />
          <Icon className="fb3" icon="fuel_cost" title="Fuel cost" size={20} />
        </div>
      </div>
      {truckInfo ? (
        <div className="new-table--row no-padding">
          <div className="new-table--row--item  no-side-shadows">
            <b>{truckInfo.name}</b>
          </div>
          <div className="new-table--row--item  no-side-shadows">
            <b className="fb3"> {calcInfo.distance_total}</b>
            <b className="fb3"> {gallonsUsed}</b>
            <b className="fb3"> {fuelCost}</b>
          </div>
        </div>
      ) : (
        <div className="new-table--row no-padding">
          <div className="new-table--row--item no-side-shadows">
            <b>no truck selected</b>
          </div>
          <div className="new-table--row--item no-side-shadows">
            <b className="fb3"> 0.00 </b>
            <b className="fb3"> 0.00 </b>
            <b className="fb3"> 0.00 </b>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default ExpResTruck;

import React from 'react';
import './TooltipTitle.scss';

const TooltipTitle = (props) => {
  return (
    <span className="tooltip-wrapper--title">
      {props.children}
      {props.title && (
        <span className="tooltip-text--title" style={props.top ? { bottom: '150%' } : { top: '150%' }}>
          {props.title}
        </span>
      )}
    </span>
  );
};

export default TooltipTitle;

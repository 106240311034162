const periodLength = [
  { value: 0, label: 'All Periods' },
  { value: 1, label: 'Today' },
  { value: 2, label: 'Yesterday' },
  { value: 3, label: 'This Week' },
  { value: 4, label: 'Last Week' },
  { value: 5, label: 'Last 2 Weeks' },
  { value: 6, label: 'Last 30 Days' },
  { value: 7, label: 'This Month' },
  { value: 8, label: 'Last Month' },
  { value: 9, label: 'This Year' },
  { value: 10, label: 'Last Year' },
];

const jobStatuses = [
  { value: 1, label: 'Assigned' },
  { value: 7, label: 'Follow Up' },
  { value: 22, label: 'Lost' },
  { value: 2, label: 'Booked' },
  { value: 18, label: 'Canceled' },
];

const buildingsJobsTypes = [
  { value: [], label: 'All' },
  { value: [6], label: 'Completed' },
];

const periodList = [
  { value: 0, label: 'Weekly' },
  { value: 1, label: 'Biweekly' },
  { value: 2, label: 'Monthly' },
  { value: 3, label: 'Quarterly' },
];

const jobStatusType = [
  { value: 'booked', label: 'Booked' },
  { value: 'lost', label: 'Lost' },
  { value: 'canceled', label: 'Canceled' },
];

const jobTypes = [
  { value: [1, 2, 3], label: 'All Job Type' },
  { value: [1], label: 'Local' },
  { value: [2], label: 'LD' },
  { value: [3], label: 'Storage' },
];

const chartTypes = [
  { value: 1, label: 'Total' },
  { value: 2, label: 'Expenses' },
  { value: 3, label: 'Salary' },
  { value: 4, label: 'Commission' },
  { value: 5, label: 'Profit' },
];

const packingTypes = [
  { value: 'null', label: 'All' },
  { value: 'packing', label: 'Container Sold' },
  { value: 'full_packing', label: 'Full Packing Job' },
];

const jobFiltersOptionsPayroll = {
  5: 'Delivered',
  6: 'Completed',
  10: 'Sold',
};

export { periodLength, periodList, packingTypes, jobStatuses, jobStatusType, jobTypes, chartTypes, buildingsJobsTypes, jobFiltersOptionsPayroll };

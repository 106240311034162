import { alert } from 'components/Alerts';

const overlapAlert = (title) => {
  return alert({
    title,
    text: 'Do you want to continue?',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, continue',
  });
};

export { overlapAlert };

import React, { Fragment, useCallback, useMemo } from 'react';
import withReactContent from 'sweetalert2-react-content';
import { useParams } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import Swal from 'sweetalert2';
import moment from 'moment';
import { useAuth } from 'context/auth';
import getAbbr from 'helpers/Abbr';
import getTitle from 'helpers/GetTitle';
import { statusNumberToName, availableStatuses } from 'helpers/statuses';
import { referralOptions } from 'helpers/constants';
import { DropdownMenu, SelectLinkItem } from 'components/newComponents/DropDown';
import JobWithoutDeposit from 'components/JobWithoutDeposit';
import Icon from 'components/Icon';
import { useModal } from 'context/modal';
import { useDefault } from 'hooks/query/useCommon';
import { useInfo } from 'hooks/query/useJob';
import { useEmailSend } from 'hooks/query/useMail';
import './style.scss';

const MySwal = withReactContent(Swal);

const JobInfoStatus = () => {
  const { id, tab } = useParams();
  const { data: defaultItems } = useDefault();

  const { mutate: sendMail } = useEmailSend(id, tab);

  const { user } = useAuth();

  const {
    data: {
      job_info: jobInfo,
      calc_info: calcInfo,
      moving_info: movingInfo,
      job_payments: jobPayments,
      additional_info: additionalInfo,
      job_insurance_info: insuranceInfo,
      dates,
    },
  } = useInfo(id);

  const jobStatuses = useMemo(() => {
    if (!defaultItems) return [];
    return defaultItems.statuses.job_statuses;
  }, [defaultItems]);

  const leadProviderName = useMemo(() => {
    if (!defaultItems || !jobInfo) return '';

    const leadProviderId = jobInfo.lead_provider_id;
    if (Boolean(leadProviderId)) {
      return defaultItems.lead_providers.find((provider) => provider.id === leadProviderId).name;
    }

    const hearAboutId = jobInfo.hear_about;
    if (Boolean(hearAboutId)) {
      return referralOptions.find((i) => i.value === hearAboutId).label;
    }

    return '';
  }, [defaultItems, jobInfo]);

  const { open, modalNames } = useModal();

  const statusTitle = useCallback((status) => jobStatuses[status], [jobStatuses]);

  const statusesArray = useMemo(() => availableStatuses[jobInfo.status], [jobInfo.status]);

  const checkCOIApproved = useCallback(() => {
    if (movingInfo.from?.required_coi === 1 && !insuranceInfo.from) {
      return false;
    }

    if (
      insuranceInfo.from &&
      (Number(insuranceInfo.from.approved_liability) !== 1 ||
        (Number(insuranceInfo.from.disabled_wc) === 0 && Number(insuranceInfo.from.approved_wc) !== 1))
    ) {
      return false;
    }

    if (movingInfo.to.required_coi === 1 && !insuranceInfo.to) {
      return false;
    }

    return !(
      insuranceInfo.to &&
      (Number(insuranceInfo.to.approved_liability) !== 1 ||
        (Number(insuranceInfo.to.disabled_wc) === 0 && Number(insuranceInfo.to.approved_wc) !== 1))
    );
  }, [insuranceInfo, movingInfo]);

  const checkElevatorConfirmation = useCallback(() => {
    if (additionalInfo.from?.elevator_exist === 1 && additionalInfo.from?.elevator_confirm === 0) return false;

    if (additionalInfo.to?.elevator_exist === 1 && additionalInfo.to?.elevator_confirm === 0) return false;

    return true;
  }, [additionalInfo]);

  const onChangeJobStatus = (value) => {
    if (value === 6 && moment(dates.stop_date).timestamp > moment().unix()) {
      return Swal.fire({
        title: 'Operation not allowed.',
        text: "Job can't be completed before the stop date reached.",
        icon: 'error',
      });
    }
    if (!checkCOIApproved() && value === 3) {
      return Swal.fire({
        title: 'Operation not allowed.',
        text: "Job can't be confirmed because COI not approved",
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Continue anyway',
      }).then((result) => {
        if (result.isConfirmed) open(modalNames.changeJobStatus, { status: value });
        return;
      });
    }
    if (!checkElevatorConfirmation() && value === 3) {
      return Swal.fire({
        title: `Operation not allowed.`,
        text: "Job can't be confirmed because elevator reservation not confirmed",
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Continue anyway',
      }).then((result) => {
        if (result.isConfirmed) open(modalNames.changeJobStatus, { status: value });
        return;
      });
    }

    if (user.id !== jobInfo.manager_id && value === 6) {
      return Swal.fire({
        title: 'Operation not allowed.',
        text: 'Only assigned manager can complete job!',
        icon: 'error',
      });
    }

    let allowed_status_change = statusesArray.some((status) => Number(status) === Number(value));

    if (allowed_status_change) {
      let payed_deposit = jobPayments?.some((payments) => parseInt(payments.deposit) === 1);

      if (value === 2 && parseInt(calcInfo.disabled_deposit) === 0 && !payed_deposit) {
        const link = `https://${window.location.hostname}/api/customerDocuments/payment?job_id=${jobInfo.id}&type=deposit`;
        MySwal.fire({
          title: 'Operation not allowed.',
          html: <JobWithoutDeposit jobId={jobInfo.id} status={jobInfo.status} link={link} sendMail={sendMail} />,
          icon: 'error',
        });
        return;
      }
      if (value === 16) {
        return Swal.fire({
          title: 'Operation not allowed.',
          text: 'Only assigned manager can complete job!',
          icon: 'error',
        });
      }

      open(modalNames.changeJobStatus, { status: value });
    } else {
      let current_status_name = defaultItems?.statuses?.job_statuses[parseInt(jobInfo.status)];
      let new_status_name = defaultItems?.statuses?.job_statuses[value];
      return Swal.fire({
        title: 'Operation not allowed.',
        text: 'Status ' + current_status_name + " can't be changed to " + new_status_name + '!',
        icon: 'error',
      });
    }
  };

  return (
    <div className="new-table--row--item w15 flex-grow-0 table-border-right" style={{ padding: '0 0 0 20px' }}>
      <DropdownMenu
        wrapperClass="status-menu-wrapper"
        trigger={
          <Fragment>
            <Icon icon={statusNumberToName(jobInfo.status)} title={getTitle(jobInfo.status)} size={20} />
            <span data-tip data-for="company-name-tooltip">
              {jobInfo.title}

              <ReactTooltip className="company-tooltip" arrowColor="transparent" id="company-name-tooltip" place="bottom">
                <span>{getAbbr(jobInfo?.company_id, defaultItems?.companies_abbr)}</span>
                {leadProviderName && <span> | {leadProviderName}</span>}
              </ReactTooltip>
            </span>
          </Fragment>
        }
      >
        <Fragment>
          {+jobInfo.status === 21 && (
            <SelectLinkItem label="Follow Up" icon={<Icon icon={statusNumberToName(7)} size={22} />} value={7} onClick={onChangeJobStatus} />
          )}
          {+jobInfo.status !== 21 && (
            <Fragment>
              {statusesArray?.map((item) => (
                <SelectLinkItem
                  key={item}
                  icon={<Icon icon={statusNumberToName(+item)} size={22} />}
                  label={statusTitle(item)}
                  value={item}
                  onClick={onChangeJobStatus}
                />
              ))}
            </Fragment>
          )}
        </Fragment>
      </DropdownMenu>
    </div>
  );
};

export default JobInfoStatus;

import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { QueryClient, QueryClientProvider, QueryCache, MutationCache } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { AuthProvider } from 'context/auth';
import showError from 'utils/errorHandler';
import { ApiProvider } from 'context/api';
import { ModalProvider } from 'context/modal';
import * as serviceWorker from './serviceWorker';
import history from 'utils/history';
import App from './App';
import './index.css';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: { refetchOnWindowFocus: false, refetchOnMount: false },
  },
  queryCache: new QueryCache({
    onError: (error, query) => {
      showError(error);
    },
  }),
  mutationCache: new MutationCache({
    onError: (error) => {
      showError(error);
    },
  }),
});

ReactDOM.render(
  <Router history={history}>
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <ModalProvider>
          <ApiProvider>
            <App />
          </ApiProvider>
        </ModalProvider>
      </AuthProvider>
      <ReactQueryDevtools initialIsOpen={false} position="bottom-left" />
    </QueryClientProvider>
  </Router>,
  document.getElementById('root')
);

serviceWorker.unregister();

import { getStorageTypeLable } from 'helpers/storage';
import initialValues from './initialValues';

export function getMatchedUnitSizes(unit_sizes = [], total = 0) {
  const unit_size_ids = [];

  if (unit_sizes.length > 0) {
    let totalUnplacedSize = total;

    const getSize = (size) => totalUnplacedSize <= Number(size.cf_capacity);

    while (totalUnplacedSize > 0) {
      const matchedUnitSize = unit_sizes.find(getSize);

      if (matchedUnitSize) {
        unit_size_ids.push(matchedUnitSize.id);
        totalUnplacedSize = 0;
      } else {
        const largestUnitSize = [...unit_sizes].reverse().find((size) => Number(size.cf_capacity) > 0);

        if (!largestUnitSize) {
          totalUnplacedSize = 0;
          return;
        }

        unit_size_ids.push(largestUnitSize.id);
        totalUnplacedSize = totalUnplacedSize - Number(largestUnitSize.cf_capacity);
      }
    }
  }

  return unit_size_ids;
}

export function prepareInitialValues(params = {}, leadProviders = []) {
  const { phoneNumber, companyID, callId, chatId } = params;

  const prepared = { ...initialValues };

  if (phoneNumber) {
    const leadProvider = leadProviders.find((i) => i.phone === phoneNumber);

    if (leadProvider) {
      prepared.lead_provider_id = leadProvider.id;
    }

    prepared.from_home_phone = phoneNumber;
  }

  if (companyID) prepared.company_id = companyID;
  if (callId) prepared.call_id = callId;
  if (chatId) prepared.chat_id = chatId;

  return prepared;
}

export function isFieldValid(errors = {}, touched = {}, name) {
  if (errors[name] && touched[name]) return errors[name];
  return null;
}

export function getLeadProviderOptions(leadProviders = []) {
  return leadProviders.map((i) => ({
    ...i,
    value: i.id,
    label: i.name,
  }));
}

export function getCompaniesOptions(companies = []) {
  return companies.map((company) => ({
    value: company.id,
    label: company.company_name,
  }));
}

export function getStoragesOptions(storages, company_id) {
  if (!storages || !company_id) return [];

  const companyStorages = storages[company_id] ?? [];

  return companyStorages.map((storage) => ({
    value: storage.id,
    label: `${storage.name} (${getStorageTypeLable(storage.type)})`,
  }));
}

export function getUnitSizesByStorageId(storage_unit_sizes = [], storage_id) {
  if (!storage_id) return [];

  return storage_unit_sizes
    .filter((size) => size.storage_id === storage_id)
    .map((item) => ({
      ...item,
      value: item.id,
      label: item.name,
    }));
}

export function getBuildingsOptions(building_info = []) {
  return building_info.map((item) => item?.building_name);
}

export function getMoveSizesByCompanyId(move_sizes, company_id) {
  if (!move_sizes || !company_id) return [];

  const companyMoveSizes = move_sizes[company_id] ?? [];

  return companyMoveSizes.map((size) => ({
    ...size,
    value: size.id,
    label: size.name,
    minimum_hour: size.minimum_hour,
    defaultNumberMan: size.default_number_man,
    defaultTruck: size.trucks.find((truck) => Boolean(truck.default))?.trucks || 1,
  }));
}

export function getCurrentMoveSize(moveSizesList = [], move_size_id) {
  if (!move_size_id) return null;

  return moveSizesList.find((e) => e.value === move_size_id);
}

export function getFees(storages, storage_id, company_id) {
  if (!storages || !storage_id) {
    return {};
  }

  const companyStorages = storages[company_id] ?? [];

  const storage = companyStorages.find((storage) => storage.id === storage_id);

  if (!storage) {
    return {};
  }

  const { admin_fee, delivery_fee, hold_fee, insurance_fee, redelivery_fee } = storage;

  return {
    admin_fee: Number(admin_fee).toFixed(0),
    delivery_fee: Number(delivery_fee).toFixed(0),
    hold_fee: Number(hold_fee).toFixed(0),
    insurance_fee: Number(insurance_fee).toFixed(0),
    redelivery_fee: Number(redelivery_fee).toFixed(0),
  };
}

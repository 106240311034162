import React, { Fragment, useCallback, useMemo } from 'react';
import { useInfo, useTabInfo, useChargesUpdate } from 'hooks/query/useJob';
import { useParams } from 'react-router-dom';
import { useDefault } from 'hooks/query/useCommon';
import { useModal } from 'context/modal';
import Icon from 'components/Icon';

const Charges = ({ type }) => {
  const { id, tab } = useParams();
  const { modalNames, open } = useModal();

  const {
    data: { job_info: jobInfo },
  } = useInfo(id);

  const {
    data: { charges },
  } = useDefault();

  const {
    data: { job_charges: jobCharges },
  } = useTabInfo(id, tab);

  const { mutate: updateCharges } = useChargesUpdate(id, tab);

  const companyId = jobInfo.company_id;
  const estIdsArr = jobCharges['estimated'].map((item) => item.charges_id);
  const finIdsArr = jobCharges['final'].map((item) => item.charges_id);

  const estFiltered = charges[companyId]?.reduce((acc, item) => {
    if (estIdsArr.includes(item.id)) return acc.concat(item);
    return acc;
  }, []);

  const finFiltered = charges[companyId]?.reduce((acc, item) => {
    if (finIdsArr.includes(item.id)) return acc.concat(item);
    return acc;
  }, []);

  const estPrepared = useMemo(() => {
    return charges[jobInfo.company_id].reduce((acc, e) => {
      if (jobCharges['estimated'].find((k) => k.charge_id === e.id)) return acc.concat(e);
      return acc;
    }, []);
  }, [charges, jobInfo.company_id, jobCharges]);

  const finPrepared = useMemo(() => {
    return charges[jobInfo.company_id].reduce((acc, e) => {
      if (jobCharges['estimated'].find((k) => k.charge_id === e.id)) return acc.concat(e);
      return acc;
    }, []);
  }, [charges, jobInfo.company_id, jobCharges]);

  const deleteHandler = useCallback(
    (insuranceId, type) => {
      const isFinal = type === 'final';
      const data = isFinal ? finPrepared : estPrepared;
      const preparedData = data.filter((item) => item.insurance_id !== insuranceId);

      updateCharges({
        job_id: id,
        job_charges: {
          estimated: isFinal ? jobCharges['estimated'] : preparedData,
          final: preparedData,
        },
      });
    },
    [finPrepared, estPrepared, updateCharges, id, jobCharges]
  );

  return (
    <Fragment>
      <div className="new-table--row bcg-light">
        <div className="new-table--row--item justify-content-center w40">
          <b>Charges</b>
        </div>
        <div className="new-table--row--item w30 justify-content-center no-padding">
          <button
            className="btn p20 flex-grow-1 d-flex text-center"
            onClick={() => open(modalNames.charges, { type: 'estimated', companyId, jobCharges })}
          >
            <Icon icon="add" size={16} />
          </button>
        </div>
        <div className="new-table--row--item w30 justify-content-center no-padding">
          <button
            className="btn p20 flex-grow-1 d-flex text-center"
            onClick={() => open(modalNames.charges, { type: 'final', companyId, jobCharges })}
          >
            <Icon icon="add" size={16} />
          </button>
        </div>
      </div>

      {(estFiltered.length !== 0 || finFiltered.length !== 0) && (
        <Fragment>
          <div className="new-table--row">
            <div className="new-table--row--item justify-content-center w40" />
            <div className="new-table--row--item no-side-shadows w60 no-padding align-items-start">
              {estFiltered.length ? (
                <div className="new-table--row--item w50 no-padding flex-direction-column">
                  {estFiltered.map((item) => (
                    <div className="new-table--row with-shadow" key={item.id}>
                      <div className="new-table--row--item w70 justify-content-center no-side-shadows">
                        <button onClick={() => deleteHandler(item.id, 'estimated')}>
                          <Icon size={20} icon="delete" title={`Delete estimated charges`} />
                        </button>
                        {item.name}
                      </div>
                      <div className="new-table--row--item w30 justify-content-center no-side-shadows">
                        <b>${item.amount}</b>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="new-table--row--item no-side-shadows w50 no-padding flex-direction-column" />
              )}

              {finFiltered.length ? (
                <div className="new-table--row--item w50 no-padding flex-direction-column">
                  {finFiltered.map((item) => (
                    <div className="new-table--row with-shadow" key={item.id}>
                      <div className="new-table--row--item w70 justify-content-center no-side-shadows">
                        <button onClick={() => deleteHandler(item.id, 'final')}>
                          <Icon size={20} icon="delete" title={`Delete final charges`} />
                        </button>
                        {item.name}
                      </div>
                      <div className="new-table--row--item w30 justify-content-center no-side-shadows">
                        <b>${item.amount}</b>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="new-table--row--item no-side-shadows w50 no-padding flex-direction-column" />
              )}
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default Charges;

import React, { Component } from 'react';
import Icon from '../Icon';

import './DropdownMenu.scss';

class DropdownMenu extends Component {
  constructor(props) {
    super(props);
    this.dropdownBoxRef = React.createRef();
    this.state = {
      isMenuVisible: false,
      isChildMenuVisible: false,
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  toggleMenu = (e) => {
    e.stopPropagation();
    this.setState((prevState) => ({ isMenuVisible: !prevState.isMenuVisible }));
  };

  handleClickOutside = (event) => {
    if (this.dropdownBoxRef && !this.dropdownBoxRef.current.contains(event.target) && this.state.isMenuVisible) {
      this.toggleMenu(event);
    }
  };

  render() {
    const {
      button,
      menu,
      dontHide = false,
      listClass,
      options,
      optionOnClick,
      horizontal = false,
      counters,
      counterOnClick,
      detailsInfo,
      divClassName,
      listStyle,
    } = this.props;

    const { isMenuVisible, isChildMenuVisible } = this.state;
    return (
      <div ref={this.dropdownBoxRef} className={`dropdown-menu--wrapper ${divClassName || ''}`}>
        <button
          type="button"
          className={`${button.className ?? ''} ${counters?.length > 0 ? 'menu-button' : ''}`}
          onClick={(e) => {
            this.toggleMenu(e);
            button.onClick && button.onClick();
          }}
        >
          {button.iconName && <Icon icon={button.iconName} size={button.iconSize} className={button.iconClassName} title={button.iconTitle} />}
          <span className={button.spanClassName}>{button.spanTitle}</span>
        </button>
        {isMenuVisible && (
          <div
            className={`menu ${listClass || ''} ${horizontal ? 'flex-column' : ''}`}
            style={listStyle}
            onClick={(e) => !dontHide && this.toggleMenu(e)}
          >
            {menu && menu}
            {options?.map((option, index) => (
              <button
                key={index}
                type="button"
                className={option.buttonClassName}
                onClick={() => {
                  if (option.clickValue) {
                    optionOnClick(option.clickValue);
                  } else {
                    if (option.clickFunc) option.clickFunc();
                  }
                }}
                onMouseEnter={() => {
                  if (option.childOptions) this.setState({ isChildMenuVisible: true });
                }}
                onMouseLeave={() => {
                  if (option.childOptions) this.setState({ isChildMenuVisible: false });
                }}
              >
                {option.iconName && <Icon icon={option.iconName} size={option.iconSize} title={option.iconTitle} />}
                {option?.buttonText}

                {option.childOptions && isChildMenuVisible && (
                  <div
                    className={`menu child-option ${listClass || ''} ${horizontal ? 'flex-column' : ''}`}
                    style={listStyle}
                    onClick={(e) => !dontHide && this.toggleMenu(e)}
                  >
                    {option.childOptions.map((option, index) => (
                      <div
                        key={index}
                        type="button"
                        className={`${option.buttonClassName}`}
                        onClick={() => {
                          if (option.clickValue) {
                            optionOnClick(option.clickValue);
                          } else {
                            if (option.clickFunc) option.clickFunc();
                          }
                        }}
                      >
                        {option.iconName && <Icon icon={option.iconName} size={option.iconSize} title={option.iconTitle} />}
                        {option?.buttonText}
                      </div>
                    ))}
                  </div>
                )}
              </button>
            ))}
            {counters?.map((item, index) => (
              <button
                key={index}
                className={item.className}
                onClick={() => {
                  if (item.clickValue) {
                    counterOnClick(item.clickValue);
                  } else {
                    item.onClick();
                  }
                }}
              >
                {item.text}
                {item.counterValue !== null && <span className={`counter ${item.counterClassName || ''}`}>{item.counterValue}</span>}
              </button>
            ))}
            {detailsInfo?.length > 0 &&
              detailsInfo
                .filter((item) => item.value)
                .map((item, index) => (
                  <p
                    key={index}
                    className="link"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      if (item.clickFunc) item.clickFunc();
                    }}
                  >
                    {item.name}: {item.value}
                  </p>
                ))}
          </div>
        )}
      </div>
    );
  }
}

export default DropdownMenu;

import React from 'react';
import { Elements } from 'react-stripe-elements';
import InjectedCheckoutForm from './CheckoutForm';

function MyStoreCheckout({ data, payment }) {
  return (
    <Elements>
      <InjectedCheckoutForm data={data} payment={payment} />
    </Elements>
  );
}

export default MyStoreCheckout;

import React, { useCallback } from 'react';
import Draggable from 'react-draggable';
import { useDefault } from 'hooks/query/useCommon';
import { useModal } from 'context/modal';
import Icon from 'components/Icon';

const CallScript = (props) => {
  const { close, modalNames } = useModal();

  const closeHandler = useCallback(() => close(modalNames.callScript), [close, modalNames.callScript]);

  const { data: defaultItems } = useDefault();

  return (
    <Draggable bounds="parent" grid={[25, 25]}>
      <div style={{ width: 300, position: 'absolute' }} className="call-scripts-modal">
        <div className="modal-pages">
          <h3 className="header">Script</h3>
          <button onClick={closeHandler}>
            <Icon rotateDeg={45} icon="add" size={28} />
          </button>
        </div>

        <div className="p-3">{defaultItems?.call_script}</div>
      </div>
    </Draggable>
  );
};

export default CallScript;

import React, { Fragment, Component } from 'react';
import Icon from 'components/Icon';

const ONE_CUBIC_FEET = 62.41;

class QuickView extends Component {
  render() {
    const items = this.props.job_inventory.items.map((item) => {
      return (
        <div className="new-table--row" key={item.id}>
          <div className="new-table--row--item w40">
            <span>{item.name}</span>
          </div>
          <div className="new-table--row--item w20 text-center fw-700">
            <input
              type="text"
              name="width"
              value={item.dimension?.width || 0}
              maxLength="2"
              className="text-center py-0"
              onChange={(e) => e}
              placeholder="W"
              disabled
            />
            <input
              type="text"
              name="length"
              value={item.dimension?.length || 0}
              maxLength="2"
              className="text-center py-0"
              onChange={(e) => e}
              placeholder="L"
              disabled
            />
            <input
              type="text"
              name="height"
              value={item.dimension?.height || 0}
              maxLength="2"
              className="text-center py-0"
              onChange={(e) => e}
              placeholder="H"
              disabled
            />
          </div>
          <div className="new-table--row--item w10 text-center fw-700">
            <span>{item.amount}</span>
          </div>
          <div className="new-table--row--item w10 text-center fw-700">
            <input
              type="text"
              maxLength="3"
              value={item.qty}
              className="text-center py-0"
              name=""
              onChange={(e) => this.props.changeQty(e.target.value, item.id)}
            />
          </div>
          <div className="new-table--row--item w10 text-center fw-700">
            <span>{Number(item.qty) * Number(item.amount)}</span>
          </div>
          <div className="new-table--row--item w10 justify-content-center">
            <button type="button" onClick={() => this.props.handleDeleteItem(item.id)} style={{ height: 20 }}>
              <Icon icon="delete" size={20} title="Delete" style={{ marginRight: 0 }} />
            </button>
          </div>
        </div>
      );
    });

    const totalVolumeCF = this.props.job_inventory.items.reduce((sum, item) => sum + parseInt(item.amount) * parseInt(item.qty), 0);
    const cfToPounds = (totalVolumeCF * ONE_CUBIC_FEET).toFixed(2);

    return (
      <Fragment>
        <div className="new-table">
          <div className="new-table--row">
            <div className="new-table--row--item fw-700">
              <span>Quick view</span>
            </div>
          </div>
          <div className="new-table--row">
            <div className="new-table--row--item light no-shadow w40 fw-700">
              <span>Name</span>
            </div>
            <div className="new-table--row--item light w20 no-shadow text-center fw-700">
              <span>W x L x H</span>
            </div>
            <div className="new-table--row--item light w10 no-shadow text-center fw-700">
              <span>CF</span>
            </div>
            <div className="new-table--row--item light w10 no-shadow text-center fw-700">
              <span>Qty</span>
            </div>
            <div className="new-table--row--item light w10 no-shadow text-center fw-700">
              <span>Total</span>
            </div>
            <div className="new-table--row--item light w10 no-shadow" />
          </div>

          {items}
        </div>

        <div className="new-table mt-3">
          <div className="new-table--row">
            <div className="new-table--row--item">
              <span>Total CF per Inventory:</span>
            </div>
            <div className="new-table--row--item light fw-700">
              <span>{totalVolumeCF}</span>
            </div>
          </div>
          <div className="new-table--row">
            <div className="new-table--row--item">
              <span>CF To Pound:</span>
            </div>
            <div className="new-table--row--item light fw-700">
              <span>{cfToPounds} Lbs.</span>
            </div>
          </div>
        </div>

        <div className="buttons">
          <button type="button" className="cancel" onClick={this.props.handleCancelButton}>
            Cancel
          </button>
          <button type="button" className="confirm" disabled={false} onClick={this.props.handleSave}>
            Save
          </button>
        </div>
      </Fragment>
    );
  }
}

export default QuickView;

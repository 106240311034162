import React from 'react';
import Select from 'components/newComponents/Select';
import { optionsReferral } from 'helpers/PaymentData';
import { useForm } from '../../../hooks/useForm';
import { getLeadProviderOptions } from '../../../utils';

function HearFrom({ leadProviders }) {
  const { values, actions } = useForm();

  return (
    <div className="new-table--row">
      <div className="new-table--row--item select">
        <Select
          placeholder="* Hear from"
          name="hear_about"
          options={optionsReferral}
          error={actions.isValidHandler('hear_about')}
          value={values['hear_about']}
          onChange={({ value }) => actions.setFieldValue('hear_about', value)}
        />
      </div>

      <div className="new-table--row--item select">
        <Select
          placeholder="* Lead Provider"
          name="lead_provider_id"
          options={getLeadProviderOptions(leadProviders)}
          value={values['lead_provider_id']}
          onChange={({ value }) => actions.setFieldValue('lead_provider_id', value)}
        />
      </div>
    </div>
  );
}

export default HearFrom;

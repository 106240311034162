import axios from 'axios';

class Api {
  constructor() {
    this.serverApi = process.env.REACT_APP_API_URL;
    this.client = axios.create({ baseURL: this.serverApi });
  }

  getData = async (job_id) => {
    try {
      return await this.client.get('/api/customer/getInfoPageData', { params: { job_id } });
    } catch (e) {
      console.log(e);
    }
  };
}

export default new Api();

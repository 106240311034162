import React, { Fragment, memo, useCallback } from 'react';
import { Link, NavLink, generatePath, useParams, useLocation } from 'react-router-dom';
import { routePaths } from 'config/routes';
import Icon from 'components/Icon';
import { useInfo } from 'hooks/query/useJob';
import { useModal } from 'context/modal';
import { updateRouteParams } from 'utils/history';

const LinkComponent = memo(({ to, name, title }) => {
  return (
    <NavLink to={to} className="menu-tab" activeClassName="menu-tab menu-tab__selected">
      <Icon icon={name} title={title} />
    </NavLink>
  );
});

const JobInfoTabMenu = memo(({ SelectedJob }) => {
  const params = useParams();
  const { search } = useLocation();

  const { data } = useInfo(params.id);

  const { long_delivery_job, pickup_job, storage_delivery_job, storage_job } = data?.related_jobs;
  const isAllJob = data?.job_info?.storage_id === 0;

  const { modalNames, open } = useModal();

  const getPath = useCallback(
    (tab) => {
      return `${generatePath(routePaths.JOBS, { ...params, tab })}${search}`;
    },
    [params, search]
  );

  const openModal = useCallback(() => {
    open(modalNames.jobUpdateToStorage, {
      jobId: params.id,
      tab: params.tab,
      companyId: data?.job_info?.company_id,
      requiredPickup: data?.dates?.required_pickup,
    });
  }, [data, params.id, params.tab, modalNames.jobUpdateToStorage, open]);

  return (
    <Fragment>
      {isAllJob ? (
        <div className="new-table--row--item w5 no-padding-y table-border-right flex-grow-0 mr-auto">
          <button type="button" onClick={openModal}>
            <Icon icon="storage" title="Add Storage" style={{ margin: 0 }} />
          </button>
        </div>
      ) : (
        <div className="new-table--row">
          {!!long_delivery_job && (
            <div className="new-table--row--item w5 table-border-right no-padding-y flex-grow-0">
              <Link to={updateRouteParams(routePaths.JOBS, { id: long_delivery_job, type: 'delivery' })}>
                <Icon icon="storage_job_delivery" title="Long Delivery Job" style={{ margin: 0 }} />
              </Link>
            </div>
          )}
          {!!pickup_job && (
            <div className="new-table--row--item w5 table-border-right no-padding-y flex-grow-0">
              <Link to={updateRouteParams(routePaths.JOBS, { id: pickup_job, type: 'pickup' })}>
                <Icon icon="storage_job_pickup" title="Pickup Job" style={{ margin: 0 }} />
              </Link>
            </div>
          )}
          {!!storage_delivery_job && (
            <div className="new-table--row--item w5 table-border-right no-padding-y flex-grow-0">
              <Link to={updateRouteParams(routePaths.JOBS, { id: storage_delivery_job, type: 'delivery' })}>
                <Icon icon="storage_job_delivery" title="Storage Delivery Job" style={{ margin: 0 }} />
              </Link>
            </div>
          )}
          {!!storage_job && (
            <div className="new-table--row--item w5 table-border-right no-padding-y flex-grow-0">
              <Link to={updateRouteParams(routePaths.JOBS, { id: storage_job, type: 'storage' })}>
                <Icon icon="storage_job_storage" title="Storage Job" style={{ margin: 0 }} />
              </Link>
            </div>
          )}
        </div>
      )}

      <div className="new-table--row--item w20 no-padding flex-grow-0">
        <LinkComponent to={getPath('general')} title="General" name="general" />
        <LinkComponent to={getPath('inventory')} title="Inventory" name="inventory" />
        <LinkComponent to={getPath('rate')} title="Fees" name="rate" />
        <LinkComponent to={getPath('exp')} title="Exp" name="exp" />
      </div>
    </Fragment>
  );
});

export default JobInfoTabMenu;

import React, { useState, useEffect, useCallback } from 'react';
import SinglePoint from 'components/GoogleMap/SinglePoint';
import { useDistanceForm } from 'hooks/query/useCommon';
import { useDefault } from 'hooks/query/useCommon';
import { findBuilding } from 'helpers/buildings';
import { useModal } from 'context/modal';
import Icon from 'components/Icon';
import Input from 'components/newComponents/Input';
import CheckBox from 'components/newComponents/CheckBox';
import Autocomplete from 'components/newComponents/Autocomplete';
import { useTimeById } from '../../../../hooks/query/useBuilding';

export const BuildingInfo = ({ handleChange, values, setFieldValue, isValidHandler }) => {
  const { data: defaultItems } = useDefault();
  const { mutate: distanceForm } = useDistanceForm();
  const { mutateAsync: buildingTimeById } = useTimeById();
  const { modalNames, open } = useModal();

  const [fromEstTime, setFromEstTime] = useState(null);
  const [toEstTime, setToEstTime] = useState(null);

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const zipFrom = values.from_zipcode;
    const zipTo = values.to_zipcode;
    const companyId = values.company_id || 1;

    if (zipFrom?.replace(/\D/g, '')?.length === 5 && zipTo?.replace(/\D/g, '')?.length === 5) {
      let params = {
        from: zipFrom,
        to: zipTo,
        company_id: companyId,
      };

      distanceForm(params, {
        onSuccess: ({ data }) => {
          setFieldValue('drop_off_to_office', data?.drop_off_to_office);
          setFieldValue('pickup_distance', data?.pickup_distance);
          setFieldValue('travel_distance', data?.travel_distance);
        },
      });
    }
  }, [distanceForm, setFieldValue, values.company_id, values.from_zipcode, values.to_zipcode]);

  const setSameBuildingsHandler = useCallback(() => {
    setFieldValue('to_name', values.from_name);
    setFieldValue('to_email', values.from_email);
    setFieldValue('to_home_phone', values.from_home_phone);
    setFieldValue('to_cell_phone', values.from_cell_phone);
    setFieldValue('to_name', values.from_name);
    setFieldValue('to_address', values.from_address);
    setFieldValue('to_building_name', values.from_building_name);
    setFieldValue('to_zipcode', values.from_zipcode);
    setFieldValue('to_city', values.from_city);
    setFieldValue('to_state', values.from_state);
    setFieldValue('full_to_address', values.full_from_address);
  }, [
    setFieldValue,
    values.from_address,
    values.from_building_name,
    values.from_cell_phone,
    values.from_city,
    values.from_email,
    values.from_home_phone,
    values.from_name,
    values.from_state,
    values.from_zipcode,
    values.full_from_address,
  ]);

  useEffect(() => {
    if (values.same_building === 1) setSameBuildingsHandler();
  }, [setSameBuildingsHandler, values.same_building]);

  const deleteDiscountHandler = useCallback(
    (id) => {
      setFieldValue(
        'job_discounts',
        values.job_discounts.filter((e) => e.discount_id !== id)
      );
    },
    [setFieldValue, values.job_discounts]
  );

  const deleteChargeHandler = useCallback(
    (id) => {
      setFieldValue(
        'job_charges',
        values.job_charges.filter((e) => e.charges_id !== id)
      );
    },
    [setFieldValue, values.job_charges]
  );

  const onSelectAutoCompleteHandler = useCallback(
    async (address, name) => {
      Object.keys(address).forEach((key) => {
        if (key === 'address') {
          setFieldValue(`${name}_${key}`, address['street_number'] + ' ' + address[key]);
        } else {
          setFieldValue(`${name}_${key}`, address[key]);
        }
      });
      const building = findBuilding(defaultItems?.building_info, address);

      setFieldValue(`${name}_building_name`, building?.building_name || '');
      setFieldValue(`${name}_required_coi`, !!building);
      setFieldValue(`${name}_building_id`, building?.id || 0);
      setFieldValue(`${name}_place_id`, address.place_id);
      setFieldValue(`building_type_${name}`, building?.building_type);
      getBuildingTimeById(name === 'to' ? 2 : 1, building?.id);
    },
    [defaultItems.building_info, setFieldValue]
  );

  const getBuildingTimeById = useCallback(
    async (type, id) => {
      const { company_id, move_size_id } = values;
      if (!company_id || !move_size_id || !id) return;

      const data = {
        building_id: id,
        company_id,
        move_size_id,
        move_type: type,
      };

      await buildingTimeById(data, { onSuccess: ({ data }) => (type === 1 ? setFromEstTime(data) : setToEstTime(data)) });
    },
    [buildingTimeById, values]
  );

  return (
    <div className="new-table modal">
      <div className="new-table--row">
        <div className="new-table--row--item">
          <span>From</span>
        </div>
        <div className="new-table--row--item">
          <span>To</span>
        </div>
      </div>
      <div className="new-table--row">
        <div className="new-table--row--item">
          <Icon icon="name" title="Name" />
          <Input
            name="from_name"
            value={values.from_name}
            onChange={(e) => {
              setFieldValue('from_name', e.target.value);
              setFieldValue('to_name', e.target.value);
            }}
            error={isValidHandler('from_name')}
            placeholder="Name"
          />
        </div>
        <div className="new-table--row--item">
          <Icon icon="name" title="Name" />
          <Input name="to_name" value={values.to_name} onChange={handleChange} error={isValidHandler('to_name')} placeholder="Name" />
        </div>
      </div>

      <div className="new-table--row">
        <div className="new-table--row--item">
          <Icon icon="email" title="Email" />
          <Input
            name="from_email"
            value={values.from_email}
            onChange={(e) => {
              setFieldValue('from_email', e.target.value);
              setFieldValue('to_email', e.target.value);
            }}
            error={isValidHandler('from_email')}
            placeholder="Email"
          />
        </div>
        <div className="new-table--row--item">
          <Icon icon="email" title="Email" />
          <Input name="to_email" value={values.to_email} onChange={handleChange} error={isValidHandler('to_email')} placeholder="Email" />
        </div>
      </div>

      <div className="new-table--row">
        <div className="new-table--row--item">
          <Icon icon="home_phone" title="Home Phone" />
          <Input
            name="from_home_phone"
            mask="111-111-1111"
            value={values.from_home_phone}
            onChange={(e) => {
              setFieldValue('from_home_phone', e.target.value);
              setFieldValue('to_home_phone', e.target.value);
            }}
            error={isValidHandler('from_home_phone')}
          />
        </div>
        <div className="new-table--row--item">
          <Icon icon="home_phone" title="Home Phone" />
          <Input
            name="to_home_phone"
            mask="111-111-1111"
            value={values.to_home_phone}
            onChange={handleChange}
            error={isValidHandler('to_home_phone')}
          />
        </div>
      </div>
      <div className="new-table--row">
        <div className="new-table--row--item">
          <Icon icon="cell_phone" title="Cell Phone" />
          <Input
            name="from_cell_phone"
            mask="111-111-1111"
            value={values.from_cell_phone}
            onChange={(e) => {
              setFieldValue('from_cell_phone', e.target.value);
              setFieldValue('to_cell_phone', e.target.value);
            }}
          />
        </div>
        <div className="new-table--row--item">
          <Icon icon="cell_phone" title="Cell Phone" />
          <Input name="to_cell_phone" mask="111-111-1111" value={values.to_cell_phone} onChange={handleChange} />
        </div>
      </div>

      <div className="new-table--row">
        <div className="new-table--row--item no-padding">
          <div className="new-table--row--item">
            <Icon icon="address" title="Address" />
            <Autocomplete
              name="from"
              placeholder="Search address"
              value={values['from_address']}
              // error={isValidHandler('from_address')}
              onChange={(value) => setFieldValue('from_address', value)}
              onSelect={onSelectAutoCompleteHandler}
            />
          </div>
          <div className="new-table--row--item w10 no-shadow">
            <Input name="from_apartment" placeholder="apt" value={values.from_apartment} onChange={handleChange} />
          </div>
        </div>
        <div className="new-table--row--item no-padding">
          <div className="new-table--row--item">
            <Icon icon="address" title="Address" />
            <Autocomplete
              name="to"
              placeholder="Search address"
              value={values['to_address']}
              // error={isValidHandler('to_address')}
              onChange={(value) => setFieldValue('to_address', value)}
              onSelect={onSelectAutoCompleteHandler}
            />
          </div>
          <div className="new-table--row--item w10 no-shadow">
            <Input name="to_apartment" placeholder="apt" value={values.to_apartment} onChange={handleChange} />
          </div>
        </div>
      </div>

      <div className="new-table--row">
        <div className="new-table--row--item">
          <Icon icon="building_name" title="Building name" />
          <Input name="from_building_name" placeholder="Building name" value={values.from_building_name} onChange={handleChange} />
        </div>
        <div className="new-table--row--item">
          <Icon icon="building_name" title="Building name" />
          <Input name="to_building_name" placeholder="Building name" value={values.to_building_name} onChange={handleChange} />
        </div>
      </div>

      <div className="new-table--row">
        <div className="new-table--row">
          <div className="new-table--row--item">
            <Icon icon="zip" title="Zip code" />
            <Input
              name="from_zipcode"
              placeholder="Zip"
              mask="11111"
              value={values.from_zipcode}
              onChange={handleChange}
              error={isValidHandler('from_zipcode')}
            />
          </div>
          <div className="new-table--row--item">
            <Input name="from_state" placeholder="State" value={values.from_state} disabled />
          </div>
        </div>

        <div className="new-table--row">
          <div className="new-table--row--item">
            <Icon icon="zip" title="Zip code" />

            <Input
              name="to_zipcode"
              placeholder="Zip"
              mask="11111"
              value={values.to_zipcode}
              onChange={handleChange}
              error={isValidHandler('to_zipcode')}
            />
          </div>
          <div className="new-table--row--item">
            <Input name="to_state" placeholder="State" value={values.to_state} disabled />
          </div>
        </div>
      </div>

      <div className="new-table--row">
        <div className="new-table--row--item">
          <Icon icon="city" title="City" />
          <Input name="from_city" placeholder="City" value={values.from_city} disabled />
        </div>
        <div className="new-table--row--item">
          <Icon icon="city" title="City" />
          <Input name="to_city" placeholder="City" value={values.to_city} disabled />
        </div>
      </div>
      <div className="new-table--row">
        <div className="new-table--row--item no-padding">
          <button type="button" onClick={() => setIsVisible((pre) => !pre)} className="main p20 flex-grow-1">
            Google map
          </button>
        </div>
      </div>

      {isVisible && (
        <div className="new-table--row">
          {values.full_from_address && <SinglePoint title="From" marker={values.full_from_address} />}
          {values.full_to_address && <SinglePoint title="To" marker={values.full_to_address} />}
        </div>
      )}

      <div className="new-table--row">
        <div className="new-table--row--item">
          <CheckBox
            className="justify-content-between"
            name="same_building"
            label="Move to same building?"
            checked={values['same_building'] === 1}
            onChange={({ target }) => setFieldValue('same_building', target.checked ? 1 : 0)}
          />
        </div>
        <div className="new-table--row--item">
          <CheckBox
            className="justify-content-between"
            name="extra_stop"
            label="Extra stop?"
            checked={values['extra_stop'] === 1}
            onChange={({ target }) => setFieldValue('extra_stop', target.checked ? 1 : 0)}
          />
        </div>
      </div>

      <div className="new-table--row">
        {fromEstTime && (
          <div className="new-table--row--item new-table--row--item__column align-items-start">
            <p>
              Estimated building time{' '}
              <b title="min/avg/max">
                {fromEstTime.building.min}/{fromEstTime.building.avg}/{fromEstTime.building.max}
              </b>
            </p>
            <p>
              Jobs <b> {fromEstTime.building.job_count}</b>
            </p>

            <p>
              Estimated move size time
              <b title="min/avg/max">
                {fromEstTime.move_size.min}/{fromEstTime.move_size.avg}/{fromEstTime.move_size.max}
              </b>
            </p>
            <p>
              Jobs <b> {fromEstTime.move_size.job_count}</b>
            </p>
          </div>
        )}
        {toEstTime && (
          <div className="new-table--row--item new-table--row--item__column align-items-start">
            <p>
              Estimated building time{' '}
              <b title="min/avg/max">
                {toEstTime.building.min}/{toEstTime.building.avg}/{toEstTime.building.max}
              </b>
            </p>
            <p>
              Jobs <b> {toEstTime.building.job_count}</b>
            </p>
            <p>
              Estimated move size time
              <b title="min/avg/max">
                {toEstTime.move_size.min}/{toEstTime.move_size.avg}/{toEstTime.move_size.max}
              </b>
            </p>
            <p>
              Jobs <b> {toEstTime.move_size.job_count}</b>
            </p>
          </div>
        )}
      </div>

      <div className="new-table--row">
        <div className="new-table--row--item flex-column">
          <div className="item-row">
            <span>Estimated Travel Distance is</span>
            <b>{values?.travel_distance} miles.</b>
          </div>
          <div className="item-row">
            <span>Distance from Office to PickUp is</span>
            <b>{values?.pickup_distance} miles.</b>
          </div>
          <div className="item-row">
            <span>Distance from DropOff to Office is</span>
            <b>{values?.drop_off_to_office} miles.</b>
          </div>
        </div>
        <div className="new-table--row--item flex-column">
          <div className="item-row">
            <span>Estimated labor is</span>
            <b>{values?.estimated_time} h</b>
          </div>
          <div className="item-row">
            <span>Estimated pickup time is</span>
            <b>{values?.estimated_manual_pickup_time}</b>
          </div>
          <div className="item-row">
            <span>Estimated travel time is</span>
            <b>{values?.estimated_manual_travel_time}</b>
          </div>
          <div className="item-row">
            <span>Estimated summary is</span>
            <b>{+values?.estimated_time + +values?.estimated_manual_pickup_time + +values?.estimated_manual_travel_time} h</b>
          </div>
        </div>
      </div>

      <div className="new-table--row">
        <div className="new-table--row--item new-table--row--item__column no-padding">
          <div className="new-table--row mh60">
            <div className="new-table--row--item no-shadow">
              <button
                type="button"
                className="default"
                disabled={!values.company_id}
                onClick={() => {
                  open(modalNames.discount, {
                    type: 'from',
                    companyId: values.company_id,
                    jobDiscounts: { from: values['job_discounts'] },
                    onSuccess: (data) => setFieldValue('job_discounts', data),
                  });
                }}
              >
                <Icon icon="add" size={18} color="#ccc" />
                Discount
              </button>
            </div>
            <div className="new-table--row--item no-shadow">
              <b>Amount</b>
            </div>
          </div>
          {values.job_discounts.map((item) => {
            const discountItem = defaultItems.discounts[values.company_id || 1].find((el) => el.id === item.discount_id);
            return (
              <div className="new-table--row mh60" key={item.discount_id}>
                <div className="new-table--row--item">
                  <button type="button" onClick={() => deleteDiscountHandler(item.discount_id)}>
                    <Icon icon="delete" size={18} />
                  </button>
                  <span>{discountItem.discount_name}</span>
                </div>
                <div className="new-table--row--item">
                  <b>{discountItem.amount}</b>
                </div>
              </div>
            );
          })}
        </div>
        <div className="new-table--row--item new-table--row--item__column no-padding">
          <div className="new-table--row mh60">
            <div className="new-table--row--item no-shadow">
              <button
                type="button"
                className="default"
                disabled={!values.company_id}
                onClick={() => {
                  open(modalNames.charges, {
                    type: 'from',
                    companyId: values.company_id,
                    jobCharges: { from: values['job_charges'] },
                    onSuccess: (data) => setFieldValue('job_charges', data),
                  });
                }}
              >
                <Icon icon="add" size={18} color="#ccc" />
                Charges
              </button>
            </div>
            <div className="new-table--row--item no-shadow">
              <b>Amount</b>
            </div>
          </div>

          {values.job_charges?.map((item) => {
            const chargeItem = defaultItems.charges[values.company_id || 1].find((el) => el.id === item.charges_id);
            return (
              <div className="new-table--row mh60" key={item.charges_id}>
                <div className="new-table--row--item">
                  <button type="button" onClick={() => deleteChargeHandler(item.charges_id)}>
                    <Icon icon="delete" size={18} />
                  </button>
                  <span>{chargeItem.name}</span>
                </div>
                <div className="new-table--row--item">
                  <b>{chargeItem.amount}</b>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

import React, { useMemo } from 'react';
import { Formik } from 'formik';
import { ModalButton } from 'components/newComponents/Buttons';
import Preloader from 'components/Preloader';
import PrimaryForm from './PrimaryForm';
import SecondaryForm from './SecondaryForm';
import { useSubmit } from '../hooks/useSubmit';
import { useFormTab } from '../hooks/useFormTab';
import { useGetData } from '../hooks/useGetData';
import { prepareInitialValues } from '../utils';
import { validationSchema } from '../validationSchema';

function Main(props) {
  const { params, closeHandler } = props;

  const phoneNumber = params?.phone;
  const companyID = params?.companyID;
  const callId = params?.callId;
  const chatId = params?.chatId;

  const { isDataLoading, ...data } = useGetData();

  const initialValues = useMemo(() => {
    return prepareInitialValues({ companyID, phoneNumber, callId, chatId }, data.lead_providers);
  }, [companyID, phoneNumber, chatId, callId, data.lead_providers]);

  const { submit, isMutatationRunning } = useSubmit({ closeModal: closeHandler });

  const { actions, isMain } = useFormTab();

  return (
    <Formik validateOnMount={true} initialValues={initialValues} validationSchema={validationSchema} onSubmit={submit}>
      {({ handleSubmit, isValid }) => (
        <form onSubmit={handleSubmit}>
          {(isMutatationRunning || isDataLoading) && <Preloader />}

          <div className="quickform">{isMain ? <PrimaryForm data={data} /> : <SecondaryForm />}</div>

          <div className="footer-buttons">
            <ModalButton title={isMain ? 'Details' : 'Back'} variant="default" disabled={isMutatationRunning} onClick={actions.toggleTab} />
            <ModalButton title="Cancel" variant="cancel" disabled={isMutatationRunning} onClick={closeHandler} />
            <ModalButton
              title="Create Job"
              variant="confirm"
              type="submit"
              disabled={isMutatationRunning}
              onClick={() => actions.handleTabOnSubmit(isValid)}
            />
          </div>
        </form>
      )}
    </Formik>
  );
}

export default Main;

import React, { useMemo, useCallback } from 'react';
import { useLocation, matchPath } from 'react-router';
import { useModal } from 'context/modal';
import { useAuth } from 'context/auth';
import { useDefault } from 'hooks/query/useCommon';
import history from 'utils/history';
import DropdownMenu from 'components/DropdownMenu';
import Icon from 'components/Icon';
import routes, { routePaths } from 'config/routes';

const NavbarLeftSide = ({ counterByType }) => {
  const { open, modalNames } = useModal();
  const { user } = useAuth();
  const { pathname, search } = useLocation();

  const { data: defaultItems } = useDefault();
  const path = matchPath(pathname, { path: routePaths.JOBS });

  const params = useMemo(() => {
    return path?.params || null;
  }, [path]);

  const getJobList = useCallback(
    (type) => {
      const to = `${routes.JOBS}/${type}`;
      const currentLocation = pathname;

      if (currentLocation === to && !search) {
        window.location.reload(false);
        return;
      }

      history.push({ pathname: to });
    },
    [pathname, search]
  );

  const getJobListByCompanyId = useCallback(
    (id) => {
      const to = `${routes.JOBS}/all?company_id=${id}`;
      const currentLocation = pathname + search;

      if (currentLocation === to) {
        window.location.reload(false);
        return;
      }

      history.push(to);
    },
    [pathname, search]
  );

  const reports = (type) => history.push({ pathname: `${routes.REPORTS}/${type}` });
  const available = () => history.push({ pathname: `${routes.AVAILABLE}` });

  const type = ['local', 'long', 'pickup', 'storage', 'delivery', 'sold'];

  const isActive = useMemo(() => {
    if (!params) return null;
    return type.some((el) => el === params?.type);
  }, [params, type]);

  const selectCompanies = useMemo(() => {
    const list = [{ buttonClassName: 'link', buttonText: 'All', clickValue: 'all', clickFunc: getJobList }];
    if (!defaultItems) return list;

    return list.concat(
      defaultItems.company.map((company) => ({
        buttonClassName: 'link',
        buttonText: company.company_name,
        clickFunc: () => getJobListByCompanyId(company.id),
      }))
    );
  }, [defaultItems, getJobListByCompanyId, getJobList]);

  return (
    <ul className="navbar-menu">
      <li className="navbar-menu--item">
        <DropdownMenu
          button={{
            iconName: 'general',
            iconTitle: 'Jobs',
            className: `menu-button ${isActive ? 'isActive' : ''}`,
            spanClassName: 'counter',
            spanTitle: counterByType?.new,
          }}
          optionOnClick={getJobList}
          options={[
            { buttonClassName: 'link', clickValue: 'all', buttonText: 'All Jobs' },
            { buttonClassName: 'link', buttonText: 'Select company', childOptions: selectCompanies },
            { buttonClassName: 'link', clickValue: 'all-my_jobs_tasks', buttonText: 'My Tasks' },
            { buttonClassName: 'link', clickValue: 'all-my_jobs', buttonText: 'My Jobs' },
            { buttonClassName: 'link', clickValue: 'new', buttonText: 'New Jobs' },
            { buttonClassName: 'link', clickValue: 'local', buttonText: 'Local' },
            { buttonClassName: 'link', clickValue: 'long', buttonText: 'Long Distance' },
            { buttonClassName: 'link', clickValue: 'pickup', buttonText: 'Storage - Pickup' },
            { buttonClassName: 'link', clickValue: 'storage', buttonText: 'Storage - Storage' },
            { buttonClassName: 'link', clickValue: 'delivery', buttonText: 'Storage - Delivery' },
            { buttonClassName: 'link', clickValue: 'sold', buttonText: 'Sold' },
          ]}
        />
      </li>
      <li className="navbar-menu--item">
        <DropdownMenu
          button={{ iconName: 'add', iconTitle: 'New Job', className: 'menu-button' }}
          options={[
            {
              buttonClassName: 'link',
              clickFunc: () => open(modalNames.followUp),
              buttonText: 'Follow up',
            },
            // {
            //   buttonClassName: 'link',
            //   clickFunc: () => showModal('createJobModal', { job_type: 'localStorage' }),
            //   buttonText: 'Storage',
            // },
            {
              buttonClassName: 'link',
              clickFunc: () => {
                open(modalNames.quickForm);
                // showModal('quickFormModal', {
                //   job_id: '',
                //   chat_id: '',
                //   call_id: '',
                //   customer_number: '',
                //   company_id: '',
                // }),
              },
              buttonText: 'Lead',
            },
          ]}
        />
      </li>
      <li className="navbar-menu--item reports">
        <DropdownMenu
          button={{ iconName: 'reports', iconTitle: 'Reports', className: 'menu-button' }}
          listClass="mw-auto"
          optionOnClick={reports}
          options={[
            { buttonClassName: 'link', clickValue: 'general', iconName: 'general', iconTitle: 'General' },
            { buttonClassName: 'link', clickValue: 'payroll', iconName: 'payroll', iconTitle: 'Payroll' },
            {
              buttonClassName: 'link',
              clickValue: 'communication',
              iconName: 'communication',
              iconTitle: 'Communication',
            },
            { buttonClassName: 'link', clickValue: 'claims', iconName: 'claims', iconTitle: 'Claims' },
            { buttonClassName: 'link', clickValue: 'lead', iconName: 'lead_person', iconTitle: 'Lead' },
            { buttonClassName: 'link', clickValue: 'booking', iconName: 'booking', iconTitle: 'Booking' },
            ...(user?.manager ? [{ buttonClassName: 'link', clickValue: 'provider', iconName: 'lead_provider', iconTitle: 'Lead Provider' }] : [{}]),
            {
              buttonClassName: 'link',
              clickValue: 'building',
              iconName: 'building_name',
              iconTitle: 'Building Report',
            },
          ]}
        />
      </li>
      <li className="navbar-menu--item">
        <button className="menu-button" onClick={available}>
          <Icon icon="movers" title="Crews" />
        </button>
      </li>
      <li className="navbar-menu--item">
        <button className="menu-button" onClick={() => history.push({ pathname: routes.NOTES })}>
          <Icon icon="notes" title="Important Notes" />
        </button>
      </li>
      <li className="navbar-menu--item">
        <button className="menu-button" onClick={() => open(modalNames.twilio)}>
          <Icon icon="put_calls" title="Phone" />
        </button>
      </li>
    </ul>
  );
};

export default NavbarLeftSide;

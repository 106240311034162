import React, { useEffect, useCallback } from 'react';
import { Modal } from '@restart/ui';
import Icon from 'components/Icon';
import './style.scss';

const ModalComponent = (props) => {
  const { className, style, children, header, onHide, show } = props;

  const onKeyPress = useCallback(
    (e) => {
      if (e.keyCode === 27) return onHide;
    },
    [onHide]
  );

  useEffect(() => {
    document.addEventListener('keydown', onKeyPress);
    return () => {
      document.removeEventListener('keydown', onKeyPress);
    };
  }, [onKeyPress]);

  return (
    <Modal show={show} onHide={onHide} className="wrapper">
      <div className={className} style={style}>
        <div className="modal-pages">
          <h3 className="header">{header}</h3>
          <button onClick={onHide}>
            <Icon rotateDeg={45} icon="add" size={28} />
          </button>
        </div>
        {children}
      </div>
    </Modal>
  );
};

export default ModalComponent;

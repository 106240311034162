import React from 'react';
import { useModal } from 'context/modal';
import Icon from 'components/Icon';

const JobNotesMenu = ({ id, tab }) => {
  const { modalNames, open } = useModal();

  return (
    <div className="d-flex justify-content-start align-items-center">
      <button className="p20" onClick={() => open(modalNames.settings, { type: 'tasks' })}>
        <Icon icon="general" title="Settings" />
      </button>
      <button className="p20" onClick={() => open(modalNames.notes, { type: 'agent_notes', jobID: id, tab })}>
        <Icon icon="notes" title="Notes" />
      </button>
      <button className="p20" onClick={() => open(modalNames.additionalInfo)}>
        <Icon icon="additional_info" title="Additional Info" />
      </button>
      <button className="p20" onClick={() => open(modalNames.insuranceProvider, { type: 'from' })}>
        <Icon icon="move_out_insurance" title="Insurance Info From" />
      </button>
    </div>
  );
};

export default JobNotesMenu;

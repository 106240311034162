import React, { Fragment, useMemo } from 'react';
import { useFormik } from 'formik';
import moment from 'moment';
import * as Yup from 'yup';
import Preloader from 'components/Preloader';
import Filter from './Filter';
import ProviderUserList from './ProviderUserList';
import { useReportFilterInfo, useGetUserNames, useGetProviderReport } from 'hooks/query/useReport';
import { changePeriod } from '../reportUtils';

const Provider = () => {
  const { data: providerReportResponse, mutate: getProviderReport, isLoading } = useGetProviderReport();
  const { data } = useReportFilterInfo();

  const providers = providerReportResponse?.data?.providers;

  const allCompanies = useMemo(() => {
    if (!data) return [];
    return data.all_companies;
  }, [data]);

  const { values, setFieldValue, handleSubmit, errors, touched } = useFormik({
    initialValues: {
      company_id: allCompanies,
      user_type: 'provider',
      user_id: [],
      from: null,
      to: null,
      period_type: 0,
      job_statuses: [6],
    },
    validationSchema: Yup.object().shape({
      user_id: Yup.array().min(1, 'Select agent').required('Select agent'),
    }),
    onSubmit: (values) => getProviderReport(values),
    enableReinitialize: true,
  });

  const selectDate = ({ name, date }) => setFieldValue(name, moment(date).format('MM/DD/YY'));

  const selectPeriod = ({ value }) => {
    const { from, to } = changePeriod(value);
    setFieldValue('from', from);
    setFieldValue('to', to);
    setFieldValue('period_type', value);
  };

  const selectAllUsers = (list) => {
    if (values['user_id'].length === list.length) {
      setFieldValue('user_id', []);
      return;
    }
    setFieldValue(
      'user_id',
      list.map((user) => user.id)
    );
  };

  const selectUser = (id) => {
    if (values['user_id'].includes(id)) {
      setFieldValue(
        'user_id',
        values['user_id'].filter((i) => i !== id)
      );
      return;
    }

    setFieldValue('user_id', [...values['user_id'], id]);
  };

  const selectJobFilter = (status, isChecked) => {
    if (isChecked) {
      setFieldValue(
        'job_statuses',
        values['job_statuses'].filter((item) => item !== status)
      );
      return;
    }

    setFieldValue('job_statuses', [...values['job_statuses'], status]);
  };

  const { data: userNamesResponse } = useGetUserNames(
    { company_id: values['company_id'], user_type: values['user_type'] },
    { onSuccess: ({ user_names }) => selectAllUsers(user_names) }
  );

  const userNames = useMemo(() => {
    if (!userNamesResponse) return [];

    return userNamesResponse.user_names.map((user) => ({ ...user, value: user.id, label: `${user.first_name} ${user.last_name}` }));
  }, [userNamesResponse]);

  const isValidHandler = (name) => {
    if (errors[name] && touched[name]) return errors[name];
    return null;
  };

  if (!data) return null;

  return (
    <Fragment>
      {isLoading && <Preloader />}

      <Filter
        data={{ values, userNames }}
        actions={{ isValidHandler, selectJobFilter, selectAllUsers, selectPeriod, selectUser, selectDate, handleSubmit }}
      />
      {providers && <ProviderUserList list={providers} />}
    </Fragment>
  );
};

export default Provider;

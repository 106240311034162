import React, { createContext, useMemo, useContext, useReducer, useCallback } from 'react';
import names from './names';

export const ModalContext = createContext(undefined);

const initial = {
  [names.fees]: { visible: false, params: {} },
  [names.notes]: { visible: false, params: {} },
  [names.email]: { visible: false, params: {} },
  [names.twilio]: { visible: false, params: {} },
  [names.packing]: { visible: false, params: {} },
  [names.charges]: { visible: false, params: {} },
  [names.followUp]: { visible: false, params: {} },
  [names.discount]: { visible: false, params: {} },
  [names.newEmail]: { visible: false, params: {} },
  [names.quickForm]: { visible: false, params: {} },
  [names.insurance]: { visible: false, params: {} },
  [names.inventory]: { visible: false, params: {} },
  [names.callScript]: { visible: false, params: {} },
  [names.assignCrew]: { visible: false, params: {} },
  [names.newRemainder]: { visible: false, params: {} },
  [names.editReminder]: { visible: false, params: {} },
  [names.additionalInfo]: { visible: false, params: {} },
  [names.dayAvailability]: { visible: false, params: {} },
  [names.changeJobStatus]: { visible: false, params: {} },
  [names.insuranceProvider]: { visible: false, params: {} },
  [names.extraStop]: { visible: false, params: {} },
  [names.rescheduleJob]: { visible: false, params: {} },
  [names.jobUpdateToStorage]: { visible: false, params: {} },
  [names.settings]: { visible: false, params: {} },
  [names.localJobInventoryToOngoingStorageJob]: { visible: false, params: {} },
  [names.editReminder]: { visible: false, params: {} },
  [names.payrollReimbursement]: { visible: false, params: {} },
  [names.longDistanceFees]: { visible: false, params: {} },
  [names.changeJobCarrier]: { visible: false, params: {} },
  [names.createCarrierDriver]: { visible: false, params: {} },
};

const reducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'open':
      return { ...state, [payload.name]: { visible: true, params: payload.params } };
    case 'close':
      return { ...state, [payload.name]: { visible: false } };
    default:
      throw new Error();
  }
};

export const ModalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initial);

  const open = useCallback((name, params) => dispatch({ type: 'open', payload: { name, params } }), []);

  const close = useCallback((name) => dispatch({ type: 'close', payload: { name } }), []);

  const info = useCallback((name) => state[name] ?? {}, [state]);

  const value = useMemo(() => {
    return { modalNames: names, open, close, info };
  }, [close, info, open]);

  return <ModalContext.Provider value={value}>{children}</ModalContext.Provider>;
};

export const useModal = () => {
  return useContext(ModalContext);
};

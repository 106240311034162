import React from 'react';

const PaymentHeader = ({ jobTitle, email, phone }) => {
  return (
    <div className="new-table">
      <div className="new-table--row">
        <div className="new-table--row--item">
          <h1>Payment</h1>
        </div>
      </div>
      <div className="new-table--row">
        <div className="new-table--row--item light">
          <span>{jobTitle}</span>
        </div>
      </div>
      {email && (
        <div className="new-table--row">
          <div className="new-table--row--item">
            <span>{email}</span>
          </div>
        </div>
      )}
      {phone && (
        <div className="new-table--row">
          <div className="new-table--row--item">
            <span>{phone}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default PaymentHeader;

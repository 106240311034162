import React from 'react';
import { useField } from 'formik';

const FormikCheckbox = (props) => {
  const { label, className, error, touched, newChange, ...rest } = props;
  const [field, meta] = useField(props);

  const handleChange = (e) => {
    field.onChange(e);
    if (newChange) newChange(e.target.checked);
  };

  return (
    <div className="d-flex flex-column w100 position-relative">
      <label className={`justify-content-between ${className ? className : ''}`}>
        <span>{label}</span>
        <input type="checkbox" {...rest} checked={field.value} onChange={handleChange} />
      </label>
      {meta.error && meta.touched && (
        <span style={{ position: 'absolute', bottom: -20 }} className="error">
          {meta.error}
        </span>
      )}
    </div>
  );
};

export default FormikCheckbox;

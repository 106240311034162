export default {
  job: 'job',
  crew: 'crew',
  user: 'user',
  email: 'email',
  token: 'token',
  crews: 'crews',
  chains: 'chains',
  jobPage: 'jobPage',
  building: 'building',
  jobUnits: 'jobUnits',
  lostJobs: 'lostJobs',
  counter: 'counter',
  counters: 'counters',
  reminder: 'reminder',
  template: 'template',
  distance: 'distance',
  reminders: 'reminders',
  templates: 'templates',
  movingInfo: 'movingInfo',
  movingSize: 'movingSize',
  ratesByDate: 'ratesByDate',
  defaultItems: 'defaultItems',
  selectedMail: 'selectedMail',
  selectedChat: 'selectedChat',
  crewsSchedule: 'crewsSchedule',
  communication: 'communication',
  communications: 'communications',
  jobGeneralCounters: 'jobGeneralCounters',
  emailTemplates: 'emailTemplates',
  remaindersChain: 'remaindersChain',
  unavailableCrew: 'unavailableCrew',
  capabilityToken: 'capabilityToken',
  statusesTemplate: 'statusesTemplate',
  bookedJobsByDate: 'bookedJobsByDate',
  assignedJobsToCrew: 'assignedJobsToCrew',
  packingDescription: 'packingDescription',
  assignedCrewSchedule: 'assignedCrewSchedule',
  availableUnitsByDate: 'availableUnitsByDate',
  ongoingStorageJobs: 'ongoingStorageJobs',
  popupInfo: 'popupInfo',
  longDistanceTariff: 'longDistanceTariff',
  reportDashboardInfo: 'reportDashboardInfo',
  reportFilterdInfo: 'reportFilterdInfo',
  reportUserNames: 'reportUserNames',
  generalReportJobInfo: 'generalReportJobInfo',
  importantNotes: 'importantNotes',
  importantNoteInfo: 'importantNoteInfo',
  inventory: 'inventory',
  carrierDrivers: 'carrierDrivers',
};

import React from 'react';
import { useField } from 'formik';

const FormikRadioButton = (props) => {
  const [field] = useField(props);
  const { title, value, newChange } = props;

  const handleChange = (e) => {
    if (newChange) newChange();
    field.onChange(e);
  };

  return (
    <label className="d-flex justify-content-center">
      <input name={field.name} value={value} type="radio" checked={value === field.value} onChange={handleChange} />
      <span>{title}</span>
    </label>
  );
};

export default FormikRadioButton;

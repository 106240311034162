import React from 'react';
import { useField } from 'formik';

const FormikFileInput = (props) => {
  const { mask, newChange, name, multiple, labelText, ...rest } = props;
  const [field, meta] = useField(props);

  const handleChange = (e) => newChange(e);

  return (
    <label className="file position-relative">
      {labelText}
      <input
        name={name}
        style={{ padding: 0, margin: 0 }}
        {...rest}
        {...field}
        value={undefined}
        accept="image/jpeg,image/gif,image/png,image/bmp,application/pdf,image/x-eps"
        onChange={handleChange}
        type="file"
        multiple={multiple}
      />

      {meta.error && meta.touched && (
        <span style={{ position: 'absolute', bottom: -20 }} className="error">
          {meta.error}
        </span>
      )}
    </label>
  );
};
export default FormikFileInput;

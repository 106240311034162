import React, { Fragment } from 'react';
import { useModal } from 'context/modal';
import RescheduleJob from './RescheduleJob';

const Modals = () => {
  const { info, modalNames } = useModal();

  const isRescheduleJob = info(modalNames.rescheduleJob).visible;

  return <Fragment>{isRescheduleJob && <RescheduleJob />}</Fragment>;
};

export default Modals;

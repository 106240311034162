import moment from 'moment';

export const formatWeekDay = (date) => {
  return moment(date).format('ddd');
};

export const formatYear = (date) => {
  return moment(date).format('YYYY');
};

export const formatMonthDay = (date) => {
  return moment(date).format('MM/DD');
};

export const formatLT = (date) => {
  return moment(date).format('LT');
};

export const formatL = (date) => {
  return moment(date).format('L');
};

export const formatFullDate = (date) => {
  return moment(date).format('MM/DD/YYYY');
};

export const formatTimeAPMeridiem = (date) => {
  return moment(date).format('hh:mm A');
};

export const formatFullDateTime = (date) => {
  return moment(date).format('MM/DD/YYYY hh:mm A');
};

export const formatFullDBDateTime = (date) => {
  return moment(date).format('YYYY-MM-DD hh:mm A');
};

export const formatFullDBDate = (date) => {
  return moment(date).format('YYYY-MM-DD');
};

export const isValid = (date) => {
  return moment(date).isValid();
};

export const add = (date, add, key) => {
  return moment(date).add(add, key);
};

export const subtract = (date, add, key) => {
  return moment(date).subtract(add, key);
};

export const getDateFromPeriod = (period) => {
  const data = {
    all: { start: null, end: moment() },
    past: { start: moment().subtract(3, 'M'), end: moment().subtract(1, 'd') },
    today: { start: moment(), end: moment() },
    future: { start: moment().add(1, 'd'), end: moment().add(1, 'M').add(1, 'd') },
    yesterday: { start: moment().subtract(1, 'd'), end: moment().subtract(1, 'd') },
    last_week: { start: moment().isoWeekday(-7), end: moment().isoWeekday(-1) },
    this_mont: { start: moment().startOf('M'), end: moment().endOf('M') },
    this_year: { start: moment().startOf('y'), end: moment().endOf('y') },
    last_year: {
      start: moment().subtract(1, 'y').startOf('y'),
      end: moment().subtract(1, 'y').endOf('y'),
    },
    last_month: {
      start: moment().subtract(1, 'M').startOf('M'),
      end: moment().subtract(1, 'M').endOf('M'),
    },
    last_two_weeks: { start: moment().isoWeekday(-14), end: moment().isoWeekday(-1) },
    last_thirty_days: {
      start: moment().subtract(31, 'd'),
      end: moment().subtract(1, 'd'),
    },
  };

  const { start, end } = data[period];

  return { start: start ? formatFullDate(start) : start, end: end ? formatFullDate(end) : null };
};

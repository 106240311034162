export default {
  address: '',
  apartment: '',
  apt_finished: 0,
  apt_note: '',
  bedroom_sets: 0,
  building_id: 0,
  building_name: '',
  building_type: '',
  cell_phone: '',
  city: '',
  created_at: '',
  customer_name: '',
  driver_notes: '',
  elevator: '',
  elevator_end: 0,
  elevator_start: 0,
  email: '',
  estimated_stairs: 0,
  fax: '',
  final_apt_note: '',
  final_fragile_note: '',
  final_packing_note: '',
  final_restrictions_notes: '',
  final_wrap_note: '',
  fragile: 0,
  fragile_note: '',
  home_phone: '',
  insurance_option: 0,
  job_id: 0,
  leg: 0,
  move_out_insurance_id: 0,
  packing: 0,
  packing_note: '',
  place_id: '',
  required_coi: 0,
  restrictions_notes: '',
  stairs: 0,
  state: '',
  time_restrictions_end: 0,
  time_restrictions_start: 0,
  trailer_access: 0,
  type: 1,
  updated_at: '',
  wrap: 0,
  wrap_note: '',
  zip: '',
};

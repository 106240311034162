import React, { Fragment } from 'react';
import moment from 'moment';
import Settings from './Settings';

const Controls = ({ values, setFieldValue, handleChange, selectedDates, onSave }) => {
  return (
    <Fragment>
      <div className="new-table">
        <div className="new-table--row">
          {Object.entries(values.rates).map(([key, value]) => {
            return (
              <div className="new-table--row--item item-row" key={key}>
                <span className="d-inline-block w50">{key} movers</span>
                <input name={`rates.${key}`} value={values.rates[`${key}`]} className="w50 rate-input" onChange={handleChange} />
              </div>
            );
          })}
        </div>
      </div>

      {Boolean(selectedDates.length) && (
        <div className="col-12 mt-4 mb-4 p-0">
          <p className="mb-4">Selected dates</p>
          <div className="selected-date-wrap">
            {selectedDates.map((date, index) => (
              <span className="selected-date" key={`${date}${index}`}>
                {date}
              </span>
            ))}
          </div>
        </div>
      )}

      <Settings
        multiSelect={values['multi']}
        rangeSelect={values['range']}
        onChangeMultiSelect={({ target }) => {
          if (values['multi']) {
            setFieldValue('multi', false);
            setFieldValue('multiSelect', []);
            return;
          }
          setFieldValue('range', false);
          setFieldValue('selectedRange', [moment(), moment().add(1, 'days')]);
          setFieldValue('multi', true);
        }}
        onChangeRangeSelect={({ target }) => {
          if (values['range']) {
            setFieldValue('range', false);
            setFieldValue('selectedRange', [moment(), moment().add(1, 'days')]);
            return;
          }
          setFieldValue('multi', false);
          setFieldValue('multiSelect', []);
          setFieldValue('range', true);
        }}
        onSave={onSave}
      />
    </Fragment>
  );
};

export default Controls;

import React, { useCallback } from 'react';
import { alert } from 'components/Alerts';
import { useFormik } from 'formik';
import { useModal } from 'context/modal';
import CheckBox from 'components/newComponents/CheckBox';
import { useMailChainsSuggest, useFollowUpChainCreate } from './query/useReminder';
import { useEmailSend } from 'hooks/query/useMail';
import { useInventorySave, useInfoAsync, useTabInfoAsync } from 'hooks/query/useJob';
import { prepareDataToSubmit } from 'pages/modal/Inventory/utils';

export const useSuggestFollowUpChain = (id, tab, type) => {
  const { open, modalNames } = useModal();

  const { mutateAsync: getMailChainsSuggest } = useMailChainsSuggest();
  const { mutateAsync: getInfo } = useInfoAsync();
  const { mutateAsync: getTabInfo } = useTabInfoAsync();

  const { mutate: createFollowUpChain } = useFollowUpChainCreate();
  const { mutate: sendQuote } = useEmailSend(id, tab, type);
  const { mutate: saveInventory } = useInventorySave(id, tab);

  const onMutate = useCallback(
    async (jobId) => {
      const {
        data: { suggested_chains },
      } = await getMailChainsSuggest({ job_id: jobId });
      const { data: infoData } = await getInfo({ id: jobId });
      const { data: tabInfoData } = await getTabInfo({ id: jobId, tab: 'inventory' });

      const companyID = infoData?.job_info.company_id;
      const jobInventory = tabInfoData?.job_inventory;
      let mailChains = suggested_chains.map((chain) => ({ ...chain, checked: chain.type === 'sms' ? false : true }));

      const openInventoryModal = () => {
        open(modalNames.inventory, {
          type: 'estimated',
          jobID: jobId,
          companyID: companyID,
          jobInventory: jobInventory.estimated,
          onSubmit: (data, options) => {
            const prepared = prepareDataToSubmit(data);
            saveInventory(
              {
                job_id: jobId,
                job_inventory: { estimated: prepared, final: prepared },
              },
              options
            );
          },
        });
      };

      if (mailChains) {
        const res = await alert({
          title: 'Select from Suggested chains',
          html: (
            <Form
              mailChains={mailChains}
              onSendQuote={() => sendQuote({ job_id: jobId, template: 'quote_1' })}
              onOpenInventory={openInventoryModal}
              onChangeMailChains={(data) => (mailChains = [...data])}
            />
          ),
          focusConfirm: false,
          allowOutsideClick: false,
        });

        if (res.isConfirmed) {
          createFollowUpChain({
            job_id: jobId,
            chains: Object.fromEntries(mailChains.map((el) => [el.chain_id, el.checked])),
          });
        }
      }
    },
    [modalNames.inventory, createFollowUpChain, getMailChainsSuggest, saveInventory, getInfo, getTabInfo, open, sendQuote]
  );

  return { onMutate };
};

const Form = ({ onSendQuote, onOpenInventory, mailChains, onChangeMailChains }) => {
  const { setFieldValue, values } = useFormik({ initialValues: { suggested_chains: mailChains } });

  const clearChainHandler = useCallback(() => {
    const chains = values.suggested_chains.map((chain) => ({ ...chain, checked: false }));

    setFieldValue('suggested_chains', chains);
    onChangeMailChains(chains);
  }, [onChangeMailChains, setFieldValue, values.suggested_chains]);

  const onChangeSuggestedChains = useCallback(
    (id) => {
      const chains = values.suggested_chains.map((chain) => {
        if (chain.chain_id === id) return { ...chain, checked: !chain.checked };
        return chain;
      });

      setFieldValue('suggested_chains', chains);
      onChangeMailChains(chains);
    },
    [setFieldValue, onChangeMailChains, values.suggested_chains]
  );

  return (
    <form>
      {mailChains &&
        mailChains.map((chain) => (
          <CheckBox
            key={chain.chain_id}
            right={false}
            label={chain.chain_name}
            name="suggested_chains"
            className="justify-content-center"
            checked={values['suggested_chains'].find((item) => item.chain_id === chain.chain_id).checked}
            onChange={() => onChangeSuggestedChains(chain.chain_id)}
          />
        ))}

      <div className="d-flex justify-content-between mt-3">
        <button type="button" onClick={onSendQuote}>
          Send Quote
        </button>
        <button type="button" onClick={onOpenInventory}>
          Inventory
        </button>
        <button type="button" onClick={clearChainHandler}>
          Clear chains
        </button>
      </div>
    </form>
  );
};

export const statusNumberToName = (status) => {
  const statuses = {
    1: 'assigned',
    2: 'booked',
    3: 'confirmed',
    4: 'in_progress',
    5: 'delivered',
    6: 'completed',
    7: 'follow_up',
    8: 'visual',
    9: 'quoted',
    10: 'sold',
    11: 'rescheduled',
    12: 'reassigned',
    13: 'in_transit',
    14: 'in_storage',
    15: 'off_loaded',
    16: 'out_storage',
    17: 'unconfirmed',
    18: 'canceled',
    19: 'delete',
    20: 'office_to_pickup',
    21: 'lead',
    22: 'lost',
    23: 'dropped',
    24: 'waiting_for_delivery',
  };
  return statuses[+status];
};

export const availableStatuses = {
  1: [2, 3, 7, 8, 9, 11, 12, 17, 19],
  2: [3, 10, 18, 11, 17],
  3: [4, 10, 6, 18, 11],
  4: [5, 18],
  5: [6],
  6: [],
  7: [2, 8, 23],
  8: [1, 7],
  9: [],
  10: [7, 2, 3],
  11: [7, 1, 2, 3, 18],
  12: [7, 1, 2, 1],
  13: [],
  14: [16],
  15: [],
  16: [],
  17: [18, 3],
  18: [1, 2, 7],
  19: [],
  20: [],
  21: [2, 7, 1],
  22: [7],
  23: [7],
  24: [14, 18],
};

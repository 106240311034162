import React, { Fragment, useMemo } from 'react';
import { useFormik } from 'formik';
import moment from 'moment';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import Preloader from 'components/Preloader';
import Filter from './Filter';
import PayrollJobList from './PayrollJobList';
import { useReportFilterInfo, useGetUserNames, useGetPayrollReport, useChangePaidStatus, useCreateReimbursement } from 'hooks/query/useReport';
import { getPayrollDatesByPeriod, getValueToAdd, getValueToSubtract } from '../reportUtils';
import { periodList } from '../reportConstants';
import { useAuth } from 'context/auth';

const Payroll = () => {
  const { user } = useAuth();
  const { data } = useReportFilterInfo();
  const { data: payrollReportResponse, mutate: getPayrollReport, isLoading } = useGetPayrollReport();
  const { mutateAsync: changePaidStatus } = useChangePaidStatus();
  const { mutateAsync: createReimbursement } = useCreateReimbursement();

  const payrollData = payrollReportResponse?.data;

  const companiesList = useMemo(() => {
    if (!data) return [];

    const list = data.companies.map((company) => ({
      value: [company.id],
      label: company.company_name,
    }));
    list.unshift({ value: data.all_companies, label: 'All Companies' });

    return list;
  }, [data]);

  const userTypes = data?.user_type.map((type) => ({ value: type.id, label: type.name })) ?? [];

  const { values, setFieldValue, handleSubmit, errors, touched } = useFormik({
    initialValues: {
      company_id: companiesList[0]?.value ?? [],
      user_type: userTypes[0]?.value ?? 1,
      user_id: [],
      job_statuses: [5, 6, 10],
      from: moment().isoWeekday(-7).format('MM/DD/YY'),
      to: moment().isoWeekday(-1).format('MM/DD/YY'),
      period_type_week: periodList[0].value,
    },
    validationSchema: Yup.object().shape({
      user_id: Yup.array().min(1, 'Select agent').required('Select agent'),
    }),
    onSubmit: (values) => getPayrollReport(values),
    enableReinitialize: true,
  });

  const handleSelect = (name, value) => setFieldValue(name, value);

  const selectPeriodTypeWeek = ({ value }) => {
    const { from, to } = getPayrollDatesByPeriod(value, values['from']);

    setFieldValue('period_type_week', value);
    setFieldValue('from', from);
    setFieldValue('to', to);
  };

  const selectDate = ({ date }) => {
    const { from, to } = getPayrollDatesByPeriod(values['period_type_week'], moment(date).format('MM/DD/YY'));

    setFieldValue('from', from);
    setFieldValue('to', to);
  };

  const goNextPeriod = () => {
    const valueToAdd = getValueToAdd(values['period_type_week']);
    const currentEndOfPeriod = getPayrollDatesByPeriod(values['period_type_week'], values['from']).to;
    const nextPeriodStart = moment(currentEndOfPeriod, 'MM/DD/YY').add(valueToAdd.value, valueToAdd.format).format('MM/DD/YY');
    const { from, to } = getPayrollDatesByPeriod(values['period_type_week'], nextPeriodStart);

    setFieldValue('from', from);
    setFieldValue('to', to);
  };

  const goPrevPeriod = () => {
    const valueToSubtract = getValueToSubtract(values['period_type_week']);
    const currentStartOfPeriod = getPayrollDatesByPeriod(values['period_type_week'], values['from']).from;
    const prevPeriodStart = moment(currentStartOfPeriod, 'MM/DD/YY').subtract(valueToSubtract.value, valueToSubtract.format).format('MM/DD/YY');
    const { from, to } = getPayrollDatesByPeriod(values['period_type_week'], prevPeriodStart);

    setFieldValue('from', from);
    setFieldValue('to', to);
  };

  const selectAllUsers = (list) => {
    if (values['user_id'].length === list.length) {
      setFieldValue('user_id', []);
      return;
    }
    setFieldValue(
      'user_id',
      list.map((user) => user.id)
    );
  };

  const selectUser = (id) => {
    if (values['user_id'].includes(id)) {
      setFieldValue(
        'user_id',
        values['user_id'].filter((i) => i !== id)
      );
      return;
    }

    setFieldValue('user_id', [...values['user_id'], id]);
  };

  const selectJobFilter = (status, isChecked) => {
    if (isChecked) {
      setFieldValue(
        'job_statuses',
        values['job_statuses'].filter((item) => item !== status)
      );
      return;
    }

    setFieldValue('job_statuses', [...values['job_statuses'], status]);
  };

  const handlePaid = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {
        changePaidStatus({
          date: [values.from, values.to],
          users_id: values.user_id,
          users_type: [values.user_type],
          period_type: values.period_type_week,
        }).then(() => getPayrollReport(values));
      }
    });
  };

  const isValidHandler = (name) => {
    if (errors[name] && touched[name]) return errors[name];
    return null;
  };

  const { data: userNamesResponse } = useGetUserNames(
    { company_id: values['company_id'], user_type: values['user_type'] },
    {
      enabled: Boolean(values['company_id'].length),
      onSuccess: ({ user_names }) => selectAllUsers(user_names),
    }
  );

  const handleCreateReimbursement = async (data) => {
    await createReimbursement({
      from: values.from,
      to: values.to,
      ...data,
    });

    getPayrollReport(values);
  };

  const userNames = useMemo(() => {
    if (!userNamesResponse) return [];

    return userNamesResponse.user_names.map((user) => ({ ...user, value: user.id, label: `${user.first_name} ${user.last_name}` }));
  }, [userNamesResponse]);

  if (!data) return null;

  return (
    <Fragment>
      {isLoading && <Preloader />}

      <Filter
        data={{ values, companiesList, userNames, userTypes, manager: Number(user.manager) }}
        actions={{
          isValidHandler,
          handleSelect,
          selectAllUsers,
          selectPeriodTypeWeek,
          selectUser,
          selectDate,
          goNextPeriod,
          goPrevPeriod,
          handlePaid,
          selectJobFilter,
          handleSubmit,
        }}
      />
      {payrollData && <PayrollJobList data={payrollData.data} list={payrollData.info} handleCreateReimbursement={handleCreateReimbursement} />}
    </Fragment>
  );
};

export default Payroll;

import React, { useCallback, useEffect } from 'react';
import { extendMoment } from 'moment-range';
import { useFormik } from 'formik';
import Moment from 'moment';
import { useRatesByDate, useRatesSave } from 'hooks/query/useCommon';
import Controls from './Controls';
import Calendar from './Calendar';
import Preloader from 'components/Preloader';
import JobsInfo from './JobsInfo';
import './styles.scss';

const moment = extendMoment(Moment);

const AnnualCalendar = () => {
  const { mutate: saveRate } = useRatesSave();

  const getRange = useCallback(([start, end]) => {
    const range = moment.range(start, end);
    return Array.from(range.by('days'));
  }, []);

  const getUniqDate = useCallback((...arg) => {
    return arg.reduce((acc, dates) => {
      const arr = [...acc];
      if (typeof dates?.[0] === 'string') arr.push(...dates);
      if (typeof dates?.[0] === 'object') arr.push(...dates.map((e) => e.format('YYYY-MM-DD')));
      return [...new Set(arr)];
    }, []);
  }, []);

  const saveRateHandler = useCallback(
    (values) => {
      saveRate({
        rates: values.rates,
        date: values.selectedDay.format('MMMM DD, YYYY'),
        multiDates: getUniqDate(values.multiSelect, values?.range ? getRange(values.selectedRange) : []),
      });
    },
    [saveRate, getUniqDate, getRange]
  );

  const { values, setFieldValue, handleChange, handleSubmit } = useFormik({
    onSubmit: saveRateHandler,
    initialValues: {
      selectedRange: [moment(), moment().add(1, 'days')],
      selectedDay: moment(),
      year: moment().year(),
      multiSelect: [],
      dates: [],
      rates: {},
      jobs: {},
      multi: false,
      range: false,
    },
  });

  const { isFetching, data } = useRatesByDate({ date: values.selectedDay.format('MMMM DD, YYYY') });

  useEffect(() => {
    if (data) {
      Object.entries(data.rates).map(([key, value]) => setFieldValue(`rates.${key}`, value));
      setFieldValue('dates', data.dates);
      setFieldValue('jobs', data.jobs);
    }
  }, [data, setFieldValue]);

  return (
    <div className="annual-calendar-container">
      {isFetching && <Preloader />}

      <Calendar values={values} setFieldValue={setFieldValue} />

      {values.jobs && <JobsInfo booked={values.jobs.booked} followUp={values.jobs.follow_up} canceled={values.jobs.canceled} />}

      <Controls
        values={values}
        setFieldValue={setFieldValue}
        handleChange={handleChange}
        selectedDates={getUniqDate(values.multiSelect, values?.range ? getRange(values.selectedRange) : [])}
        onSave={handleSubmit}
      />
    </div>
  );
};

export default AnnualCalendar;

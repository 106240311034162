import { useMutation, useQuery, useQueryClient } from 'react-query';
import { serialize } from 'object-to-formdata';
import { useApi } from 'context/api';
import queryKeys from 'config/queryKeys';

const fiveHourInSeconds = 60 * 60 * 5;

export const useDefault = () => {
  const { common } = useApi();

  return useQuery(queryKeys.defaultItems, common.default, {
    select: (res) => res.data,
    cacheTime: fiveHourInSeconds,
    staleTime: fiveHourInSeconds,
    refetchOnWindowFocus: false,
    retry: false,
  });
};

export const usePackingDescription = (options) => {
  const { common } = useApi();

  return useQuery(queryKeys.packingDescription, common.packingDescription, {
    select: (res) => res.data,
    cacheTime: fiveHourInSeconds,
    staleTime: fiveHourInSeconds,
    ...options,
  });
};

export const useDistanceForm = () => {
  const { common } = useApi();

  const queryClient = useQueryClient();

  return useMutation(common.distanceForm, {
    select: (res) => res.data,
    onSuccess: (data, variables) => {
      queryClient.setQueryData([queryKeys.distance, variables], data);
    },
  });
};

export const useFilesSave = (progress) => {
  const { common } = useApi();

  const onUploadProgress = (event) => {
    const percentCompleted = Math.round((event.loaded * 100) / event.total);

    if (progress) progress(percentCompleted);
  };

  const options = { headers: { 'Content-Type': 'multipart/form-data' }, onUploadProgress };

  return useMutation((data) => common.saveFiles(serialize(data), options));
};

export const useQuickMovingQuote = (params) => {
  const { common } = useApi();

  const queryClient = useQueryClient();

  return useMutation(common.quickMovingQuote, { onSuccess: () => queryClient.invalidateQueries([queryKeys.jobPage]) });
};

export const useStatusesTemplateSave = () => {
  const { common } = useApi();

  const queryClient = useQueryClient();

  return useMutation(common.saveStatusesTemplate, {
    onMutate: async ({ statuses }) => {
      await queryClient.cancelQueries(queryKeys.statusesTemplate);

      const previousStatuses = queryClient.getQueryData(queryKeys.statusesTemplate);

      queryClient.setQueryData(queryKeys.statusesTemplate, ({ data, ...rest }) => ({ ...rest, data: statuses }));

      return previousStatuses;
    },
    onError: (err, newTodo, previousStatuses) => {
      queryClient.setQueryData(queryKeys.statusesTemplate, previousStatuses);
    },
    onSettled: () => {
      queryClient.invalidateQueries(queryKeys.statusesTemplate);
    },
  });
};

export const useStatusesTemplate = (options) => {
  const { common } = useApi();

  return useQuery(queryKeys.statusesTemplate, common.getStatusesTemplate, {
    select: (res) => res.data,
    cacheTime: fiveHourInSeconds,
    staleTime: fiveHourInSeconds,
    refetchOnWindowFocus: false,
    retry: false,
    ...options,
  });
};

export const useRatesByDate = (date, options) => {
  const { common } = useApi();

  return useQuery([queryKeys.ratesByDate, date], () => common.ratesByDate(date), {
    select: (res) => res.data,
    cacheTime: fiveHourInSeconds,
    staleTime: fiveHourInSeconds,
    refetchOnWindowFocus: false,
    retry: false,
    ...options,
  });
};

export const useRatesSave = (params) => {
  const { common } = useApi();

  return useMutation(common.saveRates);
};

export const useDisabledAlert = () => {
  const { common } = useApi();

  return useMutation(common.disabledAlert);
};

export const useReadAlert = () => {
  const { common } = useApi();
  return useMutation(common.readAlert);
};

export const useMarkAsRead = () => {
  const { common } = useApi();
  return useMutation(common.markAsRead);
};

export const useReadAllMails = () => {
  const { common } = useApi();
  return useMutation(common.readAllMails);
};

export const useMenuCounter = () => {
  const { common } = useApi();

  return useQuery([queryKeys.counters], () => common.menuCounters(), { select: (res) => res.data });
};

export const useMenuCounterByType = (type) => {
  const { common } = useApi();

  return useQuery([queryKeys.counter, type], () => common.menuCounterByType({ type }), { select: (res) => res.data });
};

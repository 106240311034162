import React, { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { matchPath } from 'react-router';
import { routePaths } from 'config/routes';
import { useFormik } from 'formik';
import { useModal } from 'context/modal';
import { useInfo, useInsuranceUpdate, useTabInfo } from 'hooks/query/useJob';
import { useDefault } from 'hooks/query/useCommon';
import { ModalButton } from 'components/newComponents/Buttons';
import Modal from 'components/newComponents/Modal';
import InsuranceItem from './InsuranceItem';

const Insurance = () => {
  const { modalNames, close, info } = useModal();
  const { pathname } = useLocation();

  const {
    params: { id, tab },
  } = matchPath(pathname, { path: routePaths.JOBS });

  const { mutate: updateInsurance } = useInsuranceUpdate(id, tab);

  const {
    data: { job_info: jobInfo },
  } = useInfo(id);

  const {
    data: { insurance: defaultItems },
  } = useDefault();

  const {
    data: { job_insurance: jobInsurance },
  } = useTabInfo(id, tab);

  const isVisible = info(modalNames.insurance).visible;
  const type = info(modalNames.insurance)?.params?.type;

  const insurance = useMemo(() => {
    return jobInsurance[type].reduce((acc, e) => {
      return { ...acc, [e.insurance_id]: e };
    }, {});
  }, [jobInsurance, type]);

  const defaultInsurance = useMemo(() => {
    return defaultItems[jobInfo.company_id].reduce((acc, e) => {
      return { ...acc, [e.id]: { ...e, checked: !!insurance[e.id] } };
    }, {});
  }, [defaultItems, jobInfo.company_id, insurance]);

  const closeHandler = useCallback(() => close(modalNames.insurance), [close, modalNames.insurance]);

  const updateInsuranceHandler = useCallback(
    ({ items }) => {
      const isFinal = type === 'final';
      const prepared = Object.values(items)
        .filter((e) => e.checked)
        .map((e) => ({ job_id: id, insurance_id: e.id }));

      updateInsurance(
        {
          job_id: id,
          job_insurance: {
            estimated: isFinal ? jobInsurance['estimated'] : prepared,
            final: prepared,
          },
        },
        { onSuccess: closeHandler }
      );
    },
    [id, jobInsurance, type, updateInsurance, closeHandler]
  );

  const { values, setFieldValue, handleSubmit } = useFormik({
    initialValues: {
      items: defaultInsurance,
    },
    onSubmit: updateInsuranceHandler,
  });

  const onChangeHandler = useCallback(
    (id) => {
      const { [id]: current, ...rest } = values.items;

      setFieldValue('items', { ...rest, [id]: { ...current, checked: !current.checked } });
    },
    [setFieldValue, values.items]
  );

  return (
    <Modal style={{ width: 850 }} header="Insurance" show={isVisible} onHide={closeHandler}>
      <form onSubmit={handleSubmit}>
        <div className="new-table modal">
          <div className="new-table--row">
            <div className="new-table--row--item">
              <b>Name</b>
            </div>
            <div className="new-table--row--item">
              <b>Amount</b>
            </div>
          </div>
          {Object.values(values.items).map((item) => (
            <InsuranceItem key={item.id} item={item} type={type} handleChange={onChangeHandler} />
          ))}
        </div>
        <div className="footer-buttons">
          <ModalButton title="Cancel" variant="cancel" onClick={closeHandler} />
          <ModalButton title="Add" variant="confirm" type="submit" />
        </div>
      </form>
    </Modal>
  );
};

export default Insurance;

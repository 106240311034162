import React, { Fragment, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useTabInfo, useInfo } from 'hooks/query/useJob';
import Icon from 'components/Icon';

const ExpResAgent = (props) => {
  const { id, tab } = useParams();

  const {
    data: { assigned_management: assignedManagement },
  } = useTabInfo(id, tab);

  const {
    data: { job_info: jobInfo, calc_info: calcInfo },
  } = useInfo(id);

  const agentData = assignedManagement.agent;

  const jobTotal = useMemo(() => {
    return jobInfo.status !== 6 ? calcInfo.estimated_total : calcInfo.final_total;
  }, [calcInfo.estimated_total, calcInfo.final_total, jobInfo.status]);

  const commissionValue = useMemo(() => {
    return ((parseFloat(agentData?.commision || 0) * parseFloat(jobTotal)) / 100).toFixed(2);
  }, [agentData, jobTotal]);

  return (
    <Fragment>
      <div className="new-table--row no-padding bcg-light">
        <div className="new-table--row--item no-side-shadows">
          <b className="disabled"> Agent </b>
        </div>
        <div className="new-table--row--item no-side-shadows">
          <Icon className="fb3" icon="job_total" title="Job total" size={20} />
          <Icon className="fb3" icon="commission" title="Commission % / Storage Commission %" size={20} />
          <Icon className="fb3" icon="commission_value" title="Commission value" size={20} />
        </div>
      </div>

      <div className="new-table--row no-padding">
        <div className="new-table--row--item no-side-shadows">
          {agentData && (
            <b>
              {agentData.first_name} {agentData.last_name}
            </b>
          )}
        </div>
        <div className="new-table--row--item justify-content-around no-side-shadows">
          <b className="fb3"> {jobTotal} </b>
          <b className="fb3"> {agentData?.commision || 0}% / 0%</b>
          <b className="fb3"> {commissionValue} </b>
        </div>
      </div>
    </Fragment>
  );
};

export default ExpResAgent;

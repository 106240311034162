import React from 'react';

const JobsInfo = ({ booked, followUp, canceled }) => {
  return (
    <div className="new-table mt-20">
      <div className="new-table--row">
        <div className="new-table--row--item item-row light">
          <span>Booked</span>
          <span className="count">{booked}</span>
        </div>
        <div className="new-table--row--item item-row light">
          <span>Follow Up</span>
          <span className="count">{followUp}</span>
        </div>
        <div className="new-table--row--item item-row light">
          <span>Canceled</span>
          <span className="count">{canceled}</span>
        </div>
      </div>
    </div>
  );
};

export default JobsInfo;

import React, { Fragment, memo } from 'react';
import { useFormik } from 'formik';
import Select from 'components/newComponents/Select';
import CheckBox from 'components/newComponents/CheckBox';
import Input from 'components/newComponents/Input';

const blanketsOptions = [
  { value: 0, label: 0 },
  { value: 1, label: 1 },
  { value: 2, label: 2 },
  { value: 3, label: 3 },
  { value: 4, label: 4 },
  { value: 5, label: 5 },
  { value: 6, label: 6 },
  { value: 7, label: 7 },
  { value: 8, label: 8 },
  { value: 9, label: 9 },
  { value: 10, label: 10 },
];

const Header = memo(() => {
  return (
    <Fragment>
      <th>In Store</th>
      <th>Out Store</th>
      <th>Blankets</th>
      <th style={{ width: 175 }}>Note</th>
    </Fragment>
  );
});

const Footer = memo(({ blanketsTotal }) => {
  return (
    <Fragment>
      <td></td>
      <td></td>
      <td>{blanketsTotal}</td>
      <td></td>
    </Fragment>
  );
});

const Content = memo((props) => {
  const { id, chk_in_storage, chk_out_storage, note, blankets, onSave } = props;

  const { values, handleChange, setFieldValue } = useFormik({
    enableReinitialize: true,
    initialValues: {
      id,
      chk_in_storage,
      chk_out_storage,
      note,
      blankets,
    },
  });

  return (
    <Fragment>
      <td>
        <CheckBox
          className="justify-content-center"
          name="chk_in_storage"
          checked={values.chk_in_storage}
          onChange={(e) => {
            setFieldValue('chk_in_storage', e.target.checked ? 1 : 0);
            onSave({ ...values, chk_in_storage: e.target.checked ? 1 : 0 });
          }}
        />
      </td>
      <td>
        <CheckBox
          className="justify-content-center"
          name="chk_out_storage"
          checked={values.chk_out_storage}
          onChange={(e) => {
            setFieldValue('chk_out_storage', e.target.checked ? 1 : 0);
            onSave({ ...values, chk_out_storage: e.target.checked ? 1 : 0 });
          }}
        />
      </td>
      <td className="py-0">
        <Select
          name="blankets"
          value={values.blankets}
          options={blanketsOptions}
          onChange={({ value }) => {
            setFieldValue('blankets', value);
            onSave({ ...values, blankets: value });
          }}
        />
      </td>
      <td>
        <Input name="note" placeholder="Enter note" value={values.note} onBlur={() => onSave(values)} onChange={handleChange} />
      </td>
    </Fragment>
  );
});

export { Header, Footer, Content };

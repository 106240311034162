import React, { useMemo, useCallback } from 'react';
import { formatTimeAPMeridiem } from 'helpers/dateHelper';
import { useDefault } from 'hooks/query/useCommon';
import { findBuilding } from 'helpers/buildings';
import { useModal } from 'context/modal';
import Autocomplete from 'components/newComponents/Autocomplete';
import Select from 'components/newComponents/Select';
import Input from 'components/newComponents/Input';
import TimeSelect from 'components/TimeSelect';
import ElevatorMenu from 'components/ElevatorMenu/ElevatorMenu';
import Icon from 'components/Icon';

const ExtraStopForm = ({ type, values, setFieldValue, handleChange }) => {
  const { modalNames, open } = useModal();
  const { data: defaultItems } = useDefault();

  const stairsSet = useMemo(() => {
    return [...Array(10).keys()].map((_, i) => ({ value: i + 1, label: i + 1 }));
  }, []);

  const typeSet = useMemo(() => {
    return [
      {
        value: 1,
        label: 'From',
      },
      {
        value: 2,
        label: 'To',
      },
    ];
  }, []);

  const insuranceStyle = { color: '', title: 'Insurance' };

  // const createInsuranceNotifications = useCallback(() => {
  //   Swal.fire({
  //     title: `Insurance is required for this building!`,
  //     text: `Create COI and Sent?`,
  //     showCancelButton: true,
  //     confirmButtonColor: '#3085d6',
  //     cancelButtonColor: '#d33',
  //     confirmButtonText: 'Yes',
  //     cancelButtonText: 'No',
  //     reverseButtons: false,
  //     backdrop: false,
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       if (parseInt(jobInfo.status) === 7 || parseInt(jobInfo.status) === 21) {
  //         return Swal.fire({
  //           title: 'Job must be in POSTSALE status. Please Book a job',
  //           showCancelButton: false,
  //           confirmButtonColor: '#3085d6',
  //           cancelButtonColor: '#d33',
  //           confirmButtonText: 'OK',
  //         });
  //       }
  //       open(modalNames.insuranceProvider, { type });
  //     }
  //   });
  // }, [jobInfo.status, modalNames.insuranceProvider, open, type]);

  const onSelectAutoCompleteHandler = useCallback(
    async (address, name) => {
      Object.keys(address).forEach((key) => {
        setFieldValue(key, address[key]);
      });
      setFieldValue('zip', address?.zipcode || '');
      setFieldValue('address', address?.full_address || '');
      // setCreatedCoi(true);
      const building = findBuilding(defaultItems?.building_info, address);

      setFieldValue(`required_coi`, building?.required_coi || null);
      setFieldValue(`elevator_exist`, building?.elevator_exist || null);
      setFieldValue(`building_name`, building?.building_name || '');
      // setFieldValue(`required_coi`, !!building);
      setFieldValue(`building_id`, building?.id || 0);
      setFieldValue(`place_id`, address.place_id);
    },
    [defaultItems.building_info, setFieldValue]
  );

  const zipHandler = useCallback(
    (value, name) => {
      setFieldValue(`city`, value?.city);
      setFieldValue(`state`, value?.state);
    },
    [setFieldValue]
  );

  if (values.required_coi === 1) {
    insuranceStyle.color = 'red';
    insuranceStyle.title = 'COI Required';
  } else {
    insuranceStyle.color = '';
    insuranceStyle.title = 'Insurance';
  }

  return (
    <div className="new-table shadow mb-0">
      <div className="new-table--row">
        <div className="new-table--row--item waypoint-inputs">
          <span>Type: </span>
          <Select
            options={typeSet}
            value={values['type']}
            placeholder={`Select type`}
            name="type"
            onChange={({ value }) => setFieldValue('type', value)}
          />
        </div>
      </div>

      <div className="new-table--row">
        <div className="new-table--row--item waypoint-inputs height50">
          <Input name="customer_name" placeholder="Name" value={values['customer_name']} onChange={handleChange} />
        </div>
        <div className="new-table--row--item waypoint-inputs height50">
          <button type="button" onClick={() => open(modalNames.email, { type })}>
            <Icon icon="email" />
          </button>
          <Input name="email" placeholder="Email" value={values['email']} onChange={handleChange} />
        </div>
      </div>

      <div className="new-table--row">
        <div className="new-table--row--item waypoint-inputs height50">
          <Icon icon="home_phone" title="Home Phone" />
          <Input name="home_phone" mask="111-111-1111" placeholder="Home Phone" value={values['home_phone']} onChange={handleChange} />
        </div>
        <div className="new-table--row--item waypoint-inputs height50">
          <Icon icon="cell_phone" title="Cell Phone" />
          <Input name="cell_phone" mask="111-111-1111" placeholder="Cell Phone" value={values['cell_phone']} onChange={handleChange} />
        </div>
      </div>

      <div className="new-table--row">
        <div className="new-table--row--item p15">
          <Autocomplete
            name="address"
            placeholder="Search address"
            value={values['address']}
            onChange={(value) => setFieldValue('address', value)}
            onSelect={onSelectAutoCompleteHandler}
          />
        </div>
        <div className="new-table--row--item p15">
          <Input name="apartment" placeholder="apt" value={values['apartment']} onChange={handleChange} />
        </div>
        <div className="new-table--row--item p15">
          <Input name="building_name" placeholder="Building name" value={values['building_name']} onChange={handleChange} />
        </div>
      </div>

      <div className="new-table--row">
        <div className="new-table--row--item p15">
          <Autocomplete
            mask="11111"
            placeholder="Zip"
            name="zip"
            onBlur={zipHandler}
            value={values['zip']}
            shouldFetchSuggestions={false}
            onChange={(value) => setFieldValue('zip', value)}
          />
        </div>
        <div className="new-table--row--item p15">
          <span>{values.state || 'State'}</span>
        </div>
        <div className="new-table--row--item p15">
          <span>{values.city || 'City'}</span>
        </div>
      </div>

      <div className="new-table--row">
        <p className="new-table--row--item waypoint-inputs p15">Building Access:</p>
        <div className="new-table--row--item waypoint-inputs no-padding no-shadow">
          <Icon icon="property_access" title={!!values.building_access_start ? 'Access to building required' : 'Access to building not required'} />
          <TimeSelect
            name="building_access_start"
            value={values['building_access_start']}
            placeholder="Start time"
            onChange={({ name, date }) => setFieldValue(name, formatTimeAPMeridiem(date))}
          />
          <TimeSelect
            name="building_access_end"
            value={values['building_access_end']}
            placeholder="End time"
            onChange={({ name, date }) => setFieldValue(name, formatTimeAPMeridiem(date))}
          />
        </div>

        <p className="new-table--row--item p15">Elevator Access:</p>
        <div className="new-table--row--item waypoint-inputs no-padding no-shadow">
          <ElevatorMenu data={values} />
          <TimeSelect
            name="elevator_reservation_start"
            value={values['elevator_reservation_start']}
            placeholder="Start time"
            onChange={({ name, date }) => setFieldValue(name, formatTimeAPMeridiem(date))}
          />
          <TimeSelect
            name="elevator_reservation_end"
            value={values['elevator_reservation_end']}
            placeholder="End time"
            onChange={({ name, date }) => setFieldValue(name, formatTimeAPMeridiem(date))}
          />
        </div>
      </div>

      <div className="new-table--row">
        <div className="new-table--row--item waypoint-inputs">
          <span>Stairs: </span>
          <Select
            options={stairsSet}
            value={values['stairs']}
            placeholder={`Stairs in building`}
            name="stairs"
            onChange={({ value }) => setFieldValue('stairs', value)}
          />
        </div>

        <div className="new-table--row--item col-3 no-padding d-flex justify-content-center">
          <span className={`insurance-c ${insuranceStyle.color}`}>
            <Icon icon="move_out_insurance" title={insuranceStyle.title} />
          </span>
        </div>
      </div>
    </div>
  );
};

export default ExtraStopForm;

import React, { Fragment, useMemo } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import Preloader from 'components/Preloader';
import Filter from './Filter';
import ClaimsList from './ClaimsList';
import { useReportDashboardInfo, useReportFilterInfo, useGetDriverClaims } from 'hooks/query/useReport';
import { changePeriod } from '../reportUtils';

const Claims = () => {
  const { mutate: getClaims, data: claimsResponse, isLoading } = useGetDriverClaims();
  const claims = claimsResponse?.data;
  const { data } = useReportFilterInfo();

  const companiesList = useMemo(() => {
    if (!data) return [];

    const list = data.companies.map((company) => ({
      value: [company.id],
      label: company.company_name,
    }));
    list.unshift({ value: data.all_companies, label: 'All Companies' });

    return list;
  }, [data]);

  const { values, setFieldValue, handleSubmit, errors, touched } = useFormik({
    initialValues: {
      company_id: companiesList[0]?.value ?? 0,
      driver_id: null,
      from: null,
      to: null,
      period_type: 0,
    },
    onSubmit: (values) => getClaims(values),
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      driver_id: Yup.number().nullable().required('Select Driver'),
    }),
  });

  const isValidHandler = (name) => {
    if (errors[name] && touched[name]) return errors[name];
    return null;
  };

  const { data: drivers } = useReportDashboardInfo({ company_id: values.company_id });

  const driverList = drivers?.map((driver) => ({
    value: driver.id,
    label: driver.first_name + ' ' + driver.last_name,
  }));

  const handleSelect = (name, value) => setFieldValue(name, value);

  const selectDate = ({ name, date }) => setFieldValue(name, moment(date).format('MM/DD/YY'));

  const selectPeriod = ({ value }) => {
    const { from, to } = changePeriod(value);
    setFieldValue('from', from);
    setFieldValue('to', to);
    setFieldValue('period_type', value);
  };

  if (!data || !drivers) return null;

  return (
    <Fragment>
      {isLoading && <Preloader />}

      <Filter data={{ values, driverList, companiesList }} actions={{ isValidHandler, handleSelect, selectPeriod, selectDate, handleSubmit }} />
      {claims && <ClaimsList list={claims} />}
    </Fragment>
  );
};

export default Claims;

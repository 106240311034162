import { useMemo } from 'react';
import { useDefault } from 'hooks/query/useCommon';
import { useAvailableUnitsByDate, useInfo, useAssignedSchedule } from 'hooks/query/useCrew';
import { formatFullDBDate } from 'helpers/dateHelper';

const useFetchData = (params, alreadyHaveAssignedCrew, shouldFetchCrewInfo, actions) => {
  const { date, start_date, stop_date, job_id, crew_id, move_size_id, company_id } = params;
  const { handleGetAssignedCrew, handleGetNewCrew } = actions;

  const {
    data: { move_sizes: moveSizes },
  } = useDefault();

  const { data: availableUnits } = useAvailableUnitsByDate({ date, start_date, stop_date, job_id });

  useAssignedSchedule(
    {
      date: formatFullDBDate(start_date),
      job_id: job_id,
    },
    {
      enabled: Boolean(alreadyHaveAssignedCrew),
      onSuccess: (data) => handleGetAssignedCrew(data),
    }
  );

  useInfo(
    { crew_id, date, start_date, stop_date },
    {
      enabled: shouldFetchCrewInfo,
      onSuccess: (data) => handleGetNewCrew(data),
    }
  );

  const crewOptions = useMemo(() => {
    if (!availableUnits) return [];

    return availableUnits.crews.map((crew) => ({
      id: crew.id,
      value: crew.id,
      label: crew.name,
      overlap: crew.overlap,
    }));
  }, [availableUnits]);

  const availableMoversOptions = useMemo(() => {
    if (!availableUnits) return [];

    return availableUnits.movers.map((mover) => ({
      id: mover.id,
      value: mover.id,
      label: `${mover.name} ${mover.userlastname}`,
      type: 'mover',
      overlap: mover.overlap,
    }));
  }, [availableUnits]);

  const moveSize = useMemo(() => {
    return moveSizes[company_id].find((e) => e.id === move_size_id)?.name;
  }, [moveSizes, company_id, move_size_id]);

  return { availableUnits, crewOptions, availableMoversOptions, moveSize };
};

export { useFetchData };

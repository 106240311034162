import React, { Fragment, useEffect, memo, useMemo, useState, useCallback } from 'react';
import Pagination from 'react-paginate';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import { useCommunicationsList } from 'hooks/query/useCommunications';
import { getParams } from 'helpers/jobs';
import { updateRouteParams } from 'utils/history';
import { routePaths } from 'config/routes';
import FiltersSearch from './FiltersSearch';
import CallItem from './Item/CallItem';
import EmailItem from './Item/EmailItem';
import SmsItem from './Item/SmsItem';
import { useAuth } from 'context/auth';

const List = memo(() => {
  const { type } = useParams();
  const { pathname, search } = useLocation();
  const history = useHistory();

  const { user } = useAuth();

  const [perPage] = useState(20);
  const [page, setPage] = useState(0);
  const [selectedItems, setSelectedItems] = useState([]);

  const handleChangeParams = useCallback(
    ({ type, ...newParams }) => {
      const params = new URLSearchParams(search);
      Object.keys(newParams).forEach((key) => {
        if (params.has(key)) {
          params.set(key, newParams[key]);
          return;
        }
        params.append(key, newParams[key]);
      });

      history.replace({
        pathname: type ? updateRouteParams(routePaths.COMMUNICATIONS, { type }) : pathname,
        search: params.toString(),
      });
    },
    [history, pathname, search]
  );

  const params = useMemo(() => getParams(search), [search]);

  const { data: communications = [], refetch: onRefetchCommunicationsList } = useCommunicationsList({ ...params, type });

  const list = useMemo(() => {
    const params = {
      selectedItems,
      setSelectedItems,
      onChangeParams: handleChangeParams,
    };

    return communications.map((item) => {
      switch (item.entity_type) {
        case 'direct':
        case 'general':
        case 'inbound_calls':
        case 'general_calls':
        case 'outbound_calls':
        case 'direct_voicemail':
        case 'general_voicemail':
          return <CallItem key={'call' + item.id} {...item} {...params} />;
        case 'outbox_email':
          return <EmailItem key={'outbox_email' + item.id} {...item} {...params} />;
        case 'outbox_email_job':
          return <EmailItem key={'outbox_email_job' + item.id} {...item} {...params} />;
        case 'inbox_email':
          return <EmailItem key={'inbox_email' + item.id} {...item} {...params} />;
        case 'inbox_email_job':
          return <EmailItem key={'inbox_email_job' + item.id} {...item} {...params} />;
        case 'sms':
          return <SmsItem key={'sms' + item.id} {...item} {...params} />;
        case 'sms_chat':
          return <SmsItem key={'sms_chat' + item.id} {...item} {...params} />;
        case 'general_sms':
          return <SmsItem key={'general_sms' + item.id} {...item} {...params} />;
        case 'reminder':
          return <EmailItem key={'reminder' + item.id} {...item} {...params} />;
        case 'reminders_sent':
          return <EmailItem key={'reminders_sent' + item.id} {...item} {...params} />;
        case 'lead_missed_call':
          return <CallItem key={'call' + item.id} {...item} {...params} />;
        case 'lead_provider':
          return <CallItem key={'call' + item.id} {...item} {...params} />;
        case 'lead_provider_voicemail':
          return <CallItem key={'call' + item.id} {...item} {...params} />;
        case 'lead_unreplied_call':
          return <SmsItem key={'sms' + item.id} {...item} {...params} />;
        default:
          return null;
      }
    });
  }, [communications, selectedItems, handleChangeParams]);

  const currentItems = useMemo(() => {
    return list.slice(page * perPage, (page + 1) * perPage);
  }, [list, perPage, page]);

  const pageCount = useMemo(() => {
    if (!communications) return 1;
    return Math.ceil(communications.length / perPage);
  }, [communications, perPage]);

  useEffect(() => {
    const listener = (data) => {
      if (data.user_id === 0 || Number(data.user_id) === Number(user?.id)) {
        onRefetchCommunicationsList();
      }
    };

    window.socket.on('communication-list-refresh', listener);

    return () => {
      window.socket.off('communication-list-refresh', listener);
    };
  }, [onRefetchCommunicationsList, user]);

  return (
    <Fragment>
      <FiltersSearch type={type ?? 'all'} onRefetch={onRefetchCommunicationsList} selectedItems={selectedItems} />

      <div className="shadow">
        {currentItems}
        <Pagination
          forcePage={page}
          pageCount={pageCount}
          pageRangeDisplayed={5}
          marginPagesDisplayed={2}
          containerClassName={'email-pagination pagination'}
          subContainerClassName={'pages pagination'}
          activeClassName={'active'}
          onPageChange={(page) => setPage(page.selected)}
        />
      </div>
    </Fragment>
  );
});

export default List;

import { useState, useEffect } from 'react';
import { geocodeByAddress } from 'react-places-autocomplete';
import { initialState } from './inititalState';

const useJobState = (jobId, data) => {
  const [state, setState] = useState({
    ...initialState,
    job_id: jobId,
    hash_id: jobId,
  });

  const handleChange = (e) => setState({ ...state, [e.target.name]: e.target.value });

  const handleRadio = (name, value) => setState({ ...state, [name]: value });

  const handleChangeAddress = (address, name) => setState({ ...state, [name]: address });

  const handleSelect = (address, name) => {
    geocodeByAddress(address)
      .then((results) => {
        const place = results[0];
        let street_number = place.address_components.find((item) => item.types.includes('street_number'))
          ? place.address_components.find((item) => item.types.includes('street_number')).short_name
          : '';
        let address = place.address_components.find((item) => item.types.includes('route'))
          ? place.address_components.find((item) => item.types.includes('route')).long_name
          : '';
        let address_short = place.address_components.find((item) => item.types.includes('route'))
          ? place.address_components.find((item) => item.types.includes('route')).short_name
          : '';
        let zipcode = place.address_components.find((item) => item.types.includes('postal_code'))
          ? place.address_components.find((item) => item.types.includes('postal_code')).short_name
          : '';

        setState({ ...state, [name]: place.formatted_address });
        if (name === 'address_from') {
          setState({
            ...state,
            address_from: `${street_number} ${address.trim()} `,
            zip_from: zipcode,
          });
        } else {
          setState({
            ...state,
            address_to: `${street_number} ${address.trim()} `,
            zip_to: zipcode,
          });
        }
      })
      .catch((error) => console.error('Error', error));
  };

  useEffect(() => {
    if (data) {
      setState({ ...state, ...data });
    }
  }, [data]);

  return {
    state,
    actions: { handleChangeAddress, handleRadio, handleChange, handleSelect },
  };
};

export { useJobState };

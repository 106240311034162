import React, { useState, Fragment, memo, useMemo } from 'react';
import Icon from 'components/Icon';
import { preventArrowUpDown } from 'utils/preventArrowUpDown';

const Item = memo(({ item, add }) => {
  const [qty, setQty] = useState(1);

  return (
    <div className="new-table--row">
      <div className="new-table--row--item flex-grow-1">
        <span>{item.name}</span>
      </div>
      <div className="new-table--row--item w10 justify-content-center">
        <span>{item.amount}</span>
      </div>
      <div className="new-table--row--item w15 justify-content-center">
        <input type="number" value={qty} style={{ textAlign: 'center' }} onKeyDown={preventArrowUpDown} onChange={(e) => setQty(+e.target.value)} />
      </div>
      <div className="new-table--row--item w20 no-padding">
        <button type="button" onClick={() => add({ ...item, qty, inventory_id: item.id })} disabled={qty === 0} className="flex-grow-1">
          <Icon icon="add" size={18} title="Add" />
        </button>
      </div>
    </div>
  );
});

const Group = memo(({ inventory, i, type, addItem }) => {
  const [open, setOpen] = useState(false);
  const isInventory = !!inventory.length;

  const list = useMemo(() => inventory.slice(0, !open ? 5 : inventory.length), [inventory, open]);

  return (
    <Fragment>
      {list.map((el) => (
        <Item key={(i + 1) * el.id} item={el} type={type} add={addItem} />
      ))}
      <div className="new-table--row">
        <div className="new-table--row--item no-padding">
          {isInventory ? (
            inventory.length > 5 && (
              <button type="button" className="flex-grow-1 default p20" onClick={() => setOpen((pre) => !pre)}>
                {open ? 'Show less' : 'Show more'}
              </button>
            )
          ) : (
            <span className="text-center default flex-grow-1 p20">Table is empty</span>
          )}
        </div>
      </div>
    </Fragment>
  );
});

export default Group;

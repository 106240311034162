import React, { Fragment } from 'react';
import { optionsRate } from 'helpers/PaymentData';
import CheckBox from 'components/newComponents/CheckBox';
import Input from 'components/newComponents/Input';
import Select from 'components/newComponents/Select';
import { useForm } from '../../../hooks/useForm';
import { getCurrentMoveSize } from '../../../utils';

function Move({ moveSizes }) {
  const { values, actions } = useForm();

  const currentMoveSize = getCurrentMoveSize(moveSizes, values.move_size_id);

  return (
    <Fragment>
      <div className="new-table--row">
        <div className="new-table--row--item select">
          <Select
            name="move_size_id"
            value={values['move_size_id']}
            options={moveSizes}
            placeholder="Move sizes"
            error={actions.isValidHandler('move_size_id')}
            onChange={actions.handleSelectMoveSize}
          />
        </div>
      </div>

      {values.move_size_id && values.job_type !== 'long_distance' && (
        <Fragment>
          <div className="new-table--row">
            <div className="new-table--row--item">
              <CheckBox
                name="credit_rate"
                className="justify-content-between"
                label="Add credit rate?"
                checked={values['credit_rate'] === 1}
                onChange={(event) => actions.handleChangeCreditRate(event, currentMoveSize)}
              />
            </div>
            {values.rate_type === 1 ? (
              <div className="new-table--row--item">
                <Input name="default_rate" value={values['default_rate']} placeholder="Default rate" disabled />
              </div>
            ) : (
              <div className="new-table--row">
                <div className="new-table--row--item">
                  <Input name="flat_rate" type="number" value={values['flat_rate']} placeholder="Rate" />
                </div>
              </div>
            )}
          </div>

          <div className="new-table--row">
            <div className="new-table--row--item select">
              <Select
                options={optionsRate}
                placeholder="Rate type"
                name="rate_type"
                value={values['rate_type']}
                onChange={({ value }) => {
                  actions.setValues({ ...values, rate_type: value, value, manual_rate: 0 });
                }}
              />
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
}

export default Move;

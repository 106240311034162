import React, { Fragment } from 'react';

function TotalHead() {
  return (
    <Fragment>
      <div className="new-table--row">
        <span className="q-view">Quick view</span>
      </div>

      <div className="new-table--row bcg-light">
        <div className="new-table--row--item w30">
          <span>Name</span>
        </div>

        <div className="new-table--row--item w15 justify-content-center">
          <span>CF</span>
        </div>

        <div className="new-table--row--item  justify-content-center">
          <span>Inventory Conditions</span>
        </div>

        <div className="new-table--row--item w10 justify-content-center no-side-shadows">
          <span />
        </div>
      </div>
    </Fragment>
  );
}

export default TotalHead;

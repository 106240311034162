import React, { Fragment, useCallback, useMemo } from 'react';
import { useModal } from 'context/modal';
import { useInventoryForm } from './useInventoryForm';
import { useDefault } from 'hooks/query/useCommon';
import { ModalButton } from 'components/newComponents/Buttons';
import Select from 'components/newComponents/CreatableSelect';
import Modal from 'components/newComponents/Modal';
import Categories from './components/Categories';
import Total from './components/Total';

const InventoryJobModal = () => {
  const { modalNames, close, info } = useModal();

  const isVisible = info(modalNames.inventory).visible;
  const type = info(modalNames.inventory)?.params?.type;
  const jobID = info(modalNames.inventory)?.params?.jobID;
  const companyID = info(modalNames.inventory)?.params?.companyID;
  const jobInventory = info(modalNames.inventory)?.params?.jobInventory;
  const onSubmit = info(modalNames.inventory)?.params?.onSubmit;
  const closeHandler = () => close(modalNames.inventory);

  const {
    data: { inventory, inventory_categories: categories, statuses },
  } = useDefault();

  const currentInventory = inventory[companyID] ?? [];

  const inventoryOptions = useMemo(() => {
    return currentInventory.map((e) => ({
      value: e.name,
      label: e.name,
      category: categories[e.category_id],
    }));
  }, [categories, currentInventory]);

  const { values, actions } = useInventoryForm({
    jobID,
    jobInventory,
    currentInventory,
    categories,
    onSubmit,
    closeHandler,
  });

  const formatOptionLabel = useCallback(({ label, category }) => {
    return (
      <Fragment>
        {label}
        <br />
        <strong>{category}</strong>
      </Fragment>
    );
  }, []);

  return (
    <Modal style={{ width: 1850 }} header="Inventory" show={isVisible} onHide={closeHandler}>
      <form onSubmit={actions.handleSubmit} className="flex-grow-1">
        <div className="inventory-modal">
          <div className="inventory-categories">
            <div className="new-table modal">
              <div className="new-table--row">
                <div className="new-table--row--item align-items-center" style={{ paddingTop: 12, paddingBottom: 12 }}>
                  <Select
                    isClearable
                    isCreatable
                    options={inventoryOptions}
                    inputValue={values.search}
                    placeholder="Search"
                    onChange={actions.handleSelect}
                    onBlur={actions.handleBlur}
                    onInputChange={(v) => actions.handleSearch(v)}
                    formatOptionLabel={formatOptionLabel}
                    wrapperStyle={{ height: 40, justifyContent: 'center' }}
                  />
                </div>
              </div>
            </div>

            <div className="new-table modal" style={{ height: 540, overflowY: 'scroll' }}>
              <Categories inventoryByCategories={values.inventoryByCategories} type={type} addItem={actions.addNewItem} />
            </div>
          </div>

          <Total
            statuses={statuses}
            onChangeDimension={actions.changeDimensionHandler}
            onRemoveItem={actions.removeItemHandler}
            handleChangeCF={actions.handleChangeCF}
            handleChangeSymbol={actions.handleChangeSymbol}
            items={values.items}
          />
        </div>

        <div className="footer-buttons">
          <ModalButton title="Cancel" variant="cancel" onClick={closeHandler} />
          <ModalButton title="Add" variant="confirm" type="submit" />
        </div>
      </form>
    </Modal>
  );
};

export default InventoryJobModal;

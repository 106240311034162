export const options = [
  {
    value: 1,
    label:
      'Option 1 - Standard Valuation Coverage: PLEASE READ CAREFULLY - by choosing this Option you are waiving certain valuable coverage which protects your possessions above the minimum amounts by law.',
  },
  {
    value: 2,
    label:
      'Option 2 - Additional Valuation Coverage:Additional coverage is available to compensate you for goods lost or damaged at an amount closer to the declared or replacement value.',
  },
];

export const modalNames = {
  terms_of_payment: 'terms_of_payment',
  acknowledge_terms: 'acknowledge_terms',
  insurance_options: 'insurance_options',
  customer_sign: 'customer_sign',
  customer_initial: 'customer_initial',
};

import { useEffect, useMemo, useState } from 'react';

const useStripeKey = (companyId) => {
  const [stripe, setStripe] = useState(null);

  const stripeKey = useMemo(() => {
    if (!companyId) return null;

    switch (Number(companyId)) {
      case 1:
      case 6:
      case 7:
        return 'pk_live_SFJ8vI45elqWgEaAbQu71dyj';
      case 2:
        return 'pk_live_pGJKKOheBJ34z9mXPHLqd4uL';
      case 3:
      case 4:
      case 5:
      case 8:
        return 'pk_live_ijCsm5eGnaGrOtYlHfQhtvkz';
      default:
        return 'pk_live_SFJ8vI45elqWgEaAbQu71dyj';
    }
  }, [companyId]);

  useEffect(() => {
    if (stripeKey) {
      if (window.Stripe) {
        setStripe(window.Stripe(stripeKey));
      } else {
        document.querySelector('#stripe-js').addEventListener('load', () => {
          // Create Stripe instance once Stripe.js loads
          setStripe(window.Stripe(stripeKey));
        });
      }
    }
  }, [stripeKey]);

  return { stripe };
};

export { useStripeKey };

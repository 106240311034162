import React from 'react';
import { useParams } from 'react-router-dom';
import { useInfo } from 'hooks/query/useJob';
import { useDefault } from 'hooks/query/useCommon';
import { useModal } from 'context/modal';

const CarriersTab = () => {
  const { id } = useParams();

  const { data: defaultItems } = useDefault();

  const { open, modalNames } = useModal();

  const {
    data: { job_carrier: jobCarrier },
  } = useInfo(id);

  const carrier = defaultItems?.carriers.find((item) => item.id === jobCarrier.carrier_id);
  const driver = defaultItems?.carrier_drivers.find((item) => item.id === jobCarrier.carrier_driver_id);

  const openChangeCarrierModal = () => {
    open(modalNames.changeJobCarrier, {
      job_id: id,
      makeApiRequestOnSubmit: true,
      jobCarrier,
    });
  };

  return (
    <div className="shadow w49">
      <h3 className="header">Carriers</h3>

      <div className="new-table">
        {carrier && (
          <div className="new-table--row">
            <div className="new-table--row--item no-side-shadows">Carrier:</div>
            <div className="new-table--row--item no-side-shadows">
              <span className="d-flex align-items-center">
                <b>{carrier.name}</b>
              </span>
            </div>
            <div className="new-table--row--item no-side-shadows">CF price:</div>
            <div className="new-table--row--item no-side-shadows">
              <span className="d-flex align-items-center">
                <b>{jobCarrier.cf_price}</b>
              </span>
            </div>
          </div>
        )}
      </div>

      {driver && (
        <div className="new-table--row">
          <div className="new-table--row--item no-side-shadows">Driver name:</div>
          <div className="new-table--row--item no-side-shadows">
            <span className="d-flex align-items-center">
              <b>{driver.name}</b>
            </span>
          </div>
          <div className="new-table--row--item no-side-shadows">Driver phone:</div>
          <div className="new-table--row--item no-side-shadows">
            <span className="d-flex align-items-center">
              <b>{driver.phone}</b>
            </span>
          </div>
        </div>
      )}

      <div className="new-table--row">
        <div className="new-table--row--item no-side-shadows">
          <button type="button" className="main" style={{ padding: 0 }} onClick={openChangeCarrierModal}>
            Edit carrier
          </button>
        </div>
      </div>
    </div>
  );
};

export default CarriersTab;

import React, { Fragment } from 'react';
import JobInfoStatus from './JobInfoStatus';
import JobInfoTabMenu from './JobInfoTabMenu';

const JobInfo = () => {
  return (
    <div className="new-table" style={{ marginBottom: 20 }}>
      <div className="new-table--row justify-content-between">
        <Fragment>
          <JobInfoStatus />
          <JobInfoTabMenu />
        </Fragment>
      </div>
    </div>
  );
};

export default JobInfo;

import React, { Component } from 'react';
import Icon from 'components/Icon';
import SinglePoint from 'components/GoogleMap/SinglePoint';

import './JobExtraStopItem.scss';

class JobExtraStopItem extends Component {
  state = { isMapVisible: false };

  toggleMap = () => this.setState((prevState) => ({ isMapVisible: !prevState.isMapVisible }));

  render() {
    const { zip } = this.props.data;
    return (
      <div className="item-job-stop">
        <div className="item-job-stop--info">
          <Icon icon="zip" title="Zip" />
          <p>Zip Address: {zip}</p>
        </div>
        <div className="item-job-stop--map">
          <button className="item-job-stop--map--show" onClick={this.toggleMap}>
            Map
          </button>
          {this.state.isMapVisible && <SinglePoint fullWidth mapHeight={400} marker={zip} />}
        </div>
      </div>
    );
  }
}

export default JobExtraStopItem;

import React from 'react';
import './JobBanner.scss';
import background from 'assets/new_img/banner_job.png';

const JobBanner = () => {
  return (
    <div
      className="wrapper_job_banner"
      style={{ backgroundImage: `url(${background})`, height: window.innerHeight - 85 }}
    >
      <div className="wrap_banner" />
    </div>
  );
};

export default JobBanner;

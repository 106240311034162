import React, { Fragment } from 'react';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router';
import queryKeys from 'config/queryKeys';
import Icon from 'components/Icon';
import CheckBox from 'components/newComponents/CheckBox';
import { preventArrowUpDown } from 'utils/preventArrowUpDown';
import { useDefault } from 'hooks/query/useCommon';
import { useTabInfo, useAddOtherServices, useInfo } from 'hooks/query/useJob';
import { useManualLongDistanceService } from './hooks/useManualLongDistanceService';

const LDServices = () => {
  const { id, tab } = useParams();

  const { data: defaultItems } = useDefault();

  const { data: inventory } = useTabInfo(id, tab);

  const queryClient = useQueryClient();

  const {
    data: { related_jobs },
  } = useInfo(id);

  const isPickup = related_jobs?.long_delivery_job !== 0 && related_jobs?.pickup_job === 0;
  const isDelivery = related_jobs?.pickup_job !== 0 && related_jobs?.long_delivery_job === 0;

  const selectedOtherServices = inventory?.job_other_services ?? [];

  const manualServices = selectedOtherServices.filter((i) => i.service_id === 0);

  const { mutate } = useAddOtherServices();

  const handleUpdate = (selected, params) => {
    mutate(
      { job_other_services: selected, job_id: id, type: isPickup ? 'origin' : 'destination' },
      {
        onSuccess: () => {
          if (params?.onSuccess) params.onSuccess();
        },
        onSettled: () => {
          queryClient.invalidateQueries([queryKeys.job, id, tab]);
          queryClient.invalidateQueries([queryKeys.job, id]);
          queryClient.invalidateQueries([queryKeys.job, related_jobs?.long_delivery_job, tab]);
          queryClient.invalidateQueries([queryKeys.job, related_jobs?.pickup_job, tab]);
          queryClient.invalidateQueries([queryKeys.job, related_jobs?.storage_job, tab]);
        },
      }
    );
  };

  const handleCheckbox = (service) => {
    const isAlreadySelected = selectedOtherServices.find((item) => item.service_id === service.id);
    let selected = [];

    if (isAlreadySelected) {
      selected = selectedOtherServices.filter((item) => item.service_id !== service.id);
    } else {
      selected = [
        ...selectedOtherServices,
        {
          job_id: id,
          service_id: service.id,
          origin_price: isPickup ? service.price : 0,
          dest_price: isDelivery ? service.price : 0,
          name: service.name,
        },
      ];
    }

    handleUpdate(selected);
  };

  const handleBlur = (event, name, selectedIndex) => {
    const value = event.target.value;

    const prepared = selectedOtherServices.map((item, index) => {
      if (index === selectedIndex) {
        return { ...item, [name]: value };
      }

      return item;
    });

    handleUpdate(prepared);
  };

  const manualService = useManualLongDistanceService({ jobId: id });

  const handleManualServiceBlur = (service) => {
    if (!service) return;
    if (!service.name || (!service.origin_price && !service.dest_price)) return;

    const prepared = [...selectedOtherServices, service];

    handleUpdate(prepared, {
      onSuccess: () => manualService.actions.removeService(),
    });
  };

  const handleDeleteManualService = (id) => {
    const prepared = selectedOtherServices.filter((i) => i.id !== id);

    handleUpdate(prepared, {
      onSuccess: () => manualService.actions.removeService(),
    });
  };

  return (
    <div className="inventory-tab">
      <h3 className="header table-header-shadow" style={{ width: '100%' }}>
        LD SERVICES
      </h3>

      <div>
        <div className="ld-head">
          <div className="d-flex ld-b-r" style={{ width: '50%' }}>
            <div style={{ width: '10%', padding: 12 }}></div>
            <div style={{ width: '45%', padding: 12 }}>Name</div>
            <div style={{ width: '45%', padding: 12 }}>{isPickup ? 'Origin Price' : isDelivery ? 'Destination Price' : ''}</div>
          </div>
          <div className="d-flex ld-b-l" style={{ width: '50%' }}>
            <div style={{ width: '10%', padding: 12 }}></div>
            <div style={{ width: '45%', padding: 12 }}>Name</div>
            <div style={{ width: '45%', padding: 12 }}>{isPickup ? 'Origin Price' : isDelivery ? 'Destination Price' : ''}</div>
          </div>
        </div>

        <div style={{ columns: 2, columnGap: 0 }}>
          {defaultItems?.other_services?.map((item) => {
            const isSelected = selectedOtherServices.length && Boolean(selectedOtherServices.find((selected) => selected.service_id === item.id));
            const selectedIndex = selectedOtherServices.findIndex((selected) => selected.service_id === item.id);
            const priceOrigin = isSelected ? selectedOtherServices[selectedIndex].origin_price : item.price;
            const priceDest = isSelected ? selectedOtherServices[selectedIndex].dest_price : item.price;

            return (
              <div className="d-flex ld-b-l ld-b-r ld-b-full" key={item.id}>
                <div className="ld-cell" style={{ width: '10%', padding: 12 }}>
                  <CheckBox className="justify-content-center" checked={isSelected} onChange={() => handleCheckbox(item)} />
                </div>

                <div className="ld-cell" style={{ width: '45%' }}>
                  {item.name}
                </div>

                <div className="ld-cell ld-b-l ld-b-r" style={{ width: '45%' }}>
                  <input
                    style={{ width: '100%' }}
                    type="number"
                    disabled={!isSelected}
                    defaultValue={isPickup ? priceOrigin : priceDest}
                    onKeyDown={preventArrowUpDown}
                    onBlur={(event) => handleBlur(event, isPickup ? 'origin_price' : 'dest_price', selectedIndex)}
                  />
                </div>
              </div>
            );
          })}

          {manualServices.map((item) => {
            const index = selectedOtherServices.findIndex((selected) => selected.id === item.id);

            return (
              <div className="d-flex ld-b-l ld-b-r ld-b-full" key={item.id}>
                <div className="ld-cell" style={{ width: '10%', padding: 12 }}></div>

                <div className="ld-cell" style={{ width: '45%' }}>
                  {item.name}
                </div>

                <div className="ld-cell ld-b-l" style={{ width: '35%' }}>
                  <input
                    style={{ width: '100%' }}
                    type="number"
                    defaultValue={isPickup ? item.origin_price : item.dest_price}
                    onKeyDown={preventArrowUpDown}
                    onBlur={(event) => handleBlur(event, isPickup ? 'origin_price' : 'dest_price', index)}
                  />
                </div>

                <div className="ld-cell" style={{ width: '10%' }}>
                  <button onClick={() => handleDeleteManualService(item.id)}>
                    <Icon icon="delete" title="Remove item" size={16} />
                  </button>
                </div>
              </div>
            );
          })}

          <div className="d-flex ld-b-l ld-b-r ld-b-full" style={{ minHeight: 54 }}>
            {manualService.service ? (
              <Fragment>
                <div className="ld-cell" style={{ width: '10%', padding: 12 }}>
                  <button onClick={manualService.actions.removeService}>
                    <Icon icon="delete" title="Remove item" size={16} />
                  </button>
                </div>

                <div className="ld-cell" style={{ width: '45%' }}>
                  <input
                    style={{ width: '100%' }}
                    type="text"
                    defaultValue={manualService.service.name}
                    onChange={(event) => manualService.actions.onChange('name', event.target.value)}
                    onBlur={() => handleManualServiceBlur(manualService.service)}
                  />
                </div>

                <div className="ld-cell ld-b-l ld-b-r" style={{ width: '45%' }}>
                  <input
                    style={{ width: '100%' }}
                    type="number"
                    defaultValue={0}
                    onKeyDown={preventArrowUpDown}
                    onChange={(event) => manualService.actions.onChange(isPickup ? 'origin_price' : 'dest_price', event.target.value)}
                    onBlur={() => handleManualServiceBlur(manualService.service)}
                  />
                </div>
              </Fragment>
            ) : (
              <div className="ld-cell justify-content-center" style={{ width: '100%' }}>
                <button type="button" onClick={manualService.actions.addService}>
                  <Icon icon="add" title="Long Distance Service" size={16} />
                  Add Service
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LDServices;

import React, { useMemo } from 'react';
import { useParams } from 'react-router';
import { useDefault } from 'hooks/query/useCommon';
import { useTabInfo } from 'hooks/query/useJob';

const AdditionalInfo = ({ type }) => {
  const { id, tab } = useParams();

  const {
    data: { additional_info: info },
  } = useTabInfo(id, tab);

  const dataTo = info['to'];
  const dataFrom = info['from'];

  const {
    data: { statuses },
  } = useDefault();

  const buildingAccessTo = useMemo(() => {
    if (!dataTo?.building_access_start || !dataTo?.building_access_end) return '-';
    return `${dataTo.building_access_start} - ${dataTo.building_access_end}`;
  }, [dataTo]);

  const buildingAccessFrom = useMemo(() => {
    if (!dataFrom?.building_access_start || !dataFrom?.building_access_end) return '-';
    return `${dataFrom.building_access_start} - ${dataFrom.building_access_end}`;
  }, [dataFrom]);

  const elevatorReservationTo = useMemo(() => {
    if (!dataTo?.elevator_reservation_start || !dataTo?.elevator_reservation_end) return 'No';
    return `${dataTo.elevator_reservation_start} - ${dataTo.elevator_reservation_end}`;
  }, [dataTo]);

  const elevatorReservationFrom = useMemo(() => {
    if (!dataFrom?.elevator_reservation_start || !dataFrom?.elevator_reservation_end) return 'No';
    return `${dataFrom.elevator_reservation_start} - ${dataFrom.elevator_reservation_end}`;
  }, [dataFrom]);

  return (
    <div className="additional-info__container">
      <div className="new-table">
        <div className="new-table--row">
          <div className="new-table--row--item" />
          <div className="new-table--row--item">
            <h3 className="header no-padding">{'From'}</h3>
          </div>
          <div className="new-table--row--item">
            <h3 className="header no-padding">{'To'}</h3>
          </div>
        </div>
        <div className="new-table--row">
          <div className="new-table--row--item">
            <span>Type of building:</span>
          </div>
          <div className="new-table--row--item">
            <b className="ml-a">{statuses['building_type'][dataFrom?.type] ?? '-'}</b>
          </div>
          <div className="new-table--row--item">
            <b className="ml-a">{statuses['building_type'][dataTo?.type] ?? '-'}</b>
          </div>
        </div>
        <div className="new-table--row">
          <div className="new-table--row--item">
            <span>Building access:</span>
          </div>
          <div className="new-table--row--item text-right">
            <b className="ml-a">{buildingAccessFrom}</b>
          </div>
          <div className="new-table--row--item text-right">
            <b className="ml-a">{buildingAccessTo}</b>
          </div>
        </div>
        <div className="new-table--row">
          <div className="new-table--row--item">
            <span>Elevator exist:</span>
          </div>
          <div className="new-table--row--item">
            <b className="ml-a">{dataFrom?.elevator_exist ? 'Yes' : 'No'}</b>
          </div>
          <div className="new-table--row--item">
            <b className="ml-a">{dataTo?.elevator_exist ? 'Yes' : 'No'}</b>
          </div>
        </div>
        <div className="new-table--row">
          <div className="new-table--row--item">
            <span>Elevator reservation:</span>
          </div>
          <div className="new-table--row--item">
            <b className="ml-a">{elevatorReservationFrom}</b>
          </div>
          <div className="new-table--row--item">
            <b className="ml-a">{elevatorReservationTo}</b>
          </div>
        </div>
        <div className="new-table--row">
          <div className="new-table--row--item">
            <span>Stairs:</span>
          </div>
          <div className="new-table--row--item">
            <b className="ml-a">{dataFrom?.estimated_stairs ?? '-'}</b>
          </div>
          <div className="new-table--row--item">
            <b className="ml-a">{dataTo?.estimated_stairs ?? '-'}</b>
          </div>
        </div>
        <div className="new-table--row">
          <div className="new-table--row--item">
            <span>
              Distance from apartment door
              <br /> to the elevator:
            </span>
          </div>
          <div className="new-table--row--item">
            <b className="ml-a">
              {parseInt(dataFrom?.distance_elevator_apartment) > 0 ? `${parseInt(dataFrom?.distance_elevator_apartment)} yd.` : '-'}
            </b>
          </div>
          <div className="new-table--row--item">
            <b className="ml-a">{parseInt(dataTo?.distance_elevator_apartment) > 0 ? `${parseInt(dataTo?.distance_elevator_apartment)} yd.` : '-'}</b>
          </div>
        </div>
        <div className="new-table--row">
          <div className="new-table--row--item">
            <span>
              Distance from elevator to the <br />
              loading dock/truck parking:
            </span>
          </div>
          <div className="new-table--row--item">
            <b className="ml-a">{parseInt(dataFrom?.distance_elevator_parking) > 0 ? `${parseInt(dataFrom?.distance_elevator_parking)} yd.` : '-'}</b>
          </div>
          <div className="new-table--row--item">
            <b className="ml-a">{parseInt(dataTo?.distance_elevator_parking) > 0 ? `${parseInt(dataTo?.distance_elevator_parking)} yd.` : '-'}</b>
          </div>
        </div>
        <div className="new-table--row">
          <div className="new-table--row--item">
            <span>Need floor/wall protection:</span>
          </div>
          <div className="new-table--row--item">
            <b className="ml-a">
              {dataFrom?.hardwood_protect ? 'Hardwood;' : ''}
              {dataFrom?.marble_protect ? ' Marble;' : ''}
              {dataFrom?.carpet_protect ? ' Carpet;' : ''}
              {!dataFrom?.hardwood_protect && !dataFrom?.marble_protect && !dataFrom?.carpet_protect ? '-' : ''}
            </b>
          </div>
          <div className="new-table--row--item">
            <b className="ml-a">
              {dataTo?.hardwood_protect ? 'Hardwood;' : ''}
              {dataTo?.marble_protect ? ' Marble;' : ''}
              {dataTo?.carpet_protect ? ' Carpet;' : ''}
              {!dataTo?.hardwood_protect && !dataTo?.marble_protect && !dataTo?.carpet_protect ? '-' : ''}
            </b>
          </div>
        </div>
        <div className="new-table--row">
          <div className="new-table--row--item">
            <span>Need Hoisting of heavy items:</span>
          </div>
          <div className="new-table--row--item">
            <b className="ml-a">{dataFrom?.hoisting ? 'Yes' : 'No'}</b>
          </div>
          <div className="new-table--row--item">
            <b className="ml-a">{dataTo?.hoisting ? 'Yes' : 'No'}</b>
          </div>
        </div>
        <div className="new-table--row">
          <div className="new-table--row--item">
            <span>Hoisting note:</span>
          </div>
          <div className="new-table--row--item">
            <b className="ml-a">{dataFrom?.hoisting_note ?? '-'}</b>
          </div>
          <div className="new-table--row--item">
            <b className="ml-a">{dataTo?.hoisting_note ?? '-'}</b>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdditionalInfo;

import React, { Fragment, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTabInfo } from 'hooks/query/useJob';
import { useDefault } from 'hooks/query/useCommon';
import Icon from 'components/Icon';

const Payments = () => {
  const { id, tab } = useParams();

  const {
    data: { job_payments: jobPayments },
  } = useTabInfo(id, tab);

  const { data: defaultItems } = useDefault();

  const [isVisible, setIsVisible] = useState(false);
  const [src, setSrc] = useState(null);

  const total = useMemo(() => {
    return jobPayments.reduce((sum, item) => {
      if (item.type === 4) return sum - parseFloat(item.amount);
      return sum + parseFloat(item.amount);
    }, 0);
  }, [jobPayments]);

  if (!defaultItems || !jobPayments) return null;

  return (
    <Fragment>
      <div className="new-table shadow">
        <div className="new-table--row">
          <div className="new-table--row--item no-side-shadows justify-content-center no-padding">
            <h3 className="header">Job Payments</h3>
          </div>
        </div>

        <div className="new-table--row bcg-light">
          <div className="new-table--row--item no-side-shadows justify-content-center">
            <b>#</b>
          </div>
          <div className="new-table--row--item no-side-shadows justify-content-center">
            <b>Type</b>
          </div>
          <div className="new-table--row--item no-side-shadows justify-content-center">
            <b>Amount</b>
          </div>
          <div className="new-table--row--item no-side-shadows justify-content-center">
            <b>Date</b>
          </div>
        </div>

        {jobPayments.map((item) => (
          <div className="new-table--row" key={item.id}>
            <div className="new-table--row--item no-side-shadows  w25 justify-content-center">
              <span>{item.id}</span>
            </div>
            <div className="new-table--row--item no-side-shadows  w25 justify-content-center">
              <span>
                {defaultItems.statuses.payment_types[item.type]}
                {item.type === 3 && (
                  <button
                    onClick={() => {
                      setIsVisible((pre) => !pre);
                      setSrc(item.image);
                    }}
                  >
                    <Icon icon="sold" size={20} title="View check" />
                  </button>
                )}
                {!!item.deposit && ' Deposit'}
              </span>
            </div>
            <div className="new-table--row--item no-side-shadows  w25 justify-content-center">
              <span>
                ${item.amount} {item.type === 4 && <Icon icon="task_unfinished" size={20} />}
              </span>
            </div>
            <div className="new-table--row--item no-side-shadows  w25 justify-content-center">
              <span>{item.date}</span>
            </div>
          </div>
        ))}
        <div className="new-table--row bcg-light">
          <div className="new-table--row--item no-side-shadows  w25 justify-content-center">
            <b>Total</b>
          </div>
          <div className="new-table--row--item no-side-shadows  w25 justify-content-center">
            <b />
          </div>
          <div className="new-table--row--item no-side-shadows  w25 justify-content-center">
            <b>${total.toFixed(2)}</b>
          </div>
          <div className="new-table--row--item no-side-shadows  w25 justify-content-center">
            <b />
          </div>
        </div>
      </div>

      {isVisible && (
        <div className="check">
          <div className="check-content">
            <div className="check-wrapper">
              <button
                type="button"
                className="check-content-close"
                onClick={() => {
                  setIsVisible((pre) => !pre);
                  setSrc(null);
                }}
              >
                <Icon rotateDeg={45} icon="add" />
              </button>

              <img src={`${process.env.REACT_APP_API_URL}${src}`} width={200} height={200} alt="check" />
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default Payments;

function getContractInfo(contract = {}) {
  let title = '';
  let iconClassName = '';

  if (Boolean(contract.signed)) {
    iconClassName = 'icon-green';
    title = 'Contract Signed';
  }

  if (Boolean(contract.email_sent) && !Boolean(contract.signed)) {
    iconClassName = 'icon-yellow';
    title = 'Email Sent';
  }

  if (!Boolean(contract.email_sent) && !Boolean(contract.signed)) {
    iconClassName = 'icon-red';
    title = 'Not Signed Click to send contract to sign';
  }

  return { title, iconClassName };
}

export { getContractInfo };

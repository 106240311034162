import { useQueryClient } from 'react-query';
import { useCreateCarrierDriver } from 'hooks/query/useJob';
import queryKeys from 'config/queryKeys';

export const useSubmit = (params = {}) => {
  const { onSuccess } = params;

  const queryClient = useQueryClient();

  const { mutate: createCarrierDriver, isLoading: isMutationRunning } = useCreateCarrierDriver();

  const handleCreateDriver = (formValues) => {
    if (!formValues.name || !formValues.phone || !formValues.carrier_id) return;

    createCarrierDriver(formValues, {
      onSuccess: ({ data }) => {
        const createdDriverId = data.driver.id;

        queryClient.invalidateQueries([queryKeys.defaultItems]);
        queryClient.invalidateQueries([queryKeys.carrierDrivers]);

        if (typeof onSuccess === 'function') {
          onSuccess(createdDriverId);
        }
      },
    });
  };

  return {
    onSubmit: handleCreateDriver,
    isMutationRunning,
  };
};

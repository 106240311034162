import React, { memo } from 'react';

const DiscountItem = memo(({ item, type, handleChange }) => {
  return (
    <div className="new-table--row">
      <div className="new-table--row--item">
        <label className="start-value">
          <input type="checkbox" name="checked" checked={item.checked} onChange={() => handleChange(item.id)} />
          <b>{item.discount_name}</b>
        </label>
      </div>
      <div className="new-table--row--item">
        <b>{item.amount}</b>
      </div>
    </div>
  );
});

export default DiscountItem;

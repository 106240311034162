const optionsRate = [
  {
    id: 1,
    value: 1,
    label: 'Hourly',
  },
  {
    id: 2,
    value: 2,
    label: 'Flat',
  },
];

const paymentType = [
  {
    id: 1,
    value: 'Cash',
    label: 'Cash',
  },
  {
    id: 2,
    value: 'Credit',
    label: 'Credit',
  },
];
const optionsReferral = [
  {
    id: 1,
    value: 1,
    label: 'Google',
  },
  {
    id: 2,
    value: 2,
    label: 'Angies list',
  },
  {
    id: 3,
    value: 3,
    label: 'YELP',
  },
  {
    id: 4,
    value: 4,
    label: 'Returning',
  },
  {
    id: 5,
    value: 5,
    label: 'Referral',
  },
];

const laborType = [
  {
    id: 1,
    value: 'default',
    label: 'Moving',
  },
  {
    id: 2,
    value: 'same_property',
    label: 'Move within a same property',
  },
  {
    id: 3,
    value: 'unloading',
    label: 'Unloading Only',
  },
  {
    id: 4,
    value: 'packing',
    label: 'Packing Only',
  },
];

export { optionsRate, paymentType, optionsReferral, laborType };

import React from 'react';
import ProviderUser from './ProviderUser';
import { NoResultsFound } from '../NoResultsFound';

const ProviderUserList = ({ list }) => {
  if (!list.length) return <NoResultsFound />;

  return (
    <div className="my-3">
      <div className="reports-table">
        <div className="reports-table-row">
          <div className="reports-table-column reports-table-column--12 reports-table-column--title">Name</div>
          <div className="reports-table-column reports-table-column--12 reports-table-column--title">Company</div>
          <div className="reports-table-column reports-table-column--12 reports-table-column--title">Calls</div>
          <div className="reports-table-column reports-table-column--12 reports-table-column--title">Jobs</div>
          <div className="reports-table-column reports-table-column--12 reports-table-column--title">Booked</div>
          <div className="reports-table-column reports-table-column--12 reports-table-column--title">Lost</div>
          <div className="reports-table-column reports-table-column--12 reports-table-column--title">Canceled</div>
          <div className="reports-table-column reports-table-column--12 reports-table-column--title">Completed</div>
          <div className="reports-table-column reports-table-column--12 reports-table-column--title">Lead Cost</div>
          <div className="reports-table-column reports-table-column--12 reports-table-column--title">Salary</div>
          <div className="reports-table-column reports-table-column--12 reports-table-column--title">Completed Total</div>
          <div className="reports-table-column reports-table-column--12 reports-table-column--title">%</div>
        </div>
      </div>
      {list.map((item) => (
        <ProviderUser key={item.id} data={item} />
      ))}
    </div>
  );
};

export default ProviderUserList;

import React, { memo, useState, useCallback } from 'react';
import Icon from 'components/Icon';

const JobSection = memo(({ count, title, children }) => {
  const [isVisible, setIsVisible] = useState(true);

  const togglePast = useCallback(() => setIsVisible((pre) => !pre), []);

  return (
    <div className="days-group">
      <div className="day-head" onClick={togglePast} style={{ justifyContent: 'flex-start' }}>
        <p>
          <Icon size={18} icon={!isVisible ? 'add' : 'remove'} style={{ marginRight: 10 }} />
          {title}
        </p>
        <span className="day-head__count" style={{ marginLeft: 10 }}>
          {count}
        </span>
      </div>
      {isVisible && children}
    </div>
  );
});

export default JobSection;

import React, { Fragment, useMemo } from 'react';
import { useFormik } from 'formik';
import moment from 'moment';
import Preloader from 'components/Preloader';
import Filter from './Filter';
import BuildingTotal from './BuildingTotal';
import { useReportFilterInfo, useGetBuildingReport } from 'hooks/query/useReport';
import { changePeriod } from '../reportUtils';
import { buildingsJobsTypes } from '../reportConstants';

const Buildings = () => {
  const { data } = useReportFilterInfo();

  const { mutate: getBuildingReport, data: buildingReportResponse, isLoading } = useGetBuildingReport();
  const buildingReport = buildingReportResponse?.data;

  const companiesList = useMemo(() => {
    if (!data) return [];

    const list = data.companies.map((company) => ({
      value: [company.id],
      label: company.company_name,
    }));
    list.unshift({ value: data.all_companies, label: 'All Companies' });

    return list;
  }, [data]);

  const { values, setFieldValue, handleSubmit, handleChange } = useFormik({
    initialValues: {
      company_id: companiesList[0]?.value ?? 0,
      value: '',
      from: null,
      to: null,
      period_type: 0,
      job_statuses: buildingsJobsTypes[0].value,
    },
    onSubmit: (values) => getBuildingReport(values),
    enableReinitialize: true,
  });

  const selectCompany = ({ value }) => setFieldValue('company_id', value);

  const selectDate = ({ name, date }) => setFieldValue(name, moment(date).format('MM/DD/YY'));

  const selectJobType = ({ value }) => setFieldValue('job_statuses', value);

  const selectPeriod = ({ value }) => {
    const { from, to } = changePeriod(value);
    setFieldValue('from', from);
    setFieldValue('to', to);
    setFieldValue('period_type', value);
  };

  if (!data) return null;

  return (
    <Fragment>
      {isLoading && <Preloader />}

      <Filter data={{ values, companiesList }} actions={{ selectCompany, selectJobType, selectPeriod, selectDate, handleChange, handleSubmit }} />
      {buildingReport && <BuildingTotal values={values} list={buildingReport.list} move_sizes={buildingReport.move_sizes} />}
    </Fragment>
  );
};

export default Buildings;

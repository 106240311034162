import React, { createContext, useMemo, useContext, useState, useEffect, useCallback } from 'react';
import { useQueryClient } from 'react-query';
import keys from 'config/queryKeys';

export const AuthContext = createContext(undefined);

export const AuthProvider = ({ children }) => {
  const [isAuth, setIsAuth] = useState(null);
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);

  const queryClient = useQueryClient();

  const clearAllData = useCallback(() => {
    localStorage.clear();
    queryClient.invalidateQueries();
    queryClient.clear();
  }, [queryClient]);

  useEffect(() => {
    const localUser = localStorage.getItem(keys.user);
    const localToken = localStorage.getItem(keys.token);

    if (localToken && localUser) {
      setUser(JSON.parse(localUser));
      setToken(localToken);
      setIsAuth(true);
    } else setIsAuth(false);
  }, []);

  const setAuth = useCallback(
    (auth) => {
      if (!auth) {
        setIsAuth(false);
        setToken(null);
        setUser(null);
        clearAllData();
        return;
      }
      const { user, type, token } = auth;
      const preparedToken = `${type} ${token}`;

      localStorage.setItem(keys.user, JSON.stringify(user));
      localStorage.setItem(keys.token, preparedToken);

      setToken(preparedToken);
      setIsAuth(true);
      setUser(user);
    },
    [clearAllData]
  );

  const value = useMemo(
    () => ({
      isAuth,
      token,
      user,
      setAuth,
    }),
    [isAuth, setAuth, token, user]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};

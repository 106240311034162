import React, { PureComponent } from 'react';
import Swal from 'sweetalert2';
import Icon from 'components/Icon';
import { preventArrowUpDown } from 'utils/preventArrowUpDown';

class Item extends PureComponent {
  state = { qty: 1 };

  changeQty = (event) => this.setState({ [event.target.name]: event.target.value });

  addItem = () => {
    this.props.onAddItem(this.props.item.id, +this.state.qty);
    this.setState({ qty: 1 });
    return Swal.fire({
      title: 'Item Added. <br/>Scroll down to review',
      timer: 1000,
      position: 'top-right',
      customClass: 'tooltip-modal-packing',
      showConfirmButton: false,
      backdrop: false,
      allowOutsideClick: false,
    });
  };

  render() {
    const { size, name, price } = this.props.item;
    const { qty } = this.state;
    const sizes = JSON.parse(size);

    return (
      <div className="new-table--row packing-item">
        <div className="new-table--row--item w40 no-side-shadows">
          <span>{name}</span>
        </div>
        <div className="new-table--row--item w20 text-center fw-700">
          <span>
            {sizes.width} x {sizes.height} x {sizes.length}
          </span>
        </div>
        <div className="new-table--row--item w15 no-side-shadows text-center fw-700">
          <span>${price}</span>
        </div>
        <div className="new-table--row--item w15">
          <input name="qty" type="number" className="text-center p-0 fw-700" value={qty} onChange={this.changeQty} onKeyDown={preventArrowUpDown} />
        </div>
        <div className="new-table--row--item w10 no-side-shadows text-center">
          <button type="button" onClick={this.addItem} style={{ height: 20 }}>
            <Icon icon="add" size={20} style={{ marginRight: 0 }} />
          </button>
        </div>
      </div>
    );
  }
}

export default Item;

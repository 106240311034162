import React, { useCallback } from 'react';
import { ModalButton } from 'components/newComponents/Buttons';
import { useFormik } from 'formik';
import { useReminderSave } from 'hooks/query/useSMS';
import { useLocation } from 'react-router-dom';
import { matchPath } from 'react-router';
import { routePaths } from 'config/routes';
import { formatTimeAPMeridiem, formatFullDBDate } from 'helpers/dateHelper';
import Modal from 'components/newComponents/Modal';
import { useModal } from 'context/modal';
import DateSelect from 'components/DateSelect';
import TimeSelect from 'components/TimeSelect';

const SmsReminderModal = () => {
  const { close, info, modalNames } = useModal();
  const { pathname } = useLocation();

  const {
    params: { id },
  } = matchPath(pathname, { path: routePaths.JOBS });

  const isVisible = info(modalNames.smsReminder).visible;
  const params = info(modalNames.smsReminder).params;

  const { mutate: save } = useReminderSave(id);

  const closeModal = useCallback(() => close(modalNames.smsReminder), [close, modalNames.smsReminder]);

  const { values, setFieldValue, handleChange } = useFormik({
    initialValues: {
      date: '',
      time: '',
      text: '',
    },
  });

  const saveHandler = useCallback(() => {
    const { time, date, text } = values;
    save({ reminder: { time: time, date: formatFullDBDate(date), text }, job_id: +params.id }, { onSuccess: () => closeModal() });
  }, [closeModal, params.id, save, values]);

  return (
    <Modal show={isVisible} style={{ width: 800 }} header="New SMS Reminder" onHide={closeModal}>
      <div className="new-table">
        <div className="new-table--row">
          <div className="new-table--row--item">
            <DateSelect
              minDate={new Date()}
              selected={values.date}
              onChange={({ name, date }) => setFieldValue(name, date)}
              placeholder="Date to remind"
              name="date"
            />
          </div>
          <div className="new-table--row--item">
            <TimeSelect
              name="time"
              value={values.time}
              placeholder="Time to remind"
              onChange={({ name, date }) => setFieldValue(name, formatTimeAPMeridiem(date))}
            />
          </div>
        </div>
        <div className="new-table--row">
          <div className="new-table--row--item">
            <textarea rows={10} name="text" value={values.text} placeholder="Text" onChange={handleChange} />
          </div>
        </div>
      </div>
      <div className="footer-buttons">
        <ModalButton title="Cancel" variant="cancel" onClick={closeModal} />
        <ModalButton title="Save" variant="confirm" onClick={saveHandler} disabled={!values.time || !values.date || !values.text} />
      </div>
    </Modal>
  );
};

export default SmsReminderModal;

import React, { memo, useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTabInfo, useInfo, useManualTipsAdd } from 'hooks/query/useJob';
import { preventArrowUpDown } from 'utils/preventArrowUpDown';

const PaymentDetails = memo(({ deposit }) => {
  const { id, tab } = useParams();

  const { mutate: addTips } = useManualTipsAdd(id, tab);

  const {
    data: { calc_info: calcInfo, job_info: jobInfo, ld_calc: ldCalc, related_jobs: relatedJobs },
  } = useInfo(id);

  const {
    data: { job_payments: jobPayments },
  } = useTabInfo(id, tab);

  const [tips, setTips] = useState(calcInfo?.tips_amount || 0);

  const addTipsHandler = useCallback(
    (e) => {
      addTips({ job_id: jobInfo.id, tips: e.target.value });
    },
    [addTips, jobInfo.id]
  );

  const totalPayment = useMemo(() => jobPayments?.reduce((sum, item) => sum + +(+item.amount).toFixed(2), 0), [jobPayments]);

  const isAllJob = jobInfo?.storage_id === 0;
  const isLongDistanceTypeJob = jobInfo?.type === 5;
  const isPickupJob = relatedJobs?.long_delivery_job !== 0;

  const allJobTotalBalance = [4, 5, 6].includes(+jobInfo.status) ? +calcInfo.final_total : +calcInfo.estimated_total;

  const totalBalance = isAllJob ? allJobTotalBalance : isPickupJob ? Number(ldCalc?.pickup_total) : Number(ldCalc?.delivery_total);

  const remainingBalance = totalBalance - totalPayment;

  return (
    <div className="new-table">
      <div className="new-table--row">
        <div className="new-table--row--item">
          <span>Paid Deposit</span>
        </div>
        <div className="new-table--row--item">
          <b>{deposit}</b>
        </div>
      </div>
      <div className="new-table--row">
        <div className="new-table--row--item">
          <span>Total Payment</span>
        </div>
        <div className="new-table--row--item">
          <b>{totalPayment}</b>
        </div>
      </div>
      <div className="new-table--row">
        <div className="new-table--row--item">
          <span>Tips amount</span>
        </div>
        <div className="new-table--row--item">
          <b>
            <input
              type="number"
              name="tips"
              value={tips}
              onKeyDown={preventArrowUpDown}
              onChange={({ target }) => setTips(target.value)}
              onBlur={addTipsHandler}
            />
          </b>
        </div>
      </div>
      <div className="new-table--row">
        <div className="new-table--row--item">
          <span>Total Balance</span>
        </div>
        <div className="new-table--row--item">
          <b>{totalBalance.toFixed(2)}</b>
        </div>
      </div>
      <div className="new-table--row">
        <div className="new-table--row--item">
          <span>Remaining Balance</span>
        </div>
        <div className="new-table--row--item">
          <b>{remainingBalance.toFixed(2)}</b>
        </div>
      </div>
    </div>
  );
});

export default PaymentDetails;

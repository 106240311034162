import React from 'react';
import Select, { components } from 'react-select';
import Icon from '../Icon';

const MySelect = ({
  clearValue,
  options,
  // selectOption,
  onChange, // setValue
  className,
  defaultValue,
  controlStyles,
  containerStyles,
  optionStyle,
  placeholder,
  singleValue,
  isDisabled,
  input,
  defaultMenuIsOpen,
  name,
  value,
}) => {
  const { Option } = components;
  const IconOption = (props) => (
    <Option {...props}>
      {props.data.label}
      {props.data.iconName && <Icon style={{ marginLeft: 5 }} icon={props.data.iconName} />}
    </Option>
  );
  const customStyles = {
    option: () => ({
      padding: 10,
      cursor: 'pointer',
      color: '#000',
      ...optionStyle,
    }),
    // container: () => ([{height: '100%'}, containerStyles]),
    control: () => [
      { border: 'none', display: 'flex', height: '100%', backgroundColor: '#fff', fontWeight: 700 },
      controlStyles,
    ],
    indicatorSeparator: () => ({ display: 'none' }),
    singleValue: () => [{ color: '#000' }, singleValue],
    input: () => input,
  };

  const styles = {
    option: (provided) => ({
      ...provided,
      cursor: 'pointer',
      color: '#000',
    }),
    control: () => [{ border: 'none', display: 'flex', height: '100%', backgroundColor: '#fff', fontWeight: 700 }],
    indicatorSeparator: () => ({ display: 'none' }),
    menu: (provided) => ({ ...provided, zIndex: 2 }),
  };

  return (
    <div className="d-flex flex-column position-relative">
      <Select
        options={options}
        // selectOption={selectOption}
        className={['react-select-container ', className]}
        setValue={() => defaultValue?.value}
        onChange={(value) => onChange({ name, value })}
        clearValue={clearValue}
        value={value}
        defaultValue={defaultValue}
        styles={styles}
        placeholder={placeholder}
        isDisabled={isDisabled}
        // components={{ Option: IconOption }}
        defaultMenuIsOpen={defaultMenuIsOpen}
      />
    </div>
  );
};

export default MySelect;

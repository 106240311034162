import React, { useCallback, useMemo } from 'react';
import { useFormik } from 'formik';
import { ModalButton } from 'components/newComponents/Buttons';
import { useLocation } from 'react-router-dom';
import { matchPath } from 'react-router';
import { routePaths } from 'config/routes';
import Select from 'components/newComponents/Select';
import Modal from 'components/newComponents/Modal';
import { useModal } from 'context/modal';
import { useInfo, useTabInfo } from 'hooks/query/useJob';
import { useTemplatesByStatus, useTemplate, useEmailSend, useAutoEmail } from 'hooks/query/useMail';
import MciEditor from 'components/MciEditor';
import Icon from 'components/Icon';

const EmailModal = () => {
  const { info, modalNames, close, open } = useModal();

  const isVisible = info(modalNames.email).visible;

  const { pathname } = useLocation();

  const {
    params: { id, tab },
  } = matchPath(pathname, { path: routePaths.JOBS });

  const {
    data: { job_info: jobInfo },
  } = useInfo(id);

  const {
    data: { moving_info: movingInfo },
  } = useTabInfo(id, tab);

  const { data } = useTemplatesByStatus({ job_id: id });

  const { mutate: save } = useEmailSend();

  const { mutate: changeAutoEmail } = useAutoEmail();

  const { mutateAsync: getTemplateInfo } = useTemplate();

  const templatesOptions = useMemo(() => {
    if (!data) return [];
    return data.map((template) => ({ value: template.slug, label: template.title }));
  }, [data]);

  const isStorageTypeJob = jobInfo?.type === 3;

  const pdfTemplates = [
    { value: 'all', label: 'All' },
    { value: 'quote', label: 'Quote' },
    {
      value: isStorageTypeJob ? 'storage_contract' : 'contract',
      label: isStorageTypeJob ? 'Storage Contract' : 'Contract',
    },
    { value: 'insurance', label: 'Insurance' },
    { value: 'presswood', label: 'Presswood' },
    { value: 'creditCard', label: 'Credit Card' },
  ];

  const { setFieldValue, values } = useFormik({
    initialValues: {
      template: null,
      pdf: null,
      autoEmail: false,
      ifPackingSize: false,
      templateHtml: null,
      editTemplate: false,
      includePdf: false,
      email: movingInfo.from.email,
    },
  });

  const getTemplate = useCallback(
    async (template, ifPackingSize) => {
      getTemplateInfo(
        {
          job_id: jobInfo.id,
          template: template ?? values.template,
          if_packing_size: ifPackingSize ?? values.ifPackingSize,
        },
        { onSuccess: (res) => setFieldValue('templateHtml', res.data.template_html) }
      );
    },
    [getTemplateInfo, jobInfo.id, values.ifPackingSize, values.template, setFieldValue]
  );

  const changeTemplateHandler = useCallback(
    async (name, value) => {
      setFieldValue(name, value);
      getTemplate(value);
    },
    [getTemplate, setFieldValue]
  );

  const handlePackingSize = useCallback(
    (event) => {
      setFieldValue(event.target.name, event.target.checked);
      getTemplate(null, event.target.checked);
    },
    [getTemplate, setFieldValue]
  );

  const sendEmailHandler = useCallback(() => {
    const { template, includePdf, templateHtml, ifPackingSize, pdf, editTemplate } = values;
    const data = {
      pdf,
      template,
      job_id: jobInfo.id,
      include_pdf: includePdf,
      template_html: templateHtml,
      edit_template: editTemplate,
      email: movingInfo.from.email,
      if_packing_size: ifPackingSize,
      storage_id: jobInfo.storage_id,
      auto_email: jobInfo.auto_emails === 0,
    };
    save(data, { onSuccess: () => close(modalNames.email) });
  }, [close, jobInfo, modalNames.email, movingInfo.from.email, save, values]);

  const autoEmail = useCallback(
    (event) => {
      const isAuto = event.target.checked;
      changeAutoEmail(
        {
          job_id: jobInfo.id,
          auto_email: +isAuto,
        },
        {
          onSuccess: () => {
            setFieldValue(event.target.name, isAuto);
          },
        }
      );
    },
    [changeAutoEmail, jobInfo.id, setFieldValue]
  );

  const handleTemplateEdit = useCallback(
    (e) => {
      e.preventDefault();
      setFieldValue('editTemplate', !values.editTemplate);
    },
    [setFieldValue, values.editTemplate]
  );

  return (
    <Modal show={isVisible} style={{ width: 800 }} header="Send mail" onHide={() => close(modalNames.email)}>
      <form>
        <div className="new-table modal send-mail-modal">
          <div className="new-table--row">
            <div className="new-table--row--item select">
              <Select
                name="template"
                options={templatesOptions}
                value={values.template}
                onChange={(option) => changeTemplateHandler('template', option.value)}
                placeholder="Select mail template"
              />
            </div>
            <div className="new-table--row--item select">
              <Select
                name="pdf"
                options={pdfTemplates}
                value={values.pdf}
                onChange={(option) => setFieldValue('pdf', option.value)}
                placeholder="Select include pdf template"
              />
            </div>
          </div>
          <div className="new-table--row">
            <label className="new-table--row--item">
              <input type="checkbox" disabled={!values.template} name="autoEmail" checked={values.autoEmail} onChange={autoEmail} />
              Disable auto emails
            </label>
            <label className="new-table--row--item">
              <input type="checkbox" disabled={!values.template} name="ifPackingSize" checked={values.ifPackingSize} onChange={handlePackingSize} />
              Add packing size table to mail
            </label>
          </div>
          {values.template && (
            <div className="new-table--row">
              <div className="new-table--row--item no-padding">
                <button className="flex-grow-1 p20 main" onClick={handleTemplateEdit}>
                  Edit template
                </button>
              </div>
            </div>
          )}
          <div className="new-table--row">
            <div className="new-table--row--item">
              <button
                className="default d-flex align-items-center"
                type="button"
                onClick={(e) => {
                  open(modalNames.newEmail, {
                    jobId: jobInfo.id,
                    type: 'new',
                    email_to: values.email,
                    company_id: 0,
                  });
                }}
              >
                <Icon icon="add" size={20} />
                <span> New Mail </span>
              </button>
            </div>
            <div className="new-table--row--item">
              <button
                className="default d-flex align-items-center"
                type="button"
                disabled={jobInfo?.unsubscribe_mail === 1}
                onClick={(e) => {
                  open(modalNames.newRemainder, { email_to: values.email });
                }}
              >
                <Icon icon="add" size={20} />
                <span>New Reminder</span>
              </button>
            </div>
          </div>
          {values.editTemplate && (
            <div className="new-table--row">
              <MciEditor handleEditorChange={(content) => setFieldValue('templateHtml', content)} value={values.templateHtml} />
            </div>
          )}
        </div>
        <div className="footer-buttons">
          <ModalButton title="Cancel" variant="cancel" onClick={() => close(modalNames.email)} />
          <ModalButton title="Send" variant="confirm" disabled={!values.template && !values.pdf} onClick={sendEmailHandler} />
        </div>
      </form>
    </Modal>
  );
};

export default EmailModal;

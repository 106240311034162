import React, { useCallback, useMemo } from 'react';
import moment from 'moment';
import YearlyCalendar from 'components/YearlyCalendar';

const AnnualCalendar = ({ setFieldValue, values }) => {
  const onDatePickedHandler = useCallback(
    (date) => {
      if (values.multi) {
        const formattedDay = date.format('YYYY-MM-DD');
        const { multiSelect } = values;

        const isExits = multiSelect.includes(formattedDay);

        setFieldValue('multiSelect', isExits ? multiSelect.filter((e) => e !== formattedDay) : [...multiSelect, formattedDay]);
      } else setFieldValue('selectedDay', date);
    },
    [setFieldValue, values]
  );

  const onNextYearHandler = useCallback(() => {
    const nextYear = values.year + 1;
    setFieldValue('year', nextYear);
    setFieldValue('selectedDay', moment().year(nextYear).startOf('year'));
  }, [setFieldValue, values.year]);

  const onPrevYearHandler = useCallback(() => {
    const prevYear = values.year - 1;
    setFieldValue('year', prevYear);
    setFieldValue('selectedDay', moment().year(prevYear).startOf('year'));
  }, [setFieldValue, values.year]);

  const onPickRangeHandler = useCallback(
    (start, end) => {
      setFieldValue('selectedRange', [start, end]);
    },
    [setFieldValue]
  );

  const goToTodayHandler = useCallback(() => {
    setFieldValue('year', moment().year());
    setFieldValue('selectedDay', moment());
  }, [setFieldValue]);

  const preparedDateRates = useMemo(() => {
    return values.dates.map(({ date }) => moment.unix(date).format('YYYY-MM-DD'));
  }, [values.dates]);

  const customClasses = useMemo(() => {
    const weekStartDates = [];
    const weekEndDates = [];
    const daysInBetweenStartEnd = [];

    preparedDateRates.filter((date) => moment(date, 'YYYY-MM-DD').day() === 0);

    preparedDateRates.forEach((date) => {
      if (moment(date, 'YYYY-MM-DD').day() === 0) {
        weekStartDates.push(date);
        return;
      }
      if (moment(date, 'YYYY-MM-DD').day() === 6) {
        weekEndDates.push(date);
        return;
      }
      daysInBetweenStartEnd.push(date);
    });

    return {
      'multi-dates': values.multiSelect,
      'date-rate': daysInBetweenStartEnd,
      'date-rate-week-start': weekStartDates,
      'date-rate-week-end': weekEndDates,
    };
  }, [values.multiSelect, preparedDateRates]);

  return (
    <YearlyCalendar
      info={{ green: 'Manually Changed Rate', blue: 'Multiple Dates Selected', grey: 'Single Date Selected' }}
      year={values.year}
      showTodayButton={true}
      customCSSclasses={customClasses}
      selectRange={values.range}
      selectedRange={values.selectedRange}
      selectedDay={values.selectedDay}
      onPrevYear={onPrevYearHandler}
      onNextYear={onNextYearHandler}
      goToToday={goToTodayHandler}
      onPickDate={onDatePickedHandler}
      onPickRange={onPickRangeHandler}
    />
  );
};

export default AnnualCalendar;

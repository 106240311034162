import React from 'react';
import classNames from 'classnames';
import { useParams } from 'react-router-dom';
import { useHistory, generatePath } from 'react-router-dom';
import { routePaths } from 'config/routes';
import { formatFullDateTime } from 'helpers/dateHelper';

const NotesItem = (props) => {
  const history = useHistory();
  const { id } = useParams();

  const openNotes = () => {
    history.push({
      pathname: generatePath(routePaths.NOTES, { id: props.id, type: 'edit' }),
    });
  };

  return (
    <div onClick={openNotes} className={classNames('new-table--row', { 'active-row': Number(id) === Number(props.id) })}>
      <div className="new-table--row--item no-side-shadows">
        <b>{props.title}</b>
      </div>
      <div className="new-table--row--item no-side-shadows justify-content-end">
        <b>{formatFullDateTime(props.updated_at)}</b>
      </div>
    </div>
  );
};

export default NotesItem;

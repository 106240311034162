import React, { memo, useState, useEffect } from 'react';
import { useNotesUpdate, useTabInfo, useInfo } from 'hooks/query/useJob';
import { useDefault } from 'hooks/query/useCommon';
import { useAuth } from 'context/auth';
import NotesCreateForm from './NotesCreateForm';
import NoteItem from './NoteItem';

const AgentNotes = memo(({ id, tab }) => {
  const [notesList, setNotesList] = useState([]);

  const { user } = useAuth();
  const { mutate: updateNotes } = useNotesUpdate(id, tab);

  const { data: infoData } = useInfo(id);
  const { data: tabInfoData } = useTabInfo(id, tab);
  const { data: defaultData } = useDefault();

  const users = defaultData?.users;
  const jobInfo = infoData?.job_info;
  const notes = tabInfoData?.notes;

  useEffect(() => {
    if (jobInfo?.agent_id === user.id) {
      updateNotes({ job_id: jobInfo.id, notes: notes.map((note) => ({ ...note, read: 1 })) });
    }
  }, [jobInfo, user, notes, updateNotes]);

  useEffect(() => {
    const statuses = [3, 4, 5];

    const preparedNotes = notes?.reduce((acc, note) => {
      if (!statuses.includes(note.type)) {
        const author = users?.all?.find((user) => user.id === note.author_id);
        if (author) return acc.concat({ ...note, author: `${author.last_name} ${author.first_name}` });
      }
      return acc;
    }, []);

    setNotesList(preparedNotes);
  }, [notes, users?.all]);

  return (
    <div className="new-table modal">
      <NotesCreateForm id={id} tab={tab} notes={notes} jobInfo={jobInfo} />
      <div className="new-table--row bcg-light">
        <div className="new-table--row--item w15 no-side-shadows justify-content-center">
          <b>Note #</b>
        </div>
        <div className="new-table--row--item w15 no-side-shadows justify-content-center">
          <b>Description</b>
        </div>
        <div className="new-table--row--item w15 no-side-shadows justify-content-center">
          <b>Type</b>
        </div>
        <div className="new-table--row--item w15 no-side-shadows justify-content-center">
          <b>Date Entered</b>
        </div>
        <div className="new-table--row--item w15 no-side-shadows justify-content-center">
          <b>Name</b>
        </div>
        <div className="new-table--row--item w25 no-side-shadows justify-content-center">
          <b>Actions</b>
        </div>
      </div>
      {notesList?.map((item, index) => (
        <NoteItem key={item.created_at} id={id} tab={tab} notes={notes} note={item} counter={index + 1} author={item.author} />
      ))}
    </div>
  );
});

export default AgentNotes;

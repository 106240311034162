import React from 'react';

const PaymentTotal = ({ total }) => {
  return (
    <div className="new-table">
      <div className="new-table--row">
        <div className="new-table--row--item light justify-content-end">
          <span className="mr-3">Total</span>
          <span className="price">$ {Number(total).toFixed(2)}</span>
        </div>
      </div>
    </div>
  );
};

export default PaymentTotal;

import React, { useMemo } from 'react';

const TotalsTab = ({ total, totalSalary, fuelCost, expenses }) => {
  const profit = useMemo(() => {
    return (parseFloat(total) - parseFloat(totalSalary) - parseFloat(fuelCost) - parseFloat(expenses)).toFixed(2);
  }, [expenses, fuelCost, total, totalSalary]);

  return (
    <div className="new-table shadow w49">
      <div className="new-table--row no-padding">
        <h3 className="header new-table--row--item">Totals</h3>
      </div>

      <div className="new-table--row no-padding">
        <div className="new-table--row--item">
          <span>Job Total</span>
        </div>
        <div className="new-table--row--item">
          <span>{total}</span>
        </div>
      </div>

      <div className="new-table--row no-padding">
        <div className="new-table--row--item">
          <span>Salary</span>
        </div>
        <div className="new-table--row--item">
          <span>{totalSalary}</span>
        </div>
      </div>

      <div className="new-table--row no-padding">
        <div className="new-table--row--item">
          <span>Fuels</span>
        </div>
        <div className="new-table--row--item">
          <span>{fuelCost}</span>
        </div>
      </div>

      <div className="new-table--row no-padding">
        <div className="new-table--row--item">
          <span>Expenses</span>
        </div>
        <div className="new-table--row--item">
          <span>{expenses}</span>
        </div>
      </div>

      <div className="new-table--row no-padding">
        <div className="new-table--row--item">
          <span>Profit</span>
        </div>
        <div className="new-table--row--item">
          <span>{profit}</span>
        </div>
      </div>
    </div>
  );
};

export default TotalsTab;

const updatePriority = (priority) => {
  let priorityTitle;
  let priorityStyle;
  switch (Number(priority)) {
    case 0:
      priorityTitle = 'None';
      priorityStyle = 'none';
      break;
    case 1:
      priorityTitle = 'Low';
      priorityStyle = 'low';
      break;
    case 2:
      priorityTitle = 'Low-Medium';
      priorityStyle = 'low-medium';
      break;
    case 3:
      priorityTitle = 'Medium';
      priorityStyle = 'medium';
      break;
    case 4:
      priorityTitle = 'Medium-High';
      priorityStyle = 'medium-high';
      break;
    case 5:
      priorityTitle = 'High';
      priorityStyle = 'high';
      break;
    case 6:
      priorityTitle = 'Purple';
      priorityStyle = 'purple';
      break;
    default:
      priorityTitle = 'None';
      priorityStyle = 'none';
  }
  return { priorityStyle, priorityTitle };
};

export default updatePriority;

import { useCallback, useEffect, useState, useMemo } from 'react';

const useBuildCategoriesList = (inventoryData) => {
  const [items, setItems] = useState([]);

  const handleSearch = useCallback(
    (value) => {
      if (value.length >= 1) {
        const filtered = inventoryData.all_inventory.filter((item) => {
          return item.name.match(new RegExp(value, 'gi'));
        });
        setItems(filtered);
      } else {
        setItems(inventoryData.all_inventory);
      }
    },
    [inventoryData]
  );

  const categories = useMemo(() => {
    if (!inventoryData || !inventoryData.categories) return [];

    const categories = inventoryData.categories.map((category, key) => {
      const list = [];
      items.forEach((item) => {
        if (Number(item.category_id) === key) list.push(item);
      });

      return { name: category, list };
    });

    const categoriesWithList = categories.filter((item) => item.list.length);

    return categoriesWithList;
  }, [inventoryData, items]);

  useEffect(() => {
    if (inventoryData && inventoryData.categories && inventoryData.all_inventory) {
      setItems(inventoryData.all_inventory);
    }
  }, [inventoryData]);

  return {
    categories,
    actions: { handleSearch },
  };
};

export { useBuildCategoriesList };

import React, { Fragment, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useModal } from 'context/modal';
import { useInfo } from 'hooks/query/useJob';
import { useAvailableUnitsByDate } from 'hooks/query/useCrew';
import { formatFullDBDateTime, formatFullDBDate } from 'helpers/dateHelper';
import { DropdownMenu, ButtonLinkItem } from 'components/newComponents/DropDown';
import Icon from 'components/Icon';

const CrewNotAssigned = () => {
  const { id } = useParams();
  const { open, modalNames } = useModal();

  const {
    data: { job_info: jobInfo, calc_info: calcInfo, dates },
  } = useInfo(id);

  const isPresale = jobInfo && (jobInfo.status === 7 || jobInfo.status === 21);

  const { data: availableUnits } = useAvailableUnitsByDate({
    date: formatFullDBDate(dates.required_pickup),
    start_date: formatFullDBDateTime(dates.required_pickup),
    stop_date: formatFullDBDateTime(dates.stop_date),
    job_id: jobInfo.id,
  });

  const assignHandler = useCallback(
    (crewId) =>
      open(modalNames.assignCrew, {
        job_id: id,
        edit: false,
        crew_id: crewId,
        show_all: false,
        title: jobInfo.title,
        qty_man: calcInfo.qty_man,
        company_id: jobInfo.company_id,
        move_size_id: jobInfo.move_size_id,
        date: formatFullDBDate(dates.required_pickup),
        stop_date: formatFullDBDateTime(dates?.stop_date || dates.stop_date),
        start_date: formatFullDBDateTime(dates?.required_pickup || dates.start_date),
      }),
    [calcInfo, dates, id, jobInfo, modalNames.assignCrew, open]
  );

  const availabilityHandler = useCallback(
    (id) => {
      open(modalNames.dayAvailability, {
        date: formatFullDBDate(dates.required_pickup),
        minimum_hours: calcInfo.estimated_time,
        start_date: formatFullDBDateTime(dates.start_date),
        stop_date: formatFullDBDateTime(dates.stop_date),
      });
    },
    [calcInfo, dates, modalNames.dayAvailability, open]
  );

  return (
    <div className="d-flex p20">
      <span className="w30" style={{ color: 'red' }}>
        Crew is not assigned
      </span>
      <button type="button" className="w50" onClick={availabilityHandler}>
        Availability
      </button>

      <DropdownMenu
        wrapperClass="changeWrapper"
        trigger="Assign"
        disabled={isPresale}
        triggerClass="main"
        menuWrapperClass="assign-dropdown-position"
      >
        {availableUnits?.crews?.map((crew) => {
          return (
            <ButtonLinkItem
              key={crew.id}
              value={crew.id}
              onClick={assignHandler}
              label={
                <Fragment>
                  {crew.name} {crew.overlap && <Icon icon="task_unfinished" size={20} />}
                </Fragment>
              }
            />
          );
        })}
      </DropdownMenu>
    </div>
  );
};

export default CrewNotAssigned;

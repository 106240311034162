import { useFormik } from 'formik';

const useForm = (params) => {
  const { values, setFieldValue } = useFormik({
    initialValues: {
      crew_id: params.crew_id,
      crew: null,
      foreman: { unit_type: null, unit_id: null },
    },
  });

  const handleGetAssignedCrew = (crewSchedule) => {
    if (!crewSchedule || !Array.isArray(crewSchedule)) return;

    const crew = crewSchedule.map((e, i) => {
      if (e.foreman) {
        setFieldValue('foreman', { unit_type: e.unit_type, unit_id: e.unit_id });
      }
      return {
        id: e.unit_id,
        unit_name: e.full_name,
        unit_type: e.unit_type,
        overlap: e.overlap,
        available: true,
        checked: i < params.qty_man,
      };
    });

    setFieldValue('crew', Object.assign({}, crew));
  };

  const handleGetNewCrew = (crewData) => {
    if (!crewData || !Array.isArray(crewData)) return;

    let checkedCount = 0;

    const crew = crewData.map((e) => {
      const data = { ...e, checked: e.available && checkedCount < params.qty_man };
      if (e.available && checkedCount < params.qty_man && e.unit_type !== 'truck') {
        checkedCount += 1;
      }

      return data;
    });

    setFieldValue('crew', Object.assign({}, crew));

    const driver = crewData.find((e) => e.unit_type === 'driver');

    if (driver) {
      setFieldValue('foreman', { unit_type: driver.unit_type, unit_id: driver.id });
    }
  };

  const resetForeman = () => setFieldValue('foreman', { unit_type: null, unit_id: null });

  const handleSelectUnit = (index) => {
    const unit = values.crew[index];

    setFieldValue('crew', {
      ...values.crew,
      [index]: {
        ...values.crew[index],
        checked: !unit?.checked,
      },
    });

    if (unit?.checked) {
      const { unit_type: unitType, unit_id: unitId } = values.foreman;

      if (unitId === unit.id && unitType === unit.unit_type) {
        resetForeman();
      }
    }
  };

  const handleSelectForeman = (id, type) => {
    const { unit_type: unitType, unit_id: unitId } = values.foreman;
    if (unitId === id && unitType === type) {
      resetForeman();
      return;
    }
    setFieldValue('foreman', { unit_type: type, unit_id: id });
  };

  const handleChangeUnit = (index, { value, type, label }) => {
    const isExist = Object.values(values.crew).find((e) => e.id === value);
    if (isExist) return;

    setFieldValue('crew', {
      ...values.crew,
      [index]: {
        id: value,
        unit_name: label,
        unit_type: type,
        available: true,
      },
    });

    const { unit_type: unitType, unit_id: unitId } = values.foreman;

    if (unitId === value && unitType === type) {
      resetForeman();
    }
  };

  const handleSelectNewCrew = ({ value }) => setFieldValue('crew_id', value);

  const handleAddMover = ({ label, value }) => {
    const currentCrew = values.crew ?? {};

    const nextIndex = Object.keys(currentCrew).length;
    setFieldValue('crew', {
      ...currentCrew,
      [nextIndex]: {
        id: value,
        unit_name: label,
        unit_type: 'mover',
        checked: false,
        available: true,
      },
    });
  };

  const filterAssignedCrew = (movers) => {
    const assignedCrewIds = !values.crew ? [] : Object.entries(values.crew).map((item) => item[1].id);

    return movers.filter((mover) => !assignedCrewIds.includes(mover.id));
  };

  return {
    values,
    actions: {
      handleGetAssignedCrew,
      handleGetNewCrew,
      handleSelectUnit,
      handleSelectForeman,
      handleChangeUnit,
      handleSelectNewCrew,
      filterAssignedCrew,
      handleAddMover,
    },
  };
};

export { useForm };

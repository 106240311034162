import React, { useMemo, memo } from 'react';
import { NoResultsFound } from '../NoResultsFound';
import BuildingAddresses from './BuildingAddresses';

const BuildingTotal = memo(({ list, move_sizes, values }) => {
  const { titles, timeData } = useMemo(() => {
    if (!move_sizes) return {};

    const titles = [];
    const timeData = [];

    Object.entries(move_sizes).forEach((el) => {
      titles.push(
        <div key={el[0]} className="reports-table-column reports-table-column--20 reports-table-column--title">
          {el[0]}
        </div>
      );
      timeData.push(
        <div key={el[0] + el[1]} className="reports-table-column reports-table-column--20 reports-table-column--title">
          <small>{el[1].min + '/' + el[1].avg + '/' + el[1].max}</small>
        </div>
      );
    });

    return { titles, timeData };
  }, [move_sizes]);

  if (list.length === 0) return <NoResultsFound />;

  return (
    <div className="my-3">
      <div className="reports-table">
        <div className="reports-table-row">
          <div className="reports-table-column reports-table-column--20 reports-table-column--title">Building Address</div>
          <div className="reports-table-column reports-table-column--20 reports-table-column--title">Jobs</div>
          <div className="reports-table-column reports-table-column--20 reports-table-column--title">From</div>
          <div className="reports-table-column reports-table-column--20 reports-table-column--title">To</div>
          {titles}
        </div>
        <div className="reports-table-row">
          <div className="reports-table-column reports-table-column--20 reports-table-column--title" />
          <div className="reports-table-column reports-table-column--20 reports-table-column--title" />
          <div className="reports-table-column reports-table-column--20 reports-table-column--title" />
          <div className="reports-table-column reports-table-column--20 reports-table-column--title" />
          {timeData}
        </div>
      </div>

      {list.map((item, index) => {
        const items = Object.entries(item.data).map((el, i) => (
          <div key={(i + 1) * (index + 1) + item.id} className="reports-table-column reports-table-column--20">
            {el[1].from !== undefined ? el[1].from + ' / ' + el[1].to : el[1]}
          </div>
        ));

        return <BuildingAddresses values={values} key={item.id} data={item} items={items} />;
      })}
    </div>
  );
});

export default BuildingTotal;

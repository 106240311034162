/* eslint-disable no-loop-func */
import React, { Component } from 'react';
import moment from 'moment';
import ChartComponent from '../ChartComponent';
import MySelect from 'components/MySelect';
import { chartTypes } from '../reportConstants';

import './ReportChart.scss';

class ReportCharts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 1,
      job_series: [],
      series: [],
      hours_series: [],
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.job_list.length !== prevProps.job_list.length) {
      this.getChartInfo(this.props.job_list, this.state.type, this.props.data.from, this.props.data.to);
    }
  }

  componentDidMount(prevProps, prevState, snapshot) {
    this.getChartInfo(this.props.job_list, this.state.type, this.props.data.from, this.props.data.to);
  }

  setData(options, job_series, series, hours_series) {
    this.setState({ options, job_series, series, hours_series });
  }

  getOptions(from, to, count, optionTemplate) {
    const options = [];

    for (let i = 0; i <= count; i++) {
      options.push(optionTemplate(from, to, i));
    }
    return options;
  }

  getSeries(job_list, from, to, type, counter, condition) {
    const job_series = [];
    const total_series = [];
    const expenses_series = [];
    const salary_series = [];
    const commission_series = [];
    const profit_series = [];
    const hours_series = [];

    for (let i = 0; i <= counter; i++) {
      let count = 0;
      let total = 0;
      let hours = 0;
      let expenses = 0;
      let salary = 0;
      let commission = 0;
      let profit = 0;
      job_list.forEach((job) => {
        if (condition(from, to, job.start_date, i)) {
          hours += Number(job.hours);
          total += Number(job.total);
          expenses += Number(job.expenses);
          salary += Number(job.salary);
          commission += Number(job.commission);
          profit += Number(job.profit);
          count++;
        }
      });
      job_series.push(count);
      total_series.push(total.toFixed(2));
      expenses_series.push(expenses.toFixed(2));
      salary_series.push(salary.toFixed(2));
      commission_series.push(commission.toFixed(2));
      profit_series.push(profit.toFixed(2));
      hours_series.push(hours.toFixed(2));
    }

    let series = [];
    switch (type) {
      case 1:
        series = total_series;
        break;
      case 2:
        series = expenses_series;
        break;
      case 3:
        series = salary_series;
        break;
      case 4:
        series = commission_series;
        break;
      case 5:
        series = profit_series;
        break;
      default:
        return null;
    }

    return { series, job_series, hours_series };
  }

  getChartInfo(job_list, type, fromMain, toMain) {
    if (!fromMain) return false;
    const from = +moment(fromMain).format('X');
    const to = toMain ? +moment(toMain).format('X') : +moment().format('X');

    const lessThanTwoWeeks =
      Number(moment.unix(to).diff(moment.unix(from), 'days', true)) > 1 && Number(moment.unix(to).diff(moment.unix(from), 'weeks', true)) <= 2;
    const twoWeeksToTwoMonths =
      Number(moment.unix(to).diff(moment.unix(from), 'weeks', true)) > 2 && Number(moment.unix(to).diff(moment.unix(from), 'month', true)) < 2;
    const twoMonthsAndMore = Number(moment.unix(to).diff(moment.unix(from), 'months', true)) >= 2;

    if (lessThanTwoWeeks) {
      const countDays = Number(moment.unix(to).dayOfYear()) - Number(moment.unix(from).dayOfYear());
      const optionTemplate = (from, to, index) => moment.unix(from).add(index, 'days').format('MM/DD');
      const condition = (from, to, jobStartDate, index) =>
        moment.unix(jobStartDate).format('MM/DD') === moment.unix(from).add(index, 'days').format('MM/DD');

      const options = this.getOptions(from, to, countDays, optionTemplate);
      const { series, job_series, hours_series } = this.getSeries(job_list, from, to, type, countDays, condition);
      return this.setData(options, job_series, series, hours_series);
    }

    if (twoWeeksToTwoMonths) {
      const countWeeks = Number(moment.unix(to).format('WW')) - Number(moment.unix(from).format('WW'));
      const optionTemplate = (from, to, i) =>
        `${moment.unix(from).add(i, 'weeks').isoWeekday(1).format('MM/DD')} - ${moment.unix(from).add(i, 'weeks').isoWeekday(5).format('MM/DD')}`;
      const condition = (from, to, jobStartDate, index) =>
        moment.unix(jobStartDate).format('W') === moment.unix(from).add(index, 'weeks').format('W');

      const options = this.getOptions(from, to, countWeeks, optionTemplate);
      const { series, job_series, hours_series } = this.getSeries(job_list, from, to, type, countWeeks, condition);
      return this.setData(options, job_series, series, hours_series);
    }

    if (twoMonthsAndMore) {
      const countMonths = Number(moment.unix(to).diff(moment.unix(from), 'months', true).toFixed());
      const optionTemplate = (from, to, index) => moment.unix(from).add(index, 'month').format('MMM/YY');
      const condition = (from, to, jobStartDate, index) =>
        moment.unix(jobStartDate).format('MM/YY') === moment.unix(from).add(index, 'month').format('MM/YY');

      const options = this.getOptions(from, to, countMonths, optionTemplate);
      const { series, job_series, hours_series } = this.getSeries(job_list, from, to, type, countMonths, condition);
      return this.setData(options, job_series, series, hours_series);
    }
  }

  handleChangeSelect = ({ name, value }) => {
    this.setState({ [name]: value.value });
    this.getChartInfo(this.props.job_list, value.value, this.props.data.from, this.props.data.to);
  };

  render() {
    if (this.props.job_list.length === 0) return null;

    return (
      <div className="chart-container">
        {Boolean(this.state.job_series.length) && (
          <div className="chart-item">
            <span>Jobs</span>
            <ChartComponent options={this.state.options} series={this.state.job_series} type="bar" />
          </div>
        )}

        {Boolean(this.state.hours_series.length) && (
          <div className="chart-item">
            <span>Hours</span>
            <ChartComponent options={this.state.options} series={this.state.hours_series} type="bar" />
          </div>
        )}

        {Boolean(this.state.hours_series.length) && (
          <div className="chart-item">
            <div className="d-flex justify-content-between align-items-center mb2">
              <span style={{ flexBasis: '30%' }}>Total</span>
              {this.props.FilterInfo.manager_permission && (
                <MySelect
                  controlStyles={{ color: '#b1c3cc', flexBasis: '60%' }}
                  name="type"
                  options={chartTypes}
                  defaultValue={chartTypes[this.state.type - 1]}
                  onChange={this.handleChangeSelect}
                />
              )}
            </div>
            <ChartComponent options={this.state.options} series={this.state.series} type="bar" />
          </div>
        )}
      </div>
    );
  }
}

export default ReportCharts;

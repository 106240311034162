/* eslint-disable jsx-a11y/iframe-has-title */
import React, { Fragment, useEffect, useState, useRef, useMemo } from 'react';
import Api from './api/api';
import * as Modals from './Modals';
import './index.scss';

const Invoice = () => {
  const [containerHeight, setContainerHeight] = useState('100vh');

  const [isSignModalVisible, setSignModalVisible] = useState(false);
  const [isSigned, setIsSigned] = useState(false);

  const signCanvas = useRef(null);
  const iframe = useRef(null);
  const containerRef = useRef(null);

  const queryParams = useMemo(() => {
    const url = new URL(window.location.href);

    return {
      jobId: url.searchParams.get('job_id'),
    };
  }, []);

  const documentUrl = `${process.env.REACT_APP_API_URL}/api/documents/storage-contract/${queryParams.jobId}/view`;

  const openSignModal = () => setSignModalVisible(true);
  const closeSignModal = () => setSignModalVisible(false);

  const checkIsSigned = async () => {
    const response = await Api.checkSign(queryParams.jobId);

    if (response && response.data && response.data.sign) {
      setIsSigned(true);
    }
  };

  const saveSign = async () => {
    const sign = signCanvas.current.toDataURL('base64');
    if (!signCanvas.current.isEmpty()) {
      const response = await Api.sign(queryParams.jobId, sign);

      if (response) {
        window.location.reload();
      }
    }
  };

  useEffect(checkIsSigned, []);

  useEffect(() => {
    function handleResize() {
      const { innerHeight: windowHeight } = window;
      const contentHeight = containerRef?.current?.offsetHeight;

      if (windowHeight && contentHeight && windowHeight < contentHeight) {
        setContainerHeight(windowHeight + 'px');
      }
    }

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Fragment>
      <div className="storage-document-container" style={{ height: containerHeight }} ref={containerRef}>
        <header>
          <h1>Contract</h1>
        </header>

        <div className="storage-document-iframe-wrap">
          <iframe ref={iframe} src={documentUrl} id="invoice_data" name="invoice_data" className="storage-document-iframe" />
        </div>

        <footer>
          {isSigned ? (
            <div className="storage-document-signed">Signed</div>
          ) : (
            <button type="button" className="btn" onClick={openSignModal}>
              Sign
            </button>
          )}
        </footer>
      </div>

      <Modals.Sign signCanvas={signCanvas} isOpen={isSignModalVisible} onClose={closeSignModal} onSubmit={saveSign} />
    </Fragment>
  );
};

export default Invoice;

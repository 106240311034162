import React, { useMemo, useCallback } from 'react';
import { useDefault } from 'hooks/query/useCommon';
import Icon from 'components/Icon';
import { preventArrowUpDown } from 'utils/preventArrowUpDown';

const PaymentEnterTable = (props) => {
  const { values, deposit, handleChange, disabledDeposit, submitPayment, companyId, isDeposit } = props;

  const {
    data: { company },
  } = useDefault();

  const companyDepositAmount = useMemo(() => {
    return parseFloat(company?.find((i) => i.id === Number(companyId))?.deposit_amount) || 100;
  }, [company, companyId]);

  const isValidDeposit = useMemo(() => {
    if (!company) return false;
    if (deposit >= companyDepositAmount) return true;
    return parseFloat(values.amount) >= companyDepositAmount;
  }, [company, values.amount, deposit, companyDepositAmount]);

  const handleSubmit = useCallback(() => {
    if (!isValidDeposit) return;
    if (!values.amount || parseFloat(values.amount) === 0) return;
    submitPayment();
  }, [values.amount, isValidDeposit, submitPayment]);

  return (
    <div className="new-table">
      <div className="new-table--row">
        <div className="new-table--row--item">
          <span>{isDeposit ? 'Amount' : 'Deposit Amount'}</span>
        </div>
        <div className="new-table--row--item">
          <input
            name="amount"
            type="number"
            value={values.amount}
            placeholder="Enter cash"
            onKeyDown={preventArrowUpDown}
            onWheel={(event) => event.currentTarget.blur()}
            onChange={handleChange}
          />
        </div>
      </div>
      {+disabledDeposit !== 1 && isDeposit && (
        <div className="new-table--row">
          <div className="new-table--row--item">
            <span>Deposit</span>
          </div>
          <div className="new-table--row--item">
            <Icon icon="booked" title="Deposit Paid" />
          </div>
        </div>
      )}
      {!isValidDeposit && (
        <div className="new-table--row">
          <div className="new-table--row--item">
            <p>Deposit can`t be less than ${companyDepositAmount}</p>
          </div>
        </div>
      )}
      <div className="new-table--row">
        <div className="new-table--row--item no-padding">
          <button type="submit" disabled={!isValidDeposit} className="p20 flex-grow-1 submit" onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};
export default PaymentEnterTable;

import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Icon from 'components/Icon';
import Api from './api/api';
import routes from 'config/routes';

const ThankYouPage = React.memo(() => {
  const { type, job_id } = useParams();

  const [data, setData] = useState(null);

  const handleFetch = React.useCallback(async () => {
    if (job_id == null) return;

    const response = await Api.getData(job_id);

    if (response) setData(response.data);
  }, [job_id]);

  React.useEffect(handleFetch, []);

  const renderSwitch = (type) => {
    switch (type) {
      case 'coi':
        return (
          <div>
            <Icon icon="green_checkmark" size={300} />
            <p className="thank-you-title">Thank you for Approving Certificates of Insurance and elevator reservation.</p>
          </div>
        );
      case 'coi-rejected':
        return (
          <div>
            <Icon icon="red_crossmark" size={300} />
            <p className="thank-you-title">You rejected Certificates of Insurance.</p>
          </div>
        );
      case 'elevator-rejected':
        return (
          <div>
            <Icon icon="red_crossmark" size={300} />
            <p className="thank-you-title">You rejected Elevator Reservation.</p>
          </div>
        );
      case 'payment':
        return (
          <div>
            <Icon icon="green_checkmark" size={300} />
            <p className="thank-you-title">Thank you for your payment!</p>
          </div>
        );
      case 'unsubscribe':
        return (
          <div>
            <Icon icon="green_checkmark" size={300} />
            <p className="thank-you-title">Success!</p>
            <p className="thank-you-text">You have been successfully unsubscribed.</p>
          </div>
        );
      case 'payment-error':
        return (
          <div>
            <Icon icon="red_crossmark" size={300} />
            <p className="thank-you-title">Something went wrong.</p>
            <p className="thank-you-text">
              Your payment did not go through, please{' '}
              <a className="no-ajax" href={window.location.origin + '/customer/payment?job_id=' + job_id}>
                try again with another card
              </a>
            </p>
          </div>
        );
      case 'booking':
        return (
          <div>
            <Icon icon="green_checkmark" size={300} />
            <p className="thank-you-title">Thank you for booking your job.</p>
            <p className="thank-you-text">Manager will contact you shortly to follow up on your Moving job state.</p>
            <p className="thank-you-text">Please close this window and wait for the confirmation email.</p>
          </div>
        );
      case 'crew-assign':
        return (
          <div>
            <Icon icon="green_checkmark" size={300} />
            <p className="thank-you-title">Thank You!</p>
            <p className="thank-you-text">Your assignment was updated.</p>
            <p className="thank-you-text">You can close this window.</p>
          </div>
        );
      case 'reschedule':
        return (
          <div>
            <Icon icon="green_checkmark" size={300} />
            <p className="thank-you-title">Thank You!</p>
            <p className="thank-you-text">
              All Reschedule Requests must be done during business hours, at least 3 days prior to your moving date.
              <br />
              All After Hours Reschedule Requests will be processed next business day (excluding weekends/holidays)
              <br />
              All Reschedule Requests done less than 3 days prior to the move date may be subjected to an additional charge
              <br />
              All Reschedule Requests may be subjected to a RATE CHANGE.
              <br />
              All Reschedule Requests must be CONFIRMED by your sales agent.
              <br />
              All Cancellation requests done less than 3 days prior to the move date, will result in forfeiture of your deposit.
              <br />
              Call your agent {data?.agent?.full_name} {data?.agent?.twilio_number} or our general number {data?.company_phone}
            </p>
          </div>
        );
      case 'reservation-rejected':
        return (
          <div>
            <Icon icon="green_checkmark" size={300} />
            <h2 className="thank-you-title">Elevator reservation was rejected, we will notify a resident</h2>
          </div>
        );
      case 'edit-job-thank-you':
        return (
          <div>
            <Icon icon="green_checkmark" size={300} />
            <p className="thank-you-title">Thank You!</p>
            <p className="thank-you-text">Your Move was updated.</p>
            <p className="thank-you-text">Please close this window and wait for the confirmation email.</p>
            <a className="thank-you-link-back" href={`${routes.CUSTOMER_EDIT_JOB}?job_id=${job_id}`}>
              Edit Job
            </a>
          </div>
        );
      case 'canceled':
        return (
          <div>
            <Icon icon="green_checkmark" size={300} />
            <p className="thank-you-title">Your Move was canceled.</p>
            <p className="thank-you-text">Please close this window and wait for the confirmation email.</p>
            {/*<a className="thank-you-link-back" href={`${routes.CUSTOMER_EDIT_JOB}?job_id=${job_id}`}>Edit Job</a>*/}
          </div>
        );
      default:
        return (
          <div>
            <Icon icon="green_checkmark" size={300} />
            <p className="thank-you-title">Thank You!</p>
            <p className="thank-you-text">Your Move was updated.</p>
            <p className="thank-you-text">Please close this window and wait for the confirmation email.</p>
          </div>
        );
    }
  };

  return <div className="text-center thank-you-page">{renderSwitch(type)}</div>;
});

export default ThankYouPage;

import React, { useRef, memo } from 'react';
import classNames from 'classnames';
import { useDetectOutsideClick } from 'hooks/useDetectOutsideClick';
import './styles.scss';

export const DropdownMenu = memo((props) => {
  const { children, trigger, horizontal, wrapperClass, menuWrapperClass, allowMenuOpen, triggerClass, buttonStyle, disabled } = props;

  const referenceRef = useRef(null);

  const [isActive, setIsActive] = useDetectOutsideClick(referenceRef, false, allowMenuOpen);

  const handleDropdownClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsActive((pre) => !pre);
  };

  return (
    <div ref={referenceRef} className={classNames('dropdown-menu--wrapper', wrapperClass)}>
      <button type="button" className={classNames('menu-button', triggerClass)} style={buttonStyle} onClick={handleDropdownClick} disabled={disabled}>
        {trigger}
      </button>
      {isActive && <div className={classNames('menu', menuWrapperClass, { 'flex-column': horizontal })}>{children}</div>}
    </div>
  );
});

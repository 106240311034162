import moment from 'moment';
import './TooltipDayAvailability.scss';

const TooltipDayAvailability = (day_item) => {
  const pickDestinations = (parseFloat(day_item?.distance_total) - parseFloat(day_item?.distance_pickup)).toFixed(2);
  const pickDate = `${moment(day_item?.date_start).format('hh:mm a')} 
    - ${moment(day_item?.date_stop).format('hh:mm a')}`;
  return `<div class='av-tooltip'>
      <h3 class='header'>
        ${day_item?.job_title}
      </div>    
        <div class='av-tooltip--row'>
          <span class='av-tooltip--row--item text'>
            Address:
          </span>
          <span class='av-tooltip--row--item'>
            From: ${day_item?.from_address}
          </span>
          <span class='av-tooltip--row--item'>
            To: ${day_item?.to_address}
          </span>
        </div>
        <div class='av-tooltip--row'>
          <span class='av-tooltip--row--item text'>
            Zip:
          </span>
          <span class='av-tooltip--row--item'>
            From: ${day_item?.from_zipcode}
          </span>
          <span class='av-tooltip--row--item'>
            To: ${day_item?.to_zipcode}
          </span>
        </div>
        <div class='av-tooltip--row'>
          <span class='av-tooltip--row--item text'>
            Distance:
          </span>
          <span class='av-tooltip--row--item'>
            Office to pickup: ${day_item?.distance_pickup}
          </span>
          <span class='av-tooltip--row--item'>
            Pickup to destination: ${pickDestinations}
          </span>
        </div>
        <div class='av-tooltip--row'>
          <span class='av-tooltip--row--item text'>
            Date:
          </span>
          <span class='av-tooltip--row--item'>
            ${pickDate}
          </span>
        </div>
    </div>`;
};

export default TooltipDayAvailability;

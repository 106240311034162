import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useApi } from 'context/api';
import queryKeys from 'config/queryKeys';
import makeFormData from 'utils/makeFormData';

export const useCommunicationsList = (data, options) => {
  const { communication } = useApi();

  const params = {
    to: null,
    from: null,
    status: '',
    value: '',
    company_id: 0,
    priority: null,
    ...data,
  };

  return useQuery([queryKeys.communications, params], () => communication.getAllCommunications(params), {
    select: (res) => res.data.list,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    retry: false,
    ...options,
  });
};

export const useGetMail = (params) => {
  const { communication } = useApi();

  return useQuery([queryKeys.selectedMail, params], () => communication.getMail(params), {
    select: (res) => res.data,
    refetchOnWindowFocus: false,
    retry: false,
  });
};

export const useGetChat = (params, options) => {
  const { communication } = useApi();

  return useQuery([queryKeys.selectedChat, params], () => communication.getChat(params), {
    select: (res) => res.data,
    refetchOnWindowFocus: false,
    retry: false,
    ...options,
  });
};

export const useMailsDelete = () => {
  const { communication } = useApi();

  const queryClient = useQueryClient();

  return useMutation(communication.deleteMails, {
    onSuccess: () => queryClient.invalidateQueries(queryKeys.communications),
  });
};

export const useMailsRead = () => {
  const { communication } = useApi();

  const queryClient = useQueryClient();

  return useMutation(communication.readMails, {
    onSuccess: () => queryClient.invalidateQueries(queryKeys.communications),
  });
};

export const useChangeMailPriority = () => {
  const { communication } = useApi();

  const queryClient = useQueryClient();

  return useMutation(communication.changeMailPriority, {
    onSuccess: () => queryClient.invalidateQueries(queryKeys.communications),
  });
};

export const useReadCommunication = () => {
  const { communication } = useApi();

  const queryClient = useQueryClient();

  return useMutation(communication.readCommunication, {
    onSuccess: () => queryClient.invalidateQueries(queryKeys.communications),
  });
};

export const useAssignMissedCall = ({ onSuccess } = {}) => {
  const { communication } = useApi();

  const queryClient = useQueryClient();

  return useMutation(communication.assignMissedCall, {
    onSuccess: (res) => {
      queryClient.invalidateQueries(queryKeys.communications);
      if (onSuccess) onSuccess(res);
    },
  });
};

export const useDeleteCommunication = () => {
  const { communication } = useApi();

  const queryClient = useQueryClient();

  return useMutation(communication.deleteCommunication, {
    onSuccess: () => queryClient.invalidateQueries(queryKeys.communications),
  });
};

export const useAssignSmsChat = ({ onSuccess } = {}) => {
  const { communication } = useApi();

  const queryClient = useQueryClient();

  return useMutation(communication.assignSmsChat, {
    onSuccess: (res) => {
      if (res.success) queryClient.invalidateQueries(queryKeys.communications);
      if (onSuccess) onSuccess(res);
    },
  });
};

export const useDeleteMail = ({ onSuccess } = {}) => {
  const { communication } = useApi();

  const queryClient = useQueryClient();

  return useMutation(communication.deleteMail, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.communications);
      if (onSuccess) onSuccess();
    },
  });
};

export const useGetCustomerFormLink = (options) => {
  const { communication } = useApi();

  return useMutation(communication.getCustomerFormLink, options);
};

export const useGetCustomerFormLinkTemp = (options) => {
  const { communication } = useApi();

  return useMutation(communication.useGetCustomerFormLinkTemp, options);
};

export const useResultCall = () => {
  const { twilio } = useApi();

  return useMutation(twilio.saveCallResult);
};

export const useSend = (params) => {
  const { sms } = useApi();
  const queryClient = useQueryClient();
  const options = { headers: { 'Content-Type': 'multipart/form-data' } };

  return useMutation((message) => sms.send(makeFormData(message), options), {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.communications, params);
    },
  });
};

/* eslint-disable jsx-a11y/iframe-has-title */
import React, { Fragment, useEffect, useState, useRef, useMemo, useCallback } from 'react';
import * as Modals from './Modals';
import Footer from './Footer';
import { modalNames } from './data';
import Api from './api';
import './index.scss';

const LDContract = () => {
  const [invoiceContainerHeight, setInvoiceContainerHeight] = useState('100vh');

  const [contract, setContract] = useState(null);

  const [modal, setModal] = useState(null);

  const closeModal = () => setModal(null);

  const iframe = useRef(null);
  const containerRef = useRef(null);

  const queryParams = useMemo(() => {
    const url = new URL(window.location.href);

    return {
      jobId: url.searchParams.get('job_id'),
    };
  }, []);

  const getContract = useCallback(async () => {
    const response = await Api.contract(queryParams.jobId);

    if (response.data) {
      setContract(response.data);
    }
  }, [queryParams.jobId]);

  useEffect(() => {
    getContract();
  }, [getContract]);

  useEffect(() => {
    function handleResize() {
      const { innerHeight: windowHeight } = window;
      const contentHeight = containerRef?.current?.offsetHeight;

      if (windowHeight && contentHeight && windowHeight < contentHeight) {
        setInvoiceContainerHeight(windowHeight + 'px');
      }
    }

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Fragment>
      <div className="invoice-container" style={{ height: invoiceContainerHeight }} ref={containerRef}>
        <header>
          <h1>Long Distance Contract</h1>
        </header>

        <div className="invoice-iframe-wrap">
          {contract && <iframe ref={iframe} src={contract.doc_url} id="contract" name="contract" className="invoice-iframe" />}
        </div>

        <Footer contract={contract} setModal={setModal} />
      </div>

      <Modals.TermsOfPaymentSign jobId={queryParams.jobId} isOpen={modal === modalNames.terms_of_payment} onClose={closeModal} />
      <Modals.AcknowledgeTermsAndConditionsSign jobId={queryParams.jobId} isOpen={modal === modalNames.acknowledge_terms} onClose={closeModal} />
      <Modals.InsuranceOptions job_id={queryParams.jobId} isOpen={modal === modalNames.insurance_options} onClose={closeModal} />
      <Modals.CustomerSing jobId={queryParams.jobId} isOpen={modal === modalNames.customer_sign} onClose={closeModal} />
      <Modals.CustomerInitials jobId={queryParams.jobId} isOpen={modal === modalNames.customer_initial} onClose={closeModal} />
    </Fragment>
  );
};

export default LDContract;

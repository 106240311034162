import { useMutation, useQueryClient } from 'react-query';
import { useApi } from 'context/api';
import queryKeys from 'config/queryKeys';
import makeFormData from 'utils/makeFormData';

export const useClaimCreate = (id, tab) => {
  const { claim } = useApi();

  const queryClient = useQueryClient();

  const options = { headers: { 'Content-Type': 'multipart/form-data' } };

  return useMutation((data) => claim.create(makeFormData(data), options), {
    onSuccess: () => queryClient.invalidateQueries([queryKeys.job, id, tab]),
  });
};

export const useClaimUpdate = (id, tab) => {
  const { claim } = useApi();

  const queryClient = useQueryClient();

  const options = { headers: { 'Content-Type': 'multipart/form-data' } };

  return useMutation(({ id, ...data }) => claim.update(id, makeFormData(data), options), {
    onSuccess: () => queryClient.invalidateQueries([queryKeys.job, id, tab]),
  });
};

export const useClaimDelete = (id, tab) => {
  const { claim } = useApi();
  const queryClient = useQueryClient();

  return useMutation(claim.delete, { onSuccess: () => queryClient.invalidateQueries([queryKeys.job, id, tab]) });
};

export const useClaimClose = (id, tab) => {
  const { claim } = useApi();
  const queryClient = useQueryClient();

  return useMutation(claim.close, { onSuccess: () => queryClient.invalidateQueries([queryKeys.job, id, tab]) });
};

import React, { useMemo, useState, useCallback, useEffect, Fragment } from 'react';
import Draggable from 'react-draggable';
import classNames from 'classnames';
import { useHistory, generatePath } from 'react-router-dom';
import { usePutOnHold, useRedirectTo, useResultCall } from 'hooks/query/useTwilio';
import { useRefuseReasons } from 'hooks/query/useJobs';
import { useJobToDropped } from 'hooks/query/useJob';
import { useDefault } from 'hooks/query/useCommon';
import {
  formatPhoneNumberPlusAndDigits,
  formatPhoneNumberOnlyDigits,
  formatPhoneNumberPlusOneAndDigits,
  formatPhoneNumberNoFirstCharOnlyDigitsAndHyphen,
} from 'helpers/phoneFormat';
import { DropdownMenu, ButtonLinkItem } from 'components/newComponents/DropDown';
import { alert } from 'components/Alerts';
import { useModal } from 'context/modal';
import { useAuth } from 'context/auth';
import { routePaths } from 'config/routes';
import { statusNumberToName } from 'helpers/statuses';
import TextArea from 'components/newComponents/TextArea';
import Input from 'components/newComponents/Input';
import Icon from 'components/Icon';
import './style.scss';

const Phone = (props) => {
  const {
    hold,
    call,
    status,
    device,
    values,
    number,
    company,
    setCompany,
    companiesList,
    callUp,
    setHold,
    setCall,
    setStatus,
    handleChange,
    callStatuses,
    lastOutcoming,
    trigerRerender,
    setLastOutcoming,
    getIncomingJobInfo,
    setIncomingJobInfo,
  } = props;

  const { user } = useAuth();
  const { close, open, modalNames } = useModal();
  const { data: defaultItems } = useDefault();
  const { mutate: putOnHold } = usePutOnHold();
  const { mutate: redirect } = useRedirectTo();
  const { mutate: callResult } = useResultCall();
  const { mutate: changeToDropped } = useJobToDropped();
  const { mutate: refuseReasons } = useRefuseReasons();

  const history = useHistory();

  const [isDropped, setIsDropped] = useState(false);

  const closeHandler = useCallback(() => close(modalNames.twilio), [close, modalNames.twilio]);

  const agentsList = useMemo(() => {
    if (!defaultItems) return [];
    return defaultItems.users.agents.map((agent) => ({
      value: agent.id,
      label: agent.first_name + ' ' + agent.last_name,
    }));
  }, [defaultItems]);

  const putOnHoldHandler = useCallback(() => {
    putOnHold(
      { on_hold: !hold, name: user.js_client_name + call.parameters.From, call_sid: call?.parameters?.CallSid },
      { onSuccess: () => setHold((pre) => !pre) }
    );
  }, [call, hold, putOnHold, setHold, user]);

  const onCallbackHandler = useCallback(async () => {
    setLastOutcoming(values['lastIncoming']);
    setStatus(callStatuses.outgoing);

    const call = await device.connect({
      params: {
        user_id: user.id,
        To: values['lastIncoming'],

        From: company.current['phone_company'],
        Twilio_number: company.current['phone_company'],
        company_id: company.current['company_id'],

        // From: values['phone_company'],
        // Twilio_number: values['phone_company'],
        // company_id: values['company_id'],
      },
    });

    setCall(call);
  }, [device, setCall, company, user.id, values, setLastOutcoming, setStatus, callStatuses]);

  const onCallHandler = useCallback(
    async (number) => {
      const phone = typeof number === 'string' ? number : values['number'];
      const userPhones = user.phones.map((item) => formatPhoneNumberPlusOneAndDigits(Object.values(item)[0]));

      if (!company.current['phone_company']) {
        await alert({
          title: 'You have no number associated with this company',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok',
          reverseButtons: false,
          backdrop: false,
        });

        return;
      }

      if (userPhones.includes(formatPhoneNumberPlusAndDigits(phone))) {
        await alert({
          title: 'You can not make call to your companies numbers',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok',
          reverseButtons: false,
          backdrop: false,
        });

        return;
      }

      setStatus(callStatuses.outgoing);
      setLastOutcoming(formatPhoneNumberPlusAndDigits(phone));
      getIncomingJobInfo({ number: phone }, { onSuccess: (res) => setIncomingJobInfo(res.data) });

      const call = await device.connect({
        params: {
          To: formatPhoneNumberPlusAndDigits(phone),
          user_id: user.id,
          call_direction: 'outbound',
          From: company.current['phone_company'],
          Twilio_number: company.current['phone_company'],
          company_id: company.current['company_id'],
        },
      });

      setCall(call);
    },
    [device, setCall, company, user.id, user.phones, values, getIncomingJobInfo, setIncomingJobInfo, setLastOutcoming, setStatus, callStatuses]
  );

  useEffect(() => {
    if (callUp) {
      setTimeout(() => onCallHandler(number), 1000);
    }
  }, [callUp, number]);

  const onRedirectHandler = useCallback(
    (id) => {
      redirect({ from_agent_id: user.id, agent_id: id, call_sid: call?.parameters?.CallSid, customer_number: call?.parameters?.From });
    },
    [call, redirect, user]
  );

  const onSpamHandler = useCallback(async () => {
    const res = await alert({
      title: 'Are you sure?',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      reverseButtons: false,
      backdrop: false,
    });

    if (res.isConfirmed) {
      callResult({ call_result: 'SPAM', call_sid: call?.parameters?.CallSid });
    }
  }, [call, callResult]);

  const onDroppedHandler = useCallback(() => {
    callResult({ call_result: `Dropped: ${values['dropped']}`, call_sid: call?.parameters?.CallSid });

    const id = values?.job_id;

    changeToDropped({ job_id: id });

    refuseReasons({
      job_id: id,
      reason: values['dropped'],
    });

    setIsDropped(false);
  }, [call, callResult, changeToDropped, refuseReasons, values]);

  const onCancelCall = useCallback(() => {
    status === callStatuses.incoming ? call.reject() : call.disconnect();
    setHold(false);
  }, [call, callStatuses, setHold, status]);

  const handleOpenJob = useCallback(() => {
    if (values.job_id)
      history.push({
        pathname: generatePath(routePaths.JOBS, { id: values.job_id, tab: 'general', type: 'all' }),
      });
  }, [history, values.job_id]);

  const callTitle = useMemo(() => {
    if (!call) return '';

    switch (status) {
      default:
        return '';
      case callStatuses.outgoing:
        return values.lead_provider || lastOutcoming;
      case callStatuses.accept:
      case callStatuses.incoming:
        return values.lead_provider || call?.parameters?.From;
    }
  }, [call, callStatuses, values.lead_provider, status, lastOutcoming]);

  const agentName = useMemo(() => {
    if (!agentsList) return '';
    const prepared = agentsList.find((item) => item.value === Number(values.agent_id));

    if (prepared) return prepared.label;
    return '';
  }, [agentsList, values.agent_id]);

  return (
    <Draggable bounds="parent" grid={[15, 15]}>
      <div style={{ width: 340, position: 'absolute' }} className="twilio-phone-modal">
        <div className="modal-pages table-header-shadow">
          <h3 className="header">Call</h3>
          <button type="button" onClick={closeHandler}>
            <Icon rotateDeg={45} icon="add" size={28} />
          </button>
        </div>

        <div className="call-info">
          <div className="called-to">
            {values['called_to'] && <p className="call-info__subtitle">To: {values['called_to']}</p>}
            {values.lead_provider && <div className="call-info__subtitle">SRC: {values['lead_provider']}</div>}
          </div>

          <Icon style={{ marginBottom: 10 }} icon={statusNumberToName(values.job_status)} title={''} size={24} />
          <div className="call-info__title">{callTitle}</div>
          {values['customer_name'] && <div className="call-info__title">{values['customer_name']}</div>}
          {values.company_name && <div className="call-info__subtitle">{values.company_name}</div>}
          {values.job_id && (
            <button type="button" onClick={handleOpenJob} className="call-info__title call-info__link">
              {values.job_title}
            </button>
          )}
          <div className="call-info__subtitle">{agentName}</div>
          {values.spam && <div className="call-info__subtitle">Spam</div>}
        </div>

        {status !== callStatuses.accept && status !== callStatuses.incoming && (
          <div className="phone-input">
            <Icon icon="phone_numbers" size={20} />
            <Input name="number" mask="+1 (111) 111 1111" value={values['number']} onChange={handleChange} />
          </div>
        )}

        <div className="call-actions">
          <button
            type="button"
            className="call-actions__btn"
            onClick={() => {
              const number = values['lastIncoming'] || values['number'];
              console.log(1, call?.parameters?.CallSid,number);
              open(modalNames.quickForm, {
                phone: formatPhoneNumberNoFirstCharOnlyDigitsAndHyphen(number),
                companyID: +company.current['company_id'],
                callId: call?.parameters?.CallSid,
              });
            }}
          >
            <Icon icon="add_new" size={10} title="Add Lead" />
          </button>
          <button type="button" disabled={!values?.missed_call_id} onClick={onCallbackHandler} className="call-actions__btn">
            <Icon icon="call_in" size={18} title="Call Back" />
          </button>
          <button type="button" onClick={() => open(modalNames.callScript)} className="call-actions__btn call-actions__tooltipbtn">
            <Icon icon="call_hold" size={18} title="Call Scripts" />
          </button>
          <DropdownMenu
            wrapperClass="call-actions__btn call-actions__tooltipbtn"
            menuWrapperClass="call-actions__tooltipmenu"
            triggerClass="call-actions__tooltiptrigger"
            trigger={<Icon icon="call_forward" size={20} title="Redirect" />}
            disabled={status !== callStatuses.accept}
          >
            {agentsList.map((item) => (
              <ButtonLinkItem key={item.value} value={item.value} label={item.label} onClick={(value) => onRedirectHandler(value)} />
            ))}
          </DropdownMenu>

          <DropdownMenu
            wrapperClass="call-actions__btn call-actions__tooltipbtn"
            menuWrapperClass="call-actions__tooltipmenu position-left-bottom"
            triggerClass="call-actions__tooltiptrigger"
            trigger={<Icon icon="dots" size={18} title="Options" />}
          >
            {companiesList.map((company) => (
              <button
                type="button"
                style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.15)' }}
                key={company.value.id}
                onClick={() => {
                  setCompany(company.value.id, company.value.number, company.label);
                  trigerRerender();
                }}
              >
                {company.label}
                <br />
                {company.value.number}
              </button>
            ))}
          </DropdownMenu>
        </div>

        <div
          className={classNames('phone-actions', {
            'justify-content-center': status !== callStatuses.accept && status !== callStatuses.incoming,
          })}
        >
          {(status === callStatuses.incoming || status === callStatuses.accept) && (
            <button type="button" onClick={onCancelCall} className="phone-btn phone-btn__reject">
              <Icon icon="call_end" width={25} height={20} title="Hangup" />
            </button>
          )}

          {status === callStatuses.accept && (
            <Fragment>
              <button type="button" onClick={onSpamHandler} className="phone-btn phone-btn__small phone-btn__neutral">
                <Icon icon="s" title="SPAM" />
              </button>

              <button type="button" onClick={() => setIsDropped((pre) => !pre)} className="phone-btn phone-btn__small phone-btn__neutral">
                <Icon icon="d" title="Dropped" />
              </button>
            </Fragment>
          )}

          {status === callStatuses.accept && (
            <button type="button" onClick={putOnHoldHandler} className={classNames('phone-btn', { 'bcg-blue': hold, 'bcg-yellow': !hold })}>
              {hold ? <Icon icon="t" title="Terminate" /> : <Icon icon="h" title="Hold" />}
            </button>
          )}

          {status === callStatuses.incoming && (
            <button type="button" onClick={() => call.accept()} className="phone-btn phone-btn__accept">
              <Icon icon="call_answer" size={18} title="Accept" />
            </button>
          )}

          {status !== callStatuses.accept && status !== callStatuses.incoming && (
            <button
              type="button"
              className="phone-btn phone-btn__accept"
              disabled={!values['number'] || formatPhoneNumberOnlyDigits(values['number']).length < 11 || Boolean(call)}
              onClick={onCallHandler}
            >
              <Icon icon="call_answer" size={18} title="Call" />
            </button>
          )}
        </div>

        {isDropped && status === callStatuses.accept && (
          <div className="call-dropped-input">
            <TextArea name="dropped" value={values['dropped']} placeholder="Type Here" onChange={handleChange} style={{ padding: 10 }} />
            <button className="call-actions__btn bcg-green" onClick={onDroppedHandler}>
              <Icon icon="save" size={20} title="Save" />
            </button>
          </div>
        )}

        {company.current.company_name && <p className="selected-company">Selected Company: {company.current.company_name}</p>}

        {values['previous_calls'].length !== 0 && (
          <ul className="previous-calls-list">
            {values['previous_calls'].map((item, index) => (
              <li key={index}>
                {item.job_title ? <p>{`${item.job_title} - ${item.company_name}`}</p> : <p>Call</p>}
                <p className="previous-calls-date">{item.date}</p>
              </li>
            ))}
          </ul>
        )}
      </div>
    </Draggable>
  );
};

export default Phone;

import React, { Fragment } from 'react';
import { useModal } from 'context/modal';
import InsuranceProvider from './InsuranceProvider';
import ExtraStop from './ExtraStop';
import DayAvailability from './DayAvailability';
import AdditionalInfo from './AdditionalInfo';
import NewRemainder from './CreateRemainder';
import ChangeStatus from './ChangeStatus';
import AssignCrew from './AssignCrew';
import QuickForm from './QuickForm';
import Insurance from './Insurance';
import Inventor from './Inventory';
import NewEmail from './CreateEmail';
import Discount from './Discount';
import FollowUp from './FollowUp';
import Packing from './Packing';
import Charges from './Charges';
import Email from './Email';
import Notes from './Notes';
import Fees from './Fees';
import Settings from './Settings';
import EditRemainder from './EditReminder';
import JobUpdateToStorage from './JobUpdateToStorage';
import LocalJobInventoryToOngoingStorageJob from './LocalJobInventoryToOngoingStorageJob';
import PayrollReimbursement from './PayrollReimbursement';
import LongDistanceFees from './LongDistanceFees';
import ChangeJobCarrier from './ChangeJobCarrier';
import CreateCarrierDriver from './CreateCarrierDriver';

const Modals = () => {
  const { info, modalNames } = useModal();

  const isExtraStop = info(modalNames.extraStop).visible;
  const isInsuranceProvider = info(modalNames.insuranceProvider).visible;
  const isDayAvailability = info(modalNames.dayAvailability).visible;
  const isAdditionalInfo = info(modalNames.additionalInfo).visible;
  const isChangeStatus = info(modalNames.changeJobStatus).visible;
  const isNewRemainder = info(modalNames.newRemainder).visible;
  const isAssignCrew = info(modalNames.assignCrew).visible;
  const isInventory = info(modalNames.inventory).visible;
  const isInsurance = info(modalNames.insurance).visible;
  const isQuickForm = info(modalNames.quickForm).visible;
  const isDiscount = info(modalNames.discount).visible;
  const isNewEmail = info(modalNames.newEmail).visible;
  const isFollowUp = info(modalNames.followUp).visible;
  const isPacking = info(modalNames.packing).visible;
  const isCharges = info(modalNames.charges).visible;
  const isEmail = info(modalNames.email).visible;
  const isNotes = info(modalNames.notes).visible;
  const isFees = info(modalNames.fees).visible;
  const isJobUpdateToStorage = info(modalNames.jobUpdateToStorage).visible;
  const isSettings = info(modalNames.settings).visible;
  const isLocalJobInventoryToOngoingStorageJob = info(modalNames.localJobInventoryToOngoingStorageJob).visible;
  const isEditRemainder = info(modalNames.editReminder).visible;
  const isPayrollReimbursement = info(modalNames.payrollReimbursement).visible;
  const isLongDistanceFees = info(modalNames.longDistanceFees).visible;
  const isChangeJobCarrier = info(modalNames.changeJobCarrier).visible;
  const isCreateCarrierDriver = info(modalNames.createCarrierDriver).visible;

  return (
    <Fragment>
      {isFees && <Fees />}
      {isNotes && <Notes />}
      {isSettings && <Settings />}
      {isEmail && <Email />}
      {isCharges && <Charges />}
      {isPacking && <Packing />}
      {isFollowUp && <FollowUp />}
      {isDiscount && <Discount />}
      {isNewEmail && <NewEmail />}
      {isInventory && <Inventor />}
      {isQuickForm && <QuickForm />}
      {isInsurance && <Insurance />}
      {isAssignCrew && <AssignCrew />}
      {isNewRemainder && <NewRemainder />}
      {isChangeStatus && <ChangeStatus />}
      {isAdditionalInfo && <AdditionalInfo />}
      {isDayAvailability && <DayAvailability />}
      {isInsuranceProvider && <InsuranceProvider />}
      {isExtraStop && <ExtraStop />}
      {isJobUpdateToStorage && <JobUpdateToStorage />}
      {isLocalJobInventoryToOngoingStorageJob && <LocalJobInventoryToOngoingStorageJob />}
      {isEditRemainder && <EditRemainder />}
      {isPayrollReimbursement && <PayrollReimbursement />}
      {isLongDistanceFees && <LongDistanceFees />}
      {isChangeJobCarrier && <ChangeJobCarrier />}
      {isCreateCarrierDriver && <CreateCarrierDriver />}
    </Fragment>
  );
};

export default Modals;

import React from 'react';
import AdditionalInfo from 'pages/modal/AdditionalInfo/Form';
import { useForm } from '../hooks/useForm';
import { isFieldValid } from '../utils';

function SecondaryForm() {
  const { values, errors, touched, actions } = useForm();

  return (
    <AdditionalInfo
      edit={true}
      values={values}
      setFieldValue={actions.setFieldValue}
      handleChange={actions.handleChange}
      isValidHandler={(name) => isFieldValid(errors, touched, name)}
    />
  );
}

export default SecondaryForm;

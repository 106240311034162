import React, { useMemo, useCallback } from 'react';
import Swal from 'sweetalert2';
import { useParams, useLocation, useHistory, generatePath } from 'react-router-dom';
import { useReadCommunication, useAssignSmsChat, useDeleteCommunication } from 'hooks/query/useCommunications';
import { useReadCommunication as useReadJobCommunication } from 'hooks/query/useJob';
import { routePaths } from 'config/routes';
import getAbbr from 'helpers/Abbr';
import Icon from 'components/Icon';
import { useDefault } from 'hooks/query/useCommon';
import { useAuth } from 'context/auth';
import { useModal } from 'context/modal';
import { formatPhoneNumberPlusOneAndDigits, formatPhoneNumberOnlyDigitsAndHyphen } from 'helpers/phoneFormat';

const SmsItem = ({ onChangeParams, ...props }) => {
  const { user } = useAuth();
  const history = useHistory();
  const { type } = useParams();
  const { search } = useLocation();

  const { data: defaultItems = {} } = useDefault();
  const { mutate: readCommunication } = useReadCommunication();
  const { mutate: deleteCommunication } = useDeleteCommunication();
  const { mutate: readJobCommunication } = useReadJobCommunication();

  const { open, close, info, modalNames } = useModal();
  const isTwilio = info(modalNames.twilio).visible;

  const companiesABBR = defaultItems?.companies_abbr;

  const params = useMemo(() => {
    const searchParams = new URLSearchParams(search);

    const result = {};

    for (var pair of searchParams.entries()) {
      result[pair[0]] = pair[1];
    }

    return result;
  }, [search]);

  const handleSuccessAssign = (res) => {
    if (!res.success) {
      Swal.fire(res.reason);
    } else {
      if (props.job_id) {
        readJobCommunication({ job_id: props.job_id });
      }
    }
  };

  const { mutate: assignSmsChat } = useAssignSmsChat({
    onSuccess: handleSuccessAssign,
  });

  const handleOpenChat = useCallback(() => {
    onChangeParams({
      selected: props.id,
      selected_type: 'chat',
    });

    if (props.entity_type !== 'direct_voicemail' && props.entity_type !== 'general_voicemail') {
      readCommunication({
        from: props.from || props.customer_number,
        to: props.to || props.agent_number,
        // entity_id: props.id,
        user_id: user.id,
        type: 'chat',
      });
    }
  }, [onChangeParams, props, readCommunication, user.id]);

  const handleOpenJob = useCallback(() => {
    history.push({
      pathname: generatePath(routePaths.JOBS, { id: props.job_id, tab: 'general', type: 'all' }),
    });
  }, [history, props.job_id]);

  const phoneNumber = useMemo(() => {
    const userPhones = user.phones.map((item) => formatPhoneNumberPlusOneAndDigits(Object.values(item)[0]));

    if (userPhones.includes(props.from)) {
      return props.to;
    }
    return props.from;
  }, [user.phones, props.from, props.to]);

  const handleCall = (number, company_id) => {
    assignSmsChat({ chat_id: props.id });

    if (isTwilio) {
      close(modalNames.twilio);
    }
    setTimeout(() => {
      open(modalNames.twilio, { number: number, companyID: company_id, callUp: true });
    }, 0);
  };

  const handleDeleteCall = useCallback(() => {
    deleteCommunication({
      entity_id: props.id,
      entity_type: 'sms',
    });
  }, [deleteCommunication, props.id]);

  const companyInfo = useMemo(() => {
    const { company = [], lead_providers = [] } = defaultItems;
    const info = {
      companyId: '',
      companyName: '',
      leadProvider: '',
    };

    lead_providers.forEach((provider) => {
      if ('+1' + provider.phone.replace(/[^0-9]/g, '') === props.agent_number) {
        info.leadProvider = provider.name;
        info.companyId = provider.company_id;
        info.companyName = getAbbr(provider.company_id, companiesABBR);
      }
    });

    if (info.companyName.length === 0) {
      if (props.company_id) {
        info.companyName = getAbbr(props.company_id, companiesABBR);
        info.companyId = props.company_id;
      } else {
        company.forEach((company) => {
          if ('+1' + company.phone.replace(/[^0-9]/g, '') === props.agent_number) {
            info.companyName = getAbbr(company.id, companiesABBR);
            info.companyId = company.id;
          }
        });
      }
    }

    return info;
  }, [defaultItems, companiesABBR, props.company_id, props.agent_number]);

  const agentName = useMemo(() => {
    return props?.first_name ? props.first_name.charAt(0) + '' + props.last_name.charAt(0) : '';
  }, [props.first_name, props.last_name]);

  const icon = props.user_id === 0 ? 'sms_reminder' : 'sms';
  const readRow = props.read === 1 ? 'email_read' : '';

  const to = useMemo(() => {
    if (companyInfo.leadProvider.length > 0) {
      return companyInfo.leadProvider;
    }

    const company = defaultItems.company?.find((item) => '+1' + item.phone.replace(/[^0-9]/g, '') === props.agent_number);

    if (company) {
      return `${company.company_name} GMB`;
    }

    if (props.first_name || props.last_name) {
      return `${props.first_name ?? ''} ${props.last_name ?? ''}`;
    }

    return props.agent_number;
  }, [companyInfo.leadProvider, defaultItems.company, props.first_name, props.last_name, props.agent_number]);

  return (
    <div className={`comm-item border ${+params.selected === props.id ? 'job-table__row active-row' : readRow}`}>
      <button type="button" className="w5" onClick={handleOpenChat}>
        <Icon icon={icon} />
      </button>

      <div className="comm-item" onClick={handleOpenChat}>
        <span className="w10">{companyInfo.companyName}</span>
        <span className="w10" title={companyInfo.leadProvider.length ? 'LP | ' + companyInfo.leadProvider : ''}>
          {agentName}
        </span>

        {props.job_title ? (
          <button className="w10" onClick={handleOpenJob}>
            {props.job_title}
          </button>
        ) : (
          <button
            type="button"
            onClick={() => {
              open(modalNames.quickForm, {
                phone: formatPhoneNumberOnlyDigitsAndHyphen(phoneNumber?.slice(2)),
                companyID: companyInfo.companyId,
                chatId: props.sid,
              });
            }}
            className="w10 main"
          >
            Assign
          </button>
        )}

        <b className="w15">{props.customer_number}</b>
        <b className="w15">{to}</b>
        <span className="w20">{props.date}</span>
      </div>

      <button className="w5" title="Call" onClick={() => handleCall(phoneNumber, companyInfo.companyId)} />
      {type === 'lead_communications' && (
        <button className="w5" onClick={handleDeleteCall}>
          <Icon icon="delete" title="Delete" />
        </button>
      )}
    </div>
  );
};

export default SmsItem;

import React, { Fragment, useMemo, useCallback } from 'react';
import { useFormik } from 'formik';
import { useLocation } from 'react-router-dom';
import { matchPath } from 'react-router';
import { routePaths } from 'config/routes';
import { useDefault } from 'hooks/query/useCommon';
import { alert } from 'components/Alerts';
import { useAuth } from 'context/auth';
import { useClaimCreate } from 'hooks/query/useClaim';
import { formatFullDBDate, formatTimeAPMeridiem } from 'helpers/dateHelper';
import Select from 'components/newComponents/Select';
import { notesOptions } from 'helpers/constants';
import TimeSelect from 'components/TimeSelect';
import DateSelect from 'components/DateSelect';

const ClaimCreateForm = ({ assignedCrew }) => {
  const { user } = useAuth();
  const { pathname } = useLocation();

  const {
    params: { id, tab },
  } = matchPath(pathname, { path: routePaths.JOBS });
  const { mutate: createClime } = useClaimCreate(id, tab);

  const {
    data: { users },
  } = useDefault();

  const agentsOption = useMemo(() => {
    const list = [...new Map([...users.agents, ...users.managers].map((user) => [user['id'], user])).values()];
    return list.map((user) => ({ value: user.id, label: user.first_name + ' ' + user.last_name }));
  }, [users.agents, users.managers]);

  const { values, setFieldValue, handleChange } = useFormik({
    initialValues: { author_id: user.id, files: null, type: notesOptions[0].value, claim: '' },
  });

  const addClaim = useCallback(() => {
    if (!assignedCrew?.drivers.length) {
      alert({
        title: 'No driver assigned',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'OK',
      });

      return;
    }
    const { due_date, due_time, ...res } = values;

    createClime({
      ...res,
      job_id: id,
      due_time: due_time ? formatTimeAPMeridiem(due_time) : '',
      due_date: due_date ? formatFullDBDate(due_date) : '',
    });
  }, [assignedCrew.drivers.length, createClime, id, values]);

  return (
    <div className="new-table--row">
      <div className="new-table--row--item flex-grow-1 select">
        <input type="text" placeholder="New claim" name="claim" value={values.claim} onChange={handleChange} />
      </div>
      <div className="new-table--row--item w20 select">
        <label className="file">
          Add file
          {[...(values.files ?? [])].map((e) => (
            <Fragment>
              <br /> {e.name}
            </Fragment>
          ))}
          <input
            accept="image/jpeg,image/gif,image/png,image/bmp,application/pdf,image/x-eps"
            type="file"
            multiple
            onChange={(e) => setFieldValue('files', e.target.files)}
          />
        </label>
      </div>

      <div className=" new-table--row--item select w25">
        <Select value={values.type} options={notesOptions} placeholder="Select chain" onChange={(option) => setFieldValue('type', option.value)} />
      </div>

      <div className="w15 new-table--row--item select">
        <DateSelect
          minDate={new Date()}
          selected={values.due_date}
          onChange={({ name, date }) => setFieldValue(name, date)}
          placeholder="* Due date"
          name="due_date"
        />
      </div>
      <div className="w15 new-table--row--item select">
        <TimeSelect name="due_time" onChange={({ name, date }) => setFieldValue(name, date)} placeholder="* Due time" value={values.due_time} />
      </div>
      <div className="w20 new-table--row--item select">
        <Select name="author_id" onChange={(option) => setFieldValue('author_id', option.value)} options={agentsOption} value={values.author_id} />
      </div>
      <div className="new-table--row--item w10 select no-padding">
        <button className="main flex-grow-1" disabled={!values.due_time || !values.due_date} onClick={addClaim}>
          Add
        </button>
      </div>
    </div>
  );
};

export default ClaimCreateForm;

export default {
  additional_insured: '',
  approved_liability: 0,
  approved_wc: 0,
  complete_liability: 0,
  complete_wc: 0,
  emergency_request: 0,
  enabled: 0,
  sent_type: 0,
  fax: '',
  liability_certificate: [],
  wc_certificate: [],
  building_id: 0,
  agent_name: '',
  property_name: '',
  property_address: '',
  property_manager_email: '',
  attachments: [],
  company_name: '',
  certificate_holder: '',
  resident_name: '',
  resident_unit_number: '',
  phone: '',
  disabled_wc: 0,
  long_carry: 0,
  double_elevator: 0,
  security_escort: 0,
  low_clearance: 0,
  no_loading_dock: 0,
  floor_protection: 0,
  shared_elevator: 0,
  elevator_exist: 0,
  elevator_reservation: false,
  required_coi: 0,
  building_type: null,
  // type:0,
  // email_sent:0,
  liability_status: null,
};

import React, { useEffect } from 'react';
import { useGetLongDistanceTariff } from 'hooks/query/useJob';
import RadioButton from 'components/newComponents/RadioButton';
import TooltipTitle from 'components/TooltipTitle';
import LongDistanceForm from './LongDistanceForm';
import StorageForm from './StorageForm';
import LeadForm from './LeadForm';
import { getUnitSizesByStorageId, getMoveSizesByCompanyId, getFees } from '../../utils';
import { useForm } from '../../hooks/useForm';
import { useFetcher } from '../../hooks/useFetcher';

const PrimaryForm = ({ data }) => {
  const { getDistance } = useFetcher();

  const {
    values,
    actions: { setFieldValue, handleChangeJobType },
  } = useForm();

  const moveSizes = getMoveSizesByCompanyId(data.move_sizes, values.company_id);

  const storageFees = getFees(data.storages, values.storage_id, values.company_id);

  const unitSizes = getUnitSizesByStorageId(data.storage_unit_sizes, values.storage_id);

  const { data: tariff } = useGetLongDistanceTariff(
    {
      company_id: values.company_id,
      from_state: values.from_state,
      to_state: values.to_state,
    },
    {
      enabled: Boolean(values.company_id) && Boolean(values.from_state) && Boolean(values.to_state),
      onSuccess: (data) => {
        if (data.cf_price) {
          setFieldValue('cf_price', data.cf_price);
          setFieldValue('total_price', Number(data.cf_price) * Number(values.job_inventory_total));
        }
      },
    }
  );

  useEffect(() => {
    const zipFrom = values.from_zipcode;
    const zipTo = values.to_zipcode;
    const companyId = values.company_id || 1;

    if (zipFrom?.replace(/\D/g, '')?.length === 5 && zipTo?.replace(/\D/g, '')?.length === 5) {
      async function setDistance() {
        const distanceData = await getDistance(zipFrom, zipTo, companyId);

        if (distanceData) {
          setFieldValue('drop_off_to_office', distanceData?.drop_off_to_office);
          setFieldValue('pickup_distance', distanceData?.pickup_distance);
          setFieldValue('travel_distance', distanceData?.travel_distance);
        }
      }

      setDistance();
    }
  }, [setFieldValue, getDistance, values.company_id, values.from_zipcode, values.to_zipcode]);

  return (
    <div className="new-table modal">
      <div className="new-table--row">
        <div className="new-table--row--item justify-content-around">
          <TooltipTitle>
            <RadioButton value="lead" title="Local" name="job_type" current={values['job_type']} onChange={() => handleChangeJobType('lead')} />
          </TooltipTitle>

          <TooltipTitle>
            <RadioButton
              value="long_distance"
              title="Long Distance"
              name="job_type"
              current={values['job_type']}
              onChange={() => handleChangeJobType('long_distance')}
            />
          </TooltipTitle>

          <TooltipTitle>
            <RadioButton
              value="storage"
              title="Storage"
              name="job_type"
              current={values['job_type']}
              onChange={() => handleChangeJobType('storage')}
            />
          </TooltipTitle>
        </div>
      </div>

      {values.job_type === 'lead' && (
        <LeadForm
          companies={data.companies}
          moveSizes={moveSizes}
          leadProviders={data.lead_providers}
          buildingInfo={data.building_info}
          packingDescription={data.packingDescription}
        />
      )}

      {values.job_type === 'long_distance' && (
        <LongDistanceForm
          unitSizes={unitSizes}
          storageFees={storageFees}
          tariff={tariff}
          companies={data.companies}
          storages={data.storages}
          moveSizes={moveSizes}
          leadProviders={data.lead_providers}
          buildingInfo={data.building_info}
          packingDescription={data.packingDescription}
        />
      )}

      {values.job_type === 'storage' && (
        <StorageForm
          unitSizes={unitSizes}
          storageFees={storageFees}
          tariff={tariff}
          companies={data.companies}
          storages={data.storages}
          moveSizes={moveSizes}
          leadProviders={data.lead_providers}
          buildingInfo={data.building_info}
          packingDescription={data.packingDescription}
        />
      )}
    </div>
  );
};

export default PrimaryForm;

import React, { Fragment } from 'react';
import Select from 'components/newComponents/Select';
import { laborTypeOptions } from 'helpers/constants';
import { calcTypeOptions } from 'helpers/constants';
import { storagePaymentTypes } from 'helpers/storage';
import * as View from './View';
import * as Fields from './Fields';
import { useForm } from '../../hooks/useForm';
import { getCompaniesOptions, getStoragesOptions } from '../../utils';

function StorageForm(props) {
  const { companies, storages, packingDescription, moveSizes, buildingInfo, leadProviders, unitSizes, storageFees, tariff } = props;

  const { values, actions } = useForm();

  return (
    <Fragment>
      <Fields.Base />

      <div className="new-table--row">
        <div className="new-table--row--item select">
          <Select
            name="company_id"
            value={values['company_id']}
            placeholder="Companies"
            options={getCompaniesOptions(companies)}
            error={actions.isValidHandler('company_id')}
            onChange={actions.handleChangeCompany}
          />
        </div>
      </div>

      <div className="new-table--row">
        <div className="new-table--row--item select position-relative">
          <Select
            name="storage_id"
            placeholder="Storage"
            value={values['storage_id']}
            error={actions.isValidHandler('storage_id')}
            options={getStoragesOptions(storages, values.company_id)}
            onChange={({ value }) => actions.setFieldValue('storage_id', value)}
          />
        </div>
      </div>

      <Fields.Move moveSizes={moveSizes} />

      {values.storage_id && <Fields.DeliveryDate companies={companies} unitSizes={unitSizes} />}

      {values.storage_id && (
        <div className="new-table--row">
          <div className="new-table--row--item">Calculation Type</div>
          <div className="new-table--row--item select">
            <Select
              name="calc_type"
              options={calcTypeOptions}
              value={values['calc_type']}
              onChange={(value) => actions.handleChangeCalcType(value, companies, unitSizes)}
            />
          </div>
        </div>
      )}

      {values.storage_id && <Fields.StorageFees storageFees={storageFees} />}

      {values.storage_id && (
        <div className="new-table--row">
          <div className="new-table--row--item no-side-shadows">Storage Payment Method</div>
          <div className="new-table--row--item select">
            <Select
              options={storagePaymentTypes}
              value={values['storage_payment_method']}
              name="storage_payment_method"
              onChange={({ value }) => actions.setFieldValue('storage_payment_method', value)}
            />
          </div>
        </div>
      )}

      <Fields.HearFrom leadProviders={leadProviders} />

      <div className="new-table--row">
        <div className="new-table--row--item">Service type</div>
        <div className="new-table--row--item select">
          <Select
            placeholder="Service type"
            name="labor_type"
            value={values['labor_type']}
            options={laborTypeOptions}
            onChange={actions.handleChangeLaborType}
          />
        </div>
      </div>

      {values.pickup_date && values.pickup_time && <View.CrewAvailability moveSizes={moveSizes} />}

      <Fields.Address buildingInfo={buildingInfo} />

      {values.company_id && <Fields.InventoryTable companyID={values.company_id} unitSizes={unitSizes} tariff={tariff} />}

      {values.storage_id && <Fields.UnitSizes unit_sizes={unitSizes} companies={companies} />}

      {values.delivery_date && values.unit_sizes && values.unit_sizes.length > 0 && (
        <Fields.StorageRate companies={companies} unit_sizes={unitSizes} />
      )}

      <View.Route />

      <Fields.Insurance />

      <View.EstTime />

      <Fields.Additional />

      <Fields.PackingService packingDescription={packingDescription} moveSizes={moveSizes} />

      <View.Distance />
    </Fragment>
  );
}

export default StorageForm;

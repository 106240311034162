import React, { useCallback, useMemo } from 'react';
import moment from 'moment';
import { useFormik } from 'formik';
import { ModalButton } from 'components/newComponents/Buttons';
import Modal from 'components/newComponents/Modal';
import { useModal } from 'context/modal';
import DateSelect from 'components/DateSelect';
import TimeSelect from 'components/TimeSelect';
import MciEditor from 'components/MciEditor';
import { useInfo, useSave } from 'hooks/query/useReminder';
import { formatFullDate, formatTimeAPMeridiem } from 'helpers/dateHelper';

const EditReminder = () => {
  const { modalNames, info, close } = useModal();
  const { mutate: saveRemainder } = useSave();

  const isVisible = info(modalNames.editReminder).visible;
  const params = info(modalNames.editReminder).params;

  const { data } = useInfo(params.id);

  const initial = useMemo(() => {
    const init = { body: null, reminder_id: params.id, subject: '', email_to: '', send_time: '', send_date: '' };

    if (data) {
      return {
        ...data.reminder,
        reminder_id: params.id,
        send_time: formatTimeAPMeridiem(moment.unix(data.reminder.send_time)),
        send_date: formatFullDate(moment.unix(data.reminder.send_time)),
      };
    }

    return init;
  }, [data, params.id]);

  const { values, setFieldValue, handleChange } = useFormik({
    enableReinitialize: true,
    initialValues: initial,
  });

  const saveHandler = useCallback(() => {
    saveRemainder(values, { onSuccess: () => close(modalNames.editReminder) });
  }, [close, values, modalNames.editReminder, saveRemainder]);

  return (
    <Modal show={isVisible} style={{ width: 800 }} header="Edit" onHide={() => close(modalNames.editReminder)}>
      <div className="new-table modal">
        <div className="new-table--row">
          <div className="new-table--row--item">
            <input type="text" name="subject" onChange={handleChange} value={values.subject} />
          </div>
        </div>

        <div className="new-table--row">
          <div className="new-table--row--item">
            <input type="text" name="email_to" value={values.email_to} onChange={handleChange} />
          </div>
        </div>

        <div className="new-table--row">
          <div className="new-table--row--item">
            <DateSelect
              minDate={new Date()}
              selected={values.send_date}
              onChange={({ name, date }) => setFieldValue(name, formatFullDate(date))}
              placeholder="* Due date"
              name="send_date"
            />
          </div>

          <div className="new-table--row--item">
            <TimeSelect name="send_time" value={values.send_time} onChange={({ name, date }) => setFieldValue(name, formatTimeAPMeridiem(date))} />
          </div>
        </div>

        <div className="new-table--row">
          <div className="new-table--row--item">
            <MciEditor handleEditorChange={(body) => setFieldValue('body', body)} value={values.body} />
          </div>
        </div>
      </div>
      <div className="footer-buttons">
        <ModalButton title="Cancel" variant="cancel" onClick={() => close(modalNames.editReminder)} />
        <ModalButton title="Save" variant="confirm" onClick={saveHandler} />
      </div>
    </Modal>
  );
};

export default EditReminder;

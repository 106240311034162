import React from 'react';
import Dates from './Dates';
import FinalCalc from './FinalCalc';
import Summary from './Summary';

import './style.scss';

const Estimated = () => {
  return (
    <div className="d-flex justify-content-between job_details mt-20">
      <Dates />
      <FinalCalc />
      <Summary />
    </div>
  );
};

export default Estimated;

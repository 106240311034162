import React, { memo, useMemo, useState, useCallback } from 'react';
import { useFormik } from 'formik';
import { useDefault } from 'hooks/query/useCommon';
import { useLocation } from 'react-router-dom';
import { matchPath } from 'react-router';
import { routePaths } from 'config/routes';
import { useClaimUpdate, useClaimDelete, useClaimClose } from 'hooks/query/useClaim';
import { formatL, isValid } from 'helpers/dateHelper';
import Icon from 'components/Icon';
import { notesOptions } from 'helpers/constants';
import Select from 'components/newComponents/Select';
import DateSelect from 'components/DateSelect';
import TimeSelect from 'components/TimeSelect';

const ClaimItem = memo(({ claim, counter, closed }) => {
  const [isDisabled, setIsDisabled] = useState(true);

  const { pathname } = useLocation();

  const {
    params: { id, tab },
  } = matchPath(pathname, { path: routePaths.JOBS });
  const {
    data: { users },
  } = useDefault();

  const { mutate: closeClaim } = useClaimClose(id, tab);
  const { mutate: deleteClaim } = useClaimDelete(id, tab);
  const { mutate: updateClaim } = useClaimUpdate(id, tab);

  const agentsOption = useMemo(() => {
    const list = [...new Map([...users.agents, ...users.managers].map((user) => [user['id'], user])).values()];
    return list.map((user) => ({ value: user.id, label: `${user.first_name} ${user.last_name}` }));
  }, [users.agents, users.managers]);

  const { values, setFieldValue, handleChange } = useFormik({ initialValues: { ...claim } });

  const handleToggleEdit = () => setIsDisabled((pre) => !pre);

  const saveClaim = useCallback(() => {
    updateClaim(values, { onSuccess: handleToggleEdit });
  }, [updateClaim, values]);

  const closeClaimHandler = useCallback(() => {
    closeClaim(claim.id);
    setFieldValue('close', true);
  }, [setFieldValue, closeClaim, claim]);

  const deleteClaimHandler = useCallback(() => {
    deleteClaim(claim.id);
  }, [deleteClaim, claim]);

  return (
    <div className="new-table--row">
      <div className="new-table--row--item w10 no-side-shadows justify-content-center">
        <span>{counter}</span>
      </div>
      <div className="new-table--row--item w15 no-side-shadows justify-content-center">
        <input type="text" disabled={isDisabled} value={values.claim} name="claim" onChange={handleChange} />
      </div>
      <div className="new-table--row--item w15 no-side-shadows justify-content-center select">
        <Select
          options={notesOptions}
          disabled={isDisabled}
          placeholder="Select type"
          value={values.type}
          name="type"
          onChange={({ value }) => setFieldValue('type', value)}
        />
      </div>
      <div className="new-table--row--item w15 no-side-shadows justify-content-center">
        <span>{claim.created_at}</span>
      </div>
      <div className="new-table--row--item w15 no-side-shadows justify-content-center select">
        {isDisabled && (isValid(values?.due_date) ? <span> {formatL(values.due_date) + ' ' + values.due_time}</span> : <span>No due date</span>)}

        {!isDisabled && (
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <DateSelect
              minDate={new Date()}
              selected={values.due_date}
              onChange={({ name, date }) => setFieldValue(name, date)}
              placeholder="* Due date"
              name="due_date"
            />
            <TimeSelect name="due_time" onChange={({ name, date }) => setFieldValue(name, date)} placeholder="* Due time" value={values.due_time} />
          </div>
        )}
      </div>
      <div className="new-table--row--item w15 no-side-shadows justify-content-center">
        <Select
          name="author_id"
          onChange={(option) => setFieldValue('author_id', option.value)}
          options={agentsOption}
          disabled={isDisabled}
          value={values.author_id}
        />
      </div>

      <div className="new-table--row--item w20 no-side-shadows justify-content-between">
        {!closed.includes(claim.id) ? (
          <button type="button" onClick={closeClaimHandler}>
            <Icon icon="task_completed" title="Close claim" size={20} />
          </button>
        ) : (
          <span style={{ width: 40 }} />
        )}
        <button type="button" onClick={isDisabled ? handleToggleEdit : saveClaim}>
          <Icon size={20} icon={isDisabled ? 'split_job' : 'booked'} title={isDisabled ? 'Edit' : 'Save'} />
        </button>
        <button type="button" onClick={isDisabled ? deleteClaimHandler : handleToggleEdit}>
          <Icon size={20} icon={isDisabled ? 'delete' : 'canceled'} title={isDisabled ? 'Delete' : 'Cancel'} />
        </button>
        {!!claim.attachment?.length && <span style={{ width: 27 }} />}
        {claim.attachment?.length > 1 && (
          <a target="_blank" title="Download" rel="noreferrer noopener" href={'/api/downloadZipClaimAttachments?claim_id=' + claim.id}>
            <Icon icon="download" size={20} title="Save attachment" />
          </a>
        )}
        {claim.attachment?.length === 1 && (
          <a target="_blank" rel="noreferrer noopener" href={'/api/downloadAttachments?path=' + claim.attachment[0].path}>
            <Icon icon="download" size={20} title="Save attachment" />
          </a>
        )}
      </div>
    </div>
  );
});

export default ClaimItem;

import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useApi } from 'context/api';
import queryKeys from 'config/queryKeys';

const fiveHourInSeconds = 60 * 60 * 5;

export const useList = (params) => {
  const { crew } = useApi();

  return useQuery(queryKeys.crews, () => crew.list(params));
};

export const useUnavailableList = (params) => {
  const { crew } = useApi();

  return useQuery([queryKeys.unavailableCrew, params], () => crew.unavailableList(params), { select: (res) => res.data });
};

export const useMoverRemove = (id, tab) => {
  const { crew } = useApi();

  const queryClient = useQueryClient();

  return useMutation(crew.removeMover, {
    onSuccess: () => queryClient.invalidateQueries([queryKeys.job, id, tab]),
  });
};

export const useAssignedUnitChange = (id, tab) => {
  const { crew } = useApi();

  const queryClient = useQueryClient();

  return useMutation(crew.changeAssignedUnit, {
    onSuccess: () => queryClient.invalidateQueries([queryKeys.job, id, tab]),
  });
};

export const useAssign = () => {
  const { crew } = useApi();

  return useMutation(crew.assign);
};

export const useAssignedJobsList = (params) => {
  const { crew } = useApi();

  return useQuery([queryKeys.assignedJobsToCrew, params], () => crew.assignedJobsList(params), { select: (res) => res.data });
};

export const useUnitAvailabilitySave = () => {
  const { crew } = useApi();

  return useMutation(crew.saveUnitAvailability);
};

export const useScheduleList = (params) => {
  const { crew } = useApi();

  return useQuery([queryKeys.crewsSchedule, params], () => crew.scheduleList(params), { select: (res) => res.data });
};

export const useAssignedSchedule = (params, options) => {
  const { crew } = useApi();

  return useQuery(queryKeys.assignedCrewSchedule, () => crew.assignedSchedule(params), {
    select: (res) => res.data,
    staleTime: 0,
    cacheTime: 0,
    ...options,
  });
};

export const useAvailableUnitsByDate = (params, options) => {
  const { crew } = useApi();

  return useQuery(queryKeys.availableUnitsByDate, () => crew.availableUnitsByDate(params), {
    select: (res) => res.data,
    ...options,
  });
};

export const useBookedJobsByDate = (params, options) => {
  const { crew } = useApi();

  return useQuery([queryKeys.bookedJobsByDate, params.start_date, params.stop_date, params.date], () => crew.bookedJobsByDate(params), {
    select: (res) => res.data,
    staleTime: fiveHourInSeconds,
    cacheTime: fiveHourInSeconds,
    ...options,
  });
};

export const useInfo = (params, options) => {
  const { crew } = useApi();

  return useQuery([queryKeys.crew, ...Object.values(params)], () => crew.info(params), {
    select: (res) => res.data,
    staleTime: 0,
    cacheTime: 0,
    refetchOnWindowFocus: false,
    ...options,
  });
};

export const useAssignConfirm = (id, tab) => {
  const { crew } = useApi();

  const queryClient = useQueryClient();

  return useMutation(crew.assignConfirm, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.job, id]);
      queryClient.invalidateQueries([queryKeys.job, id, tab]);
    },
  });
};

export const useAssignCancel = (id, tab, params) => {
  const { crew } = useApi();

  const queryClient = useQueryClient();

  return useMutation(crew.assignCancel, {
    onSuccess: () => {
      if (id) queryClient.invalidateQueries([queryKeys.job, id]);
      if (id && tab) queryClient.invalidateQueries([queryKeys.job, id, tab]);
      if (params) queryClient.invalidateQueries([queryKeys.crewsSchedule, params]);
    },
  });
};

export const useScheduleCreate = (id, tab, params) => {
  const { crew } = useApi();

  const queryClient = useQueryClient();

  return useMutation(crew.scheduleCreate, {
    onSuccess: () => {
      if (id) queryClient.invalidateQueries([queryKeys.job, id]);
      if (id && tab) queryClient.invalidateQueries([queryKeys.job, id, tab]);
      if (params) queryClient.invalidateQueries([queryKeys.crewsSchedule, params]);
    },
  });
};

import React, { Fragment, useCallback, useMemo } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { DropdownMenu, ButtonLinkItem } from 'components/newComponents/DropDown';
import Icon from 'components/Icon';

export const SmsInbound = ({ item, openImg, communications, openVideo }) => {
  const isRead = Boolean(item.read);

  return (
    <li className="history-list-item history-list-item-reverse">
      <div className="timeline">
        <button onClick={() => communications('sms', item.chat_id)}>
          <Icon icon="email_sent" />
        </button>
        {item?.time && (
          <p className="timeline-time">
            {moment.unix(item.time).format('ddd MM/DD LT')}
            {isRead ? (
              <span className="read">
                <Icon icon="read" size={12} title="The message has been read" />
              </span>
            ) : (
              <span className="unread">
                <Icon icon="unread" size={12} title="Message not read" />
              </span>
            )}
          </p>
        )}
      </div>
      <div className="d-flex flex-column align-items-end">
        <div>
          {item.image !== '' && !item.image.match(new RegExp('.mp4|.3gp', 'gi')) && (
            <button onClick={() => openImg(item.image)}>
              <img width={200} src={item.image} alt="open" />
            </button>
          )}
          {item.image !== '' && item.image.match(new RegExp('.mp4|.3gp', 'gi')) && (
            <button onClick={() => openVideo(item.image)}>
              <video width={320} height={240} controls>
                <source src={item.image} type="video/mp4" />
              </video>
            </button>
          )}
          {item?.message && (
            <span className="history-list-item-message" style={{ maxWidth: 600 }}>
              {item?.message}
            </span>
          )}
        </div>
      </div>
    </li>
  );
};

export const SmsOutbound = ({ item, communications, openImg, openVideo }) => {
  return (
    <li className="history-list-item">
      <div className="timeline">
        <button onClick={() => communications('sms', item.chat_id)}>
          <Icon className="email_sent" title="*** Sms outbound" />
        </button>
        {item?.first_name && <p className="timeline-name">{item.first_name + ' ' + item.last_name}</p>}
        {item?.time && <p className="timeline-time">{moment.unix(item.time).format('ddd MM/DD LT')}</p>}
      </div>
      <div className="d-flex flex-column">
        {item?.image && !item.image.match(new RegExp('.mp4|.3gp', 'gi')) && (
          <button onClick={() => openImg(item.image)}>
            <img width={200} src={item.image} alt="open" />
          </button>
        )}
        {item.image !== '' && item.image.match(new RegExp('.mp4|.3gp', 'gi')) && (
          <p className="upload-docs">
            <button onClick={() => openVideo(item.image)}>
              <video width={320} height={240} controls>
                <source src={item.image} type="video/mp4" />
              </video>
            </button>
          </p>
        )}
        <span className="history-list-item-message outbound">{item?.message}</span>
      </div>
    </li>
  );
};

export const SmsReminder = ({ item, communications, openImg, isOpen }) => {
  return (
    <li
      className={classNames({
        'd-none': !isOpen,
        'history-list-item': isOpen,
      })}
    >
      <div className="timeline">
        <button onClick={() => communications('sms', item.chat_id)}>
          <Icon icon="sms_reminder" title="Sms Reminder" />
        </button>
        {item?.time && (
          <p className="timeline-time">
            {item.created_at ? 'created at ' : 'send'}
            {moment.unix(item.time).format('ddd MM/DD LT')}
          </p>
        )}
      </div>
      <div className="d-flex flex-column">
        {item.image !== '' && (
          <button onClick={() => openImg(item.image)}>
            <img width={200} src={item.image} alt="open" />
          </button>
        )}
        <span className="history-list-item-message outbound">{item?.message}</span>
      </div>
    </li>
  );
};

export const SmsReminderFuture = ({ item, communications, openImg, isOpen }) => {
  return (
    <li
      className={classNames({
        'd-none': !isOpen,
        'history-list-item': isOpen,
      })}
    >
      <div className="timeline">
        <button onClick={() => communications('sms', item.chat_id)}>
          <Icon icon="email_sent" title="***Sms Reminder" />
        </button>
        {item?.time && <p className="timeline-time">{moment.unix(item.time).format('ddd MM/DD LT')}</p>}
      </div>
      <div>
        {item.image && (
          <button onClick={() => openImg(item.image)}>
            <img width={200} src={item.image} alt="open" />
          </button>
        )}
        <span className="history-list-item-message outbound">{item?.message}</span>
      </div>
    </li>
  );
};

export const EmailInbox = ({ item, communicationsMail }) => {
  const isRead = Boolean(item.read);

  return (
    <li className="history-list-item history-list-item-reverse">
      <div className="timeline">
        <button onClick={() => communicationsMail('inbox_email_job', item.id)}>
          <Icon icon="email_reminder" title="***Email inbox" />
        </button>

        {item?.time && (
          <p className="timeline-time">
            {moment.unix(item.time).format('ddd MM/DD LT')}
            {isRead ? (
              <span className="read">
                <Icon icon="read" size={12} title="The message has been read" />
              </span>
            ) : (
              <span className="unread">
                <Icon icon="unread" size={12} title="Message not read" />
              </span>
            )}
          </p>
        )}
      </div>
      <div className="d-flex justify-content-end">
        <button onClick={() => communicationsMail('inbox_email_job', item.id)}>
          <span className="history-list-item-message">{item?.subject}</span>
        </button>
      </div>
    </li>
  );
};

export const EmailOutbox = ({ item, communicationsMail }) => {
  return (
    <li className="history-list-item">
      <div className="timeline">
        <button onClick={() => communicationsMail('outbox_email_job', item.id)}>
          <Icon icon="send" title="Email outbox" />
        </button>
        <p className="timeline-name">{item.first_name ? item.first_name + ' ' + item.last_name : 'Client'}</p>
        {item?.time && <p className="timeline-time">{moment.unix(item.time).format('ddd MM/DD LT')}</p>}
      </div>
      <button onClick={() => communicationsMail('outbox_email_job', item.id)}>
        <span className="history-list-item-message outbound">{item?.subject}</span>
      </button>
    </li>
  );
};

export const EmailReminder = ({ item, communicationsMail, isOpen }) => {
  return (
    <li
      className={classNames({
        'd-none': !isOpen,
        'history-list-item': isOpen,
      })}
    >
      <div className="timeline">
        <button onClick={() => communicationsMail('reminders_sent', item.id)}>
          <Icon icon="email_reminder" title="***Email reminder" />
        </button>

        {item?.first_name && <p className="timeline-name">{item.first_name + ' ' + item.last_name}</p>}
        {item?.time && (
          <Fragment>
            <p className="timeline-time">
              {item.created_at ? 'created at ' : 'send '}
              {moment.unix(item.time).format('ddd MM/DD LT')}
            </p>
            <p className="timeline-time">
              {item.updated_at ? 'sent ' : 'send '}
              {moment.unix(item.sent_time).format('ddd MM/DD LT')}{' '}
            </p>
          </Fragment>
        )}
      </div>
      <button onClick={() => communicationsMail('reminders_sent', item.id)}>
        <span className="history-list-item-message outbound">{item?.subject}</span>
      </button>
    </li>
  );
};

export const EmailReminderFuture = ({ item, communicationsMail, isOpen }) => {
  return (
    <li
      className={classNames({
        'd-none': !isOpen,
        'history-list-item': isOpen,
      })}
    >
      <div className="timeline">
        <button onClick={() => communicationsMail('outbox_email')}>
          <Icon icon="email_reminder" title="*** Email reminder future" />
        </button>
        {item?.first_name && <p className="timeline-name">{item.first_name + ' ' + item.last_name}</p>}
        {item?.message && <div className="messages__time">{moment.unix(item.time).format('ddd MM/DD LT')}</div>}
      </div>
      <button onClick={() => communicationsMail('outbox_email', item.id)}>
        <span className="history-list-item-message outbound">{item?.subject}</span>
      </button>
    </li>
  );
};

export const StatusChange = ({ item, getStatus }) => {
  if (!item.data) return null;
  return (
    <li className="history-list-item">
      <div className="timeline">
        <div className="timeline-line">
          <div className="col no-padding">{item?.first_name && <p className="timeline-name">{item.first_name + ' ' + item.last_name}</p>}</div>
          <div className="col-7 no-padding">
            <p className="timeline-status">
              Status change: <br />
              {Number(item.data.prev) === 0 ? '' : getStatus(item.data.prev) + '  >  '}
              {getStatus(item.data.next)}
            </p>
            {item.data && <p className="timeline-time">{moment.unix(item.time).format('ddd MM/DD LT')}</p>}
          </div>
          <div className="col no-padding" />
        </div>
      </div>
    </li>
  );
};

export const RateChange = ({ item, getStatus }) => {
  return (
    <li className="history-list-item">
      <div className="timeline">
        <div className="timeline-line">
          <div className="col no-padding">{item?.first_name && <p className="timeline-name">{item.first_name + ' ' + item.last_name}</p>}</div>
          <div className="col-7 no-padding">
            <p className="timeline-status">
              Rate change: &nbsp;
              <br /> {+item.data.prev === 0 ? '' : '$' + item.data.prev + '  >  '}
              {'$' + item.data.next}
            </p>
            {item.data && <p className="timeline-time">{moment.unix(item.time).format('ddd MM/DD LT')}</p>}
          </div>
          <div className="col no-padding" />
        </div>
      </div>
    </li>
  );
};

export const DateChange = ({ item }) => {
  return (
    <li className="history-list-item">
      <div className="timeline">
        <div className="timeline-name">{item.data.type}</div>
        <div className="timeline-line">
          <div className="col no-padding">{item?.first_name && <p className="timeline-name">{item.first_name + ' ' + item.last_name}</p>}</div>
          <div className="col-7 no-padding">
            <p className="timeline-status">
              Date change: <br /> {item.data.prev ? item.data.prev + '  >  ' : ''}
              {item.data.next}
            </p>
            {item.data && <p className="timeline-time">{moment.unix(item.time).format('ddd MM/DD LT')}</p>}
          </div>
          <div className="col no-padding" />
        </div>
      </div>
    </li>
  );
};

export const Tasks = ({ item, open }) => {
  return (
    <li className="history-list-item">
      <div className="timeline">
        <button onClick={open}>
          <Icon icon="tasks" title="Tasks" />
        </button>
        <p className="timeline-name">{item.first_name ? item.first_name + ' ' + item.last_name : 'Client'}</p>
        {item?.time && <p className="timeline-time">{moment.unix(item.time).format(' ddd MM/DD LT')}</p>}
      </div>
      <span className="history-list-item-message">{item?.task}</span>
    </li>
  );
};

export const JobCoi = ({ item }) => {
  return (
    <li className="messages__item history-list-item">
      <div className="timeline">
        <div className="timeline-status">{item.title}</div>
        <div className="timeline-status">{item.text}</div>
        {item?.first_name && <p className="timeline-name">{item.first_name + ' ' + item.last_name}</p>}
        <p className="timeline-time">{moment.unix(item.time).format('ddd MM/DD LT')}</p>
      </div>
    </li>
  );
};

export const Claims = ({ item, open, attachments }) => {
  return (
    <li className="history-list-item">
      <div className="timeline">
        <button onClick={open}>
          <Icon icon="claims" title="Claims" />
        </button>
        <p className="timeline-name"> {item.first_name ? item.first_name + ' ' + item.last_name : 'Client'}</p>
        {item?.time && <p className="timeline-time">{moment.unix(item.time).format('ddd MM/DD LT')}</p>}
      </div>
      <div className="d-flex flex-column">
        <p>
          {item.claim}
          <br />
          {attachments ? attachments : ''}
        </p>
      </div>
    </li>
  );
};

export const ExtraStop = ({ item, open }) => {
  return (
    <li className="history-list-item">
      <div className="timeline">
        <button onClick={open}>
          <Icon icon="extra_stop" title="Extra Stop" />
        </button>
        {item?.time && <p className="timeline-time">{moment.unix(item.time).format('ddd MM/DD LT')}</p>}
      </div>
      <span className="history-list-item-message">{item.zip}</span>
    </li>
  );
};

export const AgentNote = ({ item, open }) => {
  return (
    <li className="history-list-item">
      <div className="timeline">
        <button onClick={open}>
          <Icon icon="notes" title={+item.note_type === 6 ? 'Lead Note' : 'Agent Note'} />
        </button>
        {item?.first_name && <p className="timeline-name">{item.first_name + ' ' + item.last_name}</p>}
        {item?.note && <p className="timeline-time">{moment.unix(item.time).format('ddd MM/DD LT')}</p>}
      </div>
      <span className="history-list-item-message outbound">{item.note}</span>
    </li>
  );
};

export const AgentNoteTo = ({ item, open }) => {
  return (
    <li className="history-list-item">
      <div className="timeline">
        <button onClick={open}>
          <Icon icon="notes" title={+item.note_type === 6 ? 'Lead Note' : 'Agent Note'} />
        </button>
        {item?.first_name && <p className="timeline-name">{item.first_name + ' ' + item.last_name}</p>}
        {item?.note && <p className="timeline-time">{moment.unix(item.time).format('ddd MM/DD LT')}</p>}
      </div>
      <div className="d-flex justify-content-end">
        <span className="history-list-item-message outbound">{item.note}</span>
      </div>
    </li>
  );
};

export const InternalNote = ({ item, open }) => {
  return (
    <li className="history-list-item">
      <div className="timeline">
        <button onClick={open}>
          <Icon icon="internal_notes" title="Internal Note" />
        </button>
        {item.first_name && <p className="timeline-name">{item.first_name + ' ' + item.last_name}</p>}
        {item?.note && <p className="timeline-time">{moment.unix(item.time).format('ddd MM/DD LT')}</p>}
      </div>
      <span className="history-list-item-message">{item.note}</span>
    </li>
  );
};

export const CallFrom = ({ item, callResult, schedule, confirmed, cancel }) => {
  const handleSelect = useCallback(
    (val) => {
      switch (val) {
        default:
          return;
        case 'left_voicemail':
          callResult('Left Voicemail', item.sid);
          break;
        case 'spoke_to':
          callResult('Spoke to and gave guote', item.sid);
          break;
        case 'follow_up':
          schedule(item.sid);
          break;
        case 'confirmed':
          confirmed(item.sid);
          break;
        case 'too_expensive':
          callResult('Too Expensive', item.sid);
          break;
        case 'canceled':
          cancel(item.sid);
          break;
      }
    },
    [callResult, cancel, confirmed, item.sid, schedule]
  );

  const actionOptions = useMemo(() => {
    const options = [
      {
        label: 'Left Voicemail',
        value: 'left_voicemail',
      },
      {
        label: 'Spoke to and gave quote',
        value: 'spoke_to',
      },
      {
        label: 'Schedule Follow Up',
        value: 'follow_up',
      },
      {
        label: 'Confirmed',
        value: 'confirmed',
      },
      {
        label: 'Too Expensive',
        value: 'too_expensive',
      },
      {
        label: 'Canceled',
        value: 'canceled',
      },
    ];

    return options.map((el) => {
      return <ButtonLinkItem key={el.value} label={el.label} value={el.value} onClick={(value) => handleSelect(value)} />;
    });
  }, [handleSelect]);

  return (
    <li className="history-list-item history-list-item-reverse ">
      <div className="timeline">
        <div>
          <Icon icon="call" title="Call" />
        </div>
        {item?.first_name && <p className="timeline-name">{item.first_name + ' ' + item.last_name}</p>}
        {item?.time && <p className="timeline-time"> {moment.unix(item.time).format('ddd MM/DD LT')} </p>}
      </div>
      <div className="d-flex flex-column">
        <div>
          {item.recording.length > 0 && (
            <audio controls>
              <source src={item.recording} type="audio/mpeg" />
            </audio>
          )}
          {item.recording.length === 0 && <p>Missed call</p>}
        </div>
        <div>
          {item.recording.length !== 0 && (
            <div className="position-relative">
              <p>{item.call_result}</p>
              <DropdownMenu trigger="Result" triggerClass="main" wrapperClass="changeWrapper">
                {actionOptions}
              </DropdownMenu>
            </div>
          )}
        </div>
      </div>
    </li>
  );
};

export const CallTo = ({ item, callResult, schedule, confirmed, cancel }) => {
  const handleSelect = useCallback(
    (val) => {
      switch (val) {
        default:
          return;
        case 'left_voicemail':
          callResult('Left Voicemail', item.sid);
          break;
        case 'spoke_to':
          callResult('Spoke to and gave guote', item.sid);
          break;
        case 'follow_up':
          schedule(item.sid);
          break;
        case 'confirmed':
          confirmed(item.sid);
          break;
        case 'too_expensive':
          callResult('Too Expensive', item.sid);
          break;
        case 'canceled':
          cancel(item.sid);
          break;
      }
    },
    [callResult, cancel, confirmed, item.sid, schedule]
  );

  const actionOptions = useMemo(() => {
    const options = [
      {
        label: 'Left Voicemail',
        value: 'left_voicemail',
      },
      {
        label: 'Spoke to and gave quote',
        value: 'spoke_to',
      },
      {
        label: 'Schedule Follow Up',
        value: 'follow_up',
      },
      {
        label: 'Confirmed',
        value: 'confirmed',
      },
      {
        label: 'Too Expensive',
        value: 'too_expensive',
      },
      {
        label: 'Canceled',
        value: 'canceled',
      },
    ];

    return options.map((el) => {
      return <ButtonLinkItem key={el.value} label={el.label} value={el.value} onClick={(value) => handleSelect(value)} />;
    });
  }, [handleSelect]);

  const isRead = Boolean(item.read);

  return (
    <li className="history-list-item">
      <div className="timeline">
        <div>
          <Icon icon="call" title="***Call outbound" />
        </div>
        {item.first_name && <p className="timeline-name">{item.first_name + ' ' + item.last_name}</p>}
        {item?.time && <p className="timeline-time">{moment.unix(item.time).format('ddd MM/DD LT')}</p>}
        {item?.message && (
          <p className="timeline-time">
            {moment.unix(item.time).format('ddd MM/DD LT')}
            {isRead ? (
              <span className="read">
                <Icon icon="read" size={12} title="The message has been read" />
              </span>
            ) : (
              <span className="unread">
                <Icon icon="unread" size={12} title="Message not read" />
              </span>
            )}
          </p>
        )}
      </div>
      <div className="d-flex flex-column">
        <div>
          {item.recording.length > 0 && (
            <audio controls>
              <source src={item.recording} type="audio/mpeg" />
            </audio>
          )}
          {item.recording.length === 0 && <p>Missed call</p>}
        </div>
        <div>
          {item.recording.length !== 0 && (
            <div className="position-relative width100">
              <div className="col-10">{item.call_result}</div>
              <DropdownMenu trigger="Result" triggerClass="main" wrapperClass="changeWrapper">
                {actionOptions}
              </DropdownMenu>
            </div>
          )}
        </div>
      </div>
    </li>
  );
};

export const ClaimMailReceive = ({ item, communicationsMail }) => {
  return (
    <li className="history-list-item">
      <div className="timeline">
        <button onClick={() => communicationsMail('inbox_email_job', item.id)}>
          <Icon icon="claim" title="***Claim mail reveive" />
        </button>
        {item?.time && <p className="timeline-time">{moment.unix(item.time).format('ddd MM/DD LT')}</p>}
      </div>
      <button onClick={() => communicationsMail('inbox_email_job', item.id)}>
        <span className="history-list-item-message">{item.subject}</span>
      </button>
    </li>
  );
};

export const ClaimMailSent = ({ item, communicationsMail }) => {
  return (
    <li className="history-list-item">
      <div className="timeline">
        <button onClick={() => communicationsMail('outbox_email_job', item.id)}>
          <Icon icon="send" title="Claim mail sent" />
        </button>
        <p className="timeline-name">{item.first_name ? item.first_name + ' ' + item.last_name : 'Client'}</p>
        {item?.time && <p className="timeline-time">{moment.unix(item.time).format('ddd MM/DD LT')}</p>}
      </div>
      <div className="d-flex justify-coontent-end">
        <button onClick={() => communicationsMail('outbox_email_job', item.id)}>
          <span className="history-list-item-message">{item.subject}</span>
        </button>
      </div>
    </li>
  );
};

export const InvoiceSent = ({ item }) => {
  return (
    <li className="history-list-item">
      <div className="timeline-line">
        <div className="mx-auto no-padding">
          <p className="timeline-status">Invoice Sent</p>
          {item.time && <p className="timeline-time">{moment.unix(item.time).format('ddd MM/DD LT')}</p>}
        </div>
      </div>
    </li>
  );
};

export const InvoicePaid = ({ item }) => {
  return (
    <li className="history-list-item">
      <div className="timeline-line">
        <div className="mx-auto no-padding">
          <p className="timeline-status">Invoice Paid</p>
          {item.time && <p className="timeline-time">{moment.unix(item.time).format('ddd MM/DD LT')}</p>}
        </div>
      </div>
    </li>
  );
};

export const InvoicePaymentFailed = ({ item }) => {
  return (
    <li className="history-list-item">
      <div className="timeline-line">
        <div className="mx-auto no-padding">
          <p className="timeline-status">Invoice Payment Failed</p>
          {item.time && <p className="timeline-time">{moment.unix(item.time).format('ddd MM/DD LT')}</p>}
        </div>
      </div>
    </li>
  );
};

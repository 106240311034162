import React, { useMemo, useCallback } from 'react';
import Icon from 'components/Icon';
import { useFormik } from 'formik';
import { useLocation } from 'react-router-dom';
import { matchPath } from 'react-router';
import { routePaths } from 'config/routes';
import { useList, useApplyChain } from 'hooks/query/useReminder';
import SmsReminderModal from 'pages/modal/SmsReminder';
import Select from 'components/newComponents/Select';
import { useModal } from 'context/modal';

const SmsReminderCreateForm = () => {
  const { open, info, modalNames } = useModal();
  const { pathname } = useLocation();

  const {
    params: { id },
  } = matchPath(pathname, { path: routePaths.JOBS });

  const { data } = useList(id);

  const { mutate: apply } = useApplyChain(id);

  const isReminderModal = info(modalNames.smsReminder).visible;

  const { values, setFieldValue } = useFormik({ initialValues: {} });

  const applySmsChain = useCallback(() => {
    apply({ chain_id: values.sms_chain_id, job_id: id });
  }, [apply, id, values.sms_chain_id]);

  const chainOptions = useMemo(() => {
    if (!data) return [];
    return data.sms_chains.map((item) => ({ value: item.id, label: item.name }));
  }, [data]);

  return (
    <div className="new-table--row">
      {isReminderModal && <SmsReminderModal />}
      <div className="new-table--row--item w60">
        <button className="default" onClick={() => open(modalNames.smsReminder, { id })}>
          <Icon icon="add" size={18} title="New" />
          Add SMS Reminder
        </button>
      </div>

      <div className="new-table--row--item select w20">
        <Select
          options={chainOptions}
          name="sms_chain_id"
          value={values.sms_chain_id}
          placeholder="Select a chain"
          onChange={({ name, value }) => setFieldValue('sms_chain_id', value)}
        />
      </div>

      <div className="new-table--row--item w20 no-padding">
        <button className="main flex-grow-1 p20" disabled={!values.sms_chain_id} onClick={applySmsChain}>
          Apply chain
        </button>
      </div>
    </div>
  );
};

export default SmsReminderCreateForm;

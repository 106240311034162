import React, { memo, useMemo, Fragment } from 'react';
import ReactTooltip from 'react-tooltip';
import Icon from 'components/Icon';
import { useDefault } from 'hooks/query/useCommon';

const JobTooltip = memo(({ job, additionalInfo, visible }) => {
  const { data: defaultItems } = useDefault();

  const companyName = useMemo(() => {
    if (defaultItems) return '';
    const company = defaultItems?.companies.find((company) => company.id === job.company_id);
    return company?.company_name || '';
  }, [defaultItems, job.company_id]);

  return visible ? (
    <ReactTooltip border={true} place="right" type="light" clickable={true} id={`${job.title}${job.id}`} getContent={() => null}>
      <div className="tooltip-container">
        <div className="tooltip-row">
          <Icon icon="select_company" size={22} />
          <span>{companyName}</span>
        </div>
        <div className="tooltip-row">
          <Icon icon="job_number" size={22} />
          <span>{job.title}</span>
        </div>
        <div className="tooltip-row">
          <Icon icon="manager" size={22} />
          <span>
            {job.agent_first_name} {job.agent_last_name}
          </span>
        </div>

        {additionalInfo && (
          <Fragment>
            <div className="tooltip-row">
              <Icon icon="driver_info" size={22} />
              <span>
                {additionalInfo.driver_name} {additionalInfo.driver_phone}
              </span>
            </div>

            {additionalInfo.movers.map((mover, index) => (
              <div key={index} className="tooltip-row">
                <Icon icon="mover_info" size={22} />
                <span>
                  {mover.name} {mover.phone}
                </span>
              </div>
            ))}
          </Fragment>
        )}

        {job.assignments && (
          <div className="tooltip-row">
            <Icon icon="trucks" size={22} />
            <span>{job.assignments[2].movers}</span>
          </div>
        )}
        <div className="tooltip-row">
          <Icon icon="sold" size={22} />
          <span>{job.partner_name ?? ''}</span>
        </div>
        <div className="tooltip-row">
          <Icon icon="name" size={22} />
          <span>{job.customer_name}</span>
        </div>
        <div className="tooltip-row">
          <Icon icon="put_calls" size={22} />
          <span>{job.home_phone}</span>
        </div>
        <div className="tooltip-row">
          <Icon icon="address" size={22} />
          <span>
            {job.from_address} {job.from_city} {job.from_state} {job.from_zip}
          </span>
        </div>
        <div className="tooltip-row">
          <Icon icon="address" size={22} />
          <span>
            {job.to_address} {job.to_city}
            {job.to_state} {job.to_zip}
          </span>
        </div>
        <div className="tooltip-row">
          <Icon icon="arrive_time" size={22} />
          <span>{job.pickup_date}</span>
        </div>
        <div className="tooltip-row">
          <Icon icon="move_size" size={22} />
          <span>{job.move_size}</span>
        </div>
        <div className="tooltip-row">
          <Icon icon="movers" size={22} />
          <span>{job.qty_man}</span>
        </div>
        <div className="tooltip-row">
          <Icon icon="estimated_total" size={22} />
          <span>{job.estimated_total}</span>
        </div>
      </div>
    </ReactTooltip>
  ) : null;
});

export default JobTooltip;

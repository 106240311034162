import React from 'react';
import { useFormik } from 'formik';
import { useInternalNotesHeadersSave } from 'hooks/query/useJob';

const NotesCreateForm = ({ jobId, tab }) => {
  const { mutate: saveNote } = useInternalNotesHeadersSave(jobId, tab);

  const { values, handleChange, handleSubmit, setFieldValue } = useFormik({
    initialValues: { note: '' },
    onSubmit: ({ note }) => {
      saveNote({ internal_notes: note, job_id: jobId, id: null });
      setFieldValue('note', '');
    },
  });

  return (
    <form className="new-table--row" onSubmit={handleSubmit}>
      <div className="new-table--row--item">
        <textarea placeholder="Internal note" rows={10} name="note" value={values.note} onChange={handleChange} />
      </div>

      <div className="new-table--row--item col-2">
        <button type="submit" className="main flex-grow-1">
          Add
        </button>
      </div>
    </form>
  );
};

export default NotesCreateForm;

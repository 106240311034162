import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useLogin } from 'hooks/query/useAuth';
import routes from 'config/routes';
import './Login.scss';

const Login = () => {
  const history = useHistory();
  const { mutate: login } = useLogin();

  const [auth, setAuth] = useState({ login: '', password: '' });

  const changeHandler = useCallback((e) => {
    setAuth((pre) => ({ ...pre, [e.target.name]: e.target.value }));
  }, []);

  const submitHandler = useCallback(
    (e) => {
      e.preventDefault();
      login(auth, { onSuccess: () => history.push(routes.CLIENT) });
    },
    [auth, history, login]
  );

  return (
    <div className="login-wrapper">
      <form onSubmit={submitHandler} className="login-wrapper--form" aria-label="login form">
        <div className="input-group">
          <label>Login</label>
          <input name="login" type="text" value={auth.login} placeholder="Username" onChange={changeHandler} />
        </div>

        <div className="input-group">
          <label>Password</label>
          <input name="password" type="password" value={auth.password} placeholder="Password" onChange={changeHandler} />
        </div>
        <button type="submit">Sign in</button>
      </form>
    </div>
  );
};

export default Login;

import React, { Component, Fragment } from 'react';
import Pagination from 'react-paginate';
import Jobs from './Jobs';

class CommunicationsJobList extends Component {
  state = {
    perPage: 20,
    page: 0,
  };

  handlePaginationChange = (page) => this.setState({ page: page.selected });

  getName = (id) => {
    const agent = this.props.info.find((user) => +id === +user.id);
    return agent.first_name + ' ' + agent.last_name || '';
  };

  render() {
    const jobs = this.props.info.reduce((acc, item) => [...acc, ...item.jobs_agent], []);
    jobs.sort((first, second) => second.id - first.id);

    const jobsOnPage = jobs.slice(this.state.page * this.state.perPage, (this.state.page + 1) * this.state.perPage);

    return (
      <Fragment>
        <div className="reports-table">
          <div className="reports-table-row">
            <div className="reports-table-column reports-table-column--5 reports-table-column--title">Job ID</div>
            <div className="reports-table-column reports-table-column--5 reports-table-column--title">Company</div>
            <div className="reports-table-column reports-table-column--5 reports-table-column--title">Date</div>
            <div className="reports-table-column reports-table-column--5 reports-table-column--title">Agent name</div>
            <div className="reports-table-column reports-table-column--5 reports-table-column--title">Email/Calls/Sms</div>
          </div>
        </div>

        <Jobs jobs={jobsOnPage} getName={this.getName} />

        <Pagination
          pageCount={Math.ceil(jobs.length / this.state.perPage)}
          pageRangeDisplayed={5}
          marginPagesDisplayed={2}
          containerClassName="email-pagination pagination"
          subContainerClassName="pages pagination"
          activeClassName="active"
          onPageChange={this.handlePaginationChange}
        />
      </Fragment>
    );
  }
}

export default CommunicationsJobList;

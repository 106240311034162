import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useApi } from 'context/api';
import queryKeys from 'config/queryKeys';
import makeFormData from 'utils/makeFormData';

const fifteenMinutesInSeconds = 60 * 60 * 15;

export const usePriority = (params) => {
  const { job } = useApi();
  const queryClient = useQueryClient();

  return useMutation(job.priority, {
    onMutate: async (newJob) => {
      await queryClient.cancelQueries([queryKeys.jobPage, params]);

      const previousJobs = queryClient.getQueryData([queryKeys.jobPage, params]);

      queryClient.setQueryData([queryKeys.jobPage, params], (old) => {
        const jobs = old.data.map((job) => (job.id === newJob.id ? newJob : job));
        return { ...old, data: jobs };
      });

      return previousJobs;
    },

    onError: (err, newTodo, previousJobs) => {
      queryClient.setQueryData([queryKeys.jobPage, params], previousJobs);
    },
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.jobPage, params]);
    },
  });
};

export const useTabInfo = (id, tab, options) => {
  const { job } = useApi();

  return useQuery([queryKeys.job, id, tab], () => job.tabInfo({ id, tab }), {
    select: (res) => res.data,
    cacheTime: fifteenMinutesInSeconds,
    staleTime: fifteenMinutesInSeconds,
    ...options,
  });
};

export const useGetCarrierDrivers = (carrierId, options = {}) => {
  const { job } = useApi();

  return useQuery([queryKeys.carrierDrivers, carrierId], () => job.getCarrierDrivers(carrierId), {
    select: (res) => res.data,
    ...options,
  });
};

export const useCreateCarrierDriver = () => {
  const { job } = useApi();
  const queryClient = useQueryClient();

  return useMutation(job.createCarrierDriver, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.carrierDrivers]);
    },
  });
};

export const useAddJobCarrier = () => {
  const { job } = useApi();

  return useMutation(job.addJobCarrier);
};

export const useInfo = (id, options) => {
  const { job } = useApi();

  return useQuery([queryKeys.job, id], () => job.info({ id }), {
    select: (res) => res.data,
    cacheTime: fifteenMinutesInSeconds,
    staleTime: fifteenMinutesInSeconds,
    ...options,
  });
};

export const useInfoAsync = () => {
  const { job } = useApi();

  return useMutation(job.info);
};

export const useTabInfoAsync = () => {
  const { job } = useApi();

  return useMutation(job.tabInfo);
};

export const useReadCommunication = () => {
  const { job } = useApi();
  const queryClient = useQueryClient();

  return useMutation(job.readCommunication, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.counters]);
    },
  });
};

export const useConfirmRescheduleRateChange = () => {
  const { job } = useApi();
  const queryClient = useQueryClient();

  return useMutation(job.confirmRescheduleRateChange, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.job]);
    },
  });
};

export const useReschedule = (id) => {
  const { job } = useApi();
  const queryClient = useQueryClient();

  return useMutation(job.reschedule, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.job, id]);
    },
  });
};

export const useAssignManagement = (jobId, tab) => {
  const { job } = useApi();
  const queryClient = useQueryClient();

  return useMutation(job.managementAssign, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.job, jobId]);
      queryClient.invalidateQueries([queryKeys.job, jobId, tab]);
    },
  });
};

export const useJobTimeUpdate = (jobId) => {
  const { job } = useApi();
  const queryClient = useQueryClient();

  return useMutation(job.timeUpdate, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.job, jobId]);
    },
  });
};

export const useMovSizeChange = (id) => {
  const { job } = useApi();
  const queryClient = useQueryClient();

  return useMutation(job.changeMoveSize, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.job, id]);
    },
  });
};

export const usePackingSave = (id, tab) => {
  const { job } = useApi();
  const queryClient = useQueryClient();

  return useMutation(job.savePacking, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.job, id]);
      queryClient.invalidateQueries([queryKeys.job, id, tab]);
    },
  });
};

export const useCoiComplete = () => {
  const { job } = useApi();
  return useMutation(job.coiComplete);
};

export const useNotesUpdate = (id, tab) => {
  const { job } = useApi();

  const queryClient = useQueryClient();

  return useMutation(job.notesUpdate, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.job, id, tab]);
    },
  });
};

export const useDeleteJobInternalNote = (id, tab) => {
  const { job } = useApi();

  const queryClient = useQueryClient();

  return useMutation(job.deleteJobInternalNote, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.job, id, tab]);
    },
  });
};

export const useChangeServiceType = () => {
  const { job } = useApi();

  return useMutation(job.changeServiceType);
};

export const useCommunicationsList = (jobId, type) => {
  const { job } = useApi();

  return useQuery([queryKeys.communication, jobId, type], () => job.communicationsList({ job_id: jobId, type }), {
    select: (res) => res.data,
  });
};

export const useGetCommunicationCounter = (jobId) => {
  const { job } = useApi();

  return useQuery([queryKeys.jobGeneralCounters, jobId], () => job.getCommunicationCounter(jobId), {
    select: (res) => res.data,
  });
};

export const useCloneJob = () => {
  const { job } = useApi();

  return useMutation(job.clone);
};

export const useInternalNotesHeadersSave = (jobId, tab) => {
  const { job } = useApi();

  const queryClient = useQueryClient();

  return useMutation(job.saveInternalNotesHeaders, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.job, jobId, tab]);
    },
  });
};

export const useInventorySave = (id, tab) => {
  const { job } = useApi();
  const queryClient = useQueryClient();

  return useMutation(job.saveInventory, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.job, id]);
      queryClient.invalidateQueries([queryKeys.job, id, tab]);
    },
  });
};

export const useInventoryConditionSave = (id, tab) => {
  const { job } = useApi();
  const queryClient = useQueryClient();

  return useMutation(job.saveInventoryCondition, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.job, id]);
      queryClient.invalidateQueries([queryKeys.job, id, tab]);
    },
  });
};

export const useInsuranceUpdate = (id, tab) => {
  const { job } = useApi();

  const queryClient = useQueryClient();

  return useMutation(job.updateInsurance, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.job, id]);
      queryClient.invalidateQueries([queryKeys.job, id, tab]);
    },
  });
};

export const useDiscountUpdate = (id, tab) => {
  const { job } = useApi();

  const queryClient = useQueryClient();

  return useMutation(job.updateDiscount, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.job, id]);
      queryClient.invalidateQueries([queryKeys.job, id, tab]);
    },
  });
};

export const useFeesUpdate = (id, tab) => {
  const { job } = useApi();

  const queryClient = useQueryClient();

  return useMutation(job.updateFees, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.job, id, tab]);
      queryClient.invalidateQueries([queryKeys.job, id]);
    },
  });
};

export const useLDFeesUpdate = (id, tab) => {
  const { job } = useApi();

  const queryClient = useQueryClient();

  return useMutation(job.updateLDFees, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.job, id, tab]);
      queryClient.invalidateQueries([queryKeys.job, id]);
    },
  });
};

export const useChargesUpdate = (id, tab) => {
  const { job } = useApi();

  const queryClient = useQueryClient();

  return useMutation(job.updateCharges, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.job, id]);
      queryClient.invalidateQueries([queryKeys.job, id, tab]);
    },
  });
};

export const useChargeCreate = () => {
  const { job } = useApi();

  const queryClient = useQueryClient();

  return useMutation(job.createCharge, {
    select: (res) => res.data,
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries(queryKeys.defaultItems);
    },
  });
};

export const useManualValues = (id) => {
  const { job } = useApi();

  const queryClient = useQueryClient();

  return useMutation(job.setManualValues, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.job, id]);
    },
  });
};

export const usePaymentsCreate = (id, tab) => {
  const { job } = useApi();

  const queryClient = useQueryClient();

  return useMutation((data) => job.createPayments(makeFormData(data)), {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.job, id, tab]);
      queryClient.invalidateQueries([queryKeys.job, id]);
      queryClient.invalidateQueries([queryKeys.jobPage]);
    },
  });
};

export const useManualTipsAdd = (id, tab) => {
  const { job } = useApi();

  const queryClient = useQueryClient();

  return useMutation(job.addManualTips, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.job, id, tab]);
      queryClient.invalidateQueries([queryKeys.job, id]);
    },
  });
};

export const useSelfBookingUpdate = (id, tab) => {
  const { job } = useApi();

  const queryClient = useQueryClient();

  return useMutation(job.updateSelfBooking, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.job, id, tab]);
      queryClient.invalidateQueries([queryKeys.job, id]);
    },
  });
};

export const useRateDataUpdate = (jobId) => {
  const { job } = useApi();

  const queryClient = useQueryClient();

  return useMutation(job.updateRateData, {
    onSuccess: () => queryClient.invalidateQueries([queryKeys.job, jobId]),
  });
};

export const useChangeStatus = (jobId, params) => {
  const { job } = useApi();

  const queryClient = useQueryClient();

  return useMutation(job.changeStatus, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.job, jobId]);
      queryClient.invalidateQueries([queryKeys.jobPage, params]);
    },
  });
};

export const useJobUpdateToStorage = (jobId, tab, params) => {
  const { job } = useApi();

  const queryClient = useQueryClient();

  return useMutation(job.jobUpdateToStorage, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.job, jobId]);
      queryClient.invalidateQueries([queryKeys.job, jobId, tab]);

      if (params && params.onSuccess) {
        params.onSuccess();
      }
    },
  });
};

export const useChangeJobStorage = (jobId, tab) => {
  const { job } = useApi();

  const queryClient = useQueryClient();

  return useMutation(job.changeJobStorage, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.job, jobId]);
      queryClient.invalidateQueries([queryKeys.job, jobId, tab]);
      queryClient.invalidateQueries([queryKeys.defaultItems]);
    },
  });
};

export const useChangeStorageEndDate = (jobId, tab) => {
  const { job } = useApi();

  const queryClient = useQueryClient();

  return useMutation(job.changeStorageEndDate, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.job]);
      queryClient.invalidateQueries([queryKeys.defaultItems]);
    },
  });
};

export const useUpdateJobUnits = (jobId, tab) => {
  const { job } = useApi();
  const queryClient = useQueryClient();

  return useMutation(job.updateJobUnits, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.jobUnits, jobId]);
      queryClient.invalidateQueries([queryKeys.job, jobId]);
      queryClient.invalidateQueries([queryKeys.defaultItems]);
      queryClient.invalidateQueries([queryKeys.job, jobId, tab]);
    },
  });
};

export const useDeleteStorageUnitSize = (jobId, tab) => {
  const { job } = useApi();
  const queryClient = useQueryClient();

  return useMutation(job.deleteStorageUnitSize, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.jobUnits, jobId]);
      queryClient.invalidateQueries([queryKeys.defaultItems]);
      queryClient.invalidateQueries([queryKeys.job, jobId, tab]);
    },
  });
};

export const useUpdateStorageFee = (jobId, tab) => {
  const { job } = useApi();
  const queryClient = useQueryClient();

  return useMutation(job.updateStorageFee, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.jobUnits, jobId]);
      queryClient.invalidateQueries([queryKeys.defaultItems]);
      queryClient.invalidateQueries([queryKeys.job, jobId, tab]);
    },
  });
};

export const useUpdateStorageRate = (jobId, tab) => {
  const { job } = useApi();
  const queryClient = useQueryClient();

  return useMutation(job.updateManualStorageRate, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.jobUnits, jobId]);
      queryClient.invalidateQueries([queryKeys.defaultItems]);
      queryClient.invalidateQueries([queryKeys.job, jobId, tab]);
      queryClient.invalidateQueries([queryKeys.job, jobId]);
    },
  });
};

export const useUpdateStorageTotalPrice = (jobId, tab) => {
  const { job } = useApi();
  const queryClient = useQueryClient();

  return useMutation(job.updateManualStorageTotalPrice, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.jobUnits, jobId]);
      queryClient.invalidateQueries([queryKeys.defaultItems]);
      queryClient.invalidateQueries([queryKeys.job, jobId, tab]);
    },
  });
};

export const useGetJobUnits = (jobId, tab) => {
  const { job } = useApi();

  return useQuery([queryKeys.jobUnits, jobId], () => job.getJobUnits(jobId), {
    select: (res) => res.data,
  });
};

export const useAdditionalInfoSave = (jobId, params) => {
  const { job } = useApi();

  const queryClient = useQueryClient();

  return useMutation(job.saveAdditionalInfo, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.job, jobId]);
      queryClient.invalidateQueries([queryKeys.jobPage, params]);
    },
  });
};

export const useJobToDropped = (jobId, params) => {
  const { job } = useApi();

  return useMutation(job.changeJobToDropped);
};

export const useChangeElevatorStatus = (jobId, tab) => {
  const { job } = useApi();
  const queryClient = useQueryClient();

  return useMutation(job.changeElevatorStatus, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.job, jobId]);
      queryClient.invalidateQueries([queryKeys.job, jobId, tab]);
    },
  });
};

export const useGetOngoingStorageJobs = () => {
  const { job } = useApi();

  return useQuery([queryKeys.ongoingStorageJobs], () => job.getOngoingStorageJobs(), {
    select: (res) => res.data,
  });
};

export const useAddLocalJobInventoryToOngoingStorageJob = () => {
  const { job } = useApi();

  return useMutation(job.addLocalJobInventoryToOngoingStorageJob);
};

export const useGetPopupInfo = (jobId) => {
  const { jobs } = useApi();

  return useQuery([queryKeys.popupInfo, jobId], () => jobs.getPopupInfo(jobId), {
    enabled: false,
    retry: false,
    cacheTime: fifteenMinutesInSeconds,
    staleTime: fifteenMinutesInSeconds,
    select: (res) => res.data,
  });
};

export const useGetLongDistanceTariff = (data, params = {}) => {
  const { job } = useApi();

  return useQuery([queryKeys.longDistanceTariff, data], () => job.getLDTariff(data), {
    select: (res) => res.data,
    ...params,
  });
};

export const useSendStorageStripeInvoice = () => {
  const { job } = useApi();

  return useMutation(job.sendStorageStripeInvoice);
};

export const useAddOptionalAccessorialServices = () => {
  const { job } = useApi();

  return useMutation(job.addOptionalAccessorialServices);
};

export const useAddHouseholdGoods = () => {
  const { job } = useApi();

  return useMutation(job.addHouseholdGoods);
};

export const useAddOtherServices = () => {
  const { job } = useApi();

  return useMutation(job.addOtherServices);
};

import React from 'react';
import { useForm } from '../../../hooks/useForm';

function Distance() {
  const { values } = useForm();

  return (
    <div className="new-table--row">
      <div className="new-table--row--item new-table--row--item__column">
        <p>
          Estimated Travel Distance is <span className="form-title">{values?.travel_distance || 0} miles.</span>
          {values?.travel_distance === false && 'Distance can not be calculated. Please try again later on created job.'}
        </p>
        <p>
          Distance from Office to PickUp is <span className="form-title">{values?.pickup_distance || 0} miles.</span>
          {values?.pickup_distance === false && 'Distance can not be calculated. Please try again later on created job.'}
        </p>
        <p>
          Distance from DropOff to Office is <span className="form-title">{values?.drop_off_to_office || 0} miles.</span>
          {values?.drop_off_to_office === false && 'Distance can not be calculated. Please try again later on created job.'}
        </p>
      </div>
    </div>
  );
}

export default Distance;

import React, { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { matchPath } from 'react-router';
import { routePaths } from 'config/routes';
import { useFormik } from 'formik';
import { alert } from 'components/Alerts';
import { useModal } from 'context/modal';
import { useDefault } from 'hooks/query/useCommon';
import { useInfo, useTabInfo } from 'hooks/query/useJob';
import { useSave, useUpdate } from 'hooks/query/useInsurance';
import { ModalButton } from 'components/newComponents/Buttons';
import Modal from 'components/newComponents/Modal';
import { findBuilding } from 'helpers/buildings';
import initialValues from './initialValues';
import InsuranceForm from './InsuranceForm';

const InsuranceProvider = () => {
  const { pathname } = useLocation();

  const {
    params: { id, tab },
  } = matchPath(pathname, { path: routePaths.JOBS });

  const { modalNames, close, info } = useModal();

  const { mutate: updateInsurance } = useUpdate(id, tab);
  const { mutate: saveInsurance } = useSave(id, tab);

  const isVisible = info(modalNames.insuranceProvider).visible;
  const params = info(modalNames.insuranceProvider).params;

  const closeHandler = useCallback(() => close(modalNames.insuranceProvider), [close, modalNames.insuranceProvider]);

  const {
    data: { job_info: jobInfo, additional_info: additionalInfo },
  } = useInfo(id);

  const {
    data: { job_insurance_info: jobInsuranceInfo, moving_info: movingInfo },
  } = useTabInfo(id, tab);

  const {
    data: { insurance_providers: insuranceProviders, building_info: buildingInfo },
  } = useDefault();

  const transformBuilding = useCallback((info) => {
    const buildingAdr = `${info.building_address} ${info.building_unit_number} ${info.building_city} ${info.building_state} ${info.building_zip}`;

    return {
      ...info,
      building_id: info.id,
      agent_name: info.manager_name,
      property_manager_email: info.manager_email,
      property_name: info.building_name,
      company_name: info.association_name,
      resident_unit_number: buildingAdr,
    };
  }, []);

  const getBuilding = useCallback(
    (type) => {
      const movingAddr = `${movingInfo[type].address} ${movingInfo[type].city} ${movingInfo[type].state} ${movingInfo[type].zip}`;

      const addressToFind = {
        zipcode: movingInfo[type]?.zip,
        address: movingInfo[type]?.address,
        street_number: movingInfo[type]?.street,
        building_name: movingInfo[type]?.building_name,
        place_id: movingInfo[type]?.place_id
      };

      const info = findBuilding(buildingInfo, addressToFind);

      if (info) {
        return {
          ...initialValues,
          ...transformBuilding(info),
          property_address: movingAddr,
        };
      }
      return {
        ...initialValues,
        property_address: movingAddr,
        resident_unit_number: movingAddr,
      };
    },
    [buildingInfo, movingInfo, transformBuilding]
  );

  const insuranceData = useMemo(() => {
    const type = params.type;
    const jobId = jobInfo.id;
    const companyId = jobInfo.company_id;

    let info = movingInfo[type];
    let addInfo = additionalInfo[type];
    let insuranceInfo = jobInsuranceInfo[type];
    let buildingInfo = getBuilding(type);

    if (!insuranceInfo) {
      let insuranceProvider = insuranceProviders[companyId];

      return {
        ...buildingInfo,
        ...info,
        building_type: addInfo?.type_of_building,
        resident_name: info?.customer_name,
        insurance_provider: insuranceProvider['liability'][0]?.id || 0,
        insurance_provider_worker: insuranceProvider['workers'][0]?.id || 0,
        job_id: jobId,
        type: type === 'from' ? 1 : 2,
        send_request: 1,
        enabled: 1,
        additional_insured: '',
        wc_status: null,
        approving_email_sent: false,
      };
    }

    return {
      ...buildingInfo,
      ...insuranceInfo,
      job_id: jobId,
      send_request: 1,
      enabled: jobId,
      files: [],
      emergency_request: !!insuranceInfo.emergency_request,
      approving_email_sent: insuranceInfo?.approving_email_sent ?? false,
    };
  }, [insuranceProviders, jobInfo, jobInsuranceInfo, movingInfo, params.type, getBuilding]);

  const saveHandler = useCallback(
    (values) => {
      updateInsurance(values, {
        onSuccess: closeHandler,
      });
    },
    [closeHandler, updateInsurance]
  );

  const { values, handleChange, handleSubmit, setFieldValue, setValues } = useFormik({
    initialValues: insuranceData,
    onSubmit: saveHandler,
    enableReinitialize: true,
  });

  const sendRequest = useCallback(
    (providerType) => {
      if (+insuranceData.insurance_provider === 0) {
        closeHandler();
        alert({
          title: 'Error',
          text: 'Please choose Insurance Provider',
          icon: 'error',
        });
        return;
      }

      const type = params.type;
      if (
        +insuranceData?.elevator_reservation === 1 &&
        (additionalInfo[type]?.elevator_reservation_start === '' || additionalInfo[type]?.elevator_reservation_end === '')
      ) {
        alert({
          title: 'Elevator reservation required',
          text: 'Please select time range!',
          icon: 'error',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Continue anyway',
        }).then((result) => {
          if (result.isConfirmed) {
            saveInsurance({ ...values, sent_type: providerType });
            closeHandler();
          }
        });
        return;
      }

      if (+jobInfo.status === 7 || +jobInfo.status === 21) {
        closeHandler();
        alert({
          title: 'Job must be in POSTSALE status. Please Book a job',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'OK',
        });
        return;
      }
      saveInsurance({ ...values, sent_type: providerType });
    },
    [closeHandler, insuranceData.insurance_provider, jobInfo.status, saveInsurance, values, params]
  );

  const buildingOptions = useMemo(() => {
    if (!buildingInfo) return [];

    return buildingInfo.map((e) => ({
      value: e.building_name,
      label: e.building_name,
    }));
  }, [buildingInfo]);

  return (
    <Modal style={{ width: '800px' }} header="Insurance Provider" show={isVisible} onHide={closeHandler}>
      <form onSubmit={handleSubmit}>
        <InsuranceForm
          values={values}
          type={params.type}
          setValues={setValues}
          sendRequest={sendRequest}
          handleChange={handleChange}
          setFieldValue={setFieldValue}
          buildingOptions={buildingOptions}
          transformBuilding={transformBuilding}
        />
        <div className="footer-buttons">
          <ModalButton title="Cancel" variant="cancel" onClick={closeHandler} />
          <ModalButton type="submit" title="Save Insurance Info" variant="confirm" />
        </div>
      </form>
    </Modal>
  );
};

export default InsuranceProvider;

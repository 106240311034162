import React, { useCallback } from 'react';
import Modal from 'components/newComponents/Modal';
import { useModal } from 'context/modal';
import Main from './components/Main';
import './style.scss';

const QuickForm = () => {
  const { modalNames, close, info } = useModal();

  const isVisible = info(modalNames.quickForm).visible;
  const params = info(modalNames.quickForm)?.params;

  const closeHandler = useCallback(() => close(modalNames.quickForm), [close, modalNames.quickForm]);

  return (
    <Modal style={{ width: 700 }} header="Quick form" show={isVisible} onHide={closeHandler}>
      <Main params={params} closeHandler={closeHandler} />
    </Modal>
  );
};

export default QuickForm;

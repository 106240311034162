import React, { useMemo, useState, memo } from 'react';
import { useParams } from 'react-router';
import { communicationsTab } from 'helpers/constants';
import { useCommunicationsList } from 'hooks/query/useJob';
import Icon from 'components/Icon';
import JobNotesMenu from './JobNotesMenu';
import JobSendMessages from './JobSendMessages';
import JobCommunicationsHistory from './JobCommunicationsHistory';

import './JobCommunications.scss';

const JobCommunications = memo(({ type, onSetType }) => {
  const { id, tab } = useParams();
  const [open, setOpen] = useState(true);

  const { data, refetch: onRefresh } = useCommunicationsList(id, type);

  const isClaim = useMemo(() => {
    if (!data) return false;
    return data.job_claim;
  }, [data]);

  React.useEffect(() => {
    const listener = (data) => {
      if (data.job_id !== id) return;
      onRefresh();
    };
    window.socket.on('refresh_job_communication', listener);

    return () => window.socket.off('refresh_job_communication', listener);
  }, [id, onRefresh]);

  return (
    <div className="job-communications">
      <h3 className="header table-header-shadow">Job communications history</h3>
      <div className="job-communications-messages">
        <div className="job-communications-messages-chat">
          {isClaim && type !== 'claim' && (
            <button type="button" onClick={() => onSetType(communicationsTab.claim)}>
              <Icon icon="claims" title="Claim" />
            </button>
          )}
        </div>
        <JobCommunicationsHistory section={type} isOpen={open} communications={data} refetchCommunications={onRefresh} />
      </div>
      <JobSendMessages type={type} />
      <JobNotesMenu id={id} tab={tab} />
    </div>
  );
});

export default JobCommunications;

import React, { Component } from 'react';
import Icon from '../Icon';
import './Dropdown.scss';

class Dropdown extends Component {
  constructor(props) {
    super(props);
    this.dropdownBoxRef = React.createRef();
    this.state = {
      isMenuVisible: false,
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  toggleMenu = (e) => {
    if (e) e.stopPropagation();

    this.setState((prevState) => ({ isMenuVisible: !prevState.isMenuVisible }));
  };

  handleClickOutside = (event) => {
    if (this.dropdownBoxRef && !this.dropdownBoxRef.current.contains(event.target) && this.state.isMenuVisible && this.props.autoHide) {
      this.toggleMenu();
    }
  };

  render() {
    const {
      iconName,
      iconTitle,
      iconSize,
      iconStyle,
      buttonText,
      children,
      divClassname,
      dropdownClass,
      dontHide,
      buttonClass,
      buttonStyle,
      horizontal,
    } = this.props;

    return (
      <div ref={this.dropdownBoxRef} className={`dropdown-wrapper ${dropdownClass || ''}`}>
        <button onClick={this.toggleMenu} className={buttonClass} style={buttonStyle}>
          {iconName && <Icon icon={iconName} size={iconSize} title={iconTitle} style={iconStyle} />}
          {buttonText}
        </button>
        {this.state.isMenuVisible && (
          <div
            className={`menu ${divClassname ? divClassname : ''} ${horizontal ? 'horizontal' : ''}`}
            onClick={(e) => {
              !dontHide && this.toggleMenu(e);
            }}
          >
            {children}
          </div>
        )}
      </div>
    );
  }
}

export default Dropdown;

import React from 'react';

const CommunicationsTotal = (props) => {
  if (!props.info) return null;

  if (Array.isArray(props.info)) {
    let agents = [];
    let sorted = props.info.sort((first, second) => second.follow_up - first.follow_up);

    sorted.forEach((agent, key) => {
      const perc_booked = +agent.total_lead !== 0 ? 100 * (Number(agent.booked) / Number(agent.total_lead)) : 0;

      agents.push(
        <div className="reports-table-row" key={key}>
          <div className="reports-table-column reports-table-column--20">{agent.first_name + ' ' + agent.last_name}</div>
          <div className="reports-table-column reports-table-column--20">{agent.total_lead}</div>
          <div className="reports-table-column reports-table-column--20">{agent.follow_up}</div>
          <div className="reports-table-column reports-table-column--20">{agent.booked}</div>
          <div className="reports-table-column reports-table-column--20">{perc_booked.toFixed(0)}%</div>
          <div className="reports-table-column reports-table-column--20">{agent.lost}</div>
          <div className="reports-table-column reports-table-column--20">{agent.canceled}</div>
          <div className="reports-table-column reports-table-column--20">{agent.calls_inbound}</div>
          <div className="reports-table-column reports-table-column--20">{agent.calls_inbound_answered}</div>
          <div className="reports-table-column reports-table-column--20">{agent.calls_inbound_missed}</div>
          <div className="reports-table-column reports-table-column--20">{agent.calls_out}</div>
          <div className="reports-table-column reports-table-column--20">{agent.sms_in}</div>
          <div className="reports-table-column reports-table-column--20">{agent.sms_out}</div>
          <div className="reports-table-column reports-table-column--20">{agent.email_in}</div>
          <div className="reports-table-column reports-table-column--20">{agent.email_out}</div>
          <div className="reports-table-column reports-table-column--20">{agent.google}</div>
          <div className="reports-table-column reports-table-column--20">{agent.angies}</div>
          <div className="reports-table-column reports-table-column--20">{agent.yelp}</div>
          <div className="reports-table-column reports-table-column--20">{agent.returning}</div>
          <div className="reports-table-column reports-table-column--20">{agent.referal}</div>
        </div>
      );
    });

    return (
      <div className="reports-table my-3">
        <div className="reports-table-row">
          <div className="reports-table-column reports-table-column--20 reports-table-column--title">Agent Name</div>
          <div className="reports-table-column reports-table-column--20 reports-table-column--title">Total Lead</div>
          <div className="reports-table-column reports-table-column--20 reports-table-column--title">Follow Up</div>
          <div className="reports-table-column reports-table-column--20 reports-table-column--title">Booked</div>
          <div className="reports-table-column reports-table-column--20 reports-table-column--title">% Booked</div>
          <div className="reports-table-column reports-table-column--20 reports-table-column--title">Lost</div>
          <div className="reports-table-column reports-table-column--20 reports-table-column--title">Canceled</div>
          <div className="reports-table-column reports-table-column--20 reports-table-column--title">Calls In</div>
          <div className="reports-table-column reports-table-column--20 reports-table-column--title">Calls Answered</div>
          <div className="reports-table-column reports-table-column--20 reports-table-column--title">Calls Missed</div>
          <div className="reports-table-column reports-table-column--20 reports-table-column--title">Calls Out</div>
          <div className="reports-table-column reports-table-column--20 reports-table-column--title">Sms In</div>
          <div className="reports-table-column reports-table-column--20 reports-table-column--title">Sms Out</div>
          <div className="reports-table-column reports-table-column--20 reports-table-column--title">Email In</div>
          <div className="reports-table-column reports-table-column--20 reports-table-column--title">Email Out</div>
          <div className="reports-table-column reports-table-column--20 reports-table-column--title">Google</div>
          <div className="reports-table-column reports-table-column--20 reports-table-column--title">Angles list</div>
          <div className="reports-table-column reports-table-column--20 reports-table-column--title">Yelp</div>
          <div className="reports-table-column reports-table-column--20 reports-table-column--title">Returning</div>
          <div className="reports-table-column reports-table-column--20 reports-table-column--title">Referal</div>
        </div>
        {agents}
      </div>
    );
  }
};

export default CommunicationsTotal;

import React, { useCallback } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { ModalButton } from 'components/newComponents/Buttons';
import Modal from 'components/newComponents/Modal';
import { useJobUpdateToStorage } from 'hooks/query/useJob';
import { useModal } from 'context/modal';
import JobUpdateToStorageForm from './JobUpdateToStorageForm';
import { useTabInfo } from 'hooks/query/useJob';

import './style.scss';

const JobUpdateToStorage = () => {
  const { modalNames, close, info } = useModal();

  const isVisible = info(modalNames.jobUpdateToStorage).visible;
  const jobId = info(modalNames.jobUpdateToStorage).params.jobId;
  const tab = info(modalNames.jobUpdateToStorage).params.tab;
  const companyId = info(modalNames.jobUpdateToStorage).params.companyId;
  const requiredPickup = info(modalNames.jobUpdateToStorage).params.requiredPickup;

  const { data: inventory } = useTabInfo(jobId, 'inventory');

  const closeHandler = useCallback(() => close(modalNames.jobUpdateToStorage), [close, modalNames.jobUpdateToStorage]);

  const { mutate: updateJob } = useJobUpdateToStorage(jobId, tab, { onSuccess: closeHandler });

  const submitHandler = (values) => {
    const data = {
      ...values,
      job_id: jobId,
      job_inventory: values.job_inventory.estimated ?? [],
    };

    updateJob(data);
  };

  return (
    <Modal style={{ width: 700 }} header="Update to Storage" show={isVisible} onHide={closeHandler}>
      <Formik
        validateOnMount={true}
        initialValues={{
          storage_id: null,
          unit_sizes: [],
          storage_fees: {},
          storage_rate_type: '',
          storage_rate: '',
          delivery_date: '',
          delivery_time: '',
          calc_type: 'per_cf',
          job_inventory_total:
            inventory?.job_inventory?.estimated.reduce((acc, item) => {
              return acc + parseFloat(item.volume) * item.qty;
            }, 0) || 0,
          job_inventory: inventory?.job_inventory,
          total_price: '',
        }}
        onSubmit={submitHandler}
        validationSchema={Yup.object().shape({
          storage_id: Yup.number().nullable().required('Storage Must Be Specified'),
          unit_sizes: Yup.array().compact().of(Yup.number()).required('Select Unit Size').min(1, 'Select at least 1 unit size'),
          storage_rate_type: Yup.string().required('Pick Rate'),
          delivery_date: Yup.string().required('Pick Date'),
          delivery_time: Yup.string().required('Pick Time'),
        })}
        enableReinitialize={true}
      >
        {({ values, handleChange, setFieldValue, handleSubmit, errors, touched }) => {
          const isValidHandler = (name) => {
            if (errors[name] && touched[name]) return errors[name];
            return null;
          };

          return (
            <form onSubmit={handleSubmit}>
              <div>
                <JobUpdateToStorageForm
                  values={values}
                  companyId={companyId}
                  jobId={jobId}
                  tab={tab}
                  requiredPickup={requiredPickup}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                  isValidHandler={isValidHandler}
                />
              </div>
              <div className="footer-buttons">
                <ModalButton title="Cancel" variant="cancel" onClick={closeHandler} />
                <ModalButton title="Update Job" variant="confirm" type="submit" />
              </div>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default JobUpdateToStorage;

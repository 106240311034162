import React from 'react';
import { useLocation } from 'react-router-dom';
import { matchPath } from 'react-router';
import { routePaths } from 'config/routes';
import { useList } from 'hooks/query/useReminder';
import RemindersCreateForm from './ReminderCreateForm';
import ReminderItem from './ReminderItem';

const RemindersTab = () => {
  const { pathname } = useLocation();

  const {
    params: { id },
  } = matchPath(pathname, { path: routePaths.JOBS });
  const { data } = useList(id);

  return (
    <div className="modal new-table">
      <RemindersCreateForm jobId={id} />
      <div className="new-table--row bcg-light">
        <div className="new-table--row--item w40 no-side-shadows justify-content-center">
          <b>Subject</b>
        </div>
        <div className="new-table--row--item w20 no-side-shadows justify-content-center">
          <b>To</b>
        </div>
        <div className="new-table--row--item w20 no-side-shadows justify-content-center">
          <b>Date</b>
        </div>
        <div className="new-table--row--item w20 no-side-shadows justify-content-center">
          <b>Actions</b>
        </div>
      </div>
      {data?.reminders.map((item) => (
        <ReminderItem reminder={item} key={item.id} jobId={id} />
      ))}
    </div>
  );
};

export default RemindersTab;

import React, { useMemo, useCallback } from 'react';
import { useParams } from 'react-router';
import { useModal } from 'context/modal';
import { useInfo, useTabInfo, useInventorySave, useInventoryConditionSave } from 'hooks/query/useJob';
import InventoryModal from 'pages/modal/Inventory';
import Icon from 'components/Icon';
import * as Conditions from './Conditions';
import * as StorageInventoryData from './StorageInventoryData';
import { prepareDataToSubmit } from 'pages/modal/Inventory/utils';
import { useDefault } from 'hooks/query/useCommon';
import { getSymbolsOptions } from './utils';

const InventoryTab = () => {
  const { open, info, modalNames } = useModal();

  const { id, tab } = useParams();

  const { mutate: saveInventory } = useInventorySave(id, tab);

  const { mutate: saveInventoryCondition } = useInventoryConditionSave(id, tab);

  const isInventory = info(modalNames.inventory).visible;

  const { data } = useDefault();

  const symbols = getSymbolsOptions(data?.statuses);

  const {
    data: { calc_info: calcInfo, job_info: jobInfo },
  } = useInfo(id);

  const {
    data: { job_inventory: jobInventory },
  } = useTabInfo(id, tab);

  const isAllJob = jobInfo?.storage_id === 0;
  const isStorageTypeJob = jobInfo?.type === 3;
  const isDeliveryTypeJob = jobInfo?.type === 5;

  const estimated = useMemo(() => {
    return jobInventory.estimated.reduce(
      (acc, item) => {
        return {
          qty: acc.qty + parseInt(item.qty),
          cf: acc.cf + parseFloat(item.volume) * item.qty,
          lbs: acc.lbs + parseFloat(item.volume) * 7 * item.qty,
          blankets: acc.blankets + parseFloat(item.blankets),
        };
      },
      { qty: 0, cf: 0, lbs: 0, blankets: 0 }
    );
  }, [jobInventory.estimated]);

  const actual = useMemo(() => {
    return jobInventory.final.reduce(
      (acc, item) => {
        return {
          qty: acc.qty + parseInt(item.qty),
          cf: acc.cf + parseFloat(item.volume) * item.qty,
          lbs: acc.lbs + parseFloat(item.volume) * 7 * item.qty,
          blankets: acc.blankets + parseFloat(item.blankets),
        };
      },
      { qty: 0, cf: 0, lbs: 0, blankets: 0 }
    );
  }, [jobInventory.final]);

  const deleteInventoryItems = useCallback(
    (type, itemId) => {
      const prepared = jobInventory[type].filter((e) => e.id !== itemId);

      saveInventory({
        job_id: id,
        job_inventory: { estimated: type === 'estimated' ? prepared : jobInventory[type], final: prepared },
      });
    },
    [id, jobInventory, saveInventory]
  );

  const openInventoryEstimatedModal = () => {
    open(modalNames.inventory, {
      type: 'estimated',
      jobID: id,
      companyID: jobInfo?.company_id,
      jobInventory: jobInventory.estimated,
      onSubmit: (data, options) => {
        const prepared = prepareDataToSubmit(data);

        saveInventory({ job_id: id, job_inventory: { estimated: prepared, final: prepared } }, options);
      },
    });
  };

  const openInventoryFinaldModal = () => {
    open(modalNames.inventory, {
      type: 'final',
      jobID: id,
      companyID: jobInfo?.company_id,
      jobInventory: jobInventory.final,
      onSubmit: (data, options) => {
        const prepared = prepareDataToSubmit(data);
        saveInventory({ job_id: id, job_inventory: { estimated: jobInventory['final'], final: prepared } }, options);
      },
    });
  };

  const handleSaveInventoryCondition = (values, type, inventoryId) => {
    if (type === 'final') {
      const prepared = {
        ...values,
        condition_dest: values.condition.join('/'),
      };
      delete prepared.condition;

      saveInventoryCondition(prepared);
    }

    if (type === 'estimated') {
      const preparedEstimated = {
        ...values,
        condition: values.condition.join('/'),
      };

      delete preparedEstimated.condition_dest;

      const finalItem = jobInventory.final.find((item) => item.inventory_id === inventoryId);

      const preparedFinal = {
        id: finalItem.id,
        condition_dest: values.condition.join('/'),
        chk_in_storage: finalItem.chk_in_storage,
        chk_out_storage: finalItem.chk_out_storage,
        note: finalItem.note,
        blankets: finalItem.blankets,
      };
      delete preparedFinal.condition;

      saveInventoryCondition(preparedEstimated);
      saveInventoryCondition(preparedFinal);
    }
  };

  return (
    <div className="inventory-tab">
      {isInventory && <InventoryModal />}

      <div className="d-flex">
        <h3 className="header table-header-shadow" style={{ width: '100%' }}>
          Inventory
        </h3>

        {isAllJob && (
          <div className="d-flex pr-3 pl-3 table-header-shadow">
            <button
              type="button"
              disabled={isDeliveryTypeJob}
              onClick={() => open(modalNames.localJobInventoryToOngoingStorageJob, { jobId: jobInfo.id })}
            >
              <Icon
                icon="inventory_to_ongoing_storage_job"
                size={24}
                className="tooltip-right"
                title="Add local job Inventory to ongoing storage job"
              />
            </button>
          </div>
        )}
      </div>
      <table>
        <tbody>
          <tr>
            <th>
              <button disabled={isDeliveryTypeJob} type="button" onClick={openInventoryEstimatedModal}>
                <Icon icon="add" size={16} title="Add estimated inventory item" /> Inventory Est.
              </button>
            </th>
            <th>CF</th>
            <th>Qty</th>
            <th>Lbs.</th>

            <Conditions.Header />

            {isStorageTypeJob && <StorageInventoryData.Header />}
          </tr>
          {jobInventory.estimated.map((item) => (
            <tr key={item.id ?? item.inventory_id}>
              <td>
                <button type="button" disabled={isDeliveryTypeJob} onClick={() => deleteInventoryItems('estimated', item.id)}>
                  <Icon icon="delete" title="Remove item" size={16} />
                </button>
                {item.name}
              </td>
              <td>{item.volume}</td>
              <td> {item.qty} </td>
              <td>{calcInfo.rate_per_cf * (parseFloat(item.volume) * parseInt(item.qty))}</td>

              <Conditions.Content
                id={item.id}
                symbols={symbols}
                condition={item.condition}
                onSave={(values) => handleSaveInventoryCondition(values, 'estimated', item.inventory_id)}
              />

              {isStorageTypeJob && (
                <StorageInventoryData.Content
                  id={item.id}
                  chk_in_storage={item.chk_in_storage}
                  chk_out_storage={item.chk_out_storage}
                  note={item.note}
                  blankets={item.blankets}
                  onSave={(values) => saveInventoryCondition(values)}
                />
              )}
            </tr>
          ))}
          <tr>
            <td />
            <td>{estimated.cf}</td>
            <td>{estimated.qty}</td>
            <td>{estimated.lbs}</td>

            <Conditions.Footer blanketsTotal={estimated.blankets} />

            {isStorageTypeJob && <StorageInventoryData.Footer blanketsTotal={estimated.blankets} />}
          </tr>
        </tbody>
      </table>
      <table>
        <tbody>
          <tr>
            <th>
              <button type="button" disabled={isDeliveryTypeJob} onClick={openInventoryFinaldModal}>
                <Icon icon="add" title="Add final inventory item" size={16} />
                Inventory Act.
              </button>
            </th>
            <th>CF</th>
            <th>Qty</th>
            <th>Lbs.</th>

            <Conditions.Header />

            {isStorageTypeJob && <StorageInventoryData.Header />}
          </tr>

          {jobInventory.final.map((item) => (
            <tr key={item.id ?? item.inventory_id}>
              <td>
                <button onClick={() => deleteInventoryItems('final', item.id)} disabled={isDeliveryTypeJob}>
                  <Icon icon="delete" title="Remove item" size={16} />
                </button>
                {item.name}
              </td>
              <td>{item.volume}</td>
              <td>{item.qty}</td>
              <td>{calcInfo.rate_per_cf * (parseFloat(item.volume) * parseInt(item.qty))}</td>

              <Conditions.Content
                id={item.id}
                symbols={symbols}
                condition={item.condition_dest}
                onSave={(values) => handleSaveInventoryCondition(values, 'final', item.inventory_id)}
              />

              {isStorageTypeJob && (
                <StorageInventoryData.Content
                  id={item.id}
                  chk_in_storage={item.chk_in_storage}
                  chk_out_storage={item.chk_out_storage}
                  note={item.note}
                  blankets={item.blankets}
                  onSave={(values) => saveInventoryCondition(values)}
                />
              )}
            </tr>
          ))}
          <tr>
            <td />
            <td>{actual.cf}</td>
            <td>{actual.qty}</td>
            <td>{actual.lbs}</td>

            <Conditions.Footer blanketsTotal={actual.blankets} />

            {isStorageTypeJob && <StorageInventoryData.Footer blanketsTotal={actual.blankets} />}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default InventoryTab;

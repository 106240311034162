import React, { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { matchPath } from 'react-router';
import { routePaths } from 'config/routes';
import { useFormik } from 'formik';
import { useInfo } from 'hooks/query/useJob';
import { useDefault } from 'hooks/query/useCommon';
import { useModal } from 'context/modal';
import { ModalButton } from 'components/newComponents/Buttons';
import { usePackingSave } from 'hooks/query/useJob';
import Modal from 'components/newComponents/Modal';
import PackingSave from './PackingSave';
import PackingList from './PackingList';

const Packing = () => {
  const { modalNames, close, info } = useModal();
  const { pathname } = useLocation();

  const {
    params: { id, tab },
  } = matchPath(pathname, { path: routePaths.JOBS });

  const { mutate: savePacking } = usePackingSave(id, tab);

  const {
    data: { packing: packings },
  } = useDefault();

  const {
    data: { job_info: jobInfo, job_packing: jobPacking },
  } = useInfo(id);

  const isVisible = info(modalNames.packing).visible;
  const params = info(modalNames.packing).params;

  const { values, setFieldValue, handleChange } = useFormik({
    initialValues: { items: jobPacking[params.type], packing: packings[jobInfo.company_id], search: '' },
  });

  const addItem = useCallback(
    (data) => {
      const preparedData = { ...data, job_id: jobInfo.id };
      const index = values.items.findIndex((item) => item.pack_id === preparedData.pack_id);
      if (index > -1) {
        const newArr = [...values.items];
        newArr[index].qty += preparedData.qty;
        setFieldValue('items', newArr);
      } else {
        setFieldValue('items', [...values.items, preparedData]);
      }
    },
    [jobInfo.id, setFieldValue, values.items]
  );

  const saveForm = useCallback(() => {
    savePacking(
      {
        job_packing: {
          estimated: params.type === 'estimated' ? values.items : jobPacking['estimated'],
          final: values.items,
        },
        job_id: jobInfo.id,
      },
      { onSuccess: () => close(modalNames.packing) }
    );
  }, [close, jobInfo.id, jobPacking, modalNames.packing, params.type, savePacking, values.items]);

  const handleSearch = useCallback(
    (event) => {
      handleChange(event);
      let filteredArray = packings[jobInfo.company_id];
      if (event.target.value.length >= 1) {
        filteredArray = (packings[jobInfo.company_id] ?? []).filter((item) => item.name.toLowerCase().includes(event.target.value.toLowerCase()));
      }
      setFieldValue('packing', filteredArray);
    },
    [handleChange, jobInfo.company_id, setFieldValue, packings]
  );

  const removeItem = useCallback(
    (id) => {
      const items = values.items.filter((item) => item.pack_id !== id);
      setFieldValue('items', items);
    },
    [setFieldValue, values.items]
  );

  const changeMoveType = useCallback(
    (id, value) => {
      const items = values.items.map((item) => (item.pack_id === id ? { ...item, move_type: value } : item));
      setFieldValue('items', items);
    },
    [setFieldValue, values.items]
  );

  const changeQty = useCallback(
    (id, qty) => {
      const items = values.items.map((item) => (item.pack_id === id ? { ...item, qty } : item));
      setFieldValue('items', items);
    },
    [setFieldValue, values.items]
  );

  return (
    <Modal style={{ width: 800 }} header="Packing" show={isVisible} onHide={() => close(modalNames.packing)}>
      <div className="new-table modal">
        <div className="new-table--row">
          <div className="new-table--row--item p10 align-items-center">
            <input type="text" className="flex-grow-1" placeholder="Search..." value={values.search} name="search" onChange={handleSearch} />
          </div>
        </div>
        <PackingList addItem={addItem} packing={values.packing} type={params.type} />
        <PackingSave changeQty={changeQty} changeMoveType={changeMoveType} items={values.items} type={params.type} removeItem={removeItem} />
      </div>
      <div className="footer-buttons">
        <ModalButton title="Cancel" variant="cancel" onClick={() => close(modalNames.packing)} />
        <ModalButton title="Send" variant="confirm" onClick={saveForm} />
      </div>
    </Modal>
  );
};

export default Packing;

import React, { Fragment, useMemo } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import Preloader from 'components/Preloader';
import Filter from './Filter';
import CommunicationsCharts from './CommunicationsCharts';
import CommunicationsTotal from './CommunicationsTotal';
import CommunicationsJobList from './CommunicationsJobList';
import { useReportFilterInfo, useGetUserNames, useGetAgentComunicationsReport } from 'hooks/query/useReport';
import { changePeriod } from '../reportUtils';
import { jobStatusType } from '../reportConstants';

const Communication = () => {
  const { data } = useReportFilterInfo();
  const { mutate: getReport, data: agentCommunicationsReportResponse, isLoading } = useGetAgentComunicationsReport();
  const report = agentCommunicationsReportResponse?.data;

  const companiesList = useMemo(() => {
    if (!data) return [];

    const list = data.companies.map((company) => ({
      value: [company.id],
      label: company.company_name,
    }));
    list.unshift({ value: data.all_companies, label: 'All Companies' });

    return list;
  }, [data]);

  const { values, setFieldValue, handleSubmit, errors, touched } = useFormik({
    initialValues: {
      company_id: companiesList[0]?.value ?? 0,
      user_id: [],
      user_type: 1,
      status_type: jobStatusType[0].value,
      from: null,
      to: null,
      period_type: 0,
    },
    onSubmit: (values) => getReport(values),
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      user_id: Yup.array().min(1, 'Select agent').required('Select agent'),
    }),
  });

  const isValidHandler = (name) => {
    if (errors[name] && touched[name]) return errors[name];
    return null;
  };

  const handleSelect = (name, value) => setFieldValue(name, value);

  const selectDate = ({ name, date }) => setFieldValue(name, moment(date).format('MM/DD/YY'));

  const selectPeriod = ({ value }) => {
    const { from, to } = changePeriod(value);
    setFieldValue('from', from);
    setFieldValue('to', to);
    setFieldValue('period_type', value);
  };

  const selectAllUsers = (list) => {
    if (values['user_id'].length === list.length) {
      setFieldValue('user_id', []);
      return;
    }
    setFieldValue(
      'user_id',
      list.map((user) => user.id)
    );
  };

  const selectUser = (id) => {
    if (values['user_id'].includes(id)) {
      setFieldValue(
        'user_id',
        values['user_id'].filter((i) => i !== id)
      );
      return;
    }

    setFieldValue('user_id', [...values['user_id'], id]);
  };

  const { data: userNamesResponse } = useGetUserNames(
    { company_id: values['company_id'], user_type: values['user_type'] },
    {
      enabled: Boolean(values['company_id'].length),
      onSuccess: ({ user_names }) => selectAllUsers(user_names),
    }
  );

  const userNames = useMemo(() => {
    if (!userNamesResponse) return [];

    return userNamesResponse.user_names.map((user) => ({ ...user, value: user.id, label: `${user.first_name} ${user.last_name}` }));
  }, [userNamesResponse]);

  if (!data) return null;

  const formActions = { isValidHandler, handleSelect, selectPeriod, selectDate, selectAllUsers, selectUser, handleSubmit };

  return (
    <Fragment>
      {isLoading && <Preloader />}

      <Filter data={{ values, companiesList, userNames }} actions={formActions} />

      {report && (
        <Fragment>
          <CommunicationsCharts bookedJobs={report.info.reduce((acc, item) => [...acc, ...item.jobs_agent], [])} data={report.data} />
          <CommunicationsTotal info={report.info} />

          <div className="container-fluid">
            <div className="row">
              <div className="col-12 no-padding">
                <CommunicationsJobList info={report.info} />
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default Communication;

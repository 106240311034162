import React, { useMemo, useState, useCallback } from 'react';
import { useHistory, generatePath } from 'react-router-dom';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { Chart } from 'react-google-charts';
import { extendMoment } from 'moment-range';
import Moment from 'moment';
import { prepareDataToSubmit } from 'pages/modal/Inventory/utils';
import { add, formatFullDBDate, formatFullDBDateTime, subtract } from 'helpers/dateHelper';
import { useModal } from 'context/modal';
import { routePaths } from 'config/routes';
import { useScheduleList, useUnavailableList, useAssignCancel, useUnitAvailabilitySave } from 'hooks/query/useCrew';
import { useInventorySave, useTabInfoAsync } from 'hooks/query/useJob';
import { useDefault } from 'hooks/query/useCommon';
import Icon from 'components/Icon';
import CrewTooltipInfo from './CrewTooltipInfo';
import chartsConfig from 'config/googleCharts';
import getTitle from 'helpers/GetTitle';
import getAbbr from 'helpers/Abbr';
import './styles.scss';

const moment = extendMoment(Moment);
const MySwal = withReactContent(Swal);

const CrewSchedule = () => {
  const history = useHistory();
  const { open, modalNames } = useModal();
  const [date, setDate] = useState(formatFullDBDateTime());
  const [showAll, setShowAll] = useState(false);

  const { mutateAsync: getTabInfo } = useTabInfoAsync();
  const { mutate: saveInventory } = useInventorySave();

  const requestParams = useMemo(() => {
    if (!date) return {};
    return {
      week_start: date,
      week_end: formatFullDBDateTime(add(date, 7, 'days')),
    };
  }, [date]);

  const { data: scheduleList, refetch } = useScheduleList({
    ...requestParams,
    show_all: showAll,
  });

  const { mutate: saveUnitAvailability } = useUnitAvailabilitySave();

  const { data: unavailableList } = useUnavailableList(requestParams);

  const { mutate: cancelCrew } = useAssignCancel(null, null, requestParams);

  const { data: defaultItems } = useDefault();

  const openJobHandler = useCallback(
    (job) => {
      history.push(generatePath(routePaths.JOBS, { id: job.job_id, tab: 'general', type: 'all' }));
      Swal.close();
    },
    [history]
  );

  const assignCrewHandler = useCallback(
    (item) => {
      Swal.close();
      open(modalNames.assignCrew, {
        edit: false,
        crew_id: null,
        job_id: item.id,
        show_all: showAll,
        qty_man: item.qty_man,
        title: item.job_title,
        company_id: item.company_id,
        move_size_id: item.move_size_id,
        date: formatFullDBDate(date),
        start_date: formatFullDBDateTime(date),
        stop_date: formatFullDBDateTime(add(date, 7, 'days')),
        onSuccess: refetch,
      });
    },
    [date, modalNames.assignCrew, open, refetch, showAll]
  );

  const openInventoryHandler = useCallback(
    async (data) => {
      const jobId = data.job_id;

      const { data: tabInfoData } = await getTabInfo({ id: jobId, tab: 'inventory' });

      open(modalNames.inventory, {
        type: 'final',
        jobID: jobId,
        companyID: data.company_id,
        jobInventory: tabInfoData.job_inventory.final,
        onSubmit: (data, options) => {
          const prepared = prepareDataToSubmit(data);

          saveInventory(
            {
              job_id: jobId,
              job_inventory: { estimated: tabInfoData.job_inventory.estimated, final: prepared },
            },
            options
          );
        },
      });
    },
    [getTabInfo, modalNames.inventory, saveInventory, open]
  );

  const openNotesHandler = useCallback(
    async (data) => {
      const jobId = data.job_id;

      open(modalNames.notes, {
        tab: 'general',
        type: 'agent_notes',
        jobID: jobId,
      });
    },
    [modalNames.notes, open]
  );

  const deleteAssignmentHandler = useCallback(
    (job) => {
      const data = {
        job_id: job.job_id,
        spot: null,
      };
      cancelCrew(data, { onSuccess: () => Swal.close() });
    },
    [cancelCrew]
  );

  const editAssignedCrewHandler = useCallback(
    (item) => {
      Swal.close();
      open(modalNames.assignCrew, {
        edit: true,
        crew_id: item.crew_id,
        job_id: item.job_id,
        show_all: showAll,
        qty_man: item.qty_man,
        title: item.job_title,
        company_id: item.company_id,
        move_size_id: item.move_size_id,
        date: formatFullDBDate(date),
        start_date: formatFullDBDateTime(item.date_start),
        stop_date: formatFullDBDateTime(item.date_stop),
      });
      Swal.close();
    },
    [date, modalNames.assignCrew, open, showAll]
  );

  const scheduleActions = useCallback(
    (data) => {
      if (data) {
        if (document.getElementsByClassName('google-visualization-tooltip').length) {
          document.getElementsByClassName('google-visualization-tooltip')[0].style.display = 'none';
        }

        MySwal.fire({
          title: 'Actions with job',
          customClass: {
            popup: 'swal-popup-width-600',
          },
          html: (
            <div>
              {data.crew_id ? (
                <div className="popup-crew">
                  <button id="open_job" onClick={() => openJobHandler(data)}>
                    Open job
                  </button>
                  <button id="open_inventory" onClick={() => openInventoryHandler(data)}>
                    Open Inventory
                  </button>
                  <button id="edit_assignment" onClick={() => editAssignedCrewHandler(data)}>
                    Edit
                  </button>
                  <button id="edit_notes" onClick={() => openNotesHandler(data)}>
                    View Notes
                  </button>
                  <button id="delete_assignment" onClick={() => deleteAssignmentHandler(data)}>
                    Delete
                  </button>
                </div>
              ) : (
                <div className="popup-crew">
                  <button id="assign_crew" onClick={() => assignCrewHandler(data)}>
                    Assign crew
                  </button>
                </div>
              )}
            </div>
          ),
          focusConfirm: false,
          allowOutsideClick: true,
          showCancelButton: false,
          showConfirmButton: false,
        });
      }
    },
    [assignCrewHandler, deleteAssignmentHandler, editAssignedCrewHandler, openNotesHandler, openInventoryHandler, openJobHandler]
  );

  const disableCrewByDateHandler = useCallback(
    (crew, date) => {
      return Swal.fire({
        text: `Disable crew ${crew.name} for this date?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Disable',
      }).then((result) => {
        if (result.isConfirmed) {
          saveUnitAvailability({ id: crew.id, date }, { onSuccess: () => Swal.close() });
        }
      });
    },
    [saveUnitAvailability]
  );

  const days = useMemo(() => {
    if (!scheduleList || !defaultItems || !unavailableList) return null;
    const start = date;
    const end = formatFullDBDate(add(date, 7, 'days'));
    const range = moment.range(start, end);

    let days = [];

    for (let day of range.by('day')) {
      const day_items = scheduleList.filter((crew_schedule) => formatFullDBDate(crew_schedule.date_start) === formatFullDBDate(day));

      const crews_ids = day_items.map((item) => item.crew_id);
      const unique_crews = [...new Set(crews_ids)];
      let count_unassigned = 0;

      let unassigned_crews = [];
      defaultItems.crews.forEach((crew) => {
        if (!unique_crews.includes(crew.id)) {
          const unavailable_crews = unavailableList.reduce((acc, el) => {
            if (formatFullDBDate(el.date) === formatFullDBDate(day) && crew.id === el.crew_id) {
              return acc.concat(crew.id);
            }
            return acc;
          }, []);

          if (!unavailable_crews.includes(crew.id)) {
            unassigned_crews.push(crew);
          }
        }
      });

      const data = [];
      day_items.forEach((day_item) => {
        let tooltip_html = CrewTooltipInfo(
          day_item,
          defaultItems.move_sizes[day_item.company_id].find((moveSize) => moveSize.id === day_item.move_size_id),
          getAbbr(day_item.company_id, defaultItems.companies_abbr)
        );
        if (day_item.entity_type !== 'schedule') count_unassigned++;
        let start_time = moment(day_item.date_start);
        let stop_time = moment(day_item.date_stop);
        let duration = moment.duration(stop_time.diff(start_time));
        let bar_data = `
        ${getTitle(day_item.job_status)} | 
          ${day_item.job_title} | 
          ${day_item.qty_man}men | 
          ${day_item.from_zipcode}-${day_item.to_zipcode} | 
          ${moment(day_item.date_start).format('hh:mma')}-${moment(day_item.date_stop).format('hh:mma')}
          `;

        if (duration.asHours() < 3) {
          bar_data = `${day_item.job_title} | ${moment(day_item.date_start).format('hh:mma')}-${moment(day_item.date_stop).format('hh:mma')}`;
        }
        let bar_color = '#bdd9f4';

        if (day_item.entity_type === 'schedule') {
          bar_color = '#3491ed';
        }
        if (day_item.entity_type === 'confirmed') {
          bar_color = '#7ed321';
        }
        data.push([
          `${day_item.entity_type === 'schedule' || day_item.entity_type === 'confirmed' ? day_item.crew_name : 'unassigned'}`,
          `${bar_data}`,
          `${bar_color}`,
          tooltip_html,
          new Date(day_item.date_start),
          new Date(day_item.date_stop),
        ]);
      });
      let height = unique_crews.length >= 1 ? unique_crews.length * 42 + count_unassigned * 30 + 50 : count_unassigned * 42 + 50;
      if ((unassigned_crews.length + 1) * 35 > height) height = (unassigned_crews.length + 1) * 35;

      height += 'px';
      days.push(
        <div key={`${day.format('dddd')}_${day.format('MM-DD-YYYY')}`} className="row day-wrap">
          <div className="col-2">
            <div className="crew-info">
              <h3 className="header">
                {day.format('dddd')}, {day.format('MM-DD-YYYY')}
              </h3>
              <p>
                Available crews: {unassigned_crews.length}/{defaultItems.crews.length}
              </p>
              {unassigned_crews.length > 0 && (
                <div className="crew-info--list">
                  {unassigned_crews.map((crew) => (
                    <button key={crew.id} className="crew-info--list--item" onClick={() => disableCrewByDateHandler(crew, formatFullDBDate(day))}>
                      {crew.name}
                    </button>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className="col-10">
            {day_items.length > 0 && (
              <Chart
                width={'100%'}
                height={height}
                chartType="Timeline"
                loader={<div>Loading data...</div>}
                data={[chartsConfig, ...data]}
                options={{
                  timeline: { showRowLabels: true },
                  hAxis: { format: 'hh:mm a' },
                  avoidOverlappingGridLines: true,
                  allowHtml: true,
                }}
                chartEvents={[
                  {
                    eventName: 'select',
                    callback: ({ chartWrapper }) => {
                      const chart = chartWrapper.getChart();
                      const selection = chart.getSelection();
                      if (selection.length === 1) {
                        scheduleActions(day_items[selection[0].row]);
                        setTimeout(() => {
                          chart.setSelection([]);
                        }, 500);
                      }
                    },
                  },
                ]}
              />
            )}
          </div>
        </div>
      );
    }
    return days;
  }, [scheduleList, defaultItems, unavailableList, date, disableCrewByDateHandler, scheduleActions]);

  const nextWeek = () => setDate((pre) => formatFullDBDate(add(pre, 7, 'days')));

  const prevWeek = () => setDate((pre) => formatFullDBDate(subtract(pre, 7, 'days')));

  return (
    <div className="page-content">
      <div className="job-calendar">
        <div className="job-calendar--head">
          <div className="color-map">
            <Icon icon="color_map_light" title={'Unassigned'} size={16} />
            <Icon icon="color_map_blue" title={'Assigned'} size={16} />
            <Icon icon="color_map_green" title={'Confirmed'} size={16} />
          </div>

          <div className="pagination-calendar">
            <button onClick={prevWeek} className="pagination-calendar--item">
              <Icon icon="postsale" rotateDeg={180} />
            </button>
            <span className="pagination-calendar--item pagination-calendar--item-dates">
              {date} - {formatFullDBDate(add(date, 7, 'days'))}
            </span>
            <button onClick={nextWeek} className="pagination-calendar--item">
              <Icon icon="postsale" />
            </button>
          </div>

          <button className="details" onClick={() => setShowAll((pre) => !pre)}>
            {showAll ? 'View Confirmed' : 'View All'}
          </button>
        </div>
      </div>
      <div className="job-calendar--body container-fluid">{days}</div>
    </div>
  );
};

export default CrewSchedule;

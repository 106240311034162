import React, { useMemo, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { matchPath } from 'react-router';
import { routePaths } from 'config/routes';
import { alert } from 'components/Alerts';
import { useDefault, useFilesSave } from 'hooks/query/useCommon';
import { useSendSample, useResendReply } from 'hooks/query/useInsurance';
import { useInfo, useTabInfo, useCoiComplete } from 'hooks/query/useJob';
import CheckBox from 'components/newComponents/CheckBox';
import Input from 'components/newComponents/Input';
import Select from 'components/newComponents/Select';
import Icon from 'components/Icon';

const InsuranceForm = (props) => {
  const { buildingOptions, values, sendRequest, handleChange, setFieldValue, setValues, transformBuilding } = props;
  const { pathname } = useLocation();

  const {
    params: { id, tab },
  } = matchPath(pathname, { path: routePaths.JOBS });

  const { mutate: sendSample } = useSendSample();
  const { mutate: coiComplete } = useCoiComplete();
  const { mutate: saveFiles } = useFilesSave();
  const { mutate: resendReply } = useResendReply();

  const {
    data: { job_info: jobInfo },
  } = useInfo(id);

  const {
    data: { job_insurance_info: jobInsuranceInfo },
  } = useTabInfo(id, tab);

  const {
    data: {
      insurance_providers: insuranceProviders,
      building_info: buildingInfo,
      statuses: { building_type: buildingType },
    },
  } = useDefault();

  const onSelectBuildingHandler = useCallback(
    (option) => {
      if (!option) {
        setFieldValue('property_name', '');
        return;
      }

      const current = buildingInfo.find((e) => e.building_name === option.value);

      setValues({ ...values, property_name: option.value, ...transformBuilding(current) });
    },
    [buildingInfo, setFieldValue, setValues, transformBuilding, values]
  );

  const sendSampleHandler = useCallback(() => {
    if (!jobInsuranceInfo[values.type]) {
      alert({
        title: 'Sample Mail can`t be sent.',
        text: 'First save Insurance info.',
        type: 'error',
      });
      return;
    }
    const preparedData = {
      job_id: jobInfo.id,
      move_type: values.type,
    };

    sendSample(preparedData);
  }, [values.type, jobInfo.id, jobInsuranceInfo, sendSample]);

  const getCoiStatus = useCallback((mail, approved) => {
    let status = 'request not sent';

    if (mail && mail.template === 'insurance_request') {
      status = 'request sent in ' + mail.created_at;
    }
    if (mail && mail.template === 'insurance_approving') {
      status = 'request sent to property manager in ' + mail.created_at;
    }
    if (approved === 1) {
      status = 'request approved';
    }
    if (approved === 2) {
      status = 'request rejected';
    }
    return status;
  }, []);

  const coiCompleteHandler = useCallback(
    (type) => {
      coiComplete({
        job_id: jobInfo.id,
        type: values.type,
        provider_type: type,
      });
    },
    [coiComplete, values.type, jobInfo.id]
  );

  const resendApprovingMail = useCallback(() => {
    resendReply({ job_id: jobInfo.id, type: values.type });
  }, [values.type, jobInfo.id, resendReply]);

  const disableWcHandler = useCallback(
    (e) => {
      setFieldValue('send_type', e.target.checked ? 2 : 1);
      setFieldValue('disabled_wc', e.target.checked ? 0 : 1);
    },
    [setFieldValue]
  );

  const saveAttachmentsHandler = useCallback(
    (event) => {
      saveFiles(event.target.files, {
        onSuccess: ({ data }) => setFieldValue('attachments', [...values.attachments, ...data.attachments]),
      });
    },
    [saveFiles, setFieldValue, values.attachments]
  );

  const saveCertificateHandler = useCallback(
    (event) => {
      saveFiles({ file: event.target.files[0] }, { onSuccess: ({ data }) => setFieldValue(event.target.name, data.attachments) });
    },
    [saveFiles, setFieldValue]
  );

  const insuranceProviderLiability = useMemo(() => {
    const companyId = jobInfo.company_id;

    const liability = insuranceProviders[companyId]['liability'];

    return liability.map((e) => ({ value: e.id, label: e.name }));
  }, [insuranceProviders, jobInfo.company_id]);

  const insuranceProviderWorkers = useMemo(() => {
    const companyId = jobInfo.company_id;

    const workers = insuranceProviders[companyId]['workers'];

    return workers.map((e) => ({ value: e.id, label: e.name }));
  }, [insuranceProviders, jobInfo.company_id]);

  const buildingTypes = useMemo(() => {
    return buildingType.map((el, index) => ({ value: index, label: el }));
  }, [buildingType]);

  const liability_complete_disabled = values.complete_liability === 1 || values.approved_liability === 0;
  const wc_complete_disabled = values.disabled_wc ? true : values.complete_wc === 1 || values.approved_wc === 0;
  // const liability_complete_disabled = values.complete_liability === 1 && values.approved_liability === 1 || values.approved_liability === 0;
  // const wc_complete_disabled = values.disabled_wc ? true : values.complete_wc === 1 && values.approved_wc === 1;

  return (
    <div className="insurance-provider-modal">
      <h4 className="subHeader p20" style={{ backgroundColor: '#ccc', textAlign: 'center' }}>
        Please fill in your Insurance Info
      </h4>

      <div className="new-table modal">
        <div className="new-table--row">
          <div className="new-table--row--item">
            <CheckBox
              className="justify-content-between"
              name="required_coi"
              label="Required COI"
              checked={!!values['required_coi']}
              onChange={(e) => setFieldValue('required_coi', e.target.checked ? 1 : 0)}
            />
          </div>
        </div>
        <div className="new-table--row">
          <div className="new-table--row--item">
            <span>Property manager name </span>
          </div>
          <div className="new-table--row--item">
            <Input name="agent_name" value={values['agent_name']} onChange={handleChange} />
          </div>
        </div>
        <div className="new-table--row">
          <div className="new-table--row--item">
            <span>Property manager email</span>
          </div>
          <div className="new-table--row--item">
            <Input name="property_manager_email" value={values['property_manager_email']} onChange={handleChange} />
          </div>
        </div>
        <div className="new-table--row">
          <div className="new-table--row--item">
            <span>Property name</span>
          </div>
          <div className="new-table--row--item">
            <Select
              isClearable
              isCreatable
              options={buildingOptions}
              value={values['property_name']}
              onChange={onSelectBuildingHandler}
              onInputChange={(v) => {
                if (v) setFieldValue('property_name', v);
              }}
            />
          </div>
        </div>
        <div className="new-table--row">
          <div className="new-table--row--item">
            <span>Property address</span>
          </div>
          <div className="new-table--row--item">
            <b> {values['property_address']} </b>
          </div>
        </div>
        <div className="new-table--row">
          <div className="new-table--row--item">
            <span>Fax</span>
          </div>
          <div className="new-table--row--item">
            <Input name="fax" value={values['fax']} onChange={handleChange} mask="111-111-1111" />
          </div>
        </div>
        <div className="new-table--row">
          <div className="new-table--row--item">
            <span>Phone</span>
          </div>
          <div className="new-table--row--item">
            <Input name="phone" value={values['phone']} onChange={handleChange} mask="111-111-1111" />
          </div>
        </div>
        <div className="new-table--row">
          <div className="new-table--row--item">
            <span>Resident name</span>
          </div>
          <div className="new-table--row--item">
            <Input name="resident_name" value={values['resident_name']} onChange={handleChange} />
          </div>
        </div>
        <div className="new-table--row">
          <div className="new-table--row--item">
            <span>Resident address</span>
          </div>
          <div className="new-table--row--item">
            <Input name="resident_unit_number" value={values['resident_unit_number']} onChange={handleChange} />
          </div>
        </div>
        <div className="new-table--row">
          <div className="new-table--row--item">
            <span>Certificate Holder</span>
          </div>
          <div className="new-table--row--item">
            <Input name="certificate_holder" value={values['certificate_holder']} onChange={handleChange} />
          </div>
        </div>
        <div className="new-table--row">
          <div className="new-table--row--item">
            <span>Additional Insured</span>
          </div>
          <div className="new-table--row--item">
            <Input name="additional_insured" value={values['additional_insured']} onChange={handleChange} />
          </div>
        </div>
        <div className="new-table--row">
          <div className="new-table--row--item">
            <span>Insurance Liability Provider</span>
          </div>
          <div className="new-table--row--item">
            <Select
              placeholder="Insurance Provider"
              onChange={(option) => setFieldValue('insurance_provider', option.value)}
              value={Number(values['insurance_provider'])}
              options={insuranceProviderLiability}
            />
          </div>
        </div>
        <div className="new-table--row">
          <div className="new-table--row--item">
            <CheckBox
              className="justify-content-between"
              name="disabled_wc"
              label="Insurance Workers Comp Provider COI"
              checked={!values['disabled_wc']}
              onChange={disableWcHandler}
            />
          </div>
          <div className="new-table--row--item">
            <Select
              placeholder="Insurance Provider WC"
              onChange={(option) => setFieldValue('insurance_provider_worker', option.value)}
              value={Number(values['insurance_provider_worker'])}
              options={insuranceProviderWorkers}
            />
          </div>
        </div>
        <div className="new-table--row">
          <div className="new-table--row--item">
            {values.liability_certificate.length ? (
              <a
                style={{ width: '100%', textAlign: 'center', padding: 20, fontWeight: 700 }}
                target="_blank"
                title="Open"
                rel="noopener noreferrer"
                href={`${process.env.REACT_APP_API_URL}/api/downloadAttachments?path=${values.liability_certificate[0].path}`}
              >
                <Icon icon="download" title="Download Liability certificate" />
                {values.liability_certificate[0].name}
              </a>
            ) : (
              <div className="d-flex flex-column align-items-center">
                <p className="disabled">Liability certificate missing</p>
                <label className="file">
                  Add
                  <input
                    style={{ width: '100%', textAlign: 'center', padding: 20, fontWeight: 700 }}
                    type="file"
                    name="liability_certificate"
                    accept="image/jpeg,image/gif,image/png,image/bmp,application/pdf,image/x-eps"
                    onChange={saveCertificateHandler}
                  />
                </label>
              </div>
            )}
          </div>
          <div className="new-table--row--item">
            {values.wc_certificate.length ? (
              <a
                style={{ width: '100%', textAlign: 'center', padding: 20, fontWeight: 700 }}
                target="_blank"
                title="Open"
                rel="noopener noreferrer"
                href={`${process.env.REACT_APP_API_URL}/api/downloadAttachments?path=${values.wc_certificate[0].path}`}
              >
                <Icon icon="download" title="Download WC certificate" />
                {values.wc_certificate[0].name}
              </a>
            ) : (
              <div className="d-flex flex-column align-items-center">
                <p className="disabled">WC certificate missing</p>
                <label className="file">
                  Add
                  <input
                    type="file"
                    name="wc_certificate"
                    accept="image/jpeg,image/gif,image/png,image/bmp,application/pdf,image/x-eps"
                    onChange={saveCertificateHandler}
                  />
                </label>
              </div>
            )}
          </div>
        </div>
        <div className="new-table--row">
          <div className="new-table--row--item new-table--row--item__column align-items-center">
            <span>Attachments: </span>
            {values?.attachments?.length > 0 ? (
              values.attachments.map((item) => (
                <a
                  key={item.name}
                  className="p10 mt-2"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Download"
                  href={`${process.env.REACT_APP_API_URL}/api/downloadAttachments?path=${item.path}`}
                >
                  <Icon icon="download" title="Download attachment" />
                  {item.name}
                </a>
              ))
            ) : (
              <p className="disabled p10">No attachments</p>
            )}
          </div>
          <div className="new-table--row--item">
            <label className="file">
              Add
              <input
                type="file"
                multiple
                name="wc_certificate"
                accept="image/jpeg,image/gif,image/png,image/bmp,application/pdf,image/x-eps"
                onChange={saveAttachmentsHandler}
              />
            </label>
          </div>
        </div>
        {buildingTypes.length > 0 && (
          <div className="new-table--row">
            <div className="new-table--row--item">
              <span>Building type</span>
            </div>
            <div className="new-table--row--item">
              <Select onChange={(option) => setFieldValue('building_type', option.value)} value={values['building_type']} options={buildingTypes} />
            </div>
          </div>
        )}
        <div className="new-table--row">
          <div className="new-table--row--item">
            <CheckBox
              className="justify-content-between"
              name="emergency_request"
              label="Send Request Emergency?"
              checked={Number(values['emergency_request']) === 1}
              onChange={(e) => setFieldValue('emergency_request', e.target.checked ? 1 : 0)}
            />
          </div>
          <div className="new-table--row--item">
            <CheckBox
              className="justify-content-between"
              name="long_carry"
              label="Long Carry"
              checked={values['long_carry'] === 1}
              onChange={(e) => setFieldValue('long_carry', e.target.checked ? 1 : 0)}
            />
          </div>
        </div>
        <div className="new-table--row">
          <div className="new-table--row--item">
            <CheckBox
              className="justify-content-between"
              name="double_elevator"
              label="Double Elevator"
              checked={values['double_elevator'] === 1}
              onChange={(e) => setFieldValue('double_elevator', e.target.checked ? 1 : 0)}
            />
          </div>
          <div className="new-table--row--item">
            <CheckBox
              className="justify-content-between"
              name="security_escort"
              label="Security Escort"
              checked={values['security_escort'] === 1}
              onChange={(e) => setFieldValue('security_escort', e.target.checked ? 1 : 0)}
            />
          </div>
        </div>
        <div className="new-table--row">
          <div className="new-table--row--item">
            <CheckBox
              className="justify-content-between"
              name="low_clearance"
              label="Low Clearance"
              checked={values['low_clearance'] === 1}
              onChange={(e) => setFieldValue('low_clearance', e.target.checked ? 1 : 0)}
            />
          </div>
          <div className="new-table--row--item">
            <CheckBox
              className="justify-content-between"
              name="no_loading_dock"
              label="No Loading Dock"
              checked={values['no_loading_dock'] === 1}
              onChange={(e) => setFieldValue('no_loading_dock', e.target.checked ? 1 : 0)}
            />
          </div>
        </div>
        <div className="new-table--row">
          <div className="new-table--row--item">
            <CheckBox
              className="justify-content-between"
              name="elevator_exist"
              label="Elevator Exist"
              checked={values['elevator_exist'] === 1}
              onChange={(e) => setFieldValue('elevator_exist', e.target.checked ? 1 : 0)}
            />
          </div>
          <div className="new-table--row--item">
            <CheckBox
              className="justify-content-between"
              name="elevator_reservation"
              label="Elevator Reservation"
              checked={values['elevator_reservation'] === 1}
              onChange={(e) => setFieldValue('elevator_reservation', e.target.checked ? 1 : 0)}
            />
          </div>
        </div>
        <div className="new-table--row">
          <div className="new-table--row--item">
            <CheckBox
              className="justify-content-between"
              name="floor_protection"
              label="Floor Protection"
              checked={values['floor_protection'] === 1}
              onChange={(e) => setFieldValue('floor_protection', e.target.checked ? 1 : 0)}
            />
          </div>
          <div className="new-table--row--item">
            <CheckBox
              className="justify-content-between"
              name="shared_elevator"
              label="Shared Elevator"
              checked={values['shared_elevator'] === 1}
              onChange={(e) => setFieldValue('shared_elevator', e.target.checked ? 1 : 0)}
            />
          </div>
        </div>

        <div className="new-table--row">
          <div className="new-table--row--item">
            <p>{'Liability ' + getCoiStatus(values.liability_status, values.approved_liability)}</p>
          </div>
          <div className="new-table--row--item justify-content-around">
            <button
              type="button"
              className={liability_complete_disabled ? 'default' : 'main'}
              disabled={liability_complete_disabled}
              onClick={() => coiCompleteHandler(1)}
            >
              Complete
            </button>
            <button type="button" className="main" onClick={() => sendRequest(1)}>
              Send request
            </button>
          </div>
        </div>
        <div className="new-table--row">
          <div className="new-table--row--item">
            <p>{'WC ' + getCoiStatus(values.wc_status, values.approved_wc)} </p>
          </div>
          <div className="new-table--row--item justify-content-around">
            <button
              type="button"
              className={wc_complete_disabled ? 'default' : 'main'}
              disabled={wc_complete_disabled}
              onClick={() => coiCompleteHandler(2)}
            >
              Complete
            </button>

            <button type="button" className="main" disabled={!!values.disabled_wc} onClick={() => sendRequest(2)}>
              Send request
            </button>
          </div>
        </div>
        {(values.liability_certificate.length > 0 && values.wc_certificate.length > 0) ||
          (values.liability_certificate.length > 0 && values.disabled_wc) ? (
          <div className="new-table--row">
            <div className="new-table--row--item">
              {values.approving_email_sent && <p>{'Approving mail sent at ' + values.approving_email_sent}</p>}
            </div>
            <div className="new-table--row--item">
              <button type="button" className="main" onClick={resendApprovingMail}>
                {values.approving_email_sent ? 'Send' : 'Resend'} Approving Mail
              </button>
            </div>
          </div>
        ) : null}

        {values.attachments.length > 0 && (
          <div className="new-table--row">
            <div className="new-table--row--item">
              <button type="button" className="main flex-grow-1" onClick={sendSampleHandler}>
                Send Sample
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default InsuranceForm;

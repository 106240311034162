import moment from 'moment';

const getWeeklyJobsRanges = (jobs) => {
  const pages = jobs.reduce((acc, currentValue) => {
    const start = moment(currentValue.pickup_date, 'MM/DD/YYYY').isoWeekday(1).format('MM/DD/YYYY');
    const end = moment(currentValue.pickup_date, 'MM/DD/YYYY').isoWeekday(7).format('MM/DD/YYYY');
    const key = `${start} - ${end}`;

    return { ...acc, [key]: null };
  }, {});

  return Object.keys(pages);
};

const hasSelectedJobOnCurrentPage = (jobs, id) => {
  const job = jobs.find((job) => job.id === Number(id));
  return Boolean(job);
};

const getJobsByRange = (list, range) => {
  const [from, to] = range.split(' - ');

  const jobs = list.filter((job) => {
    const jobDate = moment(job.pickup_date, 'MM/DD/YYYY').unix();

    return jobDate >= moment(from, 'MM/DD/YYYY').unix() && jobDate <= moment(to, 'MM/DD/YYYY').unix();
  });

  return jobs;
};

export { hasSelectedJobOnCurrentPage, getWeeklyJobsRanges, getJobsByRange };

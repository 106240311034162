import React, { useState, useMemo } from 'react';
import classNames from 'classnames';
import { generalModalSettingsTabsOptions } from 'helpers/constants';
import Modal from 'components/newComponents/Modal';
import { useModal } from 'context/modal';
import PdfHeader from './PdfHeader';
import Reminders from './Reminders';
import QuoteHeader from './QuoteHeader';
import SmsReminders from './SmsReminders';
import Tasks from './Tasks';
import Claims from './Claims';

import './style.scss';

const JobSettings = () => {
  const { modalNames, info, close } = useModal();

  const modalInfo = info(modalNames.settings);
  const isVisible = modalInfo.visible;
  const params = modalInfo?.params;

  const [active, setActive] = useState(params.type ?? 'tasks');

  const tab = useMemo(() => {
    switch (active) {
      case 'tasks':
        return <Tasks type={active} />;
      case 'claims':
        return <Claims type={active} />;
      case 'pdf':
        return <PdfHeader />;
      case 'quote':
        return <QuoteHeader />;
      case 'reminders':
        return <Reminders type={active} />;
      case 'sms_reminders':
        return <SmsReminders type={active} />;
      default:
        return null;
    }
  }, [active]);

  const menu = useMemo(() => {
    return generalModalSettingsTabsOptions.map((el) => (
      <div key={el.value} className={classNames('job-notes__tabs__item', { active: active === el.value })}>
        <button type="button" className={classNames('p20 flex-grow-1', { main: active === el.value })} onClick={() => setActive(el.value)}>
          {el.label}
        </button>
      </div>
    ));
  }, [active]);

  const title = `Settings/${generalModalSettingsTabsOptions.find((i) => i.value === active).label}`;

  return (
    <Modal style={{ width: 1400 }} header={title} onHide={() => close(modalNames.settings)} show={isVisible}>
      <div className="job-notes">
        <div className="job-notes__tabs">{menu}</div>
        {tab}
      </div>
    </Modal>
  );
};

export default JobSettings;

import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { useInfo, useReschedule, useConfirmRescheduleRateChange } from 'hooks/query/useJob';
import { useSelectAgent } from 'hooks/useSelectAgent';
import { formatFullDateTime } from 'helpers/dateHelper';
import TimeSelect from 'components/TimeSelect';
import DateSelect from 'components/DateSelect';
import { alert } from 'components/Alerts';
import Icon from 'components/Icon';

const Dates = () => {
  const { id } = useParams();

  const { mutate: reschedule } = useReschedule(id);

  const { onMutate: rescheduleWithAgent } = useSelectAgent(reschedule);
  const { mutateAsync: confirm } = useConfirmRescheduleRateChange();

  const {
    data: { job_info: info, dates },
  } = useInfo(id);

  const { setFieldValue, values } = useFormik({
    enableReinitialize: true,
    initialValues: {
      job_id: info.id,
      customer: false,
      send_email: false,
      stop_date: dates.stop_date,
      start_date: dates.start_date,
      follow_up: dates.follow_up,
      required_pickup: dates.required_pickup,
    },
  });

  const getAlert = useCallback(async (title, button) => {
    return await alert({
      title,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: button,
    });
  }, []);

  const confirmRescheduleRate = useCallback(
    async (values) => {
      const preparedData = {
        job_id: values['job_id'],
        required_pickup: values['required_pickup'],
      };

      const res = await confirm(preparedData);

      const { data } = res;

      const currentRate = data?.current_rate;
      const newRate = data?.new_rate;

      let isConfirmed = false;

      if (Number(currentRate ?? 0) !== Number(newRate ?? 0)) {
        const title = `Current rate is ${data.current_rate}$. 
      Do you want to change it to selected date rate: ${data.new_rate}$ ?`;

        const alert = await getAlert(title, 'Change rate');
        isConfirmed = alert.isConfirmed;
      }
      rescheduleWithAgent({ ...values, change_rate: isConfirmed });
    },
    [confirm, getAlert, rescheduleWithAgent]
  );

  const packUpHandler = useCallback(
    async (e) => {
      const res = await getAlert('Send reschedule mail to customer?', 'Send');
      const date = formatFullDateTime(e.date);
      setFieldValue(e.name, date);
      setFieldValue('send_email', res.isConfirmed);
      confirmRescheduleRate({ ...values, [e.name]: date, send_email: res.isConfirmed });
    },
    [confirmRescheduleRate, getAlert, setFieldValue, values]
  );

  const followUpHandler = useCallback(
    async (e) => {
      setFieldValue(e.name, formatFullDateTime(e.date));
      rescheduleWithAgent({ ...values, follow_up: formatFullDateTime(e.date) });
    },
    [rescheduleWithAgent, setFieldValue, values]
  );

  return (
    <div className="job_details__item">
      <h3 className="header table-header-shadow">Job Time</h3>
      <div className="new-table">
        <form>
          <div className="new-table--row no-padding">
            <div className="new-table--row--item">
              <Icon icon="arrive_time" size={22} title="Required Pickup" />
              <DateSelect name="required_pickup" selected={values['required_pickup']} minDate={new Date()} onChange={packUpHandler} />
            </div>
            <div className="new-table--row--item">
              <TimeSelect name="required_pickup" value={values['required_pickup']} onChange={packUpHandler} />
            </div>
          </div>
          <div className="new-table--row no-padding">
            <div className="new-table--row--item">
              <Icon icon="follow_up" size={22} title="Follow up" />
              <DateSelect name="follow_up" selected={values['follow_up']} onChange={followUpHandler} minDate={new Date()} />
            </div>
            <div className="new-table--row--item">
              <TimeSelect name="follow_up" value={values['follow_up']} onChange={followUpHandler} />
            </div>
          </div>
          <div className="new-table--row no-padding">
            <div className="new-table--row--item">
              <Icon icon="job_start" size={22} title="Job start" />
              <DateSelect name="start_date" selected={values['start_date']} minDate={new Date()} onChange={followUpHandler} disabled />
            </div>
            <div className="new-table--row--item">
              <TimeSelect name="start_date" value={values['start_date']} onChange={followUpHandler} disabled />
            </div>
          </div>
          <div className="new-table--row no-padding">
            <div className="new-table--row--item">
              <Icon icon="job_stop" size={22} title="Job stop" />
              <DateSelect name="stop_date" selected={values['stop_date']} minDate={new Date()} onChange={followUpHandler} disabled />
            </div>
            <div className="new-table--row--item">
              <TimeSelect name="stop_date" value={values['stop_date']} onChange={followUpHandler} disabled />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Dates;

import React, { Fragment, useMemo } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import Preloader from 'components/Preloader';
import Filter from './Filter';
import ReportCharts from './ReportCharts';
import ReportTotal from './ReportTotal';
import ReportJobList from './ReportJobList';
import { useReportFilterInfo, useGetUserNames, useGetGeneralReport } from 'hooks/query/useReport';
import { changePeriod } from '../reportUtils';
import { jobTypes } from '../reportConstants';

const General = () => {
  const { data } = useReportFilterInfo();
  const { mutate: getReport, data: generalReportResponse, isLoading } = useGetGeneralReport();
  const report = generalReportResponse?.data;

  const companiesList = useMemo(() => {
    if (!data) return [];

    const list = data.companies.map((company) => ({
      value: [company.id],
      label: company.company_name,
    }));
    list.unshift({ value: data.all_companies, label: 'All Companies' });

    return list;
  }, [data]);

  const moveSizes = useMemo(() => {
    if (!data) return [];

    const list = data.move_sizes.map((size) => ({
      value: [size.id],
      label: size.name,
    }));
    list.unshift({ value: data.all_move_sizes_id, label: 'All Move Size' });

    return list;
  }, [data]);

  const userTypes = data?.user_type.map((type) => ({ value: type.id, label: type.name })) ?? [];

  const { values, setFieldValue, handleSubmit, errors, touched } = useFormik({
    initialValues: {
      company_id: companiesList[0]?.value ?? 0,
      user_id: [],
      user_type: userTypes[0]?.value ?? 1,
      move_size: moveSizes[0]?.value ?? [],
      job_type: jobTypes[0].value,
      job_statuses: [6],
      from: changePeriod(7).from,
      to: changePeriod(7).to,
      period_type: 7,
      packing_type: 'null',
    },
    onSubmit: (values) => getReport(values),
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      user_id: Yup.array().min(1, 'Select agent').required('Select agent'),
    }),
  });

  const isValidHandler = (name) => {
    if (errors[name] && touched[name]) return errors[name];
    return null;
  };

  const handleSelect = (name, value) => setFieldValue(name, value);

  const selectDate = ({ name, date }) => setFieldValue(name, moment(date).format('MM/DD/YY'));

  const selectJobFilter = (status, isChecked) => {
    if (isChecked) {
      setFieldValue(
        'job_statuses',
        values['job_statuses'].filter((item) => item !== status)
      );
      return;
    }

    setFieldValue('job_statuses', [...values['job_statuses'], status]);
  };

  const selectPeriod = ({ value }) => {
    const { from, to } = changePeriod(value);
    setFieldValue('from', from);
    setFieldValue('to', to);
    setFieldValue('period_type', value);
  };

  const selectAllUsers = (list) => {
    if (values['user_id'].length === list.length) {
      setFieldValue('user_id', []);
      return;
    }
    setFieldValue(
      'user_id',
      list.map((user) => user.id)
    );
  };

  const selectUser = (id) => {
    if (values['user_id'].includes(id)) {
      setFieldValue(
        'user_id',
        values['user_id'].filter((i) => i !== id)
      );
      return;
    }

    setFieldValue('user_id', [...values['user_id'], id]);
  };

  const { data: userNamesResponse } = useGetUserNames(
    { company_id: values['company_id'], user_type: values['user_type'] },
    {
      enabled: Boolean(values['company_id'].length),
      onSuccess: ({ user_names }) => selectAllUsers(user_names),
    }
  );

  const userNames = useMemo(() => {
    if (!userNamesResponse) return [];

    return userNamesResponse.user_names.map((user) => ({ ...user, value: user.id, label: `${user.first_name} ${user.last_name}` }));
  }, [userNamesResponse]);

  if (!data) return null;

  const formActions = { isValidHandler, handleSelect, selectJobFilter, selectPeriod, selectDate, selectAllUsers, selectUser, handleSubmit };

  return (
    <Fragment>
      {isLoading && <Preloader />}

      <Filter data={{ values, companiesList, userNames, moveSizes, userTypes }} actions={formActions} />

      {report && (
        <Fragment>
          <ReportCharts job_list={report.job_list} data={report.data} FilterInfo={data} />
          <ReportTotal job_list={report.job_list} FilterInfo={data} />
          <ReportJobList jobs={report.job_list} />
        </Fragment>
      )}
    </Fragment>
  );
};

export default General;

import React, { useMemo } from 'react';
import { Calendar, CalendarControls } from 'react-yearly-calendar';
import TooltipTitle from 'components/TooltipTitle';
import './style.scss';

function YearlyCalendar(props) {
  const {
    info = {},
    year,
    showTodayButton = false,
    customCSSclasses,
    selectRange,
    selectedRange,
    selectedDay,
    onPrevYear,
    onNextYear,
    goToToday,
    onPickDate,
    onPickRange,
  } = props;

  const infoContainer = useMemo(() => {
    const tooltips = Object.entries(info).map((i) => {
      return (
        <TooltipTitle title={i[1]} key={i[0]}>
          <span className={`info ${i[0]}`} />
        </TooltipTitle>
      );
    });

    return <div className="yearly-calendar-head-info">{tooltips}</div>;
  }, [info]);

  return (
    <div className="yearly-calendar">
      <div className="yearly-calendar-head">
        {infoContainer}

        <CalendarControls year={year} showTodayButton={showTodayButton} onPrevYear={onPrevYear} onNextYear={onNextYear} goToToday={goToToday} />
      </div>
      <Calendar
        year={year}
        selectRange={selectRange}
        selectedRange={selectedRange}
        selectedDay={selectedDay}
        onPickDate={onPickDate}
        onPickRange={onPickRange}
        customClasses={customCSSclasses}
      />
    </div>
  );
}

export default YearlyCalendar;

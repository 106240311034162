import React, { Fragment } from 'react';
import ReactTooltip from 'react-tooltip';
import { getJobType, getPaymentType, getRateModifier, shouldAddCommissionToSalary, shouldShowColumn } from './payrollUtils';
import Icon from 'components/Icon';
import { statusNumberToName } from 'helpers/statuses';
import getTitle from 'helpers/GetTitle';

const PayrollJob = ({ data, type }) => {
  let jobs = [];

  data.forEach((job, key) => {
    const jobTotalSalary = (
      Number(job.commission) +
      Number(job.hours) * Number(job.rate_per_hour) +
      Number(shouldAddCommissionToSalary(job.user_type) ? job.tips : 0)
    ).toFixed(2);

    jobs.push(
      <div className="reports-table-row" key={key}>
        <div className="reports-table-column reports-table-column--13"></div>
        <div className="reports-table-column reports-table-column--13">
          <Icon size={20} style={{ marginRight: 10 }} title={getTitle(job.job_status)} icon={statusNumberToName(job.job_status)} />
          {job.job_title}
        </div>
        <div className="reports-table-column reports-table-column--13">{job.created_at}</div>
        <div className="reports-table-column reports-table-column--13">{getJobType(job.job_type)}</div>

        <div className="reports-table-column reports-table-column--13">
          {Array.isArray(job.rate_modifier) && job.rate_modifier.length > 0 && (
            <span data-tip data-for={`rate_modifier-tooltip${key}`}>
              <Icon icon="manual_tips" size={20} style={{ marginRight: 10, lineHeight: '55px' }} />
              <ReactTooltip arrowColor="transparent" id={`rate_modifier-tooltip${key}`} place="top">
                {Boolean(job.default_rate_per_hour) && (
                  <p className="d-flex justify-content-between">
                    <span className="mr-2">Base Rate</span>
                    <span>${Number(job.default_rate_per_hour).toFixed(2)}</span>
                  </p>
                )}

                {job.rate_modifier.map((item, index) => {
                  const { label, value } = getRateModifier(item);

                  return (
                    <p key={index} className="d-flex justify-content-between">
                      <span className="mr-2">{label}</span>
                      <span>${value}</span>
                    </p>
                  );
                })}
              </ReactTooltip>
            </span>
          )}
          {job.rate_per_hour}
        </div>

        <div className="reports-table-column reports-table-column--13">{job.hours}</div>
        {shouldShowColumn.total(type) && (
          <div className="reports-table-column reports-table-column--13">${(Number(job.hours) * Number(job.rate_per_hour)).toFixed(2)}</div>
        )}
        <div className="reports-table-column reports-table-column--13">${job.tips}</div>
        <div className="reports-table-column reports-table-column--13">{job.commission}</div>
        <div className="reports-table-column reports-table-column--13">${jobTotalSalary}</div>

        <div className="reports-table-column reports-table-column--13">
          {job.payments.map((payment, index) => (
            <Fragment key={index}>
              <span>{`$${payment.amount}, ${getPaymentType(payment.type)}`}</span>
              <br />
            </Fragment>
          ))}
        </div>

        <div className="reports-table-column reports-table-column--13">${job.job_total}</div>
        <div className="reports-table-column reports-table-column--13">${(Number(job.job_total) - Number(job.tips)).toFixed(2)}</div>
      </div>
    );
  });

  return (
    <div className="reports-table">
      <div className="reports-table-row">
        <div className="reports-table-column reports-table-column--13 bcg-light"></div>
        <div className="reports-table-column reports-table-column--13 reports-table-column--title bcg-light">Job ID</div>
        <div className="reports-table-column reports-table-column--13 reports-table-column--title bcg-light">Date</div>
        <div className="reports-table-column reports-table-column--13 reports-table-column--title bcg-light">Job type</div>
        <div className="reports-table-column reports-table-column--13 reports-table-column--title bcg-light">Hourly salary</div>
        <div className="reports-table-column reports-table-column--13 reports-table-column--title bcg-light">Hours</div>
        {shouldShowColumn.total(type) && (
          <div className="reports-table-column reports-table-column--13 reports-table-column--title bcg-light">Total</div>
        )}
        <div className="reports-table-column reports-table-column--13 reports-table-column--title bcg-light">Tips</div>
        <div className="reports-table-column reports-table-column--13 reports-table-column--title bcg-light">Commission$</div>
        <div className="reports-table-column reports-table-column--13 reports-table-column--title bcg-light">Salary</div>
        <div className="reports-table-column reports-table-column--13 reports-table-column--title bcg-light">Payments</div>
        <div className="reports-table-column reports-table-column--13 reports-table-column--title bcg-light">Job total</div>
        <div className="reports-table-column reports-table-column--13 reports-table-column--title bcg-light">Total Less Tips</div>
      </div>
      {jobs}
    </div>
  );
};

export default PayrollJob;

import React, { forwardRef, useMemo } from 'react';
import { useParams } from 'react-router';
import { useJobList } from 'hooks/query/useJobs';
import CalendarBig from './CalendarBig';
import CalendarEvents from './CalendarEvents';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import history from 'utils/history';
import routes from 'config/routes';

import './CalendarPage.scss';

const CalendarPage = () => {
  const { date } = useParams();

  const params = useMemo(() => {
    return {
      company_id: 0,
      type: 'calendar',
      start_date: moment(date).startOf('month').format('MM/DD/YYYY'),
      end_date: moment(date).endOf('month').format('MM/DD/YYYY'),
    };
  }, [date]);

  const { data: jobs } = useJobList(params);

  const dataArray = useMemo(() => {
    if (!jobs) return [];
    return jobs?.map((item) => ({
      ...item,
      start: new Date(item.start_date),
      end: new Date(item.stop_date),
      pickup_date: new Date(item.pickup_date),
      desc: item.customer_name,
    }));
  }, [jobs]);

  const now = moment(date, 'YYYY-MM-DD').toDate();

  const changePickerValue = (value) => {
    const newDate = moment(value).format('YYYY-MM-DD');
    history.push({ pathname: `${routes.CALENDAR}/${newDate}` });
  };

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <button ref={ref} className="custom-input" onClick={onClick}>
      {value}
    </button>
  ));

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="calendar-wrapper">
            <h2 className="calendar-wrapper-header">Select month to load data</h2>
            <DatePicker customInput={<ExampleCustomInput />} selected={now} onChange={changePickerValue} dateFormat="MM-yyyy" showMonthYearPicker />
          </div>
        </div>
        <div className="col-12">
          <CalendarBig displayDate={now} data={dataArray} selectNewDate={changePickerValue} />
        </div>
        <div className="col-12">
          <CalendarEvents
            data={dataArray}
            start={moment(now).startOf('month').format('MM/DD/YYYY')}
            end={moment(now).endOf('month').format('MM/DD/YYYY')}
          />
        </div>
      </div>
    </div>
  );
};

export default CalendarPage;

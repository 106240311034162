import React, { memo, useMemo, useCallback, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import moment from 'moment';
import Icon from 'components/Icon';
import { useModal } from 'context/modal';
import { useSelectAgent } from 'hooks/useSelectAgent';
import { useMovSizeChange, useInfo, useTabInfo, useChangeServiceType } from 'hooks/query/useJob';
import { useDefault } from 'hooks/query/useCommon';
import { formatLT } from 'helpers/dateHelper';
import { getElevatorData } from 'helpers/getElevatorData';
import { laborTypeOptions } from 'helpers/constants';
import queryKeys from 'config/queryKeys';
import Select from 'components/newComponents/Select';
import RoutesItem from './RoutesItem';
import RoutesDropdown from './RoutesDropdown';
import MovingSection from './MovingSection';

const RoutesContainer = memo(() => {
  const { id, tab } = useParams();
  const queryClient = useQueryClient();

  const { mutate: updateServiceType } = useChangeServiceType();

  const { mutate } = useMovSizeChange(id);
  const {
    data: { job_info: jobInfo },
  } = useInfo(id);

  const {
    data: { extra_stops: extraStops, additional_info: additionalInfo },
  } = useTabInfo(id, tab);

  const {
    data: { job_distances: jobDistances },
  } = useTabInfo(id, tab);

  const {
    data: { move_sizes: sizes, company: companies },
  } = useDefault();

  const { modalNames, open } = useModal();

  const { onMutate: changeMovSize } = useSelectAgent(mutate);

  const { notes } = queryClient.getQueryData([queryKeys.job, id, tab]).data;
  const { dates } = queryClient.getQueryData([queryKeys.job, id]).data;

  const companyId = jobInfo.company_id;

  const moveSizes = useMemo(() => {
    return sizes[companyId].map((el) => ({ value: el.id, label: el.name }));
  }, [companyId, sizes]);

  const moveSize = useMemo(() => {
    return moveSizes.find((movSize) => movSize.value === jobInfo.move_size_id);
  }, [jobInfo.move_size_id, moveSizes]);

  const handleSelect = useCallback(
    ({ value }) => {
      changeMovSize({ job_id: id, move_size_id: value });
    },
    [changeMovSize, id]
  );

  const jobNote = notes.find((note) => note.type === 6);

  const officeInfo = useMemo(() => {
    return companies.find((item) => item.id === companyId);
  }, [companies, companyId]);

  const officeTime = useMemo(
    () => ({
      startDate: formatLT(moment(dates.start_date).subtract(1, 'h')),
      stopDate: formatLT(moment(dates.stop_date).add(1, 'h')),
    }),
    [dates.start_date, dates.stop_date]
  );

  const normalizeAddress = (i) => {
    const hasZipCodeInAddress = i.zip ? i.address.includes(i.zip) : false;
    const apartment = i.apartment ? `, ${i.apartment}` : '';
    const zip = i.zip ? `, ${i.zip}` : '';

    return hasZipCodeInAddress ? i.address + apartment : i.address + apartment + zip;
  };

  const serviceType = useMemo(() => {
    if (!jobInfo) return '';

    return laborTypeOptions.find((option) => option.value === jobInfo.labor_type)?.value ?? '';
  }, [jobInfo]);

  const handleUpdateServiceType = ({ value }) => {
    updateServiceType(
      {
        job_id: id,
        labor_type: value,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([queryKeys.job, id]);
        },
      }
    );
  };

  return (
    <Fragment>
      <div className="new-table">
        <div className="new-table--row justify-content-between table-header-shadow">
          <div className="new-table--row--item col-3 flex-grow-0 table-border-right no-padding-y">
            <Icon icon="move_size" size={20} title="Job move size" />
            <Select options={moveSizes} name="move_size" value={moveSize?.value || null} onChange={handleSelect} />
          </div>

          <div className="new-table--row--item select no-shadow col-3">
            <Select placeholder="Service type" value={serviceType} options={laborTypeOptions} onChange={handleUpdateServiceType} />
          </div>

          <RoutesDropdown />
        </div>

        {jobNote && (
          <div className="new-table--row">
            <p className="new-table--row--item">Quick note: {jobNote.note}</p>
          </div>
        )}

        <RoutesItem
          isOffice
          extraStopClass="waypoint__first"
          time={officeTime.startDate}
          address={`${officeInfo?.address}, ${officeInfo?.city}, ${officeInfo?.state} ${officeInfo?.zipcode}`}
          buildingName={officeInfo?.company_name}
        />

        <MovingSection type="from" />

        {extraStops?.length > 0 &&
          extraStops.map((item, key) => {
            const type = item.type === 1 ? 'from' : 'to';

            const extraStopAdditionalInfo = additionalInfo[`stop_${type}`].find((i) => i.stop_id === item.id);

            const { elevatorExist, elevatorConfirm, elevator, elevatorReservation, elevatorStyle } = getElevatorData(extraStopAdditionalInfo);

            return (
              <RoutesItem
                isExtraStop={true}
                isIntermediateStops={true}
                extraStopClass="waypoint__middle"
                key={item?.id}
                type={type}
                extraStop={item}
                extraStopId={item.id}
                elevator={elevator}
                elevatorExist={elevatorExist}
                elevatorConfirm={elevatorConfirm}
                elevatorReservation={elevatorReservation}
                elevatorStyle={elevatorStyle}
                name={item?.customer_name}
                address={normalizeAddress(item)}
                buildingName={item?.building_name}
                travelDistance={jobDistances && jobDistances.length ? jobDistances[key + 1].distance.replace(/[^0-9\.]+/g, '') : ''}
                onClick={() => open(modalNames.extraStop, { type: 'update', id: item.id })}
              />
            );
          })}

        <MovingSection type="to" />

        <RoutesItem
          isOffice
          extraStopClass="waypoint__last"
          time={officeTime.stopDate}
          address={`${officeInfo?.address}, ${officeInfo?.city}, ${officeInfo?.state} ${officeInfo?.zipcode}`}
          buildingName={officeInfo?.company_name}
          travelDistance={jobDistances ? jobDistances[jobDistances.length - 1]?.distance.replace(/[^0-9\.]+/g, '') : ''}
        />

        <div className="new-table--row">
          <div className="new-table--row--item no-padding no-side-shadows">
            <button className="default p20" onClick={() => open(modalNames.extraStop, { type: 'add' })}>
              <Icon icon="add" size={16} /> Add extra stop
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
});

export default RoutesContainer;

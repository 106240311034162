import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { matchPath } from 'react-router';
import { routePaths } from 'config/routes';
import { useFormik } from 'formik';
import { useModal } from 'context/modal';
import { useTabInfo } from 'hooks/query/useJob';
import { useAddExtraStop, useUpdateExtraStop } from 'hooks/query/useExtraStops';
import { ModalButton } from 'components/newComponents/Buttons';
import Modal from 'components/newComponents/Modal';
import ExtraStopForm from './ExtraStopForm';
import initialValues from './initialValues';
import Preloader from 'components/Preloader';

const ExtraStop = () => {
  const { pathname } = useLocation();

  const [isLoading, setIsLoading] = useState(false);

  const {
    params: { id, tab },
  } = matchPath(pathname, { path: routePaths.JOBS });

  const { modalNames, close, info } = useModal();

  const { mutate: updateExtraStop } = useUpdateExtraStop(id, tab);
  const { mutate: saveExtraStop } = useAddExtraStop(id, tab);

  const isVisible = info(modalNames.extraStop).visible;
  const params = info(modalNames.extraStop).params;

  const closeHandler = useCallback(() => close(modalNames.extraStop), [close, modalNames.extraStop]);

  const {
    data: { extra_stops: extraStops, moving_info: movingInfo, additional_info: additionalInfo },
  } = useTabInfo(id, tab);

  const initial = useMemo(() => {
    if (!params?.id) {
      return {
        ...initialValues,
        customer_name: movingInfo?.from?.customer_name || '',
        email: movingInfo?.from?.email || '',
        home_phone: movingInfo?.from?.home_phone || '',
        cell_phone: movingInfo?.from?.cell_phone || '',
      };
    }

    const extraStop = extraStops.find((item) => item.id === params.id);
    const extraStopType = extraStop.type === 1 ? 'from' : 'to';
    const extraStopAdditionalInfo = additionalInfo[`stop_${extraStopType}`].find((i) => i.stop_id === params.id);

    const missedExtraStopData = {
      elevator_exist: extraStopAdditionalInfo?.elevator_exist,
      building_access_end: extraStopAdditionalInfo?.building_access_end,
      building_access_start: extraStopAdditionalInfo?.building_access_start,
      elevator_reservation_end: extraStopAdditionalInfo?.elevator_reservation_end,
      elevator_reservation_start: extraStopAdditionalInfo?.elevator_reservation_start,
      stop_id: extraStopAdditionalInfo?.stop_id,
    };

    return { ...extraStop, ...missedExtraStopData } ?? initialValues;
  }, [extraStops, additionalInfo, movingInfo, params.id]);

  const saveHandler = useCallback(
    (values) => {
      setIsLoading(true);

      if (params?.type === 'update')
        updateExtraStop(
          { ...values, job_id: id },
          {
            onSuccess: closeHandler,
            onSettled: () => setIsLoading(false),
          }
        );
      else
        saveExtraStop(
          { ...values, job_id: id },
          {
            onSuccess: closeHandler,
            onSettled: () => setIsLoading(false),
          }
        );
    },
    [closeHandler, id, params.type, saveExtraStop, updateExtraStop]
  );

  const { values, handleChange, setFieldValue, handleSubmit } = useFormik({
    initialValues: initial,
    onSubmit: saveHandler,
    enableReinitialize: true,
    // validationSchema: Yup.object().shape({
    //   customer_name: Yup.string().required('Enter name!'),
    //   home_phone: Yup.string()
    //     .test('len', 'Invalid phone number', (value) => {
    //       if (!value) return false;
    //       return !value.includes('_');
    //     })
    //     .required('Home phone required'),
    //   city: Yup.string().required('Enter address!'),
    //   address: Yup.string().required('Enter address!'),
    //   zip: Yup.string()
    //     .required('Zip required')
    //     .test('len', 'Invalid zip length', (value) => {
    //       if (!value) return false;
    //       return value.length === 5;
    //     }),
    // }),
  });

  return (
    <Fragment>
      {isLoading && <Preloader />}

      <Modal style={{ width: '800px' }} header="Extra Stop" show={isVisible} onHide={closeHandler}>\
        <form onSubmit={handleSubmit}>
          <ExtraStopForm values={values} handleChange={handleChange} setFieldValue={setFieldValue} />

          <div className="footer-buttons">
            <ModalButton title="Cancel" variant="cancel" onClick={closeHandler} />
            <ModalButton type="submit" title={params?.type === 'update' ? 'Update' : 'Save'} variant="confirm" />
          </div>
        </form>
      </Modal>
    </Fragment>
  );
};

export default ExtraStop;

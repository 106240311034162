import React, { memo, useCallback } from 'react';
import Icon from 'components/Icon';
import MySelect from 'components/MySelect';
import notesOptions from 'helpers/notesOptions';
import { preventArrowUpDown } from 'utils/preventArrowUpDown';

const ItemToSave = memo((props) => {
  const {
    item: { name, price, qty, date, move_type, pack_id },
    changeMoveType,
    changeQty,
    removeItem,
  } = props;

  const handleChangeMoveType = useCallback(({ _, value }) => changeMoveType(pack_id, value.value), [changeMoveType, pack_id]);
  const handleChangeCurrentQty = useCallback((e) => changeQty(pack_id, e.target.value), [changeQty, pack_id]);

  return (
    <div className="new-table--row">
      <div className="new-table--row--item justify-content-center w25">
        <span>{name}</span>
      </div>
      <div className="new-table--row--item justify-content-center w15">
        <span>{price}</span>
      </div>
      <div className="new-table--row--item justify-content-center w10">
        <input type="number" className="text-center" value={qty} onKeyDown={preventArrowUpDown} onChange={handleChangeCurrentQty} />
      </div>
      <div className="new-table--row--item justify-content-center w15 select">
        <MySelect
          name="mover_type"
          options={notesOptions}
          defaultValue={notesOptions[+move_type - 1]}
          onChange={handleChangeMoveType}
        />
      </div>
      <div className="new-table--row--item justify-content-center w15">
        <span>{date}</span>
      </div>
      <div className="new-table--row--item justify-content-center w10">
        <span>{(qty * price).toFixed(2)}</span>
      </div>
      <div className="new-table--row--item justify-content-center w10">
        <button onClick={() => removeItem(pack_id)} className="no-padding">
          <Icon size={20} icon="delete" title="Delete" />
        </button>
      </div>
    </div>
  );
});

export default ItemToSave;

import React, { useMemo, useState, Fragment } from 'react';
import classNames from 'classnames';
import { useDefault } from 'hooks/query/useCommon';
import { formatTimeAPMeridiem } from 'helpers/dateHelper';
import { preventArrowUpDown } from 'utils/preventArrowUpDown';
import TextArea from 'components/newComponents/TextArea';
import CheckBox from 'components/newComponents/CheckBox';
import Select from 'components/newComponents/Select';
import Input from 'components/newComponents/Input';
import TimeSelect from 'components/TimeSelect';

import './styles.scss';

const AdditionalInfo = ({ values, setFieldValue, handleChange, edit, isValidHandler }) => {
  const [isFrom, seIsFrom] = useState(true);

  const { data: defaultItems } = useDefault();

  const stairsSet = useMemo(() => {
    return [...Array(10).keys()].map((_, i) => ({ value: i + 1, label: i + 1 }));
  }, []);

  const bedroomSets = useMemo(() => {
    return [...Array(15).keys()].map((_, i) => ({ value: i + 1, label: i + 1 }));
  }, []);

  const buildingTypes = useMemo(() => {
    if (!defaultItems) return [];

    return defaultItems.statuses.building_type.map((el, index) => ({ value: index, label: el }));
  }, [defaultItems]);

  return (
    <div className="step-three">
      {!edit && <h5 className="step-three__header">Please enter your additional information </h5>}
      <div className="step-three__switcher">
        <div className={classNames('step-three__switcher__item', { active: isFrom })} onClick={() => seIsFrom(true)}>
          <p>From</p>
        </div>
        <div className={classNames('step-three__switcher__item', { active: !isFrom })} onClick={() => seIsFrom(false)}>
          <p>To</p>
        </div>
      </div>

      <div className="step-three__body new-table modal">
        {isFrom ? (
          <Fragment>
            <div className="new-table--row">
              <div className="new-table--row--item">
                <p>Type of building</p>
              </div>
              <div className="new-table--row--item select">
                <Select
                  options={buildingTypes}
                  value={Number(values['building_type_from'])}
                  placeholder="Building type from"
                  name="building_type_from"
                  onChange={({ value }) => setFieldValue('building_type_from', value)}
                />
              </div>
            </div>
            {[1, 2, 3, 6].includes(values.building_type_from) && (
              <Fragment>
                <div className="new-table--row">
                  <div className="new-table--row--item">
                    <p>Building Access</p>
                  </div>
                  <div className="new-table--row">
                    <div className="new-table--row--item select">
                      <TimeSelect
                        name="building_access_from_start"
                        value={values['building_access_from_start']}
                        interval={60}
                        placeholder="Start time"
                        onChange={({ name, date }) => setFieldValue(name, formatTimeAPMeridiem(date))}
                      />
                    </div>
                    <div className="new-table--row--item select">
                      <TimeSelect
                        name="building_access_from_end"
                        value={values['building_access_from_end']}
                        interval={60}
                        placeholder="End time"
                        onChange={({ name, date }) => setFieldValue(name, formatTimeAPMeridiem(date))}
                      />
                    </div>
                  </div>
                </div>
                <div className="new-table--row">
                  <div className="new-table--row--item">
                    <CheckBox
                      className="justify-content-between"
                      name="elevator_exist_from"
                      label="Elevator Exist?"
                      checked={Number(values['elevator_exist_from']) === 1}
                      onChange={(e) => setFieldValue('elevator_exist_from', e.target.checked ? 1 : 0)}
                    />
                  </div>
                </div>
                {!!values.elevator_exist_from && (
                  <div className="new-table--row">
                    <div className="new-table--row--item">
                      <p>Elevator Access</p>
                    </div>
                    <div className="new-table--row">
                      <div className="new-table--row--item">
                        <TimeSelect
                          name="elevator_reservation_from_start"
                          value={values['elevator_reservation_from_start']}
                          interval={60}
                          placeholder="Start time"
                          error={isValidHandler ? isValidHandler('elevator_reservation_from_start') : false}
                          onChange={({ name, date }) => setFieldValue(name, formatTimeAPMeridiem(date))}
                        />
                      </div>
                      <div className="new-table--row--item">
                        <TimeSelect
                          name="elevator_reservation_from_end"
                          value={values['elevator_reservation_from_end']}
                          interval={60}
                          placeholder="End time"
                          error={isValidHandler ? isValidHandler('elevator_reservation_from_end') : false}
                          onChange={({ name, date }) => setFieldValue(name, formatTimeAPMeridiem(date))}
                        />
                      </div>
                    </div>
                  </div>
                )}
                <div className="new-table--row">
                  <div className="new-table--row--item w70">
                    <p>Which floor?</p>
                  </div>
                  <div className="new-table--row--item w30">
                    <Input
                      name="elevator_from_floor"
                      type="number"
                      value={values['elevator_from_floor']}
                      placeholder="0"
                      onChange={handleChange}
                      onKeyDown={preventArrowUpDown}
                    />
                  </div>
                </div>
                <div className="new-table--row">
                  <div className="new-table--row--item w70">
                    <p>What is a distance from apartment door to the elevator - (Yards):</p>
                  </div>
                  <div className="new-table--row--item w30">
                    <Input
                      name="distance_elevator_apartment_from"
                      type="number"
                      value={values['distance_elevator_apartment_from']}
                      placeholder="0"
                      onChange={handleChange}
                      onKeyDown={preventArrowUpDown}
                    />
                  </div>
                </div>
                <div className="new-table--row">
                  <div className="new-table--row--item w70">
                    <p>What is the distance from elevator to the loading dock/truck parking - (Yards):</p>
                  </div>
                  <div className="new-table--row--item w30">
                    <Input
                      name="distance_elevator_parking_from"
                      type="number"
                      value={values['distance_elevator_parking_from']}
                      placeholder="0"
                      onChange={handleChange}
                      onKeyDown={preventArrowUpDown}
                    />
                  </div>
                </div>
              </Fragment>
            )}
            <div className="new-table--row">
              <div className="new-table--row--item w40">
                <p>Need floor/wall protection?</p>
              </div>
              <div className="new-table--row--item w60">
                <div className="d-flex justify-content-between">
                  <div className="w20">
                    <CheckBox
                      className="justify-content-between"
                      name="hardwood_protect_from"
                      label="Hardwood"
                      checked={!!values['hardwood_protect_from']}
                      onChange={(e) => setFieldValue('hardwood_protect_from', e.target.checked ? 1 : 0)}
                    />{' '}
                  </div>
                  <div className="w20">
                    <CheckBox
                      className="justify-content-between"
                      name="marble_protect_from"
                      label="Marble"
                      checked={!!values['marble_protect_from']}
                      onChange={(e) => setFieldValue('marble_protect_from', e.target.checked ? 1 : 0)}
                    />{' '}
                  </div>
                  <div className="w20">
                    <CheckBox
                      className="justify-content-between"
                      name="carpet_protect_from"
                      label="Carpet"
                      checked={!!values['carpet_protect_from']}
                      onChange={(e) => setFieldValue('carpet_protect_from', e.target.checked ? 1 : 0)}
                    />{' '}
                  </div>
                </div>
              </div>
            </div>
            <div className="new-table--row">
              <div className="new-table--row--item">
                <CheckBox
                  className="justify-content-between"
                  name="hoisting_from"
                  label="Need Hoisting of heavy items?"
                  checked={values['hoisting_from'] === 1}
                  onChange={(e) => setFieldValue('hoisting_from', e.target.checked ? 1 : 0)}
                />
              </div>
            </div>
            {!!values.hoisting_from && (
              <div className="new-table--row">
                <div className="new-table--row--item">
                  <TextArea name="hoisting_note_from" placeholder="Note..." value={values['hoisting_note_from']} onChange={handleChange} />
                </div>
              </div>
            )}

            <div className="new-table--row">
              <div className="new-table--row--item w70">
                <p>Stairs?</p>
              </div>
              <div className="new-table--row--item w30">
                <Select
                  options={stairsSet}
                  value={values['stairs_from']}
                  placeholder="Stairs in building from"
                  name="stairs_from"
                  onChange={({ value }) => setFieldValue('stairs_from', value)}
                />
              </div>
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <div className="new-table--row">
              <div className="new-table--row--item">
                <p>Type of building</p>
              </div>
              <div className="new-table--row--item select">
                <Select
                  options={buildingTypes}
                  value={Number(values['building_type_to'])}
                  placeholder="Building type to"
                  name="building_type_to"
                  onChange={({ value }) => setFieldValue('building_type_to', value)}
                />
              </div>
            </div>
            {[1, 2, 3, 6].includes(values.building_type_to) && (
              <Fragment>
                <div className="new-table--row">
                  <div className="new-table--row--item">
                    <p>Building Access</p>
                  </div>
                  <div className="new-table--row">
                    <div className="new-table--row--item select">
                      <TimeSelect
                        name="building_access_to_start"
                        value={values['building_access_to_start']}
                        interval={60}
                        placeholder="Start time"
                        onChange={({ name, date }) => setFieldValue(name, formatTimeAPMeridiem(date))}
                      />
                    </div>
                    <div className="new-table--row--item select">
                      <TimeSelect
                        name="building_access_to_end"
                        value={values['building_access_to_end']}
                        interval={60}
                        placeholder="End time"
                        onChange={({ name, date }) => setFieldValue(name, formatTimeAPMeridiem(date))}
                      />
                    </div>
                  </div>
                </div>
                <div className="new-table--row">
                  <div className="new-table--row--item">
                    <CheckBox
                      className="justify-content-between"
                      name="elevator_exist_to"
                      label="Elevator Exist?"
                      checked={Number(values['elevator_exist_to']) === 1}
                      onChange={(e) => setFieldValue('elevator_exist_to', e.target.checked ? 1 : 0)}
                    />
                  </div>
                </div>
                {!!values.elevator_exist_to && (
                  <div className="new-table--row">
                    <div className="new-table--row--item">
                      <p>Elevator Access</p>
                    </div>
                    <div className="new-table--row">
                      <div className="new-table--row--item">
                        <TimeSelect
                          name="elevator_reservation_to_start"
                          value={values['elevator_reservation_to_start']}
                          interval={60}
                          placeholder="Start time"
                          error={isValidHandler ? isValidHandler('elevator_reservation_to_start') : false}
                          onChange={({ name, date }) => setFieldValue(name, formatTimeAPMeridiem(date))}
                        />
                      </div>
                      <div className="new-table--row--item">
                        <TimeSelect
                          name="elevator_reservation_to_end"
                          value={values['elevator_reservation_to_end']}
                          interval={60}
                          placeholder="End time"
                          error={isValidHandler ? isValidHandler('elevator_reservation_to_end') : false}
                          onChange={({ name, date }) => setFieldValue(name, formatTimeAPMeridiem(date))}
                        />
                      </div>
                    </div>
                  </div>
                )}

                <div className="new-table--row">
                  <div className="new-table--row--item w70">
                    <p>Which floor?</p>
                  </div>
                  <div className="new-table--row--item w30">
                    <Input
                      name="elevator_to_floor"
                      type="number"
                      value={values['elevator_to_floor']}
                      placeholder="0"
                      onChange={handleChange}
                      onKeyDown={preventArrowUpDown}
                    />
                  </div>
                </div>

                <div className="new-table--row">
                  <div className="new-table--row--item w70">
                    <p>What is a distance from apartment door to the elevator - (Yards):</p>
                  </div>
                  <div className="new-table--row--item w30">
                    <Input
                      name="distance_elevator_apartment_to"
                      type="number"
                      value={values['distance_elevator_apartment_to']}
                      placeholder="0"
                      onChange={handleChange}
                      onKeyDown={preventArrowUpDown}
                    />
                  </div>
                </div>

                <div className="new-table--row">
                  <div className="new-table--row--item w70">
                    <p>What is the distance from elevator to the loading dock/truck parking - (Yards):</p>
                  </div>
                  <div className="new-table--row--item w30">
                    <Input
                      name="distance_elevator_parking_to"
                      type="number"
                      value={values['distance_elevator_parking_to']}
                      placeholder="0"
                      onChange={handleChange}
                      onKeyDown={preventArrowUpDown}
                    />
                  </div>
                </div>
              </Fragment>
            )}

            <div className="new-table--row">
              <div className="new-table--row--item w40">
                <p>Need floor/wall protection?</p>
              </div>
              <div className="new-table--row--item w60">
                <div className="d-flex justify-content-between">
                  <div className="w20">
                    <CheckBox
                      className="justify-content-between"
                      name="hardwood_protect_to"
                      label="Hardwood"
                      checked={!!values['hardwood_protect_to']}
                      onChange={(e) => setFieldValue('hardwood_protect_to', e.target.checked ? 1 : 0)}
                    />
                  </div>
                  <div className="w20">
                    <CheckBox
                      className="justify-content-between"
                      name="marble_protect_to"
                      label="Marble"
                      checked={!!values['marble_protect_to']}
                      onChange={(e) => setFieldValue('marble_protect_to', e.target.checked ? 1 : 0)}
                    />
                  </div>
                  <div className="w20">
                    <CheckBox
                      className="justify-content-between"
                      name="carpet_protect_to"
                      label="Carpet"
                      checked={!!values['carpet_protect_to']}
                      onChange={(e) => setFieldValue('carpet_protect_to', e.target.checked ? 1 : 0)}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="new-table--row">
              <div className="new-table--row--item">
                <CheckBox
                  className="justify-content-between"
                  name="hoisting_to"
                  label="Need Hoisting of heavy items?"
                  checked={values['hoisting_to'] === 1}
                  onChange={(e) => setFieldValue('hoisting_to', e.target.checked ? 1 : 0)}
                />
              </div>
            </div>
            {!!values.hoisting_to && (
              <div className="new-table--row">
                <div className="new-table--row--item">
                  <TextArea name="hoisting_note_to" placeholder="Note..." values={values['hoisting_note_to']} onChange={handleChange} />
                </div>
              </div>
            )}

            <div className="new-table--row">
              <div className="new-table--row--item w70">
                <p>Stairs?</p>
              </div>
              <div className="new-table--row--item w30">
                <Select
                  options={stairsSet}
                  value={values['stairs_to']}
                  placeholder="Stairs in building to"
                  name="stairs_to"
                  onChange={({ value }) => setFieldValue('stairs_to', value)}
                />
              </div>
            </div>
          </Fragment>
        )}

        <div className="new-table--row">
          <div className="new-table--row--item w70">
            <p>How many bedroom sets you have?</p>
          </div>
          <div className="new-table--row--item w30">
            <Select
              options={bedroomSets}
              value={values['bedroom_sets']}
              placeholder="Bedroom sets"
              name="bedroom_sets"
              onChange={({ value }) => setFieldValue('bedroom_sets', value)}
            />
          </div>
        </div>
        <div className="new-table--row">
          <div className="new-table--row--item">
            <CheckBox
              className="justify-content-between"
              name="apt_finished"
              label="Inventory list?"
              checked={values['apt_finished'] === 1}
              onChange={(e) => setFieldValue('apt_finished', e.target.checked ? 1 : 0)}
            />
          </div>
        </div>
        {!!values.apt_finished && (
          <div className="new-table--row">
            <div className="new-table--row--item">
              <TextArea name="apt_note" placeholder="Note..." value={values['apt_note']} onChange={handleChange} />
            </div>
          </div>
        )}
        <div className="new-table--row">
          <div className="new-table--row--item">
            <CheckBox
              className="justify-content-between"
              name="packing"
              label="Are you packing miscellaneous items yourself?"
              checked={values['packing'] === 1}
              onChange={(e) => setFieldValue('packing', e.target.checked ? 1 : 0)}
            />
          </div>
        </div>
        {!!values.packing && (
          <div className="new-table--row">
            <div className="new-table--row--item">
              <TextArea name="packing_note" placeholder="Packing Note..." value={values['packing_note']} onChange={handleChange} />
            </div>
          </div>
        )}
        <div className="new-table--row">
          <div className="new-table--row--item">
            <CheckBox
              className="justify-content-between"
              name="fragile"
              label="Do you have any fragile items such as mirror, tv, glass, picture, paintings, or other?"
              checked={values['fragile'] === 1}
              onChange={(e) => setFieldValue('fragile', e.target.checked ? 1 : 0)}
            />
          </div>
        </div>
        {!!values.fragile && (
          <div className="new-table--row">
            <div className="new-table--row--item">
              <TextArea name="fragile_note" placeholder="Fragile Note..." value={values['fragile_note']} onChange={handleChange} />
            </div>
          </div>
        )}
        <div className="new-table--row">
          <div className="new-table--row--item">
            <CheckBox
              className="justify-content-between"
              name="wrap"
              label="Will you be able to provide bubble wrap, tv/mirror/picture box and shrink wrap to avoid
                charges for packing supplies?"
              checked={values['wrap'] === 1}
              onChange={(e) => setFieldValue('wrap', e.target.checked ? 1 : 0)}
            />
          </div>
        </div>
        {!!values.wrap && (
          <div className="new-table--row">
            <div className="new-table--row--item">
              <TextArea name="wrap_note" placeholder="Wrap Note..." value={values['wrap_note']} onChange={handleChange} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AdditionalInfo;

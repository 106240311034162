class APIError extends Error {
  constructor(message, config, code, request, response, isAxiosError) {
    super();

    this.name = 'ResponseError';

    this.code = code;
    this.config = config;
    this.request = request;
    this.response = response;

    this.isAxiosError = isAxiosError;

    this.isConnectAborted = ['ECONNABORTED'].includes(code);
    this.inNetworkError = ['Network Error', 'timeout of 10000ms exceeded'].includes(message);
  }

  get responseData() {
    return this.response?.data || {};
  }
}

export { APIError as default };

const findBuilding = (list, obj) => {
  if (!list || !obj) return null;

  const address = obj?.address ?? obj?.full_address;
  const placeId = obj?.place_id;
  const street = obj?.street_number;
  const zip = obj?.zipcode;

  return list.find((item) => {
    if (placeId && item.place_id && placeId === item.place_id) {
      return true;
    }
    return (
      Number(item.building_zip) === Number(zip || 0) &&
      compareAddress(item.building_address, address) &&
      (street == null || RegExp('\\b' + street + '\\b').test(item.building_address))
    );
  });
};

const compareAddress = (address, searched_address) => {
  return searched_address
    .toLowerCase()
    .split(' ')
    .every((item) => address?.toLowerCase().includes(item));
};

export { findBuilding };

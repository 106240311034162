import React, { Fragment } from 'react';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import PackingTab from './PackingTab';
import { useInfo } from 'hooks/query/useJob';
import InventoryTab from './InventoryTab';
import LD from './LDTable';

import './style.scss';

const InventoryContainer = () => {
  const { id } = useParams();
  const { data } = useInfo(id);

  const { long_delivery_job, pickup_job, storage_job } = data?.related_jobs;

  const isStorageTypeJob = data?.job_info?.type === 3;

  const showLD = Boolean(long_delivery_job) || Boolean(pickup_job) || Boolean(storage_job);

  return (
    <Fragment>
      <div className={classNames('inventory-container')}>
        {!isStorageTypeJob && <PackingTab />}

        <InventoryTab />

        {showLD && <LD />}
      </div>
    </Fragment>
  );
};

export default InventoryContainer;

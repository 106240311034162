import React, { useMemo } from 'react';
import { Formik } from 'formik';
import Swal from 'sweetalert2';
import { useInfo } from 'hooks/query/useJob';
import Select from 'components/newComponents/Select';
import DateSelect from 'components/DateSelect';
import TimeSelect from 'components/TimeSelect';
import CheckBox from 'components/newComponents/CheckBox';
import Icon from 'components/Icon';
import DropdownMenu from 'components/DropdownMenu';
import { formatPhoneNumberPlusOneAndDigits } from 'helpers/phoneFormat';
import { formatFullDateTime } from 'helpers/dateHelper';
import { statusNumberToName } from 'helpers/statuses';
import { useModal } from 'context/modal';
import { getContractInfo } from './storageUtils';
import './styles.scss';

const StorageInfoForm = (props) => {
  const {
    id,
    jobInfo,
    movingInfo,
    dates,
    storage,
    storages,
    contract,
    storageData,
    jobInventoryData,
    changeStorage,
    changeStorageEndDate,
    updateStorageFee,
    onSendManualInvoice,
    onSendContractEmail,
    updateStorageRate,
    updateStorageTotalPrice,
  } = props;

  const {
    data: { related_jobs: relatedJobs },
  } = useInfo(id);

  const address = `${storage.address} ${storage.city} ${storage.state} ${storage.zip}, ${storage.phone}`;
  const contractLink = `${process.env.REACT_APP_API_URL}/api/documents/storage-contract/${jobInfo.hash_id}/show`;

  const inititalValues = useMemo(() => {
    const fees = {
      admin_fee: Number(storageData.admin_fee) ? storageData.admin_fee : storage.admin_fee,
      delivery_fee: Number(storageData.delivery_fee) ? storageData.delivery_fee : storage.delivery_fee,
      hold_fee: Number(storageData.hold_fee) ? storageData.hold_fee : storage.hold_fee,
      insurance_fee: Number(storageData.insurance_fee) ? storageData.insurance_fee : storage.insurance_fee,
      redelivery_fee: Number(storageData.redelivery_fee) ? storageData.redelivery_fee : storage.redelivery_fee,
      late_fee: Number(storageData.late_fee) ? Number(storageData.late_fee) + ' %' : Number(storage.late_fee) + ' %',
    };

    return {
      storage_rate: storageData.storage_rate,
      balance: storageData.balance,
      storages: storage.id,
      calc_type: storageData.calc_type,
      type: storageData.type,
      last_pay_date: '', // FIX
      current_amount: Number(storageData.current_amount).toFixed(2),
      job_inventory_total: jobInventoryData?.reduce((sum, item) => sum + parseInt(item.amount) * parseInt(item.qty), 0)?.toFixed(2),
      required_pickup: dates.required_pickup,
      storage_delivery: dates.storage_delivery,
      storage_due_date: dates.storage_due_date,
      late_fee: fees.late_fee,
      late_type: storageData.late_type,
      late_date: dates.late_date,
      admin_fee: fees.admin_fee,
      delivery_fee: fees.delivery_fee,
      hold_fee: fees.hold_fee,
      insurance_fee: fees.insurance_fee,
      redelivery_fee: fees.redelivery_fee,
      admin_fee_checked: Boolean(Number(storageData.admin_fee)),
      delivery_fee_checked: Boolean(Number(storageData.delivery_fee)),
      hold_fee_checked: Boolean(Number(storageData.hold_fee)),
      insurance_fee_checked: Boolean(Number(storageData.insurance_fee)),
      redelivery_fee_checked: Boolean(Number(storageData.redelivery_fee)),
    };
  }, [storageData, jobInventoryData, storage, dates]);

  const { open, modalNames, info, close } = useModal();
  const isTwilio = info(modalNames.twilio).visible;

  const handleCall = (phone) => {
    if (isTwilio) {
      close(modalNames.twilio);
    }

    setTimeout(() => {
      open(modalNames.twilio, { number: formatPhoneNumberPlusOneAndDigits(phone), callUp: true, companyID: jobInfo?.company_id });
    }, 0);
  };

  const handleSendContractEmail = () => {
    if (!Boolean(contract.email_sent) && !Boolean(contract.signed)) {
      onSendContractEmail();
    } else {
      Swal.fire({
        title: 'You have already sent Email',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Ok',
      });
    }
  };

  const handleEmail = (email) => {
    open(modalNames.newEmail, {
      job_id: id,
      type: 'new',
      email_to: email,
      company_id: jobInfo.company_id,
    });
  };

  const options = [
    { name: 'Cell Phone', value: movingInfo.cell_phone, clickFunc: () => handleCall(movingInfo.cell_phone) },
    { name: 'Home Phone', value: movingInfo?.home_phone, clickFunc: () => handleCall(movingInfo?.home_phone) },
    { name: 'Email', value: movingInfo?.email, clickFunc: () => handleEmail(movingInfo?.email) },
  ];

  return (
    <Formik enableReinitialize={true} initialValues={inititalValues}>
      {({ values, handleChange, setFieldValue, handleSubmit }) => {
        const handleFeesCheckbox = (name, data) => {
          const checked = values[name];

          if (!checked) {
            updateStorageFee({ ...data });
          } else {
            const [key] = Object.entries(data)[0];
            updateStorageFee({ [key]: '0.00' });
          }

          setFieldValue(name, !checked);
        };

        const handleBlur = (event) => {
          const { name, value } = event.target;

          setFieldValue(name, value);

          if (!value) return;

          updateStorageFee({ [name]: value });
        };

        const handleStorageRateBlur = (event) => {
          const { name, value } = event.target;

          setFieldValue(name, value);

          if (!value) return;

          updateStorageRate({ [name]: value, job_id: id, job_inventory_total: values.job_inventory_total });
        };

        const handleStorageBalanceBlur = (event) => {
          const { name, value } = event.target;

          setFieldValue(name, value);

          if (!value) return;

          updateStorageTotalPrice({ [name]: value, job_id: id });
        };

        return (
          <form onSubmit={handleSubmit} className="storage-info">
            <div className="new-table">
              <div className="new-table--row">
                <div className="new-table--row--item no-padding no-side-shadows">
                  <h3 className="header">Storage info</h3>
                </div>
                <div className="new-table--row--item no-side-shadows justify-content-end no-padding-y">
                  <button onClick={onSendManualInvoice} type="button" style={{ marginRight: 20 }}>
                    <Icon icon="cash" size="24" title="Send Manual Invoice" style={{ marginRight: 0 }} />
                  </button>

                  <a href={contractLink} target="_blank" rel="noopener noreferrer">
                    <Icon icon="doc_export_quote" size="24" title="Contract" style={{ marginRight: 0 }} />
                  </a>

                  <a
                    target="_blank"
                    rel="noreferrer noopener"
                    style={{ marginLeft: 20 }}
                    href={`https://dev2.imover.biz/api/documents/ld-contract/${relatedJobs?.ld_contract_id}/show`}
                  >
                    <Icon icon="download" className="tooltip-right" size={22} title="Long Distance Contract" style={{ margin: 0 }} />
                  </a>

                  <button onClick={handleSendContractEmail} type="button" style={{ marginLeft: 20 }}>
                    <Icon
                      icon="sign"
                      className={getContractInfo(contract).iconClassName}
                      size="24"
                      title={getContractInfo(contract).title}
                      style={{ marginRight: 0 }}
                      iconStyle={{ whiteSpace: 'initial', width: 100 }}
                    />
                  </button>
                </div>
              </div>
            </div>

            <div className="new-table">
              <div className="new-table--row">
                <div className="new-table--row--item no-side-shadows">
                  <Icon size={20} className="icon-grey" icon="name" style={{ marginRight: 20 }} />
                  <span>{movingInfo.customer_name}</span>
                </div>
                <div className="new-table--row--item w5 no-padding no-side-shadows">
                  <DropdownMenu
                    listStyle={{ right: 0 }}
                    button={{
                      iconName: 'put_calls',
                      iconTitle: 'Client contact info',
                      className: 'contact-info',
                      iconClassName: 'tooltip-right',
                      iconSize: 20,
                    }}
                    optionOnClick={() => 1}
                    detailsInfo={options}
                  />
                </div>
                <div className="new-table--row--item">
                  <Icon size={20} className="icon-grey" icon={statusNumberToName(jobInfo?.status)} style={{ marginRight: 20 }} />
                  <span>{jobInfo.title}</span>
                </div>

                <div className="new-table--row--item col-4 select">
                  <Icon icon="storage" className="icon-grey" style={{ marginRight: 20 }} size={20} />
                  <Select
                    options={storages}
                    value={values.storages}
                    name="storages"
                    isSearchable={false}
                    onChange={({ value }) => {
                      changeStorage(value);
                      setFieldValue('storages', value);
                    }}
                  />
                </div>
              </div>

              <div className="new-table--row">
                <div className="new-table--row--item no-side-shadows">
                  <Icon size={20} className="icon-grey" icon="text" style={{ marginRight: 20 }} />
                  <span>{address}</span>
                </div>
              </div>

              <div className="new-table--row">
                <div className="new-table--row--item no-side-shadows">
                  <CheckBox
                    right={false}
                    label="Delivery Fee"
                    name="delivery_fee_checked"
                    className="centered-checkbox justify-content-start"
                    checked={values['delivery_fee_checked']}
                    style={{ marginRight: 20, width: 16, height: 16 }}
                    onChange={() => handleFeesCheckbox('delivery_fee_checked', { delivery_fee: values['delivery_fee'] })}
                  />
                  {values.delivery_fee_checked && (
                    <input
                      name="delivery_fee"
                      type="text"
                      value={values['delivery_fee']}
                      className="col-2 no-padding"
                      disabled={!values['delivery_fee_checked']}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  )}
                </div>

                <div className="new-table--row--item no-side-shadows">
                  <CheckBox
                    right={false}
                    label="Redelivery Fee"
                    name="redelivery_fee_checked"
                    className="justify-content-start"
                    style={{ marginRight: 20, width: 16, height: 16 }}
                    checked={values['redelivery_fee_checked']}
                    onChange={() => handleFeesCheckbox('redelivery_fee_checked', { redelivery_fee: values['redelivery_fee'] })}
                  />
                  {values.redelivery_fee_checked && (
                    <input
                      name="redelivery_fee"
                      type="text"
                      value={values['redelivery_fee']}
                      className="col-2 no-padding"
                      disabled={!values['redelivery_fee_checked']}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  )}
                </div>

                <div className="new-table--row--item no-side-shadows">
                  <CheckBox
                    right={false}
                    label="Admin Fee"
                    name="admin_fee_checked"
                    className="justify-content-start"
                    style={{ marginRight: 20, width: 16, height: 16 }}
                    checked={values['admin_fee_checked']}
                    onChange={() => handleFeesCheckbox('admin_fee_checked', { admin_fee: values['admin_fee'] })}
                  />
                  {values.admin_fee_checked && (
                    <input
                      name="admin_fee"
                      type="text"
                      value={values['admin_fee']}
                      className="col-2 no-padding"
                      disabled={!values['admin_fee_checked']}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  )}
                </div>

                <div className="new-table--row--item no-side-shadows">
                  <CheckBox
                    right={false}
                    label="Hold Fee"
                    name="hold_fee_checked"
                    className="justify-content-start"
                    style={{ marginRight: 20, width: 16, height: 16 }}
                    checked={values['hold_fee_checked']}
                    onChange={() => handleFeesCheckbox('hold_fee_checked', { hold_fee: values['hold_fee'] })}
                  />
                  {values.hold_fee_checked && (
                    <input
                      name="hold_fee"
                      type="text"
                      value={values['hold_fee']}
                      className="col-2 no-padding"
                      disabled={!values['hold_fee_checked']}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  )}
                </div>

                <div className="new-table--row--item no-side-shadows">
                  <CheckBox
                    right={false}
                    label="Insurance Fee"
                    name="insurance_fee_checked"
                    className="justify-content-start"
                    style={{ marginRight: 20, width: 16, height: 16 }}
                    checked={values['insurance_fee_checked']}
                    onChange={() => handleFeesCheckbox('insurance_fee_checked', { insurance_fee: values['insurance_fee'] })}
                  />
                  {values.insurance_fee_checked && (
                    <input
                      name="insurance_fee"
                      type="text"
                      value={values['insurance_fee']}
                      className="col-2 no-padding"
                      disabled={!values['insurance_fee_checked']}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  )}
                </div>
              </div>

              <div className="new-table--row">
                <div className="new-table--row--item col-4 no-side-shadows light">
                  <span>Fees info job title</span>
                </div>
                <div className="new-table--row--item col-8 no-side-shadows light">
                  <span>Dates info</span>
                </div>
              </div>

              <div className="new-table--row">
                <div className="new-table--row--item col-4">
                  <Icon icon="rate_type" className="icon-grey" size={22} title="Storage Rate Type" style={{ marginRight: 20 }} />
                  <input
                    type="text"
                    disabled
                    className="no-padding"
                    style={{ textTransform: 'capitalize' }}
                    value={values['type']?.replace('_', ' ') + ' / ' + values['calc_type']?.replace('_', ' ')}
                  />
                </div>
                <div className="new-table--row--item col-4">
                  <Icon icon="job_start" className="icon-grey" size={22} title="Start Date" style={{ marginRight: 20 }} />
                  <div className="date-select-wrap">
                    <DateSelect name="required_pickup" minDate={new Date()} className="no-padding" selected={values.required_pickup} disabled />
                  </div>
                </div>
                <div className="new-table--row--item col-4">
                  <TimeSelect name="required_pickup" disabled className="no-padding" value={values.required_pickup} />
                </div>
              </div>

              <div className="new-table--row">
                <div className="new-table--row--item col-4">
                  <Icon icon="cash" className="icon-grey" size={22} title="Storage Rate" style={{ marginRight: 20 }} />
                  <input
                    type="text"
                    name="storage_rate"
                    className="no-padding"
                    placeholder="Storage Rate"
                    value={values.storage_rate}
                    onChange={handleChange}
                    onBlur={handleStorageRateBlur}
                  />
                </div>

                <div className="new-table--row--item col-4">
                  <Icon icon="job_stop" className="icon-grey" size={22} title="End Date" style={{ marginRight: 20 }} />
                  <div className="date-select-wrap">
                    <DateSelect
                      name="storage_delivery"
                      className="no-padding"
                      minDate={new Date()}
                      selected={values.storage_delivery}
                      onChange={({ name, date }) => {
                        changeStorageEndDate({ [name]: formatFullDateTime(date) });
                        setFieldValue(name, formatFullDateTime(date));
                      }}
                    />
                  </div>
                </div>

                <div className="new-table--row--item col-4">
                  <TimeSelect
                    name="storage_delivery"
                    className="no-padding"
                    value={values.storage_delivery}
                    onChange={({ name, date }) => {
                      changeStorageEndDate({ [name]: formatFullDateTime(date) });
                      setFieldValue(name, formatFullDateTime(date));
                    }}
                  />
                </div>
              </div>

              <div className="new-table--row">
                <div className="new-table--row--item col-4">
                  <Icon icon="task_important" className="icon-grey" size={22} title="Late Fee" style={{ marginRight: 20 }} />
                  <input name="late_fee" type="text" disabled className="no-padding" value={values.late_fee} />
                </div>

                <div className="new-table--row--item col-4">
                  <Icon icon="due_date" className="icon-grey" size={22} title="Due Date" style={{ marginRight: 20 }} />
                  <div className="date-select-wrap">
                    <DateSelect name="storage_due_date" disabled className="no-padding" minDate={new Date()} selected={values.storage_due_date} />
                  </div>
                </div>

                <div className="new-table--row--item col-4"></div>
              </div>

              <div className="new-table--row">
                <div className="new-table--row--item col-4">
                  <Icon icon="money_back" className="icon-grey" size={22} title="Total Price" style={{ marginRight: 20 }} />
                  <input
                    type="text"
                    name="balance"
                    className="no-padding"
                    placeholder="Balance"
                    value={values.balance}
                    onChange={handleChange}
                    onBlur={handleStorageBalanceBlur}
                  />
                </div>
                <div className="new-table--row--item col-4">
                  <Icon icon="late_date" className="icon-grey" size={22} title="Late Date" style={{ marginRight: 20 }} />
                  <div className="date-select-wrap">
                    <DateSelect name="late_date" disabled minDate={new Date()} className="no-padding" selected={values['late_date']} />
                  </div>
                </div>
                <div className="new-table--row--item col-4"></div>
              </div>

              <div className="new-table--row">
                <div className="new-table--row--item col-4">
                  <Icon icon="current_balance" className="icon-grey" size={22} title="Current Balance" style={{ marginRight: 20 }} />
                  <input type="text" className="no-padding" disabled name="current_amount" placeholder="Balance" value={values.current_amount} />
                </div>
                <div className="new-table--row--item col-4">
                  <Icon icon="calendar_dot" className="icon-grey" size={22} title="Last pmt Date" style={{ marginRight: 20 }} />
                  <input type="text" className="no-padding" disabled name="last_pay_date" placeholder="Date" value={values.last_pay_date} />
                </div>
                <div className="new-table--row--item col-4"></div>
              </div>

              <div className="new-table--row">
                <div className="new-table--row--item col-4">
                  <Icon icon="full_packing" className="icon-grey" size={22} title="CF Total" style={{ marginRight: 20 }} />
                  <input
                    type="text"
                    className="no-padding"
                    disabled
                    name="job_inventory_total"
                    placeholder="CF Total"
                    value={values.job_inventory_total}
                  />
                </div>
                <div className="new-table--row--item col-4"></div>
                <div className="new-table--row--item col-4"></div>
              </div>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default StorageInfoForm;

import React from 'react';
import MaskedInput from 'react-maskedinput';
import { useField } from 'formik';
import { preventArrowUpDown } from 'utils/preventArrowUpDown';

const FormikInput = (props) => {
  const { mask, newChange, name, datalist, datalistId, ...rest } = props;
  const [field, meta] = useField(props);

  const handleChange = (e) => {
    field.onChange(e);
    if (newChange) newChange(e.target.value);
  };

  return (
    <label style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', position: 'relative' }}>
      {mask ? (
        <MaskedInput name={field.name} style={{ padding: 0, margin: 0 }} mask={mask} onBlur={props.onBlur} {...rest} onChange={handleChange} />
      ) : (
        <input list={datalistId} style={{ padding: 0, margin: 0 }} {...rest} {...field} onChange={handleChange} onKeyDown={preventArrowUpDown} />
      )}
      {datalist?.length > 0 && (
        <datalist id={datalistId}>
          {datalist &&
            datalist.slice(0, 10).map((item, index) => (
              <option value={item.building_name} key={index}>
                {item.building_address + ' ' + item.building_city}
              </option>
            ))}
        </datalist>
      )}
      {meta.error && meta.touched && (
        <span style={{ position: 'absolute', bottom: -20 }} className="error">
          {meta.error}
        </span>
      )}
    </label>
  );
};
export default FormikInput;

import Swal from 'sweetalert2';

const showError = (e, cb) => {
  Swal.fire({
    position: 'top-end',
    icon: 'error',
    title: e?.message || e?.error,
    showConfirmButton: false,
    timer: 1500,
  }).then(() => typeof cb === 'function' && cb());
  throw e;
};

export default showError;

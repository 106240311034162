import React, { useEffect, useMemo, useCallback, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { useInfo, useTabInfo, useCommunicationsList } from 'hooks/query/useJob';
import { useEmailSend, useSendComposedEmail } from 'hooks/query/useMail';
import { useReadCommunication } from 'hooks/query/useJob';
import { communicationsTab } from 'helpers/constants';
import { useFilesSave } from 'hooks/query/useCommon';
import { useSend } from 'hooks/query/useSMS';
import { alert } from 'components/Alerts';
import { useModal } from 'context/modal';
import Select from 'components/newComponents/Select';
import Icon from 'components/Icon';

const JobSendMessages = ({ type }) => {
  const { id, tab } = useParams();
  const { modalNames, open, info, close } = useModal();
  const isMounted = useRef(false);

  const isTwilio = info(modalNames.twilio).visible;

  const {
    data: { job_info: jobInfo },
  } = useInfo(id);

  const {
    data: { moving_info: movingInfo },
  } = useTabInfo(id, tab);

  const { mutate: sendSMS } = useSend(id, type);
  const { mutate: sendMail } = useEmailSend(id, tab, type);
  const { mutate: saveFiles } = useFilesSave();
  const { mutate: sendComposedMail } = useSendComposedEmail(id, tab, type);
  const { mutate: readCommunication } = useReadCommunication(id, tab);
  const { data: communications } = useCommunicationsList(id, type);

  const smsTemplatesOptions = useMemo(() => {
    if (!communications) return [];
    return communications['sms_templates'].map((e) => ({ label: e.name, value: e.id }));
  }, [communications]);

  const mailTemplatesOptions = useMemo(() => {
    if (!communications) return [];
    return communications['mail_templates'].map((e) => ({ label: e.title, value: e.slug }));
  }, [communications]);

  useEffect(() => (isMounted.current ? readCommunication({ job_id: id }) : (isMounted.current = true)), [readCommunication, id]);

  const { values, setFieldValue, handleChange } = useFormik({
    initialValues: {
      subject: '',
      message: '',
      mail_message: '',
      image: null,
      imagePreviewUrl: null,
      sms_template: null,
      mail_template: null,
      attachments: [],
      number: '+1' + movingInfo.from.home_phone.replace(/[^0-9]/g, ''),
    },
  });

  const sendSMSHandler = useCallback(() => {
    if (+jobInfo.agent_id === 0) {
      return alert({ text: 'Please assign agent to send sms.' });
    }
    if (!values.message.length && !values.image) {
      return alert({ text: 'Message body is required!' });
    }

    let message = {
      job_id: id,
      message: values.message,
      to: '+1' + movingInfo.from.home_phone.replace(/[^0-9]/g, ''),
      image: values.image,
      type: 'default',
    };

    sendSMS(message, {
      onSuccess: () => {
        setFieldValue('message', '');
        setFieldValue('image', null);
        setFieldValue('imagePreviewUrl', null);
      },
    });
  }, [id, jobInfo, sendSMS, setFieldValue, values]);

  const handleImageChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let image = e.target.files[0];
    reader.onloadend = () => {
      setFieldValue('image', image);
      setFieldValue('imagePreviewUrl', reader.result);
    };
    reader.readAsDataURL(image);
  };

  const changeSmsTemplates = useCallback(
    (option) => {
      const item = communications['sms_templates'].find((el) => el.id === option.value);
      setFieldValue('message', item.text);
      setFieldValue('sms_template', item.value);
    },
    [setFieldValue, communications]
  );

  const changeMailTemplates = useCallback(
    (option) => {
      const item = communications['mail_templates'].find((el) => el.slug === option.value);

      if (type === communicationsTab.claim) {
        setFieldValue('subject', item.title);
        setFieldValue('mail_message', item.text);
        setFieldValue('mail_template', item.slug);
      } else {
        setFieldValue('mail_template', item.slug);
      }
    },
    [communications, setFieldValue, type]
  );

  const handleFileSelect = useCallback(
    (event) => {
      saveFiles(event.target.files, {
        onSuccess: ({ data }) => setFieldValue('attachments', [...values.attachments, ...data.attachments]),
      });
    },
    [saveFiles, setFieldValue, values.attachments]
  );

  const sendMailHandler = useCallback(() => {
    if (values.mail_template) {
      const data = {
        template: values.mail_template,
        include_pdf: false,
        job_id: id,
        template_html: '',
        edit_template: false,
        pdf: false,
        auto_email: jobInfo.auto_emails === 0,
        if_packing_size: false,
        email: movingInfo.from.email,
      };
      sendMail(data, {
        onSuccess: () => {
          setFieldValue('mail_template', '');
        }
      });
    } else {
      const info = {
        company_id: jobInfo.company_id,
        job_id: id,
        type: 'new',
        subject: values.subject,
        email_to: [movingInfo.from.email],
        message: values.mail_message,
        attachments: values.attachments,
      };
      sendComposedMail(info, {
        onSuccess: () => {
          setFieldValue('subject', '');
          setFieldValue('mail_message', '');
          setFieldValue('template', '');
          setFieldValue('attachments', []);
        },
      });
    }
  }, [id, jobInfo, movingInfo.from.email, sendComposedMail, sendMail, values]);

  const call = useCallback(() => {
    if (isTwilio) {
      close(modalNames.twilio);
    }
    setTimeout(() => {
      open(modalNames.twilio, { number: values?.number, callUp: true, companyID: jobInfo?.company_id });
    }, 0);
  }, [modalNames.twilio, isTwilio, close, open, jobInfo?.company_id, values.number]);

  return (
    <div className="new-table">
      <div className="new-table--row border-top">
        {/* ROW SMS */}
        <div className="new-table--row--item new-table--row--item__column no-padding">
          <div className="new-table--row--item no-padding">
            <textarea placeholder="Send sms from here" className="p20" rows={10} name="message" value={values.message} onChange={handleChange} />
          </div>

          <div className="new-table--row mh60">
            <div className="new-table--row--item no-padding w0">
              <label className="file p20">
                <Icon icon="clip" size={20} title="Choose file" style={{ margin: 0 }} />
                <input type="file" accept="image/jpeg,image/gif,image/png,image/bmp,application/pdf,image/x-eps" onChange={handleImageChange} />
              </label>
            </div>
            <div className="new-table--row--item w100 select">
              <Select
                name="sms_template"
                value={values['sms_template']}
                options={smsTemplatesOptions}
                placeholder="Select SMS Template"
                onChange={changeSmsTemplates}
              />
            </div>
            <div className="new-table--row--item w0 no-padding">
              <button onClick={sendSMSHandler} className="p20">
                <Icon icon="sms" title="Sms" size={20} style={{ margin: 0 }} />
              </button>
            </div>
            <div className="new-table--row--item w0 no-padding">
              <button className="p20" onClick={call}>
                <Icon icon="call" title="Call" size={20} style={{ margin: 0 }} />
              </button>
            </div>
          </div>

          {values.imagePreviewUrl && (
            <div className="new-table--row">
              <div className="new-table--row--item">
                <img src={values.imagePreviewUrl} alt="preview" />
              </div>
            </div>
          )}
        </div>

        {/* ROW MAILS */}

        <div className="new-table--row--item new-table--row--item__column no-padding">
          <div className="new-table--row--item p1 mh60">
            <input
              name="subject"
              placeholder="Subject:"
              className="mh60 p20"
              value={values.subject}
              disabled={!!values['mail_template']}
              onChange={handleChange}
            />
          </div>
          <div className="new-table--row--item p1">
            <textarea
              placeholder="Send email from here just type text"
              className="p20"
              rows={10}
              name="mail_message"
              value={values['mail_message']}
              disabled={!!values['mail_template']}
              onChange={handleChange}
            />
          </div>

          <div className="new-table--row no-padding mh60">
            <div className="new-table--row--item no-padding w0">
              <label className="file p20">
                <Icon icon="clip" size={20} className="" title="Choose attachments" style={{ margin: 0 }} />
                <input type="file" multiple="multiple" onChange={handleFileSelect} />
              </label>
            </div>
            <div className="new-table--row--item select w100">
              <Select
                name="mail_template"
                value={values['mail_template']}
                options={mailTemplatesOptions}
                placeholder="Select MAIL Template"
                disabled={values['mail_message'] !== '' || values.subject !== ''}
                onChange={changeMailTemplates}
              />
            </div>
            <div className="new-table--row--item no-padding w0">
              <button type="button" className="p20" onClick={() => open(modalNames.email)}>
                <Icon icon="email_edit" size={20} title="Email" style={{ margin: 0 }} />
              </button>
            </div>
            <div className="new-table--row--item w0 no-padding">
              <button type="button" className="p20" onClick={sendMailHandler}>
                <Icon icon="send" title="Send email" size={20} style={{ margin: 0 }} />
              </button>
            </div>
          </div>

          {Object.keys(values.attachments).length > 0 && (
            <div className="new-table--row">
              <div className="new-table--row--item">
                <p style={{ minHeight: 80, width: '100%' }}>Attachments: {Object.keys(values.attachments).length} files attached</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default JobSendMessages;

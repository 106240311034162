import React, { useMemo, memo } from 'react';
import { useTabInfo, useInfo } from 'hooks/query/useJob';
import { useLocation } from 'react-router-dom';
import { matchPath } from 'react-router';
import { routePaths } from 'config/routes';
import { useAuth } from 'context/auth';
import { useDefault } from 'hooks/query/useCommon';
import TaskItem from './TaskItem';
import TaskCreateForm from './TasksCreateForm';

const TableHeader = memo(() => (
  <div className="new-table--row bcg-light">
    <div className="new-table--row--item w5 no-side-shadows justify-content-center">
      <b>Note #</b>
    </div>
    <div className="new-table--row--item w15 no-side-shadows justify-content-center">
      <b>Description</b>
    </div>
    <div className="new-table--row--item w15 no-side-shadows justify-content-center">
      <b>Type</b>
    </div>
    <div className="new-table--row--item w20 no-side-shadows justify-content-center">
      <b>Date Entered</b>
    </div>
    <div className="new-table--row--item w15 no-side-shadows justify-content-center">
      <b>Due Date</b>
    </div>
    <div className="new-table--row--item w15 no-side-shadows justify-content-center">
      <b>Name</b>
    </div>
    <div className="new-table--row--item w10 no-side-shadows justify-content-center" />
  </div>
));

const TasksTab = () => {
  const { pathname } = useLocation();

  const {
    params: { id, tab },
  } = matchPath(pathname, { path: routePaths.JOBS });
  const { user } = useAuth();

  const {
    data: { job_info: jobInfo },
  } = useInfo(id);

  const {
    data: { tasks },
  } = useTabInfo(id, tab);

  const {
    data: { users },
  } = useDefault();

  const prepared = useMemo(() => {
    if (!tasks) return [];
    return tasks
      .sort((a, b) => a.id - b.id)
      .map((task) => {
        const author = users.all.find((user) => user.id === task.author_id);
        return { ...task, author_id: author?.id || null };
      });
  }, [tasks, users]);

  return (
    <div className="new-table modal">
      <TaskCreateForm jobId={jobInfo.id} userId={user.id} companyId={jobInfo.company_id} />
      <TableHeader />
      {prepared.map((item, index) => (
        <TaskItem key={item.id} number={index + 1} task={item} userId={user.id} jobId={jobInfo.id} />
      ))}
    </div>
  );
};

export default TasksTab;

import React, { Fragment } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import getAbbr from 'helpers/Abbr';
import { statusNumberToName } from 'helpers/statuses';
import getTitle from 'helpers/GetTitle';
import { useDefault } from 'hooks/query/useCommon';
import ReportInfo from './ReportInfo';
import Icon from 'components/Icon';

const ReportJob = ({ data, isActive, onClick }) => {
  const { data: defaultData } = useDefault();

  const companiesABBR = defaultData?.companies_abbr;
  const paymestStatuses = defaultData?.statuses.payment_types;

  const paymentsTypes = {};
  data.payments.forEach((payment) => {
    paymentsTypes[payment.type] = paymestStatuses[payment.type];
  });

  return (
    <Fragment>
      <div className="reports-table" onClick={onClick} style={{ cursor: 'pointer' }}>
        <div className="reports-table">
          <div className={classNames('reports-table-row', { 'reports-select': isActive })}>
            <div className="reports-table-column reports-table-column--12">
              <Icon size={20} style={{ marginRight: 10 }} title={getTitle(data.job_status)} icon={statusNumberToName(data.job_status)} />
              {data.job_title}
            </div>
            <div className="reports-table-column reports-table-column--12">{getAbbr(data.company_id, companiesABBR)}</div>
            <div className="reports-table-column reports-table-column--12">{moment.unix(data.start_date).format('L')}</div>
            <div className="reports-table-column reports-table-column--12">{data.hours}</div>
            <div className="reports-table-column reports-table-column--12">{data.distance}</div>
            <div className="reports-table-column reports-table-column--12">${Number(data.hourly_rate)}</div>
            <div className="reports-table-column reports-table-column--12">${data?.total}</div>
            <div className="reports-table-column reports-table-column--12">${data?.expenses?.toFixed(2)}</div>
            <div className="reports-table-column reports-table-column--12">${(data.packing_commission + data?.salary).toFixed(2)}</div>
            <div className="reports-table-column reports-table-column--12">${data?.commission?.toFixed(2)}</div>
            <div className="reports-table-column reports-table-column--12">${data?.profit.toFixed(2)}</div>
            <div className="reports-table-column reports-table-column--12">{Object.values(paymentsTypes).join(' / ')}</div>
          </div>
        </div>
      </div>

      {isActive && <ReportInfo id={data?.job_id} paymentStatuses={paymestStatuses} />}
    </Fragment>
  );
};

export default ReportJob;

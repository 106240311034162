const getTitle = (status, partner_name = null) => {
  let title;
  switch (+status) {
    case 1:
      title = 'Assigned';
      break;
    case 2:
      title = 'Booked';
      break;
    case 3:
      title = 'Confirmed';
      break;
    case 4:
      title = 'In Progress';
      break;
    case 5:
      title = 'Delivered';
      break;
    case 6:
      title = 'Completed';
      break;
    case 7:
      title = 'Follow up';
      break;
    case 8:
      title = 'Visual';
      break;
    case 10:
      if (partner_name) {
        title = partner_name;
      } else {
        title = '';
      }
      break;
    case 11:
      title = 'Rescheduled';
      break;
    case 12:
      title = 'Reassigned';
      break;
    case 14:
      title = 'In Storage';
      break;
    case 16:
      title = 'Out Storage';
      break;
    case 17:
      title = 'Unconfirmed';
      break;
    case 18:
      title = 'Canceled';
      break;
    case 19:
      title = 'Deleted';
      break;
    case 21:
      title = 'Lead';
      break;
    case 22:
      title = 'Lost';
      break;
    case 23:
      title = 'Dropped';
      break;
    case 24:
      title = 'Waiting for Delivery';
      break;
    default:
      title = '';
  }
  return title;
};

export default getTitle;

import React, { Fragment, useEffect, useCallback, useMemo } from 'react';
import { ModalButton } from 'components/newComponents/Buttons';
import { useLocation } from 'react-router-dom';
import { matchPath } from 'react-router';
import * as Yup from 'yup';
import { routePaths } from 'config/routes';
import { useFormik } from 'formik';
import { useInfo } from 'hooks/query/useJob';
import { useSendComposedEmail } from 'hooks/query/useMail';
import { useAuth } from 'context/auth';
// import { useEmailGet } from 'hooks/query/useMail';
import { useDefault, useFilesSave } from 'hooks/query/useCommon';
import Modal from 'components/newComponents/Modal';
import { useModal } from 'context/modal';
import MciEditor from 'components/MciEditor';
import Select from 'components/newComponents/Select';
import EmailChips from 'components/newComponents/EmailChips';
import Icon from 'components/Icon';

const attachmentAcceptFileTypes = [
  'image/jpeg',
  'image/gif',
  'image/png',
  'image/bmp',
  'application/pdf',
  'image/x-eps',
  'application/msword',
  '.csv',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
].join(',');

const Actions = () => {
  const { pathname } = useLocation();
  const { info, modalNames, close } = useModal();
  const { user } = useAuth();

  const { mutate: saveFiles } = useFilesSave();

  const params = info(modalNames.newEmail).params;
  const isVisible = info(modalNames.newEmail).visible;

  const { id, tab } = useMemo(() => {
    const { params: route = {} } = matchPath(pathname, { path: routePaths.JOBS }) ?? {};

    return { id: route.id ?? params.job_id, tab: route.tab ?? 'general' };
  }, [params.job_id, pathname]);

  const {
    data: { company: companies },
  } = useDefault();
  const { data: { job_info: jobInfo } = {}, isFetching, isIdle } = useInfo(id, { enabled: !!id });

  const data = useMemo(
    () => ({
      title: jobInfo?.title,
      company_id: jobInfo?.company_id ?? params?.company_id,
    }),
    [jobInfo, params]
  );

  const { mutate: composedEmailSend } = useSendComposedEmail(id, tab);

  const companyName = useMemo(() => {
    const company = companies.find((company) => company.id === data.company_id);
    return company?.company_name || '';
  }, [companies, data.company_id]);

  const mailAccounts = useMemo(() => {
    if (!user.emails?.length) return [];
    return user.emails.map((item) => ({ value: item.email, label: item.email }));
  }, [user.emails]);

  const jobEmailAccount = useMemo(() => {
    if (!user.emails?.length) return [];
    const company_user_email = user.emails.find((item) => item.company_id === data.company_id);
    return company_user_email ? company_user_email.email : null;
  }, [data.company_id, user.emails]);

  const saveHandler = useCallback(
    (values) => {
      const prepared = {
        ...values,
        attachments: JSON.stringify(values.attachments),
      };

      composedEmailSend({ job_id: id, ...prepared });

      close(modalNames.newEmail);
    },
    [composedEmailSend, id, close, modalNames.newEmail]
  );

  const { values, handleSubmit, setFieldValue, handleChange, errors, touched } = useFormik({
    initialValues: {
      type: params.type,
      cc: params?.email_cc ? params?.email_cc?.map((item) => item.mail) : [],
      email_to: params?.email_to ? [params?.email_to] : [],
      email_from: jobEmailAccount,
      message: null,
      template: null,
      files: null,
      job_id: id,
      attachments: [],
      subject: '',
    },
    validationSchema: Yup.object({
      subject: Yup.string().required('Type subject'),
      email_from: Yup.string().nullable().required('Type email'),
      email_to: Yup.array().compact().of(Yup.string().email()).required('Type email and press "Enter"').min(1, 'Type email and press "Enter"'),
    }),
    // enableReinitialize: true,
    onSubmit: saveHandler,
  });

  const isValidHandler = (name) => {
    if (errors[name] && touched[name]) return errors[name];
    return null;
  };

  const saveAttachmentsHandler = useCallback(
    (event) => {
      saveFiles(event.target.files, {
        onSuccess: ({ data }) => setFieldValue('attachments', [...values.attachments, ...data.attachments]),
      });
    },
    [saveFiles, setFieldValue, values.attachments]
  );

  useEffect(() => {
    const subjects = {
      new: '',
      reply: `Re: `,
      replyAll: `Re: `,
      forward: `FW: `,
    };

    const message = {
      new: '',
      reply: params?.selectedMail?.body,
      replyAll: params?.selectedMail?.body,
      forward: params?.selectedMail?.body,
    };

    const value = [subjects[params?.type], params?.selectedMail?.subject || ''].join('');

    if (!isFetching || isIdle) {
      setFieldValue('subject', value);
      setFieldValue('message', message[params?.type]);
    }
  }, [params, data, companyName, isFetching, isIdle, setFieldValue]);

  // const updateEmailInfo = useCallback(
  //   (email) => {
  //     if (['reply', 'replyAll'].includes(params?.type)) {
  //       const preparedData = {
  //         ...values,
  //         subject: `RE: ${email.subject}`,
  //         email_to: email.email_from,
  //         email_from: email.email_to,
  //         message: email.body,
  //       };
  //       setValues(preparedData);
  //       return;
  //     }

  //     if (params?.type === 'forward') {
  //       const preparedData = {
  //         ...values,
  //         subject: `FWD: ${email.subject}`,
  //         email_from: email.email_to,
  //         message: email.body,
  //       };

  //       setValues(preparedData);
  //       return;
  //     }
  //   },
  //   [params, setValues, values]
  // );

  // useEmailGet(params.messageId, { enabled: !!params?.messageId, onSuccess: updateEmailInfo });

  const titles = {
    new: 'New email',
    reply: 'Reply - ',
    replyAll: 'Reply all - ',
    forward: 'Forward page - ',
  };

  const handleChangeChips = useCallback(
    (data) => {
      if (!Array.isArray(data)) return;

      const value = data.map((item) => item.email);
      setFieldValue('email_to', value);
    },
    [setFieldValue]
  );

  const handleEditorChange = useCallback((content) => setFieldValue('message', content), [setFieldValue]);

  return (
    <Fragment>
      <Modal show={isVisible} style={{ width: 900 }} onHide={() => close(modalNames.newEmail)} header={`${titles[params?.type]} ${data.title || ''}`}>
        <form onSubmit={handleSubmit}>
          <div className="new-table modal">
            <div className="new-table--row">
              <div className="new-table--row--item select" style={{ position: 'relative' }}>
                <input type="text" name="subject" placeholder="Subject" value={values.subject ?? ''} onChange={handleChange} />
                {isValidHandler('subject') && (
                  <span style={{ position: 'absolute', bottom: 0, left: 20 }} className="error">
                    {isValidHandler('subject')}
                  </span>
                )}
              </div>
            </div>

            <div className="new-table--row">
              <div className="new-table--row--item select" style={{ position: 'relative' }}>
                From
                <Select
                  options={mailAccounts}
                  name="email_from"
                  value={values.email_from}
                  onChange={(option) => setFieldValue('email_from', option.value)}
                />
                {isValidHandler('email_from') && (
                  <span style={{ position: 'absolute', bottom: 0, left: 20 }} className="error">
                    {isValidHandler('email_from')}
                  </span>
                )}
              </div>
            </div>

            <div className="new-table--row">
              <div className="new-table--row--item select" style={{ position: 'relative' }}>
                To
                <EmailChips value={values.email_to.concat(values.cc)} onChange={handleChangeChips} />
                {isValidHandler('email_to') && (
                  <span style={{ position: 'absolute', bottom: 0, left: 20 }} className="error">
                    {isValidHandler('email_to')}
                  </span>
                )}
              </div>
            </div>

            <div className="new-table--row">
              <div className="new-table--row--item new-table--row--item__column select">
                <h3 className="header mb-3 no-padding">Message</h3>
                <MciEditor value={values.message} handleEditorChange={handleEditorChange} />
              </div>
            </div>

            <div className="new-table--row">
              <div className="new-table--row--item select">
                <label className="file">
                  Attach file
                  <input type="file" name="attachments[]" accept={attachmentAcceptFileTypes} multiple onChange={saveAttachmentsHandler} />
                </label>
              </div>
              <div className="new-table--row--item select">
                {values?.attachments?.length > 0 ? (
                  values.attachments.map((item) => (
                    <a
                      key={item.name}
                      className="p10 mt-2"
                      target="_blank"
                      rel="noopener noreferrer"
                      title="Download"
                      href={`${process.env.REACT_APP_API_URL}/api/downloadAttachments?path=${item.path}`}
                    >
                      <Icon icon="download" title="Download attachment" />
                      {item.name}
                    </a>
                  ))
                ) : (
                  <p className="disabled p10">No attachments</p>
                )}
              </div>
            </div>
          </div>

          <div className="footer-buttons">
            <ModalButton title="Cancel" variant="cancel" onClick={() => close(modalNames.newEmail)} />
            <ModalButton type="submit" title="Send" variant="confirm" />
          </div>
        </form>
      </Modal>
    </Fragment>
  );
};

export default Actions;

import React, { useEffect, Fragment, memo, useCallback, useMemo, useState, useRef } from 'react';
import { useLocation, useHistory, generatePath, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import moment from 'moment';
import { getParams } from 'helpers/jobs';
import { useAuth } from 'context/auth';
import { useReadCommunication as useReadJobCommunication } from 'hooks/query/useJob';
import {
  useCommunicationsList,
  useGetChat,
  useReadCommunication,
  useGetCustomerFormLink,
  useGetCustomerFormLinkTemp,
  useResultCall,
  useSend,
  useAssignMissedCall,
} from 'hooks/query/useCommunications';
import { useSuggestFollowUpChain } from 'hooks/useSuggestFollowUpChain';
import { useModal } from 'context/modal';
import { routePaths } from 'config/routes';
import DropdownMenu from 'components/DropdownMenu';
import Icon from 'components/Icon';
import './ChatCommunications.scss';

const Chat = memo(() => {
  const { search } = useLocation();
  const params = useMemo(() => getParams(search), [search]);

  const { user } = useAuth();
  const history = useHistory();
  const scrollPointRef = useRef();
  const { type } = useParams();
  const { open, close, info, modalNames } = useModal();
  const { mutate: sendSMS } = useSend(params);
  const { mutate: assignMissedCall } = useAssignMissedCall();
  const { mutate: readCommunication } = useReadCommunication();
  const { mutate: readJobCommunication } = useReadJobCommunication();

  const { onMutate: suggestFollowUpChain } = useSuggestFollowUpChain(null, 'general', 'followUp');

  const isTwilio = info(modalNames.twilio).visible;

  const [message, setMessage] = useState('');
  const resetMessage = () => setMessage('');

  const [imageData, setImageData] = useState({
    image: '',
    imagePreviewUrl: '',
  });

  const [data, setData] = useState({
    to: '',
    from: '',
    job_id: 0,
  });

  const { data: communications = [] } = useCommunicationsList({ ...params, type });

  const selectedItem = useMemo(() => {
    return communications.find((item) => item.id === +params.selected);
  }, [communications, params.selected]);

  const { data: chat = {}, refetch: refetchChat } = useGetChat(data, { onSuccess: resetMessage });

  // const handleScrollList = () => {
  //   if (scrollPointRef?.current) scrollPointRef.current.scrollIntoView();
  // };

  const readChatCommunications = useCallback(() => {
    readCommunication({
      from: selectedItem.from || selectedItem.customer_number,
      to: selectedItem.to || selectedItem.agent_number,
      user_id: user.id,
      type: 'chat',
    });
  }, [selectedItem, user.id, readCommunication]);

  const handleGetInfo = useCallback(() => {
    if (!params.selected || !selectedItem) return;

    const data = {};

    if (selectedItem.entity_type?.includes('sms')) {
      data.from = selectedItem.customer_number;
      data.to = selectedItem.agent_number;
    } else {
      data.from = selectedItem.from;
      data.to = selectedItem.to;
    }

    data.job_id = selectedItem.job_id ?? 0;

    setData(data);
  }, [params.selected, selectedItem]);

  useEffect(() => {
    handleGetInfo();
  }, [handleGetInfo, params.selected]);

  const handleOpenJob = useCallback(
    (e) => {
      e.stopPropagation();
      if (chat.job_info.id)
        history.push({
          pathname: generatePath(routePaths.JOBS, { id: chat.job_info.id, tab: 'general', type: 'all' }),
        });
    },
    [history, chat.job_info]
  );

  const handleOpenImage = (img) => {
    return Swal.fire({
      imageUrl: img,
      imageWidth: 800,
      imageAlt: 'Custom image',
      animation: false,
    });
  };

  const handleImageChange = useCallback((e) => {
    e.preventDefault();
    const reader = new FileReader();
    const image = e.target.files[0];

    reader.onloadend = () => {
      setImageData({
        image: image,
        imagePreviewUrl: reader.result,
      });
    };

    reader.readAsDataURL(image);
  }, []);

  const { mutate: getCustomerFormLink } = useGetCustomerFormLink();
  const { mutate: getCustomerFormLinkTemp } = useGetCustomerFormLinkTemp();

  const handleSendCustomerFormLink = () => {
    const data = {
      user_id: user.id,
      to: selectedItem.from,
      from: selectedItem.to,
    };

    getCustomerFormLink(data, {
      onSuccess: (res) => {
        Swal.fire({
          text: res.data.link,
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Send',
        }).then((result) => {
          if (result.isConfirmed) {
            try {
              getCustomerFormLinkTemp(data, {
                onError: (e) => console.log('ERROR', e),
                onSuccess: () => {
                  handleGetInfo();
                  // Swal.fire('Link sent');
                },
              });
            } catch (e) {
              console.log('ERROR', e);
            }
          }
        });
      },
    });
  };

  const { mutateAsync: saveCallResult } = useResultCall();

  const handleSaveCallResult = useCallback((call_result, call_sid) => saveCallResult({ call_result, call_sid }), [saveCallResult]);

  const handleSchedule = useCallback(
    async (sid) => {
      if (chat.job_info && chat.job_info.id) {
        await suggestFollowUpChain(chat.job_info.id);
        handleSaveCallResult('Schedule Follow Up', sid);
      }
    },
    [chat.job_info, handleSaveCallResult, suggestFollowUpChain]
  );

  const handleSend = useCallback(() => {
    let newMessage = {
      message,
      type: 'default',
      image: imageData.image,
      chat_id: params.selected,
      to: chat.customer_number,
      job_id: chat.job_info ? chat.job_info.id : '',
    };

    sendSMS(newMessage, {
      onSuccess: () => {
        setMessage('');
        setImageData({
          image: null,
          imagePreviewUrl: null,
        });
        refetchChat();
      },
    });

    if (chat.job_info && chat.job_info.id) {
      readJobCommunication({ job_id: chat.job_info.id });
    } else {
      readChatCommunications();
    }

    setTimeout(() => {
      handleGetInfo();
    }, 1000);
  }, [
    chat.customer_number,
    chat.job_info,
    handleGetInfo,
    imageData.image,
    message,
    params.selected,
    readJobCommunication,
    sendSMS,
    readChatCommunications,
    refetchChat,
  ]);

  const handleCall = useCallback(
    (company_id) => {
      assignMissedCall(
        { call_id: params.selected },
        {
          onSuccess: () => {
            if (chat.job_info && chat.job_info.id) {
              readJobCommunication(chat.job_info.id);
              return;
            }

            readChatCommunications();
          },
        }
      );

      if (isTwilio) {
        close(modalNames.twilio);
      }
      setTimeout(() => {
        open(modalNames.twilio, { number: chat.customer_number, companyID: company_id, callUp: true });
      }, 0);
    },
    [
      assignMissedCall,
      isTwilio,
      modalNames.twilio,
      chat.customer_number,
      chat.job_info,
      params.selected,
      open,
      close,
      readJobCommunication,
      readChatCommunications,
    ]
  );

  const handleGetCallIcon = useCallback((item) => {
    if (item.status === 'callback') {
      return { icon: 'call', title: 'Callback' };
    }
    if (item.type.match(new RegExp('voicemail', 'gi'))) {
      if (item.played > 0) {
        return { icon: 'assigned', title: 'Answered' };
      }

      if (item.recording.length > 0) {
        return { icon: 'dropped', title: 'Missed' };
      }
      return { icon: 'missed_call', title: 'Missed' };
    } else if (item.status === 'completed' && item.type === 'call') {
      return { icon: 'booked', title: 'Completed' };
    } else if (item.status === 'completed') {
      return { icon: 'confirmed', title: 'Answered' };
    }
    return { icon: 'task_unfinished', title: 'Missed' };
  }, []);

  const messages = useMemo(() => {
    if (!chat.list) return null;

    const jobID = chat?.job_info?.id;

    const data = [];
    chat.list
      .sort((a, b) => parseFloat(a.date) - parseFloat(b.date))
      .forEach((item, key) => {
        if (item.type.includes('call')) {
          const dropdownOptions = [
            {
              buttonClassName: 'link',
              clickFunc: () => handleSaveCallResult('Left Voicemail', item.sid),
              buttonText: 'LeftVoicemail',
            },
            {
              buttonClassName: 'link',
              clickFunc: () => handleSaveCallResult('Spoke to and gave quote', item.sid),
              buttonText: 'Spoke to and gave quote',
            },
            {
              buttonClassName: 'link',
              clickFunc: () => handleSchedule(item.sid),
              buttonText: 'Schedule Follow Up',
            },
            {
              buttonClassName: 'link',
              clickFunc: () => handleSaveCallResult('Too Expensive', item.sid),
              buttonText: 'Too Expensive',
            },
            // TODO handleConfirmed + handleCancel
            // {
            //   buttonClassName: 'link',
            //   clickFunc: () => handleConfirmed(item.sid),
            //   buttonText: 'Confirmed',
            // },
            // {
            //   buttonClassName: 'link',
            //   clickFunc: () => handleCancel(item.sid),
            //   buttonText: 'Canceled',
            // },
          ];

          if (!jobID) {
            dropdownOptions.splice(2, 1);
          }

          let icon_info = handleGetCallIcon(item);
          data.push(
            <li key={item.id + item.type + key} className={'messages__item messages-item--' + item.direction}>
              <div className="message-timeline-container">
                <Icon icon={icon_info.icon} title={icon_info.title} />
                {item.message !== '' && <div className="messages__time">{moment.unix(item.date).format('ddd MM/DD LT')}</div>}
              </div>
              <div className="messages__item--container">
                <div className="messages__text">
                  {item.recording.length > 0 && (
                    <audio controls id={item.id}>
                      <source src={item.recording} type="audio/mpeg" />
                    </audio>
                  )}
                  {item.recording.length === 0 && <p>Missed call</p>}
                </div>
                <div className="messages__text">
                  {item.recording.length !== 0 && (
                    <div className="position-relative width100">
                      <div className="col-10">{item.call_result}</div>
                      <DropdownMenu button={{ spanTitle: 'Result' }} options={dropdownOptions} />
                    </div>
                  )}
                </div>
              </div>
            </li>
          );
        } else {
          data.push(
            <li key={item.id + '_' + key} className={'messages__item messages-item messages-item--' + item.direction}>
              <div className="messages__text">
                {item.image !== '' && (
                  <p>
                    <span onClick={() => handleOpenImage(item.image)}>
                      <img width={200} src={item.image} alt="somepic" />
                    </span>
                  </p>
                )}
                {item.message !== '' && (
                  <Fragment>
                    <p>{item.message}</p>
                    <span className="messages__time">{moment(item.date).format('ddd DD/MM LT')}</span>
                  </Fragment>
                )}
              </div>
            </li>
          );
        }
      });

    return data;
  }, [chat.list, chat?.job_info?.id, handleGetCallIcon, handleSaveCallResult, handleSchedule]);

  return (
    <div className="b-chat">
      <div className="b-chat__content">
        {chat.job_info ? (
          <button type="button" className="b-chat__title-btn" onClick={handleOpenJob}>
            {chat.job_info.title}
          </button>
        ) : (
          <button type="button" style={{ height: 'auto' }} className="main p20 w100" onClick={() => open(modalNames.quickForm)}>
            Assign
          </button>
        )}
        <div className="b-chat__messages messages shadow">
          <ul className="messages__list">
            {messages}
            <span ref={scrollPointRef} />
          </ul>
        </div>

        <div className="new-table">
          <div className="new-table--row">
            <div className="new-table--row--item">
              <b>{chat.customer_number} </b>
            </div>
          </div>

          <div className="new-table--row">
            <div className="new-table--row new-table--row__column">
              <div className="new-table--row--item">
                {imageData.image !== '' ? <img src={imageData.imagePreviewUrl} alt="uploaded file" /> : <b>No image</b>}
              </div>

              <div className="new-table--row">
                <div className="new-table--row--item">
                  <label className="file">
                    Choose file
                    <input
                      type="file"
                      accept="image/jpeg,image/gif,image/png,image/bmp,application/pdf,image/x-eps"
                      onChange={(e) => handleImageChange(e)}
                    />
                  </label>
                </div>
              </div>
            </div>

            <div className="new-table--row new-table--row__column">
              <div className="new-table--row">
                <div className="new-table--row--item">
                  <textarea placeholder="Enter message:" value={message} onChange={(e) => setMessage(e.target.value)} />
                </div>
              </div>

              <div className="new-table--row">
                {selectedItem && selectedItem.job_id === 0 && (
                  <div className="new-table--row--item no-padding">
                    <button type="submit" className="main p20 flex-grow-1" onClick={handleSendCustomerFormLink}>
                      Send LINK
                    </button>
                  </div>
                )}
                <div className="new-table--row--item no-padding">
                  <button type="submit" className="main p20 flex-grow-1" onClick={handleSend}>
                    Send
                  </button>
                </div>
                <div className="new-table--row--item no-padding">
                  <button type="submit" className="main p20 flex-grow-1" onClick={() => handleCall(selectedItem.company_id)}>
                    Call
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Chat;

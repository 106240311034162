import React from 'react';
import './Icon.scss';

import { ReactComponent as None } from 'assets/icons/none.svg';
import { ReactComponent as Add } from 'assets/icons/add.svg';
import { ReactComponent as Remove } from 'assets/icons/remove.svg';
import { ReactComponent as AdditionalInfo } from 'assets/icons/additional_info.svg';
import { ReactComponent as Address } from 'assets/icons/address.svg';
import { ReactComponent as Bulb } from 'assets/icons/bulb.svg';
import { ReactComponent as Agent } from 'assets/icons/agent.svg';
import { ReactComponent as AlertLog } from 'assets/icons/alert_log.svg';
import { ReactComponent as ArriveTime } from 'assets/icons/arrive_time.svg';
import { ReactComponent as Assigned } from 'assets/icons/assigned.svg';
import { ReactComponent as Booked } from 'assets/icons/booked.svg';
import { ReactComponent as Booking } from 'assets/icons/booking.svg';
import { ReactComponent as BuildingName } from 'assets/icons/building_name.svg';
import { ReactComponent as Call } from 'assets/icons/call.svg';
import { ReactComponent as Canceled } from 'assets/icons/canceled.svg';
import { ReactComponent as Cash } from 'assets/icons/cash.svg';
import { ReactComponent as CellPhone } from 'assets/icons/cell_phone.svg';
import { ReactComponent as City } from 'assets/icons/city.svg';
import { ReactComponent as Claims } from 'assets/icons/claims.svg';
import { ReactComponent as CommissionValue } from 'assets/icons/commission_value.svg';
import { ReactComponent as Commission } from 'assets/icons/commission.svg';
import { ReactComponent as Communication } from 'assets/icons/communication.svg';
import { ReactComponent as Communications } from 'assets/icons/communications.svg';
import { ReactComponent as Completed } from 'assets/icons/completed.svg';
import { ReactComponent as Confirmed } from 'assets/icons/confirmed.svg';
import { ReactComponent as Delete } from 'assets/icons/delete.svg';
import { ReactComponent as DeleteCross } from 'assets/icons/delete_cross.svg';
import { ReactComponent as Delivered } from 'assets/icons/delivered.svg';
import { ReactComponent as DriverInfo } from 'assets/icons/driver_info.svg';
import { ReactComponent as Dropped } from 'assets/icons/dropped.svg';
import { ReactComponent as Email } from 'assets/icons/email.svg';
import { ReactComponent as EmailLogo } from 'assets/icons/email_logo.svg';
import { ReactComponent as EmailReminder } from 'assets/icons/email_reminder.svg';
import { ReactComponent as EstimatedStairs } from 'assets/icons/estimated_stairs.svg';
import { ReactComponent as EstimatedTotal } from 'assets/icons/estimated_total.svg';
import { ReactComponent as Estimator } from 'assets/icons/estimator.svg';
import { ReactComponent as Exp } from 'assets/icons/exp.svg';
import { ReactComponent as ExtraStop } from 'assets/icons/extra_stop.svg';
import { ReactComponent as ExtraStops } from 'assets/icons/extra_stops.svg';
import { ReactComponent as FinalTotal } from 'assets/icons/final_total.svg';
import { ReactComponent as FollowUp } from 'assets/icons/follow_up.svg';
import { ReactComponent as FromElevator } from 'assets/icons/from_elevator.svg';
import { ReactComponent as FuelCost } from 'assets/icons/fuel_cost.svg';
import { ReactComponent as FullDistance } from 'assets/icons/full_distance.svg';
import { ReactComponent as FullPacking } from 'assets/icons/full_packing.svg';
import { ReactComponent as GallonsUsed } from 'assets/icons/gallons_used.svg';
import { ReactComponent as General } from 'assets/icons/general.svg';
import { ReactComponent as GeneralForm } from 'assets/icons/general_form.svg';
import { ReactComponent as High } from 'assets/icons/high.svg';
import { ReactComponent as HomePhone } from 'assets/icons/home_phone.svg';
import { ReactComponent as HoursTotal } from 'assets/icons/hours_total.svg';
import { ReactComponent as InProgress } from 'assets/icons/in_progress.svg';
import { ReactComponent as InStorage } from 'assets/icons/in_storage.svg';
import { ReactComponent as InTransit } from 'assets/icons/in_transit.svg';
import { ReactComponent as InternalNotes } from 'assets/icons/internal_notes.svg';
import { ReactComponent as Inventory } from 'assets/icons/inventory.svg';
import { ReactComponent as JobFilter } from 'assets/icons/job_filter.svg';
import { ReactComponent as JobStart } from 'assets/icons/job_start.svg';
import { ReactComponent as JobStop } from 'assets/icons/job_stop.svg';
import { ReactComponent as JobTotal } from 'assets/icons/job_total.svg';
import { ReactComponent as Labor } from 'assets/icons/labor.svg';
import { ReactComponent as Lead } from 'assets/icons/lead.svg';
import { ReactComponent as LeadPerson } from 'assets/icons/lead_person.svg';
import { ReactComponent as LeadProvider } from 'assets/icons/lead_provider.svg';
import { ReactComponent as Logout } from 'assets/icons/logout.svg';
import { ReactComponent as Lost } from 'assets/icons/lost.svg';
import { ReactComponent as Low } from 'assets/icons/low.svg';
import { ReactComponent as GreenCheckmark } from 'assets/icons/yes.svg';
import { ReactComponent as RedCrossmark } from 'assets/icons/no.svg';
import { ReactComponent as All } from 'assets/icons/all.svg';
import { ReactComponent as Manager } from 'assets/icons/manager.svg';
import { ReactComponent as ManualCharge } from 'assets/icons/manual_charge.svg';
import { ReactComponent as ManualDiscount } from 'assets/icons/manual_discount.svg';
import { ReactComponent as ManualTax } from 'assets/icons/manual_tax.svg';
import { ReactComponent as ManualTips } from 'assets/icons/manual_tips.svg';
import { ReactComponent as Medium } from 'assets/icons/medium.svg';
import { ReactComponent as MediumHigh } from 'assets/icons/medium_high.svg';
import { ReactComponent as LowMedium } from 'assets/icons/low_medium.svg';
import { ReactComponent as MoveOutInsurance } from 'assets/icons/move_out_insurance.svg';
import { ReactComponent as MoveSize } from 'assets/icons/move_size.svg';
import { ReactComponent as MoveInfo } from 'assets/icons/mover_info.svg';
import { ReactComponent as Movers } from 'assets/icons/movers.svg';
import { ReactComponent as MoversPeople } from 'assets/icons/movers_people.svg';
import { ReactComponent as Name } from 'assets/icons/name.svg';
import { ReactComponent as Notes } from 'assets/icons/notes.svg';
import { ReactComponent as OffLoaded } from 'assets/icons/off_loaded.svg';
import { ReactComponent as OfficeToPickup } from 'assets/icons/office_to_pickup.svg';
import { ReactComponent as Ongoing } from 'assets/icons/ongoing.svg';
import { ReactComponent as OutStorage } from 'assets/icons/out_storage.svg';
import { ReactComponent as Packing } from 'assets/icons/packing.svg';
import { ReactComponent as PaymentType } from 'assets/icons/payment_type.svg';
import { ReactComponent as Payroll } from 'assets/icons/payroll.svg';
import { ReactComponent as PickupTime } from 'assets/icons/pickup_time.svg';
import { ReactComponent as Pin } from 'assets/icons/pin.svg';
import { ReactComponent as Pin2 } from 'assets/icons/pin2.svg';
import { ReactComponent as Pin3 } from 'assets/icons/pin3.svg';
import { ReactComponent as Postsale } from 'assets/icons/postsale.svg';
import { ReactComponent as PropertyAccess } from 'assets/icons/property_access.svg';
import { ReactComponent as PutCalls } from 'assets/icons/put_calls.svg';
import { ReactComponent as PhoneNumbers } from 'assets/icons/phone_numbers.svg';
import { ReactComponent as Quoted } from 'assets/icons/quoted.svg';
import { ReactComponent as Rate } from 'assets/icons/rate.svg';
import { ReactComponent as RateType } from 'assets/icons/rate_type.svg';
import { ReactComponent as Reassigned } from 'assets/icons/reassigned.svg';
import { ReactComponent as Reports } from 'assets/icons/reports.svg';
import { ReactComponent as Rescheduled } from 'assets/icons/rescheduled.svg';
import { ReactComponent as SalaryPerHour } from 'assets/icons/salary_per_hour.svg';
import { ReactComponent as Search } from 'assets/icons/search.svg';
import { ReactComponent as SelectCompany } from 'assets/icons/select_company.svg';
import { ReactComponent as SelectPeriod } from 'assets/icons/select_period.svg';
import { ReactComponent as Send } from 'assets/icons/send.svg';
import { ReactComponent as Sms } from 'assets/icons/sms.svg';
import { ReactComponent as SmsReminder } from 'assets/icons/sms_reminder.svg';
import { ReactComponent as Sold } from 'assets/icons/sold.svg';
import { ReactComponent as Sort } from 'assets/icons/sort.svg';
import { ReactComponent as SplitJob } from 'assets/icons/split_job.svg';
import { ReactComponent as State } from 'assets/icons/state.svg';
import { ReactComponent as Storage } from 'assets/icons/storage.svg';
import { ReactComponent as Sum } from 'assets/icons/sum.svg';
import { ReactComponent as TaskCompleted } from 'assets/icons/task_completed.svg';
import { ReactComponent as TaskUnfinished } from 'assets/icons/task_unfinished.svg';
import { ReactComponent as Tasks } from 'assets/icons/tasks.svg';
import { ReactComponent as Team } from 'assets/icons/team.svg';
import { ReactComponent as TextNotes } from 'assets/icons/text_notes.svg';
import { ReactComponent as TimeWasted } from 'assets/icons/time_wasted.svg';
import { ReactComponent as TrailerAccess } from 'assets/icons/trailer_access.svg';
import { ReactComponent as Travel } from 'assets/icons/travel.svg';
import { ReactComponent as Trucks } from 'assets/icons/trucks.svg';
import { ReactComponent as Unconfirmed } from 'assets/icons/unconfirmed.svg';
import { ReactComponent as Visual } from 'assets/icons/visual.svg';
import { ReactComponent as Zip } from 'assets/icons/zip.svg';
import { ReactComponent as Handset } from 'assets/icons/handset.svg';
import { ReactComponent as Upload } from 'assets/icons/upload.svg';
import { ReactComponent as Download } from 'assets/icons/download.svg';
import { ReactComponent as MissedCall } from 'assets/icons/missed_call.svg';
import { ReactComponent as EmailRead } from 'assets/icons/email_read.svg';
import { ReactComponent as EmailSent } from 'assets/icons/email_sent.svg';
import { ReactComponent as DocExportQuote } from 'assets/icons/doc_export_quote.svg';
import { ReactComponent as DocContractEst } from 'assets/icons/doc_contract_est.svg';
import { ReactComponent as DocInsurance } from 'assets/icons/doc_insurance.svg';
import { ReactComponent as DocPresswood } from 'assets/icons/doc_presswood.svg';
import { ReactComponent as DocCreditCard } from 'assets/icons/doc_credit_card.svg';
import { ReactComponent as AddNew } from 'assets/icons/add_new.svg';
import { ReactComponent as CallIn } from 'assets/icons/call_in.svg';
import { ReactComponent as CallHold } from 'assets/icons/call_hold.svg';
import { ReactComponent as CallForward } from 'assets/icons/call_forward.svg';
import { ReactComponent as Dots } from 'assets/icons/dots.svg';
import { ReactComponent as CallEnd } from 'assets/icons/call_end.svg';
import { ReactComponent as CallAnswer } from 'assets/icons/call_answer.svg';
import { ReactComponent as Save } from 'assets/icons/save.svg';
import { ReactComponent as ColorMapLight } from 'assets/icons/color_map_light.svg';
import { ReactComponent as ColorMapBlue } from 'assets/icons/color_map_blue.svg';
import { ReactComponent as ColorMapGreen } from 'assets/icons/color_map_green.svg';
import { ReactComponent as Clip } from 'assets/icons/clip.svg';
import { ReactComponent as EmailEdit } from 'assets/icons/email_edit.svg';
import { ReactComponent as T } from 'assets/icons/T.svg';
import { ReactComponent as D } from 'assets/icons/D.svg';
import { ReactComponent as H } from 'assets/icons/H.svg';
import { ReactComponent as S } from 'assets/icons/S.svg';
import { ReactComponent as StorageJobDelivery } from 'assets/icons/storage_job_delivery.svg';
import { ReactComponent as StorageJobPickup } from 'assets/icons/storage_job_pickup.svg';
import { ReactComponent as StorageJobStorage } from 'assets/icons/storage_job_storage.svg';
import { ReactComponent as WaitingForDelivery } from 'assets/icons/waiting_for_delivery.svg';
import { ReactComponent as InventoryToOngoingStorageJob } from 'assets/icons/inventory_to_ongoing_storage_job.svg';
import { ReactComponent as Read } from 'assets/icons/read.svg';
import { ReactComponent as Unread } from 'assets/icons/unread.svg';
import { ReactComponent as File } from 'assets/icons/file.svg';
import { ReactComponent as Purple } from 'assets/icons/purple.svg';
import { ReactComponent as Info } from 'assets/icons/info.svg';
import { ReactComponent as RemindersEdit } from 'assets/icons/reminders_edit.svg';
import { ReactComponent as Reminders } from 'assets/icons/reminders.svg';
import { ReactComponent as RemoveCross } from 'assets/icons/remove_cross.svg';
import { ReactComponent as Text } from 'assets/icons/text.svg';
import { ReactComponent as CurrentBalance } from 'assets/icons/current_balance.svg';
import { ReactComponent as CalendarDot } from 'assets/icons/calendar_dot.svg';
import { ReactComponent as TaskImportant } from 'assets/icons/task_important.svg';
import { ReactComponent as MoneyBack } from 'assets/icons/money_back.svg';
import { ReactComponent as DueDate } from 'assets/icons/due_date.svg';
import { ReactComponent as LateDate } from 'assets/icons/late_date.svg';
import { ReactComponent as Sign } from 'assets/icons/sign.svg';
import { ReactComponent as Weight } from 'assets/icons/weight.svg';
import { ReactComponent as Foot } from 'assets/icons/foot.svg';

const Icon = (props) => {
  const { style, icon, className, color = '#ffffff', iconstyle, size = 24, title, rotateDeg = 0, iconStyle, width, height } = props;
  const defaultStyles = {
    fill: color,
    transform: `rotate(${rotateDeg}deg)`,
    ...iconstyle,
  };

  const renderIcon = () => {
    switch (icon) {
      case 'bulb':
        return <Bulb width={size} height={size} style={defaultStyles} />;
      case 'phone_numbers':
        return <PhoneNumbers width={size} height={size} style={defaultStyles} />;
      case 'handset':
        return <Handset width={size} height={size} style={defaultStyles} />;
      case 'additional_info':
        return <AdditionalInfo width={size} height={size} style={defaultStyles} />;
      case 'address':
        return <Address width={size} height={size} style={defaultStyles} />;
      case 'agent':
        return <Agent width={size} height={size} style={defaultStyles} />;
      case 'alert_log':
        return <AlertLog width={size} height={size} style={defaultStyles} />;
      case 'arrive_time':
        return <ArriveTime width={size} height={size} style={defaultStyles} />;
      case 'assigned':
        return <Assigned width={size} height={size} style={defaultStyles} />;
      case 'booked':
        return <Booked width={size} height={size} style={defaultStyles} />;
      case 'booking':
        return <Booking width={size} height={size} style={defaultStyles} />;
      case 'building_name':
        return <BuildingName width={size} height={size} style={defaultStyles} />;
      case 'call':
        return <Call width={size} height={size} style={defaultStyles} />;
      case 'canceled':
        return <Canceled width={size} height={size} style={defaultStyles} />;
      case 'cash':
        return <Cash width={size} height={size} style={defaultStyles} />;
      case 'cell_phone':
        return <CellPhone width={size} height={size} style={defaultStyles} />;
      case 'city':
        return <City width={size} height={size} style={defaultStyles} />;
      case 'claims':
        return <Claims width={size} height={size} style={defaultStyles} />;
      case 'commission':
        return <Commission width={size} height={size} style={defaultStyles} />;
      case 'commission_value':
        return <CommissionValue width={size} height={size} style={defaultStyles} />;
      case 'communication':
        return <Communication width={size} height={size} style={defaultStyles} />;
      case 'communications':
        return <Communications width={size} height={size} style={defaultStyles} />;
      case 'completed':
        return <Completed width={size} height={size} style={defaultStyles} />;
      case 'confirmed':
        return <Confirmed width={size} height={size} style={defaultStyles} />;
      case 'delete':
        return <Delete width={size} height={size} style={defaultStyles} />;
      case 'delete_cross':
        return <DeleteCross width={size} height={size} style={defaultStyles} />;
      case 'delivered':
        return <Delivered width={size} height={size} style={defaultStyles} />;
      case 'driver_info':
        return <DriverInfo width={size} height={size} style={defaultStyles} />;
      case 'dropped':
        return <Dropped width={size} height={size} style={defaultStyles} />;
      case 'email':
        return <Email width={size} height={size} style={defaultStyles} />;
      case 'email_logo':
        return <EmailLogo width={size} height={size} style={defaultStyles} />;
      case 'email_reminder':
        return <EmailReminder width={size} height={size} style={defaultStyles} />;
      case 'estimated_stairs':
        return <EstimatedStairs width={size} height={size} style={defaultStyles} />;
      case 'estimated_total':
        return <EstimatedTotal width={size} height={size} style={defaultStyles} />;
      case 'estimator':
        return <Estimator width={size} height={size} style={defaultStyles} />;
      case 'exp':
        return <Exp width={size} height={size} style={defaultStyles} />;
      case 'extra_stop':
        return <ExtraStop width={size} height={size} style={defaultStyles} />;
      case 'extra_stops':
        return <ExtraStops width={size} height={size} style={defaultStyles} />;
      case 'final_total':
        return <FinalTotal width={size} height={size} style={defaultStyles} />;
      case 'follow_up':
        return <FollowUp width={size} height={size} style={defaultStyles} />;
      case 'from_elevator':
        return <FromElevator width={size} height={size} style={defaultStyles} />;
      case 'fuel_cost':
        return <FuelCost width={size} height={size} style={defaultStyles} />;
      case 'full_distance':
        return <FullDistance width={size} height={size} style={defaultStyles} />;
      case 'full_packing':
        return <FullPacking width={size} height={size} style={defaultStyles} />;
      case 'gallons_used':
        return <GallonsUsed width={size} height={size} style={defaultStyles} />;
      case 'general':
        return <General width={size} height={size} style={defaultStyles} />;
      case 'general_form':
        return <GeneralForm width={size} height={size} style={defaultStyles} />;
      case 'high':
        return <High width={size} height={size} style={defaultStyles} />;
      case 'home_phone':
        return <HomePhone width={size} height={size} style={defaultStyles} />;
      case 'hours_total':
        return <HoursTotal width={size} height={size} style={defaultStyles} />;
      case 'in_progress':
        return <InProgress width={size} height={size} style={defaultStyles} />;
      case 'in_storage':
        return <InStorage width={size} height={size} style={defaultStyles} />;
      case 'in_transit':
        return <InTransit width={size} height={size} style={defaultStyles} />;
      case 'internal_notes':
        return <InternalNotes width={size} height={size} style={defaultStyles} />;
      case 'inventory':
        return <Inventory width={size} height={size} style={defaultStyles} />;
      case 'job_filter':
        return <JobFilter width={size} height={size} style={defaultStyles} />;
      case 'job_start':
        return <JobStart width={size} height={size} style={defaultStyles} />;
      case 'job_stop':
        return <JobStop width={size} height={size} style={defaultStyles} />;
      case 'job_total':
        return <JobTotal width={size} height={size} style={defaultStyles} />;
      case 'labor':
        return <Labor width={size} height={size} style={defaultStyles} />;
      case 'lead':
        return <Lead width={size} height={size} style={defaultStyles} />;
      case 'lead-person':
        return <LeadPerson width={size} height={size} style={defaultStyles} />;
      case 'lead_provider':
        return <LeadProvider width={size} height={size} style={defaultStyles} />;
      case 'logout':
        return <Logout width={size} height={size} style={defaultStyles} />;
      case 'lost':
        return <Lost width={size} height={size} style={defaultStyles} />;
      case 'low':
        return <Low width={size} height={size} style={defaultStyles} />;
      case 'all':
        return <All width={size} height={size} style={defaultStyles} />;
      case 'manager':
        return <Manager width={size} height={size} style={defaultStyles} />;
      case 'manual_charge':
        return <ManualCharge width={size} height={size} style={defaultStyles} />;
      case 'manual_discount':
        return <ManualDiscount width={size} height={size} style={defaultStyles} />;
      case 'manual_tax':
        return <ManualTax width={size} height={size} style={defaultStyles} />;
      case 'manual_tips':
        return <ManualTips width={size} height={size} style={defaultStyles} />;
      case 'medium':
        return <Medium width={size} height={size} style={defaultStyles} />;
      case 'move_out_insurance':
        return <MoveOutInsurance width={size} height={size} style={defaultStyles} />;
      case 'move_size':
        return <MoveSize width={size} height={size} style={defaultStyles} />;
      case 'mover_info':
        return <MoveInfo width={size} height={size} style={defaultStyles} />;
      case 'movers':
        return <Movers width={size} height={size} style={defaultStyles} />;
      case 'movers_people':
        return <MoversPeople width={size} height={size} style={defaultStyles} />;
      case 'name':
        return <Name width={size} height={size} style={defaultStyles} />;
      case 'notes':
        return <Notes width={size} height={size} style={defaultStyles} />;
      case 'off_loaded':
        return <OffLoaded width={size} height={size} style={defaultStyles} />;
      case 'office_to_pickup':
        return <OfficeToPickup width={size} height={size} style={defaultStyles} />;
      case 'ongoing':
        return <Ongoing width={size} height={size} style={defaultStyles} />;
      case 'out_storage':
        return <OutStorage width={size} height={size} style={defaultStyles} />;
      case 'packing':
        return <Packing width={size} height={size} style={defaultStyles} />;
      case 'payment_type':
        return <PaymentType width={size} height={size} style={defaultStyles} />;
      case 'payroll':
        return <Payroll width={size} height={size} style={defaultStyles} />;
      case 'pickup_time':
        return <PickupTime width={size} height={size} style={defaultStyles} />;
      case 'pin':
        return <Pin width={size} height={size} style={defaultStyles} />;
      case 'pin2':
        return <Pin2 width={size} height={size} style={defaultStyles} />;
      case 'pin3':
        return <Pin3 width={size} height={size} style={defaultStyles} />;
      case 'postsale':
        return <Postsale width={size} height={size} style={defaultStyles} />;
      case 'presale':
        return <Postsale width={size} height={size} style={defaultStyles} />;
      case 'property_access':
        return <PropertyAccess width={size} height={size} style={defaultStyles} />;
      case 'put_calls':
        return <PutCalls width={size} height={size} style={defaultStyles} />;
      case 'quoted':
        return <Quoted width={size} height={size} style={defaultStyles} />;
      case 'rate':
        return <Rate width={size} height={size} style={defaultStyles} />;
      case 'rate_type':
        return <RateType width={size} height={size} style={defaultStyles} />;
      case 'reassigned':
        return <Reassigned width={size} height={size} style={defaultStyles} />;
      case 'reports':
        return <Reports width={size} height={size} style={defaultStyles} />;
      case 'rescheduled':
        return <Rescheduled width={size} height={size} style={defaultStyles} />;
      case 'salary_per_hour':
        return <SalaryPerHour width={size} height={size} style={defaultStyles} />;
      case 'search':
        return <Search width={size} height={size} style={defaultStyles} />;
      case 'select_company':
        return <SelectCompany width={size} height={size} style={defaultStyles} />;
      case 'select_period':
        return <SelectPeriod width={size} height={size} style={defaultStyles} />;
      case 'send':
        return <Send width={size} height={size} style={defaultStyles} />;
      case 'sms':
        return <Sms width={size} height={size} style={defaultStyles} />;
      case 'sms_reminder':
        return <SmsReminder width={size} height={size} style={defaultStyles} />;
      case 'sold':
        return <Sold width={size} height={size} style={defaultStyles} />;
      case 'sort':
        return <Sort width={size} height={size} style={defaultStyles} />;
      case 'split_job':
        return <SplitJob width={size} height={size} style={defaultStyles} />;
      case 'state':
        return <State width={size} height={size} style={defaultStyles} />;
      case 'storage':
        return <Storage width={size} height={size} style={defaultStyles} />;
      case 'sum':
        return <Sum width={size} height={size} style={defaultStyles} />;
      case 'task_completed':
        return <TaskCompleted width={size} height={size} style={defaultStyles} />;
      case 'task_unfinished':
        return <TaskUnfinished width={size} height={size} style={defaultStyles} />;
      case 'tasks':
        return <Tasks width={size} height={size} style={defaultStyles} />;
      case 'team':
        return <Team width={size} height={size} style={defaultStyles} />;
      case 'text_notes':
        return <TextNotes width={size} height={size} style={defaultStyles} />;
      case 'time_wasted':
        return <TimeWasted width={size} height={size} style={defaultStyles} />;
      case 'trailer_access':
        return <TrailerAccess width={size} height={size} style={defaultStyles} />;
      case 'travel':
        return <Travel width={size} height={size} style={defaultStyles} />;
      case 'trucks':
        return <Trucks width={size} height={size} style={defaultStyles} />;
      case 'unconfirmed':
        return <Unconfirmed width={size} height={size} style={defaultStyles} />;
      case 'visual':
        return <Visual width={size} height={size} style={defaultStyles} />;
      case 'zip':
        return <Zip width={size} height={size} style={defaultStyles} />;
      case 'add':
        return <Add width={size} height={size} style={defaultStyles} />;
      case 'remove':
        return <Remove width={size} height={size} style={defaultStyles} />;
      case 'none':
        return <None width={size} height={size} style={defaultStyles} />;
      case 'low-medium':
        return <LowMedium width={size} height={size} style={defaultStyles} />;
      case 'medium-high':
        return <MediumHigh width={size} height={size} style={defaultStyles} />;
      case 'purple':
        return <Purple width={size} height={size} style={defaultStyles} />;
      case 'upload':
        return <Upload width={size} height={size} style={defaultStyles} />;
      case 'download':
        return <Download width={size} height={size} style={defaultStyles} />;
      case 'missed_call':
        return <MissedCall width={size} height={size} style={defaultStyles} />;
      case 'email_read':
        return <EmailRead width={size} height={size} style={defaultStyles} />;
      case 'email_sent':
        return <EmailSent width={size} height={size} style={defaultStyles} />;
      case 'email_edit':
        return <EmailEdit width={size} height={size} style={defaultStyles} />;
      case 'clip':
        return <Clip width={size} height={size} style={defaultStyles} />;
      case 'doc_export_quote':
        return <DocExportQuote width={size} height={size} style={defaultStyles} />;
      case 'doc_contract_est':
        return <DocContractEst width={size} height={size} style={defaultStyles} />;
      case 'doc_insurance':
        return <DocInsurance width={size} height={size} style={defaultStyles} />;
      case 'doc_presswood':
        return <DocPresswood width={size} height={size} style={defaultStyles} />;
      case 'doc_credit_cCard':
        return <DocCreditCard width={size} height={size} style={defaultStyles} />;
      case 'green_checkmark':
        return <GreenCheckmark width={size} height={size} style={defaultStyles} />;
      case 'red_crossmark':
        return <RedCrossmark width={size} height={size} style={defaultStyles} />;
      case 'add_new':
        return <AddNew width={size} height={size} style={defaultStyles} />;
      case 'call_in':
        return <CallIn width={size} height={size} style={defaultStyles} />;
      case 'call_hold':
        return <CallHold width={size} height={size} style={defaultStyles} />;
      case 'call_forward':
        return <CallForward width={size} height={size} style={defaultStyles} />;
      case 'dots':
        return <Dots width={size} height={size} style={defaultStyles} />;
      case 'call_end':
        return <CallEnd width={width || size} height={height || size} style={defaultStyles} />;
      case 'call_answer':
        return <CallAnswer width={size} height={size} style={defaultStyles} />;
      case 'save':
        return <Save width={size} height={size} style={defaultStyles} />;
      case 'color_map_light':
        return <ColorMapLight width={size} height={size} style={defaultStyles} />;
      case 'color_map_blue':
        return <ColorMapBlue width={size} height={size} style={defaultStyles} />;
      case 'color_map_green':
        return <ColorMapGreen width={size} height={size} style={defaultStyles} />;
      case 't':
        return <T width={size} height={size} style={defaultStyles} />;
      case 'd':
        return <D width={size} height={size} style={defaultStyles} />;
      case 'h':
        return <H width={size} height={size} style={defaultStyles} />;
      case 's':
        return <S width={size} height={size} style={defaultStyles} />;
      case 'storage_job_delivery':
        return <StorageJobDelivery width={size} height={size} style={defaultStyles} />;
      case 'storage_job_pickup':
        return <StorageJobPickup width={size} height={size} style={defaultStyles} />;
      case 'storage_job_storage':
        return <StorageJobStorage width={size} height={size} style={defaultStyles} />;
      case 'waiting_for_delivery':
        return <WaitingForDelivery width={size} height={size} style={defaultStyles} />;
      case 'inventory_to_ongoing_storage_job':
        return <InventoryToOngoingStorageJob width={size} height={size} style={defaultStyles} />;
      case 'read':
        return <Read width={size} height={size} style={defaultStyles} />;
      case 'unread':
        return <Unread width={size} height={size} style={defaultStyles} />;
      case 'file':
        return <File width={size} height={size} style={defaultStyles} />;
      case 'info':
        return <Info width={size} height={size} style={defaultStyles} />;
      case 'reminders_edit':
        return <RemindersEdit width={size} height={size} style={defaultStyles} />;
      case 'reminders':
        return <Reminders width={size} height={size} style={defaultStyles} />;
      case 'remove_cross':
        return <RemoveCross width={size} height={size} style={defaultStyles} />;
      case 'text':
        return <Text width={size} height={size} style={defaultStyles} />;
      case 'current_balance':
        return <CurrentBalance width={size} height={size} style={defaultStyles} />;
      case 'calendar_dot':
        return <CalendarDot width={size} height={size} style={defaultStyles} />;
      case 'task_important':
        return <TaskImportant width={size} height={size} style={defaultStyles} />;
      case 'money_back':
        return <MoneyBack width={size} height={size} style={defaultStyles} />;
      case 'due_date':
        return <DueDate width={size} height={size} style={defaultStyles} />;
      case 'late_date':
        return <LateDate width={size} height={size} style={defaultStyles} />;
      case 'sign':
        return <Sign width={size} height={size} style={defaultStyles} />;
      case 'weight':
        return <Weight width={size} height={size} style={defaultStyles} />;
      case 'foot':
        return <Foot width={size} height={size} style={defaultStyles} />;
      default:
        return null;
    }
  };

  const currentIcon = renderIcon();

  return (
    <span style={style} className={`tooltip-wrapper ${className || ''}`}>
      {currentIcon ?? title}
      {title && (
        <span className="tooltip-text" style={iconStyle}>
          {title}
        </span>
      )}
    </span>
  );
};

export default Icon;

import React, { Fragment, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useInfo, useTabInfo } from 'hooks/query/useJob';
import MapsContainer from './MapsContainer';
import RoutesContainer from '../General/RoutesContainer';
import JobExtraStopItem from './JobExtraStopItem';
import JobStopDetailsItem from './JobStopDetailsItem';
import { useDefault } from 'hooks/query/useCommon';

const JobExtraStopContainer = (props) => {
  const { id, tab } = useParams();

  const { data: info } = useInfo(id);

  const { data: tabInfo } = useTabInfo(id, tab);

  const {
    data: { company: companies, statuses },
  } = useDefault();

  const officeInfo = useMemo(() => {
    return companies.find((item) => item.id === info.job_info.company_id);
  }, [companies, info]);

  return (
    <Fragment>
      <RoutesContainer
        dates={info.dates}
        docs={tabInfo.docs}
        notes={tabInfo.notes}
        jobInfo={info.job_info}
        calcInfo={info.calc_info}
        extraStops={tabInfo.extra_stops}
        movingInfo={tabInfo.moving_info}
        companyId={info.job_info.company_id}
        jobInsuranceInfo={tabInfo.job_insuranceInfo}
      />
      <MapsContainer movingInfo={tabInfo.moving_info} extraStops={tabInfo.extra_stops} officeInfo={officeInfo} />
      {tabInfo.extra_stops.length > 0 && (
        <div className="extrastops-wrapper">
          <div className="extrastops-wrapper--item">
            <h3 className="header">ExtraStops from</h3>
            {tabInfo.extra_stops.map((item) => {
              if (item.type === 1) return <JobExtraStopItem data={item} key={item.id} />;
              return null;
            })}
          </div>

          <div className="extrastops-wrapper--item">
            <h3 className="header">ExtraStops to</h3>
            {tabInfo.extra_stops.map((item) => {
              if (item.type === 2) return <JobExtraStopItem data={item} key={item.id} />;
              return null;
            })}
          </div>
        </div>
      )}
      {(tabInfo.additional_info?.stop_from.length > 0 || tabInfo.additional_info?.stop_to.length > 0) && (
        <div className="extrastops-wrapper-additional">
          <h3 className="header">Additional info</h3>

          <div className="extrastops-wrapper-additional--list">
            {tabInfo.additional_info?.stop_from.length > 0 && (
              <div className="extrastops-wrapper-additional--list--item">
                <h3 className="header">ExtraStops details from</h3>
                {tabInfo.additional_info?.stop_from.map((item, index) => (
                  <JobStopDetailsItem data={item} key={item.id} index={index} typeOfBuilding={statuses?.building_type} />
                ))}
              </div>
            )}

            {tabInfo.additional_info?.stop_to.length > 0 && (
              <div className="extrastops-wrapper-additional--list--item">
                <h3 className="header">ExtraStops details to</h3>
                {tabInfo.additional_info?.stop_to.map((item, index) => (
                  <JobStopDetailsItem data={item} key={item.id} index={index} typeOfBuilding={statuses?.building_type} />
                ))}
              </div>
            )}
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default JobExtraStopContainer;

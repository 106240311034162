import { useHistory, generatePath } from 'react-router-dom';
import { useQuickMovingQuote } from 'hooks/query/useCommon';
import { useSelectAgent } from 'hooks/useSelectAgent';
import { routePaths } from 'config/routes';

export const useSubmit = (params) => {
  const { closeModal } = params;

  const history = useHistory();

  const { mutate, isLoading } = useQuickMovingQuote();

  const { onMutate: createJob } = useSelectAgent(mutate, true);

  const submit = (values) => {
    createJob(values, null, ({ id, agentId }) => {
      closeModal();
      history.push(generatePath(routePaths.JOBS, { type: agentId ? 'followUp' : 'lead', tab: 'general', id }));
    });
  };

  return {
    submit,
    isMutatationRunning: isLoading,
  };
};

import React, { Fragment, useMemo, useCallback } from 'react';
import { useLocation, useHistory, generatePath } from 'react-router-dom';
import Swal from 'sweetalert2';
import Banner from 'components/JobBanner';
import { useDeleteMail, useGetMail } from 'hooks/query/useCommunications';
import { getParams } from 'helpers/jobs';
import { routePaths } from 'config/routes';
import Icon from 'components/Icon';
import MciEditor from 'components/MciEditor';
import { useModal } from 'context/modal';

const Detail = () => {
  const { search } = useLocation();
  const history = useHistory();

  const params = useMemo(() => getParams(search), [search]);

  const { modalNames, open } = useModal();

  const { data: selectedMail = null } = useGetMail({ id: params.selected ?? 0 });

  const attachments = useMemo(() => {
    if (!selectedMail || !selectedMail.attachments) return [];

    if (Array.isArray(selectedMail.attachments)) return selectedMail.attachments;
    if (typeof selectedMail.attachments === 'string') return JSON.parse(selectedMail.attachments);
  }, [selectedMail]);

  const handleSuccessDelete = useCallback(() => {
    const queryParams = new URLSearchParams(search);
    queryParams.delete('selected');
    queryParams.delete('selected_type');
    history.replace({
      search: queryParams.toString(),
    });
  }, [history, search]);

  const { mutate: deleteMail } = useDeleteMail({ onSuccess: handleSuccessDelete });

  const handleDeleteEmail = useCallback(() => {
    const { id } = selectedMail;
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        deleteMail({ id });
      }
    });
  }, [deleteMail, selectedMail]);

  const handleOpenJob = useCallback(
    (id) => {
      if (id)
        history.push({
          pathname: generatePath(routePaths.JOBS, { id, tab: 'general', type: 'all' }),
        });
    },
    [history]
  );

  const openModalReply = () => {
    open(modalNames.newEmail, {
      job_id: selectedMail.job_id,
      message_id: selectedMail.id,
      type: 'reply',
      title: 'Reply',
      email_cc: '',
      email_to: selectedMail.email_from,
      company_id: selectedMail?.company_id || 0,
      selectedMail: selectedMail,
    });
  };

  const openModalReplyAll = () => {
    open(modalNames.newEmail, {
      job_id: selectedMail.job_id,
      message_id: selectedMail.id,
      type: 'replyAll',
      title: 'Reply All',
      email_cc: selectedMail.email_cc,
      email_to: selectedMail.email_from,
      company_id: selectedMail?.company_id || 0,
      selectedMail: selectedMail,
    });
  };

  const openForwardModal = () => {
    open(modalNames.newEmail, {
      job_id: selectedMail.job_id,
      message_id: selectedMail.id,
      type: 'forward',
      title: 'Forward',
      email_cc: '',
      email_to: '',
      company_id: selectedMail?.company_id || 0,
      selectedMail: selectedMail,
    });
  };

  const handleOpenEditModal = () => {
    open(modalNames.editReminder, { id: selectedMail.id });
  };

  if (!selectedMail) {
    return <Banner />;
  }

  const mailCC = (selectedMail) => {
    if (selectedMail == null) return '';
    return !!selectedMail.email_cc ? selectedMail?.email_cc.map((item) => item).join(', ') : '';
  };

  return (
    <div className="shadow">
      <div className="new-table">
        <div className="new-table--row">
          {selectedMail.job_id !== 0 && (
            <div className="new-table--row--item no-padding">
              <button type="button" className="main flex-grow-1 p20" onClick={() => handleOpenJob(selectedMail.job_id)}>
                Open Job
              </button>
            </div>
          )}

          {+selectedMail.send_time === 0 && (
            <Fragment>
              <div className="new-table--row--item no-padding">
                <button type="button" className="main flex-grow-1 p20" onClick={openModalReply}>
                  Reply
                </button>
              </div>
              <div className="new-table--row--item no-padding">
                <button type="button" className="main flex-grow-1 p20" onClick={openModalReplyAll}>
                  Reply All
                </button>
              </div>
              <div className="new-table--row--item no-padding">
                <button type="button" className="main flex-grow-1 p20" onClick={openForwardModal}>
                  Forward
                </button>
              </div>
            </Fragment>
          )}
          {+selectedMail.send_time !== 0 && +selectedMail.type === 0 && (
            <div className="new-table--row--item no-padding">
              <button type="button" className="main flex-grow-1 p20" onClick={handleOpenEditModal}>
                Edit
              </button>
            </div>
          )}
          <div className="new-table--row--item no-padding">
            <button type="button" className="main flex-grow-1 p20" onClick={handleDeleteEmail}>
              Delete
            </button>
          </div>
        </div>
      </div>

      <div className="new-table">
        <div className="new-table--row">
          <div className="new-table--row--item">
            <span>{selectedMail?.created_at?.slice(0, 10)}</span>
          </div>
          <div className="new-table--row--item">
            <span>{selectedMail.email_to}</span>
          </div>
        </div>

        <div className="new-table--row">
          <div className="new-table--row--item">
            <span>cc : {mailCC(selectedMail) ?? '-'}</span>
          </div>
          <div className="new-table--row--item">
            <span>{selectedMail.subject}</span>
          </div>
        </div>
      </div>

      <div className="col-12 email-body">
        <MciEditor value={selectedMail.body} hideToolbar hideMenu disabled />
      </div>
      <div className="col-12 email-head">
        <h3 className="header">Attachments :</h3>
        <div className="new-table">
          {attachments.length > 0 ? (
            attachments.map((item) => (
              <div key={item.name} className="new-table--row">
                <div className="new-table--row--item">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Download"
                    href={`${process.env.REACT_APP_API_URL}/api/downloadAttachments?path=` + item.path}
                  >
                    <Icon icon="download" size={20} title="Save attachment" style={{ marginRight: 5 }} />
                    {item.name}
                  </a>
                </div>
              </div>
            ))
          ) : (
            <div className="new-table--row">
              <div className="new-table--row--item">
                <span>no attachments</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Detail;

import { useQuery, useMutation } from 'react-query';
import { useApi } from 'context/api';
import queryKeys from 'config/queryKeys';

export const useReportDashboardInfo = (data) => {
  const { report } = useApi();

  return useQuery([queryKeys.reportDashboardInfo, data], () => report.getDashboardInfo(data), {
    select: (res) => res.data,
    keepPreviousData: true,
    cacheTime: 0,
    staleTime: 0,
  });
};

export const useReportFilterInfo = () => {
  const { report } = useApi();

  return useQuery([queryKeys.reportFilterdInfo], () => report.getFilterInfo(), {
    select: (res) => res.data,
    keepPreviousData: true,
    cacheTime: 0,
    staleTime: 0,
  });
};

export const useGetDriverClaims = () => {
  const { report } = useApi();

  return useMutation((data) => report.getDriverClaims(data));
};

export const useGetLeadReport = () => {
  const { report } = useApi();

  return useMutation((data) => report.getLeadReport(data));
};

export const useGetBookingReport = () => {
  const { report } = useApi();

  return useMutation((data) => report.getBookingReport(data));
};

export const useGetProviderReport = () => {
  const { report } = useApi();

  return useMutation((data) => report.getProviderReport(data));
};

export const useGetBuildingReport = () => {
  const { report } = useApi();

  return useMutation((data) => report.getBuildingReport(data));
};

export const useGetBuildingTimeInfo = () => {
  const { report } = useApi();

  return useMutation((data) => report.getBuildingTimeInfo(data));
};

export const useGetUserNames = (data, options = {}) => {
  const { report } = useApi();

  return useQuery([queryKeys.reportUserNames, data], () => report.getUserNames(data), {
    cacheTime: 0,
    staleTime: 0,
    select: (res) => res.data,
    keepPreviousData: true,
    ...options,
  });
};

export const useGetGeneralReportJobInfo = (data) => {
  const { report } = useApi();

  return useQuery([queryKeys.generalReportJobInfo, data], () => report.getGeneralReportJobInfo(data), {
    select: (res) => res.data,
    cacheTime: 0,
    staleTime: 0,
  });
};

export const useGetPayrollReport = () => {
  const { report } = useApi();

  return useMutation((data) => report.getPayrollReport(data));
};

export const useGetGeneralReport = () => {
  const { report } = useApi();

  return useMutation((data) => report.getGeneralReport(data));
};

export const useGetAgentComunicationsReport = () => {
  const { report } = useApi();

  return useMutation((data) => report.getAgentComunicationsReport(data));
};

export const useChangePaidStatus = () => {
  const { report } = useApi();

  return useMutation((data) => report.changePaidStatus(data));
};

export const useCreateReimbursement = () => {
  const { report } = useApi();

  return useMutation((data) => report.createReimbursement(data));
};

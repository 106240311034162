import React from 'react';
import classNames from 'classnames';
import './style.scss';

export const ModalButton = (props) => {
  const { className, variant, disabled, onClick, title, type } = props;

  return (
    <button type={type || 'button'} className={classNames(className, variant)} disabled={disabled} onClick={onClick}>
      {title}
    </button>
  );
};

// details confirm cancel

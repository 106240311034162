import { useQueryClient, useQuery, useMutation } from 'react-query';

import { useApi } from 'context/api';
import queryKeys from 'config/queryKeys';

export const useSaveImportantNote = (params) => {
  const { importantNotes } = useApi();
  const queryClient = useQueryClient();

  return useMutation((data) => importantNotes.saveImportantNote(data), {
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.importantNoteInfo, params]);
      queryClient.invalidateQueries([queryKeys.importantNotes]);
    },
  });
};

export const useDeleteAttachments = (params) => {
  const { importantNotes } = useApi();
  const queryClient = useQueryClient();

  return useMutation((data) => importantNotes.deleteAttachments(data), {
    onSettled: () => queryClient.invalidateQueries([queryKeys.importantNoteInfo, params]),
  });
};

export const useDeleteImportantNote = () => {
  const { importantNotes } = useApi();
  const queryClient = useQueryClient();

  return useMutation((data) => importantNotes.deleteImportantNote(data), {
    onSettled: () => queryClient.invalidateQueries([queryKeys.importantNotes]),
  });
};

export const useGetImportantNotes = () => {
  const { importantNotes } = useApi();

  return useQuery([queryKeys.importantNotes], () => importantNotes.getImportantNotes(), {
    select: (res) => res.data.list,
    keepPreviousData: true,
  });
};

export const useGetNoteInfo = (params) => {
  const { importantNotes } = useApi();

  return useQuery([queryKeys.importantNoteInfo, params], () => importantNotes.getNoteInfo(params), {
    select: (res) => res.data.note_info,
    enabled: Boolean(params.id),
  });
};

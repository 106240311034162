import React, { useMemo, memo } from 'react';
import CreatableSelect from 'react-select/creatable';

const ReactSelect = memo((props) => {
  const { options, name, onChange, disabled, placeholder, value, ...res } = props;

  const styles = {
    option: (provided) => ({
      ...provided,
      cursor: 'pointer',
      color: '#000',
    }),
    control: () => [{ border: 'none', display: 'flex', height: '100%', backgroundColor: '#fff', fontWeight: 700 }],
    indicatorSeparator: () => ({ display: 'none' }),
  };

  const currentValue = useMemo(() => options?.find((e) => e.value === value) ?? null, [options, value]);

  return (
    <CreatableSelect
      name={name}
      value={currentValue}
      styles={styles}
      options={options}
      onChange={onChange}
      isDisabled={disabled}
      placeholder={placeholder}
      {...res}
    />
  );
});

export default ReactSelect;

import React from 'react';
import Icon from 'components/Icon';
import CheckBox from 'components/newComponents/CheckBox';
import { useForm } from '../../../hooks/useForm';

function Insurance() {
  const { actions, values } = useForm();

  return (
    <div className="new-table--row--item no-padding no-shadow">
      <div className="new-table--row--item">
        <Icon icon="move_out_insurance" title="Move Out Insurance" />
        <CheckBox
          name="from_required_coi"
          className="justify-content-between"
          label="Insurance From?"
          checked={values['from_required_coi'] === 1}
          onChange={(e) => actions.setFieldValue('from_required_coi', e.target.checked ? 1 : 0)}
        />
      </div>

      <div className="new-table--row--item no-shadow">
        <Icon icon="move_out_insurance" title="Move In Insurance" />
        <CheckBox
          name="to_required_coi"
          className="justify-content-between"
          label="Insurance To?"
          checked={values['to_required_coi'] === 1}
          onChange={(e) => actions.setFieldValue('to_required_coi', e.target.checked ? 1 : 0)}
        />
      </div>
    </div>
  );
}

export default Insurance;

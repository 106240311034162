import { useMutation, useQueryClient } from 'react-query';
import { useApi } from 'context/api';
import queryKeys from 'config/queryKeys';
import makeFormData from 'utils/makeFormData';

export const useReminderDelete = (jobId) => {
  const { sms } = useApi();
  const queryClient = useQueryClient();

  return useMutation(sms.deleteReminder, {
    onSuccess: () => queryClient.invalidateQueries([queryKeys.reminders, jobId]),
  });
};

export const useReminderSave = (jobId) => {
  const { sms } = useApi();
  const queryClient = useQueryClient();

  return useMutation(sms.saveReminder, {
    onSuccess: () => queryClient.invalidateQueries([queryKeys.reminders, jobId]),
  });
};

export const useSend = (jobId, type) => {
  const { sms } = useApi();
  const queryClient = useQueryClient();
  const options = { headers: { 'Content-Type': 'multipart/form-data' } };
  return useMutation((message) => sms.send(makeFormData(message), options), {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.communication, jobId, type]);
    },
  });
};

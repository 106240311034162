import axios from 'axios';
import showError from 'utils/errorHandler';
import showSuccess from 'utils/successHandler';

class Api {
  constructor() {
    this.serverApi = process.env.REACT_APP_API_URL;
    this.client = axios.create({ baseURL: this.serverApi });
  }

  getData = async (jobId, type) => {
    try {
      return await this.client.get(`/api/customer/coi/certificate/info/${jobId}/${type}`);
    } catch (error) {
      showError(error);
    }
  };

  // showFile = async (path) => {
  //   try {
  //     return await this.client.post(`/api/showCertificatePdf`, {path});
  //   } catch (error) {
  //     showError(error);
  //   }
  // };

  approve = async (jobId, type, providerType) => {
    try {
      await this.client.post(`/api/customer/coi/certificate/approve`, {
        job_id: jobId,
        type: type,
        provider_type: providerType,
      });

      return true;
    } catch (error) {
      showError(error);
    }
  };

  reject = async (jobId, type, providerType, message) => {
    try {
      await this.client.post(`/api/customer/coi/certificate/reject`, {
        job_id: jobId,
        type: type,
        provider_type: providerType,
        message,
      });

      showSuccess({ message: 'Success' });
      return true;
    } catch (error) {
      showError(error);
    }
  };
}

export default new Api();

export const getAppropriateValue = (a, b, c) => {
  if (a && Number(a) !== 0) return Number(a);
  if (b && Number(b) !== 0) return Number(b);
  if (c && Number(c) !== 0) return Number(c);
  return 0;
};

export const getRate = (init, rates) => {
  const [first, second, third] = rates;

  const rate = parseFloat(first) || parseFloat(init);

  return rate + parseFloat(second) + parseFloat(third);
};

export const inRange = (v, min, max) => {
  return v > (min || 0) && v < max;
};

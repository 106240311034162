import { useCallback } from 'react';
import { useFormik } from 'formik';
import { v4 } from 'uuid';
import { prepareInitialValues, inventoryByCategories } from './utils';

export const useInventoryForm = (props) => {
  const { jobID, jobInventory, currentInventory, categories, onSubmit, closeHandler } = props;

  const { values, setFieldValue, handleSubmit } = useFormik({
    initialValues: {
      search: '',
      items: prepareInitialValues(jobInventory),
      inventoryByCategories: inventoryByCategories(currentInventory, categories),
    },
    onSubmit: ({ items }) => {
      onSubmit(items, { onSuccess: closeHandler });
    },
  });

  const addCustomHandler = useCallback(
    (search) => {
      if (!search) return;

      const localId = v4();

      const newItem = {
        id: localId,
        name: search,
        amount: 0,
        volume: 0,
        custom: 1,
        qty: 1,
        job_id: jobID,
        category_id: 10,
        condition_dest: '//',
        condition: '//',
        weight: 0,
        price: 0,
        dimension: { width: 0, length: 0, high: 0 },
      };

      setFieldValue('items', [...values.items, newItem]);
    },
    [setFieldValue, values.items, jobID]
  );

  const addNewItem = useCallback(
    (item) => {
      const arrayFromQty = Array.from(Array(item.qty).keys());

      const filledArray = arrayFromQty.map(() => ({
        ...item,
        job_id: jobID,
        qty: 1,
        volume: item.amount,
        condition_dest: '//',
        condition: '//',
        dimension: {
          width: 0,
          length: 0,
          high: 0,
        },
      }));

      const prepared = [...values.items, ...filledArray];

      setFieldValue('items', prepared);
    },
    [setFieldValue, values.items, jobID]
  );

  const changeDimensionHandler = useCallback(
    (e, index) => {
      const { name, value } = e.target;

      const prepared = values.items.map((item, i) => {
        if (i === index) {
          item.dimension[name] = Number(value.trim());
          return item;
        }
        return item;
      });

      setFieldValue('items', prepared);
    },
    [setFieldValue, values.items]
  );

  const removeItemHandler = useCallback(
    (index) => {
      const filtred = values.items.filter((_, i) => i !== index);
      setFieldValue('items', filtred);
    },
    [setFieldValue, values.items]
  );

  const handleSearch = (value) => {
    setFieldValue('search', value);

    if (value?.length >= 1) {
      const filtered = currentInventory.filter((item) => item.name.toLowerCase().includes(value.toLowerCase()));
      setFieldValue('inventoryByCategories', inventoryByCategories(filtered, categories));
    } else {
      setFieldValue('inventoryByCategories', inventoryByCategories(currentInventory, categories));
    }
  };

  const handleChangeCF = (event, index) => {
    const value = Number(event.target.value) || 0;

    const list = values.items.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          amount: value,
          volume: value,
        };
      }
      return item;
    });

    setFieldValue('items', list);
  };

  const handleChangeSymbol = (value, position, itemIndex) => {
    const current = values.items[itemIndex];

    const condition = current.condition.split('/');
    condition[position] = value;

    const name = `items[${itemIndex}].condition`;

    setFieldValue(name, condition.join('/'));
  };

  const handleBlur = (e) => setFieldValue('search', e.target.value);

  const handleSelect = (option) => {
    const val = option?.value;

    if (!val) return;

    const selectedItemFromOptions = currentInventory.find((opt) => opt.name === val);

    if (selectedItemFromOptions) {
      addNewItem({
        ...selectedItemFromOptions,
        inventory_id: selectedItemFromOptions.id,
      });
    } else {
      addCustomHandler(val);
      setFieldValue('inventoryByCategories', inventoryByCategories(currentInventory, categories));
    }
  };

  return {
    values,
    actions: {
      handleSubmit,
      handleChangeSymbol,
      addNewItem,
      changeDimensionHandler,
      removeItemHandler,
      handleSearch,
      handleBlur,
      handleSelect,
      handleChangeCF,
    },
  };
};

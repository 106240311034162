import React, { useState, useCallback, useMemo } from 'react';
import { useFormik } from 'formik';
import { useLocation } from 'react-router-dom';
import { matchPath } from 'react-router';
import { routePaths } from 'config/routes';
import { useTabInfo } from 'hooks/query/useJob';
import { formatL, isValid, formatFullDBDate } from 'helpers/dateHelper';
import Select from 'components/newComponents/Select';
import { useTaskUpdate, useTaskDelete } from 'hooks/query/useTask';
import { useDefault } from 'hooks/query/useCommon';
import Icon from 'components/Icon';
import DateSelect from 'components/DateSelect';
import TimeSelect from 'components/TimeSelect';
import { statusTypesOptions } from 'helpers/constants';

const TaskItem = ({ number, task, userId, jobId }) => {
  const { pathname } = useLocation();

  const {
    params: { id, tab },
  } = matchPath(pathname, { path: routePaths.JOBS });

  const { mutate: taskDelete } = useTaskDelete(id, tab);
  const { mutate: taskUpdate } = useTaskUpdate(id, tab);

  const [isDisabled, setIsDisabled] = useState(true);

  const {
    data: { users },
  } = useDefault();

  const {
    data: { tasks },
  } = useTabInfo(id, tab);

  const { values, handleChange, setFieldValue } = useFormik({
    initialValues: {
      ...task,
      due_date: isValid(task?.due_date) ? task.due_date : new Date(),
    },
    validateOnChange: true,
  });

  const saveTask = useCallback(() => {
    const { due_date, ...res } = values;
    setIsDisabled(true);

    const data = {
      ...res,
      due_date: formatFullDBDate(due_date),
    };

    const preparedTasks = tasks.map((task) => (task.id === values.id ? { ...data, job_id: jobId } : { ...task, job_id: jobId }));

    taskUpdate({ job_id: jobId, tasks: preparedTasks });
  }, [jobId, taskUpdate, tasks, values]);

  const agentsOption = useMemo(() => {
    const list = [...new Map([...users.agents, ...users.managers].map((user) => [user['id'], user])).values()];
    return list.map((user) => ({ value: user.id, label: user.first_name + ' ' + user.last_name }));
  }, [users.agents, users.managers]);

  return (
    <form>
      <div className="new-table--row">
        <div className="new-table--row--item w5 no-side-shadows justify-content-center">
          <span>{number}</span>
        </div>
        <div className="new-table--row--item w15 no-side-shadows justify-content-center">
          <input type="text" disabled={isDisabled} value={values.task} name="task" style={{ textAlign: 'center' }} onChange={handleChange} />
        </div>
        <div className="new-table--row--item w15 no-side-shadows justify-content-center select">
          <Select
            value={values.type}
            options={statusTypesOptions}
            isDisabled={isDisabled}
            onChange={(option) => setFieldValue('type', option.value)}
          />
        </div>
        <div className="new-table--row--item w20 no-side-shadows justify-content-center">
          {isDisabled && (isValid(task?.due_date) ? <span> {formatL(task.due_date) + ' ' + task.due_time}</span> : <span>No due date</span>)}

          {!isDisabled && (
            <div className="d-flex justify-content-around">
              <DateSelect
                minDate={new Date()}
                selected={values.due_date}
                onChange={({ name, date }) => setFieldValue(name, date)}
                placeholder="* Due date"
                name="due_date"
              />
              <TimeSelect
                name="due_time"
                onChange={({ name, date }) => setFieldValue(name, date)}
                placeholder="* Select time"
                value={values.due_time}
              />
            </div>
          )}
        </div>
        <div className="new-table--row--item w15 no-side-shadows justify-content-center">
          <span>{formatL(task.created_at)}</span>
        </div>
        <div className="new-table--row--item w20 no-side-shadows justify-content-center">
          <Select
            value={values.author_id}
            options={agentsOption}
            isDisabled={isDisabled}
            onChange={(option) => setFieldValue('author_id', option.value)}
          />
        </div>
        <div className="new-table--row--item w10 no-side-shadows justify-content-center">
          <button type="button" onClick={() => (isDisabled ? setIsDisabled((pre) => !pre) : saveTask())}>
            <Icon size={20} icon={isDisabled ? 'split_job' : 'booked'} title={isDisabled ? 'Edit' : 'Save'} />
          </button>
          <button type={'button'} onClick={() => (isDisabled ? taskDelete({ id: task.id }) : setIsDisabled((pre) => !pre))}>
            <Icon size={20} icon={isDisabled ? 'delete' : 'canceled'} title={isDisabled ? 'Delete' : 'Cancel'} />
          </button>
        </div>
      </div>
    </form>
  );
};

export default TaskItem;

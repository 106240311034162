import React, { memo } from 'react';
import TotalItem from './Totaltem';
import TotalHead from './TotalHead';
import TotalSummary from './TotalSummary';
import { getSymbolsOptions } from '../utils';

const InventoryTotalView = memo((props) => {
  const { items, statuses, onRemoveItem, handleChangeSymbol, onChangeDimension, handleChangeCF } = props;

  const symbols = getSymbolsOptions(statuses);

  return (
    <div className="new-table inventory-total">
      <TotalHead />

      <div className="inventory-items">
        {items.map((item, index) => (
          <TotalItem
            onChangeDimension={(e) => onChangeDimension(e, index)}
            onRemoveItem={() => onRemoveItem(index)}
            handleChangeCF={(e) => handleChangeCF(e, index)}
            onChangeSymbol={(value, position) => handleChangeSymbol(value, position, index)}
            symbols={symbols}
            item={item}
            key={index}
          />
        ))}
      </div>

      <TotalSummary items={items} />
    </div>
  );
});

export default InventoryTotalView;

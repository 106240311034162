import React, { Fragment, useCallback } from 'react';
import { useModal } from 'context/modal';
import { useReminderDelete } from 'hooks/query/useReminder';
import moment from 'moment';
import Icon from 'components/Icon';

const ReminderItem = ({ reminder, jobId }) => {
  const { modalNames, open } = useModal();
  const { mutate: deleteReminder } = useReminderDelete(jobId);

  const deleteItem = useCallback(() => {
    deleteReminder({ reminder_id: reminder.id, job_id: jobId });
  }, [deleteReminder, jobId, reminder.id]);

  const showActionModal = () => {
    open(modalNames.editReminder, { id: reminder.id });
  };

  return (
    <div className="new-table--row" key={reminder.id}>
      <div className="new-table--row--item w40 justify-content-center no-side-shadows">
        <span>{reminder.subject}</span>
      </div>
      <div className="new-table--row--item w20 justify-content-center no-side-shadows">
        <span>{reminder.email_to}</span>
      </div>
      <div className="new-table--row--item w20 justify-content-center no-side-shadows">
        <span>{moment.unix(reminder.send_time).format('l LT')}</span>
      </div>
      {+reminder.type === 0 && (
        <Fragment>
          <div className="new-table--row--item w10 justify-content-center no-side-shadows">
            <button onClick={showActionModal}>
              <Icon size={20} icon="split_job" title="Edit" />
            </button>
          </div>
          <div className="new-table--row--item w10 justify-content-center no-side-shadows">
            <button onClick={deleteItem}>
              <Icon size={20} icon="delete" title="Delete" />
            </button>
          </div>
        </Fragment>
      )}
      {+reminder.type !== 0 && (
        <div className="new-table--row--item w20 justify-content-center no-side-shadows">
          <span>Sent</span>
        </div>
      )}
    </div>
  );
};

export default ReminderItem;

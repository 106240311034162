const notesOptions = [
  {
    value: 1,
    label: 'From',
  },
  {
    value: 2,
    label: 'To',
  },
];
export default notesOptions;

import React, { useState } from 'react';
import PackingItem from './PackingItem';

const PackingList = ({ packing, type, addItem }) => {
  const [open, setOpen] = useState(false);

  return (
    <div className="new-table">
      <div className="new-table--row bcg-light">
        <div className="justify-content-center new-table--row--item w35">
          <b>Name</b>
        </div>
        <div className="justify-content-center new-table--row--item w10">
          <b>Price</b>
        </div>
        <div className="justify-content-center new-table--row--item w25">
          <b>Size</b>
        </div>
        <div className="justify-content-center new-table--row--item w15">
          <b>Quantity</b>
        </div>
        <div className="justify-content-center new-table--row--item w15" />
      </div>
      <div className="packing-list-body">
        {packing?.length > 0 &&
          packing
            .slice(0, !open ? 5 : packing.length)
            .map((item) => <PackingItem onAdd={addItem} key={item.id} item={item} />)}
      </div>
      <div className="new-table--row no-shadow">
        <div className="new-table--row--item">
          <button className="default flex-grow-1" onClick={() => setOpen((pre) => !pre)}>
            {!open ? 'Show more' : 'Show less'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PackingList;

import React, { Fragment } from 'react';
import { modalNames } from './data';

const Footer = ({ contract, setModal }) => {
  return (
    <footer>
      {contract && (
        <Fragment>
          {!contract.signs.ld_contract_acknowledge_client &&
            !contract.signs.ld_contract_interstate_bill_client &&
            !contract.signs.ld_contract_interstate_bill_client_initials &&
            !contract.signs.ld_contract_terms_of_payment && (
              <button type="button" className="btn" onClick={() => setModal(modalNames.terms_of_payment)}>
                Sign
              </button>
            )}

          {contract.signs.ld_contract_terms_of_payment &&
            !contract.signs.ld_contract_acknowledge_client &&
            !contract.signs.ld_contract_interstate_bill_client &&
            !contract.signs.ld_contract_interstate_bill_client_initials && (
              <button type="button" className="btn" onClick={() => setModal(modalNames.acknowledge_terms)}>
                Sign
              </button>
            )}

          {contract.signs.ld_contract_terms_of_payment &&
            contract.signs.ld_contract_acknowledge_client &&
            !contract.signs.ld_contract_interstate_bill_client &&
            !contract.signs.ld_contract_interstate_bill_client_initials &&
            contract.insurance_option === 0 && (
              <button type="button" className="btn" onClick={() => setModal(modalNames.insurance_options)}>
                Insurance
              </button>
            )}

          {contract.signs.ld_contract_terms_of_payment && contract.signs.ld_contract_acknowledge_client && contract.insurance_option !== 0 && (
            <Fragment>
              {contract.insurance_option === 1 && (
                <Fragment>
                  {!contract.signs.ld_contract_interstate_bill_client && (
                    <button type="button" className="btn" onClick={() => setModal(modalNames.customer_sign)}>
                      Customer Signature
                    </button>
                  )}
                </Fragment>
              )}

              {contract.insurance_option === 2 && (
                <Fragment>
                  {!contract.signs.ld_contract_interstate_bill_client && !contract.signs.ld_contract_interstate_bill_client_initials && (
                    <button type="button" className="btn" onClick={() => setModal(modalNames.customer_initial)}>
                      Customer Initials
                    </button>
                  )}
                  {contract.signs.ld_contract_interstate_bill_client_initials && !contract.signs.ld_contract_interstate_bill_client && (
                    <button type="button" className="btn" onClick={() => setModal(modalNames.customer_sign)}>
                      Customer Signature
                    </button>
                  )}
                </Fragment>
              )}
            </Fragment>
          )}
        </Fragment>
      )}
    </footer>
  );
};

export default Footer;

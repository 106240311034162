import Swal from 'sweetalert2';

const feesTooltip = (storage_calc) => {
  Swal.fire({
    title: 'Fees',
    html: `
        <div class="fees-tooltip">
            <div class="fees-tooltip-item__header">
              <span>Fee</span>
              <span>Amount</span>
            </div>
            <div class="fees-tooltip-item">
              <span>Deposit amount</span>
              <span class="fees-tooltip-item__bold">${storage_calc.storage_deposit} $</span>
            </div>
            <div class="fees-tooltip-item">
              <span>Admin fee</span>
              <span class="fees-tooltip-item__bold">${storage_calc.admin_fee} $</span>
            </div>
            <div class="fees-tooltip-item">
              <span>Delivery fee</span>
              <span class="fees-tooltip-item__bold">${storage_calc.delivery_fee} $</span>
            </div>
            <div class="fees-tooltip-item">
              <span>Redelivery fee</span>
              <span class="fees-tooltip-item__bold">${storage_calc.redelivery_fee} $</span>
            </div>
            <div class="fees-tooltip-item">
              <span>Hold fee</span>
              <span class="fees-tooltip-item__bold">${storage_calc.hold_fee} $</span>
            </div>
            <div class="fees-tooltip-item">
              <span>Insurance fee</span>
              <span class="fees-tooltip-item__bold">${storage_calc.insurance_fee} $</span>
            </div>
            <p>
              Customer have to pay all fees and Deposit to confirm the move to Storage
            </p>
        </div>
      `,
    showCloseButton: true,
  });
};

const alertDenySelfBooking = (companyPhone) => {
  Swal.fire(
    'We are sorry, its already passed cut off time to book your job automatically. ' +
    'We need advance notice to be able to build a schedule properly. ' +
    'We will still do our best to accommodate you. ' +
    `Please call your sales agent or our general line at ${companyPhone}`
  );
};

const alertBookingExpiration = (companyPhone) => {
  Swal.fire(
    'We are sorry, its already passed cut off time to book your job automatically. ' +
    `Please call your sales agent or our general line at ${companyPhone}`
  );
};

const alertNeedAdvanceNotice = (companyPhone) => {
  Swal.fire(
    'We are sorry, its already passed cut off time to book your job automatically. ' +
    'We need advance notice to be able to build a schedule properly. ' +
    'We will still do our best to accommodate you. ' +
    `Please call your sales agent or our general line at ${companyPhone}`
  );
};

export { feesTooltip, alertDenySelfBooking, alertBookingExpiration, alertNeedAdvanceNotice };

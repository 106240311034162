import React from 'react';
import { useLocation } from 'react-router-dom';
import * as qs from 'querystring';
import QuickView from './components/QuickView';
import Category from './components/Category';
import SearchPanel from './components/SearchPanel';
import Preloader from 'components/Preloader';
import { useBuildInventory } from './useBuildInventory';
import { useBuildCategoriesList } from './useBuildCategoriesList';
import { useGetInventory, useSaveInventory } from './useController';
import './style.scss';

function App() {
  const { search } = useLocation();
  const { job_id = '' } = qs.parse(search.replace('?', ''));

  const { data: inventoryData = {}, isLoading } = useGetInventory(job_id);

  const { mutate: saveInventory } = useSaveInventory();

  const {
    values,
    actions: { handleCancelButton, handleDeleteItem, addItemToJobInventory, changeQty },
  } = useBuildInventory(inventoryData);

  const {
    categories,
    actions: { handleSearch },
  } = useBuildCategoriesList(inventoryData);

  const handleSaveInventory = () => {
    saveInventory({ job_id, items: values.items });
  };

  return (
    <div className="container container-inventory">
      {isLoading && <Preloader />}

      <div className="new-table">
        <h1>Inventory</h1>
        <SearchPanel onSearch={handleSearch} />

        {categories.map((category, index) => {
          return <Category key={index} name={category.name} items={category.list} addItemToJobInventory={addItemToJobInventory} />;
        })}
      </div>

      <QuickView
        job_inventory={values}
        changeQty={changeQty}
        handleCancelButton={handleCancelButton}
        handleDeleteItem={handleDeleteItem}
        handleSave={handleSaveInventory}
      />
    </div>
  );
}

export default App;

import React, { useMemo, memo } from 'react';
import Select from 'react-select';

const ReactSelect = memo((props) => {
  const { options, name, onChange, disabled, placeholder, value, isCreatable = false, error, wrapperStyle, portal = false, ...res } = props;

  const styles = {
    option: (provided) => ({
      ...provided,
      cursor: 'pointer',
      color: '#000',
    }),
    control: () => [{ border: 'none', display: 'flex', height: '100%', backgroundColor: '#fff', fontWeight: 700 }],
    indicatorSeparator: () => ({ display: 'none' }),
    menu: (provided) => ({ ...provided, zIndex: 3 }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  };

  const currentValue = useMemo(() => {
    if (!value && typeof value !== 'number' && typeof value !== 'string') return null;
    const current = options?.find((e) => e.value === value) ?? null;
    if (!current && isCreatable) return { value: value, label: value };
    return current;
  }, [isCreatable, options, value]);

  return (
    <div className="d-flex flex-column position-relative" style={wrapperStyle}>
      <Select
        name={name}
        value={currentValue}
        styles={styles}
        options={options}
        onChange={onChange}
        isDisabled={disabled}
        placeholder={placeholder}
        menuPortalTarget={portal ? document.getElementById('portal') : null}
        {...res}
      />

      {error && (
        <span style={{ position: 'absolute', bottom: -15 }} className="error">
          {error}
        </span>
      )}
    </div>
  );
});

export default ReactSelect;

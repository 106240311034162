import React, { PureComponent } from 'react';
import Icon from 'components/Icon';

class SearchPanel extends PureComponent {
  state = { search: '' };

  handleChange = ({ target }) => {
    this.setState({ search: target.value });
    this.props.onSearch(target.value);
  };

  handleClear = () => {
    this.setState({ search: '' });
    this.props.onSearch('');
  };

  render() {
    const { search } = this.state;

    return (
      <div className="new-table--row">
        <div className="new-table--row--item no-side-shadows">
          <Icon icon="search" size={20} />

          <input type="text" placeholder="Search ..." value={search} name="search" onChange={this.handleChange} />

          {search.length > 2 && (
            <button type="button" onClick={this.handleClear}>
              <Icon icon="delete_cross" size={20} style={{ marginRight: 0 }} />
            </button>
          )}
        </div>
      </div>
    );
  }
}

export default SearchPanel;

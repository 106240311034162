import React, { Fragment, useState } from 'react';
import { useDefault } from 'hooks/query/useCommon';
import getAbbr from 'helpers/Abbr';
import ProviderJob from './ProviderJob';

const ProviderUser = (props) => {
  const info = props.data;
  const total = info.jobs.reduce((sum, job) => (job.status === 6 ? Number(sum) + Number(job.final_total) : sum), 0);

  const x = total !== 0 ? info.count.booked / (info.count.calls / 100) : 0;

  const { data: defaultData } = useDefault();
  const companiesABBR = defaultData?.companies_abbr;

  const [open, setOpen] = useState(false);

  return (
    <Fragment>
      <div className="reports-table" style={{ cursor: 'pointer' }} onClick={() => setOpen(!open)}>
        <div className={open ? 'reports-table-row reports-select' : 'reports-table-row'}>
          <div className="reports-table-column reports-table-column--12">{info.name}</div>
          <div className="reports-table-column reports-table-column--12">{getAbbr(info.company_id, companiesABBR)}</div>
          <div className="reports-table-column reports-table-column--12">{info.count.calls}</div>
          <div className="reports-table-column reports-table-column--12">{info.count.receive}</div>
          <div className="reports-table-column reports-table-column--12">{info.count.booked}</div>
          <div className="reports-table-column reports-table-column--12">{info.count.lost}</div>
          <div className="reports-table-column reports-table-column--12">{info.count.canceled}</div>
          <div className="reports-table-column reports-table-column--12">{info.count.completed}</div>
          <div className="reports-table-column reports-table-column--12">${info.lead_cost}</div>
          <div className="reports-table-column reports-table-column--12">${info.count.receive * info.lead_cost}</div>
          <div className="reports-table-column reports-table-column--12">${total.toFixed(2)}</div>
          <div className="reports-table-column reports-table-column--12">{x.toFixed(0)}%</div>
        </div>
      </div>

      {open && <ProviderJob data={info.jobs} user_id={info.id} />}
    </Fragment>
  );
};

export default ProviderUser;

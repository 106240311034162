import axios from 'axios';
import showError from 'utils/errorHandler';
// import showSuccess from 'utils/successHandler';

class Api {
  constructor() {
    this.serverApi = process.env.REACT_APP_API_URL;
    this.client = axios.create({ baseURL: this.serverApi });
  }

  getData = async (jobId, document) => {
    try {
      const response = await this.client.post('/api/customerDocuments/paymentData', {
        job_id: jobId,
        document,
      });

      return response.data;
    } catch (error) {
      showError(error);
    }
  };

  sendPaymentRequest = (data) => {
    this.client.post('/api/stripe/payment_request', data);
  };

  stripeCharge = (data) => {
    return this.client.post('/api/stripe/charge', data);
  };
}

export default new Api();

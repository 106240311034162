import React, { Component } from 'react';
import Icon from 'components/Icon';
import getTitle from 'helpers/GetTitle';
import { statusNumberToName } from 'helpers/statuses';

class ProviderJob extends Component {
  getJobType = (type_id) => {
    switch (+type_id) {
      case 1:
        return 'LO';
      case 2:
        return 'LD';
      case 3:
        return 'Labor';
      case 4:
        return 'Storage';
      default:
        return 'Unset';
    }
  };

  render() {
    if (!this.props.data) return null;
    let jobs = [];

    this.props.data.forEach((job, key) => {
      jobs.push(
        <div className="reports-table-row" key={key}>
          <div className="reports-table-column reports-table-column--7" />
          <div className="reports-table-column reports-table-column--7">{job.title}</div>
          <div className="reports-table-column reports-table-column--7">
            <Icon icon={statusNumberToName(job.status)} size={24} title={getTitle(job.status)} />
          </div>
          <div className="reports-table-column reports-table-column--7">{job.pickup_date}</div>
          <div className="reports-table-column reports-table-column--7">{this.getJobType(job.type)}</div>
          <div className="reports-table-column reports-table-column--7">{job.first_name + ' ' + job.last_name}</div>
          <div className="reports-table-column reports-table-column--7">{job.final_total}</div>
        </div>
      );
    });
    return (
      <div className="reports-table">
        <div className="reports-table-row" key={'jobs thead'}>
          <div className="reports-table-column reports-table-column--7 bcg-light" />
          <div className="reports-table-column reports-table-column--7 reports-table-column--title bcg-light">Job ID</div>
          <div className="reports-table-column reports-table-column--7 reports-table-column--title bcg-light">Status</div>
          <div className="reports-table-column reports-table-column--7 reports-table-column--title bcg-light">Date</div>
          <div className="reports-table-column reports-table-column--7 reports-table-column--title bcg-light">Job type</div>
          <div className="reports-table-column reports-table-column--7 reports-table-column--title bcg-light">Agent</div>
          <div className="reports-table-column reports-table-column--7 reports-table-column--title bcg-light">Total</div>
        </div>
        {jobs}
      </div>
    );
  }
}

export default ProviderJob;

import React, { Fragment, useMemo } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import Filter from './Filter';
import BookingTotal from './BookingTotal';
import BookingCharts from './BookingCharts';
import UsersList from './UsersList';
import Preloader from 'components/Preloader';
import { useReportFilterInfo, useGetBookingReport, useGetUserNames } from 'hooks/query/useReport';
import { changePeriod } from '../reportUtils';

const Booking = () => {
  const { data } = useReportFilterInfo();
  const { mutate: getBookingReport, data: bookingReportResponse, isLoading } = useGetBookingReport();
  const report = bookingReportResponse?.data;

  const companiesList = useMemo(() => {
    if (!data) return [];

    const list = data.companies.map((company) => ({
      value: [company.id],
      label: company.company_name,
    }));
    list.unshift({ value: data.all_companies, label: 'All Companies' });

    return list;
  }, [data]);

  const { values, touched, errors, setFieldValue, handleSubmit } = useFormik({
    initialValues: {
      company_id: companiesList[0]?.value ?? [],
      from: null,
      to: null,
      period_type: 0,
      user_id: [],
    },
    validationSchema: Yup.object().shape({
      user_id: Yup.array().min(1, 'Select agent').required('Select agent'),
    }),
    onSubmit: (values) => getBookingReport(values),
    enableReinitialize: true,
  });

  const selectCompany = ({ value }) => setFieldValue('company_id', value);

  const selectDate = ({ name, date }) => setFieldValue(name, moment(date).format('MM/DD/YY'));

  const selectPeriod = ({ value }) => {
    const { from, to } = changePeriod(value);
    setFieldValue('from', from);
    setFieldValue('to', to);
    setFieldValue('period_type', value);
  };

  const selectAllUsers = (list) => {
    if (values['user_id'].length === list.length) {
      setFieldValue('user_id', []);
      return;
    }
    setFieldValue(
      'user_id',
      list.map((user) => user.id)
    );
  };

  const selectUser = (id) => {
    if (values['user_id'].includes(id)) {
      setFieldValue(
        'user_id',
        values['user_id'].filter((i) => i !== id)
      );
      return;
    }

    setFieldValue('user_id', [...values['user_id'], id]);
  };

  const isValidHandler = (name) => {
    if (errors[name] && touched[name]) return errors[name];
    return null;
  };

  const { data: userNamesResponse } = useGetUserNames(
    { company_id: values['company_id'], user_type: 1 },
    {
      enabled: Boolean(values['company_id'].length),
      onSuccess: ({ user_names }) => selectAllUsers(user_names),
    }
  );

  const userNames = useMemo(() => {
    if (!userNamesResponse) return [];

    return userNamesResponse.user_names.map((user) => ({ ...user, value: user.id, label: `${user.first_name} ${user.last_name}` }));
  }, [userNamesResponse]);

  const users = useMemo(() => {
    if (!report || !report.users || !userNames) return [];

    return Object.entries(report.users).map((user) => ({
      ...userNames.find((i) => Number(i.id) === Number(user[0])),
      data: user[1],
    }));
  }, [report, userNames]);

  if (!data) return null;

  return (
    <Fragment>
      {isLoading && <Preloader />}

      <Filter
        data={{ values, companiesList, userNames }}
        actions={{ selectCompany, selectPeriod, selectDate, isValidHandler, selectUser, selectAllUsers, handleSubmit }}
      />

      {report && report.all && (
        <div className="new-table mb-20 mt-20">
          <div className="new-table--row">
            <div className="new-table--row--item col-4">
              <BookingCharts list={report.all} />
            </div>
            <div className="new-table--row--item new-table--row--item__column">
              <BookingTotal list={report.all} />
            </div>
          </div>
        </div>
      )}

      <UsersList users={users} />
    </Fragment>
  );
};

export default Booking;

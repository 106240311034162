import React, { Fragment, useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { useLostJobs, useRefuseReasons } from 'hooks/query/useJobs';
import { useAuth } from 'context/auth';
import { useActivity } from 'hooks/query/useAuth';
import { useDefault } from 'hooks/query/useCommon';
import routes, { routePaths } from 'config/routes';
import Twilio from 'pages/modal/Twilio';
import Modals from 'pages/modal';
import ImportantNotes from '../ImportantNotes';
import Communications from '../Communications';
import CrewSchedule from '../Crew/CrewSchedule';
import CalendarPage from '../CalendarPage';
import Navbar from '../Navbar/Navbar';
import Jobs from '../Jobs';
import ReportRoot from '../Report';
import WSNotification from './WSNotification';
import './Main.scss';

const Main = () => {
  const { mutate } = useRefuseReasons();
  const { user } = useAuth();
  const { data: defaultItems } = useDefault();

  useLostJobs({ refuseReasonsMutate: mutate });

  const { mutate: activity } = useActivity();

  useEffect(() => {
    let intervalId;
    if (user) {
      activity(user.id);
      intervalId = setInterval(() => {
        activity(user.id);
      }, 15000);
    }
    return () => {
      clearInterval(intervalId);
    };
  }, [activity, user]);

  return (
    <Fragment>
      <Navbar />
      <WSNotification User={user} DefaultInfo={defaultItems} />
      <div className="page-container">
        <main>
          <Switch>
            <Route path={routePaths.JOBS} component={Jobs} />
            <Route path={routePaths.COMMUNICATIONS} component={Communications} />
            <Route path={routePaths.CALENDAR} component={CalendarPage} />
            <Route path={routePaths.REPORTS} component={ReportRoot} />
            <Route path={routePaths.NOTES} component={ImportantNotes} />
            <Route path={routes.AVAILABLE} component={CrewSchedule} />
            <Redirect to={routePaths.JOBS} />
          </Switch>
        </main>

        <Modals />
        <Twilio />
      </div>
    </Fragment>
  );
};

export default Main;

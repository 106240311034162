const chartsConfig = [
  { type: 'string', id: 'Date' },
  { type: 'string', id: 'Name' },
  { type: 'string', id: 'style', role: 'style' },
  { type: 'string', role: 'tooltip' },
  { type: 'date', id: 'Start' },
  { type: 'date', id: 'End' },
];

export default chartsConfig;

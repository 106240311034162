import React, { useCallback, Fragment } from 'react';
import classNames from 'classnames';
import Select from 'components/newComponents/Select';
import Icon from 'components/Icon';

const CrewUnit = (props) => {
  const { crew, available, onSelectUnit, onChangeUnit, foreman, onSelectForeman, formatOptionLabel, filterAssignedCrew } = props;

  const getOptions = useCallback(
    (i) => {
      if (!crew) return [];

      const current = crew[i];
      const units = available[`${current.unit_type}s`];

      if (!units?.length) return [];

      return filterAssignedCrew(units).map((e) => ({
        label: `${e.name} ${e.userlastname ? e.userlastname : ''}`.trim(),
        value: e.id,
        overlap: e?.overlap,
        type: current.unit_type,
      }));
    },
    [available, crew, filterAssignedCrew]
  );

  return (
    <Fragment>
      {Object.values(crew || {}).map((unit, i) => {
        return (
          <div key={`${unit.unit_name}_${unit.id}`} className="new-table--row">
            <label className="new-table--row--item no-side-shadows justify-content-start col-5">
              <input value={unit.id} type="checkbox" checked={unit.checked} disabled={!unit.available} onChange={() => onSelectUnit(i)} />

              <span style={{ fontWeight: 700, marginLeft: 20 }}>
                {unit.unit_name}
                {unit.overlap && <Icon icon="task_unfinished" style={{ marginLeft: 10 }} size={20} />}
              </span>
            </label>

            <div className={classNames('new-table--row--item justify-content-center select no-side-shadows', { available: unit.available })}>
              {unit.available && !unit.overlap ? (
                'Available'
              ) : (
                <Select options={getOptions(i)} formatOptionLabel={formatOptionLabel} onChange={(option) => onChangeUnit(i, option)} />
              )}
            </div>

            <div className="new-table--row--item no-side-shadows">
              {unit.unit_type === 'truck' ? null : (
                <label>
                  <input
                    type="checkbox"
                    value={unit.id}
                    disabled={!unit.checked}
                    checked={unit.id === foreman.unit_id && unit.unit_type === foreman.unit_type}
                    onChange={() => onSelectForeman(unit.id, unit.unit_type)}
                  />
                  <span style={{ fontWeight: 700, marginLeft: 20 }}>Foreman</span>
                </label>
              )}
            </div>
          </div>
        );
      })}
    </Fragment>
  );
};

export default CrewUnit;

import React, { Fragment } from 'react';
import Switch from 'react-switch';

function Settings({ state, crewUnit, actions, changeAvailability, onSave }) {
  return (
    <div className="table-form">
      <div className="col-4 p-0 d-flex">
        <label className="col-6 d-flex justify-content-start p-0">
          <input className="" type="checkbox" checked={state.multiSelect} onChange={actions.onChangeMultiSelect} />
          Multi select
        </label>
        <label className="col-6 d-flex justify-content-start p-0">
          <input className="" type="checkbox" checked={state.rangeSelect} onChange={actions.onChangeRangeSelect} />
          Range select
        </label>
      </div>

      <div className="col-4 p-0 d-flex align-items-center justify-content-center">
        {crewUnit && (
          <Fragment>
            <Switch onChange={changeAvailability} checked={crewUnit.available} />
            <span className="ml-4">
              {state.selectedDay.format('LL')} {crewUnit.unit_name}, {crewUnit.unit_type} availability
            </span>
          </Fragment>
        )}
      </div>

      <div className="col-4 p-0 d-flex align-items-center">
        <button type="button" className="save-btn" onClick={onSave}>
          Save
        </button>
      </div>
    </div>
  );
}

export default Settings;

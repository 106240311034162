import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useApi } from 'context/api';
import queryKeys from 'config/queryKeys';

export const useInfo = (id) => {
  const { reminder } = useApi();

  return useQuery([queryKeys.reminder, id], () => reminder.info({ reminder_id: id }), { select: (res) => res.data });
};

export const useList = (jobId) => {
  const { reminder } = useApi();

  return useQuery([queryKeys.reminders, jobId], () => reminder.list({ job_id: jobId }), {
    select: (res) => res.data,
  });
};

export const useReminderDelete = (jobId) => {
  const { reminder } = useApi();
  const queryClient = useQueryClient();

  return useMutation(reminder.delete, {
    onSuccess: () => queryClient.invalidateQueries([queryKeys.reminders, jobId]),
  });
};

export const useSave = () => {
  const { reminder } = useApi();
  const queryClient = useQueryClient();

  return useMutation(reminder.save, {
    onSuccess: () => queryClient.invalidateQueries([queryKeys.reminders]),
  });
};

export const useMailChainsSuggest = () => {
  const { reminder } = useApi();

  return useMutation(reminder.suggestMailChains);
};

export const useApplyChain = (jobId) => {
  const { reminder } = useApi();
  const queryClient = useQueryClient();

  return useMutation(reminder.applyChain, {
    onSuccess: () => queryClient.invalidateQueries([queryKeys.reminders, jobId]),
  });
};

export const useFollowUpChainCreate = () => {
  const { reminder } = useApi();

  return useMutation(reminder.createFollowUpChain);
};

export const useChains = () => {
  const { reminder } = useApi();

  return useQuery(queryKeys.chains, reminder.chains, { select: (res) => res.data.chains });
};

export const useChainSave = () => {
  const { reminder } = useApi();
  const queryClient = useQueryClient();

  return useMutation(reminder.saveChain, { onSuccess: () => queryClient.invalidateQueries(queryKeys.chains) });
};

export const useChainDelete = () => {
  const { reminder } = useApi();
  const queryClient = useQueryClient();

  return useMutation(reminder.deleteChain, { onSuccess: () => queryClient.invalidateQueries(queryKeys.chains) });
};

export const useRemindersChain = (id, options) => {
  const { reminder } = useApi();

  return useQuery([queryKeys.remaindersChain, id], () => reminder.getRemindersChain({ chain_id: id }), {
    select: (res) => res.data.reminders,
    ...options,
  });
};

export const useReminderChainDelete = (id) => {
  const { reminder } = useApi();
  const queryClient = useQueryClient();

  return useMutation(reminder.deleteReminderChain, { onSuccess: () => queryClient.invalidateQueries([queryKeys.remaindersChain, id]) });
};

export const useReminderChainSave = (id) => {
  const { reminder } = useApi();
  const queryClient = useQueryClient();

  return useMutation(reminder.saveChainReminder, { onSuccess: () => queryClient.invalidateQueries([queryKeys.remaindersChain, id]) });
};

import React, { Fragment, memo, useState, useMemo, useCallback } from 'react';
import { useLocation, useHistory, generatePath } from 'react-router-dom';
import classNames from 'classnames';
import { useChangeMailPriority, useMailsRead } from 'hooks/query/useCommunications';
import { routePaths } from 'config/routes';
import getAbbr from 'helpers/Abbr';
import Dropdown from 'components/Dropdown';
import Icon from 'components/Icon';
import updatePriority from 'helpers/UpdatePriority';
import { useDefault } from 'hooks/query/useCommon';

const Item = memo(({ onChangeParams, selectedItems, setSelectedItems, ...props }) => {
  const history = useHistory();
  const { search } = useLocation();
  const { data: defaultItems } = useDefault();
  const { mutate: changePriority } = useChangeMailPriority();
  const { mutateAsync: readEmail } = useMailsRead();

  const companiesABBR = defaultItems?.companies_abbr;

  const [priority, setPriority] = useState({
    priorityTitle: updatePriority(props.priority).priorityTitle,
    priorityStyle: updatePriority(props.priority).priorityStyle,
  });

  const params = useMemo(() => {
    const searchParams = new URLSearchParams(search);

    const result = {};

    for (var pair of searchParams.entries()) {
      result[pair[0]] = pair[1];
    }

    return result;
  }, [search]);

  const handleOpenEmail = useCallback(
    async (e) => {
      e.stopPropagation();

      if (props.entity_type === 'inbox_email') {
        await readEmail({ ids: [props.id] });
      }

      onChangeParams({
        selected: props.id,
        selected_type: props.entity_type,
      });
    },
    [onChangeParams, readEmail, props.entity_type, props.id]
  );

  const handleChangePriority = useCallback(
    (priority) => {
      const { priorityStyle, priorityTitle } = updatePriority(priority);

      setPriority({ priorityStyle, priorityTitle });
      changePriority({ mail_id: props.id, priority });
    },
    [changePriority, props.id]
  );

  const handleOpenJob = useCallback(
    (e) => {
      e.stopPropagation();

      history.push({
        pathname: generatePath(routePaths.JOBS, { id: props.job_id, tab: 'general', type: 'all' }),
      });
    },
    [history, props.job_id]
  );

  const isChecked = useMemo(() => {
    return selectedItems.some((id) => id === props.id);
  }, [selectedItems, props.id]);

  const handleSelect = useCallback(() => {
    const selected = [...selectedItems];

    if (isChecked) {
      const index = selected.findIndex((id) => id === props.id);
      selected.splice(index, 1);

      setSelectedItems(selected);
    } else {
      selected.push(props.id);
      setSelectedItems(selected);
    }
  }, [isChecked, props.id, selectedItems, setSelectedItems]);

  const isActiveRow = +params.selected === props.id;

  return (
    <div
      onClick={handleOpenEmail}
      key={'email' + props.id}
      className={classNames('comm-item border', {
        'active-row': isActiveRow,
        email_read: !isActiveRow && props.read !== 0 && props.type === 2 && props.entity_type !== 'reminder',
      })}
    >
      <button className="w5" onClick={handleOpenEmail}>
        <Icon icon={props.read === 1 ? 'email_read' : 'email'} title={props.read === 1 ? 'Mail opened' : 'Mail unread'} />
      </button>
      <div
        data-tip=""
        data-for={props.title + 'info'}
        className={classNames('comm-item', {
          'active-row': isActiveRow,
          email_read: !isActiveRow && props.read !== 0 && props.type === 2 && props.entity_type !== 'reminder',
        })}
      >
        <div className="w5">
          <Dropdown
            iconSize={22}
            iconName={priority.priorityStyle}
            dropdownClass="w5 dropdown-status"
            divClassname="drop-menu"
            horizontal
            iconTitle={priority.priorityTitle}
            autoHide
          >
            <Fragment>
              {defaultItems?.statuses?.job_priority?.map((prior, index) => (
                <li key={prior}>
                  <button type="button" onClick={() => handleChangePriority(index)}>
                    <Icon icon={prior.toLowerCase()} size={20} title={prior} />
                  </button>
                </li>
              ))}
            </Fragment>
          </Dropdown>
        </div>

        <b className="w10">{props.company_id ? getAbbr(props.company_id, companiesABBR) : ''}</b>
        <b className="w15">
          {props.title ? (
            <button className="main" onClick={handleOpenJob}>
              {props.title}
            </button>
          ) : (
            ''
          )}
        </b>
        <button onClick={handleOpenEmail} className="comm-item--open">
          {props.type === 2 && (
            <Fragment>
              <span className="w25">
                From: {props.email_from ? props.email_from.slice(0, 25) : ''}
                {props.email_from.length > 25 ? '...' : ''}
              </span>
              <span className="w50">{props?.subject?.slice(0, 38) ?? ''}</span>
            </Fragment>
          )}
          {props.type === 1 && (
            <Fragment>
              <span className="w25">
                To: {props?.email_to?.slice(0, 25)}
                {props?.email_to?.length > 25 ? '...' : ''}
              </span>

              <span className="w50">{props?.subject?.slice(0, 38) ?? ''}</span>
            </Fragment>
          )}
          {props.type !== 1 && props.type !== 2 && (
            <Fragment>
              <span className="w25">
                {props.email_from ? props.email_from.slice(0, 25) : ''}
                {props.email_from && props.email_from.length > 25 ? '...' : ''}
              </span>
              <span className="w25">
                {props?.email_to?.slice(0, 25)}
                {props?.email_to?.length > 25 ? '...' : ''}
              </span>
            </Fragment>
          )}

          <span className="w25">{props.created_at}</span>
        </button>
      </div>

      <div className="w5 d-flex justify-content-center">
        <input type="checkbox" checked={isChecked} onChange={handleSelect} onClick={(e) => e.stopPropagation()} />
      </div>
    </div>
  );
});

export default Item;

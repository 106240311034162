import React, { Fragment } from 'react';

const FeesInfo = ({ data }) => {
  return (
    <Fragment>
      {Number(data.admin_fee) > 0 && (
        <div className="new-table--row">
          <div className="new-table--row--item col-4 no-shadow no-padding-y" style={{ paddingLeft: 53 }}>
            Admin fee
          </div>
          <div className="new-table--row--item col-2 no-shadow no-padding-y">
            <span>${data.admin_fee} $</span>
          </div>
        </div>
      )}
      {Number(data.delivery_fee) > 0 && (
        <div className="new-table--row">
          <div className="new-table--row--item col-4 no-shadow no-padding-y" style={{ paddingLeft: 53 }}>
            Delivery fee
          </div>
          <div className="new-table--row--item col-2 no-shadow no-padding-y">
            <span>${data.delivery_fee} $</span>
          </div>
        </div>
      )}
      {Number(data.redelivery_fee) > 0 && (
        <div className="new-table--row">
          <div className="new-table--row--item col-4 no-shadow no-padding-y" style={{ paddingLeft: 53 }}>
            Redelivery fee
          </div>
          <div className="new-table--row--item col-2 no-shadow no-padding-y">
            <span>${data.redelivery_fee} $</span>
          </div>
        </div>
      )}
      {Number(data.hold_fee) > 0 && (
        <div className="new-table--row">
          <div className="new-table--row--item col-4 no-shadow no-padding-y" style={{ paddingLeft: 53 }}>
            Hold fee
          </div>
          <div className="new-table--row--item col-2 no-shadow no-padding-y">
            <span>${data.hold_fee} $</span>
          </div>
        </div>
      )}
      {Number(data.insurance_fee) > 0 && (
        <div className="new-table--row">
          <div className="new-table--row--item col-4 no-shadow no-padding-y" style={{ paddingLeft: 53 }}>
            Insurance fee
          </div>
          <div className="new-table--row--item col-2 no-shadow no-padding-y">
            <span>${data.insurance_fee} $</span>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default FeesInfo;

import { useCallback } from 'react';
import { useFormik } from 'formik';
import swal from 'sweetalert2';

const useBuildInventory = (inventoryData) => {
  const { values, setFieldValue } = useFormik({
    enableReinitialize: true,
    initialValues: {
      items: inventoryData.job_inventory ?? [],
    },
  });

  const handleCancelButton = () => {
    setFieldValue('items', inventoryData.job_inventory);
  };

  const handleDeleteItem = (id) => {
    const prapared = values.items.filter((item) => Number(item.id) !== Number(id));
    setFieldValue('items', prapared);
  };

  const addItemToJobInventory = useCallback(
    (id, qty) => {
      const isAdded = values.items.find((item) => Number(item.id) === Number(id));

      if (isAdded) {
        const items = values.items.map((item) => {
          if (Number(item.id) === Number(id)) {
            return {
              ...item,
              qty: Number(item.qty) + Number(qty),
            };
          }
          return item;
        });

        setFieldValue('items', items);
        return;
      }

      const item = inventoryData.all_inventory.find((item) => Number(item.id) === Number(id));
      setFieldValue('items', [
        ...values.items,
        {
          ...item,
          inventory_id: id,
          dimension: { width: 0, length: 0, height: 0 },
          qty: Number(qty),
        },
      ]);

      swal.fire({
        title: 'Item Added. <br/>Scroll down to review',
        timer: 1000,
        position: 'top-right',
        customClass: 'tooltip-modal-inventory',
        showConfirmButton: false,
        backdrop: false,
        allowOutsideClick: false,
      });
    },
    [inventoryData, values.items, setFieldValue]
  );

  const changeQty = (qty, id) => {
    const items = values.items.map((item) => {
      if (Number(item.id) === Number(id)) {
        return {
          ...item,
          qty: Number(qty),
        };
      }
      return item;
    });

    setFieldValue('items', items);
  };

  return {
    values,
    actions: {
      handleCancelButton,
      handleDeleteItem,
      addItemToJobInventory,
      changeQty,
    },
  };
};

export { useBuildInventory };

import React from 'react';
import TooltipTitle from 'components/TooltipTitle';
import classNames from 'classnames';

const Estimated = ({ type, rate, calcInfo, jobInfo, jobPacking, jobFees, field }) => {
  let estimated_pickup = 0;

  if (parseFloat(calcInfo.distance_pickup) >= 16.9 && parseFloat(calcInfo.distance_pickup) <= 27) {
    estimated_pickup = parseFloat(rate) * 0.5;
  }

  if (parseFloat(calcInfo.distance_pickup) > 27 && parseFloat(calcInfo.distance_pickup) < 60) {
    estimated_pickup = parseFloat(rate);
  }

  if (parseFloat(calcInfo.distance_pickup) >= 60) {
    estimated_pickup = parseFloat(calcInfo.estimated_pickup_time) * parseFloat(rate);
  }

  let estimated_distance = parseFloat(calcInfo.estimated_travel_time) * parseFloat(rate);

  if (parseFloat(calcInfo.estimated_manual_travel_time) !== 0) {
    estimated_distance = parseFloat(calcInfo.estimated_manual_travel_time) * parseFloat(rate);
  }
  if (parseFloat(calcInfo.estimated_manual_pickup_time) !== 0) {
    estimated_pickup = parseFloat(calcInfo.estimated_manual_pickup_time) * parseFloat(rate);
  }
  if (parseInt(calcInfo.disabled_travel_time) === 1 && parseFloat(calcInfo.estimated_manual_travel_time) === 0) {
    estimated_distance = 0.0;
  }
  if (parseInt(calcInfo.disabled_pickup_time) === 1 && parseFloat(calcInfo.estimated_manual_pickup_time) === 0) {
    estimated_pickup = 0.0;
  }
  if (parseInt(jobInfo.company_id) === 5) {
    estimated_pickup = 0.0;
    estimated_distance = estimated_distance * 2;
  }

  const packingTotalEstimated = jobPacking[type].reduce((sum, item) => sum + item.qty * parseFloat(item.price), 0);
  const jobFeesTotal = jobFees?.reduce((sum, item) => sum + parseFloat(item.amount), 0) ?? 0;

  let estimated_work_time = parseFloat(calcInfo.estimated_time) * parseFloat(rate);
  let estimated_total = estimated_pickup + estimated_distance + estimated_work_time + packingTotalEstimated + jobFeesTotal;

  let tooltip_pickup = `0-16.9 mi - FREE \n 17-26.9 mi - 0.5 * Hourly Rate \n27-60 mi - 1 * Hourly Rate 
        \n 60-400 mi - Miles / 60 * Hourly Rate`;
  let tooltip_travel_time = `Distance hours are \nTravel hours, \n can be changed on FINAL`;
  if (parseInt(jobInfo.company_id) === 5) {
    tooltip_pickup = 'Free';
    tooltip_travel_time = `Distance hours are \n Travel hours(x2),\n can be changed on FINAL`;
  }

  switch (field) {
    default:
      return null;
    case 'office_to_pick_up':
      return (
        <div className={classNames('new-table--row--item justify-content-center w30', { minor: !estimated_pickup })}>
          <TooltipTitle title={tooltip_pickup}>
            <b>${estimated_pickup.toFixed(2)}</b>
          </TooltipTitle>
        </div>
      );

    case 'pick_up_to_destination':
      return (
        <div className={classNames('new-table--row--item justify-content-center w30', { minor: !estimated_distance })}>
          <TooltipTitle title={tooltip_travel_time}>
            <b>${estimated_distance.toFixed(2)}</b>
          </TooltipTitle>
        </div>
      );

    case 'working_time':
      return (
        <div className={classNames('new-table--row--item justify-content-center w30', { minor: !estimated_work_time })}>
          <TooltipTitle title={`Minimum defined \n Working Hours \n depending on MoveSize`}>
            <b>${estimated_work_time.toFixed(2)}</b>
          </TooltipTitle>
        </div>
      );

    case 'wait_time':
      return (
        <div className="new-table--row--item justify-content-center w30 minor">
          <b>0$</b>
        </div>
      );

    case 'total':
      return (
        <div className={classNames('new-table--row--item justify-content-center w30', { minor: !estimated_total })}>
          <b>${estimated_total.toFixed(2)}</b>
        </div>
      );

    case 'working_time_estimated':
      return (
        <div className={classNames('new-table--row--item justify-content-center w30')}>
          <b>${estimated_work_time.toFixed(2)}</b>
        </div>
      );
  }
};

export default Estimated;

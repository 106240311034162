import React, { useState, useEffect, Fragment } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { injectStripe } from 'react-stripe-elements';
import * as qs from 'querystring';

import CardSection from './CardSection';
import Preloader from 'components/Preloader';
import { alertDenySelfBooking, alertBookingExpiration, alertNeedAdvanceNotice } from '../paymentAlert';
import { checkIsLastMomentJob, checkIsBookingExpired, checkIfNeedAdvanceNotice } from './checks';
import Api from '../api';
import { sumFees } from '../utils';

function CheckoutForm(props) {
  const { data, payment, stripe } = props;

  const history = useHistory();
  const { search } = useLocation();
  const { job_id, booking_expiration } = qs.parse(search.replace('?', ''));

  const [last_moment_job, setLastMomentJob] = useState(false);
  const [allow_booking, setAllowBooking] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isValidCardData, setIsValidCardDate] = useState(false);

  useEffect(() => {
    if (checkIsLastMomentJob(data)) {
      setLastMomentJob(true);
    }
  }, [data]);

  useEffect(() => {
    if (Number(data.deny_self_booking) === 1) {
      setAllowBooking(false);
      alertDenySelfBooking(data.company_phone);

      Api.sendPaymentRequest({
        job_id: data.pickup_job_id,
        company_id: data.company_id,
        email: data.email,
        phone: data.phone,
        error_data: { reason: 'deny_self_booking' },
      });
    }

    if (Number(data.deposit_paid) === 0 && data.allow_booking === 0 && !checkIsLastMomentJob(data)) {
      if (checkIsBookingExpired(booking_expiration)) {
        setAllowBooking(false);
        alertBookingExpiration(data.company_phone);

        Api.sendPaymentRequest({
          job_id: data.pickup_job_id,
          company_id: data.company_id,
          email: data.email,
          phone: data.phone,
          error_data: { reason: 'link_expired', booking_expiration: booking_expiration },
        });
      }
    }
  }, [data, booking_expiration]);

  const checkValidCardData = (event) => {
    if (event.complete) {
      setIsValidCardDate(true);
    }
    if (isValidCardData && event.complete === false) {
      setIsValidCardDate(false);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (Number(data.deposit_paid) === 0 && data.allow_booking === 0) {
      if (checkIfNeedAdvanceNotice(data)) {
        alertNeedAdvanceNotice(data.company_phone);
        return;
      }
    }

    setIsLoading(true);

    stripe.createToken({ name: data.job_title }).then((result) => {
      if (result.error) {
        history.push(`/customer/info/payment-error?job_id=${job_id}`);
        return;
      }

      Api.stripeCharge({
        pickup_job_id: data.pickup_job_id,
        storage_job_id: data.storage_job_id,
        delivery_job_id: data.delivery_job_id,
        company_id: data.company_id,
        email: data.email,
        phone: data.phone,
        stripeToken: result.token.id,
        amount: payment.amount,
        deposit: data.deposit,
        remaining: data.remaining,
        storage_deposit: Number(data.storage_calc.storage_rate) + sumFees(data.storage_calc),
        storage_full: data.storage_calc.current_amount,
        storage_rate: data.storage_calc.storage_rate,
        destination_payment_type: payment.destination_payment_type,
        pay_for_destination: payment.pay_for_destination,
        pay_for_pickup: payment.pay_for_pickup,
        pay_for_storage: payment.pay_for_storage,
        pickup_payment_type: payment.pickup_payment_type,
        storage_payment_type: payment.storage_payment_type,
        destination_total: data.destination_total,
        type: data.type,
      })
        .then((response) => {
          if (response.data.success) {
            if (data.deposit_paid === 0) {
              history.push(`/customer/info/booking`);
            } else {
              history.push(`/customer/info/payment`);
            }
          } else {
            history.push(`/customer/info/payment-error/${job_id}`);
          }
        })
        .catch(() => {
          history.push(`/customer/info/payment-error/${job_id}`);
        })
        .finally(() => setIsLoading(false));
    });
  };

  if (!allow_booking && !last_moment_job) return null;

  return (
    <Fragment>
      {isLoading && <Preloader />}

      <form onSubmit={handleSubmit}>
        <CardSection checkValidCardData={checkValidCardData} />
        <div className="d-flex justify-content-end">
          <button type="submit" disabled={!isValidCardData || payment.amount === 0} className="pay-btn">
            Pay
          </button>
        </div>
      </form>
    </Fragment>
  );
}

// class CheckoutForm extends Component {
//   constructor(props) {
//     super(props);

//     if (
//       Number(this.props.data.deny_self_booking) === 0 &&
//       (
//         moment.unix(this.props.data.created_date).dayOfYear() === moment.unix(this.props.data.required_pickup).dayOfYear()
//         || moment.unix(this.props.data.created_date).dayOfYear() + 1 === moment.unix(this.props.data.required_pickup).dayOfYear()
//       )
//     ) {
//       this.state = {
//         last_moment_job: true,
//         allow_booking: true,
//         isLoading: false,
//         isValidCardData: false,
//       };
//     } else {
//       this.state = {
//         last_moment_job: false,
//         allow_booking: true,
//         isLoading: false,
//         isValidCardData: false,
//       };
//     }
//   }

//   componentDidMount() {
//     let url = new URL(window.location.href);
//     let booking_expiration = url.searchParams.get('booking_expiration');

//     if (Number(this.props.data.deny_self_booking) === 1) {
//       this.setState({ allow_booking: false });
//       alertDenySelfBooking(this.props.data.company_phone);

//       Api.sendPaymentRequest({
//         job_id: this.props.data.pickup_job_id,
//         company_id: this.props.data.company_id,
//         email: this.props.data.email,
//         phone: this.props.data.phone,
//         error_data: { reason: 'deny_self_booking' },
//       });
//     }

//     if (Number(this.props.data.deposit_paid) === 0 && this.props.data.allow_booking === 0 && !this.state.last_moment_job) {
//       if (moment.tz('America/New_York').unix() > Number(booking_expiration)) {
//         this.setState({ allow_booking: false });
//         alertBookingExpiration(this.props.data.company_phone);

//         Api.sendPaymentRequest({
//           job_id: this.props.data.pickup_job_id,
//           company_id: this.props.data.company_id,
//           email: this.props.data.email,
//           phone: this.props.data.phone,
//           error_data: { reason: 'link_expired', booking_expiration: booking_expiration },
//         });
//       }
//     }
//   }

//   checkValidCardData = (event) => {
//     if (event.complete) {
//       this.setState({ isValidCardData: true });
//     }
//     if (this.state.isValidCardData && event.complete === false) {
//       this.setState({ isValidCardData: false });
//     }
//   };

//   handleSubmit = (ev) => {
//     ev.preventDefault();
//     // const depositNotPaid = Number(this.props.data.deposit_paid) === 0;
//     // const bookingNotAllow = this.props.data.allow_booking === 0;

//     if (Number(this.props.data.deposit_paid) === 0) {
//       if (this.props.data.allow_booking === 0) {
//         if (
//           moment.unix(this.props.data.required_pickup).dayOfYear() - moment().dayOfYear() <= Number(this.props.data.book_expiration) &&
//           moment.unix(this.props.data.required_pickup).dayOfYear() - moment().dayOfYear() >= 0
//         ) {
//           return alertNeedAdvanceNotice(this.props.data.company_phone);
//         }
//       }
//     }

//     this.setState({ isLoading: true });

//     let url = new URL(window.location.href);
//     let job_id = url.searchParams.get('job_id');

//     this.props.stripe.createToken({ name: this.props.data.job_title }).then((result) => {
//       if (result.error) {
//         window.location.href = `${window.location.origin}/customer/info/payment-error?job_id=${job_id}`;
//       }

//       Api.stripeCharge({
//         pickup_job_id: this.props.data.pickup_job_id,
//         storage_job_id: this.props.data.storage_job_id,
//         delivery_job_id: this.props.data.delivery_job_id,
//         company_id: this.props.data.company_id,
//         email: this.props.data.email,
//         phone: this.props.data.phone,
//         stripeToken: result.token.id,
//         amount: this.props.payment.amount,
//         deposit: this.props.data.deposit,
//         remaining: this.props.data.remaining,
//         storage_deposit: this.props.data.storage_calc.storage_deposit,
//         storage_full: this.props.data.storage_calc.current_amount,
//         storage_rate: this.props.data.storage_calc.storage_rate,
//         destination_payment_type: this.props.payment.destination_payment_type,
//         pay_for_destination: this.props.payment.pay_for_destination,
//         pay_for_pickup: this.props.payment.pay_for_pickup,
//         pay_for_storage: this.props.payment.pay_for_storage,
//         pickup_payment_type: this.props.payment.pickup_payment_type,
//         storage_payment_type: this.props.payment.storage_payment_type,
//         destination_total: this.props.data.destination_total,
//         type: this.props.data.type,
//       })
//         .then((response) => {
//           if (response.data.success) {
//             if (this.props.data.deposit_paid === 0) {
//               window.location.href = `${window.location.origin}/customer/info/booking`;
//             } else {
//               window.location.href = `${window.location.origin}/customer/info/payment`;
//             }
//           } else {
//             window.location.href = `${window.location.origin}/customer/info/payment-error/${job_id}`;
//           }
//         })
//         .catch((error) => {
//           window.location.href = `${window.location.origin}/customer/info/payment-error/${job_id}`;
//         })
//         .finally(() => this.setState({ isLoading: false }));
//     });
//   };

//   render() {
//     let url = new URL(window.location.href);
//     let booking_expiration = url.searchParams.get('booking_expiration');

//     if (!this.state.allow_booking && !this.state.last_moment_job) return null;

//     return (
//       <Fragment>
//         {this.state.isLoading && <Preloader />}
//         <form onSubmit={this.handleSubmit}>
//           <CardSection checkValidCardData={this.checkValidCardData} />
//           <div className="d-flex justify-content-end">
//             <button disabled={!this.state.isValidCardData || this.props.payment.amount === 0} className="pay-btn">
//               Pay
//             </button>
//           </div>
//         </form>
//       </Fragment>
//     );
//   }
// }

export default injectStripe(CheckoutForm);

import React, { memo } from 'react';
import { useAuth } from 'context/auth';
import Icon from 'components/Icon';

const NavbarUser = memo(() => {
  const { user, setAuth } = useAuth();

  return (
    <div className="navbar-header">
      {user && (
        <div className="navbar-header--username">
          <div className="image-cropper">
            {user.avatar ? <img src={user.avatar} alt="avatar" className="profile-pic" /> : <Icon icon="name" />}
          </div>
          <span className="navbar-header--username--name">{user.short_name}</span>
        </div>
      )}
      <button type="button" className="navbar-header--logout" onClick={() => setAuth(null)}>
        <Icon icon="logout" />
      </button>
    </div>
  );
});

export default NavbarUser;

import React, { PureComponent } from 'react';
import Chart from 'react-apexcharts';

class DonutChartComponent extends PureComponent {
  render() {
    const options = {
      fill: { colors: ['#f9c633', '#cee551', '#fdf256', '#91cf57'] },
      tooltip: { enable: false, enabledOnSeries: [] },
      dataLabels: {
        enabled: true,
        formatter: function (value, { seriesIndex, _, w }) {
          return w.config.series[seriesIndex];
        },
        style: { colors: ['#373d3f'] },
      },
      legend: {
        show: true,
        labels: { colors: undefined, useSeriesColors: false },
        markers: { fillColors: ['#f9c633', '#cee551', '#fdf256', '#91cf57'] },
      },
      labels: ['Expenses', 'Salary', 'Commission', 'Profit'],
      plotOptions: {
        pie: {
          size: undefined,
          customScale: 1,
          offsetX: 0,
          offsetY: 0,
          expandOnClick: false,
          dataLabels: {
            offset: 0,
            minAngleToShowLabel: 10,
          },
          donut: {
            size: '60%',
            background: 'transparent',
            fill: { colors: ['#f9c633', '#cee551', '#fdf256', '#91cf57'] },
            labels: {
              show: true,
              name: {
                show: true,
                fontSize: '22px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                color: ['#f9c633', '#cee551', '#fdf256', '#91cf57'],
                offsetY: -10,
              },
              value: {
                show: true,
                fontSize: '16px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                color: undefined,
                offsetY: 16,
                formatter: function (val) {
                  return val;
                },
              },
              total: {
                show: true,
                showAlways: true,
                label: 'Total',
                color: '#373d3f',
                formatter: () => {
                  return this.props.total;
                },
              },
            },
          },
        },
      },
    };
    return <Chart options={options} series={this.props.series} type="donut" width="400" />;
  }
}

export default DonutChartComponent;

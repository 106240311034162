import React from 'react';
import ExpResAgent from './ExpResAgent';
import ExpResDriver from './ExpResDriver';
import ExpResMover from './ExpResMover';
import ExpResTruck from './ExpResTruck';

const ResourceSalaryTab = ({ totalSalary, fuelCost }) => {
  return (
    <div className="new-table shadow w49">
      <div className="new-table--row no-padding">
        <h3 className="new-table--row--item header">Resource</h3>
        <h3 className="new-table--row--item header">Salary</h3>
      </div>
      <ExpResAgent />
      <ExpResDriver />
      <ExpResMover />
      <ExpResTruck />
      <div className="new-table--row no-padding">
        <div className="new-table--row--item no-side-shadows" />
        <div className="new-table--row--item no-side-shadows justify-content-around">
          <span className="fb3">Salary</span>
          <span className="fb3">Fuels</span>
          <span className="fb3">Total</span>
        </div>
      </div>
      <div className="new-table--row">
        <div className="new-table--row--item no-side-shadows" />
        <div className="new-table--row--item no-side-shadows justify-content-around">
          <b className="fb3">{totalSalary}</b>
          <b className="fb3">{fuelCost}</b>
          <b className="fb3">{(parseFloat(totalSalary) + parseFloat(fuelCost)).toFixed(2)}</b>
        </div>
      </div>
    </div>
  );
};

export default ResourceSalaryTab;

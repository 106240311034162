import React, { useState, useMemo } from 'react';
import Pagination from 'react-paginate';
import LeadJob from './LeadJob';
import { NoResultsFound } from '../NoResultsFound';

const LeadJobList = ({ list }) => {
  const [page, setPage] = useState(0);
  const perPage = 20;

  const handlePaginationChange = (page) => setPage(page.selected);

  const jobList = useMemo(() => {
    if (!list) return [];

    return list.map((job, key) => (
      <div className="reports-table" key={job.id + 'user'}>
        <LeadJob key={'report' + key} k={key} data={job} />
      </div>
    ));
  }, [list]);

  if (list.length === 0) return <NoResultsFound />;

  return (
    <div className="my-3">
      <div className="reports-table">
        <div className="reports-table-row">
          <div className="reports-table-column reports-table-column--8 reports-table-column--title">Current Status</div>
          <div className="reports-table-column reports-table-column--8 reports-table-column--title">Company</div>
          <div className="reports-table-column reports-table-column--8 reports-table-column--title">Agent</div>
          <div className="reports-table-column reports-table-column--8 reports-table-column--title">Job Id</div>
          <div className="reports-table-column reports-table-column--8 reports-table-column--title">Date</div>
          <div className="reports-table-column reports-table-column--8 reports-table-column--title">Customer Name</div>
          <div className="reports-table-column reports-table-column--8 reports-table-column--title">From</div>
          <div className="reports-table-column reports-table-column--8 reports-table-column--title">To</div>
        </div>
      </div>
      {jobList.slice(page * perPage, (page + 1) * perPage)}
      <Pagination
        pageCount={Math.ceil(jobList.length / perPage)}
        pageRangeDisplayed={5}
        marginPagesDisplayed={2}
        containerClassName="email-pagination pagination"
        subContainerClassName="pages pagination"
        activeClassName="active"
        onPageChange={handlePaginationChange}
      />
    </div>
  );
}

export default LeadJobList;

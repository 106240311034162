import React from 'react';
import DateSelect from 'components/DateSelect';
import Select from 'components/newComponents/Select';
import { periodLength, jobStatuses } from '../reportConstants';

const Filter = (props) => {
  const { data, actions } = props;
  const { values, companiesList } = data;
  const { isValidHandler, handleSelect, selectPeriod, selectDate, handleSubmit } = actions;

  return (
    <form onSubmit={handleSubmit}>
      <div className="new-table">
        <div className="new-table--row no-padding">
          <div className="new-table--row--item select w10">
            {companiesList.length > 0 && (
              <Select
                name="company_id"
                options={companiesList}
                value={values['company_id']}
                onChange={({ value }) => handleSelect('company_id', value)}
              />
            )}
          </div>

          <div className="new-table--row--item select w10">
            <Select name="period_type" options={periodLength} value={values['period_type']} onChange={selectPeriod} />
          </div>

          <div className="new-table--row--item select w20">
            <DateSelect name="from" selected={values['from']} placeholder="From date" className="w45 placeholder-date" onChange={selectDate} />
            <DateSelect name="to" selected={values['to']} placeholder="To date" className="w45 placeholder-date" onChange={selectDate} />
          </div>

          <div className="new-table--row--item select w10">
            <Select
              name="status"
              value={values['status']}
              placeholder="Select Status"
              error={isValidHandler('status')}
              options={jobStatuses}
              onChange={({ value }) => handleSelect('status', value)}
            />
          </div>

          <div className="new-table--row no-padding w10">
            <div className="new-table--row--item w10 no-padding justify-content-end">
              <button type="submit" className="confirm px-5 py-3">
                Apply
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default Filter;

import React from 'react';
import Select, { components } from 'react-select';
import Icon from 'components/Icon';
import { useField } from 'formik';
// import { getByDisplayValue } from '@testing-library/dom';

const FormikSelect = (props) => {
  const {
    options,
    controlStyles,
    optionStyle,
    singleValue,
    isDisabled,
    placeholder,
    newChange,
    menuListStyle,
    defaultValue,
    // valueContainer,
  } = props;

  const [field, meta, helpers] = useField(props);

  const { Option } = components;
  const IconOption = (props) => (
    <Option {...props}>
      {props.data.label}
      {props.data.iconName && <Icon style={{ marginLeft: 5 }} icon={props.data.iconName} />}
    </Option>
  );
  const customStyles = {
    option: () => ({ padding: 10, cursor: 'pointer', ...optionStyle }),
    container: () => [{ height: '100%', width: '100%', position: 'relative' }],
    menuList: () => [menuListStyle],
    control: () => [{ border: 'none', display: 'flex', height: '100%' }, controlStyles],
    indicatorSeparator: () => ({ display: 'none' }),
    singleValue: () => singleValue,
    // valueContainer: () => [
    //   { display: 'flex', flexWrap: 'nowrap', width: '100%', alignItems: 'center' },
    //   valueContainer,
    // ],
  };

  const onChangeValue = ({ value }) => {
    newChange && newChange(value);
    helpers.setValue(value);
  };

  const getValue = (value) => {
    if (Number(value)) return Number(value);
    return value;
  };

  return (
    <div className="d-flex flex-column position-relative">
      <Select
        name={field.name}
        options={options}
        className={['react-select-container ']}
        onChange={onChangeValue}
        value={options?.find((el) => el.value === getValue(field.value)) || null}
        defaultValue={options?.find((el) => el.value === +meta.defaultValue) ?? defaultValue ?? null}
        styles={customStyles}
        placeholder={placeholder}
        isDisabled={isDisabled}
        components={{ Option: IconOption }}
      />
      {meta.error && meta.touched && (
        <span style={{ position: 'absolute', bottom: -10 }} className="error">
          {meta.error}
        </span>
      )}
    </div>
  );
};

export default FormikSelect;

import React, { useCallback, useMemo } from 'react';
import { useFormik } from 'formik';
import { useInfo, useTabInfo, useAssignManagement } from 'hooks/query/useJob';
import { useDefault } from 'hooks/query/useCommon';
import { useParams } from 'react-router-dom';
import Select from 'components/newComponents/Select';
import Icon from 'components/Icon';

const JobManagersTab = () => {
  const { id, tab } = useParams();

  const { mutate: managementAssign } = useAssignManagement(id, tab);

  const {
    data: { job_info: jobInfo },
  } = useInfo(id);

  const {
    data: { assigned_management: assignedManagement },
  } = useTabInfo(id, tab);

  const {
    data: { users },
  } = useDefault();

  const agentsList = useMemo(() => {
    return users.agents.map((agent) => ({
      id: agent.id,
      value: agent.id,
      label: agent.first_name + ' ' + agent.last_name,
    }));
  }, [users.agents]);

  const managersList = useMemo(() => {
    return users.managers.map((manager) => ({
      id: manager.id,
      value: manager.id,
      label: manager.first_name + ' ' + manager.last_name,
    }));
  }, [users.managers]);

  const estimatorsList = useMemo(() => {
    return users.estimators.map((estimator) => ({
      id: estimator.id,
      value: estimator.id,
      label: estimator.first_name + ' ' + estimator.last_name,
    }));
  }, [users.estimators]);

  const { values, setFieldValue } = useFormik({
    initialValues: {
      agent_id: assignedManagement?.agent?.id,
      manager_id: assignedManagement?.manager?.id,
      estimator_id: assignedManagement?.estimator?.id,
    },
  });

  const managementAssignHandler = useCallback(
    (name, value) => {
      managementAssign({ [name]: value, job_id: id }, { onSuccess: () => setFieldValue(name, value) });
    },
    [id, managementAssign, setFieldValue]
  );

  return (
    <div className="new-table shadow w49">
      <div className="new-table--row no-padding">
        <h3 className="new-table--row--item header">Management</h3>
      </div>
      <form>
        <div className="new-table--row no-padding">
          <div className="new-table--row--item select">
            <Icon title="Agent" icon="agent" size={20} />
            <Select
              options={agentsList}
              value={values['agent_id']}
              name="agent_id"
              placeholder="Agents"
              onChange={({ value }) => managementAssignHandler('agent_id', value)}
            />
          </div>
        </div>
        <div className="new-table--row no-padding">
          <div className="new-table--row--item select">
            <Icon title="Manager" icon="manager" size={20} />
            <Select
              options={managersList}
              value={values['manager_id']}
              name="manager_id"
              placeholder="Managers"
              onChange={({ value }) => managementAssignHandler('manager_id', value)}
            />
          </div>
        </div>
        <div className="new-table--row no-padding">
          <div className="new-table--row--item select">
            <Icon title="Estimator" icon="estimator" size={20} />
            <Select
              options={estimatorsList}
              value={values['estimator_id']}
              isDisabled={jobInfo.estimator_id !== 0 || jobInfo.status === 8}
              name="estimator_id"
              placeholder="Estimator"
              onChange={({ value }) => managementAssignHandler('estimator_id', value)}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default JobManagersTab;

import React from 'react';
import { useForm } from '../../../hooks/useForm';

function Route() {
  const { values } = useForm();

  return (
    <div className="new-table--row">
      <div className="new-table--row--item no-padding">
        <div className="new-table--row--item no-shadow">
          <span>{values.from_state || 'From State'}</span>
        </div>
        <div className="new-table--row--item">
          <span>{values.from_city || 'From city'}</span>
        </div>
      </div>

      <div className="new-table--row--item no-padding">
        <div className="new-table--row--item no-shadow">
          <span>{values.to_state || 'To State'}</span>
        </div>
        <div className="new-table--row--item">
          <span>{values.to_city || 'To city'}</span>
        </div>
      </div>
    </div>
  );
}

export default Route;

import React, { Fragment } from 'react';
import { useGetGeneralReportJobInfo } from 'hooks/query/useReport';
import { calcJobTotalPaymentsByPayments } from './generalUtils';

const ReportInfo = ({ id, paymentStatuses }) => {
  const { data, isFetching } = useGetGeneralReportJobInfo({ job_id: id });

  const paymentsSumByPaymentType = calcJobTotalPaymentsByPayments(data?.job_payments);

  if (isFetching) {
    return (
      <div className="mh200 shadow">
        <div className="preloader" />
      </div>
    );
  }

  let Foreman = [];
  let MoF = {};
  let hoursWorked = [];
  let deposit = '-';
  let packing_proc = [];
  let packing_total = 0;
  let total_pay = 0;
  let hoursSalary = [];
  let salaryTotal = [];

  if (data.agent) {
    Foreman.push(
      <tr title="Agent" key={'agent_name'}>
        <td>{data.agent.user_name + ' ' + data.agent.user_last_name}</td>
      </tr>
    );
    hoursWorked.push(
      <tr title="Agent" key={'agent_hours'}>
        <td>{data.agent.hours}</td>
      </tr>
    );
    packing_proc.push(
      <tr title="Agent" key={'agent_perc'}>
        <td>-</td>
      </tr>
    );
    hoursSalary.push(
      <tr title="Agent" key={'agent_hSalary'}>
        <td>{data.agent?.commission_percent}%</td>
      </tr>
    );
    salaryTotal.push(
      <tr title="Agent" key={'agent_sTotal'}>
        <td>${Number(data.agent.commission)?.toFixed(2)}</td>
      </tr>
    );
  }
  if (data.manager) {
    Foreman.push(
      <tr title="Manager" key={'manager_name'}>
        <td>{data.manager.user_name + ' ' + data.manager.user_last_name}</td>
      </tr>
    );
    hoursWorked.push(
      <tr title="Manager" key={'manager_name'}>
        <td>{data.manager.hours}</td>
      </tr>
    );
    packing_proc.push(
      <tr title="Manager" key={'manager_name'}>
        <td>-</td>
      </tr>
    );
    hoursSalary.push(
      <tr title="Manager" key={'manager_name'}>
        <td>{data.manager?.commission_percent}%</td>
      </tr>
    );
    salaryTotal.push(
      <tr title="Manager" key={'manager_name'}>
        <td>${Number(data.manager?.commission)?.toFixed(2)}</td>
      </tr>
    );
  }

  if (data.driver) {
    Foreman.push(
      <tr title="Driver" key={'driver_name'}>
        <td>{data.driver.user_name + ' ' + data.driver.user_last_name}</td>
      </tr>
    );
    hoursWorked.push(
      <tr title="Driver" key={'driver_name'}>
        <td>{data.driver.hours}</td>
      </tr>
    );
    packing_proc.push(
      <tr title="Driver" key={'driver_name'}>
        <td>{data.driver?.packing_percent}%</td>
      </tr>
    );
    hoursSalary.push(
      <tr title="Driver" key={'driver_name'}>
        <td>${data.driver.rate_per_hour}</td>
      </tr>
    );
    salaryTotal.push(
      <tr title="Driver" key={'driver_name'}>
        <td>${data.driver.salary?.toFixed(2)}</td>
      </tr>
    );
  }

  if (data.mover && data.mover.length !== 0) {
    data.mover.forEach((mover, key) => {
      Foreman.push(
        <tr key={key + 'movers_name'} title="Mover">
          <td>{mover.user_name + ' ' + mover.user_last_name} </td>
        </tr>
      );
      hoursWorked.push(
        <tr key={key + 'movers_name'} title="Mover">
          <td>{mover.hours}</td>
        </tr>
      );
      packing_proc.push(
        <tr key={key + 'movers_name'} title="Mover">
          <td>-</td>
        </tr>
      );
      hoursSalary.push(
        <tr key={key + 'movers_name'} title="Mover">
          <td>${mover.rate_per_hour}</td>
        </tr>
      );
      salaryTotal.push(
        <tr key={key + 'movers_name'} title="Mover">
          <td>${mover.salary?.toFixed(2)}</td>
        </tr>
      );
    });
  }

  data.job_payments.forEach((item) => {
    switch (item.type) {
      case 1:
        MoF[item.type] = 'Stripe';
        break;
      case 2:
        MoF[item.type] = 'Cash';
        break;
      case 3:
        MoF[item.type] = 'Credit';
        break;
      case 5:
        MoF[item.type] = 'Zelle';
        break;
      default:
        MoF = { 0: 'Payment Denied' };
        break;
    }

    if (item.deposit === 1) {
      deposit = '$' + item.amount;
    }
    total_pay = (Number(total_pay) + Number(item.amount)).toFixed(2);
  });

  if (data.packing_price) {
    packing_total = data.packing_price;
  }

  return (
    <div className="reports-table reports-info">
      <div className="reports-table-row">
        <div className="reports-table-column reports-table-column--13 reports-table-info--title">Foreman/Helpers</div>
        <div className="reports-table-column reports-table-column--13 reports-table-info--title">Cash/Credit</div>
        <div className="reports-table-column reports-table-column--13 reports-table-info--title">Hours Worked</div>
        <div className="reports-table-column reports-table-column--13 reports-table-info--title">Tips</div>
        <div className="reports-table-column reports-table-column--13 reports-table-info--title">Price Per Hours</div>
        <div className="reports-table-column reports-table-column--13 reports-table-info--title">Deposit</div>
        <div className="reports-table-column reports-table-column--13 reports-table-info--title">Packing</div>
        <div className="reports-table-column reports-table-column--13 reports-table-info--title">Packing %</div>
        <div className="reports-table-column reports-table-column--13 reports-table-info--title">Discount</div>
        <div className="reports-table-column reports-table-column--13 reports-table-info--title">Fuel Charge</div>
        <div className="reports-table-column reports-table-column--13 reports-table-info--title">Charge Client</div>
        <div className="reports-table-column reports-table-column--13 reports-table-info--title">
          <span>Rate Per Hours</span>
          <br />
          <span>/ Commission %</span>
        </div>
        <div className="reports-table-column reports-table-column--13 reports-table-info--title">Salary</div>
      </div>
      <div className="reports-table-row">
        <div className="reports-table-column reports-table-column--13">
          <table className="reports-general-info">
            <tbody>{Foreman}</tbody>
          </table>
        </div>
        <div className="reports-table-column reports-table-column--13">
          {Object.entries(paymentsSumByPaymentType).map(([key, value]) => {
            return (
              <Fragment key={key}>
                <span>{paymentStatuses[key]}</span> - <span>${value.toFixed(2)}</span>
                <br />
              </Fragment>
            );
          })}
        </div>
        {/* <div className="reports-table-column reports-table-column--13">{Object.values(MoF).join(' / ')}</div> */}
        <div className="reports-table-column reports-table-column--13">
          <table className="reports-general-info">
            <tbody>{hoursWorked}</tbody>
          </table>
        </div>
        <div className="reports-table-column reports-table-column--13">${data.tips_amount}</div>
        <div className="reports-table-column reports-table-column--13">${data.job_rate}</div>
        <div className="reports-table-column reports-table-column--13">{deposit}</div>
        <div className="reports-table-column reports-table-column--13">${packing_total}</div>
        <div className="reports-table-column reports-table-column--13">
          <table className="reports-general-info">
            <tbody>{packing_proc}</tbody>
          </table>
        </div>
        <div className="reports-table-column reports-table-column--13">${data.job_discounts}</div>
        <div className="reports-table-column reports-table-column--13">${data.truck_expenses.toFixed(2)}</div>
        <div className="reports-table-column reports-table-column--13">${total_pay}</div>
        <div className="reports-table-column reports-table-column--13">
          <table className="reports-general-info">
            <tbody>{hoursSalary}</tbody>
          </table>
        </div>
        <div className="reports-table-column reports-table-column--13">
          <table className="reports-general-info">
            <tbody>{salaryTotal}</tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ReportInfo;

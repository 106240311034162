import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useApi } from 'context/api';
import queryKeys from 'config/queryKeys';
import makeFormData from 'utils/makeFormData';

export const useTemplatesByStatus = (status, options) => {
  const { mail } = useApi();

  return useQuery(queryKeys.emailTemplates, () => mail.templatesByStatus(status), {
    select: (res) => res.data,
    ...options,
  });
};

export const useTemplate = () => {
  const { mail } = useApi();

  return useMutation(queryKeys.template, mail.template);
};

export const useTemplates = () => {
  const { mail } = useApi();

  return useQuery(queryKeys.templates, mail.templates, { select: (res) => res.data });
};

export const useAutoEmail = () => {
  const { mail } = useApi();

  return useMutation(mail.autoEmail);
};

export const useEmailSend = (jobId, tab, type) => {
  const { mail } = useApi();
  const queryClient = useQueryClient();
  const options = { headers: { 'Content-Type': 'multipart/form-data' } };

  return useMutation((data) => mail.send(makeFormData(data), options), {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.job, jobId, tab]);
      type && queryClient.invalidateQueries([queryKeys.communication, jobId, type]);
    },
  });
};

export const useSendComposedEmail = (jobId, tab, type) => {
  const { mail } = useApi();
  const queryClient = useQueryClient();
  const options = { headers: { 'Content-Type': 'multipart/form-data' } };

  function objectToFormData(obj, form = new FormData(), namespace = '') {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        let formKey = namespace ? `${namespace}[${key}]` : key;

        if (Array.isArray(obj[key])) {
          obj[key].forEach((value) => {
            if (typeof value === 'object' && value !== null) {
              objectToFormData(value, form, `${formKey}[]`);
            } else {
              form.append(`${formKey}[]`, value);
            }
          });
        } else if (typeof obj[key] === 'object' && obj[key] !== null) {
          objectToFormData(obj[key], form, formKey);
        } else {
          form.append(formKey, obj[key]);
        }
      }
    }
    return form;
  }

  return useMutation((data) => mail.sendComposed(objectToFormData(data), options), {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.job, jobId, tab]);
      queryClient.invalidateQueries([queryKeys.reminders, jobId]);
      type && queryClient.invalidateQueries([queryKeys.communication, jobId, type]);
    },
  });
};

export const useEmailGet = (id, options) => {
  const { common } = useApi();

  return useQuery([queryKeys.email, id], common.getEmail, { ...options });
};

import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useApi } from 'context/api';
import queryKeys from 'config/queryKeys';

export const useMovingInfo = (id, options) => {
  const { moving } = useApi();

  return useQuery(queryKeys.movingInfo, moving.info, { ...options });
};

export const useMovingRate = (id, options) => {
  const { moving } = useApi();

  return useMutation(moving.rate, { ...options });
};

export const useMovingSave = (jobId, tab) => {
  const { moving } = useApi();

  const queryClient = useQueryClient();

  return useMutation(moving.save, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.job, jobId, tab]);
    },
  });
};

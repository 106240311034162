import React from 'react';
import Radio from 'components/newComponents/RadioButton';

const PaymentLocal = ({ data, payment, onChange }) => {
  return (
    <div className="new-table">
      {parseInt(data.remaining) > 0 ? (
        <div className="new-table--row">
          <div className="new-table--row--item col-4 no-shadow vertical-align-top">
            <Radio
              name="pickup_payment_type"
              value="pickup_full"
              title="Pay Minimum -"
              radioStyles={{ marginRight: 20, marginLeft: 0 }}
              titleStyles={{ fontWeight: 500 }}
              current={payment.pickup_payment_type}
              onChange={onChange}
            />
          </div>
          <div className="new-table--row--item col-2 no-shadow vertical-align-top">
            <span className="price">${data.remaining}</span>
          </div>
          <div className="new-table--row--item col-6 no-shadow vertical-align-top">
            <p>
              Not a Final Total. Additional hour, if needed, is ${data.rate}, billed every 5 minutes @${(Number(data.rate) / 12).toFixed(2)}
            </p>
          </div>
        </div>
      ) : (
        <div className="new-table--row">
          <div className="new-table--row--item no-shadow">
            <Radio
              name="pickup_payment_type"
              value="pickup_full"
              title="Pickup paid."
              disabled
              radioStyles={{ marginRight: 20, marginLeft: 0 }}
              titleStyles={{ fontWeight: 500 }}
            />
          </div>
        </div>
      )}

      {parseInt(data.deposit_paid) === 0 ? (
        <div className="new-table--row">
          <div className="new-table--row--item col-4 no-shadow vertical-align-top">
            <Radio
              name="pickup_payment_type"
              value="pickup_deposit"
              title="Deposit - "
              radioStyles={{ marginRight: 20, marginLeft: 0 }}
              titleStyles={{ fontWeight: 500 }}
              current={payment.pickup_payment_type}
              onChange={onChange}
            />
          </div>
          <div className="new-table--row--item col-2 no-shadow vertical-align-top">
            <span className="price">${parseFloat(data.deposit)}</span>
          </div>
          <div className="new-table--row--item col-6 no-shadow vertical-align-top">
            <p>Non-Refundable if job canceled by customer less than 3 days prior to moving date</p>
          </div>
        </div>
      ) : (
        <div className="new-table--row">
          <div className="new-table--row--item no-shadow">
            <Radio
              name="pickup_payment_type"
              value="pickup_deposit"
              title="Deposit - Paid."
              disabled
              radioStyles={{ marginRight: 20, marginLeft: 0 }}
              titleStyles={{ fontWeight: 500 }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default PaymentLocal;

import React, { Fragment } from 'react';
import classNames from 'classnames';
import DateSelect from 'components/DateSelect';
import Select from 'components/newComponents/Select';
import { periodLength, jobTypes } from '../reportConstants';
import DropdownMenu from 'components/DropdownMenu';
import JobFilterDropdown from 'components/JobFilterDropdown/';
import { jobFiltersOptions } from 'helpers/constants';
import { packingTypes } from '../reportConstants';

const Filter = (props) => {
  const { data, actions } = props;
  const { values, companiesList, userNames, moveSizes, userTypes } = data;
  const { isValidHandler, handleSelect, selectJobFilter, selectPeriod, selectDate, selectAllUsers, selectUser, handleSubmit } = actions;

  return (
    <form onSubmit={handleSubmit}>
      <div className="new-table">
        <div className="new-table--row no-padding">
          <div className="new-table--row--item select w10">
            {companiesList.length > 0 && (
              <Select
                name="company_id"
                options={companiesList}
                value={values['company_id']}
                onChange={({ value }) => handleSelect('company_id', value)}
              />
            )}
          </div>

          <div className="new-table--row--item select w10">
            <Select name="job_type" options={jobTypes} value={values['job_type']} onChange={({ value }) => handleSelect('job_type', value)} />
          </div>

          <div className="new-table--row--item select w10">
            {moveSizes.length > 0 && (
              <Select name="move_size" options={moveSizes} value={values['move_size']} onChange={({ value }) => handleSelect('move_size', value)} />
            )}
          </div>

          <div className="new-table--row--item select w10">
            <Select name="period_type" options={periodLength} value={values['period_type']} onChange={selectPeriod} />
          </div>

          <div className="new-table--row--item select w20">
            <DateSelect name="from" selected={values['from']} placeholder="From date" className="w45 placeholder-date" onChange={selectDate} />
            <DateSelect name="to" selected={values['to']} placeholder="To date" className="w45 placeholder-date" onChange={selectDate} />
          </div>

          <div className="new-table--row--item select w10">
            {userTypes.length > 0 && (
              <Select name="user_type" options={userTypes} value={values['user_type']} onChange={({ value }) => handleSelect('user_type', value)} />
            )}
          </div>

          <DropdownMenu
            name="user_id"
            divClassName="new-table--row--item select w10 d-block"
            button={{
              spanTitle: 'Select agent',
              className: classNames('', { error: isValidHandler('user_id'), default: !isValidHandler('user_id') }),
            }}
            dontHide
            listStyle={{ left: 0, marginTop: 15, width: 250 }}
            menu={
              <Fragment>
                <label style={{ padding: '10px 5px', justifyContent: 'flex-start', fontWeight: 700 }}>
                  <input
                    type="checkbox"
                    checked={values['user_id'].length === userNames.length}
                    style={{ marginRight: 7 }}
                    onChange={() => selectAllUsers(userNames)}
                  />
                  Select All
                </label>
                {userNames.map((item) => (
                  <label style={{ padding: '10px 5px', justifyContent: 'flex-start', fontWeight: 700 }} key={item.value}>
                    <input
                      type="checkbox"
                      checked={values['user_id'].includes(item.value)}
                      style={{ marginRight: 7 }}
                      onChange={() => selectUser(item.value)}
                    />
                    {item.label}
                  </label>
                ))}
              </Fragment>
            }
          />

          <DropdownMenu
            name="user_id"
            divClassName="new-table--row--item select w10 d-block"
            button={{ spanTitle: 'Filters', className: 'default' }}
            dontHide
            listStyle={{ left: 0, marginTop: 15 }}
            menu={<JobFilterDropdown jobOptions={jobFiltersOptions} statuses={values['job_statuses']} onChange={selectJobFilter} />}
          />

          <div className="new-table--row--item select w10">
            {userTypes.length > 0 && (
              <Select
                name="packing_type"
                options={packingTypes}
                value={values['packing_type']}
                onChange={({ value }) => handleSelect('packing_type', value)}
              />
            )}
          </div>

          <div className="new-table--row no-padding w10">
            <div className="new-table--row--item w10 no-padding justify-content-end">
              <button type="submit" className="confirm px-5 py-3">
                Apply
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default Filter;

import React, { useState } from 'react';
import { useMenuCounter, useMenuCounterByType } from 'hooks/query/useCommon';
import NavbarRightSide from './NavbarRightSide';
import NavbarLeftSide from './NavbarLeftSide';
import NavbarUser from './NavbarUser';

import './Navbar.scss';
import { useAuth } from 'context/auth';

const Navbar = () => {
  const [countersType, setCountersType] = useState(null);

  const { user } = useAuth();
  const { data: counter, refetch: onRefreshCounter } = useMenuCounter();
  const { data: counterByType, refetch: onRefreshCounterByType } = useMenuCounterByType(countersType);

  if (countersType && counterByType) {
    counter[countersType] = counterByType.counter;
    counter.communication = counterByType.communication;
  }

  React.useEffect(() => {
    const listener = (data) => {
      if (data.user_id === 0 || Number(data.user_id) === Number(user?.id)) {
        onRefreshCounter();
        onRefreshCounterByType();
      }
    };

    window.socket.on('counter-refresh', listener);

    return () => {
      window.socket.off('counter-refresh', listener);
    };
  }, [onRefreshCounter, onRefreshCounterByType, user]);

  return (
    <div className="navbar">
      <NavbarLeftSide counterByType={counter} />
      <NavbarRightSide setCountersType={setCountersType} counters={counter} />
      <NavbarUser />
    </div>
  );
};

export default Navbar;

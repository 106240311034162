import React, { Fragment, useRef, useEffect, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import { Device } from '@twilio/voice-sdk';
import { useIncomingJobInfo, useCapabilityToken, useRedirectToConference } from 'hooks/query/useTwilio';
import { useDefault } from 'hooks/query/useCommon';
import { useGeneralCompanyName } from 'hooks/query/useTwilio';
import { useModal } from 'context/modal';
import { useAuth } from 'context/auth';
import PhoneNew from './PhoneNew';
import CallScript from './CallScript';
import { formatPhoneNumberOnlyDigits, formatPhoneNumberPlusOneAndDigits } from 'helpers/phoneFormat';

const callStatuses = {
  initial: 'initial',
  incoming: 'incoming',
  disconnect: 'disconnect',
  accept: 'accept',
  reject: 'reject',
  cancel: 'cancel',
  error: 'error',
  connecting: 'connecting',
  pending: 'pending',
  open: 'open',
  outgoing: 'outgoing',
};

const Twilio = () => {
  const { info, open, modalNames } = useModal();
  const { user } = useAuth();

  const [incomingJobInfo, setIncomingJobInfo] = useState(null);

  const [rerender, setRerender] = useState({});
  const trigerRerender = () => setRerender({});

  const [hold, setHold] = useState(false);
  const [call, setCall] = useState(null);
  const [status, setStatus] = useState(callStatuses.initial);
  const [lastOutcoming, setLastOutcoming] = useState('');
  const { mutate: redirectToConference } = useRedirectToConference();

  const { mutateAsync: getGeneralCompanyData } = useGeneralCompanyName();

  const { data: defaultItems } = useDefault();

  const company = useRef({});
  const setCompany = (id, number, name) => {
    company.current.company_id = id;
    company.current.phone_company = number;
    company.current.company_name = name;
  };

  const companiesList = useMemo(() => {
    if (!defaultItems || !user) return [];

    const companies = user?.phones.map((item) => {
      const [companyID, phoneNumber] = Object.entries(item)[0];
      const companyName = defaultItems.company.find((company) => Number(company.id) === Number(companyID)).company_name;
      return {
        label: companyName,
        value: {
          number: formatPhoneNumberPlusOneAndDigits(phoneNumber),
          id: companyID,
        },
      };
    });

    return companies;
  }, [defaultItems, user]);

  const isVisible = info(modalNames.twilio).visible;
  const phone = info(modalNames.twilio).params?.number;
  const companyID = info(modalNames.twilio).params?.companyID;
  const callUp = info(modalNames.twilio).params?.callUp;
  const isCallScript = info(modalNames.callScript).visible;

  useEffect(() => {
    if (companyID && companiesList && companiesList.length > 0) {
      const foundCompany = companiesList.find((company) => company.value.id == companyID);

      setCompany(companyID, foundCompany?.value?.number, foundCompany?.label);
      return;
    }
    if (companiesList && companiesList.length > 0) {
      const defaultCompany = companiesList[0];

      setCompany(defaultCompany.value.id, defaultCompany.value.number, defaultCompany.label);
    }
  }, [companyID, companiesList]);

  const { values, setFieldValue, handleChange } = useFormik({
    enableReinitialize: true,
    initialValues: {
      number: phone || lastOutcoming,
      lastIncoming: '',
      agent_id: null,
      dropped: '',
      company_name: '',
      customer_name: '',
      lead_provider: '',
      job_id: '',
      job_status: '',
      job_title: '',
      missed_call_id: null,
      previous_calls: [],
      spam: false,
      called_to: '',
      ...incomingJobInfo,
    },
  });

  const { mutateAsync: getIncomingJobInfo } = useIncomingJobInfo();

  const { data: token } = useCapabilityToken();

  const device = useMemo(() => {
    if (!token) return null;
    return new Device(token, {
      maxAverageBitrate: 16000,
      fakeLocalDTMF: true,
      allowIncomingWhileBusy: true,
      logLevel: 1,
    });
  }, [token]);

  useEffect(() => {
    if (call) {
      call.on(callStatuses.accept, (call) => {
        setStatus(callStatuses.accept);
        setTimeout(() => {
          const number = formatPhoneNumberOnlyDigits(values.number);

          redirectToConference({
            callSid: call.parameters.CallSid,
            name: user.js_client_name + number,
            type: 'direct',
          });
        }, 5000);
      });
      call.on(callStatuses.cancel, () => {
        setStatus(callStatuses.cancel);
      });
      call.on(callStatuses.disconnect, () => {
        setIncomingJobInfo(null);
        setCall(null);
        setStatus(callStatuses.disconnect);
      });
      call.on(callStatuses.error, (error) => {
        setStatus(callStatuses.error);
      });
      call.on(callStatuses.reject, () => {
        setIncomingJobInfo(null);
        setStatus(callStatuses.reject);
      });
    }
  }, [call, values.number, redirectToConference, setStatus, user.js_client_name]);

  useEffect(() => {
    if (device) {
      device.register();

      device.on(callStatuses.incoming, (call) => {
        open(modalNames.twilio, {
          number: call.parameters.From,
        });

        getIncomingJobInfo(
          { number: call.parameters.From },
          {
            onSuccess: async (res) => {
              const prepared = { ...res.data };

              const { data: generalCompanyData } = await getGeneralCompanyData({
                call_sid: call.parameters.CallSid,
                to: call.parameters.To,
              });

              prepared.called_to = generalCompanyData.company_name;
              prepared.lead_provider = generalCompanyData.lead_provider;
              prepared.lastIncoming = call.parameters?.From;

              setIncomingJobInfo(prepared);
            },
          }
        );

        setStatus(callStatuses.incoming);
        setCall(call);
      });
      device.on(callStatuses.error, (twilioError) => console.error('twilioError', twilioError));
    }
    return () => {
      if (device && device.state !== 'unregistered') {
        device.unregister();
      }
    };
  }, [device, getIncomingJobInfo, getGeneralCompanyData, modalNames.twilio, open, setFieldValue, setStatus]);

  return (
    <Fragment>
      {isVisible && (
        <PhoneNew
          hold={hold}
          call={call}
          number={phone}
          callUp={callUp}
          status={status}
          device={device}
          company={company}
          setCompany={setCompany}
          values={values}
          setHold={setHold}
          setCall={setCall}
          setStatus={setStatus}
          callStatuses={callStatuses}
          trigerRerender={trigerRerender}
          handleChange={handleChange}
          companiesList={companiesList}
          lastOutcoming={lastOutcoming}
          setLastOutcoming={setLastOutcoming}
          setIncomingJobInfo={setIncomingJobInfo}
          getIncomingJobInfo={getIncomingJobInfo}
        />
      )}

      {isCallScript && <CallScript />}
    </Fragment>
  );
};

export default Twilio;

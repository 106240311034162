export function prepareInitialValues(carrier_id) {
  return {
    carrier_id,
    phone: '',
    name: '',
  };
}

export function prepareCarriersOptions(carriers) {
  return carriers.map((carrier) => ({
    value: carrier.id,
    label: carrier.name,
  }));
}

import React from 'react';
import Icon from 'components/Icon';
import { useModal } from 'context/modal';

const JobStopDetailsItem = ({ data, index, typeOfBuilding }) => {
  const buildingTypes = typeOfBuilding?.map((el, index) => ({ value: index, label: el })) ?? [];

  const { modalNames, open } = useModal();
  const openExtraStopModal = () => open(modalNames.extraStop, { type: 'update', id: data.stop_id });

  return (
    <div className="new-table">
      <div className="new-table--row new-table--row__column">
        <div className="new-table--row--item justify-content-between mh60">
          <h3 className="header">Extra stop {index + 1}</h3>
          <button onClick={openExtraStopModal}>
            <Icon icon="notes" title="Edit extra stop details" />
          </button>
        </div>

        {buildingTypes.length > 0 && (
          <p className="new-table--row--item justify-content-between mh60" style={{ background: 'red' }}>
            What kind of building are you moving to?
            <b>{buildingTypes.find((el) => el.value === +data.type_of_building).label} </b>
          </p>
        )}

        {(+data.type_of_building < 4 || +data.type_of_building === 6) && (
          <p className="new-table--row--item justify-content-between mh60">
            Building access:
            <b>
              <span>{data.building_access_start || ''}</span> &mdash;
              <span>{data.building_access_end || ''}</span>
            </b>
          </p>
        )}

        {!!data.elevator_exist && (
          <p className="new-table--row--item justify-content-between mh60">
            Elevator reservation:
            <b>
              <span>{data.elevator_reservation_start || ''}</span> &mdash;
              <span>{data.elevator_reservation_end || ''}</span>
            </b>
          </p>
        )}
        {!!data.elevator_floor && (
          <p className="new-table--row--item justify-content-between mh60">
            Which floor:
            <b>{data.elevator_floor || ''}</b>
          </p>
        )}
        {!!data.distance_elevator_apartment && (
          <p className="new-table--row--item justify-content-between mh60">
            What is a distance from apartment door to the elevator (Yards):
            <b>{data.distance_elevator_apartment || ''}</b>
          </p>
        )}
        {!!data.distance_elevator_parking && (
          <p className="new-table--row--item justify-content-between mh60">
            What is the distance from elevator to the loading dock/truck parking - (Yards):
            <b>{data.distance_elevator_parking || ''}</b>
          </p>
        )}

        {Boolean(data.hardwood_protect || data.marble_protect || data.carpet_protect) && (
          <p className="new-table--row--item justify-content-between mh60">
            Need floor/wall protection:
            <b>
              {!!data.hardwood_protect && <span>Hardwood; </span>}
              {!!data.marble_protect && <span>Marble; </span>}
              {!!data.carpet_protect && <span>Carpet </span>}
            </b>
          </p>
        )}

        {!!data.hoisting && (
          <p className="new-table--row--item justify-content-between mh60">
            Need Hoisting of heavy items:
            <b>Yes</b>
          </p>
        )}

        {!!data.hoisting_note && (
          <p className="new-table--row--item justify-content-between mh60">
            Hoisting note:
            <b>{data.hoisting_note}</b>
          </p>
        )}

        {!!data.apt_finished && (
          <p className="new-table--row--item justify-content-between mh60">
            Inventory List
            <b>{data.apt_note}</b>
          </p>
        )}

        {!!data.packing && (
          <p className="new-table--row--item justify-content-between mh60">
            Are you packing miscellaneous items yourself?
            <b>{data.packing_note}</b>
          </p>
        )}

        {!!data.fragile && (
          <p className="new-table--row--item justify-content-between mh60">
            Do you have any fragile items such as mirror, tv, glass, picture, paintings, or any other?
            <b>{data.fragile_note}</b>
          </p>
        )}

        {!!data.wrap && (
          <p className="new-table--row--item justify-content-between mh60">
            Do you have any fragile items such as mirror, tv, glass, picture, paintings, or any other?
            <b>{data.wrap_note}</b>
          </p>
        )}
      </div>
    </div>
  );
};

export default JobStopDetailsItem;

import React, { useCallback, useMemo, Fragment } from 'react';
import { useModal } from 'context/modal';
import { useInfo, useTabInfo, useDiscountUpdate } from 'hooks/query/useJob';
import { useParams } from 'react-router-dom';
import { useDefault } from 'hooks/query/useCommon';
import Icon from 'components/Icon';

const Discount = ({ type }) => {
  const { id, tab } = useParams();
  const { modalNames, open } = useModal();
  const { mutate: updateDiscount } = useDiscountUpdate(id, tab);

  const {
    data: { discounts: defaultDiscounts },
  } = useDefault();

  const {
    data: { job_info: jobInfo },
  } = useInfo(id);

  const {
    data: { job_discounts: jobDiscounts },
  } = useTabInfo(id, tab);

  const estPrepared = useMemo(() => {
    return defaultDiscounts[jobInfo.company_id].reduce((acc, e) => {
      if (jobDiscounts['estimated'].find((k) => k.discount_id === e.id)) return acc.concat(e);
      return acc;
    }, []);
  }, [defaultDiscounts, jobInfo.company_id, jobDiscounts]);

  const finPrepared = useMemo(() => {
    return defaultDiscounts[jobInfo.company_id].reduce((acc, e) => {
      if (jobDiscounts['final'].find((k) => k.discount_id === e.id)) return acc.concat(e);
      return acc;
    }, []);
  }, [defaultDiscounts, jobInfo.company_id, jobDiscounts]);

  const handleDeleteDiscount = useCallback(
    (discountId, type) => {
      const isFinal = type === 'final';
      const data = isFinal ? finPrepared : estPrepared;
      const preparedData = data.filter((item) => item.id !== discountId).map((e) => ({ job_id: id, discount_id: e.id }));;

      updateDiscount({
        job_id: id,
        job_discount: {
          estimated: isFinal ? jobDiscounts['estimated'] : preparedData,
          final: preparedData,
        },
      });
    },
    [id, jobDiscounts, updateDiscount, finPrepared, estPrepared]
  );

  return (
    <Fragment>
      <div className="new-table--row bcg-light">
        <div className="new-table--row--item justify-content-center w40">
          <b>Discount</b>
        </div>
        <div className="new-table--row--item w30 justify-content-center no-padding">
          <button
            className="btn p20 flex-grow-1 d-flex text-center"
            onClick={() => open(modalNames.discount, { type: 'estimated', companyId: jobInfo.company_id, jobDiscounts })}
          >
            <Icon icon="add" size={16} />
          </button>
        </div>
        <div className="new-table--row--item w30 justify-content-center no-padding">
          <button
            className="btn p20 flex-grow-1 d-flex text-center"
            onClick={() => open(modalNames.discount, { type: 'final', companyId: jobInfo.company_id, jobDiscounts })}
          >
            <Icon icon="add" size={16} />
          </button>
        </div>
      </div>

      {(estPrepared.length !== 0 || finPrepared.length !== 0) && (
        <Fragment>
          <div className="new-table--row">
            <div className="new-table--row--item justify-content-center w40" />
            <div className="new-table--row--item no-side-shadows w60 no-padding align-items-start">
              {estPrepared.length ? (
                <div className="new-table--row--item w50 no-padding flex-direction-column">
                  {estPrepared.map((item) => (
                    <div className="new-table--row with-shadow" key={item.id}>
                      <div className="new-table--row--item w70 justify-content-center no-side-shadows">
                        <button onClick={() => handleDeleteDiscount(item.id, 'estimated')}>
                          <Icon size={20} icon="delete" title={`Delete estimated discount`} />
                        </button>
                        {item.discount_name}
                      </div>
                      <div className="new-table--row--item w30 justify-content-center no-side-shadows">
                        <b>${item.amount}</b>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="new-table--row--item no-side-shadows w50 no-padding flex-direction-column" />
              )}

              {finPrepared.length ? (
                <div className="new-table--row--item w50 no-padding flex-direction-column">
                  {finPrepared.map((item) => (
                    <div className="new-table--row with-shadow" key={item.id}>
                      <div className="new-table--row--item w70 justify-content-center no-side-shadows">
                        <button onClick={() => handleDeleteDiscount(item.id, 'final')}>
                          <Icon size={20} icon="delete" title={`Delete final discount`} />
                        </button>
                        {item.discount_name}
                      </div>
                      <div className="new-table--row--item w30 justify-content-center no-side-shadows">
                        <b>${item.amount}</b>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="new-table--row--item no-side-shadows w50 no-padding flex-direction-column" />
              )}
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default Discount;

import React, { useMemo } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';

const DateSelect = (props) => {
  const { selected, onChange, className, placeholder, isClearable, showWeekNumbers, minDate, disabled, name, hideToday, filterDate, error } = props;

  const date = useMemo(() => {
    if (!selected) return null;

    return moment.utc(moment(selected)).toDate();
  }, [selected]);

  return (
    <div className="d-flex flex-column position-relative">
      <DatePicker
        selected={date}
        // selected={!!selected ? new Date(selected) : null}
        onChange={(date) => onChange({ name, date })}
        className={className}
        placeholderText={placeholder}
        isClearable={isClearable}
        showWeekNumbers={showWeekNumbers}
        minDate={minDate}
        disabled={disabled}
        // highlightDates={[new Date()]}
        todayButton={hideToday ? null : 'Today'}
        filterDate={filterDate}
      />
      {error && (
        <span style={{ position: 'absolute', bottom: -15 }} className="error">
          {error}
        </span>
      )}
    </div>
  );
};

export default DateSelect;

import React, { useCallback, useMemo, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { periodOptions, followUpSortOptions, forbiddenTypes, jobFiltersOptions } from 'helpers/constants';
import { getDateFromPeriod, formatFullDate } from 'helpers/dateHelper';
import history, { updateRouteParams } from 'utils/history';
import { useDefault, useStatusesTemplateSave, useStatusesTemplate } from 'hooks/query/useCommon';
import { DropdownMenu, ButtonLinkItem, ButtonIconItem } from 'components/newComponents/DropDown';
import JobFilterDropdown from 'components/JobFilterDropdown';
import Icon from 'components/Icon';
import DateSelect from 'components/DateSelect';
import { routePaths } from 'config/routes';

const TopBar = ({ onChangeParams, onRefreshJobList }) => {
  const { type } = useParams();
  const { search } = useLocation();

  const isForbidden = useMemo(() => forbiddenTypes.includes(type), [type]);

  const isFollowUp = type.includes('followUp');

  const { data: statusesTemplate } = useStatusesTemplate();

  const params = useMemo(() => {
    const searchParams = new URLSearchParams(search);
    const result = { priority: 'null' };

    for (let pair of searchParams.entries()) {
      result[pair[0]] = pair[1];
    }

    return result;
  }, [search]);

  const [inputValue, setInputValue] = useState(params.value || '');

  const { data: defaultItems } = useDefault();

  const { mutate: updateStatuses } = useStatusesTemplateSave();

  const jobPriority = useMemo(() => {
    if (!defaultItems) return [];
    return defaultItems.statuses.job_priority;
  }, [defaultItems]);

  const changePeriodHandler = useCallback(
    (v) => {
      const { start, end } = getDateFromPeriod(v);
      onChangeParams({ start_date: start ?? '', end_date: end }, 'all');
    },
    [onChangeParams]
  );

  const onSelectDateHandler = useCallback(
    ({ name, date }) => {
      if (date) {
        onChangeParams({ [name]: formatFullDate(date) }, 'all');
      } else {
        onChangeParams({ [name]: '' }, 'all');
      }
    },
    [onChangeParams]
  );

  const changeFollowUpHandler = useCallback((type) => {
    history.push({ pathname: updateRouteParams(routePaths.JOBS, { type }) });
  }, []);

  const changePriorityHandler = useCallback(
    (v) => {
      const index = jobPriority.findIndex((e) => e === v);
      onChangeParams({
        priority: index < 0 ? null : index,
      });
    },
    [jobPriority, onChangeParams]
  );

  const onChangeStatuses = useCallback(
    (status, isChecked) => {
      if (isChecked) {
        updateStatuses({ statuses: statusesTemplate.filter((e) => Number(e) !== Number(status)) }, { onSuccess: () => onRefreshJobList() });
      }
      if (!isChecked) {
        updateStatuses({ statuses: [...statusesTemplate, status] }, { onSuccess: () => onRefreshJobList() });
      }
    },
    [statusesTemplate, updateStatuses, onRefreshJobList]
  );

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      onChangeParams({ value: inputValue || '' });
    }
  };

  return (
    <form>
      <div className="new-table">
        <div className="new-table--row">
          <div className="new-table--row--item p15">
            <DropdownMenu wrapperClass="w5" trigger={<Icon icon="select_period" title="Select Period" size={20} />}>
              {periodOptions.map((e) => (
                <ButtonLinkItem key={e.value} label={e.label} value={e.value} onClick={changePeriodHandler} />
              ))}
            </DropdownMenu>

            <DateSelect selected={params?.start_date} onChange={onSelectDateHandler} placeholder="From Date" name="start_date" showWeekNumbers />

            <DateSelect selected={params?.end_date} onChange={onSelectDateHandler} placeholder="To Date" name="end_date" showWeekNumbers />
          </div>
          <div className="new-table--row--item p15">
            {isFollowUp && (
              <DropdownMenu wrapperClass="w5" trigger={<Icon icon="job_filter" title="Sort By" />}>
                {followUpSortOptions.map((e) => (
                  <ButtonLinkItem key={e.value} label={e.label} value={e.value} onClick={changeFollowUpHandler} />
                ))}
              </DropdownMenu>
            )}

            <div className="flex-grow-1 d-flex align-items-center">
              <input
                value={inputValue}
                placeholder="Type Keyword"
                name="searchValue"
                type="text"
                onKeyDown={handleKeyDown}
                onChange={(e) => setInputValue(e.target.value)}
              />
              <button type="button" onClick={() => onChangeParams({ value: inputValue || '' })}>
                <Icon icon="search" title="Search" size={20} />
              </button>
            </div>

            {!isForbidden && (
              <DropdownMenu wrapperClass="w5" trigger={<Icon icon="sort" title="Filters" size={25} />}>
                <JobFilterDropdown jobOptions={jobFiltersOptions} onChange={onChangeStatuses} statuses={statusesTemplate} />
              </DropdownMenu>
            )}
            {jobPriority?.length && (
              <DropdownMenu
                wrapperClass="w5"
                menuWrapperClass="mw-auto job_priority_filter"
                trigger={
                  <Icon
                    size={22}
                    icon={params?.priority !== 'null' ? jobPriority?.[params?.priority ?? null]?.toLowerCase() : 'all'}
                    title={jobPriority?.[params?.priority ?? 'ALL']}
                  />
                }
              >
                <ButtonIconItem key={'all'} value={'ALL'} onClick={changePriorityHandler} />
                {jobPriority.map((e) => (
                  <ButtonIconItem key={e} value={e} onClick={changePriorityHandler} />
                ))}
              </DropdownMenu>
            )}
          </div>
        </div>
      </div>
    </form>
  );
};

export default TopBar;

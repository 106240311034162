import { useMutation, useQuery } from 'react-query';
import axios from 'axios';
import showError from 'utils/errorHandler';
import queryKeys from 'config/queryKeys';
import history from 'utils/history';

const api = `${process.env.REACT_APP_API_URL}/api/customer/jobs`;

const useGetInventory = (job_id) => {
  return useQuery(
    queryKeys.inventory,
    () => {
      return axios.post(`${api}/getCustomerInventory`, { job_id });
    },
    {
      select: (res) => res.data,
      onError: (error) => showError(error),
      retry: 0,
      cacheTime: 0,
      staleTime: 0,
    }
  );
};

const useSaveInventory = () => {
  return useMutation((data) => axios.post(`${api}/saveCustomerInventory`, data), {
    onError: (error) => showError(error),
    onSuccess: () => history.push('/customer/info/thank-you'),
  });
};

export { useGetInventory, useSaveInventory };

import React, { memo, Fragment } from 'react';
import Group from './Group';

const Categories = memo(({ inventoryByCategories, type, addItem }) => {
  return (
    <Fragment>
      {Object.keys(inventoryByCategories).map((category, i) => {
        return inventoryByCategories[category].length ? (
          <Fragment key={category}>
            <div className="new-table--row bcg-light">
              <div className="new-table--row--item flex-grow-1">
                <span>{category}</span>
              </div>
              <div className="new-table--row--item w10 justify-content-center">
                <span>CF</span>
              </div>
              <div className="new-table--row--item w15 justify-content-center">
                <span>Qty</span>
              </div>
              <div className="new-table--row--item w20 justify-content-center">
                <span />
              </div>
            </div>
            <Group i={i} type={type} inventory={inventoryByCategories[category]} addItem={addItem} />
          </Fragment>
        ) : null;
      })}
    </Fragment>
  );
});

export default Categories;

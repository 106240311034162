import axios from 'axios';
import showError from 'utils/errorHandler';

class Api {
  constructor() {
    this.serverApi = process.env.REACT_APP_API_URL;
    this.client = axios.create({ baseURL: this.serverApi });
  }

  getData = async (data) => {
    try {
      return await this.client.get('/api/customer/getJobEditData', { params: data });
    } catch (e) {
      showError(e);
    }
  };

  getPackingInventoryData = async (data) => {
    try {
      return await this.client.get('/api/customer/getPackingInventory', { params: data });
    } catch (e) {
      showError(e);
    }
  };

  saveData = async (data) => {
    try {
      return await this.client.post('/api/customer/saveJobEditData', data);
    } catch (e) {
      showError(e);
    }
  };

  confirmJob = async (data) => {
    try {
      return await this.client.post('/api/customer/confirmJob', data);
    } catch (e) {
      showError(e);
    }
  };

  cancelJob = async (data) => {
    try {
      return await this.client.post('/api/customer/cancelJob', data);
    } catch (e) {
      showError(e);
    }
  };

  rescheduleJob = async (data) => {
    try {
      return await this.client.post('/api/customer/rescheduleJob', data);
    } catch (e) {
      showError(e);
    }
  };
}

export default new Api();

import moment from 'moment';
import { statusNumberToName } from 'helpers/statuses';

const CrewTooltipInfo = (day_item, moveSize, title) => {
  const date = `${moment(day_item.date_start).format('hh:mm a')} - ${moment(day_item.date_stop).format('hh:mm a')}`;

  return `<div class="crew-tooltip">
    <h3 class="header">${day_item.job_title} - ${title} - ${statusNumberToName(day_item.job_status)}</h3>
    <div class="crew-tooltip--row">
      <span class="av-tooltip--row--item text">Crew:</span>
      <span class="av-tooltip--row--item">${day_item.crew_name ?? 'Unassigned'}</span>
      <span class="av-tooltip--row--item "/>
    </div>
    <div class="crew-tooltip--row">
      <span class="av-tooltip--row--item text">Address:</span>
      <span class="av-tooltip--row--item">From: ${day_item.from_address}</span>
      <span class="av-tooltip--row--item">To: ${day_item.to_address}</span>
     </div>
    <div class="crew-tooltip--row">
      <span class="av-tooltip--row--item text">Zip:</span>
      <span class="av-tooltip--row--item">From: ${day_item.from_zipcode}</span>
      <span class="av-tooltip--row--item">To: ${day_item.to_zipcode}</span>
    </div>
    <div class="crew-tooltip--row">
      <span class="av-tooltip--row--item text">Distance:</span>
      <span class="av-tooltip--row--item">Office to pickup: ${(+day_item.distance_pickup).toFixed(2)}</span>
      <span class="av-tooltip--row--item">
        Pickup to destination: ${(day_item.distance_total - parseFloat(day_item.distance_pickup)).toFixed(2)}
      </span>
    </div>
    <div class="crew-tooltip--row">
      <span class="av-tooltip--row--item text">Date:</span>
      <span class="av-tooltip--row--item">${date}</span>
     <span class="av-tooltip--row--item"/>
    </div>
    <div class="crew-tooltip--row">
      <span class="av-tooltip--row--item text">Move size:</span>
      <span class="av-tooltip--row--item">${moveSize.name}</span>
     <span class="av-tooltip--row--item"/>
    </div>
    </div>
  </div>`;
};

export default CrewTooltipInfo;

import React, { useCallback, Fragment } from 'react';
import MaskedInput from 'react-maskedinput';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';
import { normalize } from 'helpers/normalizeGoogleAddress';

import './styles.scss';

const Autocomplete = (props) => {
  const { name, value, onChange, onSelect, placeholder, error, disabled, mask, onBlur, shouldFetchSuggestions = true } = props;

  const onSelectHandler = useCallback(
    async (address) => {
      const [res] = await geocodeByAddress(address);
      if (onSelect) onSelect(normalize(res), name);
    },
    [name, onSelect]
  );

  const onBlurHandler = useCallback(
    async (e) => {
      if (onBlur) {
        const [res] = await geocodeByAddress(e.target.value);
        if (res) onBlur(normalize(res), name);
      }
    },
    [name, onBlur]
  );

  return (
    <div className="autocomplete-wrapper" key={name}>
      <PlacesAutocomplete
        name={name}
        value={value}
        onChange={(value) => onChange(value, name)}
        onSelect={onSelectHandler}
        shouldFetchSuggestions={shouldFetchSuggestions}
        searchOptions={{
          language: 'en',
          componentRestrictions: { country: 'us' },
          fields: ['address_component'],
          types: ['geocode'],
        }}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            {mask ? (
              <MaskedInput
                {...getInputProps({
                  placeholder: placeholder || 'Search Places ...',
                  className: 'location-search-input',
                  autoComplete: 'off',
                  disabled: disabled,
                  mask: mask,
                  onBlur: onBlurHandler,
                })}
              />
            ) : (
              <input
                {...getInputProps({
                  placeholder: placeholder || 'Search Places ...',
                  className: 'location-search-input',
                  autoComplete: 'off',
                  disabled: disabled,
                  onBlur: onBlurHandler,
                })}
              />
            )}

            <div className="autocomplete-dropdown-container">
              <Fragment>
                {loading && <div>Loading...</div>}
                {suggestions.map((suggestion) => (
                  <div
                    {...getSuggestionItemProps(suggestion)}
                    className={suggestion.active ? ' suggestion-item suggestion-item--active' : 'suggestion-item'}
                    key={suggestion.placeId}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                ))}
              </Fragment>
            </div>
          </div>
        )}
      </PlacesAutocomplete>
      {error && <span className="error">{error}</span>}
    </div>
  );
};

export default Autocomplete;

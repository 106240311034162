import React, { Fragment, useMemo } from 'react';
import Input from 'components/newComponents/Input';
import AutocompleteInput from 'components/newComponents/AutocompleteInput';
import Autocomplete from 'components/newComponents/Autocomplete';
import { useForm } from '../../../hooks/useForm';
import { getBuildingsOptions } from '../../../utils';

function Address({ buildingInfo }) {
  const { values, actions } = useForm();

  const isLaborTypeDefault = useMemo(() => values.labor_type === 'default', [values.labor_type]);

  const buildings = getBuildingsOptions(buildingInfo);

  return (
    <Fragment>
      <div className="new-table--row">
        <div className="new-table--row--item no-padding">
          <div className="new-table--row--item">
            <Autocomplete
              name="from"
              placeholder="Search address"
              value={values['from_address']}
              // error={isValidHandler('from_address')}
              onChange={(value) => {
                actions.setFieldValue('from_address', value);
                if (!isLaborTypeDefault) actions.setFieldValue('to_address', value);
              }}
              onSelect={(value, name) => actions.onSelectAutoCompleteHandler(value, name, buildingInfo)}
            />
          </div>
          <div className="new-table--row--item w10 no-shadow">
            <Input name="from_apartment" placeholder="apt" value={values['from_apartment']} onChange={actions.handleChange} />
          </div>
        </div>
        <div className="new-table--row--item no-padding">
          <div className="new-table--row--item">
            <Autocomplete
              name="to"
              placeholder="Search address"
              value={values['to_address']}
              onChange={(value) => actions.setFieldValue('to_address', value)}
              onSelect={(value, name) => actions.onSelectAutoCompleteHandler(value, name, buildingInfo)}
              // error={isValidHandler('to_address')}
              disabled={!isLaborTypeDefault}
            />
          </div>
          <div className="new-table--row--item w10 no-shadow">
            <Input name="to_apartment" placeholder="apt" value={values['to_apartment']} onChange={actions.handleChange} />
          </div>
        </div>
      </div>

      <div className="new-table--row">
        <div className="new-table--row--item">
          <AutocompleteInput
            name="from_building_name"
            placeholder="Building name"
            value={values['from_building_name']}
            onChange={(value) => actions.setFieldValue('from_building_name', value)}
            onSelect={(value, name) => actions.handleSelectAutocompleteByName(value, name, buildingInfo)}
            options={buildings}
          />
        </div>
        <div className="new-table--row--item">
          <AutocompleteInput
            name="to_building_name"
            placeholder="Building name"
            value={values['to_building_name']}
            onChange={(value) => actions.setFieldValue('to_building_name', value)}
            onSelect={(value, name) => actions.handleSelectAutocompleteByName(value, name, buildingInfo)}
            disabled={!isLaborTypeDefault}
            options={buildings}
          />
        </div>
      </div>

      <div className="new-table--row">
        <div className="new-table--row--item no-padding">
          <div className="new-table--row--item">
            <Autocomplete
              mask="11111"
              placeholder="Zip"
              name="from"
              onBlur={actions.handleZipCode}
              value={values['from_zipcode']}
              shouldFetchSuggestions={false}
              error={actions.isValidHandler('from_zipcode')}
              onChange={(value) => {
                actions.setFieldValue('from_zipcode', value);
                if (!isLaborTypeDefault) actions.setFieldValue('to_zipcode', value);
              }}
            />
          </div>
        </div>

        <div className="new-table--row--item no-padding">
          <div className="new-table--row--item">
            <Autocomplete
              mask="11111"
              name="to"
              placeholder="Zip"
              onBlur={actions.handleZipCode}
              value={values['to_zipcode']}
              shouldFetchSuggestions={false}
              error={actions.isValidHandler('to_zipcode')}
              disabled={!isLaborTypeDefault}
              onChange={(value) => actions.setFieldValue('to_zipcode', value)}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Address;

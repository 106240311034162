import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import Api from './api/api';
import './styles.scss';
import history from 'utils/history';
import routes from 'config/routes';

const ElevatorReservation = React.memo(() => {
  const { job_id, type } = useParams();

  const [data, setData] = useState(null);

  const handleFetch = useCallback(async () => {
    if (!job_id || !type) return;

    const response = await Api.getData(job_id, type);

    if (response) {
      setData(response.data);
    }
  }, [job_id, type]);

  const handleApprove = useCallback(() => {
    const isSuccess = Api.approve(job_id, type);

    if (isSuccess) {
      history.push({ pathname: routes.COI_CERT_APPROVING_SUCCESS });
    }
  }, [job_id, type]);

  const handleReject = useCallback(() => {
    const isSuccess = Api.reject(job_id, type);

    if (isSuccess) {
      history.push({
        pathname: routes.COI_CERT_APPROVING_SUCCESS,
      });
    }
  }, [job_id, type]);

  useEffect(handleFetch, []);

  return !data ? null : (
    <div className="elevator-reservation">
      <div className="elevator-reservation__wrap">
        <h1>Please confirm elevator reservation</h1>
        <p className="elevator-reservation__job-title">{data.job_title}</p>

        <p className="elevator-reservation__date">
          Move date:<span>{data.date}</span>
        </p>
        <div className="elevator-reservation__time">
          <div>
            From<span>{data.from}</span>
          </div>
          <div>
            To<span>{data.to}</span>
          </div>
        </div>

        <div className="elevator-reservation__action">
          <button onClick={handleApprove} type="button" className="btn btn-confirm">
            Confirm
          </button>

          <button onClick={handleReject} type="button" className="btn btn-reject">
            Reject
          </button>
        </div>
      </div>
    </div>
  );
});

export default ElevatorReservation;

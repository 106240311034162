import React, { useCallback, useMemo } from 'react';
import { useDefault } from 'hooks/query/useCommon';
import { useTaskCreate } from 'hooks/query/useTask';
import { formatFullDBDate } from 'helpers/dateHelper';
import { useLocation } from 'react-router-dom';
import { matchPath } from 'react-router';
import { routePaths } from 'config/routes';
import statusTypes from 'helpers/TaskStatusTypes';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import FormikComponents from 'components/FormikComponents';

const TaskCreateForm = ({ userId, companyId, jobId }) => {
  const {
    data: { users },
  } = useDefault();

  const { pathname } = useLocation();

  const {
    params: { id, tab },
  } = matchPath(pathname, { path: routePaths.JOBS });
  const { mutate: createTask } = useTaskCreate(id, tab);

  const agentsOption = useMemo(() => {
    const list = [...new Map([...users.agents, ...users.managers].map((user) => [user['id'], user])).values()];
    return list.map((user) => ({ value: user.id, label: user.first_name + ' ' + user.last_name }));
  }, [users.agents, users.managers]);

  const handleSubmitForm = useCallback(
    (values, { resetForm }) => {
      const { due_date, ...res } = values;

      createTask({ data: { ...res, job_id: jobId, due_date: due_date ? formatFullDBDate(due_date) : '' } });
      resetForm();
    },
    [createTask, jobId]
  );

  return (
    <Formik
      initialValues={{
        task: '',
        type: '1',
        due_date: '',
        due_time: '',
        author_id: userId,
      }}
      validationSchema={Yup.object().shape({
        task: Yup.string().required('Enter task name!'),
        due_time: Yup.string().required('Select time!'),
      })}
      onSubmit={handleSubmitForm}
    >
      <Form>
        <div className="new-table--row">
          <div className="new-table--row--item flex-grow-1">
            <Field name="task" as={FormikComponents.FormikInput} placeholder="*New Task" />
          </div>
          <div className="new-table--row--item w25 select">
            <Field name="type" as={FormikComponents.FormikSelect} options={statusTypes} defaultValue={statusTypes[0]} />
          </div>
          <div className="new-table--row--item w20 select">
            <Field name="due_date" as={FormikComponents.FormikDate} minDate={new Date()} placeholder="Due date" />
          </div>
          <div className="new-table--row--item w20 select">
            <Field name="due_time" placeholder="*Select time" as={FormikComponents.FormikTime} />
          </div>
          <div className="new-table--row--item w30 select">
            <Field name="author_id" as={FormikComponents.FormikSelect} options={agentsOption} />
          </div>
          <div className="new-table--row--item w10 no-padding">
            <button type="submit" className="main flex-grow-1">
              Add
            </button>
          </div>
        </div>
      </Form>
    </Formik>
  );
};

export default TaskCreateForm;

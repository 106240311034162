import Api from './api/api';

const useJobController = (params) => {
  const { showPreloader, hidePreloader } = params;

  const saveChanges = async (state) => {
    showPreloader();
    await Api.saveData(state);
    await Api.getData({ job_id: state.job_id });
    hidePreloader();
  };

  const confirmJob = async (jobId) => {
    showPreloader();
    await Api.confirmJob({ job_id: jobId });
    hidePreloader();
    window.location.href = `/customer/info/thank-you`;
  };

  const bookingJob = async (jobId) => {
    window.open(window.location.origin + '/customer/termsConditions?job_id=' + jobId + '&document=invoice', '_blank');
  };

  const cancelJob = async (jobId) => {
    showPreloader();
    await Api.cancelJob({ job_id: jobId });
    hidePreloader();

    window.location.href = `/customer/info/canceled`;
  };

  const handleReschedule = (data) => {
    showPreloader();

    return Api.rescheduleJob(data).then(() => {
      hidePreloader();
      window.location.href = `${window.location.origin}/customer/info/reschedule`;
    });
  };

  return { saveChanges, confirmJob, bookingJob, cancelJob, handleReschedule };
};

export { useJobController };

import React, { memo } from 'react';
import classNames from 'classnames';

export const SelectLinkItem = memo(({ label, value, onClick, current, icon, className }) => {
  return (
    <button className={classNames('select-link', className, { active: current === value })} type="button" onClick={() => onClick(value)}>
      {icon}
      {label}
    </button>
  );
});

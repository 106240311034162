import React from 'react';
import { useLocation } from 'react-router-dom';
import { matchPath } from 'react-router';
import { routePaths } from 'config/routes';
import { useList } from 'hooks/query/useReminder';
import SmsReminderItem from './SmsReminderItem';
import SmsReminderCreateForm from './SmsReminderCreateForm';

const SmsReminders = () => {
  const { pathname } = useLocation();

  const {
    params: { id },
  } = matchPath(pathname, { path: routePaths.JOBS });
  const { data } = useList(id);

  return (
    <div className="new-table modal">
      <SmsReminderCreateForm jobId={id} smsChains={data?.sms_chains} />

      <div className="new-table">
        <div className="new-table--row">
          <div className="new-table--row--item justify-content-center no-side-shadows bcg-light">
            <b>Message</b>
          </div>
          <div className="new-table--row--item justify-content-center no-side-shadows bcg-light">
            <b>Date</b>
          </div>
          <div className="new-table--row--item justify-content-center no-side-shadows bcg-light">
            <b>Actions</b>
          </div>
        </div>
        {data?.sms_reminders.map((reminder) => (
          <SmsReminderItem jobId={id} key={reminder.id} reminder={reminder} />
        ))}
      </div>
    </div>
  );
};

export default SmsReminders;

import React from 'react';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router';
import { useTabInfo, useAddHouseholdGoods, useInfo } from 'hooks/query/useJob';
import { useDefault } from 'hooks/query/useCommon';
import CheckBox from 'components/newComponents/CheckBox';
import queryKeys from 'config/queryKeys';
import { preventArrowUpDown } from 'utils/preventArrowUpDown';

const HouseholdGoods = () => {
  const { id, tab } = useParams();

  const { data: defaultItems } = useDefault();

  const { data: inventory } = useTabInfo(id, tab);

  const queryClient = useQueryClient();

  const jobHouseholdGoods = inventory?.job_household_goods ?? [];

  const { mutate } = useAddHouseholdGoods();

  const {
    data: { related_jobs },
  } = useInfo(id);

  const handleUpdate = (selected) => {
    mutate(
      { job_household_goods: selected, job_id: id },
      {
        onSettled: () => {
          queryClient.invalidateQueries([queryKeys.job, id, tab]);
          queryClient.invalidateQueries([queryKeys.job, related_jobs?.long_delivery_job, tab]);
          queryClient.invalidateQueries([queryKeys.job, related_jobs?.pickup_job, tab]);
          queryClient.invalidateQueries([queryKeys.job, related_jobs?.storage_job, tab]);
        },
      }
    );
  };

  const handleCheckbox = (service) => {
    const isAlreadySelected = jobHouseholdGoods.find((item) => item.good_id === service.id);
    let selected = [];

    if (isAlreadySelected) {
      selected = jobHouseholdGoods.filter((item) => item.good_id !== service.id);
    } else {
      selected = [...jobHouseholdGoods, { job_id: id, good_id: service.id, cu_ft: service.cu_ft, qty: 0 }];
    }

    handleUpdate(selected);
  };

  const handleBlur = (event, name, selectedIndex) => {
    const value = event.target.value;

    const prepared = jobHouseholdGoods.map((item, index) => {
      if (index === selectedIndex) {
        return { ...item, [name]: value };
      }

      return item;
    });

    handleUpdate(prepared);
  };

  return (
    <div className="inventory-tab">
      <h3 className="header table-header-shadow" style={{ width: '100%' }}>
        ADDITIONAL HOUSEHOLD GOODS ITEM LIST
      </h3>

      <div>
        <div className="ld-head">
          <div className="d-flex ld-b-r" style={{ width: '50%' }}>
            <div style={{ width: '10%', padding: 12 }}></div>
            <div style={{ width: '40%', padding: 12 }}>Article</div>
            <div style={{ width: '25%', padding: 12 }}>N0. of Items</div>
            <div style={{ width: '25%', padding: 12 }}>EST. CU.FT./LBS.</div>
          </div>
          <div className="d-flex ld-b-l" style={{ width: '50%' }}>
            <div style={{ width: '10%', padding: 12 }}></div>
            <div style={{ width: '40%', padding: 12 }}>Article</div>
            <div style={{ width: '25%', padding: 12 }}>N0. of Items</div>
            <div style={{ width: '25%', padding: 12 }}>EST. CU.FT./LBS.</div>
          </div>
        </div>
        <div style={{ columns: 2, columnGap: 0 }}>
          {defaultItems?.household_goods?.map((item) => {
            const isSelected = jobHouseholdGoods.length && Boolean(jobHouseholdGoods.find((selected) => selected.good_id === item.id));
            const selectedIndex = jobHouseholdGoods.findIndex((selected) => selected.good_id === item.id);
            const qty = isSelected ? jobHouseholdGoods[selectedIndex].qty : '';
            const cuFt = isSelected ? jobHouseholdGoods[selectedIndex].cu_ft : item.cu_ft;

            return (
              <div className="d-flex ld-b-l ld-b-r" key={item.id}>
                <div className="ld-cell" style={{ width: '10%', padding: 12 }}>
                  <CheckBox className="justify-content-center" checked={isSelected} onChange={() => handleCheckbox(item)} />
                </div>
                <div className="ld-cell" style={{ width: '40%' }}>
                  {item.name}
                </div>
                <div className="ld-cell ld-b-l ld-b-r" style={{ width: '25%' }}>
                  <input
                    style={{ width: '100%' }}
                    type="number"
                    disabled={!isSelected}
                    defaultValue={qty}
                    onKeyDown={preventArrowUpDown}
                    onBlur={(event) => handleBlur(event, 'qty', selectedIndex)}
                  />
                </div>
                <div className="ld-cell" style={{ width: '25%' }}>
                  <input
                    style={{ width: '100%' }}
                    type="number"
                    disabled={!isSelected}
                    defaultValue={cuFt}
                    onKeyDown={preventArrowUpDown}
                    onBlur={(event) => handleBlur(event, 'cu_ft', selectedIndex)}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default HouseholdGoods;

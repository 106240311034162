import React, { Component } from 'react';
import Chart from 'react-apexcharts';

class ChartComponent extends Component {
  render() {
    if (!this.props.options || !this.props.series) return null;

    const options = {
      chart: { id: 'basic-bar' },
      xaxis: { categories: this.props.options },
    };
    const series = [{ data: this.props.series }];
    const chartType = this.props.type || 'line';

    return <Chart options={options} series={series} type={chartType} />;
  }
}

export default ChartComponent;

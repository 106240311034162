import React, { useState, useEffect, useRef, Fragment } from 'react';
import { Link } from 'react-router-dom';
import Api from './api/api';
import './index.scss';

const Terms = () => {
  const url = new URL(window.location.href);
  const jobId = url.searchParams.get('job_id');
  const document = url.searchParams.get('document');

  const agreeLink = `/customer/invoice?job_id=${jobId}&document=${document}`;
  const cancelLink = `/customer/edit-job/?job_id=${jobId}`;

  const containerRef = useRef(null);

  const [termsContainerHeight, setTermsContainerHeight] = useState('100vh');

  const [data, setData] = useState(null);
  const terms = data?.terms ?? [];

  const handleFetch = async () => {
    if (!jobId || !document) return;
    const response = await Api.getData(jobId, document);
    if (response) setData(response.data.terms);
  };

  useEffect(handleFetch, []);

  useEffect(() => {
    function handleResize() {
      const { innerHeight: windowHeight } = window;
      const contentHeight = containerRef?.current?.offsetHeight;

      if (windowHeight && contentHeight && windowHeight < contentHeight) {
        setTermsContainerHeight(windowHeight + 'px');
      }
    }

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="terms" style={{ height: termsContainerHeight }} ref={containerRef}>
      <header>
        <h1>
          TERMS AND CONDITIONS OF MOVING SERVICE
          <br />
          PLEASE READ CAREFULLY, INITIAL AND SIGN
        </h1>
      </header>

      <div className="terms-content">
        {terms.map((item, index) => {
          const title = `${index + 1}. ${item.title}`;

          return (
            <div className="terms-content-item" key={index}>
              <div className="terms-content-item-title">{title}</div>
              <div dangerouslySetInnerHTML={{ __html: item.text }} />
            </div>
          );
        })}
      </div>

      <footer>
        {data && (
          <Fragment>
            <Link className="agree" to={agreeLink}>
              Agree
            </Link>
            <Link className="cancel" to={cancelLink}>
              Cancel
            </Link>
          </Fragment>
        )}
      </footer>
    </div>
  );
};

export default Terms;

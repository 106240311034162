import React, { useState, useMemo } from 'react';
import classNames from 'classnames';
import { generalModalNotesTabsOptions } from 'helpers/constants';
import Modal from 'components/newComponents/Modal';
import { useModal } from 'context/modal';
import AgentNotes from './AgentNotes';
import InternalNotes from './InternalNotes';
import CustomerNotes from './CustomerNotes';

import './style.scss';

const JobNotes = () => {
  const { modalNames, info, close } = useModal();

  const modalInfo = info(modalNames.notes);
  const isVisible = modalInfo.visible;
  const params = modalInfo.params;

  const type = params.type;
  const id = params.jobID;
  const tab = params.tab;

  const [active, setActive] = useState(type ?? 'agent_notes');

  const notes = useMemo(() => {
    switch (active) {
      case 'agent_notes':
        return <AgentNotes type={active} id={id} tab={tab} />;
      case 'internal_notes':
        return <InternalNotes type={active} id={id} tab={tab} />;
      case 'customer_notes':
        return <CustomerNotes type={active} id={id} tab={tab} />;
      default:
        return null;
    }
  }, [active, id, tab]);

  const menu = useMemo(() => {
    return generalModalNotesTabsOptions.map((el) => (
      <div key={el.value} className={classNames('job-notes__tabs__item', { active: active === el.value })}>
        <button type="button" className={classNames('p20 flex-grow-1', { main: active === el.value })} onClick={() => setActive(el.value)}>
          {el.label}
        </button>
      </div>
    ));
  }, [active]);

  const title = `Notes/${generalModalNotesTabsOptions.find((i) => i.value === active).label}`;

  return (
    <Modal style={{ width: 1400 }} header={title} onHide={() => close(modalNames.notes)} show={isVisible}>
      <div className="job-notes">
        <div className="job-notes__tabs">{menu}</div>
        {notes}
      </div>
    </Modal>
  );
};

export default JobNotes;

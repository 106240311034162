import React, { useCallback, useMemo } from 'react';
import { useFormik } from 'formik';
import { useLocation } from 'react-router-dom';
import { matchPath } from 'react-router';
import { routePaths } from 'config/routes';
import { useAdditionalInfoSave, useInfo } from 'hooks/query/useJob';
import Modal from 'components/newComponents/Modal';
import { ModalButton } from 'components/newComponents/Buttons';
import { useModal } from 'context/modal';
import AdditionalForm from './Form';

const AdditionalInfo = () => {
  const { modalNames, close, info } = useModal();
  const { pathname } = useLocation();

  const {
    params: { tab, id },
  } = matchPath(pathname, { path: routePaths.JOBS });

  const {
    data: { additional_info: additionalInfo, moving_info: movingInfo, job_info: jobInfo },
  } = useInfo(id);

  const { mutate: saveInfo } = useAdditionalInfoSave(id, tab);

  const isVisible = info(modalNames.additionalInfo).visible;

  const closeHandler = useCallback(() => close(modalNames.additionalInfo), [close, modalNames.additionalInfo]);

  const saveHandler = useCallback(
    (values) => {
      saveInfo({ ...values, job_id: id }, { onSuccess: closeHandler });
    },
    [closeHandler, id, saveInfo]
  );

  const initialValues = useMemo(() => {
    return {
      // from
      building_type_from: additionalInfo.from.type_of_building,
      building_type_to: additionalInfo.to.type_of_building,
      building_access_from:
        additionalInfo.from.type_of_building === 1 ||
        additionalInfo.from.type_of_building === 2 ||
        additionalInfo.from.type_of_building === 3 ||
        additionalInfo.from.type_of_building === 6,
      building_access_to:
        additionalInfo.to.type_of_building === 1 ||
        additionalInfo.to.type_of_building === 2 ||
        additionalInfo.to.type_of_building === 3 ||
        additionalInfo.to.type_of_building === 6,
      building_access_from_start: additionalInfo.from.building_access_start,
      building_access_from_end: additionalInfo.from.building_access_end,
      elevator_exist_from: !!additionalInfo.from.elevator_exist,
      elevator_reservation_from_start: additionalInfo.from.elevator_reservation_start,
      elevator_reservation_from_end: additionalInfo.from.elevator_reservation_end,
      elevator_from_floor: parseInt(additionalInfo.from.elevator_floor) === 0 ? '' : additionalInfo.from.elevator_floor,
      distance_elevator_apartment_from:
        parseInt(additionalInfo.from.distance_elevator_apartment) === 0 ? '' : parseInt(additionalInfo.from.distance_elevator_apartment),
      distance_elevator_parking_from:
        parseInt(additionalInfo.from.distance_elevator_parking) === 0 ? '' : parseInt(additionalInfo.from.distance_elevator_parking),
      stairs_from: movingInfo.from.estimated_stairs,
      hardwood_protect_from: !!additionalInfo.from.hardwood_protect,
      marble_protect_from: !!additionalInfo.from.marble_protect,
      carpet_protect_from: !!additionalInfo.from.carpet_protect,
      hoisting_from: !!additionalInfo.from.hoisting,
      hoisting_note_from: additionalInfo.from.hoisting_note,
      // to
      building_access_to_start: additionalInfo.to.building_access_start,
      building_access_to_end: additionalInfo.to.building_access_end,
      elevator_exist_to: !!additionalInfo.to.elevator_exist,
      elevator_reservation_to_start: additionalInfo.to.elevator_reservation_start,
      elevator_reservation_to_end: additionalInfo.to.elevator_reservation_end,
      elevator_to_floor: parseInt(additionalInfo.to.elevator_floor) === 0 ? '' : additionalInfo.to.elevator_floor,
      distance_elevator_apartment_to:
        parseInt(additionalInfo.to.distance_elevator_apartment) === 0 ? '' : parseInt(additionalInfo.to.distance_elevator_apartment),
      distance_elevator_parking_to:
        parseInt(additionalInfo.to.distance_elevator_parking) === 0 ? '' : parseInt(additionalInfo.to.distance_elevator_parking),
      stairs_to: movingInfo.to.estimated_stairs,
      hardwood_protect_to: !!additionalInfo.to.hardwood_protect,
      marble_protect_to: !!additionalInfo.to.marble_protect,
      carpet_protect_to: !!additionalInfo.to.carpet_protect,
      hoisting_to: !!additionalInfo.to.hoisting,
      hoisting_note_to: additionalInfo.to.hoisting_note,
      // common
      apt_finished: !!movingInfo.from.apt_finished,
      apt_note: movingInfo.from.apt_note,
      packing: !!movingInfo.from.packing,
      packing_note: movingInfo.from.packing_note,
      fragile: !!movingInfo.from.fragile,
      fragile_note: movingInfo.from.fragile_note,
      wrap: !!movingInfo.from.wrap,
      wrap_note: movingInfo.from.wrap_note,
      bedroom_sets: movingInfo.from.bedroom_sets,
      job_id: jobInfo.id,
    };
  }, [additionalInfo, jobInfo, movingInfo]);

  const { values, handleChange, setFieldValue, handleSubmit } = useFormik({ initialValues: initialValues, onSubmit: saveHandler });

  return (
    <Modal style={{ width: 700 }} header="Edit additional info" show={isVisible} onHide={closeHandler}>
      <form onSubmit={handleSubmit}>
        <AdditionalForm edit={true} values={values} setFieldValue={setFieldValue} handleChange={handleChange} />
        <div className="footer-buttons">
          <ModalButton title="Save" variant="confirm" type="submit" />
        </div>
      </form>
    </Modal>
  );
};

export default AdditionalInfo;

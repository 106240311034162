import React, { Component } from 'react';
import Chart from 'react-apexcharts';

class ColumnComponent extends Component {
  render() {
    let series = [{ data: this.props.series }];
    let options = {
      chart: {
        height: this.props.height,
        type: 'bar',
        events: {
          click: function (chart, w, e) {},
        },
      },
      plotOptions: { bar: { columnWidth: '45%', distributed: true } },
      dataLabels: { enabled: false },
      legend: { show: false },
      xaxis: { categories: this.props.options, labels: { style: { fontSize: '12px' } } },
    };

    return <Chart options={options} series={series} type="bar" />;
  }
}

export default ColumnComponent;

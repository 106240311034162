import * as Yup from 'yup';

export default [
  Yup.object().shape({
    pickup_date: Yup.string().required('Select pickup Date!'),
    company_id: Yup.number().required('Select a company!'),
    hear_about: Yup.number().required('Select hear about!'),
    move_size_id: Yup.number().required('Select move size'),
    qty_trucks: Yup.string().required('Select trucks!'),
    qty_man: Yup.string().required('Select movers!'),
  }),
  Yup.object().shape({
    from_name: Yup.string().required('Enter name!'),
    to_name: Yup.string().required('Enter name!'),
    from_email: Yup.string().email('Email not valid').required('Enter email'),
    to_email: Yup.string().email('Email not valid').required('Enter email'),
    from_home_phone: Yup.string()
      .test('len', 'Invalid phone number', (value) => {
        if (!value) return false;
        return !value.includes('_');
      })
      .required('Home phone required'),
    to_home_phone: Yup.string()
      .test('len', 'Invalid phone number', (value) => {
        if (!value) return false;
        return !value.includes('_');
      })
      .required('Home phone required'),
    // from_city: Yup.string().required('Enter address!'),
    // from_address: Yup.string().required('Enter address!'),
    // to_address: Yup.string().required('Enter address!'),
    // to_city: Yup.string().required('Enter address!'),

    from_zipcode: Yup.string()
      .required('Zip required')
      .test('len', 'Invalid zip length', (value) => {
        if (!value) return false;
        return value.length === 5;
      }),
    to_zipcode: Yup.string()
      .required('Zip required')
      .test('len', 'Invalid zip length', (value) => {
        if (!value) return false;
        return value.length === 5;
      }),
  }),
];

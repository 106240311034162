import React, { useState } from 'react';
import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';
import PayrollJob from './PayrollJob';
import Icon from 'components/Icon';
import { useModal } from 'context/modal';
import { formatFullDate } from 'helpers/dateHelper';
import { shouldShowColumn, shouldAddCommissionToSalary } from './payrollUtils';

const PayrollUser = ({ data, user_id, type, index, createReimbursement }) => {
  const [open, setOpen] = useState(false);

  const { open: openModal, modalNames } = useModal();

  if (data.list.length === 0) return null;

  let user_name = '';
  let LO_count = 0;
  let LD_count = 0;
  let Labor_count = 0;
  let Storage_count = 0;
  let default_rate_per_hour = 0;
  let total_commission = 0;
  let total_tips = 0;
  let total_hourly = 0;
  let total_hours = 0;
  let total_afternoon_hours = 0;
  let total_week_extra_hours = 0;
  let user_total_salary = 0;
  let jobs_count = 0;
  let completed_total = 0;
  let commission_percentage = 0;
  let packing_total = 0;

  data.list.forEach((job) => {
    if (default_rate_per_hour === 0) default_rate_per_hour = job.default_rate_per_hour;
    if (Number(job.job_type) === 1) LO_count++;
    if (Number(job.job_type) === 2) LD_count++;
    if (Number(job.job_type) === 3) Labor_count++;
    if (Number(job.job_type) === 4) Storage_count++;

    // let job_total_salary = (
    //   Number(job.commission) +
    //   Number(job.hours) * Number(job.rate_per_hour) +
    //    Number(job.tips)
    //    ).toFixed(2);

    // let job_total_salary = (
    //   Number(shouldAddCommissionToSalary(type) ? Number(job.commission) : 0) +
    //   Number(job.hours) * Number(job.rate_per_hour) +
    //   Number(job.tips)
    // ).toFixed(2);

    const job_total_salary = (
      Number(job.commission) +
      Number(job.hours) * Number(job.rate_per_hour) +
      Number(shouldAddCommissionToSalary(job.user_type) ? job.tips : 0)
    ).toFixed(2);

    user_total_salary += parseFloat(job_total_salary);
    total_commission += Number(job.commission);
    total_tips += Number(job.tips);
    total_hourly += Number(job.hours) * Number(job.rate_per_hour);
    total_hours += Number(job.hours);
    total_afternoon_hours += Number(job.afternoon_hours);
    total_week_extra_hours += Number(job.week_extra_hours);
    user_name = job.user_name + ' ' + job.user_last_name;
    completed_total += Number(job.job_total);
    commission_percentage = job.commission_percentage;
    packing_total += Number(job.packing_total);

    jobs_count++;
  });

  const handleOpenModal = (event) => {
    event.stopPropagation();
    openModal(modalNames.payrollReimbursement, {
      user_id,
      user_type: type,
      onSubmit: createReimbursement,
    });
  };

  const reimbursementTotal = data.reimbursement.reduce((acc, curr) => acc + Number(curr.amount), 0);

  return (
    <div className="reports-table">
      <div className="reports-table" onClick={() => setOpen(!open)} style={{ cursor: 'pointer' }}>
        <div className={classNames('reports-table-row', { 'reports-select': open })}>
          <div className="reports-table-column reports-table-column--18">{user_name}</div>
          <div className="reports-table-column reports-table-column--18">{jobs_count}</div>
          <div className="reports-table-column reports-table-column--18">{LO_count}</div>
          <div className="reports-table-column reports-table-column--18">{LD_count}</div>
          <div className="reports-table-column reports-table-column--18">{Labor_count}</div>
          <div className="reports-table-column reports-table-column--18">{Storage_count}</div>
          <div className="reports-table-column reports-table-column--18">{total_hours.toFixed(2)}</div>
          <div className="reports-table-column reports-table-column--18">{total_afternoon_hours.toFixed(2)}</div>
          <div className="reports-table-column reports-table-column--18">{total_week_extra_hours.toFixed(2)}</div>
          {shouldShowColumn.total(type) && <div className="reports-table-column reports-table-column--18">${total_hourly.toFixed(2)}</div>}
          <div className="reports-table-column reports-table-column--18">${completed_total.toFixed(2)}</div>
          <div className="reports-table-column reports-table-column--18">${total_tips.toFixed(2)}</div>
          <div className="reports-table-column reports-table-column--18">${(completed_total - total_tips).toFixed(2)}</div>
          {shouldShowColumn.packingTotal(type) && <div className="reports-table-column reports-table-column--18">${packing_total.toFixed(2)}</div>}
          <div className="reports-table-column reports-table-column--18">{commission_percentage}</div>
          <div className="reports-table-column reports-table-column--18">${total_commission.toFixed(2)}</div>
          <div className="reports-table-column reports-table-column--18">
            ${(user_total_salary + reimbursementTotal + default_rate_per_hour * total_week_extra_hours * 0.5).toFixed(2)}
          </div>
          <div className="reports-table-column reports-table-column--18">
            <div className="d-flex align-items-center justify-content-between">
              <span data-tip data-for={`reimbursement-tooltip-${index}`}>
                <Icon icon="manual_tips" size={20} style={{ margin: 0 }} />

                {Boolean(data.reimbursement.length) && (
                  <ReactTooltip arrowColor="transparent" id={`reimbursement-tooltip-${index}`} place="top">
                    {data.reimbursement.map((item) => {
                      return (
                        <p key={item.id} className="d-flex gap-1">
                          <span style={{ width: 80, textAlign: 'start' }}>{formatFullDate(item.created_at)}</span>
                          <span style={{ width: 100 }}>${item.amount}</span>
                          <span style={{ width: 120, textAlign: 'start' }}>{item.note}</span>
                        </p>
                      );
                    })}
                  </ReactTooltip>
                )}
              </span>

              <span>${reimbursementTotal.toFixed(2)}</span>

              <button type="button" onClick={handleOpenModal}>
                <Icon icon="add_new" size={16} style={{ margin: 0 }} />
              </button>
            </div>
          </div>
        </div>
      </div>

      {open && <PayrollJob data={data.list} user_id={user_id} type={type} />}
    </div>
  );
};

export default PayrollUser;

import React from 'react';
import DropdownComponent from 'components/Dropdown';
import Icon from 'components/Icon';

function Dropdown(props) {
  const { options } = props;

  return (
    <DropdownComponent iconName="download" iconTitle="Downloads" divClassname="downloads-menu-position" iconStyle={{ margin: 0 }}>
      {options.map((item, index) => (
        <li key={index}>
          <a tabIndex="-1" href={item.value} className="link align-items-center" title={item.label} target="_blank" rel="noopener noreferrer">
            <Icon icon="doc_export_quote" style={{ marginLeft: 0, marginRight: 5 }} />
            {item.label}
          </a>
        </li>
      ))}
    </DropdownComponent>
  );
}

export default Dropdown;

import { useState, useEffect, useCallback } from 'react';

const usePageBuilder = (jobList, id) => {
  const [currentJobs, setCurrentJobs] = useState([]);

  const findPageIndex = useCallback(() => {
    let pageIndex = 0;

    jobList.every((p, index) => {
      if (p.hasSelectedJobOnCurrentPage) {
        pageIndex = index;
        return false;
      }

      return true;
    });

    return pageIndex;
  }, [jobList]);

  useEffect(() => {
    if (!jobList) return;

    if (id) {
      const index = findPageIndex();

      setCurrentJobs(jobList[index]?.list);
      return;
    }

    setCurrentJobs(jobList[0]?.list);
  }, [id, jobList, findPageIndex]);

  const handlePaginationChange = useCallback(({ selected }) => setCurrentJobs(jobList[selected].list), [jobList]);

  return { currentPage: currentJobs, handlePaginationChange, findPageIndex };
};

export { usePageBuilder };

import React, { useMemo, useState, Fragment, useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { FieldArray } from 'formik';
import { useDefault } from 'hooks/query/useCommon';
import CheckBox from 'components/newComponents/CheckBox';
import Select from 'components/newComponents/Select';
import RadioButton from 'components/newComponents/RadioButton';
import TooltipTitle from 'components/TooltipTitle';
import Icon from 'components/Icon';
import DateSelect from 'components/DateSelect';
import { formatFullDBDate, formatTimeAPMeridiem } from 'helpers/dateHelper';
import { getStorageRateTypeByPeriod, getStorageRateMultiplierByRateType } from 'helpers/storage';
import TimeSelect from 'components/TimeSelect';
import InventoryTable from './InventoryTable';
import { calcTypeOptions } from 'helpers/constants';
import { getStorageTypeLable } from 'helpers/storage';
import { prepareDataToSubmit } from 'pages/modal/Inventory/utils';
import { useModal } from 'context/modal';
import { useInventorySave } from 'hooks/query/useJob';
import queryKeys from 'config/queryKeys';
import moment from 'moment';

const JobUpdateToStorageForm = (props) => {
  const { values, requiredPickup, handleChange, tab, setFieldValue, companyId, jobId, isValidHandler } = props;

  const queryClient = useQueryClient();

  const { data: defaultItems } = useDefault();

  const { open, close, modalNames } = useModal();

  const { mutate: saveInventory } = useInventorySave(jobId, tab);

  const storageFees = useMemo(() => {
    if (!defaultItems || !values.storage_id) return {};

    const { admin_fee, delivery_fee, hold_fee, insurance_fee, redelivery_fee } = defaultItems.storages[companyId].find(
      (storage) => storage.id === values.storage_id
    );

    return {
      admin_fee: Number(admin_fee).toFixed(0),
      delivery_fee: Number(delivery_fee).toFixed(0),
      hold_fee: Number(hold_fee).toFixed(0),
      insurance_fee: Number(insurance_fee).toFixed(0),
      redelivery_fee: Number(redelivery_fee).toFixed(0),
    };
  }, [defaultItems, companyId, values.storage_id]);

  const storages = useMemo(() => {
    if (!defaultItems) return [];
    return defaultItems.storages[companyId].map((storage) => ({
      value: storage.id,
      label: `${storage.name} (${getStorageTypeLable(storage.type)})`,
    }));
  }, [defaultItems, companyId]);

  const [unitSizes, setUnitSizes] = useState([]);

  const onChangeRateType = useCallback(
    (value, deliveryDate) => {
      const ratePerCf = defaultItems.company.find((company) => company.id === companyId)?.rate_per_cf;

      setFieldValue('storage_rate_type', value);
      setFieldValue('storage_rate', ratePerCf);

      if (values.calc_type === 'per_cf') {
        setFieldValue('storage_rate', Number(ratePerCf).toFixed(2));

        if (values.job_inventory_total) {
          setFieldValue('total_price', (Number(ratePerCf) * Number(values.job_inventory_total)).toFixed(2));
        }
      }

      if (values.calc_type === 'per_unit' && values.unit_sizes?.length && values.unit_sizes[0]) {
        const firstUnitSizeId = values.unit_sizes[0];

        const firstUnitSize = unitSizes.find((size) => size.id === firstUnitSizeId);
        if (firstUnitSize) {
          const storageRate = firstUnitSize[value];
          const multiplier = getStorageRateMultiplierByRateType(value, formatFullDBDate(deliveryDate), requiredPickup);

          setFieldValue('storage_rate', Number(storageRate).toFixed(2));
          setFieldValue('total_price', (Number(storageRate) * multiplier).toFixed(2));
        }
      }
    },
    [setFieldValue, defaultItems, values, unitSizes, requiredPickup, companyId]
  );

  const onChangeStorageFee = useCallback(
    (fee) => {
      let result = {};
      const [key, value] = Object.entries(fee)[0];

      if (values.storage_fees[key]) {
        result = { ...values.storage_fees };
        delete result[key];
      } else {
        result = { ...values.storage_fees, [key]: value };
      }

      setFieldValue('storage_fees', result);
    },
    [setFieldValue, values.storage_fees]
  );

  const onChangeDeliveryDate = ({ name, date }) => {
    setFieldValue(name, formatFullDBDate(date));
    const rateType = getStorageRateTypeByPeriod(formatFullDBDate(date), requiredPickup);

    onChangeRateType(rateType, formatFullDBDate(date));
  };

  const onChangeStorage = ({ value }) => {
    setFieldValue('storage_id', value);

    if (!defaultItems) return;

    const units = defaultItems.storage_unit_sizes
      .filter((size) => size.storage_id === value)
      .map((item) => ({
        ...item,
        value: item.id,
        label: item.name,
      }));

    setUnitSizes(units);

    const suitableStorage = units.find((unit) => Number(unit.cf_capacity) >= Number(values.job_inventory_total));

    if (suitableStorage) {
      setFieldValue('unit_sizes', [suitableStorage.value]);
    }
  };

  const onChangeCalcType = ({ value }) => {
    setFieldValue('calc_type', value);

    const ratePerCf = defaultItems.company.find((company) => company.id === companyId)?.rate_per_cf;

    if (value === 'per_cf') {
      setFieldValue('storage_rate', Number(ratePerCf).toFixed(2));

      if (values.job_inventory_total) {
        setFieldValue('total_price', (Number(ratePerCf) * Number(values.job_inventory_total)).toFixed(2));
      }
    }

    if (value === 'per_unit' && values.unit_sizes?.length && values.unit_sizes[0]) {
      const firstUnitSizeId = values.unit_sizes[0];

      const firstUnitSize = unitSizes.find((size) => size.id === firstUnitSizeId);

      if (firstUnitSize) {
        const storageRate = firstUnitSize[values.storage_rate_type];
        const multiplier = getStorageRateMultiplierByRateType(values.storage_rate_type, values.delivery_date, requiredPickup);

        setFieldValue('storage_rate', Number(storageRate).toFixed(2));
        setFieldValue('total_price', (Number(storageRate) * multiplier).toFixed(2));
      }
    }
  };

  const handleSaveInventory = (invValues) => {
    const prepared = prepareDataToSubmit(invValues);

    saveInventory(
      {
        job_id: jobId,
        job_inventory: { estimated: prepared, final: prepared },
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([queryKeys.job, jobId?.toString(), 'inventory']);
          close(modalNames.inventory);
        },
      }
    );
  };

  const minDeliveryDate = moment(moment(requiredPickup)).add(1, 'days');

  return (
    <div className="new-table modal">
      <div className="new-table--row">
        <div className="new-table--row--item select position-relative">
          <Select
            placeholder="Storage"
            name="storage_id"
            value={values['storage_id']}
            error={isValidHandler('storage_id')}
            options={storages}
            onChange={onChangeStorage}
          />
        </div>
      </div>

      <div className="new-table--row">
        <div className="new-table--row--item">
          <DateSelect
            name="delivery_date"
            selected={values['delivery_date']}
            placeholder="Delivery Date"
            onChange={onChangeDeliveryDate}
            error={isValidHandler('delivery_date')}
            minDate={new Date(minDeliveryDate)}
          />
        </div>
        <div className="new-table--row--item">
          <TimeSelect
            name="delivery_time"
            value={values['delivery_time']}
            placeholder="Delivery Time"
            error={isValidHandler('delivery_time')}
            onChange={({ name, date }) => setFieldValue(name, formatTimeAPMeridiem(date))}
          />
        </div>
      </div>

      <div className="new-table--row">
        <div className="new-table--row--item">Calculation Type</div>
        <div className="new-table--row--item select">
          <Select
            options={calcTypeOptions}
            value={values['calc_type']}
            // error={isValidHandler('calc_type')}
            name="calc_type"
            onChange={onChangeCalcType}
          />
        </div>
      </div>

      {values.storage_id && (
        <FieldArray
          name="unit_sizes"
          render={({ remove, push }) => (
            <Fragment>
              <div className="new-table--row">
                <div className="new-table--row--item light">Unit Size</div>
              </div>

              {values?.unit_sizes?.map((item, index) => (
                <div className="new-table--row" key={index}>
                  <div className="new-table--row--item select">
                    <Select
                      placeholder="Unit Size"
                      name={`unit_sizes[${index}]`}
                      value={item}
                      options={unitSizes}
                      onChange={({ value }) => {
                        setFieldValue(`unit_sizes[${index}]`, value);
                      }}
                    />

                    <button
                      type="button"
                      className="remove-btn"
                      onClick={() => {
                        remove(index);
                      }}
                    >
                      <Icon icon="remove" size={18} />
                    </button>
                  </div>
                </div>
              ))}

              <div className="new-table--row">
                <div className="new-table--row--item position-relative">
                  <button type="button" className="add-btn" onClick={() => push(undefined)}>
                    <Icon icon={'add'} size={18} />
                    <span>Add Size</span>
                  </button>

                  {isValidHandler('unit_sizes') && (
                    <span style={{ position: 'absolute', bottom: 0, left: 20 }} className="error">
                      {isValidHandler('unit_sizes')}
                    </span>
                  )}
                </div>
              </div>
            </Fragment>
          )}
        />
      )}

      {values.unit_sizes && values.unit_sizes.length > 0 && typeof values.unit_sizes[0] === 'number' && (
        <div className="new-table--row">
          <div className="new-table--row--item justify-content-between flex-grow-2 float-row position-relative">
            <TooltipTitle>
              <RadioButton
                value="daily_rate"
                title="Daily Rate"
                name="storage_rate_type"
                current={values['storage_rate_type']}
                onChange={(e) => onChangeRateType('daily_rate', values.delivery_date)}
              />
            </TooltipTitle>

            <TooltipTitle>
              <RadioButton
                value="monthly_rate"
                title="Monthly Rate"
                name="storage_rate_type"
                current={values['storage_rate_type']}
                onChange={(e) => onChangeRateType('monthly_rate', values.delivery_date)}
              />
            </TooltipTitle>

            <TooltipTitle>
              <RadioButton
                value="annual_rate"
                title="Annual Rate"
                name="storage_rate_type"
                current={values['storage_rate_type']}
                onChange={(e) => onChangeRateType('annual_rate', values.delivery_date)}
              />
            </TooltipTitle>

            {isValidHandler('storage_rate_type') && (
              <span style={{ position: 'absolute', bottom: 0, left: 20 }} className="error">
                {isValidHandler('storage_rate_type')}
              </span>
            )}
          </div>
        </div>
      )}

      {values.storage_id && (
        <div className="new-table--row">
          <div className="new-table--row--item">
            <CheckBox
              right={false}
              label={`Delivery Fee (${storageFees.delivery_fee})`}
              name="storage_fees"
              className="centered-checkbox justify-content-start"
              checked={values['storage_fees'].delivery_fee === storageFees.delivery_fee}
              onChange={(e) => onChangeStorageFee({ delivery_fee: storageFees.delivery_fee })}
            />
            <CheckBox
              right={false}
              label={`Redelivery Fee (${storageFees.redelivery_fee})`}
              name="storage_fees"
              className="justify-content-start"
              checked={values['storage_fees'].redelivery_fee === storageFees.redelivery_fee}
              onChange={(e) => onChangeStorageFee({ redelivery_fee: storageFees.redelivery_fee })}
            />
            <CheckBox
              right={false}
              label={`Admin Fee (${storageFees.admin_fee})`}
              name="storage_fees"
              className="justify-content-start"
              checked={values['storage_fees'].admin_fee === storageFees.admin_fee}
              onChange={(e) => onChangeStorageFee({ admin_fee: storageFees.admin_fee })}
            />
            <CheckBox
              right={false}
              label={`Hold Fee (${storageFees.hold_fee})`}
              name="storage_fees"
              className="justify-content-start"
              checked={values['storage_fees'].hold_fee === storageFees.hold_fee}
              onChange={(e) => onChangeStorageFee({ hold_fee: storageFees.hold_fee })}
            />
            <CheckBox
              right={false}
              label={`Insurance Fee (${storageFees.insurance_fee})`}
              name="storage_fees"
              className="justify-content-start"
              checked={values['storage_fees'].insurance_fee === storageFees.insurance_fee}
              onChange={(e) => onChangeStorageFee({ insurance_fee: storageFees.insurance_fee })}
            />
          </div>
        </div>
      )}

      <div className="new-table--row">
        <div className="new-table--row--item">
          <button
            type="button"
            className="main flex-grow-1"
            onClick={() => {
              open(modalNames.inventory, {
                type: 'estimated',
                jobID: jobId,
                jobInventory: values.job_inventory.estimated,
                companyID: companyId,
                onSubmit: handleSaveInventory,
              });
            }}
          >
            Add inventory
          </button>
        </div>
      </div>

      {values.job_inventory?.estimated.length > 0 && (
        <Fragment>
          <div className="new-table--row">
            <div className="new-table--row--item light">Name</div>
            <div className="new-table--row--item light">Quantity</div>
            <div className="new-table--row--item light">CF</div>
          </div>
          {values.job_inventory.estimated.map(({ name, qty, volume }, index) => (
            <div className="new-table--row" key={index}>
              <div className="new-table--row--item">{name}</div>
              <div className="new-table--row--item">{qty}</div>
              <div className="new-table--row--item">{qty * volume} CF</div>
            </div>
          ))}
        </Fragment>
      )}

      <InventoryTable values={values} handleChange={handleChange} setFieldValue={setFieldValue} />
    </div>
  );
};

export default JobUpdateToStorageForm;

import { useDefault } from 'hooks/query/useCommon';
import { useGetCarrierDrivers } from 'hooks/query/useJob';

export const useFetchData = (params = {}) => {
  const { carrier_id } = params;

  const {
    data: { carriers = [] },
  } = useDefault();

  const { data: carrierDrivers = [] } = useGetCarrierDrivers(carrier_id, { enabled: Boolean(carrier_id) });

  return {
    carriers,
    carrierDrivers,
  };
};

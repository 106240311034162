import React, { useState, useEffect, useCallback, useRef, Fragment } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { useParams } from 'react-router-dom';
import useOutsideClick from 'hooks/useOutsideClick';
import routes from 'config/routes';
import Api from './api/api';
import history, { updateRouteParams } from 'utils/history';
import logo from './logo.png';
import './styles.scss';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const InsuranceApproving = React.memo(() => {
  const { job_id, type } = useParams();

  const inputRef = useRef(null);

  const [data, setData] = useState(null);
  const [file, setFile] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [rejectCertificate, setRejectCertificate] = useState(false);

  const hasMultiCertificates = data && data.disabled_wc === 0;
  const isFullyApproved = hasMultiCertificates ? Boolean(data.liability.approved) && Boolean(data.wc.approved) : Boolean(data?.liability.approved);

  const ref = useRef();
  useOutsideClick(ref, () => {
    if (rejectCertificate) {
      setRejectCertificate(false);
    }
  });

  function onDocumentLoadSuccess({ numPages: nextNumPages }) {
    setNumPages(nextNumPages);
  }

  const options = {
    cMapUrl: 'cmaps/',
    cMapPacked: true,
    standardFontDataUrl: 'standard_fonts/',
  };

  const handleFetch = useCallback(async () => {
    if (!job_id || !type) return;

    const response = await Api.getData(job_id, type);

    if (response) {
      setData(response.data);
      setFile(response.data?.liability);
    }
  }, [job_id, type]);

  const handleApprove = useCallback(async () => {
    if (!file) return;

    const isSuccess = await Api.approve(job_id, type, file.type);

    if (isSuccess) {
      const file_name = file.type ? 'liability' : 'wc';
      const FileSaver = require('file-saver');
      FileSaver.saveAs(file?.download, file_name + 'certificate.pdf');
    }

    if (isSuccess && data.wc && file.type !== data.wc.type) {
      setData({
        ...data,
        liability: {
          ...data.liability,
          approved: true,
          rejected: false,
        },
      });
      setFile(data.wc);
    } else if (isSuccess && data.wc && file.type === data.wc.type && data.liability.rejected) {
      history.push({
        pathname: `${routes.COI_CERT_APPROVING_REJECTED}/${job_id}`,
      });
    } else {
      let elevator_approved = data.elevator_reservation ? data.elevator_reserved : true;

      if (elevator_approved) {
        history.push({
          pathname: routes.COI_CERT_APPROVING_SUCCESS,
        });
      } else {
        history.push({
          pathname: updateRouteParams(routes.ELEVATOR_RESERVATION, { job_id: job_id, type: type }),
        });
      }
    }
  }, [job_id, type, data, file]);

  const handleNextCertificate = useCallback(() => {
    if (file?.type === 1) {
      setFile(data.wc);
    } else {
      setFile(data.liability);
    }
  }, [data, file]);

  const handleRejectButtonClick = useCallback(() => {
    if (!file) return;
    setRejectCertificate(true);
  }, [file]);

  const handleRejectSend = useCallback(async () => {
    if (!inputRef?.current?.value) return;

    const isSuccess = await Api.reject(job_id, type, file.type, inputRef.current.value);

    if (isSuccess) {
      inputRef.current.value = '';
      setRejectCertificate(false);
    }

    if (isSuccess && data.wc && file.type !== data.wc.type) {
      setData({
        ...data,
        liability: {
          ...data.liability,
          approved: false,
          rejected: true,
        },
      });
      setFile(data.wc);
    } else {
      history.push({
        pathname: `${routes.COI_CERT_APPROVING_REJECTED}/${job_id}`,
      });
    }
  }, [job_id, type, data, file]);

  useEffect(handleFetch, []);

  let provider_title = file?.type === 1 ? 'Liability' : 'WC';

  return !data ? null : (
    <div className="coi-certificate">
      <header className="coi-certificate__header">
        <div className="coi-certificate__title">Certificate of {provider_title} Insurance</div>

        <a href="https://www.cloudcoi.com" className="coi-certificate__logo">
          <span>Securely sent by</span>
          <img src={logo} alt="cloud coi" />
        </a>
      </header>

      <div className="coi-certificate__pdf-container">
        {file && (
          <Document
            file={{
              url: `${process.env.REACT_APP_API_URL}/api/showCertificatePdf?path=` + file.certificate,
              httpHeaders: { 'X-CustomHeader': '40359820958024350238508234' },
              withCredentials: false,
            }}
            onLoadSuccess={onDocumentLoadSuccess}
            options={options}
          >
            {Array.from(new Array(numPages), (el, index) => (
              <Page key={`page_${index + 1}`} pageNumber={index + 1} />
            ))}
          </Document>
        )}
      </div>

      <footer ref={ref} className="coi-certificate__footer">
        {isFullyApproved ? (
          <Fragment>
            <a href={file?.download} download className="coi-certificate__download-link">
              Download
            </a>

            {hasMultiCertificates && (
              <button type="button" onClick={handleNextCertificate} className="coi-certificate__reject-btn">
                Next certificate
              </button>
            )}
          </Fragment>
        ) : (
          <Fragment>
            {rejectCertificate ? (
              <Fragment>
                <input ref={inputRef} placeholder="Enter reject message" className="coi-certificate__input" />

                <button type="button" onClick={handleRejectSend} className="coi-certificate__send-btn">
                  Send
                </button>
              </Fragment>
            ) : (
              <Fragment>
                <button type="button" onClick={handleApprove} className="coi-certificate__accept-btn">
                  Approve and download <br /> {provider_title} certificate
                </button>

                <button type="button" onClick={handleRejectButtonClick} className="coi-certificate__reject-btn">
                  Reject
                </button>
              </Fragment>
            )}
          </Fragment>
        )}
      </footer>
    </div>
  );
});

export default InsuranceApproving;

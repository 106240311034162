import moment from 'moment-timezone';

function checkIsLastMomentJob(data) {
  if (!data) return false;

  const createdDay = moment.unix(data.created_date).dayOfYear();
  const requiredPickupDay = moment.unix(data.required_pickup).dayOfYear();
  const pickupOnJobCreationDay = createdDay === requiredPickupDay;
  const pickupOnNextDayOfJobCreation = createdDay + 1 === requiredPickupDay;

  if (Number(data.deny_self_booking) === 0 && (pickupOnJobCreationDay || pickupOnNextDayOfJobCreation)) {
    return true;
  }
  return false;
}

function checkIsBookingExpired(bookingExpiration) {
  if (bookingExpiration && moment.tz('America/New_York').unix() > Number(bookingExpiration)) {
    return true;
  }
  return false;
}

function checkIfNeedAdvanceNotice(data) {
  if (!data) return false;

  const requiredPickupDay = moment.unix(data.required_pickup).dayOfYear();
  const isBookingEnds = requiredPickupDay - moment().dayOfYear() <= Number(data.book_expiration);
  const isPickupDateExpired = requiredPickupDay - moment().dayOfYear() >= 0;

  if (isBookingEnds && !isPickupDateExpired) {
    return true;
  }
  return false;
}

export { checkIsLastMomentJob, checkIsBookingExpired, checkIfNeedAdvanceNotice };

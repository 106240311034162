import React from 'react';

const BuildingDetail = ({ data }) => {
  let items = [];
  for (let i = 0; i < 3; i++) {
    let title = '';

    switch (i) {
      case 0:
        title = 'All';
        break;
      case 1:
        title = 'From';
        break;
      case 2:
        title = 'To';
        break;
      default:
        return title;
    }

    let info = data?.map((data, index) => (
      <div className="reports-table-column reports-table-column--20" key={index}>
        {data.data[i].min + '/ ' + data?.data[i]?.avg + '/ ' + data.data[i].max}
      </div>
    ));

    items.push(
      <div className="reports-table-row" key={Date.now() * Math.random()}>
        <div className="reports-table-column reports-table-column--20" />
        <div className="reports-table-column reports-table-column--20" />
        <div className="reports-table-column reports-table-column--20" />
        <div className="reports-table-column reports-table-column--20 reports-table-column--title">{title}</div>
        {info}
      </div>
    );
  }

  return (
    <div className="reports-table">
      <div className="reports-table-row">
        <div className="reports-table-column reports-table-column--20" />
        <div className="reports-table-column reports-table-column--20" />
        <div className="reports-table-column reports-table-column--20" />
        <div className="reports-table-column reports-table-column--20 reports-table-column--title" />
        <div className="reports-table-column reports-table-column--20 reports-table-column--title">Min/Avg/Max</div>
        <div className="reports-table-column reports-table-column--20 reports-table-column--title">Min/Avg/Max</div>
        <div className="reports-table-column reports-table-column--20 reports-table-column--title">Min/Avg/Max</div>
        <div className="reports-table-column reports-table-column--20 reports-table-column--title">Min/Avg/Max</div>
        <div className="reports-table-column reports-table-column--20 reports-table-column--title">Min/Avg/Max</div>
        <div className="reports-table-column reports-table-column--20 reports-table-column--title">Min/Avg/Max</div>
        <div className="reports-table-column reports-table-column--20 reports-table-column--title">Min/Avg/Max</div>
        <div className="reports-table-column reports-table-column--20 reports-table-column--title">Min/Avg/Max</div>
        <div className="reports-table-column reports-table-column--20 reports-table-column--title">Min/Avg/Max</div>
        <div className="reports-table-column reports-table-column--20 reports-table-column--title">Min/Avg/Max</div>
        <div className="reports-table-column reports-table-column--20 reports-table-column--title">Min/Avg/Max</div>
        <div className="reports-table-column reports-table-column--20 reports-table-column--title">Min/Avg/Max</div>
        <div className="reports-table-column reports-table-column--20 reports-table-column--title">Min/Avg/Max</div>
        <div className="reports-table-column reports-table-column--20 reports-table-column--title">Min/Avg/Max</div>
        <div className="reports-table-column reports-table-column--20 reports-table-column--title">Min/Avg/Max</div>
        <div className="reports-table-column reports-table-column--20 reports-table-column--title">Min/Avg/Max</div>
      </div>
      {items}
    </div>
  );
};

export default BuildingDetail;

import React, { memo } from 'react';
import Icon from 'components/Icon';
import { statusNumberToName } from 'helpers/statuses';

const Item = memo(({ filter, checked, onChange }) => {
  return (
    <label className="job-filters--item">
      <input className="checkbox" type="checkbox" checked={checked} onChange={onChange} />
      <span className="name">{filter[1]}</span>
      <Icon size={20} className="icon" icon={statusNumberToName([filter[0]])} />
    </label>
  );
});

export default Item;

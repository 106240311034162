import React, { memo, useMemo } from 'react';
import { useTabInfo } from 'hooks/query/useJob';

const CustomerNotes = memo(({ id, tab }) => {
  const { data } = useTabInfo(id, tab);

  const note = useMemo(() => {
    if (!data) return '';

    return data?.moving_info?.lead_note?.note ?? '';
  }, [data]);

  return (
    <div className="new-table modal">
      <div className="new-table--row">
        <div className="new-table--row--item no-side-shadows" style={{ minHeight: 60 }}>
          <b>{note}</b>
        </div>
      </div>
    </div>
  );
});

export default CustomerNotes;

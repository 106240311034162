import React from 'react';
import TimeSelect from 'components/TimeSelect';
import DateSelect from 'components/DateSelect';
import { formatTimeAPMeridiem } from 'helpers/dateHelper';
import { useForm } from '../../../hooks/useForm';

function DeliveryDate({ companies, unitSizes }) {
  const { values, actions } = useForm();

  return (
    <div className="new-table--row">
      <div className="new-table--row--item">
        <DateSelect
          name="delivery_date"
          selected={values['delivery_date']}
          placeholder="Delivery Date"
          onChange={(value) => actions.handleChangeDeliveryDate(value, companies, unitSizes)}
          error={actions.isValidHandler('delivery_date')}
          minDate={new Date()}
        />
      </div>

      <div className="new-table--row--item">
        <TimeSelect
          name="delivery_time"
          value={values['delivery_time']}
          error={actions.isValidHandler('delivery_time')}
          placeholder="Delivery Time"
          onChange={({ name, date }) => actions.setFieldValue(name, formatTimeAPMeridiem(date))}
        />
      </div>
    </div>
  );
}

export default DeliveryDate;

import { useFormik } from 'formik';
import { extendMoment } from 'moment-range';
import Moment from 'moment';

const moment = extendMoment(Moment);
const today = moment();

const useCalendarState = () => {
  const { values, setFieldValue } = useFormik({
    initialValues: {
      today: today,
      year: today.year(),
      selectedDay: today,
      selectedRange: [today, today],
      range: false,
      custom_dates: [],
      multiDates: [],
      multiSelect: false,
      rangeSelect: false,
    },
    enableReinitialize: true,
  });

  const pickSingleDate = (date) => setFieldValue('selectedDay', date);

  const pickMultiDates = (date) => {
    const prepared = date.format('YYYY-MM-DD');

    const alreadySelected = values.multiDates.includes(prepared);

    if (alreadySelected) {
      setFieldValue(
        'multiDates',
        values.multiDates.filter((i) => i !== prepared)
      );
      return;
    }

    setFieldValue('multiDates', [...values.multiDates, prepared]);
  };

  const pickDate = (date) => {
    if (values.multiSelect) {
      pickMultiDates(date);
      return;
    }

    pickSingleDate(date);
  };

  const pickRange = (start, end) => {
    const multiDates = [];
    const range = moment.range(start, end);

    for (let day of range.by('day')) {
      multiDates.push(day.format('YYYY-MM-DD'));
    }

    setFieldValue('multiDates', multiDates);
    setFieldValue('selectedRange', [start, end]);
  };

  const onPrevYear = () => setFieldValue('year', values.year - 1);

  const onNextYear = () => setFieldValue('year', values.year + 1);

  const goToToday = () => {
    setFieldValue('selectedDay', today);
    setFieldValue('selectedRange', [today, today]);
    setFieldValue('year', today.year());
  };

  const onChangeMultiSelect = () => {
    setFieldValue('multiSelect', !values.multiSelect);
    setFieldValue('rangeSelect', false);
    setFieldValue('multiDates', []);
    setFieldValue('selectedRange', [today, today]);
  };

  const onChangeRangeSelect = () => {
    setFieldValue('rangeSelect', !values.rangeSelect);
    setFieldValue('multiSelect', false);
    setFieldValue('multiDates', []);
    setFieldValue('selectedRange', [today, today]);
  };

  const resetMulti = () => {
    setFieldValue('rangeSelect', false);
    setFieldValue('multiSelect', false);
    setFieldValue('multiDates', []);
    setFieldValue('selectedRange', [today, today]);
  };

  const setUnitAvailability = (dates) => {
    setFieldValue('custom_dates', dates);
  };

  return {
    calendarState: values,
    actions: {
      pickDate,
      pickRange,
      onPrevYear,
      onNextYear,
      goToToday,
      onChangeMultiSelect,
      onChangeRangeSelect,
      setUnitAvailability,
      resetMulti,
    },
  };
};

export { useCalendarState };

import React, { memo } from 'react';

const ChargesItem = memo(({ item, handleChange }) => {
  return (
    <div className="new-table--row">
      <div className="new-table--row--item">
        <label className="start-value">
          <input type="checkbox" name="checked" checked={item.checked} onChange={() => handleChange(item.id)} />
          <b>{item.name}</b>
        </label>
      </div>
      <div className="new-table--row--item ">
        <b>{item.amount}</b>
      </div>
    </div>
  );
});

export default ChargesItem;

import { useQuery, useMutation } from 'react-query';
import { useApi } from 'context/api';
import { stepAlert } from 'components/Alerts';
import requestKeys from 'config/queryKeys';

export const useLostJobs = ({ refuseReasonsMutate }) => {
  const { jobs } = useApi();

  const onSuccess = async (res) => {
    if (!res.length) return;

    const preparedData = res.map((job) => ({
      title: `${job.job_title} is lost`,
      text: 'Please enter refuse reason',
      input: 'text',
    }));

    const alertData = await stepAlert(preparedData, { confirmButtonText: 'Next &rarr;', showCancelButton: true });

    if (alertData?.value) {
      refuseReasonsMutate(alertData?.value);
    }
  };

  return useQuery(requestKeys.lostJobs, jobs.lost, {
    select: (res) => res.data.lost_jobs,
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess,
  });
};

export const useRefuseReasons = () => {
  const { jobs } = useApi();

  return useMutation(jobs.refuseReasons);
};

export const useJobList = (params) => {
  const { jobs } = useApi();

  return useQuery([requestKeys.jobPage, params], () => jobs.list(params), {
    select: (res) => res.data,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    retry: false,
  });
};

import { useMutation, useQueryClient } from 'react-query';
import { useApi } from 'context/api';
import queryKeys from 'config/queryKeys';

export const useSendSample = () => {
  const { insurance } = useApi();

  return useMutation(insurance.sendSample);
};

export const useSave = (id, tab) => {
  const { insurance } = useApi();
  const queryClient = useQueryClient();

  return useMutation(insurance.save, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.job, id, tab]);
    },
  });
};

export const useUpdate = (id, tab) => {
  const { insurance } = useApi();
  const queryClient = useQueryClient();

  return useMutation(insurance.update, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.job, id, tab]);
    },
  });
};

export const useResendReply = () => {
  const { insurance } = useApi();
  return useMutation(insurance.resendReply);
};

const inboxOptions = [
  { buttonClassName: 'link', clickValue: 'inbox_email', buttonText: 'Inbox Mails' },
  { buttonClassName: 'link', clickValue: 'reminders_replied', buttonText: 'Reminders Replied' },
];

const outboxOptions = [
  { buttonClassName: 'link', clickValue: 'outbox_email', buttonText: 'Outbox Mails' },
  { buttonClassName: 'link', clickValue: 'reminders_sent', buttonText: 'Reminders Sent' },
];

const allOption = { buttonClassName: 'link', clickValue: 'reminders_replied', buttonText: 'Reminders Replied' };

const callOptions = [
  { buttonClassName: 'link', clickValue: 'all', buttonText: 'All' },
  { buttonClassName: 'link', clickValue: 'my', buttonText: 'All My' },
  { buttonClassName: 'link', clickValue: 'all_my_calls', buttonText: 'All My/Missed' },
  { buttonClassName: 'link', clickValue: 'my_missed', buttonText: 'My Missed' },
  { buttonClassName: 'link', clickValue: 'all_missed', buttonText: 'All Missed' },
  { buttonClassName: 'link', clickValue: 'my_general', buttonText: 'My General' },
  { buttonClassName: 'link', clickValue: 'direct_comm', buttonText: 'My Direct' },
  { buttonClassName: 'link', clickValue: 'general_comm', buttonText: 'General' },
  { buttonClassName: 'link', clickValue: 'my_voicemail', buttonText: 'My VoiceMail' },
];

const callOptionsManager = [...callOptions, { buttonClassName: 'link', clickValue: 'voicemail', buttonText: 'All VoiceMail' }];

export { allOption, outboxOptions, inboxOptions, callOptions, callOptionsManager };

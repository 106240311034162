import axios from 'axios';
import showError from 'utils/errorHandler';
import showSuccess from 'utils/successHandler';

class Api {
  constructor() {
    this.serverApi = process.env.REACT_APP_API_URL;
    this.client = axios.create({ baseURL: this.serverApi });
  }

  getUnitAvailability = (data) => {
    return this.client
      .post('/api/crews/getUnitAvailability', data)
      .catch((error) => showError(error));
  };

  getUnitData = (data) => {
    return this.client
      .post('/api/crews/getCrewUnitAvailabilityByDate', data)
      .catch((error) => showError(error));
  };

  save = (data) => {
    return this.client
      .post('/api/crews/saveUnitAvailability', { data })
      .then(() => showSuccess({ message: 'Success' }))
      .catch((error) => showError(error));
  };
}

export default new Api();

import React, { PureComponent } from 'react';
import { GoogleMap, Marker } from '@react-google-maps/api';
import Geocode from 'react-geocode';

const startPoint = {
  lat: 25.761681,
  lng: -80.191788,
};

class SinglePoint extends PureComponent {
  constructor(props) {
    super(props);
    Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAP_API_KEY);
    Geocode.enableDebug();
    this.state = {
      marker: props.marker || startPoint,
      isMarkLoaded: false,
      isTitleVisible: false,
    };
  }

  componentDidMount() {
    Geocode.fromAddress(this.state.marker).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;
        this.setState({
          marker: { lat: lat, lng: lng },
          isMarkLoaded: true,
        });
      },
      (error) => {
        console.error(error);
      }
    );
  }

  click = () => this.setState((prevState) => ({ isTitleVisible: !prevState.isTitleVisible }));

  render() {
    const { marker, isTitleVisible, isMarkLoaded } = this.state;
    return (
      <div className={this.props.fullWidth ? 'col-12' : 'col-6'}>
        <div className="small-map" style={{ minHeight: this.props.mapHeight || 200, height: 250 }}>
          {this.props.title && <h4 className="subHeader">{this.props.title}</h4>}
          {isMarkLoaded ? (
            <GoogleMap
              mapContainerStyle={{ height: this.props.mapHeight || 200 }}
              center={marker || startPoint}
              zoom={this.props.zoom || 15}
              mapTypeId="satellite"
            >
              {/* Child components, such as markers, info windows, etc. */}
              <Marker position={this.state.marker} label={isTitleVisible ? marker : null} onClick={this.click} />
            </GoogleMap>
          ) : (
            <div className="preloader" />
          )}
        </div>
      </div>
    );
  }
}

export default SinglePoint;

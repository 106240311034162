import React, { Component } from 'react';
import Icon from 'components/Icon';

class CategoryItem extends Component {
  constructor() {
    super();
    this.state = {
      qty: 1,
    };
  }

  changeQty = (event) => {
    this.setState({
      qty: event.target.value,
    });
  };

  render() {
    return (
      <div className="new-table--row">
        <div className="new-table--row--item w70">
          <span>{this.props.name}</span>
        </div>
        <div className="new-table--row--item w10 text-center fw-700">
          <span>{this.props.amount}</span>
        </div>
        <div className="new-table--row--item w10">
          <input type="text" maxLength={3} value={this.state.qty} className="text-center p-0 fw-700" onChange={this.changeQty} />
        </div>
        <div className="new-table--row--item w10 text-center">
          <button type="button" onClick={() => this.props.addItemToJobInventory(this.props.id, this.state.qty)} style={{ height: 20 }}>
            <Icon icon="add" size={20} style={{ marginRight: 0 }} />
          </button>
        </div>
      </div>
    );
  }
}

export default CategoryItem;

import React, { memo, Fragment } from 'react';
import NoteItem from '../NoteItem';

const NotesList = memo(({ list, id, tab }) => {
  return (
    <Fragment>
      <div className="new-table--row bcg-light">
        <div className="new-table--row--item col-1 no-side-shadows justify-content-center">
          <b>Note #</b>
        </div>
        <div className="new-table--row--item no-side-shadows justify-content-center">
          <b>Description</b>
        </div>
        <div className="new-table--row--item col-1 no-side-shadows justify-content-center">
          <b>Date Entered</b>
        </div>
        <div className="new-table--row--item col-2 no-side-shadows justify-content-center">
          <b>Actions</b>
        </div>
      </div>
      {list?.map((item, index) => (
        <NoteItem key={item.id} id={id} tab={tab} note={item} counter={index + 1} />
      ))}
    </Fragment>
  );
});

export default NotesList;

const types = {
  route: 'address',
  locality: 'city',
  postal_code: 'zipcode',
  address_short: 'route',
  street_number: 'street_number',
  administrative_area_level_1: 'state',
  administrative_area_level_2: 'city',
};

export const normalize = (place) => {
  if (place == null) return null;

  const { address_components: addressComponents, geometry, place_id: placeId, formatted_address } = place;

  const result = {};

  addressComponents.forEach((item) => {
    Object.keys(types).forEach((key) => {
      if (item.types.includes(key)) {
        Object.assign(result, {
          [types[key]]: item.short_name,
        });
      }
    });
  });

  return Object.assign(result, {
    location: geometry.location.toJSON(),
    full_address: formatted_address,
    place_id: placeId,
  });
};

import React from 'react';
import { useFormik } from 'formik';
import { useAuth } from 'context/auth';
import { useNotesUpdate } from 'hooks/query/useJob';
import Select from 'components/newComponents/Select';
import notesOptions from 'helpers/notesOptions';

const NotesCreateForm = ({ notes, jobInfo, id, tab }) => {
  const { user } = useAuth();

  const { mutate: notesUpdate } = useNotesUpdate(id, tab);

  const { values, handleChange, setFieldValue, handleSubmit } = useFormik({
    initialValues: {
      note: '',
      type: notesOptions[0].value,
    },
    onSubmit: (v) => {
      const preparedNote = {
        author_id: user.id,
        note: v.note,
        type: v.type,
      };
      notesUpdate({ job_id: jobInfo.id, notes: [...notes, preparedNote] });
      setFieldValue('note', '');
      setFieldValue('type', notesOptions[0].value);
    },
  });

  return (
    <form className="new-table--row" onSubmit={handleSubmit}>
      <input
        type="text"
        value={values.note}
        name="note"
        onChange={handleChange}
        className="new-table--row--item flex-grow-1"
        placeholder="New note"
      />
      <div className="new-table--row--item select w20">
        <Select options={notesOptions} value={values.type} onChange={(option) => setFieldValue('type', option.value)} />
      </div>
      <div className="new-table--row--item w15">
        <button type="submit" className="main flex-grow-1">
          Add
        </button>
      </div>
    </form>
  );
};

export default NotesCreateForm;

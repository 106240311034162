import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { useDefault } from 'hooks/query/useCommon';
import getAbbr from 'helpers/Abbr';
import { updateRouteParams } from 'utils/history';
import { routePaths } from 'config/routes';

function Jobs({ jobs, getName }) {
  const { data: defaultData } = useDefault();
  const companiesABBR = defaultData?.companies_abbr;

  return jobs.map((job, key) => {
    const jobLink = updateRouteParams(routePaths.JOBS, { type: 'all', tab: 'general', id: job.id });

    return (
      <div className="reports-table" key={job.id + 'user' + key}>
        <div className="reports-table-row">
          <div className="reports-table-column reports-table-column--5">
            <Link to={jobLink} target="_blank" rel="noopener noreferrer">
              {job.title}
            </Link>
          </div>
          <div className="reports-table-column reports-table-column--5">{getAbbr(job.company_id, companiesABBR)}</div>
          <div className="reports-table-column reports-table-column--5">{moment(job.created_at).format('MM/DD/YY')}</div>
          <div className="reports-table-column reports-table-column--5">{getName(job.agent_id)}</div>
          <div className="reports-table-column reports-table-column--5">{job.mails + ' / ' + job.calls + ' / ' + job.sms}</div>
        </div>
      </div>
    );
  });
}

export default Jobs;

import React, { useCallback } from 'react';
import { useFormik } from 'formik';
import { useModal } from 'context/modal';
import { formatFullDateTime } from 'helpers/dateHelper';
import TimeSelect from 'components/TimeSelect';
import DateSelect from 'components/DateSelect';
import { ModalButton } from 'components/newComponents/Buttons';
import Modal from 'components/newComponents/Modal';

const Reschedule = () => {
  const { modalNames, close, info } = useModal();

  const isVisible = info(modalNames.rescheduleJob).visible;
  const params = info(modalNames.rescheduleJob).params;

  const closeHandler = useCallback(() => close(modalNames.rescheduleJob), [close, modalNames.rescheduleJob]);

  const saveHandler = useCallback(
    (values) => {
      params
        .onReschedule({
          customer: true,
          job_id: params.jobId,
          required_reschedule: formatFullDateTime(values?.date),
        })
        .then(() => closeHandler());
    },
    [closeHandler, params]
  );

  const { values, setFieldValue, handleSubmit } = useFormik({
    initialValues: {
      date: params?.requiredPickup,
    },
    onSubmit: saveHandler,
    enableReinitialize: true,
  });

  const handleChange = useCallback(
    async (e) => {
      const date = formatFullDateTime(e.date);
      setFieldValue(e.name, date);
    },
    [setFieldValue]
  );

  return (
    <Modal style={{ width: 560 }} header="Reschedule Job" show={isVisible} onHide={closeHandler}>
      <form onSubmit={handleSubmit} className="reschedule-modal">
        <div className="new-table reschedule-modal__container">
          <div className="reschedule-modal__title new-table--row--item no-side-shadows">
            {params?.requiredPickup && `YOUR MOVE IS SCHEDULED FOR ${params.requiredPickup}`}
          </div>
          <div className="new-table--row no-padding">
            <div className="new-table--row--item">
              <DateSelect name="date" selected={values['date']} minDate={new Date()} onChange={handleChange} />
            </div>
            <div className="new-table--row--item no-side-shadows">
              <TimeSelect name="date" value={values['date']} onChange={handleChange} />
            </div>
          </div>
        </div>
        <div className="footer-buttons">
          <ModalButton title="Cancel" variant="cancel" onClick={closeHandler} />
          <ModalButton type="submit" title="Apply" variant="confirm" />
        </div>
      </form>
    </Modal>
  );
};

export default Reschedule;

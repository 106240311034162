import React, { Fragment } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { useIsFetching } from 'react-query';
import Preloader from 'components/Preloader';
import queryKeys from 'config/queryKeys';

import routes from 'config/routes';
import Claims from './Claims';
import Lead from './Lead';
import Booking from './Booking';
import Provider from './Provider';
import Buildings from './Building';
import Payroll from './Payroll';
import Communication from './Communication';
import General from './General';

const ReportRoot = () => {
  const { type } = useParams();

  const isFetchinReportFilterdInfo = useIsFetching([queryKeys.reportFilterdInfo]);
  const isFetchinReportUserNames = useIsFetching([queryKeys.reportUserNames]);
  const isFetchinReportDashboard = useIsFetching([queryKeys.reportDashboardInfo]);

  if (!type) return <Redirect to={`${routes.REPORTS}/general`} />;

  const renderReportContainer = () => {
    switch (type) {
      case 'general': {
        return <General />;
      }
      case 'communication': {
        return <Communication />;
      }
      case 'payroll': {
        return <Payroll />;
      }
      case 'claims': {
        return <Claims />;
      }
      case 'lead': {
        return <Lead />;
      }
      case 'booking': {
        return <Booking />;
      }
      case 'provider': {
        return <Provider />;
      }
      case 'building': {
        return <Buildings />;
      }
      default: {
        return null;
      }
    }
  };

  return (
    <Fragment>
      {isFetchinReportFilterdInfo || isFetchinReportUserNames || isFetchinReportDashboard ? <Preloader /> : null}

      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="reports-container">{renderReportContainer()}</div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ReportRoot;

import React, { Fragment, useCallback, useMemo } from 'react';
import {
  useTabInfo,
  useChangeJobStorage,
  useChangeStorageEndDate,
  useGetJobUnits,
  useUpdateJobUnits,
  useDeleteStorageUnitSize,
  useUpdateStorageFee,
  useSendStorageStripeInvoice,
  useCommunicationsList,
  useUpdateStorageRate,
  useUpdateStorageTotalPrice,
} from 'hooks/query/useJob';
import { useEmailSend } from 'hooks/query/useMail';

import { useDefault } from 'hooks/query/useCommon';
import UnitSizeForm from './UnitSizeForm';
import StorageInfoForm from './StorageInfoForm';
import { alert } from 'components/Alerts';
import './styles.scss';

const Storage = ({ id, tab, jobInfo, dates, movingInfo }) => {
  const { data: defaultItems } = useDefault();
  const { data, refetch: refetchTabInfo } = useTabInfo(id, 'storage_calc');
  const storageData = data?.storage_calc;
  const contract = data?.contract;

  const { data: inventory } = useTabInfo(id, 'inventory');

  const { mutate: changeStorage } = useChangeJobStorage(id, 'storage_calc');
  const { mutate: changeStorageEndDate } = useChangeStorageEndDate(id, 'storage_calc');
  const { data: jobUnitSizes } = useGetJobUnits(id);
  const { mutate: updateJobUnits } = useUpdateJobUnits(id, 'storage_calc');
  const { mutate: deleteStorageUnitSize } = useDeleteStorageUnitSize(id, 'storage_calc');
  const { mutate: updateStorageFee } = useUpdateStorageFee(id, 'storage_calc');
  const { mutate: updateStorageRate } = useUpdateStorageRate(id, 'storage_calc');
  const { mutate: updateStorageTotalPrice } = useUpdateStorageTotalPrice(id, 'storage_calc');
  const { mutateAsync: sendManualInvoice } = useSendStorageStripeInvoice();
  const { mutate: sendMail } = useEmailSend(id, tab, 'default');

  const { data: communications } = useCommunicationsList(id, 'default');

  const sendMailHandler = useCallback(() => {
    // const template = communications['mail_templates'].find((i) => i.slug === 'storage_contract_sign');

    const data = {
      template: 'storage_contract_sign',
      include_pdf: false,
      job_id: id,
      template_html: '',
      edit_template: false,
      pdf: false,
      auto_email: jobInfo.auto_emails,
      if_packing_size: false,
      email: '',
    };

    sendMail(data, {
      onSuccess: () => refetchTabInfo(),
    });
  }, [id, communications, jobInfo, sendMail, refetchTabInfo]);

  const storages = useMemo(() => {
    if (!defaultItems?.storages) return [];

    return defaultItems.storages[jobInfo.company_id].map((storage) => {
      return { ...storage, label: storage.name, value: storage.id };
    });
  }, [defaultItems, jobInfo.company_id]);

  const storage = storages.find((storage) => storage.id === jobInfo.storage_id);

  const storageUnitSizes = useMemo(() => {
    if (!defaultItems?.storage_unit_sizes && !jobInfo?.storage_id) return [];

    return defaultItems.storage_unit_sizes
      .filter((storage) => storage.storage_id === jobInfo.storage_id)
      .map((storage) => {
        return { ...storage, label: storage.name, value: storage.id };
      });
  }, [defaultItems, jobInfo.storage_id]);

  const handleChangeStorage = useCallback(
    (storageId) => {
      changeStorage({ storage_id: storageId, job_id: id });
    },
    [id, changeStorage]
  );

  const handleChangeStorageEndDate = useCallback(
    (endDate) => {
      changeStorageEndDate({ ...endDate, job_id: id });
    },
    [id, changeStorageEndDate]
  );

  const handleUpdateJobUnits = useCallback((data) => updateJobUnits(data), [updateJobUnits]);

  const onDeleteUnitSize = useCallback(
    (data) => {
      if (!data.id) return;

      deleteStorageUnitSize({ id: data.id, job_id: id });
    },
    [deleteStorageUnitSize, id]
  );

  const onUpdateStorageFee = useCallback((data) => updateStorageFee({ job_id: id, ...data }), [updateStorageFee, id]);

  const onSendManualInvoice = () => {
    sendManualInvoice({ job_id: id, company_id: jobInfo.company_id }).then(() => {
      alert({
        title: 'Manual Invoice Successfully Sent',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'OK',
      });
    });
  };

  return (
    <Fragment>
      {storage && storageData && inventory && movingInfo && (
        <StorageInfoForm
          id={id}
          movingInfo={movingInfo}
          jobInfo={jobInfo}
          dates={dates}
          storage={storage}
          storages={storages}
          storageData={storageData}
          jobInventoryData={inventory.job_inventory.estimated}
          contract={contract}
          onSendContractEmail={sendMailHandler}
          changeStorage={handleChangeStorage}
          changeStorageEndDate={handleChangeStorageEndDate}
          updateStorageFee={onUpdateStorageFee}
          updateStorageRate={updateStorageRate}
          updateStorageTotalPrice={updateStorageTotalPrice}
          onSendManualInvoice={onSendManualInvoice}
        />
      )}
      <UnitSizeForm
        jobId={id}
        jobUnitSizes={jobUnitSizes}
        storageUnitSizes={storageUnitSizes}
        onSubmit={handleUpdateJobUnits}
        onDelete={onDeleteUnitSize}
      />
    </Fragment>
  );
};

export default Storage;

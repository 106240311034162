import React, { Fragment, useMemo } from 'react';
import { useInfo, useTabInfo } from 'hooks/query/useJob';
import { useParams } from 'react-router-dom';
import Icon from 'components/Icon';

const ExpResMover = (props) => {
  const { id, tab } = useParams();

  const {
    data: { assigned_crew: assignedCrew },
  } = useTabInfo(id, tab);

  const {
    data: { job_info: jobInfo, calc_info: calcInfo },
  } = useInfo(id);

  const moversArr = assignedCrew.movers;

  const travelTime = useMemo(() => {
    return parseFloat(calcInfo.manual_travel_time) !== 0 ? parseFloat(calcInfo.manual_travel_time) : parseFloat(calcInfo.travel_time);
  }, [calcInfo]);

  const pickupTime = useMemo(() => {
    return parseFloat(calcInfo.manual_pickup_time) !== 0 ? parseFloat(calcInfo.manual_pickup_time) : parseFloat(calcInfo.pickup_time);
  }, [calcInfo]);

  return (
    <Fragment>
      <div className="new-table--row no-padding bcg-light">
        <div className="new-table--row--item  no-side-shadows">
          <span className="disabled font-weight-bold"> Movers </span>
        </div>
        <div className="new-table--row--item  no-side-shadows">
          <Icon className="fb3" icon="hours_total" title="Hours total" size={20} />
          <Icon className="fb3" icon="salary_per_hour" title="Salary per hour" size={20} />
          <Icon className="fb3" icon="commission_value" title="Commission value" size={20} />
        </div>
      </div>
      {moversArr?.map((mover) => {
        const mover_hours =
          jobInfo.status !== 6
            ? parseFloat(calcInfo.estimated_time) + travelTime + pickupTime
            : parseFloat(calcInfo.labor_time) + travelTime + pickupTime;

        const mover_salary = jobInfo.status !== 6 ? mover_hours * mover.hourly_salary : mover_hours * mover.hourly_salary;

        return (
          <div className="new-table--row no-padding" key={mover.id}>
            <div className="new-table--row--item  no-side-shadows">
              <b>
                {mover.name} {mover.userlastname}
              </b>
            </div>
            <div className="new-table--row--item  no-side-shadows">
              <b className="fb3"> {mover_hours.toFixed(2)}</b>
              <b className="fb3"> {mover.hourly_salary}$/h</b>
              <b className="fb3"> {mover_salary.toFixed(2)}</b>
            </div>
          </div>
        );
      })}
    </Fragment>
  );
};

export default ExpResMover;

import React, { useState, useMemo } from 'react';
import { useTabInfo, useInfo } from 'hooks/query/useJob';
import { useParams } from 'react-router-dom';
import ExpResExpense from './ExpenseTab';
import TotalsTab from './TotalsTab';
import JobManagersTab from './JobManagersTab';
import JobAssignmentsTab from './JobAssignmentsTab';
import ResourceSalaryTab from './ResourceSalaryTab';
import CarriersTab from './CarriersTab';

const ExpRes = () => {
  const { id, tab } = useParams();

  const [expenses, setExpenses] = useState(0);
  const {
    data: { assigned_crew: assignedCrew, job_packing: jobPacking, assigned_management: assignedManagement },
  } = useTabInfo(id, tab);

  const {
    data: { job_info: jobInfo, calc_info: calcInfo, related_jobs: relatedJobs },
  } = useInfo(id);

  const totalExpenses = (data) => setExpenses(parseFloat(data).toFixed(2));

  const isStorageTypeJob = jobInfo?.type === 3;

  const jobStatus = +jobInfo.status;
  const commission = assignedManagement.agent?.commision || 0;
  const jobIsDone = jobStatus !== 6;

  const agentCommission = useMemo(() => {
    return jobIsDone
      ? ((parseFloat(commission) * parseFloat(calcInfo.estimated_total)) / 100).toFixed(2)
      : ((parseFloat(commission) * parseFloat(calcInfo.final_total)) / 100).toFixed(2);
  }, [calcInfo, commission, jobIsDone]);

  const truckInfo = assignedCrew.trucks[0];

  const gallonsUsed = useMemo(() => {
    return truckInfo ? (parseFloat(calcInfo.distance_total) / parseFloat(truckInfo.miles_per_gallon)).toFixed(2) : 0;
  }, [calcInfo.distance_total, truckInfo]);

  const fuelCost = useMemo(() => {
    return truckInfo ? (gallonsUsed * parseFloat(truckInfo.fuel_cost_per_gallon)).toFixed(2) : 0;
  }, [gallonsUsed, truckInfo]);

  const travelTime = useMemo(() => {
    return parseFloat(calcInfo.manual_travel_time) !== 0 ? parseFloat(calcInfo.manual_travel_time) : parseFloat(calcInfo.travel_time);
  }, [calcInfo.manual_travel_time, calcInfo.travel_time]);

  const pickupTime = useMemo(() => {
    return parseFloat(calcInfo.manual_travel_time) !== 0 ? parseFloat(calcInfo.manual_pickup_time) : parseFloat(calcInfo.pickup_time);
  }, [calcInfo.manual_pickup_time, calcInfo.manual_travel_time, calcInfo.pickup_time]);

  const driverInfo = assignedCrew.drivers[0];
  const packingCommission = parseFloat(driverInfo?.packing_persent);

  const driverHourlySalary = useMemo(() => {
    return jobIsDone
      ? (travelTime + pickupTime + parseFloat(calcInfo.estimated_time)) * parseFloat(driverInfo?.hourly_salary)
      : (travelTime + pickupTime + parseFloat(calcInfo.labor_time)) * parseFloat(driverInfo?.hourly_salary);
  }, [calcInfo.estimated_time, calcInfo.labor_time, driverInfo?.hourly_salary, jobIsDone, pickupTime, travelTime]);

  const fin_packing_total = jobIsDone
    ? jobPacking.estimated.reduce((sum, item) => sum + parseInt(item.qty) * parseFloat(item.price), 0)
    : jobPacking.final.reduce((sum, item) => sum + parseInt(item.qty) * parseFloat(item.price), 0);

  const commission_value = (((packingCommission || 0) * fin_packing_total) / 100).toFixed(2);
  const driver_salary = parseFloat(commission_value) + parseFloat(driverHourlySalary || 0);

  const moverSalary = useMemo(() => {
    return jobIsDone
      ? assignedCrew.movers.reduce((sum, mover) => sum + (parseFloat(calcInfo.estimated_time) + pickupTime + travelTime) * mover.hourly_salary, 0)
      : assignedCrew.movers.reduce(
          (sum, mover) => sum + (parseFloat(calcInfo.labor_time) + pickupTime + travelTime) * parseFloat(mover.hourly_salary),
          0
        );
  }, [calcInfo.estimated_time, calcInfo.labor_time, jobIsDone, assignedCrew.movers, pickupTime, travelTime]);

  const totalSalary = (parseFloat(driver_salary) + parseFloat(agentCommission) + parseFloat(moverSalary)).toFixed(2);
  const total = jobIsDone ? calcInfo.estimated_total : calcInfo.final_total;

  const isDelivery = relatedJobs?.pickup_job !== 0 && relatedJobs?.long_delivery_job === 0;

  return (
    <div className="d-flex flex-wrap">
      <div className="d-flex w100 justify-content-between align-items-start">
        <JobManagersTab />

        {isDelivery && jobStatus === 10 ? <CarriersTab /> : !isStorageTypeJob ? <JobAssignmentsTab /> : null}
      </div>

      {!isStorageTypeJob && (
        <div className="d-flex w100 justify-content-between">
          <ResourceSalaryTab totalSalary={totalSalary} fuelCost={fuelCost} />
          <div className="d-flex align-items-start justify-content-between w49">
            <ExpResExpense totalExpenses={totalExpenses} />
            <TotalsTab total={total} totalSalary={totalSalary} fuelCost={fuelCost} expenses={expenses} />
          </div>
        </div>
      )}
    </div>
  );
};

export default ExpRes;

import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import * as qs from 'querystring';
import YearlyCalendar from 'components/YearlyCalendar';
import Settings from './Settings';
import { useCalendarState } from './useCalendarState';
import Api from './Api';
import './App.scss';

function CrewAvailabilityCalendar() {
  const { search } = useLocation();
  const queryString = qs.parse(search.replace('?', ''));

  const [crewUnit, setCrewUnit] = useState(null);

  const { calendarState, actions } = useCalendarState();

  const getUnitAvailability = () => {
    Api.getUnitAvailability({
      date: calendarState.today.format('LL'),
      unit_type: queryString.unit_type,
      unit_id: queryString.unit_id,
    }).then((response) => {
      const dates = response.data.map((date) => moment(date).format('YYYY-MM-DD'));
      actions.setUnitAvailability(dates);
    });
  };

  const getUnitData = (date) => {
    Api.getUnitData({
      date: date.format('YYYY-MM-DD'),
      unit_type: queryString.unit_type,
      unit_id: queryString.unit_id,
    }).then((response) => setCrewUnit(response.data));
  };

  useEffect(() => {
    getUnitAvailability();
  }, []);

  useEffect(() => {
    if (calendarState.selectedDay) {
      getUnitData(calendarState.selectedDay);
    }
  }, [calendarState.selectedDay]);

  const changeAvailability = () => {
    setCrewUnit({ ...crewUnit, available: !crewUnit.available });
  };

  const handleSave = () => {
    const prepared = {
      data: {
        ...crewUnit,
        ...calendarState,
        date: calendarState.selectedDay.format('LL'),
        unit_type: queryString.unit_type,
        unit_id: queryString.unit_id,
      },
    };

    Api.save(prepared).then(() => {
      actions.resetMulti();
      getUnitAvailability();
    });
  };

  return (
    <div className="crew-calendar-container">
      <YearlyCalendar
        info={{ red: 'Date Not Available', blue: 'Multiple Dates Selected', grey: 'Single Date Selected' }}
        year={calendarState.year}
        showTodayButton={true}
        customCSSclasses={{
          holidays: calendarState.custom_dates,
          'multi-dates': calendarState.multiDates,
        }}
        selectRange={calendarState.rangeSelect}
        selectedRange={calendarState.selectedRange}
        selectedDay={calendarState.selectedDay}
        onPrevYear={actions.onPrevYear}
        onNextYear={actions.onNextYear}
        goToToday={actions.goToToday}
        onPickDate={actions.pickDate}
        onPickRange={actions.pickRange}
      />

      <Settings state={calendarState} crewUnit={crewUnit} actions={actions} changeAvailability={changeAvailability} onSave={handleSave} />
    </div>
  );
}

export default CrewAvailabilityCalendar;

import React from 'react';
import RadioButton from 'components/newComponents/RadioButton';
import TooltipTitle from 'components/TooltipTitle';
import { useForm } from '../../../hooks/useForm';
import { getCurrentMoveSize } from '../../../utils';

function PackingService({ packingDescription, moveSizes }) {
  const { values, actions } = useForm();

  const currentMoveSize = getCurrentMoveSize(moveSizes, values.move_size_id);

  return (
    <div className="new-table--row">
      <div className="new-table--row--item justify-content-around">
        {packingDescription.map((item) => {
          return (
            <TooltipTitle title={item.description} key={item.type} top>
              <RadioButton
                name="packing_service"
                title={item.title}
                current={values['packing_service']}
                value={item.type}
                onChange={() => actions.handleChangePackingService(item.type, currentMoveSize)}
              />
            </TooltipTitle>
          );
        })}
      </div>
    </div>
  );
}

export default PackingService;

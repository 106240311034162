import React, { useState, useMemo, useCallback } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useMovingSave } from 'hooks/query/useMoving';
import { useTabInfo, useInfo } from 'hooks/query/useJob';
import { formatTimeAPMeridiem } from 'helpers/dateHelper';
import { useDefault } from 'hooks/query/useCommon';
import { findBuilding } from 'helpers/buildings';
import { formatPhoneNumberNoFirstCharOnlyDigitsAndHyphen } from 'helpers/phoneFormat';
import { useModal } from 'context/modal';
import Autocomplete from 'components/newComponents/Autocomplete';
import Select from 'components/newComponents/Select';
import Input from 'components/newComponents/Input';
import TimeSelect from 'components/TimeSelect';
import Icon from 'components/Icon';
import AutocompleteInput from 'components/newComponents/AutocompleteInput';
import ElevatorMenu from 'components/ElevatorMenu/ElevatorMenu';

const MovingSectionForm = ({ type }) => {
  const { id, tab } = useParams();
  const {
    data: { moving_info: movingInfo, additional_info: additionalInfo },
  } = useTabInfo(id, tab);

  const {
    data: { job_info: jobInfo },
  } = useInfo(id);

  const { modalNames, open } = useModal();

  const { mutate: saveMoving } = useMovingSave(id, tab);

  const { data: defaultItems } = useDefault();

  const [createdCoi, setCreatedCoi] = useState(false);

  const data = useMemo(() => {
    const prepared = {
      ...additionalInfo[type],
      ...movingInfo[type],
      lead_note: movingInfo.lead_note?.note ?? '',
    };
    prepared.cell_phone = formatPhoneNumberNoFirstCharOnlyDigitsAndHyphen(prepared.cell_phone);
    prepared.home_phone = formatPhoneNumberNoFirstCharOnlyDigitsAndHyphen(prepared.home_phone);

    return prepared;
  }, [additionalInfo, type, movingInfo]);

  const stairsSet = useMemo(() => {
    return [...Array(10).keys()].map((_, i) => ({ value: i + 1, label: i + 1 }));
  }, []);

  const buildings = useMemo(() => {
    if (!defaultItems?.building_info || !defaultItems?.building_info.length) return [];
    return defaultItems.building_info.map((item) => item?.building_name);
  }, [defaultItems]);

  const createInsuranceNotifications = useCallback(() => {
    Swal.fire({
      title: `Insurance is required for this building!`,
      text: `Create COI and Sent?`,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      reverseButtons: false,
      backdrop: false,
    }).then((result) => {
      if (result.isConfirmed) {
        if (parseInt(jobInfo.status) === 7 || parseInt(jobInfo.status) === 21) {
          return Swal.fire({
            title: 'Job must be in POSTSALE status. Please Book a job',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'OK',
          });
        }
        open(modalNames.insuranceProvider, { type });
      }
    });
  }, [jobInfo.status, modalNames.insuranceProvider, open, type]);

  const saveMovingHandler = useCallback(
    (values) => {
      saveMoving(
        { ...values },
        {
          onSuccess: () => {
            if (createdCoi) createInsuranceNotifications();
          },
        }
      );
    },
    [createInsuranceNotifications, createdCoi, saveMoving]
  );

  const { values, handleChange, setFieldValue, resetForm, handleSubmit, dirty } = useFormik({
    initialValues: data,
    onSubmit: saveMovingHandler,
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      customer_name: Yup.string().required('Enter name!'),
      // home_phone: Yup.string()
      //   .test('len', 'Invalid phone number', (value) => {
      //     if (!value) return false;
      //     return !value.includes('_');
      //   })
      //   .required('Home phone required'),
      // city: Yup.string().required('Enter address!'),
      // address: Yup.string().required('Enter address!'),
      zip: Yup.string()
        .required('Zip required')
        .test('len', 'Invalid zip length', (value) => {
          if (!value) return false;
          return value.length === 5;
        }),
    }),
  });

  const setBuildingFieldValue = useCallback(
    (building, place_id) => {
      if (!building) return null;

      setFieldValue(`address`, building?.building_address || '');
      setFieldValue(`zip`, building?.building_zip || '');
      setFieldValue(`city`, building?.building_city || '');
      setFieldValue(`state`, building?.building_state || '');
      setFieldValue(`building_name`, building?.building_name || '');
      setFieldValue(`required_coi`, building?.required_coi || 0);
      setFieldValue(`building_id`, building?.id || 0);
      setFieldValue(`place_id`, place_id || '');
      setFieldValue(`building_type`, building?.building_type);
      setFieldValue(`elevator_exist`, building?.elevator_exist);
    },
    [setFieldValue]
  );

  const onSelectAutoCompleteHandler = useCallback(
    async (address, name) => {
      Object.keys(address).forEach((key) => {
        setFieldValue(key, address[key]);
      });
      setFieldValue('zip', address?.zipcode || '');
      setFieldValue('address', `${address?.street_number ?? ''} ${address?.address ?? ''}`);
      setCreatedCoi(true);
      const building = findBuilding(defaultItems?.building_info, address);

      setFieldValue(`building_name`, building?.building_name || '');
      setFieldValue(`required_coi`, !!building);
      setFieldValue(`building_id`, building?.id || 0);
      setFieldValue(`place_id`, address.place_id);
    },
    [defaultItems.building_info, setFieldValue]
  );

  const onSelectAutocompleteOption = useCallback(
    (value, name) => {
      const building_info = defaultItems?.building_info.find((item) => item.building_name === value);

      setBuildingFieldValue(building_info, building_info?.place_id);
    },
    [setFieldValue, setBuildingFieldValue]
  );

  const zipHandler = useCallback(
    (value, name) => {
      setFieldValue(`city`, value?.city);
      setFieldValue(`state`, value?.state);
    },
    [setFieldValue]
  );

  const restoreToDefault = useCallback(() => {
    resetForm();
  }, [resetForm]);

  return (
    <form style={{ backgroundColor: '#ccc', padding: 3 }} onSubmit={handleSubmit}>
      <div className="new-table shadow mb-0">
        <h3 className="header">Edit waypoint {type}</h3>

        <div className="new-table--row">
          <div className="new-table--row--item waypoint-inputs">
            <Input name="customer_name" placeholder="Name" value={values['customer_name']} onChange={handleChange} />
          </div>
          <div className="new-table--row--item waypoint-inputs">
            <button type="button" onClick={() => open(modalNames.email, { type })}>
              <Icon icon="email" />
            </button>
            <Input name="email" placeholder="Email" value={values['email']} onChange={handleChange} />
          </div>
          <div className="new-table--row--item waypoint-inputs">
            <div>
              <Icon icon="home_phone" title="Home Phone" />
            </div>
            <Input name="home_phone" mask="111-111-1111" value={values['home_phone']} onChange={handleChange} />
          </div>
          <div className="new-table--row--item waypoint-inputs">
            <Icon icon="cell_phone" title="Cell Phone" />
            <Input name="cell_phone" mask="111-111-1111" value={values['cell_phone']} onChange={handleChange} />
          </div>
        </div>

        <div className="new-table--row">
          <div className="new-table--row--item p15 no-padding">
            <div className="new-table--row--item p15">
              <Autocomplete
                name="address"
                placeholder="Search address"
                value={values['address']}
                onChange={(value) => setFieldValue('address', value)}
                onSelect={onSelectAutoCompleteHandler}
              />
            </div>
            <div className="new-table--row--item p15 w10 no-shadow">
              <Input name="apartment" placeholder="apt" value={values['apartment']} onChange={handleChange} />
            </div>

            <div className="new-table--row--item p15">
              <AutocompleteInput
                name="building_name"
                placeholder="Building name"
                value={values['building_name']}
                onChange={(value) => setFieldValue('building_name', value)}
                onSelect={onSelectAutocompleteOption}
                options={buildings}
              />
            </div>
          </div>
        </div>

        <div className="new-table--row">
          <div className="new-table--row--item p15 no-padding">
            <div className="new-table--row--item p15">
              <Autocomplete
                mask="11111"
                placeholder="Zip"
                name="zip"
                onBlur={zipHandler}
                value={values['zip']}
                shouldFetchSuggestions={false}
                onChange={(value) => setFieldValue('zip', value)}
              />
            </div>
          </div>
          <div className="new-table--row--item p15 no-padding">
            <div className="new-table--row--item p15 no-shadow">
              <span>{values.state || 'State'}</span>
            </div>
            <div className="new-table--row--item p15">
              <span>{values.city || 'City'}</span>
            </div>
          </div>
        </div>

        <div className="new-table--row">
          <p className="new-table--row--item waypoint-inputs w20">Building Access:</p>
          <div className="new-table--row--item waypoint-inputs">
            <Icon icon="property_access" title={!!values.building_access_start ? 'Access to building required' : 'Access to building not required'} />
            <TimeSelect
              name="building_access_start"
              value={values['building_access_start']}
              placeholder="Start time"
              onChange={({ name, date }) => setFieldValue(name, formatTimeAPMeridiem(date))}
            />
            <TimeSelect
              name="building_access_end"
              value={values['building_access_end']}
              placeholder="End time"
              onChange={({ name, date }) => setFieldValue(name, formatTimeAPMeridiem(date))}
            />
          </div>
          <p className="new-table--row--item waypoint-inputs text-right w20">Elevator Access:</p>
          <div className="new-table--row--item waypoint-inputs">
            <ElevatorMenu data={additionalInfo[type]} />

            <TimeSelect
              name="elevator_reservation_start"
              value={values['elevator_reservation_start']}
              placeholder="Start time"
              onChange={({ name, date }) => setFieldValue(name, formatTimeAPMeridiem(date))}
            />
            <TimeSelect
              name="elevator_reservation_end"
              value={values['elevator_reservation_end']}
              placeholder="End time"
              onChange={({ name, date }) => setFieldValue(name, formatTimeAPMeridiem(date))}
            />
          </div>
          <div className="new-table--row--item waypoint-inputs">
            <span>Stairs: </span>
            <Select
              options={stairsSet}
              value={values['stairs']}
              placeholder={`Stairs in building ${type}`}
              name="stairs"
              onChange={({ value }) => setFieldValue('stairs', value)}
            />
          </div>
        </div>

        {type === 'from' && (
          <div className="new-table--row">
            <div className="new-table--row--item p15">
              <Input name="lead_note" placeholder="Quick note for job" value={values['lead_note']} onChange={handleChange} />
            </div>
          </div>
        )}
        {dirty && (
          <div className="new-table--row">
            <div className="new-table--row--item no-padding">
              <button className=" flex-grow-1 p20" type="button" onClick={restoreToDefault}>
                Restore default
              </button>
            </div>
            <div className="new-table--row--item no-padding">
              <button className="main flex-grow-1 p20" type="submit">
                Save changes
              </button>
            </div>
          </div>
        )}
      </div>
    </form>
  );
};

export default MovingSectionForm;

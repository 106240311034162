import React, { Fragment, useState, useMemo, useCallback } from 'react';
import classNames from 'classnames';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import RoutesItem from '../RoutesItem';
import { formatLT } from 'helpers/dateHelper';
import MovingSectionForm from './MovingSectionForm';
import { useModal } from 'context/modal';
import { useTabInfo } from 'hooks/query/useJob';
import { useDefault } from 'hooks/query/useCommon';
import queryKeys from 'config/queryKeys';
import { getElevatorData } from 'helpers/getElevatorData';
import { formatPhoneNumberPlusOneAndDigits } from 'helpers/phoneFormat';

const title = {
  from: 'From office to PickUp',
  to: 'From PickUp to Destination',
};

const MovingSection = ({ type }) => {
  const queryClient = useQueryClient();
  const [isOpen, setIsOpen] = useState(false);
  const { id, tab } = useParams();

  const {
    data: { additional_info: additionalInfo, job_distances: jobDistances },
  } = useTabInfo(id, tab);

  const { dates } = queryClient.getQueryData([queryKeys.job, id]).data;
  const { moving_info: movingInfo } = queryClient.getQueryData([queryKeys.job, id, tab]).data;
  const { job_packing: jobPacking, job_info: jobInfo } = queryClient.getQueryData([queryKeys.job, id]).data;
  const { data: defaultItems } = useDefault();

  const { elevatorExist, elevatorConfirm, elevator, elevatorReservation, elevatorStyle } = getElevatorData(additionalInfo[type]);

  const { open, modalNames, info, close } = useModal();
  const isTwilio = info(modalNames.twilio).visible;

  const isDeliveryJob = useMemo(() => {
    if (!jobInfo) return false;
    if (jobInfo.parent_id !== 0 && jobInfo.storage_id !== 0 && (jobInfo.type === 1 || jobInfo.type === 5)) return true;
    return false;
  }, [jobInfo]);

  const isPickupJob = useMemo(() => {
    if (!jobInfo) return false;
    if (jobInfo.parent_id === 0 && jobInfo.storage_id !== 0) return true;
    return false;
  }, [jobInfo]);

  const waypointData = movingInfo[type];
  const packing = jobPacking[type === 'from' ? 'estimated' : 'final'];

  const name = useMemo(() => {
    if (!waypointData) return '';
    if (isDeliveryJob && type === 'from') return '';
    if (isDeliveryJob && type === 'to') return waypointData.customer_name;
    if (isPickupJob && type === 'from') return waypointData.customer_name;
    if (isPickupJob && type === 'to') return '';

    return waypointData.customer_name;
  }, [type, isDeliveryJob, isPickupJob, waypointData]);

  const buildingName = useMemo(() => {
    if (!waypointData || !defaultItems) return '';

    if (isDeliveryJob && type === 'from') {
      const storage = defaultItems.storages[jobInfo.company_id].find((storage) => storage.id === jobInfo.storage_id);
      if (storage) {
        return `Storage: ${storage.name}`;
      }
      return '';
    }
    if (isDeliveryJob && type === 'to') {
      return waypointData.building_name;
    }

    if (isPickupJob && type === 'from') {
      return waypointData.building_name;
    }
    if (isPickupJob && type === 'to') {
      const storage = defaultItems.storages[jobInfo.company_id].find((storage) => storage.id === jobInfo.storage_id);
      if (storage) {
        return `Storage: ${storage.name}`;
      }
      return '';
    }

    return waypointData.building_name;
  }, [type, defaultItems, jobInfo, isDeliveryJob, isPickupJob, waypointData]);

  const handleCall = useCallback(
    (phone) => {
      if (isTwilio) {
        close(modalNames.twilio);
      }
      setTimeout(() => {
        open(modalNames.twilio, { number: formatPhoneNumberPlusOneAndDigits(phone), callUp: true, companyID: jobInfo?.company_id });
      }, 0);
    },
    [modalNames.twilio, jobInfo?.company_id, isTwilio, open, close]
  );

  const handleEmail = useCallback(
    (email) => {
      open(modalNames.newEmail, {
        job_id: id,
        type: 'new',
        email_to: email,
        company_id: 0,
      });
    },
    [id, modalNames.newEmail, open]
  );

  const phoneOptions = [
    { name: 'Cell Phone', value: waypointData.cell_phone, clickFunc: () => handleCall(waypointData.cell_phone) },
    { name: 'Fax', value: waypointData?.fax },
    { name: 'Home Phone', value: waypointData?.home_phone, clickFunc: () => handleCall(waypointData?.home_phone) },
    { name: 'Email', value: waypointData?.email, clickFunc: () => handleEmail(waypointData?.email) },
  ];

  const time = useMemo(() => {
    // if (!jobInfo || !jobInfo.status) return '';
    // const inProgressStatuses = [4, 5, 6];
    // const deliveredStatus = 6;

    // if (type === 'from' && inProgressStatuses.includes(jobInfo.status)) {
    //   return formatLT(dates.in_progress_date);
    // }

    // if (type === 'to' && deliveredStatus === jobInfo.status) {
    //   return formatLT(dates.delivered_date);
    // }

    if (type === 'from') return formatLT(dates.start_date);

    if (type === 'to') return formatLT(dates.stop_date);

    return '';
  }, [type, dates]);

  const distance = type === 'from' ? jobDistances?.[0]?.distance ?? '' : jobDistances?.[jobDistances.length - 2]?.distance ?? '';

  const address = useMemo(() => {
    const hasZipCodeInAddress = waypointData.zip ? waypointData.address.includes(waypointData.zip) : false;
    const apartment = waypointData.apartment ? `, ${waypointData.apartment}` : '';
    const zip = waypointData.zip ? `, ${waypointData.zip}` : '';

    return hasZipCodeInAddress ? waypointData.address + apartment : waypointData.address + apartment + zip;
  }, [waypointData.address, waypointData.apartment, waypointData.zip]);

  return (
    <Fragment>
      <RoutesItem
        extraStopClass={classNames({ waypoint__first: type === 'from', waypoint__last: type === 'to' }, 'waypoint__middle')}
        key={type}
        type={type}
        time={time}
        travelDistance={distance.replace(/[^0-9\.]+/g, '')}
        travelTitle={title[type]}
        address={address}
        buildingName={buildingName}
        name={name}
        options={phoneOptions}
        stairs={waypointData.stairs}
        elevator={elevator}
        elevatorExist={elevatorExist}
        elevatorConfirm={elevatorConfirm}
        isIntermediateStops
        elevatorReservation={elevatorReservation}
        elevatorStyle={elevatorStyle}
        packing={packing}
        jobType="estimated"
        onClick={() => setIsOpen((prev) => !prev)}
      />
      {isOpen && <MovingSectionForm type={type} />}
    </Fragment>
  );
};

export default MovingSection;

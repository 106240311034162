import React from 'react';
import { useModal } from 'context/modal';
import { useDefault } from 'hooks/query/useCommon';
import { preventArrowUpDown } from 'utils/preventArrowUpDown';
import { ModalButton } from 'components/newComponents/Buttons';
import Select from 'components/newComponents/Select';
import Modal from 'components/newComponents/Modal';
import Input from 'components/newComponents/Input';
import { useForm } from './hooks/useForm';
import { useSubmit } from './hooks/useSubmit';
import { prepareInitialValues, prepareCarriersOptions } from './utils';

const CreateCarrierDriver = () => {
  const { info, close, modalNames } = useModal();

  const params = info(modalNames.createCarrierDriver).params;
  const isVisible = info(modalNames.createCarrierDriver).visible;

  const { onSuccess, carrier_id } = params;

  const closeHandler = () => close(modalNames.createCarrierDriver);

  const {
    data: { carriers = [] },
  } = useDefault();

  const { onSubmit, isMutationRunning } = useSubmit({
    onSuccess: (createdDriverId) => {
      if (typeof onSuccess === 'function') onSuccess(createdDriverId);

      closeHandler();
    },
  });

  const { dirty, values, errors, touched, actions } = useForm({
    initialValues: prepareInitialValues(carrier_id),
    onSubmit,
  });

  return (
    <Modal show={isVisible} style={{ width: 480 }} header="Create Driver" onHide={closeHandler}>
      <div className="new-table modal">
        <div className="new-table--row">
          <div className="new-table--row--item select no-side-shadows">
            <Select
              name="carrier_id"
              value={values.carrier_id}
              disabled
              placeholder="Select Carrier"
              error={errors.carrier_id && touched.carrier_id ? errors.carrier_id : undefined}
              options={prepareCarriersOptions(carriers)}
            />
          </div>
        </div>

        <div className="new-table--row">
          <div className="new-table--row--item no-side-shadows">
            <Input
              name="name"
              type="text"
              value={values.name}
              placeholder="Driver name"
              error={errors.name && touched.name ? errors.name : undefined}
              onKeyDown={preventArrowUpDown}
              onChange={actions.handleChange}
            />
          </div>
        </div>

        <div className="new-table--row">
          <div className="new-table--row--item no-side-shadows">
            <Input
              name="phone"
              value={values.phone}
              error={errors.phone && touched.phone ? errors.phone : undefined}
              mask="111-111-1111"
              placeholder="Driver phone"
              onKeyDown={preventArrowUpDown}
              onChange={actions.handleChange}
            />
          </div>
        </div>
      </div>

      <div className="footer-buttons">
        <ModalButton title="Cancel" variant="cancel" onClick={closeHandler} />

        <ModalButton title="Create" variant="confirm" disabled={!dirty || isMutationRunning} onClick={() => actions.handleSubmit()} />
      </div>
    </Modal>
  );
};

export default CreateCarrierDriver;

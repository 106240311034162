import React, { Fragment } from 'react';
import Select from 'components/newComponents/Select';
import { laborTypeOptions } from 'helpers/constants';
import * as Fields from './Fields';
import * as View from './View';
import { useForm } from '../../hooks/useForm';
import { getCompaniesOptions } from '../../utils';

function LeadForm(props) {
  const { companies, moveSizes, leadProviders, buildingInfo, packingDescription } = props;

  const { values, actions } = useForm();

  return (
    <Fragment>
      <Fields.Base />

      <div className="new-table--row">
        <div className="new-table--row--item select">
          <Select
            name="company_id"
            value={values['company_id']}
            placeholder="Companies"
            options={getCompaniesOptions(companies)}
            error={actions.isValidHandler('company_id')}
            onChange={actions.handleChangeCompany}
          />
        </div>
      </div>

      <Fields.Move moveSizes={moveSizes} />

      <Fields.HearFrom leadProviders={leadProviders} />

      <div className="new-table--row">
        <div className="new-table--row--item">Service type</div>
        <div className="new-table--row--item select">
          <Select
            placeholder="Service type"
            name="labor_type"
            value={values['labor_type']}
            options={laborTypeOptions}
            onChange={actions.handleChangeLaborType}
          />
        </div>
      </div>

      {values.pickup_date && values.pickup_time && <View.CrewAvailability moveSizes={moveSizes} />}

      <Fields.Address buildingInfo={buildingInfo} />

      <View.Route />

      <Fields.Insurance />

      <View.EstTime />

      <Fields.Additional />

      <Fields.PackingService packingDescription={packingDescription} moveSizes={moveSizes} />

      <View.Distance />
    </Fragment>
  );
}

export default LeadForm;

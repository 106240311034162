import { useMutation, useQueryClient } from 'react-query';
import { useApi } from 'context/api';
import queryKeys from 'config/queryKeys';

export const useDeleteExtraStop = (id, tab) => {
  const { extraStops } = useApi();
  const queryClient = useQueryClient();

  return useMutation(extraStops.delete, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.job, id]);
      queryClient.invalidateQueries([queryKeys.job, id, tab]);
    },
  });
};

export const useAddExtraStop = (id) => {
  const { extraStops } = useApi();
  const queryClient = useQueryClient();

  return useMutation(extraStops.add, {
    onSuccess: () => queryClient.invalidateQueries([queryKeys.job, id]),
  });
};

export const useUpdateExtraStop = (id) => {
  const { extraStops } = useApi();
  const queryClient = useQueryClient();

  return useMutation(extraStops.update, {
    onSuccess: () => queryClient.invalidateQueries([queryKeys.job, id]),
  });
};

import React, { memo, useMemo, Fragment } from 'react';
import QuickViewItem from './QuickViewItem';

const QuickView = memo((props) => {
  const { all_packing, selected, fields, onDeleteFromSave, onSavePacking, onClearPacking, onChangeQty } = props;

  const total = useMemo(() => {
    if (!selected.length || !fields || !all_packing) return 0;

    return all_packing.reduce((acc, item) => acc + fields[item.id] * +item.price, 0);
  }, [all_packing, fields, selected]);

  return (
    <Fragment>
      <div className="new-table">
        <div className="new-table--row">
          <div className="new-table--row--item fw-700">
            <span>Quick view</span>
          </div>
        </div>
        <div className="new-table--row">
          <div className="new-table--row--item light no-shadow w40">
            <span>Name</span>
          </div>
          <div className="new-table--row--item light w15 no-shadow text-center">
            <span>Size</span>
          </div>
          <div className="new-table--row--item light w10 no-shadow text-center">
            <span>Price</span>
          </div>
          <div className="new-table--row--item light w10 no-shadow text-center">
            <span>Qty</span>
          </div>
          <div className="new-table--row--item light w15 no-shadow text-center">
            <span>Total</span>
          </div>
          <div className="new-table--row--item light w10 no-shadow" />
        </div>

        {all_packing.map((item) => {
          if (selected.includes(item.id))
            return <QuickViewItem key={item.id} item={item} qty={fields[item.id]} onChangeQty={onChangeQty} onDeleteFromSave={onDeleteFromSave} />;
          return null;
        })}

        <div className="new-table--row">
          <div className="new-table--row--item">
            <span>Total Price:</span>
          </div>
          <div className="new-table--row--item light fw-700">
            <span>${total}</span>
          </div>
        </div>
      </div>

      <div className="buttons">
        <button type="button" className="cancel" onClick={onClearPacking}>
          Clear all
        </button>
        <button type="button" className="confirm" disabled={!selected.length} onClick={onSavePacking}>
          Save
        </button>
      </div>
    </Fragment>
  );
});

export default QuickView;

import React, { useCallback, useMemo, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { matchPath } from 'react-router';
import { useFormik } from 'formik';
import { routePaths } from 'config/routes';
import { useModal } from 'context/modal';
import { useChargesUpdate, useChargeCreate } from 'hooks/query/useJob';
import { useDefault } from 'hooks/query/useCommon';
import { ModalButton } from 'components/newComponents/Buttons';
import Modal from 'components/newComponents/Modal';
import ChargesItem from './ChargesItem';

import './styles.scss';

const Charges = () => {
  const { modalNames, close, info } = useModal();
  const { pathname } = useLocation();

  const {
    params: { id, tab },
  } = matchPath(pathname, { path: routePaths.JOBS });

  const {
    data: { charges: defaultItems },
  } = useDefault();

  const isVisible = info(modalNames.charges).visible;
  const type = info(modalNames.charges)?.params?.type;
  const companyId = info(modalNames.charges)?.params?.companyId;
  const jobCharges = info(modalNames.charges)?.params?.jobCharges;
  const onSuccess = info(modalNames.charges)?.params?.onSuccess;

  const { mutate: updateCharges } = useChargesUpdate(id, tab);
  const { mutate: createCharge, mutateAsync: createChargeAsync } = useChargeCreate();

  const closeHandler = useCallback(() => close(modalNames.charges), [close, modalNames.charges]);

  const charges = useMemo(() => {
    if (!jobCharges) return {};
    return jobCharges[type].reduce((acc, e) => {
      return { ...acc, [e.charges_id]: e };
    }, {});
  }, [jobCharges, type]);

  const defaultCharges = useMemo(() => {
    return defaultItems[companyId].reduce((acc, e) => {
      return { ...acc, [e.id]: { ...e, checked: !!charges[e.id] } };
    }, {});
  }, [defaultItems, companyId, charges]);

  const updateChargesHandler = useCallback(
    ({ items }) => {
      const isFinal = type === 'final';
      const prepared = Object.values(items)
        .filter((e) => e.checked)
        .map((e) => ({ job_id: id, charges_id: e.id }));

      if (onSuccess) {
        onSuccess(prepared);
        closeHandler();
        return;
      }

      updateCharges(
        {
          job_id: id,
          job_charges: {
            estimated: isFinal ? jobCharges['estimated'] : prepared,
            final: prepared,
          },
        },
        { onSuccess: closeHandler }
      );
    },
    [type, onSuccess, updateCharges, id, jobCharges, closeHandler]
  );

  const { values, setFieldValue, handleSubmit, handleChange } = useFormik({
    initialValues: { items: defaultCharges, custom: { name: '', amount: '' } },
    onSubmit: updateChargesHandler,
  });

  useEffect(() => {
    if (defaultCharges) setFieldValue('items', defaultCharges);
  }, [defaultCharges, setFieldValue]);

  const createChargeHandler = useCallback(() => {
    const { custom } = values;
    createCharge(custom);
  }, [createCharge, values]);

  const onChangeHandler = useCallback(
    (id) => {
      const { [id]: current, ...rest } = values.items;

      setFieldValue('items', { ...rest, [id]: { ...current, checked: !current.checked } });
    },
    [setFieldValue, values.items]
  );

  const createChargeAndAdd = async () => {
    const { custom } = values;

    const res = await createChargeAsync(custom);

    if (res?.data?.id) {
      const isFinal = type === 'final';

      const prepared = Object.values(defaultCharges)
        .filter((e) => e.checked)
        .map((e) => ({ job_id: id, charges_id: e.id }));
      prepared.push({ job_id: id, charges_id: res.data.id });

      updateCharges(
        {
          job_id: id,
          job_charges: {
            estimated: isFinal ? jobCharges['estimated'] : prepared,
            final: prepared,
          },
        },
        { onSuccess: closeHandler }
      );
    }
  };

  const isDisabledSave = useMemo(() => !(values.custom.amount && values.custom.name), [values]);

  return (
    <Modal style={{ width: 650 }} header="Charges" show={isVisible} onHide={closeHandler}>
      <form onSubmit={handleSubmit}>
        <div className="new-table modal">
          <div className="new-table--row">
            <div className="new-table--row--item">
              <span>Name</span>
            </div>
            <div className="new-table--row--item">
              <input type="text" placeholder="Enter name" name="custom.name" value={values.custom.name} onChange={handleChange} />
            </div>
          </div>

          <div className="new-table--row">
            <div className="new-table--row--item">
              <span>Amount</span>
            </div>
            <div className="new-table--row--item">
              <input type="text" placeholder="Enter amount" name="custom.amount" value={values.custom.amount} onChange={handleChange} />
            </div>
          </div>

          <div className="new-table--row">
            <div className="new-table--row--item no-padding">
              <button type="button" className="main flex-grow-1 p20" disabled={isDisabledSave} onClick={createChargeAndAdd}>
                Save and Add
              </button>
            </div>
            <div className="new-table--row--item no-padding">
              <button type="button" className="main flex-grow-1 p20" disabled={isDisabledSave} onClick={createChargeHandler}>
                Save
              </button>
            </div>
          </div>

          <div className="new-table--row bcg-light">
            <div className="new-table--row--item">
              <span>Name</span>
            </div>
            <div className="new-table--row--item ">
              <span>Amount</span>
            </div>
          </div>
          <div className="charges-box">
            {Object.values(values.items).map((item) => (
              <ChargesItem key={item.id} item={item} type={type} handleChange={onChangeHandler} />
            ))}
          </div>
        </div>
        <div className="footer-buttons">
          <ModalButton title="Cancel" variant="cancel" onClick={closeHandler} />
          <ModalButton title="Add" variant="confirm" type="submit" />
        </div>
      </form>
    </Modal>
  );
};

export default Charges;

import React, { useState } from 'react';
import classNames from 'classnames';
import BookingTotal from './BookingTotal';

const UsersList = ({ users }) => {
  const [openedRowId, setOpenedRowId] = useState(null);

  const onRowClick = (id) => {
    if (openedRowId && openedRowId === id) {
      setOpenedRowId(null);
      return;
    }

    setOpenedRowId(id);
  };

  return (
    <div className="mb-3">
      {users.map((user) => {
        return (
          <div key={user.id} className={classNames('reports-table', { 'mb-3': openedRowId === user.id })}>
            <div className={classNames('reports-table-row', { 'reports-select': openedRowId === user.id })}>
              <div
                className="reports-table-column reports-table-column--title title-align-left"
                style={{ cursor: 'pointer' }}
                onClick={() => onRowClick(user.id)}
              >
                {user.label}
              </div>
            </div>
            {openedRowId === user.id && <BookingTotal list={user.data} />}
          </div>
        );
      })}
    </div>
  );
};

export default UsersList;

import React from 'react';

const NoResultsFound = () => {
  const styles = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    fontSize: 16,
  };

  return <div style={styles}>No results found...</div>;
};

export { NoResultsFound };

import axios from 'axios';
import showError from 'utils/errorHandler';

class Api {
  constructor() {
    this.serverApi = process.env.REACT_APP_API_URL;
    this.client = axios.create({ baseURL: this.serverApi });
  }

  contract = async (jobId) => {
    try {
      const response = await this.client.get(`/api/customerDocuments/ld_contract/${jobId}/sign`);

      return response;
    } catch (error) {
      console.log(error);
    }
  };

  insurance = async (job_id, insurance_option) => {
    try {
      const response = await this.client.post('api/customerDocuments/insuranceOption', {
        job_id,
        insurance_option,
      });

      return response;
    } catch (error) {
      console.log(error);
    }
  };

  sign = async (job_id, sign, document) => {
    try {
      await this.client.post(`/api/customerDocuments/saveSign`, {
        job_id,
        sign,
        document,
      });
      return true;
    } catch (error) {
      showError(error);
    }
  };
}

export default new Api();

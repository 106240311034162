import React, { Fragment, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useInfo, useTabInfo } from 'hooks/query/useJob';
import { useDefault } from 'hooks/query/useCommon';
import JobInfo from 'pages/Jobs/JobInfo';
import Estimate from 'pages/Jobs/Estimate';
import NotFoundRoute from '../NotFoundRoute';
import ExtraStop from './ExtraStop';
import Inventory from './Inventory';
import General from './General';
import ExpRes from './ExpRes';
import Rate from './Rate';

const Job = React.memo(() => {
  const { id, tab } = useParams();

  const { data: defaultItems } = useDefault();

  const { data: info, refetch: onRefreshInfo } = useInfo(id, { enabled: !!id });

  const { data: tabInfo, refetch: onRefreshTabInfo } = useTabInfo(id, tab, { enabled: !!(tab && id) });

  const isStorageTypeJob = info?.job_info?.type === 3;

  const current = useMemo(() => {
    switch (tab) {
      case 'general':
        return <General />;

      case 'extra-stop':
        return <ExtraStop />;

      case 'inventory':
        return <Inventory />;

      case 'rate':
        return <Rate />;

      case 'exp':
        return <ExpRes />;

      default:
        return <NotFoundRoute />;
    }
  }, [tab]);

  React.useEffect(() => {
    const listener = (data) => {
      if (data.job_id !== id) return;
      onRefreshTabInfo();
      onRefreshInfo();
    };

    window.socket.on('refresh_job', listener);
    window.socket.on('new_notification', listener);
    window.socket.on('job-booked', listener);
    window.socket.on('inventory_update', listener);
    window.socket.on('packing_update', listener);

    return () => {
      window.socket.off('refresh_job', listener);
      window.socket.off('new_notification', listener);
      window.socket.off('job-booked', listener);
      window.socket.off('inventory_update', listener);
      window.socket.off('packing_update', listener);
    };
  }, [id, onRefreshInfo, onRefreshTabInfo]);

  return (
    <Fragment>
      {info && defaultItems && (
        <Fragment>
          <JobInfo />

          {!isStorageTypeJob && <Estimate />}

          {tabInfo && current}
        </Fragment>
      )}
    </Fragment>
  );
});

export default Job;

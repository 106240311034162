import React from 'react';
import { StripeProvider } from 'react-stripe-elements';
import MyStoreCheckout from './stripe';
import PaymentHeader from './components/PaymentHeader';
import PaymentLocal from './components/PaymentLocal';
import PaymentStorage from './components/PaymentStorage';
import PaymentTotal from './components/PaymentTotal';
import { useForm } from './useForm';
import { useStripeKey } from './useStripeKey';
import './index.scss';

const Payment = () => {
  const url = new URL(window.location.href);
  const jobId = url.searchParams.get('job_id');
  const document = url.searchParams.get('document');

  const { payment, data, actions } = useForm(jobId, document);

  const { stripe } = useStripeKey(data?.company_id);

  if (!data) return null;

  return (
    <div className="container-fluid container-payment">
      <PaymentHeader jobTitle={data.job_title} email={data.email} phone={data.phone} />

      {(data.type === 'local' || data.type === 'long_distance') && (
        <PaymentLocal data={data} payment={payment} onChange={actions.changePickupPayType} />
      )}

      {data.type === 'storage' && <PaymentStorage data={data} payment={payment} actions={actions} />}

      <PaymentTotal total={payment.amount} />

      <StripeProvider stripe={stripe}>
        <MyStoreCheckout data={data} payment={payment} />
      </StripeProvider>

      {/* 
      TODO Storage
      TODO  data.storage_calc.type -> data.storage_calc.storage_rate_type ???
      {data.type === 'storage' && data.storage_calc.type === 'monthly' && (
        <div className="col-xs-12 col-sm-12">
          <p className="free-month">
            1st month - {parseFloat(data.storage_calc.first_month_charge) !== 0 ? '$' + data.storage_calc.first_month_charge : 'free'}
          </p>
        </div>
      )}
      */}
    </div>
  );
};

export default Payment;

import React, { useMemo, useCallback, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { useInfo } from 'hooks/query/useJob';
import { DropdownMenu, SelectLinkItem } from 'components/newComponents/DropDown';
import Icon from 'components/Icon';
import { useDefault } from 'hooks/query/useCommon';
import { useRateDataUpdate } from 'hooks/query/useJob';
import Select from 'components/newComponents/Select';
import { useSelectAgent } from 'hooks/useSelectAgent';
import { usePackingDescription } from 'hooks/query/useCommon';
import { getAppropriateValue } from 'helpers/getAppropriateValue';
import { rateTypeOptions, paymentTypeOptions } from 'helpers/constants';

const Summary = () => {
  const { id } = useParams();

  const { data: packingDescription } = usePackingDescription();

  const {
    data: { job_info: info, calc_info: calcInfo, related_jobs, job_packing: jobPacking, dates, ld_calc },
  } = useInfo(id);

  const isLongDistanceTypeJob = info?.type === 5;

  const isPickupJob = related_jobs?.long_delivery_job !== 0;

  const {
    data: { move_sizes: moveSizes },
  } = useDefault();

  const { mutate } = useRateDataUpdate(id);
  const { onMutate: updateInfo } = useSelectAgent(mutate);

  const moveSize = useMemo(() => {
    const companyId = info.company_id;
    const moveSizeId = info.move_size_id;
    return moveSizes[companyId].find((movSize) => movSize.id === moveSizeId);
  }, [info.company_id, info.move_size_id, moveSizes]);

  const moversOptions = useMemo(() => {
    return moveSize.qty_man_rates.map((e) => ({ value: e.movers, label: e.movers }));
  }, [moveSize]);

  const trucksOptions = useMemo(() => {
    return moveSize.trucks.map((e) => ({ value: e.trucks, label: e.trucks }));
  }, [moveSize]);

  const packingTotal = useMemo(() => {
    return jobPacking.final.reduce((sum, item) => sum + Number(item.qty) * Number(item.price), 0);
  }, [jobPacking]);

  const rate = useMemo(() => {
    if (!calcInfo) return 0;

    if (isLongDistanceTypeJob || isPickupJob) return Number(ld_calc?.rate_per_cf).toFixed(2);

    let rate = Number(calcInfo.manual_rate) !== 0 ? Number(calcInfo.manual_rate) : Number(calcInfo.rate);

    rate = rate + (Number(calcInfo.full_packing_rate) ?? 0) + (Number(calcInfo.credit_rate) ?? 0);

    return Number(rate).toFixed(2);
  }, [calcInfo, ld_calc, isLongDistanceTypeJob, isPickupJob]);

  const { setFieldValue, handleChange, values } = useFormik({
    initialValues: {
      rate: rate,
      total_cf: ld_calc?.total_cf ?? '',
      total_lbs: ld_calc?.total_lbs ?? '',
      packing_service: calcInfo.packing_service,
      rate_type: info.rate_type,
      final_packing_total: isLongDistanceTypeJob ? Number(calcInfo.est_total_cf) : packingTotal,
      estimated_total: isLongDistanceTypeJob ? Number(calcInfo.delivery_calc).toFixed(2) : calcInfo.estimated_total,
      movers: moversOptions.find((e) => Number(e.value) === Number(calcInfo.qty_man))?.value,
      trucks: trucksOptions.find((e) => Number(e.value) === Number(calcInfo.qty_trucks))?.value,
      final_total: isLongDistanceTypeJob
        ? Number(calcInfo.job_total_price)?.toFixed(2)
        : getAppropriateValue(calcInfo.manual_final_total, calcInfo.final_total)?.toFixed(2),
      payment_type: Number(calcInfo.credit_rate) > 0 ? paymentTypeOptions[1].value : paymentTypeOptions[0]?.value,
    },
    enableReinitialize: true,
  });

  const packingServiceOptions = useMemo(() => {
    if (!packingDescription) return [];
    const options = packingDescription.map((option) => ({ label: option.title, value: option.type }));
    const packingService = options.find((e) => e.value === calcInfo.packing_service);

    setFieldValue('packing_service', packingService ? packingService.value : '');

    return options;
  }, [calcInfo.packing_service, packingDescription, setFieldValue]);

  const saveChangesHandler = useCallback(
    (name, value, additional = {}) => {
      setFieldValue(name, value);
      updateInfo({ job_id: info.id, [name]: value, ...additional });
    },
    [info.id, setFieldValue, updateInfo]
  );

  const packingOptions = useMemo(() => {
    return packingServiceOptions?.map((e) => (
      <SelectLinkItem
        key={e.value}
        label={e.label}
        value={e.value}
        current={values.packing_service}
        onClick={(value) => saveChangesHandler('packing_service', value)}
      />
    ));
  }, [packingServiceOptions, saveChangesHandler, values.packing_service]);

  return (
    <div className="job_details__item">
      <h3 className="header table-header-shadow">Rate/Total</h3>

      <div className="new-table">
        <form>
          <div className="new-table--row">
            <div className="new-table--row--item">
              <Icon icon="rate_type" size={22} title="Rate Type" />
              <Select
                name="rate_type"
                options={rateTypeOptions}
                value={values.rate_type}
                onChange={(e) => saveChangesHandler('rate_type', e.value)}
              />
            </div>
            <div className="new-table--row--item">
              <Icon icon="payment_type" size={22} title="Payment Type" />
              <Select
                name="payment_type"
                options={paymentTypeOptions}
                value={values.payment_type}
                onChange={(e) => saveChangesHandler('payment_type', e.value)}
              />
            </div>
          </div>

          <div className="new-table--row">
            <div className="new-table--row--item">
              <Icon icon="movers" size={22} title="Movers" />
              <Select
                name="movers"
                options={moversOptions}
                value={values.movers}
                onChange={(e) => saveChangesHandler('movers', e.value, { required_pickup: dates.required_pickup })}
              />
            </div>
            <div className="new-table--row--item">
              <Icon icon="trucks" size={22} title="Trucks" />
              <Select name="trucks" options={trucksOptions} value={values.trucks} onChange={(e) => saveChangesHandler('trucks', e.value)} />
            </div>
          </div>

          {info.move_type === 2 || isPickupJob ? (
            <Fragment>
              <div className="new-table--row">
                <div className="new-table--row--item no-padding">
                  <div className="new-table--row">
                    <div className="new-table--row--item">
                      <Icon icon="cash" size={22} title="Rate" />
                      <input
                        type="text"
                        name="rate"
                        // disabled={parseInt(info.rate_type) === 2}
                        value={values.rate}
                        onChange={handleChange}
                        onBlur={(e) => saveChangesHandler(e.target.name, e.target.value)}
                      />
                    </div>

                    {ld_calc?.calc_type === 'per_cf' ? (
                      <div className="new-table--row--item">
                        <Icon icon="foot" size={22} title="CF" />

                        <input
                          type="text"
                          name="total_cf"
                          value={values.total_cf}
                          onChange={handleChange}
                          onBlur={(e) => saveChangesHandler(e.target.name, e.target.value)}
                        />
                      </div>
                    ) : (
                      <div className="new-table--row--item">
                        <Icon icon="weight" size={22} title="LBS" />

                        <input
                          type="text"
                          name="total_lbs"
                          value={values.total_lbs}
                          onChange={handleChange}
                          onBlur={(e) => saveChangesHandler(e.target.name, e.target.value)}
                        />
                      </div>
                    )}
                  </div>
                </div>

                <div className="new-table--row--item">
                  <div className="w25">
                    <DropdownMenu
                      triggerClass="p0"
                      trigger={
                        <Icon
                          icon="packing"
                          title={packingServiceOptions?.find((e) => e.value === values.packing_service)?.label}
                          className={values.packing_service}
                        />
                      }
                    >
                      {packingOptions}
                    </DropdownMenu>
                  </div>

                  <span>{ld_calc?.total_packing}</span>
                  {/* <span>{values.final_packing_total.toFixed(2)}</span> */}
                </div>
              </div>

              <div className="new-table--row">
                <div className="new-table--row--item">
                  <Icon icon="estimated_total" size={22} title="Pickup Total" />
                  <input type="text" disabled value={ld_calc?.pickup_total} />
                </div>
                <div className="new-table--row--item">
                  <Icon icon="final_total" size={22} title="Delivery Total" />
                  <input type="text" disabled value={ld_calc?.delivery_total} />
                </div>
              </div>
            </Fragment>
          ) : (
            <Fragment>
              <div className="new-table--row">
                <div className="new-table--row--item">
                  <Icon icon="cash" size={22} title="Rate" />
                  <input
                    type="text"
                    name="rate"
                    // disabled={parseInt(info.rate_type) === 2}
                    value={values.rate}
                    onChange={handleChange}
                    onBlur={(e) => saveChangesHandler(e.target.name, e.target.value)}
                  />
                </div>
                <div className="new-table--row--item">
                  <div className="w25">
                    <DropdownMenu
                      triggerClass="p0"
                      trigger={
                        <Icon
                          icon="packing"
                          title={packingServiceOptions?.find((e) => e.value === values.packing_service)?.label}
                          className={values.packing_service}
                        />
                      }
                    >
                      {packingOptions}
                    </DropdownMenu>
                  </div>

                  <span>{values.final_packing_total.toFixed(2)}</span>
                </div>
              </div>

              {isPickupJob ? (
                <div className="new-table--row">
                  <div className="new-table--row--item">
                    <Icon icon="estimated_total" size={22} title="Pickup Balance" />
                    <input type="text" disabled value={calcInfo.pickup_balance} />
                  </div>
                  <div className="new-table--row--item">
                    <Icon icon="final_total" size={22} title="Delivery Balance" />
                    <input type="text" disabled value={calcInfo.delivery_balance} />
                  </div>
                </div>
              ) : (
                <div className="new-table--row">
                  <div className="new-table--row--item">
                    <Icon icon="estimated_total" size={22} title="Estimated Total" />
                    <input
                      type="text"
                      name="estimated_total"
                      disabled
                      value={values.estimated_total}
                      onChange={handleChange}
                      onBlur={(e) => saveChangesHandler(e.target.name, e.target.value)}
                    />
                  </div>
                  <div className="new-table--row--item">
                    <Icon icon="final_total" size={22} title="Final total" />
                    <input
                      type="text"
                      name="final_total"
                      disabled
                      value={values.final_total}
                      onChange={handleChange}
                      onBlur={(e) => saveChangesHandler(e.target.name, e.target.value)}
                    />
                  </div>
                </div>
              )}
            </Fragment>
          )}
        </form>
      </div>
    </div>
  );
};

export default Summary;

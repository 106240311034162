import React, { Fragment, useState } from 'react';
import ChainItem from './ChainItem';
import AddChain from './EditChain';
import { useChainDelete, useChainSave } from 'hooks/query/useReminder';

const Chains = ({ chains }) => {
  const { mutate: deleteChains } = useChainDelete();
  const { mutate: saveChain } = useChainSave();

  const [addNewChain, setAddNewChain] = useState(false);
  const [activeEditChains, setActiveEditChains] = useState(null);
  const [activeChainReminders, setActiveChainReminders] = useState(null);

  const onEditClick = (id) => {
    if (activeEditChains === id) {
      setActiveEditChains(null);
      setActiveChainReminders(null);
    } else {
      setActiveEditChains(id);
      setActiveChainReminders(null);
    }
  };

  const onRemindersClick = (id) => {
    if (activeChainReminders) {
      setActiveChainReminders(null);
      setActiveEditChains(null);
    } else {
      setActiveChainReminders(id);
      setActiveEditChains(null);
    }
  };

  const onSaveChain = (data) => {
    saveChain(data, {
      onSuccess: () => {
        setActiveEditChains(null);
        setActiveChainReminders(null);
        setAddNewChain(false);
      },
    });
  };

  return (
    <Fragment>
      <div className="new-table--row">
        <div className="new-table--row--item no-side-shadows light col-1">#</div>
        <div className="new-table--row--item no-side-shadows light">Chain Name</div>
        <div className="new-table--row--item no-side-shadows light col-3">Type</div>
        <div className="new-table--row--item no-side-shadows light col-2">Actions</div>
      </div>

      {chains.map((chain) => (
        <ChainItem
          key={chain.id}
          chain={chain}
          activeEditChains={activeEditChains === chain.id}
          activeChainReminders={activeChainReminders === chain.id}
          onEditClick={() => onEditClick(chain.id)}
          onRemindersClick={() => onRemindersClick(chain.id)}
          onDelete={() => deleteChains({ id: chain.id })}
          onSave={onSaveChain}
        />
      ))}

      {addNewChain ? (
        <AddChain onSave={onSaveChain} />
      ) : (
        <div className="new-table--row">
          <div className="new-table--row--item justify-content-center">
            <button type="button" className="save-chain" onClick={() => setAddNewChain(true)}>
              Add New Chain
            </button>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default Chains;

import React, { Fragment, useCallback, useMemo } from 'react';
import { ModalButton } from 'components/newComponents/Buttons';
import { useLocation } from 'react-router-dom';
import { matchPath } from 'react-router';
import { routePaths } from 'config/routes';
import { useFormik } from 'formik';
import { useModal } from 'context/modal';
import { useSendComposedEmail } from 'hooks/query/useMail';
import { useInfo } from 'hooks/query/useJob';
import { useDefault } from 'hooks/query/useCommon';
import Modal from 'components/newComponents/Modal';
import * as Yup from 'yup';
import TimeSelect from 'components/TimeSelect';
import DateSelect from 'components/DateSelect';
import Select from 'components/newComponents/Select';
import { formatFullDate, formatTimeAPMeridiem } from 'helpers/dateHelper';

// const attachmentAcceptFileTypes = [
//   'image/jpeg',
//   'image/gif',
//   'image/png',
//   'image/bmp',
//   'application/pdf',
//   'image/x-eps',
//   'application/msword',
//   '.csv',
//   'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
//   'application/vnd.ms-excel',
//   'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
// ].join(',');

const Actions = () => {
  const { pathname } = useLocation();

  const {
    params: { id, tab },
  } = matchPath(pathname, { path: routePaths.JOBS });
  const {
    data: { mail_templates: mailTemplates, company: companies, users },
  } = useDefault();

  const { info, modalNames, close } = useModal();

  const isVisible = info(modalNames.newRemainder).visible;
  const params = info(modalNames.newRemainder).params;

  const { mutate: composedEmailSend } = useSendComposedEmail(id, tab);

  const {
    data: { job_info: jobInfo },
  } = useInfo(id);

  const company = useMemo(() => {
    return companies?.find((company) => company.id === jobInfo.company_id);
  }, [companies, jobInfo.company_id]);

  const saveHandler = useCallback(
    (values) => {
      const prepared = {
        ...values,
        date: formatFullDate(values.date),
        email_to: [values.email_to],
      };

      composedEmailSend({ job_id: id, ...prepared });
      close(modalNames.newRemainder);
    },
    [composedEmailSend, close, id, modalNames.newRemainder]
  );

  const emailFrom = useMemo(() => {
    if (+jobInfo?.agent_id > 0) {
      const currentAgent = users.agents.find((e) => e.id === +jobInfo.agent_id);
      if (currentAgent && currentAgent.emails.length > 0) return currentAgent.emails[0].email;
    }
    return '';
  }, [jobInfo.agent_id, users.agents]);

  const { values, handleSubmit, setFieldValue, handleChange, errors } = useFormik({
    initialValues: {
      subject: `[${jobInfo.title}] - ${company.company_name} - `,
      type: 'reminder',
      email_to: params?.email_to || '',
      email_from: emailFrom,
      time: '8:00 AM',
      date: null,
      template: null,
      attachments: [],
    },
    onSubmit: saveHandler,
    validationSchema: Yup.object().shape({
      email_to: Yup.string().required('Enter email!'),
    }),
  });

  const mailTemplatesOptions = useMemo(() => {
    if (!mailTemplates) return [];
    return mailTemplates.map((temp) => ({ value: temp.slug, label: temp.title }));
  }, [mailTemplates]);

  return (
    <Fragment>
      <Modal show={isVisible} style={{ width: 900 }} onHide={() => close(modalNames.newRemainder)} header={`Add new reminder ${jobInfo.title}`}>
        <form onSubmit={handleSubmit}>
          <div className="new-table modal">
            <div className="new-table--row">
              <div className="new-table--row--item">
                <input type="text" name="subject" value={values['subject']} onChange={handleChange} />
              </div>
            </div>
            <div className="new-table--row">
              <div className="new-table--row--item">
                <input type="text" name="email_to" value={values['email_to']} onChange={handleChange} />
                {errors?.['email_to'] && (
                  <span style={{ position: 'absolute' }} className="error">
                    {errors?.['email_to']}
                  </span>
                )}
              </div>
            </div>

            <div className="new-table--row">
              <div className="new-table--row--item select">
                <DateSelect
                  minDate={new Date()}
                  selected={values['date']}
                  onChange={({ name, date }) => setFieldValue(name, date)}
                  placeholder="Due date"
                  name="date"
                />
              </div>
              <div className="new-table--row--item select">
                <TimeSelect
                  name="time"
                  onChange={({ name, date }) => setFieldValue(name, formatTimeAPMeridiem(date))}
                  placeholder="* Select time"
                  value={values['time']}
                />
              </div>
            </div>

            <div className="new-table--row">
              <div className="new-table--row--item">
                <Select
                  options={mailTemplatesOptions}
                  placeholder="Templates"
                  name="template"
                  value={values.template}
                  onChange={(option) => setFieldValue('template', option.value)}
                />
              </div>
            </div>
          </div>

          <div className="footer-buttons">
            <ModalButton title="Cancel" variant="cancel" onClick={() => close(modalNames.newRemainder)} />
            <ModalButton type="submit" title="Send" variant="confirm" disabled={!values.template || !values.date} />
          </div>
        </form>
      </Modal>
    </Fragment>
  );
};

export default Actions;

import React, { useCallback, useMemo } from 'react';
import { useDefault } from 'hooks/query/useCommon';
import { useMovingRate } from 'hooks/query/useMoving';
import { formatFullDBDate, formatTimeAPMeridiem } from 'helpers/dateHelper';
import { referralOptions, packingRateOptions, rateTypeOptions } from 'helpers/constants';
import RadioButton from 'components/newComponents/RadioButton';
import CheckBox from 'components/newComponents/CheckBox';
import Select from 'components/newComponents/Select';
import TimeSelect from 'components/TimeSelect';
import DateSelect from 'components/DateSelect';
import { useEffect } from 'react';

export const MainInfo = ({ handleChange, values, setFieldValue, isValidHandler }) => {
  const {
    data: { move_sizes: moveSizes, mail_templates: mailTemplates, company },
  } = useDefault();

  const { mutateAsync: movingRate } = useMovingRate();

  const currentMoveSizes = useMemo(() => {
    if (!values.company_id) return [];

    return moveSizes[values.company_id].reduce((obj, item) => Object.assign(obj, { [item.id]: item }), {});
  }, [moveSizes, values.company_id]);

  const mailTemplatesOptions = useMemo(() => {
    return mailTemplates.map((template) => ({
      value: template.slug,
      label: template.title,
    }));
  }, [mailTemplates]);

  const trucksQty = useMemo(() => {
    if (!values.move_size_id || !values.company_id) return [];
    return currentMoveSizes[values.move_size_id].trucks.map((truck) => ({
      value: +truck.trucks,
      label: +truck.trucks,
      default: +truck.default,
    }));
  }, [currentMoveSizes, values.company_id, values.move_size_id]);

  const moversQty = useMemo(() => {
    if (!values.move_size_id || !values.company_id) return [];

    return currentMoveSizes[values.move_size_id].qty_man_rates.map((mover) => ({
      value: mover.movers,
      label: mover.movers,
      default: mover.default,
    }));
  }, [currentMoveSizes, values.company_id, values.move_size_id]);

  const getMovingRate = useCallback(
    (params) => {
      movingRate(params, { onSuccess: ({ data }) => setFieldValue('default_rate', data) });
    },
    [movingRate, setFieldValue]
  );

  useEffect(() => {
    if (!values.move_size_id || !values.qty_man || !values.pickup_date || !values.pickup_time || !values.company_id) return;
    getMovingRate({
      move_size_id: values.move_size_id,
      qty_man: values.qty_man,
      pickup_date: values.pickup_date,
      pickup_time: values.pickup_time,
      company_id: values.company_id,
      credit_rate: values.credit_rate,
    });
  }, [getMovingRate, values.company_id, values.credit_rate, values.move_values_id, values.pickup_date, values.pickup_time, values.qty_man]);

  const companiesList = useMemo(() => {
    return company.map((company) => ({
      value: company.id,
      label: company.company_name,
    }));
  }, [company]);

  const moveSizesOption = useMemo(() => {
    if (!values.company_id) return [];
    return moveSizes[values.company_id].map((el) => {
      return {
        value: el.id,
        label: el.name,
        estimated_number: el.estimated_number,
        default_number_man: el.default_number_man,
      };
    });
  }, [moveSizes, values.company_id]);

  const onChangeMovSizeHandler = useCallback(
    ({ value, estimated_number, default_number_man }) => {
      setFieldValue('qty_trucks', trucksQty.find((truck) => truck.default)?.value || 1);
      setFieldValue('estimated_time', estimated_number);
      setFieldValue('qty_man', default_number_man);
      setFieldValue('move_size_id', value);
    },
    [setFieldValue, trucksQty]
  );

  const onChangeDoNotSend = useCallback(
    ({ target }) => {
      setFieldValue('do_not_send', target.checked ? 1 : 0);
      setFieldValue('mail_template', null);
    },
    [setFieldValue]
  );

  const onChangeCompanyIdHandler = useCallback(
    ({ value }) => {
      setFieldValue('company_id', value);
      setFieldValue('full_packing_rate_value', parseFloat(company[value].full_packing_rate).toFixed(2));
    },
    [company, setFieldValue]
  );

  return (
    <div className="new-table modal">
      <div className="new-table--row">
        <div className="new-table--row--item">
          <CheckBox
            className="justify-content-between"
            name="do_not_send"
            label="Do not send auto email"
            checked={values['do_not_send'] === 1}
            onChange={onChangeDoNotSend}
          />
        </div>
        <div className="new-table--row--item">
          <CheckBox
            className="justify-content-between"
            name="deny_self_booking"
            label="Deny self booking"
            checked={values['deny_self_booking'] === 1}
            onChange={({ target }) => setFieldValue('deny_self_booking', target.checked ? 1 : 0)}
          />
        </div>
      </div>
      {!values.do_not_send && (
        <div className="new-table--row">
          <div className="new-table--row--item">
            <p>Email template</p>
          </div>
          <div className="new-table--row--item">
            <Select
              name="mail_template"
              placeholder="Mail template"
              value={values['mail_template']}
              options={mailTemplatesOptions}
              onChange={({ value }) => setFieldValue('mail_template', value)}
            />
          </div>
        </div>
      )}

      <div className="new-table--row">
        <div className="new-table--row--item">
          <p>Follow up Date</p>
        </div>
        <div className="new-table--row">
          <div className="new-table--row--item">
            <DateSelect
              name="follow_up_date"
              selected={values['follow_up_date']}
              placeholder="Follow Up Date"
              onChange={({ name, date }) => setFieldValue(name, formatFullDBDate(date))}
              minDate={new Date()}
            />
          </div>
          <div className="new-table--row--item">
            <TimeSelect
              name="follow_up_time"
              value={values['follow_up_time']}
              placeholder="Follow Up Time"
              onChange={({ name, date }) => setFieldValue(name, formatTimeAPMeridiem(date))}
            />
          </div>
        </div>
      </div>
      <div className="new-table--row">
        <div className="new-table--row--item">
          <p>* Pickup Date</p>
        </div>
        <div className="new-table--row">
          <div className="new-table--row--item">
            <DateSelect
              name="pickup_date"
              minDate={new Date()}
              placeholder="Pick Up Date"
              selected={values['pickup_date']}
              error={isValidHandler('pickup_date')}
              onChange={({ name, date }) => setFieldValue(name, formatFullDBDate(date))}
            />
          </div>
          <div className="new-table--row--item">
            <TimeSelect
              name="pickup_time"
              value={values['pickup_time']}
              placeholder="Pick Up Time"
              onChange={({ name, date }) => setFieldValue(name, formatTimeAPMeridiem(date))}
            />
          </div>
        </div>
      </div>

      <div className="new-table--row">
        <div className="new-table--row--item">* Company</div>
        <div className="new-table--row--item">
          <Select
            name="company_id"
            options={companiesList}
            placeholder="Select company"
            value={values['company_id']}
            error={isValidHandler('company_id')}
            onChange={onChangeCompanyIdHandler}
          />
        </div>
      </div>

      <div className="new-table--row">
        <div className="new-table--row--item justify-content-between">
          <span>* Rate</span>
          {values.default_rate > 0 && (
            <span>${parseFloat(values.default_rate) + (values.full_packing_rate === 'true' ? parseFloat(values.full_packing_rate_value) : 0)}</span>
          )}
        </div>
        <div className="new-table--row--item">
          <Select
            name="rate_type"
            placeholder="Rate"
            options={rateTypeOptions}
            value={values['rate_type']}
            onChange={({ value }) => setFieldValue('rate_type', value)}
          />
        </div>
      </div>

      <div className="new-table--row">
        <div className="new-table--row--item">* Hear from</div>
        <div className="new-table--row--item">
          <Select
            name="hear_about"
            placeholder="Hear about from"
            options={referralOptions}
            value={values['hear_about']}
            error={isValidHandler('hear_about')}
            onChange={({ value }) => setFieldValue('hear_about', value)}
          />
        </div>
      </div>

      <div className="new-table--row">
        <div className="new-table--row--item">* Move size</div>
        <div className="new-table--row--item">
          <Select
            name="move_size_id"
            options={moveSizesOption}
            value={values['move_size_id']}
            placeholder="Select move siz"
            error={isValidHandler('move_size_id')}
            onChange={onChangeMovSizeHandler}
          />
        </div>
      </div>

      <div className="new-table--row">
        <div className="new-table--row--item">How many movers?</div>
        <div className="new-table--row--item">
          <Select
            name="qty_man"
            options={moversQty}
            value={values['qty_man']}
            error={isValidHandler('qty_man')}
            placeholder="Movers on job"
            onChange={({ value }) => setFieldValue('qty_man', value)}
          />
        </div>
      </div>

      <div className="new-table--row">
        <div className="new-table--row--item">How many trucks?</div>
        <div className="new-table--row--item">
          <Select
            name="qty_trucks"
            options={trucksQty}
            value={values['qty_trucks']}
            error={isValidHandler('qty_trucks')}
            placeholder="Trucks on job"
            onChange={({ value }) => setFieldValue('qty_trucks', value)}
          />
        </div>
      </div>

      <div className="new-table--row">
        <div className="new-table--row--item">Default Rate</div>
        <div className="new-table--row--item">{values.default_rate} $/hour</div>
      </div>

      <div className="new-table--row">
        <div className="new-table--row--item">
          <CheckBox
            name="credit_rate"
            className="justify-content-between"
            label="Add credit rate?"
            checked={values['credit_rate'] === 1}
            onChange={({ target }) => setFieldValue('credit_rate', target.checked ? 1 : 0)}
          />
        </div>
      </div>

      <div className="new-table--row">
        <div className="new-table--row--item">
          {packingRateOptions.map(({ value, label }) => (
            <RadioButton
              key={`${value}${label}`}
              name="full_packing_rate"
              value={value}
              title={label}
              current={values['full_packing_rate']}
              onChange={(e) => setFieldValue(e.target.name, e.target.value)}
            />
          ))}
        </div>
      </div>

      {values.full_packing_rate === 'customer' && (
        <div className="p-5">
          DISCLAIMER <br />
          Customer responsible for packing. Company is not liable for any damage
        </div>
      )}

      {values.full_packing_rate === 'on_demand' && (
        <div className="p-5">
          You will be charged for each item used at company discretion to protect your belongings. For ex, price of: <br />
          LARGE BOX – 8.50, <br />
          EXTRA LG BOX - 9.75
        </div>
      )}

      {values.full_packing_rate === 'true' && (
        <div className="p-5">For this company full packing rate is additional ${values.full_packing_rate_value} RATE per hour</div>
      )}
    </div>
  );
};

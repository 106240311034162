import React, { Fragment, useMemo } from 'react';
import { Formik, FieldArray, Field, getIn } from 'formik';
import * as Yup from 'yup';
import Select from 'components/newComponents/Select';
import './styles.scss';

const unitTemplate = {
  unit_code: '',
  unit_color: '',
  rate: '',
  unit_id: null,
  unit_number: '',
};

const UnitSizeForm = ({ jobId, jobUnitSizes, storageUnitSizes, onSubmit, onDelete }) => {
  const inititalValues = useMemo(() => {
    return {
      job_id: jobId,
      unit_sizes: jobUnitSizes ? jobUnitSizes : [],
    };
  }, [jobId, jobUnitSizes]);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={inititalValues}
      validationSchema={Yup.object().shape({
        unit_sizes: Yup.array().of(
          Yup.object().shape({
            unit_id: Yup.number().nullable().required('Size Must Be Specified'),
          })
        ),
      })}
      onSubmit={onSubmit}
    >
      {({ values, handleChange, setFieldValue, handleSubmit, dirty }) => {
        return (
          <form onSubmit={handleSubmit} className="storage-info">
            <h3 className="header table-header-shadow">Unit Size</h3>

            <div className="new-table">
              <FieldArray
                name="unit_sizes"
                render={({ remove, push }) => (
                  <Fragment>
                    <div className="new-table--row">
                      <div className="new-table--row--item">
                        <strong>Unit Size</strong>
                      </div>
                      <div className="new-table--row--item">
                        <strong>Unit Code</strong>
                      </div>
                      <div className="new-table--row--item">
                        <strong>Unit Rate</strong>
                      </div>
                      <div className="new-table--row--item">
                        <strong>Unit #</strong>
                      </div>
                      <div className="new-table--row--item no-side-shadows">
                        <strong>Unit Color</strong>
                      </div>
                      <div className="new-table--row--item no-side-shadows col-1"></div>
                    </div>

                    {values.unit_sizes.map((item, index) => {
                      return (
                        <Fragment key={index}>
                          <div className="new-table--row">
                            <div className="new-table--row--item select position-relative">
                              <Select
                                placeholder="Unit Size"
                                name={`unit_sizes[${index}].unit_id`}
                                value={item.unit_id}
                                options={storageUnitSizes}
                                disabled={Boolean(item.id)}
                                onChange={({ value }) => setFieldValue(`unit_sizes[${index}].unit_id`, value)}
                              />

                              <Field name={`unit_sizes[${index}].unit_id`}>
                                {({ form }) => {
                                  const error = getIn(form.errors, `unit_sizes[${index}].unit_id`);
                                  const touch = getIn(form.touched, `unit_sizes[${index}].unit_id`);

                                  return touch && error ? (
                                    <span style={{ position: 'absolute', bottom: 0, left: 20 }} className="error">
                                      {error}
                                    </span>
                                  ) : null;
                                }}
                              </Field>
                            </div>
                            <div className="new-table--row--item">
                              <input
                                type="text"
                                name={`unit_sizes[${index}].unit_code`}
                                placeholder="Unit Code"
                                value={values.unit_sizes?.[index]?.unit_code}
                                onChange={handleChange}
                              />
                            </div>
                            <div className="new-table--row--item">
                              <input
                                type="text"
                                name={`unit_sizes[${index}].rate`}
                                placeholder="Unit Rate"
                                value={values.unit_sizes?.[index]?.rate}
                                onChange={handleChange}
                              />
                            </div>
                            <div className="new-table--row--item">
                              <input
                                type="text"
                                name={`unit_sizes[${index}].unit_number`}
                                placeholder="Unit Number"
                                value={values.unit_sizes?.[index]?.unit_number}
                                onChange={handleChange}
                              />
                            </div>
                            <div className="new-table--row--item no-side-shadows">
                              <input
                                type="text"
                                name={`unit_sizes[${index}].unit_color`}
                                placeholder="Unit Color"
                                value={values.unit_sizes?.[index]?.unit_color}
                                onChange={handleChange}
                              />
                            </div>

                            <div className="new-table--row--item no-side-shadows col-1">
                              <button
                                type="button"
                                onClick={() => {
                                  remove(index);
                                  onDelete(item);
                                }}
                              >
                                Remove
                              </button>
                            </div>
                          </div>
                        </Fragment>
                      );
                    })}

                    <div className="new-table--row">
                      <div className="new-table--row--item no-side-shadows">
                        <button type="button" onClick={() => push(unitTemplate)}>
                          Add Unit
                        </button>
                      </div>
                      <div className="new-table--row--item no-shadow" />
                      <div className="new-table--row--item no-shadow" />
                      <div className="new-table--row--item no-shadow" />
                      <div className="new-table--row--item p-0 no-shadow col-1">
                        {dirty && (
                          <button type="submit" className="unit-size-submit">
                            Save
                          </button>
                        )}
                      </div>
                    </div>
                  </Fragment>
                )}
              />
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default UnitSizeForm;

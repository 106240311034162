import React, { useCallback } from 'react';
import { useLocation } from 'react-router';
import classNames from 'classnames';
import history, { updateRouteParams } from 'utils/history';
import routes, { routePaths } from 'config/routes';
import { useDisabledAlert, useMarkAsRead, useReadAllMails } from 'hooks/query/useCommon';
import { useModal } from 'context/modal';
import Swal from 'sweetalert2';
import DropdownMenu from 'components/DropdownMenu';

const NavbarRightSide = ({ setCountersType, components, counters }) => {
  const location = useLocation();
  const { open, modalNames } = useModal();

  const communications = (type, value = 1) => {
    const to = `${routes.COMMUNICATIONS}/${type}`;
    const currentLocation = location.pathname;

    if (currentLocation === to) {
      window.location.reload(false);
      return;
    }

    if (value) {
      history.push({ pathname: to });
    }
  };

  const { mutate: disabledAlert } = useDisabledAlert();
  const { mutate: markAsRead } = useMarkAsRead();
  const { mutate: readAllMails } = useReadAllMails();

  const alertDisable = (e) => {
    disabledAlert(e.target.name);
  };

  const get_job_list = (type) => {
    if (!location.pathname.includes('/client/jobs/' + type)) {
      history.push({ pathname: updateRouteParams(routePaths.JOBS, { type }) });
    }
  };

  const closeCommunicateClose = useCallback(() => open(modalNames.twilio), [open, modalNames.twilio]);

  const showDevice = useCallback(() => {
    open(modalNames.twilio);
  }, [modalNames.twilio, open]);

  const resetMailCounter = (event, type) => {
    event.stopPropagation();

    Swal.fire({
      text: 'Reset counter?',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Reset',
    })
      .then((result) => {
        if (result.isConfirmed) {
          return readAllMails({ type });
        }
      })
      .then((data) => {
        if (data?.data?.success) {
          window.location.reload(false);
        }
      });
  };

  const alertMarkAsRead = (type) =>
    Swal.fire({
      text: 'Reset counter?',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Reset',
    }).then((result) => result.isConfirmed && markAsRead({ type }));

  const get_alert_job = (type, value) => {
    if (value !== 0) {
      history.push({ pathname: updateRouteParams(routePaths.JOBS, { type: type }) });
    }
  };

  const counter_leads = counters?.lead?.leads + counters?.lead?.communications || 0;

  return (
    <ul className="navbar-menu ml-auto">
      <li className="navbar-menu--item">
        <DropdownMenu
          button={{
            iconName: 'lead',
            iconTitle: 'Lead',
            className: 'menu-button',
            spanClassName: `counter ${counter_leads > 0 ? 'new' : ''}`,
            spanTitle: counter_leads ?? 0,
          }}
          counterOnClick={get_job_list}
          counters={[
            {
              className: 'link',
              text: 'Call/SMS',
              onClick: () => communications('lead_communications', counters?.lead?.communications),
              counterValue: counters?.lead?.communications ?? 0,
            },
            { className: 'link', text: 'Leads', clickValue: 'lead', counterValue: counters?.lead?.leads ?? 0 },
          ]}
        />
      </li>
      <li className="navbar-menu--item">
        <DropdownMenu
          button={{
            iconName: 'presale',
            iconTitle: 'Presale',
            className: 'menu-button',
            spanClassName: `counter ${counters?.presale?.new > 0 ? 'new' : ''}`,
            spanTitle: counters?.presale?.new ?? 0,
            onClick: () => setCountersType('presale'),
          }}
          counterOnClick={get_job_list}
          counters={[
            { className: 'link', text: 'Follow up', clickValue: 'followUp', counterValue: counters?.presale?.follow_up ?? 0 },
            {
              className: 'link',
              text: 'New Message',
              clickValue: 'presale-new_message',
              counterValue: counters?.presale?.new ?? 0,
            },
            { className: 'link', text: 'Lost', clickValue: 'lost', counterValue: counters?.presale?.lost ?? 0 },
            { className: 'link', text: 'Dropped', clickValue: 'dropped', counterValue: counters?.presale?.dropped ?? 0 },
            { className: 'link', text: 'Recently open', clickValue: 'presale-new_message-read', counterValue: null },
          ]}
        />
      </li>
      <li className="navbar-menu--item">
        <DropdownMenu
          button={{
            iconName: 'postsale',
            iconTitle: 'Postsale',
            className: 'menu-button',
            spanClassName: `counter ${counters?.postsale?.new > 0 ? 'new' : ''}`,
            spanTitle: counters?.postsale?.new ?? 0,
            onClick: () => setCountersType('postsale'),
          }}
          counterOnClick={get_job_list}
          counters={[
            { className: 'link', text: 'All', clickValue: 'postsale', counterValue: counters?.postsale?.all ?? 0 },
            {
              className: 'link',
              text: 'New Message',
              clickValue: 'postsale-new_message',
              counterValue: counters?.postsale?.new ?? 0,
            },
            { className: 'link', text: 'Recently open', clickValue: 'postsale-new_message-read', counterValue: null },
            { className: 'link', text: 'COI', clickValue: 'postsale-coid', counterValue: counters?.postsale?.coi ?? 0 },
          ]}
        />
      </li>
      <li className="navbar-menu--item">
        <DropdownMenu
          button={{
            iconName: 'ongoing',
            iconTitle: 'Ongoing',
            className: 'menu-button',
            spanClassName: `counter ${counters?.ongoing?.new > 0 ? 'new' : ''}`,
            spanTitle: counters?.ongoing?.new ?? 0,
            onClick: () => setCountersType('ongoing'),
          }}
          counterOnClick={get_job_list}
          counters={[
            { className: 'link', text: 'All', clickValue: 'ongoing', counterValue: counters?.ongoing?.all ?? 0 },
            {
              className: 'link',
              text: 'New Message',
              clickValue: 'ongoing-new_message',
              counterValue: counters?.ongoing?.new ?? 0,
            },
            { className: 'link', text: 'Recently open', clickValue: 'ongoing-new_message-read', counterValue: null },
            { className: 'link', text: 'Claim', clickValue: 'ongoing-claim', counterValue: counters?.ongoing?.claim ?? 0 },
            { className: 'link', text: 'COI', clickValue: 'ongoing-coi', counterValue: counters?.ongoing?.coi ?? 0 },
          ]}
        />
      </li>
      <li className="navbar-menu--item">
        <DropdownMenu
          button={{
            iconName: 'completed',
            iconTitle: 'Completed',
            className: 'menu-button',
            spanClassName: `counter ${counters?.completed?.new > 0 ? 'new' : ''}`,
            spanTitle: counters?.completed?.new ?? 0,
            onClick: () => setCountersType('completed'),
          }}
          counterOnClick={get_job_list}
          counters={[
            { className: 'link', text: 'All', clickValue: 'completed', counterValue: counters?.completed?.all ?? 0 },
            {
              className: 'link',
              text: 'New Message',
              clickValue: 'completed-new_message',
              counterValue: counters?.completed?.new ?? 0,
            },
            { className: 'link', text: 'Recently open', clickValue: 'completed-new_message-read', counterValue: null },
            { className: 'link', text: 'Claim', clickValue: 'completed-claim', counterValue: counters?.completed?.claim ?? 0 },
          ]}
        />
      </li>
      <li className="navbar-menu--item">
        <DropdownMenu
          button={{
            iconName: 'communication',
            iconTitle: 'Communication',
            className: 'menu-button',
            spanClassName: `counter ${counters?.communication > 0 ? 'new' : ''}`,
            spanTitle: counters?.communication ?? 0,
          }}
          menu={
            <ul className="menu-list">
              <li>
                <button type="button" className="link" onClick={() => communications('all_my_calls')}>
                  Calls/SMS/VM
                  <span className={`counter ${counters?.communication > 0 ? 'new' : ''}`}>{counters?.communication ?? 0}</span>
                </button>
              </li>
              <li>
                <div className="link" onClick={() => communications('inbox_email')} style={{ cursor: 'pointer' }}>
                  <span>Email Inbox</span>
                  <button
                    type="button"
                    className={classNames('counter', { new: counters?.mails?.inbox > 0 })}
                    onClick={(e) => resetMailCounter(e, 2)}
                  >
                    {counters?.mails?.inbox}
                  </button>
                </div>
              </li>
              <li>
                <div className="link" onClick={() => communications('outbox_email')} style={{ cursor: 'pointer' }}>
                  <span>Email Outbox</span>
                  <button
                    type="button"
                    className={classNames('counter', { new: counters?.mails?.outbox > 0 })}
                    onClick={(e) => resetMailCounter(e, 1)}
                  >
                    {counters?.mails?.outbox}
                  </button>
                </div>
              </li>
              <li>
                <button type="button" className="link" onClick={closeCommunicateClose}>
                  Call Co-Workers
                </button>
              </li>
              <li>
                <button type="button" className="link" onClick={showDevice}>
                  Call Contacts
                </button>
              </li>
            </ul>
          }
        />
      </li>
      <li className="navbar-menu--item">
        <DropdownMenu
          button={{
            iconName: 'alert_log',
            iconTitle: 'Alert',
            className: 'menu-button',
            spanClassName: `counter ${counters?.alert?.all > 0 ? 'new' : ''}`,
            spanTitle: counters?.alert?.all ?? 0,
            onClick: () => setCountersType('alert'),
          }}
          menu={
            <ul className="menu-list menu-list__with-buttons">
              <li>
                <div className="link">
                  <span onClick={() => get_alert_job('alert-booked', counters?.alert?.booked)}>Booked</span>
                  <div className="link--submenu">
                    <span className="counter" onClick={() => alertMarkAsRead('job_booked')}>
                      {counters?.alert?.booked ?? 0}
                    </span>
                    <input type="checkbox" name="job_booked" checked={counters?.alert_statuses?.job_booked} onChange={alertDisable} />
                  </div>
                </div>
              </li>
              <li>
                <div className="link">
                  <span onClick={() => get_alert_job('alert-payment', counters?.alert?.payment)}>Payments</span>
                  <div className="link--submenu">
                    <span className="counter" onClick={() => alertMarkAsRead('job_payment')}>
                      {counters?.alert?.payment ?? 0}
                    </span>
                    <input type="checkbox" checked={counters?.alert_statuses?.job_payment} name="job_payment" onChange={alertDisable} />
                  </div>
                </div>
              </li>
              <li>
                <div className="link">
                  <span onClick={() => get_alert_job('alert-confirmed', counters?.alert?.confirmed)}>Confirmed</span>
                  <div className="link--submenu">
                    <span className="counter" onClick={() => alertMarkAsRead('job_confirmed')}>
                      {counters?.alert?.confirmed ?? 0}
                    </span>
                    <input type="checkbox" checked={counters?.alert_statuses?.job_confirmed} name="job_confirmed" onChange={alertDisable} />
                  </div>
                </div>
              </li>
              <li>
                <div className="link">
                  <span onClick={() => get_alert_job('alert-canceled', counters?.alert?.canceled)}>Canceled</span>
                  <div className="link--submenu">
                    <span className="counter" onClick={() => alertMarkAsRead('job_canceled')}>
                      {counters?.alert?.canceled ?? 0}
                    </span>
                    <input type="checkbox" checked={counters?.alert_statuses?.job_canceled} name="job_canceled" onChange={alertDisable} />
                  </div>
                </div>
              </li>
              <li>
                <div className="link">
                  <span onClick={() => get_alert_job('alert-rescheduled', counters?.alert?.rescheduled)}>Rescheduled</span>
                  <div className="link--submenu">
                    <span className="counter" onClick={() => alertMarkAsRead('job_rescheduled')}>
                      {counters?.alert?.rescheduled ?? 0}
                    </span>
                    <input type="checkbox" checked={counters?.alert_statuses?.job_rescheduled} name="job_rescheduled" onChange={alertDisable} />
                  </div>
                </div>
              </li>
              <li>
                <div className="link">
                  <span onClick={() => get_alert_job('alert-task', counters?.alert?.task)}>Tasks</span>
                  <div className="link--submenu">
                    <span className="counter" onClick={() => alertMarkAsRead('job_task')}>
                      {counters?.alert?.task ?? 0}
                    </span>
                    <input type="checkbox" checked={counters?.alert_statuses?.job_task} name="job_task" onChange={alertDisable} />
                  </div>
                </div>
              </li>
              <li>
                <div className="link">
                  <span onClick={() => get_alert_job('alert-claim', counters?.alert?.claim)}>Claims</span>
                  <div className="link--submenu">
                    <span className="counter" onClick={() => alertMarkAsRead('job_claim')}>
                      {counters?.alert?.claim ?? 0}
                    </span>
                    <input type="checkbox" checked={counters?.alert_statuses?.job_claim} name="job_claim" onChange={alertDisable} />
                  </div>
                </div>
              </li>
            </ul>
          }
        />
      </li>
    </ul>
  );
};

export default NavbarRightSide;

import moment from 'moment';
import { formatFullDate } from './dateHelper';

const getJobByDate = (list, date, key) => {
  return list.filter((job) => moment(formatFullDate(job.pickup_date))[key](date));
};

const getParams = (search) => {
  const searchParams = new URLSearchParams(search);

  const result = {};
  for (let pair of searchParams.entries()) {
    result[pair[0]] = pair[1];
  }
  return result;
};

export { getJobByDate, getParams };

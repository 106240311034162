import { useState } from 'react';

const createTemplate = (jobId) => {
  return {
    job_id: jobId,
    service_id: 0,
    origin_price: 0,
    dest_price: 0,
    name: '',
  };
};

export const useManualLongDistanceService = (params) => {
  const { jobId } = params;

  const [service, setService] = useState(null);

  const addService = () => {
    setService(createTemplate(jobId));
  };

  const removeService = () => {
    setService(null);
  };

  const onChange = (name, value) => {
    setService((prev) => {
      return { ...prev, [name]: value };
    });
  };

  return {
    service,
    actions: {
      addService,
      removeService,
      onChange,
    },
  };
};

import React from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import './TimeSelect.scss';

const TimeSelect = (props) => {
  const { value, onChange, interval = 30, minHours, maxHours, className, disabled, name, placeholder, error } = props;

  const min = minHours ? moment(new Date()).set('hour', minHours).set('minute', 0) : moment(new Date()).set('hour', 6).set('minute', 0);

  const max = !maxHours ? moment(new Date()).set('hour', 23).set('minute', 0) : moment(new Date()).set('hour', maxHours).set('minute', 0);

  const setHours = (dt, h) => {
    const s = h?.match(/\d+(:\d{2} | )(?:AM|PM|am|pm)$/g)?.[0]?.split(/[\s:]+/);

    const hours = s[0];
    const minutes = Number(s[1]) ? s[1] : 0;
    const period = !Number(s[2]) ? s[2] : '';

    dt.setHours(period?.trim()?.toLocaleLowerCase() === 'pm' && hours < 12 ? 12 + parseInt(hours, 10) : parseInt(hours, 10));
    dt.setMinutes(minutes);
  };

  let newTime = moment(value).isValid() ? new Date(value) : new Date();

  if (value && !value?.includes('Invalid')) {
    setHours(newTime, value);
  } else newTime = null;

  return (
    <div className="d-flex flex-column position-relative">
      <DatePicker
        selected={newTime}
        onChange={(date) => onChange({ name, date })}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={interval}
        timeCaption="Time"
        dateFormat="h:mm a"
        minTime={new Date(min)}
        maxTime={new Date(max)}
        className={className}
        disabled={disabled}
        name={name}
        placeholderText={placeholder}
        autoComplete="off"
      />
      {error && (
        <span style={{ position: 'absolute', bottom: -15 }} className="error">
          {error}
        </span>
      )}
    </div>
  );
};

export default TimeSelect;

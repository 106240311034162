import React, { useMemo } from 'react';
import { useFormik } from 'formik';
import { useParams } from 'react-router-dom';
import { useTabInfo, useInfo } from 'hooks/query/useJob';

const ExpResExpense = () => {
  const { id, tab } = useParams();

  const {
    data: { job_packing: jobPacking },
  } = useTabInfo(id, tab);

  const {
    data: { job_info: jobInfo, calc_info: calcInfo },
  } = useInfo(id);

  const finPackingTotal = useMemo(() => {
    const total =
      +jobInfo.status !== 6
        ? jobPacking.estimated.reduce((sum, item) => sum + parseInt(item.qty) * parseInt(item.price), 0)
        : jobPacking.final.reduce((sum, item) => sum + +item.qty * parseFloat(item.price), 0);

    return total * 0.25;
  }, [jobInfo, jobPacking]);

  const totalExp = useMemo(() => {
    return (
      parseFloat(calcInfo.day_labor) +
      parseFloat(calcInfo.parking) +
      parseFloat(calcInfo.tickets) +
      parseFloat(calcInfo.tolls) +
      parseFloat(calcInfo.transportation) +
      finPackingTotal
    );
  }, [calcInfo, finPackingTotal]);

  const { values, handleChange } = useFormik({
    initialValues: {
      day_labor: calcInfo.day_labor,
      tickets: calcInfo.tickets,
      tolls: calcInfo.tolls,
      parking: calcInfo.parking,
      transportation: calcInfo.transportation,
      total_exp: 0,
    },
  });

  return (
    <div className="new-table shadow w49">
      <div className="new-table--row">
        <h3 className="header new-table--row--item">Expense</h3>
      </div>

      <div className="new-table--row">
        <div className="new-table--row--item">
          <span>Packing cost</span>
        </div>
        <div className="new-table--row--item select">
          <b>{finPackingTotal.toString()}</b>
        </div>
      </div>
      <div className="new-table--row">
        <div className="new-table--row--item">
          <span>Day labor</span>
        </div>
        <div className="new-table--row--item select">
          <input className="no-padding" value={values.day_labor} name="day_labor" onChange={handleChange} />
        </div>
      </div>
      <div className="new-table--row">
        <div className="new-table--row--item">
          <span>Tickets</span>
        </div>
        <div className="new-table--row--item select">
          <input className="no-padding" value={values.tickets} name="tickets" onChange={handleChange} />
        </div>
      </div>
      <div className="new-table--row">
        <div className="new-table--row--item">
          <span>Tolls</span>
        </div>
        <div className="new-table--row--item select">
          <input className="no-padding" value={values.tolls} name="tolls" onChange={handleChange} />
        </div>
      </div>
      <div className="new-table--row">
        <div className="new-table--row--item">
          <span>Insurance</span>
        </div>
        <div className="new-table--row--item select">
          <b>{calcInfo.insurance}</b>
        </div>
      </div>
      <div className="new-table--row">
        <div className="new-table--row--item">
          <span>Parking</span>
        </div>
        <div className="new-table--row--item select">
          <input className="no-padding" value={values.parking} name="parking" onChange={handleChange} />
        </div>
      </div>
      <div className="new-table--row">
        <div className="new-table--row--item">
          <span>Transportation</span>
        </div>
        <div className="new-table--row--item select">
          <input className="no-padding" value={values.transportation} name="transportation" onChange={handleChange} />
        </div>
      </div>
      <div className="new-table--row">
        <div className="new-table--row--item">
          <span>Total</span>
        </div>
        <div className="new-table--row--item select">
          <b>{totalExp}</b>
        </div>
      </div>
    </div>
  );
};

export default ExpResExpense;

import { useMutation } from 'react-query';
import { useApi } from 'context/api';
import { useAuth } from 'context/auth';
import history from 'utils/history';
import ROUTES from 'config/routes';

export const useLogin = () => {
  const { user, setToken } = useApi();
  const { setAuth } = useAuth();

  return useMutation(user.login, {
    onSuccess: async ({ data }) => {
      const { user, token_type: type, access_token: token } = data;
      setToken(`${type} ${token}`);
      setAuth({ user, type, token });
    },
    onError: () => history.push(ROUTES.LOGIN),
  });
};

export const useActivity = (id) => {
  const { user } = useApi();
  return useMutation(user.activity);
};

export const useRefresh = (id) => {
  const { user } = useApi();
  return useMutation(user.refresh);
};

import React, { useState, Fragment } from 'react';
import { useHistory, generatePath } from 'react-router-dom';
import Pagination from 'react-paginate';

import NotesItem from './NotesItem/NotesItem';
import Icon from 'components/Icon';
import { routePaths } from 'config/routes';
import { useGetImportantNotes } from 'hooks/query/useImportantNotes';

function ImportantList() {
  const perPage = 20;
  const [page, setPage] = useState(0);

  const history = useHistory();

  const { data: notes = [] } = useGetImportantNotes();

  const handlePaginationChange = (page) => setPage(page.selected);

  const create = () => {
    history.push({
      pathname: generatePath(routePaths.NOTES, { type: 'new' }),
    });
  };

  return (
    <Fragment>
      <div className="new-table mb-4">
        <div className="new-table--row">
          <div className="new-table--row--item no-side-shadows">
            <button type="button" onClick={create}>
              <Icon icon="add" />
            </button>
          </div>
        </div>
      </div>

      <div className="new-table">
        {notes.slice(page * perPage, (page + 1) * perPage).map((item) => (
          <NotesItem key={item.id} {...item} />
        ))}
      </div>

      <Pagination
        pageCount={Math.ceil(notes.length / perPage)}
        pageRangeDisplayed={5}
        marginPagesDisplayed={2}
        containerClassName="email-pagination pagination"
        subContainerClassName="pages pagination"
        activeClassName="active"
        onPageChange={handlePaginationChange}
      />
    </Fragment>
  );
}

export default ImportantList;

import React, { memo, useState, useCallback } from 'react';
import Icon from 'components/Icon';

const JobColumn = memo(({ date, children }) => {
  const [isVisible, setIsVisible] = useState(true);

  const togglePast = useCallback(() => setIsVisible((pre) => !pre), []);

  return (
    <div key={date} className="day">
      <div className="day-head" onClick={togglePast} style={{ justifyContent: 'flex-start' }}>
        <p>
          <Icon size={15} icon={!isVisible ? 'add' : 'remove'} style={{ marginRight: 30 }} />
          {date}&nbsp;
        </p>
        <span className="day-head__count" style={{ marginLeft: 20 }}>
          {React.Children.count(children)}
        </span>
      </div>
      {isVisible && children}
    </div>
  );
});

export default JobColumn;

import React from 'react';
import { useModal } from 'context/modal';
import { ModalButton } from 'components/newComponents/Buttons';
import Select from 'components/newComponents/Select';
import Modal from 'components/newComponents/Modal';
import Input from 'components/newComponents/Input';
import { preventArrowUpDown } from 'utils/preventArrowUpDown';
import { useFetchData } from './hooks/useFetchData';
import { useForm } from './hooks/useForm';
import { useSubmit } from './hooks/useSubmit';
import { prepareInitialValues, createInitialValues, prepareCarrierDriversOptions, prepareCarriersOptions } from './utils';

const ChangeJobCarrier = () => {
  const { info, close, open, modalNames } = useModal();

  const isVisible = info(modalNames.changeJobCarrier).visible;

  const params = info(modalNames.changeJobCarrier).params;
  const jobCarrier = params.jobCarrier;
  const jobId = params.job_id;
  const onSubmitLocal = params.onSubmit;
  const makeApiRequestOnSubmit = Boolean(params.makeApiRequestOnSubmit);

  const closeHandler = () => close(modalNames.changeJobCarrier);

  const { onSubmit, isMutationRunning } = useSubmit({
    makeApiRequestOnSubmit,
    onSubmitLocal,
  });

  const { values, errors, touched, dirty, actions } = useForm({
    initialValues: jobCarrier ? prepareInitialValues(jobCarrier) : createInitialValues(jobId),
    onSubmit,
  });

  const { carriers, carrierDrivers } = useFetchData({ carrier_id: values.carrier_id });

  const createDriver = () => {
    if (!values.carrier_id) return;

    open(modalNames.createCarrierDriver, {
      carrier_id: values.carrier_id,
      onSuccess: (id) => actions.setFieldValue('carrier_driver_id', id),
    });
  };

  return (
    <Modal show={isVisible} style={{ width: 650 }} header="Change Carrier" onHide={closeHandler}>
      <div className="new-table modal">
        <div className="new-table--row">
          <div className="new-table--row--item no-side-shadows select">
            <Select
              name="carrier_id"
              placeholder="Select carrier"
              value={values.carrier_id}
              error={errors.carrier_id && touched.carrier_id ? errors.carrier_id : undefined}
              options={prepareCarriersOptions(carriers)}
              onChange={actions.handleChangeCarrier}
            />
          </div>
        </div>

        <div className="new-table--row">
          <div className="new-table--row--item no-side-shadows select">
            <Select
              name="carrier_driver_id"
              placeholder="Select driver"
              value={values['carrier_driver_id']}
              disabled={!values['carrier_id']}
              options={prepareCarrierDriversOptions(carrierDrivers)}
              error={errors.carrier_driver_id && touched.carrier_driver_id ? errors.carrier_driver_id : undefined}
              onChange={({ value }) => actions.setFieldValue('carrier_driver_id', value)}
            />

            <button className="main flex flex-shrink-0" type="button" onClick={createDriver}>
              Create Driver
            </button>
          </div>
        </div>

        <div className="new-table--row">
          <div className="new-table--row--item no-side-shadows">
            <Input
              type="number"
              name="cf_price"
              value={values.cf_price}
              className="ml-2"
              placeholder="Enter CF Price"
              disabled={!values['carrier_driver_id']}
              error={errors.cf_price && touched.cf_price ? errors.cf_price : undefined}
              onKeyDown={preventArrowUpDown}
              onChange={actions.handleChange}
            />
          </div>
        </div>
      </div>

      <div className="footer-buttons">
        <ModalButton title="Cancel" variant="cancel" onClick={closeHandler} />

        <ModalButton title="Confirm" variant="confirm" disabled={isMutationRunning || !dirty} onClick={() => actions.handleSubmit()} />
      </div>
    </Modal>
  );
};

export default ChangeJobCarrier;

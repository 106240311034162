import React, { useCallback } from 'react';
import { useFormik } from 'formik';

const SendSmsSwalModal = ({ onSubmit, onCancel }) => {
  const { handleSubmit, handleChange, setFieldValue, values } = useFormik({
    initialValues: {
      imagePreviewUrl: null,
      image: null,
      message: '',
    },
    onSubmit,
  });

  const handleImageChangeIn = useCallback(
    (e) => {
      e.preventDefault();
      let reader = new FileReader();
      const image = e.target.files[0];
      reader.onloadend = () => {
        setFieldValue('imagePreviewUrl', reader.result);
        setFieldValue('image', image);
      };
      reader.readAsDataURL(image);
    },
    [setFieldValue]
  );

  return (
    <form onSubmit={handleSubmit}>
      <textarea
        placeholder="Send sms from here"
        style={{ width: 330, height: 150, marginBottom: 20 }}
        name="message"
        value={values.message}
        onChange={handleChange}
      />
      <label className="sms-team-label file">
        Choose pdf file or image(max 10Mb)
        <input
          size="10485760"
          onChange={handleImageChangeIn}
          accept="image/jpeg,image/gif,image/png,image/bmp,application/pdf,image/x-eps"
          type="file"
        />
      </label>
      {values.image &&
        (values.image.type === 'application/pdf' ? (
          <embed src={values.imagePreviewUrl} width="400px" height="500px" />
        ) : (
          <img src={values.imagePreviewUrl} alt="preview img" style={{ width: 450 }} />
        ))}
      <div className="swal2-actions">
        <button type="submit" className="swal2-confirm swal2-styled">
          Send
        </button>
        <button type="button" className="swal2-cancel swal2-styled" onClick={onCancel}>
          Cancel
        </button>
      </div>
    </form>
  );
};

export default SendSmsSwalModal;

import React, { createContext, useMemo, useContext, useCallback } from 'react';
import Axios from 'axios';
import { useAuth } from '../auth';
import APIError from './APIError';

export const ApiContext = createContext(undefined);

export const ApiProvider = ({ children }) => {
  const { setAuth } = useAuth();

  const api = useMemo(() => {
    const api = Axios.create({
      timeout: 60000,
      baseURL: `${process.env.REACT_APP_API_URL}/api`,
      headers: {
        Authorization: localStorage.getItem('token'),
      },
      validateStatus: (status) => status >= 200 && status < 300,
    });

    api.interceptors.response.use(
      (response) => {
        return Promise.resolve(response);
      },
      async (res) => {
        const { response, isAxiosError } = res;

        if (response?.status === 401) {
          setAuth(null);
          return Promise.reject(response);
        }
        if (isAxiosError) return Promise.reject(response);
        return Promise.reject(response);
      }
    );
    api.interceptors.request.use(
      (response) => {
        return Promise.resolve(response);
      },
      (res) => {
        const { message, config, code, request, response, isAxiosError } = res;
        throw new APIError(message, config, code, request, response, isAxiosError);
      }
    );

    return api;
  }, [setAuth]);

  const setToken = useCallback(
    (token) => {
      api.defaults.headers.Authorization = token;
    },
    [api.defaults.headers]
  );

  const userRequests = {
    refresh: () => api.get('auth/refresh'),
    login: (data) => api.post('/auth/login', data),
    activity: (id) => api.post('/updateUserActivity', { user_id: id }),
  };

  const commonRequests = {
    default: () => api.get('/baseData/getDefaultItems'),
    saveRates: (data) => api.post('/baseData/saveRates', data),
    disabledAlert: (type) => api.post('/alert/disabled', { type }),
    readAlert: (job_id) => api.post('/alert/readAlert', { job_id }),
    markAsRead: (data) => api.post('/alert/markAsRead', data),
    readAllMails: (data) => api.post('/alert/readAllMails', data),
    ratesByDate: (params) => api.get('/baseData/getByDate', { params }),
    getStatusesTemplate: () => api.get('/baseData/statusesTemplateGet'),
    packingDescription: () => api.get('/baseData/getPackingDescription'),
    distanceForm: (params) => api.get('/baseData/getDistanceForm', { params }),
    quickMovingQuote: (data, options) => api.post(`/quick_moving_quote`, data),
    saveFiles: (data, options) => api.post(`/baseData/saveFiles`, data, options),
    saveStatusesTemplate: (data) => api.post(`/baseData/statusesTemplateSave`, data),
    menuCounters: (params) => api.get('/counters/getCounters'),
    menuCounterByType: (params) => api.get('/counters/getMenuCounterByType', { params }),
  };

  const jobsRequests = {
    lost: () => api.get('/jobs/getLostJobs'),
    list: (params) => api.get('/job-list/get', { params }),
    refuseReasons: (data) => api.post('/jobs/saveRefuseReasons', data),
    getPopupInfo: (jobId) => api.get(`/job-list/getPopupInfo/${jobId}`),
  };

  const jobRequests = {
    info: ({ id }) => api.get(`/job-info/get/${id}`),
    clone: (data) => api.post('/jobs/cloneJob', data),
    timeUpdate: (data) => api.post('/job/updateJobTime', data),
    updateFees: (data) => api.post('/job/updateJobFees', data),
    updateLDFees: (data) => api.post('/job/updateLDFees', data),
    changeStatus: (data) => api.post('/job/changeStatus', data),
    tabInfo: ({ id, tab }) => api.get(`/job-info/${id}/${tab}`),
    createPayments: (data) => api.post('/payments/create', data),
    notesUpdate: (data) => api.post('/job/updateJobNotes', data),
    deleteJobInternalNote: (data) => api.post('/job/deleteJobInternalNotesHeaders', data),
    addManualTips: (data) => api.post('/job/addManualTips', data),
    reschedule: (data) => api.post('job/rescheduleByAgent', data),
    changeMoveSize: (data) => api.post('job/changeMoveSize', data),
    createCharge: (data) => api.post('/job/createNewCharge', data),
    savePacking: (data) => api.post(`/job/updateJobPacking`, data),
    coiComplete: (data) => api.post(`/customer/coi/complete`, data),
    updateRateData: (data) => api.post(`/job/updateRateData`, data),
    saveAdditionalInfo: (data) => api.post('/job/saveAdditionalInfo', data),
    updateCharges: (data) => api.post('/job/updateJobCharges', data),
    saveInventory: (data) => api.post(`/job/updateJobInventory`, data),
    saveInventoryCondition: (data) => api.post(`/job/updateJobInventoryCondition`, data),
    changeServiceType: (data) => api.post(`/job/changeServiceType`, data),
    managementAssign: (data) => api.post('/job/assignManagement', data),
    updateDiscount: (data) => api.post('/job/updateJobDiscounts', data),
    setManualValues: (data) => api.post('/job/updateManualValues', data),
    updateInsurance: (data) => api.post('/job/updateJobInsurance', data),
    updateSelfBooking: (data) => api.post('/job/updateSelfBooking', data),
    changeJobToDropped: (data) => api.post('/jobs/changeJobToDropped', data),
    readCommunication: (data) => api.post('/jobs/read_job_communications', data),
    confirmRescheduleRateChange: (data) => api.post('/job/confirmRateChange', data),
    communicationsList: (params) => api.get('/jobs/getJobCommunicationsList', { params }),
    getCommunicationCounter: (jobId) => api.get(`/jobs/getCommunicationCounter?job_id=${jobId}`),
    saveInternalNotesHeaders: (data) => api.post('/job/saveJobInternalNotesHeaders', data),
    priority: ({ id, priority }) => api.post('/jobs/savePriority', { job_id: id, priority }),
    jobUpdateToStorage: (data) => api.post('/job/storage/updateToStorage', data),
    changeJobStorage: (data) => api.post('/job/storage/changeJobStorage', data),
    changeStorageEndDate: (data) => api.post('/job/storage/changeStorageEndDate', data),
    getJobUnits: (jobId) => api.get(`/job/storage/getJobUnits/${jobId}`),
    updateJobUnits: (data) => api.post(`/job/storage/updateStorageJobUnitSize`, data),
    deleteStorageUnitSize: (data) => api.post(`/job/storage/deleteStorageUnitSize`, data),
    updateStorageFee: (data) => api.post(`/job/storage/updateStorageFee`, data),
    updateManualStorageRate: (data) => api.post(`/job/storage/addManualStorageRate`, data),
    updateManualStorageTotalPrice: (data) => api.post(`/job/storage/changeStorageTotalPrice`, data),
    changeElevatorStatus: (data) => api.post(`/job/changeElevatorStatus`, data),
    getOngoingStorageJobs: () => api.get('/job/storage/getOngoingStorageJobs'),
    addLocalJobInventoryToOngoingStorageJob: (data) => api.post(`/job/storage/addLocalJobInventoryToOngoingStorageJob`, data),
    addOptionalAccessorialServices: (data) => api.post(`/job/addOptionalAccessorialServices`, data),
    addHouseholdGoods: (data) => api.post(`/job/addHouseholdGoods`, data),
    addOtherServices: (data) => api.post(`/job/addOtherServices`, data),
    getLDTariff: (data) => {
      return api.get(`/baseData/getLDTariff/${data.company_id}/${data.from_state}/${data.to_state}`);
    },
    sendStorageStripeInvoice: ({ job_id, company_id }) => api.get(`/stripe/stripeInvoice?job_id=${job_id}&company_id=${company_id}`),
    getCarrierDrivers: (carrier_id) => api.post(`/job/getCarrierDrivers`, { carrier_id }),
    createCarrierDriver: ({ carrier_id, name, phone }) => api.post(`/job/addCarrierDriver`, { carrier_id, name, phone }),
    addJobCarrier: ({ carrier_id, job_id, carrier_driver_id, cf_price }) => {
      return api.post(`/job/addCarrier`, { carrier_id, job_id, carrier_driver_id, cf_price });
    },
  };

  const extraStopsRequests = {
    add: (data) => api.post('/job/extra_stop/save', data),
    update: (data) => api.post('/job/extra_stop/update', data),
    delete: (data) => api.post('/job/extra_stop/delete', data),
  };

  const movingRequests = {
    info: () => api.get('/getMovingInfo'),
    save: (data) => api.post('/moving_info/update', data),
    rate: (params) => api.get('/baseData/rate', { params }),
  };

  const mailRequests = {
    send: (data, options) => api.post('/mail/send', data, options),
    autoEmail: (data) => api.post('/mail/changeAutoEmail', data),
    template: (params) => api.get('mail/getTemplate', { params }),
    getEmail: (params) => api.get('/mail/getMailInfo', { params }),
    templates: (params) => api.get('/mail/getMailTemplates', { params }),
    sendComposed: (data, options) => {
      return api.post('/mail/sendComposed', data, options);
    },
    templatesByStatus: (params) => api.get('/mail/getMailTemplatesByStatus', { params }),
  };

  const taskRequests = {
    update: (data) => api.put('/job-tasks/update', data),
    delete: (params) => api.delete('/job-tasks/delete', { params }),
    create: (data, options) => api.post('/job-tasks/create', data, options),
  };

  const claimRequests = {
    delete: (id) => api.delete(`/job_claims/${id}`),
    close: (id) => api.post(`/job_claims/${id}/close`),
    create: (data) => api.post('/job_claims', data),
    update: ({ id, ...data }, options) => api.put(`/job_claims/${id}`, data, options),
  };

  const reminderRequests = {
    chains: () => api.get('reminders/getChains'),
    save: (data) => api.post('reminders/saveReminder', data),
    saveChain: (data) => api.post('reminders/saveChain', data),
    applyChain: (data) => api.post('reminders/applyChain', data),
    info: (params) => api.get('reminders/getReminder', { params }),
    list: (params) => api.get('reminders/getReminders', { params }),
    delete: (params) => api.delete('reminders/deleteReminder', { params }),
    deleteChain: (params) => api.delete('reminders/deleteChain', { params }),
    saveChainReminder: (data) => api.post('reminders/saveChainReminder', data),
    createFollowUpChain: (data) => api.post('reminders/createFollowUpChain', data),
    getRemindersChain: (params) => api.get('reminders/getRemindersChain', { params }),
    suggestMailChains: (params) => api.get('/reminders/suggestMailChains', { params }),
    deleteReminderChain: (params) => api.delete('reminders/deleteChainReminder', { params }),
  };

  const smsRequests = {
    deleteReminder: (params) => api.delete('reminders/deleteSmsReminder', { params }),
    saveReminder: (data) => api.post('/sms/saveSmsReminder', data),
    createReminder: (data) => api.post('/sms/newSmsReminder', data),
    send: (data, options) => api.post('/sms/send', data, options),
  };

  const twilioRequests = {
    putOnHold: (data) => api.post('/twilio/putOnHold', data),
    redirectTo: (data) => api.post('/twilio/redirectTo', data),
    capabilityToken: () => api.get('/twilio/getCapabilityToken'),
    saveCallResult: (params) => api.get('/twilio/callResult', { params }),
    callbackStatus: (params) => api.get('/twilio/callbackStatus', { params }),
    redirectToConference: (data) => api.post('/twilio/redirectToConference', data),
    getIncomingJobInfo: (params) => api.get('/twilio/getIncomingJobInfo', { params }),
    redirectBusyToVoiceMail: (data) => api.post('/twilio/redirectBusyToVoiceMail', data),
    getGeneralCompanyName: (params) => api.get('/twilio/getGeneralCompanyName', { params }),
    redirectGeneralToConference: (data) => api.post('/twilio/redirectGeneralToConference', data),
  };

  const crewRequests = {
    assign: (data) => api.post('/crews/assignToJob', data),
    info: (params) => api.get('/crews/getCrewData', { params }),
    assignCancel: (data) => api.post('/crews/cancelCrewAssign', data),
    assignConfirm: (data) => api.post('/crews/confirmCrewAssign', data),
    list: (params) => api.get('/crews/getCrewsAvailability', { params }),
    scheduleCreate: (data) => api.post('/crews/createCrewSchedule', data),
    scheduleList: (params) => api.get('/crews/getCrewsSchedule', { params }),
    changeAssignedUnit: (data) => api.post('/crews/changeAssignedUnit', data),
    assignedJobsList: (params) => api.get('/crews/getJobsToAssign', { params }),
    removeMover: (data) => api.post('/crews/removeAssignedMover', data),
    unavailableList: (params) => api.get('/crews/getUnavailableCrews', { params }),
    bookedJobsByDate: (params) => api.get('/crews/getBookedJobsByDate', { params }),
    assignedSchedule: (params) => api.get('/crews/getAssignedCrewSchedule', { params }),
    availableUnitsByDate: (params) => api.get('/crews/getAvailableUnitsByDate', { params }),
    saveUnitAvailability: ({ id, date, ...res }) => api.post(`/crews/saveCrewUnitAvailability/${id}/crew/${date}/0`, res),
  };

  const insuranceRequests = {
    sendSample: (data) => api.post(`/insurance/sendSample`, data),
    save: (data) => api.post('/insurance/sendJobInsuranceRequest', data),
    update: (data) => api.post('/insurance/updateJobInsuranceInfo', data),
    resendReply: (data) => api.post('/insurance/resendReplyToPropertyManager', data),
  };

  const buildingRequests = {
    updateInfo: (data) => api.post('building_info/save', data),
    timeById: (params) => api.get('/baseData/getBuildingTimeById', { params }),
    info: (params) => api.get('/baseData/getBuildingInfo', { params }),
  };

  const communicationRequests = {
    getCalls: (data) => api.post('/twilio/getCalls', data),
    getMailbox: (data) => api.post('/mail/getMailbox', data),
    deleteMail: (data) => api.post('/mail/deleteMail', data),
    readMails: (data) => api.post('/mail/bulkReadMail', data),
    deleteMails: (data) => api.post('/mail/bulkDeleteMail', data),
    getMail: (params) => api.get('/mail/getMailInfo', { params }),
    assignSmsChat: (data) => api.post('/twilio/assignSmsChat', data),
    getAllReminders: (data) => api.post('/mail/getAllReminders', data),
    assignMissedCall: (data) => api.post('/twilio/assignMissedCall', data),
    getChat: (params) => api.get('/twilio/getCommunicationChat', { params }),
    readCommunication: (data) => api.post('/twilio/readChatCommunication', data),
    changeMailPriority: (data) => api.post('/mail/changeMailPriority', data),
    deleteCommunication: (params) => api.delete('/twilio/deleteCommunication', { params }),
    getAllCommunications: (data) => api.post('/twilio/getAllCommunications', data),
    getCustomerFormLink: (params) => api.get('/sms/getCustomerFormLink', { params }),
    getCustomerFormLinkTemp: (params) => api.get('/sms/getCustomerFormLinkTemp', { params }),
    getMissedLeadCommunications: (data) => api.post('/twilio/getMissedLeadCommunications', data),
  };

  const reportRequests = {
    getDashboardInfo: (data) => api.post('/reports/getDashboardInfo', data),
    getFilterInfo: () => api.get('/reports/getFilterInfo'),
    getDriverClaims: (data) => api.post('/reports/getDriverClaim', data),
    getLeadReport: (data) => api.post('/reports/getLeadReport', data),
    getBookingReport: (data) => api.post('/reports/getBookingReport', data),
    getProviderReport: (data) => api.post('/reports/getProviderReport', data),
    getBuildingReport: (data) => api.post('/reports/getBuildingReport', data),
    getPayrollReport: (data) => api.post('/reports/getPayrollList', data),
    getUserNames: (data) => api.post('/reports/getUserNames', data),
    getBuildingTimeInfo: (data) => api.post('/reports/getBuildingTimeInfo', data),
    changePaidStatus: (data) => api.post('/reports/paidStatus', data),
    getAgentComunicationsReport: (data) => api.post('/reports/getAgentCommunication', data),
    getGeneralReport: (data) => api.post('/reports/getReportList', data),
    getGeneralReportJobInfo: (data) => api.post('/reports/getJobInfoReport', data),
    createReimbursement: (data) => api.post('/reports/createReimbursement', data),
  };

  const importantNotesRequests = {
    saveImportantNote: (data) => api.post('/importantNotes/save', data, { headers: { 'Content-Type': 'multipart/form-data' } }),
    getImportantNotes: () => api.get('/importantNotes/get'),
    getNoteInfo: (params) => api.get('/importantNotes/getNoteInfo', { params }),
    deleteAttachments: (data) => api.post('/importantNotes/deleteAttachment', data),
    deleteImportantNote: (data) => api.post('/importantNotes/delete', data),
  };

  const value = {
    api,
    setToken,
    job: jobRequests,
    sms: smsRequests,
    crew: crewRequests,
    jobs: jobsRequests,
    user: userRequests,
    mail: mailRequests,
    task: taskRequests,
    claim: claimRequests,
    moving: movingRequests,
    common: commonRequests,
    twilio: twilioRequests,
    building: buildingRequests,
    reminder: reminderRequests,
    insurance: insuranceRequests,
    extraStops: extraStopsRequests,
    communication: communicationRequests,
    report: reportRequests,
    importantNotes: importantNotesRequests,
  };

  return <ApiContext.Provider value={value}>{children}</ApiContext.Provider>;
};

export const useApi = () => {
  return useContext(ApiContext);
};

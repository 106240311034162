import React, { PureComponent, Fragment } from 'react';
import classNames from 'classnames';
import Item from './Item';

class PackingList extends PureComponent {
  state = { isOpen: false };

  toggleOpen = () => this.setState((prevState) => ({ isOpen: !prevState.isOpen }));

  render() {
    const { list, onAddItem } = this.props;
    const { isOpen } = this.state;

    if (!list.length) return null;

    return (
      <Fragment>
        <div className="new-table--row">
          <div className="new-table--row--item light no-shadow w40">
            <span>Name</span>
          </div>
          <div className="new-table--row--item light w20 no-shadow text-center">
            <span>Size</span>
          </div>
          <div className="new-table--row--item light w15 no-shadow text-center">
            <span>Price</span>
          </div>
          <div className="new-table--row--item light w15 no-shadow text-center">
            <span>Qty</span>
          </div>
          <div className="new-table--row--item light w10 no-shadow" />
        </div>

        <div className={classNames('new-table stripped', { open: isOpen })}>
          {list.map((item) => (
            <Item key={item.id} item={item} onAddItem={onAddItem} />
          ))}
        </div>

        <div className="new-table--row mt-3">
          <button type="button" className="new-table--row--item show-more" onClick={this.toggleOpen}>
            {!isOpen ? 'Show more' : 'Show less'}
          </button>
        </div>
      </Fragment>
    );
  }
}

export default PackingList;

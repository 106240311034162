import { useDefault } from 'hooks/query/useCommon';
import { usePackingDescription } from 'hooks/query/useCommon';

export const useGetData = () => {
  const { data: defaultItems, isLoading: isDefaultDataLoading } = useDefault();
  const { data: packingDescription = [], isLoading: isPackingDescLoading } = usePackingDescription();

  return {
    isDataLoading: isDefaultDataLoading || isPackingDescLoading,
    lead_providers: defaultItems?.lead_providers ?? [],
    storages: defaultItems?.storages,
    companies: defaultItems?.company ?? [],
    storage_unit_sizes: defaultItems?.storage_unit_sizes ?? [],
    building_info: defaultItems?.building_info ?? [],
    move_sizes: defaultItems?.move_sizes,
    packingDescription,
  };
};

import React from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';

const Autocomplete = (props) => {
  const { name, value, disabled, onChange, onSelect, placeholderText } = props;
  return (
    <PlacesAutocomplete
      name={name}
      value={value}
      onChange={(value) => onChange(value, name)}
      onSelect={(value) => onSelect(value, name)}
      searchOptions={{
        componentRestrictions: { country: 'us' },
        fields: ['address_component'],
        types: ['geocode'],
      }}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div className="search-view">
          <input
            {...getInputProps({
              disabled: disabled,
              placeholder: placeholderText,
              className: 'location-search-input',
            })}
          />
          <div className="autocomplete-dropdown-container">
            {loading && <div>Loading...</div>}
            {suggestions.map((suggestion, index) => {
              const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
              const style = suggestion.active
                ? { backgroundColor: '#fafafa', cursor: 'pointer', padding: 3, fontWeight: 500 }
                : { backgroundColor: '#ffffff', cursor: 'pointer', padding: 3, fontWeight: 500 };
              return (
                <div
                  key={index}
                  {...getSuggestionItemProps(suggestion, {
                    className,
                    style,
                  })}
                >
                  <span>{suggestion.description}</span>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
};

export default Autocomplete;

import React from 'react';
import { useParams } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import JobBanner from 'components/JobBanner';
import routes from 'config/routes';
import JobList from '../JobsList';
import Job from './Job';

const JobRoot = () => {
  const { id, type } = useParams();

  if (!type) return <Redirect to={`${routes.JOBS}/all`} />;

  return (
    <div className="page-content">
      <div className="container-fluid mb-4">
        <div className="row">
          <div className="col-5">
            <JobList />
          </div>
          <div className="col-7">{id ? <Job /> : <JobBanner />}</div>
        </div>
      </div>
    </div>
  );
};

export default JobRoot;

import React, { Fragment } from 'react';
import { useInfo, useTabInfo } from 'hooks/query/useJob';
import { useParams } from 'react-router-dom';

const JobCustomerNotes = () => {
  const { id, tab } = useParams();
  const {
    data: { job_payments: jobPayments },
  } = useInfo(id);

  const {
    data: { moving_info: movingInfo },
  } = useTabInfo(id, tab);

  return (
    <div className="shadow job-customer-notes">
      {movingInfo.from.driver_notes && (
        <Fragment>
          <h3 className="header">DRIVER NOTES</h3>
          <div className="new-table">
            <div className="new-table--row">
              <div className="new-table--row--item">{movingInfo.from.driver_notes}</div>
            </div>
          </div>
        </Fragment>
      )}
      <Fragment>
        <h3 className="header">CUSTOMER NOTES</h3>
        <div className="new-table">
          {movingInfo.lead_note > 0 && (
            <div className="new-table--row">
              <div className="new-table--row--item col-2">Lead note:</div>
              <div className="new-table--row--item">{movingInfo.lead_note.note}</div>
            </div>
          )}
          {movingInfo.from.apt_note.length > 0 && (
            <div className="new-table--row">
              <div className="new-table--row--item col-2">Inventory List:</div>
              <div className="new-table--row--item">{movingInfo.from.apt_note}</div>
            </div>
          )}
          {movingInfo.from.fragile_note.length > 0 && (
            <div className="new-table--row">
              <div className="new-table--row--item col-2">Fragile note:</div>
              <div className="new-table--row--item">{movingInfo.from.fragile_note}</div>
            </div>
          )}
          {movingInfo.from.packing_note.length > 0 && (
            <div className="new-table--row">
              <div className="new-table--row--item col-2">Packing note:</div>
              <div className="new-table--row--item">{movingInfo.from.packing_note}</div>
            </div>
          )}
          {movingInfo.from.wrap_note.length > 0 && (
            <div className="new-table--row">
              <div className="new-table--row--item col-2">Wrap note:</div>
              <div className="new-table--row--item">{movingInfo.from.wrap_note}</div>
            </div>
          )}
        </div>
      </Fragment>

      {jobPayments.length > 0 && jobPayments.some((job) => job.notes) && (
        <Fragment>
          <h3 className="header">PAYMENT NOTES</h3>
          <div className="new-table">
            {jobPayments.map((item, index) => {
              if (item.notes !== '') {
                return (
                  <div className="new-table--row" key={index}>
                    <div className="new-table--row--item col-2">Payment note:</div>
                    <div className="new-table--row--item">{item.notes}</div>
                  </div>
                );
              }
              return null;
            })}
          </div>
        </Fragment>
      )}
    </div>
  );
};
export default JobCustomerNotes;

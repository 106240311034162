function formatPhoneNumberOnlyDigits(phoneNumber) {
  if (!phoneNumber) return '';
  return phoneNumber.replace(/\D/g, '');
}

function formatPhoneNumberPlusAndDigits(phoneNumber) {
  if (!phoneNumber) return '';
  return `+${phoneNumber.replace(/\D/g, '')}`;
}

function formatPhoneNumberPlusOneAndDigits(phoneNumber) {
  if (!phoneNumber) return '';
  return `+1${phoneNumber.replace(/\D/g, '')}`;
}

function formatPhoneNumberOnlyDigitsAndHyphen(phoneNumber) {
  if (!phoneNumber) return '';
  return phoneNumber.replace(/^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})/, '$1-$2-$3');
}

function formatPhoneNumberNoFirstCharOnlyDigitsAndHyphen(phoneNumber) {
  if (!phoneNumber) return '';
  // +1 (###) ### #### -> ###-###-####
  return phoneNumber
    .replace('+1', '')
    .replaceAll(' ', '')
    .replaceAll('-', '')
    .replace('(', '')
    .replace(')', '')
    .replace(/^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})/, '$1-$2-$3');
}

export {
  formatPhoneNumberOnlyDigits,
  formatPhoneNumberPlusAndDigits,
  formatPhoneNumberPlusOneAndDigits,
  formatPhoneNumberOnlyDigitsAndHyphen,
  formatPhoneNumberNoFirstCharOnlyDigitsAndHyphen,
};

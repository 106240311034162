import React, { memo } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useAuth } from 'context/auth';

const PrivateRoute = memo(({ component: Component, ...rest }) => {
  const { isAuth } = useAuth();

  return (
    <Route {...rest} render={(props) => (isAuth ? <Component {...props} /> : <Redirect to={{ pathname: '/', state: { from: props.location } }} />)} />
  );
});

export default PrivateRoute;

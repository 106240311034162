import React, { useMemo, useCallback } from 'react';
import withReactContent from 'sweetalert2-react-content';
import { useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import Swal from 'sweetalert2';

import { useSend } from 'hooks/query/useSMS';
import { useInfo, useTabInfo } from 'hooks/query/useJob';
import Icon from 'components/Icon';
import Select from 'components/newComponents/Select';
import SendSmsSwalModal from './SendSmsSwalModal';
import { useModal } from 'context/modal';
import { useAuth } from 'context/auth';

const MySwal = withReactContent(Swal);

const TeamCommunications = () => {
  const { id, tab } = useParams();

  const {
    data: { assigned_crew: assignedCrew, assigned_management: assignedManagement },
  } = useTabInfo(id, tab);
  const {
    data: {
      job_info: { id: jobId },
    },
  } = useInfo(id);

  const { user: currentUser } = useAuth();

  const { mutate } = useSend();
  const { modalNames, open } = useModal();

  const team = useMemo(() => {
    const management = Object.values(assignedManagement).filter((e) => e);
    const { movers, drivers } = assignedCrew;

    return Object.assign({}, [...movers, ...drivers, ...management]);
  }, [assignedCrew, assignedManagement]);

  const teamOptions = useMemo(() => {
    return Object.values(team)
      .filter((user) => currentUser.id !== user.id)
      .map((user, index) => ({
        value: index,
        label: `${user.first_name ?? user.name} ${user.last_name ?? user.userlastname}`,
      }));
  }, [currentUser.id, team]);

  const { values, setFieldValue, resetForm } = useFormik({
    initialValues: {
      user: null,
      url: null,
      phone: null,
      email: null,
    },
  });

  const sendHandler = useCallback(
    ({ image, message }) => {
      const preparedMessage = {
        job_id: jobId,
        message: message,
        to: team[values.user].email,
        image: image,
        type: 'default',
      };
      mutate(preparedMessage, { onSuccess: resetForm });
    },
    [jobId, mutate, resetForm, team, values.user]
  );

  const smsHandler = useCallback(async () => {
    new Promise((res, rej) => {
      MySwal.fire({
        title: 'Send Sms',
        html: (
          <SendSmsSwalModal
            onSubmit={(v) => {
              Swal.close();
              res(v);
            }}
            onCancel={() => Swal.close()}
          />
        ),
        showConfirmButton: false,
      });
    }).then(sendHandler);
  }, [sendHandler]);

  const isDisabled = typeof values.user !== 'number';

  return (
    <div className="new-table mt-20">
      <div className="new-table--row">
        <div className="new-table--row--item select">
          <Icon icon="team" size={22} />

          <Select
            options={teamOptions}
            value={values.user}
            name="user"
            placeholder="Select Team member to communicate"
            onChange={({ value }) => setFieldValue('user', value)}
          />
        </div>

        <div className="new-table--row--item no-padding">
          <button disabled={isDisabled} className="main justify-content-center new-table--row--item select" onClick={() => open(modalNames.twilio)}>
            Call
          </button>
          <button disabled={isDisabled} className="main justify-content-center new-table--row--item select" onClick={smsHandler}>
            Sms
          </button>
          <button
            className="main justify-content-center new-table--row--item select"
            disabled={isDisabled}
            onClick={() => {
              open(modalNames.newEmail, { type: 'new', email_to: team[values.user].email });
            }}
          >
            Email
          </button>
        </div>
      </div>
    </div>
  );
};

export default TeamCommunications;

import axios from 'axios';
import showError from 'utils/errorHandler';

class Api {
  constructor() {
    this.serverApi = process.env.REACT_APP_API_URL;
    this.client = axios.create({ baseURL: this.serverApi });
  }

  getData = async (jobId, document) => {
    try {
      return await this.client.get(`/api/customerDocuments/termsConditions?job_id=${jobId}&document=${document}`);
    } catch (error) {
      showError(error);
    }
  };
}

export default new Api();

import React from 'react';
import DonutChartComponent from './DonutChartComponent';
import {
  calcJobTotalPaymentsByPaymentsType,
  getValidPaymentsValue,
  getPackingCommision,
  getMaterialPrice,
  getPackingSoldPrice,
  getPackingProfitPrice,
  getFullPackingProfitPrice,
} from './generalUtils';

const ReportTotal = (props) => {
  if (!props.job_list) return null;
  if (props.job_list.length === 0) return null;
  let hours = 0;
  let total = 0;
  let expenses = 0;
  let salary = 0;
  let commission = 0;
  let profit = 0;

  const job_count = props.job_list.length;
  props.job_list.forEach((job) => {
    hours += job.hours;
    total += job.total;
    expenses += job.expenses;
    salary += parseFloat(job.salary);
    commission += job.commission;
    profit += job.profit;
  });

  const hours_a = (hours / job_count).toFixed(2);
  const total_a = (total / job_count).toFixed(2);
  const expenses_a = (expenses / job_count).toFixed(2);
  const salary_a = (salary / job_count).toFixed(2);
  const commission_a = (commission / job_count).toFixed(2);
  const profit_a = (profit / job_count).toFixed(2);

  const min_hours = props.job_list.reduce((min, p) => (+p.hours < min ? +p.hours : min), +props.job_list[0].hours) || 0;
  const max_hours = props.job_list.reduce((max, p) => (+p.hours > max ? +p.hours : max), +props.job_list[0].hours) || 0;
  const min_total = props.job_list.reduce((min, p) => (+p.total < min ? +p.total : min), +props.job_list[0].total) || 0;
  const max_total = props.job_list.reduce((max, p) => (+p.total > max ? +p.total : max), +props.job_list[0].total) || 0;
  const min_expenses = props.job_list.reduce((min, p) => (+p.expenses < min ? +p.expenses : min), +props.job_list[0].expenses);
  const max_expenses = props.job_list.reduce((max, p) => (+p.expenses > max ? +p.expenses : max), +props.job_list[0].expenses);
  const min_salary = props.job_list.reduce((min, p) => (+p.salary < min ? +p.salary : min), +props.job_list[0].salary);
  const max_salary = props.job_list.reduce((max, p) => (+p.salary > max ? +p.salary : max), +props.job_list[0].salary);
  const min_commision = props.job_list.reduce((min, p) => (+p.commission < min ? +p.commission : min), +props.job_list[0].commission);
  const max_commision = props.job_list.reduce((max, p) => (+p.commission > max ? +p.commission : max), +props.job_list[0].commission);
  const min_profit =
    props.job_list.reduce(
      (min, p) => (+(p.profit - p.commission) < min ? +(p.profit - p.commission) : min),
      +(props.job_list[0].profit - props.job_list[0].commission)
    ) || 0;
  const max_profit =
    props.job_list.reduce(
      (max, p) => (Number(p.profit - p.commission) > max ? +(p.profit - p.commission) : max),
      +(props.job_list[0].profit - props.job_list[0].commission)
    ) || 0;

  const { manager_permission } = props.FilterInfo;

  const jobTotalPayments = calcJobTotalPaymentsByPaymentsType(props.job_list);

  const packingCommission = getPackingCommision(props.job_list);
  const materialPrice = getMaterialPrice(props.job_list);
  const packingSoldPrice = getPackingSoldPrice(props.job_list);
  const packingProfitPrice = getPackingProfitPrice(materialPrice, packingSoldPrice, packingCommission);
  const fullPackingProfitPrice = getFullPackingProfitPrice(props.job_list);

  return (
    <div className="d-flex justify-content-between">
      <div className={manager_permission ? 'w45' : 'w65'}>
        <div className="reports-table">
          <div className="reports-table-row">
            <div className="reports-table-column reports-table-column--8 reports-table-column--title">Title</div>
            <div className="reports-table-column reports-table-column--8 reports-table-column--title">Jobs:</div>
            <div className="reports-table-column reports-table-column--8 reports-table-column--title">Hours:</div>
            <div className="reports-table-column reports-table-column--8 reports-table-column--title">Total $:</div>
            <div className="reports-table-column reports-table-column--8 reports-table-column--title">Expenses $:</div>
            <div className="reports-table-column reports-table-column--8 reports-table-column--title">Workers Salary $:</div>
            <div className="reports-table-column reports-table-column--8 reports-table-column--title">Sales Commission $:</div>
            <div className="reports-table-column reports-table-column--8 reports-table-column--title">Profit $:</div>
          </div>
          <div className="reports-table-row">
            <div className="reports-table-column reports-table-column--8 reports-table-column--title bcg-light">Total</div>
            <div className="reports-table-column reports-table-column--8">{job_count}</div>
            <div className="reports-table-column reports-table-column--8">{hours.toFixed(2)}</div>
            <div className="reports-table-column reports-table-column--8">${Number(total).toFixed(2)}</div>
            <div className="reports-table-column reports-table-column--8">${manager_permission ? expenses.toFixed(2) : '-'}</div>
            <div className="reports-table-column reports-table-column--8">${manager_permission ? salary.toFixed(2) : '-'}</div>
            <div className="reports-table-column reports-table-column--8">${manager_permission ? commission.toFixed(2) : '-'}</div>
            <div className="reports-table-column reports-table-column--8">${manager_permission ? profit.toFixed(2) : ''}</div>
          </div>
          <div className="reports-table-row">
            <div className="reports-table-column reports-table-column--8 reports-table-column--title bcg-light">Min</div>
            <div className="reports-table-column reports-table-column--8">-</div>
            <div className="reports-table-column reports-table-column--8">{min_hours.toFixed(2)}</div>
            <div className="reports-table-column reports-table-column--8">${min_total.toFixed(2) > 0 ? min_total.toFixed(2) : 0}</div>
            <div className="reports-table-column reports-table-column--8">${manager_permission ? min_expenses.toFixed(2) : '-'}</div>
            <div className="reports-table-column reports-table-column--8">${manager_permission ? min_salary.toFixed(2) : '-'}</div>
            <div className="reports-table-column reports-table-column--8">${manager_permission ? min_commision.toFixed(2) : '-'}</div>
            <div className="reports-table-column reports-table-column--8">${manager_permission ? min_profit.toFixed(2) : '-'}</div>
          </div>
          <div className="reports-table-row">
            <div className="reports-table-column reports-table-column--8 reports-table-column--title bcg-light">Max</div>
            <div className="reports-table-column reports-table-column--8">-</div>
            <div className="reports-table-column reports-table-column--8">{max_hours.toFixed(2)}</div>
            <div className="reports-table-column reports-table-column--8">${max_total.toFixed(2)}</div>
            <div className="reports-table-column reports-table-column--8">${manager_permission ? max_expenses.toFixed(2) : '-'}</div>
            <div className="reports-table-column reports-table-column--8">${manager_permission ? max_salary.toFixed(2) : '-'}</div>
            <div className="reports-table-column reports-table-column--8">${manager_permission ? max_commision.toFixed(2) : '-'}</div>
            <div className="reports-table-column reports-table-column--8">${manager_permission ? max_profit.toFixed(2) : '-'}</div>
          </div>
          <div className="reports-table-row">
            <div className="reports-table-column reports-table-column--8 reports-table-column--title bcg-light">Average</div>
            <div className="reports-table-column reports-table-column--8">-</div>
            <div className="reports-table-column reports-table-column--8">{hours_a}</div>
            <div className="reports-table-column reports-table-column--8">${total_a}</div>
            <div className="reports-table-column reports-table-column--8">${manager_permission ? expenses_a : '-'}</div>
            <div className="reports-table-column reports-table-column--8">${manager_permission ? salary_a : '-'}</div>
            <div className="reports-table-column reports-table-column--8">${manager_permission ? commission_a : '-'}</div>
            <div className="reports-table-column reports-table-column--8">${manager_permission ? profit_a : '-'}</div>
          </div>
        </div>
      </div>

      <div className="w25 mx-3">
        <div className="reports-table">
          <div className="reports-table-row">
            <div className="reports-table-column reports-table-column--title reports-table-column--6">Title</div>
            <div className="reports-table-column reports-table-column--title reports-table-column--6">
              Containers <br />
              Cost $:
            </div>
            <div className="reports-table-column reports-table-column--title reports-table-column--6">
              Containers <br />
              Sold $:
            </div>
            <div className="reports-table-column reports-table-column--title reports-table-column--6">
              Packing <br />
              Commission $:
            </div>
            <div className="reports-table-column reports-table-column--title reports-table-column--6">
              Packing <br />
              Profit $:
            </div>
            <div className="reports-table-column reports-table-column--title reports-table-column--6">
              FP <br />
              Profit $:
            </div>
          </div>
          <div className="reports-table-row">
            <div className="reports-table-column reports-table-column--title reports-table-column--6 bcg-light">Total</div>
            <div className="reports-table-column reports-table-column--6">${materialPrice.total}</div>
            <div className="reports-table-column reports-table-column--6">${packingSoldPrice.total}</div>
            <div className="reports-table-column reports-table-column--6">${packingCommission.total}</div>
            <div className="reports-table-column reports-table-column--6">${packingProfitPrice.total}</div>
            <div className="reports-table-column reports-table-column--6">${fullPackingProfitPrice.total}</div>
          </div>
          <div className="reports-table-row">
            <div className="reports-table-column reports-table-column--title reports-table-column--6 bcg-light">Min</div>
            <div className="reports-table-column reports-table-column--6">${materialPrice.min}</div>
            <div className="reports-table-column reports-table-column--6">${packingSoldPrice.min}</div>
            <div className="reports-table-column reports-table-column--6">${packingCommission.min}</div>
            <div className="reports-table-column reports-table-column--6">${packingProfitPrice.min}</div>
            <div className="reports-table-column reports-table-column--6">${fullPackingProfitPrice.min}</div>
          </div>
          <div className="reports-table-row">
            <div className="reports-table-column reports-table-column--title reports-table-column--6 bcg-light">Max</div>
            <div className="reports-table-column reports-table-column--6">${materialPrice.max}</div>
            <div className="reports-table-column reports-table-column--6">${packingSoldPrice.max}</div>
            <div className="reports-table-column reports-table-column--6">${packingCommission.max}</div>
            <div className="reports-table-column reports-table-column--6">${packingProfitPrice.max}</div>
            <div className="reports-table-column reports-table-column--6">${fullPackingProfitPrice.max}</div>
          </div>
          <div className="reports-table-row">
            <div className="reports-table-column reports-table-column--title reports-table-column--6 bcg-light">Average</div>
            <div className="reports-table-column reports-table-column--6">${materialPrice.avarage}</div>
            <div className="reports-table-column reports-table-column--6">${packingSoldPrice.avarage}</div>
            <div className="reports-table-column reports-table-column--6">${packingCommission.avarage}</div>
            <div className="reports-table-column reports-table-column--6">${packingProfitPrice.avarage}</div>
            <div className="reports-table-column reports-table-column--6">${fullPackingProfitPrice.avarage}</div>
          </div>
        </div>
      </div>

      <div className="w10 mr-3">
        <div className="reports-table">
          <div className="reports-table-row">
            <div className="reports-table-column reports-table-column--title reports-table-column--2">Payment Type</div>
            <div className="reports-table-column reports-table-column--title reports-table-column--2">Total $:</div>
          </div>
          <div className="reports-table-row">
            <div className="reports-table-column reports-table-column--title bcg-light reports-table-column--2">Stripe</div>
            <div className="reports-table-column reports-table-column--2">{getValidPaymentsValue(jobTotalPayments[1])}</div>
          </div>
          <div className="reports-table-row">
            <div className="reports-table-column reports-table-column--title bcg-light reports-table-column--2">Cash</div>
            <div className="reports-table-column reports-table-column--2">{getValidPaymentsValue(jobTotalPayments[2])}</div>
          </div>
          <div className="reports-table-row">
            <div className="reports-table-column reports-table-column--title bcg-light reports-table-column--2">Check</div>
            <div className="reports-table-column reports-table-column--2">{getValidPaymentsValue(jobTotalPayments[3])}</div>
          </div>
          <div className="reports-table-row">
            <div className="reports-table-column reports-table-column--title bcg-light reports-table-column--2">Zelle</div>
            <div className="reports-table-column reports-table-column--2">{getValidPaymentsValue(jobTotalPayments[5])}</div>
          </div>
        </div>
      </div>

      {manager_permission && (
        <div className="w20">
          <DonutChartComponent
            series={[Number(expenses.toFixed(2)), Number(salary.toFixed(2)), Number(commission.toFixed(2)), Number((profit - commission).toFixed(2))]}
            total={Number(total).toFixed(2)}
          />
        </div>
      )}
    </div>
  );
};

export default ReportTotal;

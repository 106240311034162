import React, { useMemo } from 'react';
import Icon from 'components/Icon';
import { referralOptions } from 'helpers/constants';
import { useDefault } from 'hooks/query/useCommon';

export const Final = ({ values, distanceList }) => {
  const {
    data: { statuses, company, move_sizes: moveSizes },
  } = useDefault();

  const companyName = useMemo(() => {
    return company.find((e) => e.id === values.company_id)?.company_name || '';
  }, [company, values.company_id]);

  const selectedReferral = useMemo(() => {
    return referralOptions.find((option) => option.value === +values?.hear_about || 1).label;
  }, [values]);

  const selectedMoveSize = useMemo(() => {
    if (!values.company_id || !values.move_size_id) return null;
    return moveSizes[values.company_id].find((size) => size.id === +values.move_size_id)?.name || 'UNKNOWN SIZE';
  }, [moveSizes, values]);

  const buildingTypes = useMemo(() => {
    return statuses.building_type.map((el, index) => ({ value: index, label: el }));
  }, [statuses]);

  return (
    <div className="new-table modal">
      <div className="new-table--row">
        <div className="new-table--row--item">
          <span>From</span>
        </div>
        <div className="new-table--row--item">
          <span>To</span>
        </div>
      </div>
      <div className="new-table--row">
        <div className="new-table--row--item">
          <Icon icon="name" title="Name" />
          <span>{values?.from_name}</span>
        </div>
        <div className="new-table--row--item">
          <Icon icon="name" title="Name" />
          <span>{values?.to_name}</span>
        </div>
      </div>
      <div className="new-table--row">
        <div className="new-table--row--item">
          <Icon icon="email" title="Email" />
          <span>{values?.from_email}</span>
        </div>
        <div className="new-table--row--item">
          <Icon icon="email" title="Email" />
          <span>{values?.to_email}</span>
        </div>
      </div>
      <div className="new-table--row">
        <div className="new-table--row--item">
          <Icon icon="home_phone" title="Home Phone" />
          <span>{values?.from_home_phone}</span>
        </div>
        <div className="new-table--row--item">
          <Icon icon="home_phone" title="Home Phone" />
          <span>{values?.to_home_phone}</span>
        </div>
      </div>

      <div className="new-table--row">
        <div className="new-table--row--item">
          <Icon icon="cell_phone" title="Cell Phone" />
          <span>{values?.from_cell_phone}</span>
        </div>
        <div className="new-table--row--item">
          <Icon icon="cell_phone" title="Cell Phone" />
          <span> {values?.to_cell_phone}</span>
        </div>
      </div>

      <div className="new-table--row">
        <div className="new-table--row--item">
          <Icon icon="address" title="Address" />
          <span> {values.from_address}</span>
        </div>
        <div className="new-table--row--item">
          <Icon icon="address" title="Address" />
          <span>{values.to_address}</span>
        </div>
      </div>

      <div className="new-table--row">
        <div className="new-table--row">
          <div className="new-table--row--item">
            <Icon icon="zip" title="Zip code, state" />
            <span>{values?.from_zipcode}</span>
          </div>
          <div className="new-table--row--item">
            <span>{values.from_state}</span>
          </div>
        </div>
        <div className="new-table--row">
          <div className="new-table--row--item">
            <Icon icon="zip" title="Zip code, state" />
            <span>{values?.to_zipcode}</span>
          </div>

          <div className="new-table--row--item">
            <span>{values.to_state}</span>
          </div>
        </div>
      </div>

      <div className="new-table--row">
        <div className="new-table--row--item">
          <Icon icon="city" title="City" />
          <span>{values?.from_city}</span>
        </div>
        <div className="new-table--row--item">
          <Icon icon="city" title="City" />
          <span>{values?.to_city}</span>
        </div>
      </div>

      <div className="new-table--row">
        <div className="new-table--row--item new-table--row--item__column">
          <p>
            Estimated Travel Distance is <b>{values.travel_stop_distance || 0} MILES.</b>
          </p>
          <p>
            Distance from Office to PickUp is <b>{values?.pickup_distance || 0} MILES.</b>
          </p>
          {!!distanceList.length &&
            distanceList.map((stop, index) => {
              if (distanceList.length === index + 1) {
                return (
                  <p key={index + 1}>
                    From extra point {index} to DropOff
                    <b> {stop} MILES</b>
                  </p>
                );
              }
              return (
                <p key={index + 1}>
                  Distance to Extra stop {index + 1}
                  <b> {stop || 0} MILES</b>
                </p>
              );
            })}
          <p>
            Distance from DropOff to Office is <b>{values?.drop_off_to_office || 0} MILES. </b>
          </p>
        </div>
        <div className="new-table--row--item new-table--row--item__column">
          <p className="details">Selected items</p>
          <p className="details">
            Company <span className="b main">{companyName}</span>
          </p>
          <p className="details">
            How did you hear about us <span className="b main">{selectedReferral}</span>
          </p>
        </div>
      </div>

      <div className="new-table--row">
        <div className="new-table--row--item new-table--row--item__column">
          <p className="details">
            Pickup Date <span className="b">{values?.pickup_date}</span>
          </p>
          <p className="details">
            Move size <span className="b">{selectedMoveSize}</span>
          </p>
          <p className="details">
            How many movers? <span className="b">{values?.qty_man}</span>
          </p>
          <p className="details">
            How many trucks? <span className="b">{values?.qty_trucks}</span>
          </p>
        </div>
        <div className="new-table--row--item new-table--row--item__column">
          {values.full_packing_rate === 'customer' && (
            <p className="details">Customer responsible for packing. Company is not liable for any damage</p>
          )}
          {values.full_packing_rate === 'on_demand' && (
            <p className="details">
              You will be charged for each item used at company discretion to protect your belongings. For ex, price of:
              <br />
              LARGE BOX – 8.50, <br />
              EXTRA LG BOX - 9.75
            </p>
          )}
          {values.full_packing_rate === 'true' && (
            <p className="details">For this company full packing rate is additional ${values?.full_packing_rate_value} RATE per hour</p>
          )}
        </div>
      </div>

      <h3 className="header main">Additional main information</h3>
      <div className="new-table--row">
        <div className="new-table--row--item new-table--row--item__column no-padding">
          <h3 className="header">From</h3>
          {values?.building_type_from && (
            <p className="new-table--row--item justify-content-between mh60">
              What kind of building are you moving from?
              <b>{buildingTypes[values.building_type_from].label}</b>
            </p>
          )}
          {(values?.building_access_from_start || values?.building_access_from_end) && (
            <p className="new-table--row--item justify-content-between mh60">
              Building access:
              <b>{(values?.building_access_from_start || '') + ' - ' + (values?.building_access_from_end || '')}</b>
            </p>
          )}
          {!!values?.elevator_exist_from && (
            <p className="new-table--row--item justify-content-between mh60">
              Elevator reservation:
              <b>{(values?.elevator_reservation_from_start || '') + ' - ' + (values?.elevator_reservation_from_end || '')}</b>
            </p>
          )}
          {!!values?.elevator_exist_from && (
            <p className="new-table--row--item justify-content-between mh60">
              Which floor:
              <b>{values?.elevator_from_floor}</b>
            </p>
          )}

          {!!values?.distance_elevator_apartment_from && (
            <p className="new-table--row--item justify-content-between mh60">
              What is a distance from apartment door to the elevator (Yards):
              <b>{values?.distance_elevator_apartment_from}</b>
            </p>
          )}

          {!!values?.distance_elevator_parking_from && (
            <p className="new-table--row--item justify-content-between mh60">
              What is the distance from elevator to the loading dock/truck parking - (Yards):
              <b>{values?.distance_elevator_parking_from}</b>
            </p>
          )}

          {(values?.hardwood_protect_from || values?.marble_protect_from || values?.carpet_protect_from) ? (
            <p className="new-table--row--item justify-content-between mh60">
              Need floor/wall protection:
              <b>
                {values?.hardwood_protect_from ? <span>Hardwood; </span> : ''}
                {values?.marble_protect_from ? <span>Marble; </span> : ''}
                {values?.carpet_protect_from ? <span>Carpet; </span> : ''}
              </b>
            </p>
          ) : ''}
          {!!values.hoisting_from && (
            <p className="new-table--row--item justify-content-between mh60">
              Need Hoisting of heavy items:
              <b>Yes</b>
            </p>
          )}

          {values.hoisting_note_from !== '' && (
            <p className="new-table--row--item justify-content-between mh60">
              Hoisting note:
              <b>{values.hoisting_note_from}</b>
            </p>
          )}
        </div>

        <div className="new-table--row--item new-table--row--item__column no-padding">
          <h3 className="header">To</h3>
          {values?.building_type_to && (
            <p className="new-table--row--item justify-content-between mh60">
              What kind of building are you moving from?
              <b>{buildingTypes[values.building_type_from].label}</b>
            </p>
          )}
          {(values?.building_access_to_start || values?.building_access_to_end) && (
            <p className="new-table--row--item justify-content-between mh60">
              Building access:
              <b>{(values?.building_access_to_start || '') + ' - ' + (values?.building_access_to_end || '')}</b>
            </p>
          )}
          {!!values?.elevator_exist_to && (
            <p className="new-table--row--item justify-content-between mh60">
              Elevator reservation:
              <b>{(values?.elevator_reservation_to_start || '') + ' - ' + (values?.elevator_reservation_to_end || '')}</b>
            </p>
          )}

          {!!values?.elevator_exist_to && (
            <p className="new-table--row--item justify-content-between mh60">
              Which floor:
              <b>{values?.elevator_to_floor}</b>
            </p>
          )}

          {!!values?.distance_elevator_apartment_to && (
            <p className="new-table--row--item justify-content-between mh60">
              What is a distance from apartment door to the elevator (Yards):
              <b>{values?.distance_elevator_apartment_to}</b>
            </p>
          )}

          {!!values?.distance_elevator_parking_to && (
            <p className="new-table--row--item justify-content-between mh60">
              What is the distance from elevator to the loading dock/truck parking - (Yards):
              <b>{values?.distance_elevator_parking_to}</b>
            </p>
          )}

          {(values?.hardwood_protect_to || values?.marble_protect_to || values?.carpet_protect_to) ? (
            <p className="new-table--row--item justify-content-between mh60">
              Need floor/wall protection:
              <span>
                {values?.hardwood_protect_to ? <span>Hardwood; </span> : ''}
                {values?.marble_protect_to ? <span>Marble; </span> : ''}
                {values?.carpet_protect_to ? <span>Carpet; </span> : ''}
              </span>
            </p>
          ) : ''}
        </div>
      </div>

      {!!values?.apt_finished && (
        <div className="new-table--row">
          <p className="new-table--row--item justify-content-between">
            Inventory List
            <span>{values?.apt_note}</span>
          </p>
        </div>
      )}

      {!!values?.packing && (
        <div className="new-table--row">
          <p className="new-table--row--item justify-content-between">
            Are you packing miscellaneous items yourself?
            <span>{values?.packing_note}</span>
          </p>
        </div>
      )}
      {!!values?.fragile && (
        <div className="new-table--row">
          <p className="new-table--row--item justify-content-between">
            Do you have any fragile items such as mirror, tv, glass, picture, paintings, or any other?
            <span>{values?.fragile_note}</span>
          </p>
        </div>
      )}

      {!!values?.wrap && (
        <div className="new-table--row">
          <p className="new-table--row--item justify-content-between">
            Will you be able to provide bubble wrap, tv/mirror/picture box and shrink wrap to avoid charges for packing supplies?
            <span>{values?.wrap_note}</span>
          </p>
        </div>
      )}
      {!!values?.wrap && (
        <div className="new-table--row">
          <p className="new-table--row--item justify-content-between">
            Will you be able to provide bubble wrap, tv/mirror/picture box and shrink wrap to avoid charges for packing supplies?
            <span>{values?.wrap_note}</span>
          </p>
        </div>
      )}

      <h3 className="header main">Extra stop information</h3>
      <div className="new-table--row flex-wrap">
        {values.extra_stops.map((stop, index) => (
          <div className="new-table--row--item new-table--row--item__column no-padding" key={index}>
            <h3 className="header">Extra stop {index + 1}</h3>
            <p className="new-table--row--item mh60">{stop.address}</p>
            <p className="new-table--row--item mh60">{stop.city}</p>
            <div className="new-table--row mh60">
              <span className="new-table--row--item">{stop.state}</span>
              <span className="new-table--row--item">{stop.zipcode}</span>
            </div>

            {values.additional_info[index].building_type ? (
              <p className="new-table--row--item justify-content-between mh60">
                What kind of building are you moving to?
                <b>{buildingTypes[values.additional_info[index].building_type].label} </b>
              </p>
            ) : ''}

            {(+values.additional_info[index].building_type < 4 || +values.additional_info[index].building_type === 6) && (
              <p className="new-table--row--item justify-content-between mh60">
                Building access:
                <b>
                  <span>{values.additional_info[index].building_access_start || ''}</span> &mdash;
                  <span>{values.additional_info[index].building_access_end || ''}</span>
                </b>
              </p>
            )}
            {!!values.additional_info[index].elevator_exist && (
              <p className="new-table--row--item justify-content-between mh60">
                Elevator reservation:
                <b>
                  <span>{values.additional_info[index].elevator_reservation_start || ''}</span> &mdash;
                  <span>{values.additional_info[index].elevator_reservation_end || ''}</span>
                </b>
              </p>
            )}
            {!!values.additional_info[index].elevator_floor && (
              <p className="new-table--row--item justify-content-between mh60">
                Which floor:
                <b>{values.additional_info[index].elevator_floor || ''}</b>
              </p>
            )}
            {!!values.additional_info[index].distance_elevator_apartment && (
              <p className="new-table--row--item justify-content-between mh60">
                What is a distance from apartment door to the elevator (Yards):
                <b>{values.additional_info[index].distance_elevator_apartment || ''}</b>
              </p>
            )}
            {!!values.additional_info[index].distance_elevator_parking && (
              <p className="new-table--row--item justify-content-between mh60">
                What is the distance from elevator to the loading dock/truck parking - (Yards):
                <b>{values.additional_info[index].distance_elevator_parking || ''}</b>
              </p>
            )}
            {(values.additional_info[index].hardwood_protect ||
              values.additional_info[index].marble_protect ||
              values.additional_info[index].carpet_protect) && (
                <p className="new-table--row--item justify-content-between mh60">
                  Need floor/wall protection:
                  <b>
                    {values.additional_info[index].hardwood_protect && <span>Hardwood; </span>}
                    {values.additional_info[index].marble_protect && <span>Marble; </span>}
                    {values.additional_info[index].carpet_protect && <span>Carpet </span>}
                  </b>
                </p>
              )}
            {!!values.additional_info[index].hoisting && (
              <p className="new-table--row--item justify-content-between mh60">
                Need Hoisting of heavy items:
                <b>Yes</b>
              </p>
            )}
            {!!values.additional_info[index].hoisting_note && (
              <p className="new-table--row--item justify-content-between mh60">
                Hoisting note:
                <b>{values.additional_info[index].hoisting_note}</b>
              </p>
            )}
            {!!values.additional_info[index].apt_finished && (
              <p className="new-table--row--item justify-content-between mh60">
                Inventory List
                <b>{values.additional_info[index].apt_note}</b>
              </p>
            )}
            {!!values.additional_info[index].packing && (
              <p className="new-table--row--item justify-content-between mh60">
                Are you packing miscellaneous items yourself?
                <b>{values.additional_info[index].packing_note}</b>
              </p>
            )}
            {!!values.additional_info[index].fragile && (
              <p className="new-table--row--item justify-content-between mh60">
                Do you have any fragile items such as mirror, tv, glass, picture, paintings, or any other?
                <b>{values.additional_info[index].fragile_note}</b>
              </p>
            )}
            {!!values.additional_info[index].wrap && (
              <p className="new-table--row--item justify-content-between mh60">
                Do you have any fragile items such as mirror, tv, glass, picture, paintings, or any other?
                <b>{values.additional_info[index].wrap_note}</b>
              </p>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

import React, { useCallback, useMemo } from 'react';
import { useFormik } from 'formik';
import { useModal } from 'context/modal';
import { useBookedJobsByDate } from 'hooks/query/useCrew';
import { formatFullDBDateTime, formatFullDBDate, formatTimeAPMeridiem, add } from 'helpers/dateHelper';
import { useDefault } from 'hooks/query/useCommon';
import { ModalButton } from 'components/newComponents/Buttons';
import Modal from 'components/newComponents/Modal';
import DateSelect from 'components/DateSelect';
import TimeSelect from 'components/TimeSelect';
import { Chart } from 'react-google-charts';
import chartsConfig from 'config/googleCharts';
import TooltipDayAvailability from './TooltipDayAvailability';

import './styles.scss';

const DayAvailability = () => {
  const { modalNames, close, info } = useModal();

  const isVisible = info(modalNames.dayAvailability).visible;
  const params = info(modalNames.dayAvailability).params;
  const closeHandler = useCallback(() => close(modalNames.dayAvailability), [close, modalNames.dayAvailability]);

  const {
    data: { crews },
  } = useDefault();

  const { values, setValues } = useFormik({
    initialValues: {
      minimum_hours: params.minimum_hours,
      start_date: params.start_date,
      stop_date: params.stop_date,
      date: params.date,
      bookedJobs: [],
      scheduledCrews: [],
      rates: {},
    },
  });

  useBookedJobsByDate(
    { start_date: values.start_date, stop_date: values.stop_date, date: values.date },
    {
      onSuccess: (data) => setValues({ ...values, bookedJobs: data.list, rates: data.rates, scheduledCrews: data.scheduled_crews }),
    }
  );

  const dateHandler = useCallback(
    ({ date, name }) => {
      setValues({
        ...values,
        [name]: name === 'date' ? formatFullDBDate(date) : formatFullDBDateTime(date),
        stop_date: formatFullDBDateTime(add(date, params.minimum_hours, 'hours')),
      });
    },
    [params.minimum_hours, setValues, values]
  );

  const jobsList = useMemo(() => {
    return values.bookedJobs.map((day_item) => {
      const tooltip_html = TooltipDayAvailability(day_item);
      return [
        values.date,
        `${day_item.job_title}|${day_item.qty_man}men|${day_item.from_zipcode}-${day_item.to_zipcode}
        |${formatTimeAPMeridiem(day_item.date_start)} - ${formatTimeAPMeridiem(day_item.date_stop)}`,
        '#70a5c2',
        tooltip_html,
        new Date(day_item.date_start),
        new Date(day_item.date_stop),
      ];
    });
  }, [values]);

  return (
    <Modal style={{ width: 1200 }} header="Day Availability" show={isVisible} onHide={closeHandler}>
      <div className="day-availability">
        <div className="new-table modal">
          <div className="new-table--row">
            <p className="new-table--row--item dark">Date</p>
            <p className="new-table--row--item dark">Time</p>
          </div>
          <div className="new-table--row">
            <div className="new-table--row--item select">
              <DateSelect minDate={new Date()} selected={values.date} onChange={dateHandler} placeholder="Due date" name="date" />
            </div>
            <div className="new-table--row--item select">
              <TimeSelect name="start_date" onChange={dateHandler} placeholder="* Select time" value={values.start_date} />
            </div>
          </div>

          <div className="new-table">
            <div className="new-table--row">
              <div className="new-table--row--item w40 no-side-shadows">
                <b>
                  Available crews:
                  {crews.length - values.scheduledCrews.length}/{crews.length}
                </b>
              </div>

              <div className="new-table--row--item w10 no-side-shadows">
                <b>Rates:</b>
              </div>
              {Object.entries(values.rates).map((rate) => (
                <div className="new-table--row--item w10 no-side-shadows" key={rate[0]}>
                  <b>{rate[0]} men</b>
                </div>
              ))}
            </div>
            <div className="new-table--row">
              <div className="new-table--row--item w50 no-side-shadows" />

              {Object.entries(values.rates).map((rate) => (
                <div className="new-table--row--item w10 no-side-shadows" key={rate[0]}>
                  ${rate[1]}
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="day-availability-body">
          {values.bookedJobs.length > 0 && (
            <Chart
              className="crew-chart"
              chartType="Timeline"
              loader={<div>Loading Chart</div>}
              data={[chartsConfig, ...jobsList]}
              options={{
                timeline: {
                  showRowLabels: true,
                  colorByRowLabel: true,
                },
                avoidOverlappingGridLines: true,
                allowHtml: true,
                hAxis: { format: 'hh:mm a' },
                axes: {
                  x: {
                    0: { side: 'top' },
                  },
                },
              }}
            />
          )}
        </div>
      </div>
      <div className="footer-buttons">
        <ModalButton title="Close" variant="close" onClick={closeHandler} />
      </div>
    </Modal>
  );
};

export default DayAvailability;

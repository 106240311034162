import React from 'react';

const BookingTotal = ({ list }) => {
  if (!list) return null;
  if (list.length === 0) return null;

  let title = [];
  let lead = [];
  let booked = [];
  let canceled = [];
  let lost = [];
  let completed = [];

  list.forEach((item) => {
    title.push(
      <div key={item.id} className="reports-table-column reports-table-column--17 reports-table-column--title bcg-light">
        {item.name}
      </div>
    );
    lead.push(
      <div key={item.id} className="reports-table-column reports-table-column--17">
        {item.jobs.jobs}
      </div>
    );
    booked.push(
      <div key={item.id} className="reports-table-column reports-table-column--17">
        {item.jobs.booked}
      </div>
    );
    canceled.push(
      <div key={item.id} className="reports-table-column reports-table-column--17">
        {item.jobs.canceled}
      </div>
    );
    lost.push(
      <div key={item.id} className="reports-table-column reports-table-column--17">
        {item.jobs.lost}
      </div>
    );
    completed.push(
      <div key={item.id} className="reports-table-column reports-table-column--17">
        {item.jobs.completed}
      </div>
    );
  });

  return (
    <div className="reports-table">
      <div className="reports-table-row">
        <div className="reports-table-column reports-table-column--17 reports-table-column--title">-</div>
        {title}
      </div>
      <div className="reports-table-row">
        <div className="reports-table-column reports-table-column--17 reports-table-column--title bcg-light">Lead</div>
        {lead}
      </div>
      <div className="reports-table-row">
        <div className="reports-table-column reports-table-column--17 reports-table-column--title bcg-light">Booked</div>
        {booked}
      </div>
      <div className="reports-table-row">
        <div className="reports-table-column reports-table-column--17 reports-table-column--title bcg-light">Completed</div>
        {completed}
      </div>
      <div className="reports-table-row">
        <div className="reports-table-column reports-table-column--17 reports-table-column--title bcg-light">Lost</div>
        {lost}
      </div>
      <div className="reports-table-row">
        <div className="reports-table-column reports-table-column--17 reports-table-column--title bcg-light">Canceled</div>
        {canceled}
      </div>
    </div>
  );
};

export default BookingTotal;

import React, { Component } from 'react';
import moment from 'moment';
import ChartComponent from '../ChartComponent';

class CommunicationsCharts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bookedJobs: props.bookedJobs,
      from: props.data.from,
      to: props.data.to,
      booked_series: [],
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.bookedJobs.length !== prevProps.bookedJobs.length) {
      this.getChartInfo(this.props.bookedJobs, this.props.data.from, this.props.data.to);
    }
  }

  componentDidMount() {
    this.getChartInfo(this.props.bookedJobs, this.props.data.from, this.props.data.to);
  }

  getChartInfo(bookedJobs, fromMain, toMain) {
    if (!fromMain) return false;
    const from = +moment(fromMain).format('X');
    const to = toMain ? +moment(toMain).format('X') : +moment().format('X');

    let options = [];
    let booked_series = [];

    if (Number(moment.unix(from).diff(moment.unix(to).add(1, 'days'), 'days', true)) < 2) {
      for (let i = 0; i < 2; i++) {
        options.push(moment.unix(from).add(-1, 'days').format('MM/DD'));
        booked_series.push(bookedJobs.length);
      }
    }

    if (
      Number(moment.unix(to).diff(moment.unix(from).add(1, 'days'), 'days', true)) > 1 &&
      Number(moment.unix(to).diff(moment.unix(from), 'weeks', true)) <= 2
    ) {
      let count_days = Number(moment.unix(to).dayOfYear()) - Number(moment.unix(from).dayOfYear());
      for (let i = 0; i <= count_days; i++) {
        let booked = 0;
        options.push(moment.unix(from).add(i, 'days').format('MM/DD'));
        bookedJobs.forEach((job) => {
          if (moment(job.created_at, 'YYYY-MM-DD hh:mm:ss').format('MM/DD') === moment.unix(from).add(i, 'days').format('MM/DD')) {
            booked++;
          }
        });
        booked_series.push(booked);
      }
    }

    if (
      Number(moment.unix(to).diff(moment.unix(from), 'weeks', true)) > 2 &&
      Number(moment.unix(to).diff(moment.unix(from), 'month', true).toFixed()) <= 1
    ) {
      let count_weeks = Number(moment.unix(to).format('WW')) - Number(moment.unix(from).format('WW'));
      for (let i = 0; i <= count_weeks; i++) {
        let booked = 0;
        options.push(
          moment.unix(from).add(i, 'weeks').isoWeekday(1).format('MM/DD') + ' - ' + moment.unix(from).add(i, 'weeks').isoWeekday(5).format('MM/DD')
        );
        bookedJobs.forEach((job) => {
          if (moment(job.created_at, 'YYYY-MM-DD hh:mm:ss').format('W') === moment.unix(from).add(i, 'weeks').format('W')) {
            booked++;
          }
        });
        booked_series.push(booked);
      }
    }

    if (Number(moment.unix(to).diff(moment.unix(from), 'months', true).toFixed()) > 1) {
      let count_months = Number(moment.unix(to).diff(moment.unix(from), 'months', true).toFixed());
      for (let i = 0; i <= count_months; i++) {
        options.push(moment.unix(from).add(i, 'month').format('MM/YY'));
        let booked = 0;
        bookedJobs.forEach((job) => {
          if (moment(job.created_at, 'YYYY-MM-DD hh:mm:ss').format('MM/YY') === moment.unix(from).add(i, 'month').format('MM/YY')) {
            booked++;
          }
        });
        booked_series.push(booked);
      }
    }
    this.setState({
      options: options,
      booked_series: booked_series,
    });
  }

  render() {
    if (this.props.bookedJobs.length === 0) return null;
    if (this.props.data.type === 'communication') return null;

    return (
      <div className="chart-container">
        <div className="chart-item">
          <span>Booked</span>
          <ChartComponent options={this.state.options} series={this.state.booked_series} />
        </div>
      </div>
    );
  }
}

export default CommunicationsCharts;

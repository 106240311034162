import React, { Fragment } from 'react';
import classNames from 'classnames';

const Checkbox = (props) => {
  const { right = true, label, className, error, touched, onChange, checked, ...rest } = props;

  return (
    <div className="d-flex flex-column w100 position-relative">
      <label className={classNames('', className)}>
        {right ? (
          <Fragment>
            <span>{label}</span>
            <input type="checkbox" {...rest} checked={checked} onChange={onChange} />
          </Fragment>
        ) : (
          <Fragment>
            <input type="checkbox" {...rest} checked={checked} onChange={onChange} />
            <span>{label}</span>
          </Fragment>
        )}
      </label>
      {error && (
        <span style={{ position: 'absolute', bottom: -20 }} className="error">
          {error}
        </span>
      )}
    </div>
  );
};

export default Checkbox;

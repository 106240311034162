import React from 'react';
import Icon from 'components/Icon';
import history from 'utils/history';
import routes from 'config/routes';
import { statusNumberToName } from 'helpers/statuses';

const CalendarSubItem = ({ item }) => {
  const { id, title, status, customer_name, home_phone, from_address, from_city, from_state, from_zip, to_address, to_city, to_state, to_zip } = item;

  const handleClick = (item_id) => () => history.push({ pathname: `${routes.JOBS}/all/${item_id}` });

  return (
    <div key={id} className="row" onClick={handleClick(id)}>
      <div className="main-job">
        <div className="col-2">
          <Icon className="status" icon={statusNumberToName(status)} size={18} />
          <p>{title}</p>
        </div>
        <div className="col-2 ">
          <p className="nowrap">{customer_name}</p>
        </div>
        <div className="col-2">
          <p className="nowrap">{home_phone}</p>
        </div>
        <div className="col-3">
          <p>
            {from_address} {from_city} {from_state} {from_zip}
          </p>
        </div>
        <div className="col-3">
          <p>
            {to_address} {to_city} {to_state} {to_zip}
          </p>
        </div>
      </div>
    </div>
  );
};

export default CalendarSubItem;

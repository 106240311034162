import React, { useState, useCallback } from 'react';
import Swal from 'sweetalert2';

const JobWithoutDeposit = ({ link, jobId, status, sendMail }) => {
  const [isCopied, setIsCopied] = useState(false);
  const [isMailSent, setIsMailSent] = useState(false);

  const copyLink = useCallback(() => {
    navigator.clipboard.writeText(link).then(() => setIsCopied(true));
  }, [link]);

  const sendMailHandler = useCallback(() => {
    const data = {
      template_id: 0,
      template_edited: 0,
      previous_status: status,
      template: 'payment_link',
      include_pdf: false,
      job_id: jobId,
      template_html: '',
      edit_template: false,
      show_partners: false,
      link: link,
    };
    sendMail(data, {
      onSuccess: () => setIsMailSent(true),
      onError: () => Swal.fire('Error.', "Email isn't sent. Invalid credentials. Please check your SMTP settings.", 'error'),
    });
  }, [link, jobId, status, sendMail]);

  return (
    <div className="d-flex flex-column align-items-center">
      <p>Job can't be Booked without deposit.</p>
      <p>
        Please pay by next &nbsp;
        <a target="_blank" rel="noopener noreferrer" href={link}>
          link
        </a>
      </p>
      <p>
        {isCopied ? (
          <span style={{ color: 'green', marginTop: 10 }}>Copied!</span>
        ) : (
          <button type="button" className="link" onClick={copyLink}>
            Copy link to clipboard
          </button>
        )}
      </p>
      <p>
        {isMailSent ? (
          <span style={{ color: 'green', marginTop: 10 }}>Mail sent!</span>
        ) : (
          <button type="button" className="link" onClick={sendMailHandler}>
            Send link to customer
          </button>
        )}
      </p>
    </div>
  );
};

export default JobWithoutDeposit;

import React, { Fragment, memo, useCallback, useMemo, useState } from 'react';
import * as qs from 'querystring';
import { useLocation, useHistory } from 'react-router-dom';
import { routePaths } from 'config/routes';
import { emailTypes, mailStatuses, inboundCallTypes, reminderTypes } from './types';
import { allOption, outboxOptions, inboxOptions, callOptions, callOptionsManager } from './filterOptions';
import { updateRouteParams } from 'utils/history';
import { formatFullDate } from 'helpers/dateHelper';
import updatePriority from 'helpers/UpdatePriority';
import { useAuth } from 'context/auth';
import { useModal } from 'context/modal';
import { useDefault } from 'hooks/query/useCommon';
import { useMailsDelete, useMailsRead } from 'hooks/query/useCommunications';
import Icon from 'components/Icon';
import DateSelect from 'components/DateSelect';
import DropdownMenu from 'components/DropdownMenu';
import Select from 'components/newComponents/Select';
import Dropdown from 'components/Dropdown';
import './FilterSearch.scss';

const Filter = memo(({ type, selectedItems, onRefetch }) => {
  const { search, pathname } = useLocation();
  const history = useHistory();
  const params = qs.parse(search.replace('?', ''));

  const { user } = useAuth();
  const { modalNames, open } = useModal();
  const { data: defaultItems } = useDefault();

  const [filterDate, setFilterDate] = useState({ to: params.to, from: params.from });
  const [inputSearch, setInputSearch] = useState('');

  const { priorityStyle, priorityTitle } = updatePriority(params.priority);

  const { mutate: readMails } = useMailsRead();
  const { mutate: deleteMails } = useMailsDelete();

  const handleSelect = useCallback(({ name, value }) => history.push({ pathname, search: qs.stringify({ ...params, [name]: value }) }), [
    params,
    pathname,
    history,
  ]);

  const handleChangeDate = useCallback(({ name, date }) => setFilterDate({ ...filterDate, [name]: date }), [filterDate]);

  const handleChange = useCallback(({ target }) => setInputSearch(target.value), []);

  const handleOptionOnClick = useCallback(
    (type) => {
      const to = updateRouteParams(routePaths.COMMUNICATIONS, { type });
      const currentLocation = pathname;

      if (currentLocation === to && !search) {
        onRefetch();
        return;
      }

      history.push(to);
    },
    [history, pathname, search, onRefetch]
  );

  const handleSearchByDate = useCallback(() => {
    if (!filterDate.from || !filterDate.to) return;

    history.push({ pathname, search: qs.stringify({ ...params, from: formatFullDate(filterDate.from), to: formatFullDate(filterDate.to) }) });
  }, [filterDate.from, filterDate.to, pathname, params, history]);

  const handleSearchByInputValue = () => {
    if (!inputSearch) return;

    history.push({ pathname, search: qs.stringify({ ...params, value: inputSearch }) });
  };

  const handleChangePriority = useCallback(
    (priority) => {
      history.push({ pathname, search: qs.stringify({ ...params, priority }) });
    },
    [pathname, params, history]
  );

  const handleReadMails = useCallback(() => {
    readMails({ ids: selectedItems });
  }, [selectedItems, readMails]);

  const handleDeleteMails = useCallback(() => {
    deleteMails({ ids: selectedItems });
  }, [selectedItems, deleteMails]);

  const isMailFilter = useMemo(() => {
    return type.includes('email') || type.includes('reminders') || type === 'scheduled';
  }, [type]);

  const defaultFilter = useCallback(
    (type) => {
      switch (type) {
        default:
          return null;
        case 'mail_statuses':
          if (params?.filter_title) {
            return mailStatuses.find((item) => item.value === params?.filter_title)?.value;
          }
          return mailStatuses[0].value;

        case 'inbound_call':
          if (params?.filter_title) {
            return inboundCallTypes.find((item) => item.value === params?.filter_title)?.value;
          }
          return inboundCallTypes[0].value;
      }
    },
    [params.filter_title]
  );

  const renderFilter = useMemo(() => {
    switch (type) {
      default:
        return null;
      case 'outbox_email':
        return (
          <div className="w20 filter-outbox" style={{ background: 'red' }}>
            <Select
              options={mailStatuses}
              defaultValue={defaultFilter('mail_statuses')}
              name="filter_title"
              placeholder="Select Email"
              value={params.filter_title}
              onChange={({ value }) => handleSelect({ name: 'filter_title', value })}
            />
          </div>
        );
      case 'inbound_calls':
        return (
          <div className="w20" style={{ background: 'red' }}>
            <Select
              name="filter_title"
              options={inboundCallTypes}
              value={params.filter_title}
              placeholder="Select Calls Type"
              defaultValue={defaultFilter('inbound_call')}
              onChange={({ value }) => handleSelect({ name: 'filter_title', value })}
            />
          </div>
        );
      case 'scheduled':
      case 'reminders_sent':
        return (
          <div className="w20">
            <Select
              name="filter_title"
              options={reminderTypes}
              placeholder="Reminder type"
              value={params.filter_title}
              defaultValue={reminderTypes[type === 'reminders_sent' ? 0 : 1].value}
              onChange={({ value }) => handleSelect({ name: 'filter_title', value })}
            />
          </div>
        );
    }
  }, [type, defaultFilter, params.filter_title, handleSelect]);

  const companiesList = useMemo(() => {
    if (!defaultItems) return [{ value: 0, label: 'All' }];

    let result = [];

    result = defaultItems?.company.map((company) => ({
      value: company.id,
      label: company.company_name,
    }));
    result.unshift({ value: 0, label: 'All' });

    return result;
  }, [defaultItems]);

  const defaultCompany = useMemo(() => {
    if (!params.company_id) return null;

    return companiesList.find((item) => item.value === Number(params.company_id))?.value;
  }, [companiesList, params.company_id]);

  const filterOptions = useMemo(() => {
    switch (type) {
      default:
        return [allOption];
      case 'inbox_email':
      case 'reminders_sent':
        return inboxOptions;
      case 'outbox_email':
      case 'reminders_replied':
        return [allOption, ...outboxOptions];
    }
  }, [type]);

  const callsFilterOptions = useMemo(() => {
    if (user.manager) return callOptionsManager;
    return callOptions;
  }, [user.manager]);

  if (emailTypes.includes(type)) {
    return (
      <div className="filter-communications">
        <div className="filter-communications--item w50">
          {isMailFilter && (
            <div className="w5">
              <button
                onClick={() => {
                  open(modalNames.newEmail, {
                    job_id: 0,
                    message_id: 0,
                    type: 'new',
                    title: 'New Email',
                    email_cc: [],
                    email_to: '',
                    company_id: 0,
                  });
                }}
              >
                <Icon icon="add" title="New Mail" />
              </button>
            </div>
          )}

          {renderFilter}

          {(type === 'outbox_email' || type === 'inbox_email') && (
            <div className="w35 d-flex align-items-center">
              <DateSelect selected={filterDate.from} onChange={handleChangeDate} placeholder="From date" name="from" />
              <span>&mdash;</span>
              <DateSelect selected={filterDate.to} onChange={handleChangeDate} placeholder="To date" name="to" />
              <button onClick={handleSearchByDate}>
                <Icon icon="search" />
              </button>
            </div>
          )}
        </div>
        <div className="filter-communications--item w50">
          <div className="w25 d-flex align-items-center">
            <input name="value" value={inputSearch} placeholder="Search..." onChange={handleChange} type="text" />
            <button type="button" onClick={handleSearchByInputValue}>
              <Icon icon="search" />
            </button>
          </div>

          <div className="w50">
            <Select
              name="company_id"
              options={companiesList}
              value={Number(params.company_id)}
              placeholder="Select company"
              defaultValue={defaultCompany}
              onChange={({ value }) => handleSelect({ name: 'company_id', value })}
            />
          </div>

          <div className="w5">
            <DropdownMenu
              button={{
                iconName: 'job_filter',
                iconTitle: 'Filters',
              }}
              optionOnClick={handleOptionOnClick}
              options={filterOptions}
            />
          </div>
          <div className="w5">
            {selectedItems.length > 0 && (
              <DropdownMenu
                button={{ iconName: 'pin3', iconTitle: 'Details' }}
                options={[
                  { buttonClassName: 'link', clickFunc: handleReadMails, buttonText: 'Mark as read' },
                  { buttonClassName: 'link', clickFunc: handleDeleteMails, buttonText: 'Delete' },
                ]}
              />
            )}
          </div>
          <div className="w5">
            <Dropdown
              iconSize={22}
              iconName={priorityStyle}
              dropdownClass="w5 dropdown-status"
              divClassname="drop-menu"
              horizontal
              iconTitle={priorityTitle}
            >
              <Fragment>
                {defaultItems?.statuses?.job_priority?.map((prior, index) => (
                  <li key={prior}>
                    <button type="button" onClick={() => handleChangePriority(index)}>
                      <Icon icon={prior.toLowerCase()} size={20} title={prior} />
                    </button>
                  </li>
                ))}
              </Fragment>
            </Dropdown>
          </div>
        </div>
      </div>
    );
  }
  return (
    <Fragment>
      {type !== 'inbox_email' && type !== 'outbox_email' && (
        <div className="new-table mb-3">
          <div className="new-table--row">
            <div className="new-table--row--item no-side-shadows select">
              <span style={{ width: '45%' }}>
                <DateSelect selected={filterDate.from} onChange={handleChangeDate} placeholder="From date" name="from" />
              </span>
              <span>&mdash;</span>
              <span style={{ width: '45%' }}>
                <DateSelect selected={filterDate.to} onChange={handleChangeDate} placeholder="To date" name="to" />
              </span>
            </div>
            <span className="new-table--row--item no-side-shadows select" />
            <div className="new-table--row--item no-side-shadows w5 select">
              <button type="button" onClick={handleSearchByDate}>
                <Icon icon="search" />
              </button>
            </div>
            <div className="new-table--row--item no-side-shadows w5 select">
              {type !== 'lead_missed_call' && type !== 'lead_unreplied_sms' && (
                <DropdownMenu
                  button={{
                    iconName: 'sort',
                    iconTitle: 'Filters',
                  }}
                  optionOnClick={handleOptionOnClick}
                  options={callsFilterOptions}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
});

export default Filter;

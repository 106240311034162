import { useEffect, useCallback } from 'react';
import { useFormik } from 'formik';
import Api from './api';
import { sumFees } from './utils';

const useForm = (jobId, document) => {
  const { values: data, setValues: setDataValues } = useFormik({
    initialValues: {},
    enableReinitialize: true,
  });

  const { values: payment, setFieldValue: setPaymentField } = useFormik({
    initialValues: {
      job_id: 0,
      amount: 100,
      // type: 'local',
      pickup_payment_type: '',
      storage_payment_type: '',
      destination_payment_type: '',
      pay_for_pickup: false,
      pay_for_storage: false,
      pay_for_destination: false,
    },
    enableReinitialize: true,
  });

  const changePickupPayType = (event) => {
    setPaymentField('pickup_payment_type', event.target.value);
  };

  const changeStoragePayType = (event) => {
    setPaymentField('storage_payment_type', event.target.value);
  };

  const updateTotal = useCallback(
    (amount) => {
      setPaymentField('amount', amount);
    },
    [setPaymentField]
  );

  const changeDestinationPayType = (event) => {
    setPaymentField('destination_payment_type', event.target.value);
  };

  const payForPickup = () => {
    const isSelected = payment.pay_for_pickup;

    setPaymentField('pay_for_pickup', !isSelected);

    if (isSelected) {
      setPaymentField('pickup_payment_type', '');
    }
  };

  const payForStorage = () => {
    const isSelected = payment.pay_for_storage;

    setPaymentField('pay_for_storage', !isSelected);

    if (isSelected) {
      setPaymentField('storage_payment_type', '');
    }
  };

  const payForDestination = () => {
    const isSelected = payment.pay_for_destination;

    setPaymentField('pay_for_destination', !isSelected);

    if (isSelected) {
      setPaymentField('destination_payment_type', '');
    }
  };

  useEffect(() => {
    let amount = 0;
    switch (payment.pickup_payment_type) {
      default:
        break;
      case 'pickup_full':
        amount += parseFloat(data.remaining);
        break;
      case 'pickup_deposit':
        amount += parseFloat(data.deposit);
        break;
    }

    switch (payment.storage_payment_type) {
      default:
        break;
      case 'storage_full':
        amount += parseFloat(data.storage_calc.balance);
        break;
      case 'storage_period':
        amount += parseFloat(data.storage_calc.storage_rate) + sumFees(data.storage_calc);
        break;
    }

    switch (payment.destination_payment_type) {
      default:
        break;
      case 'destination_full':
        amount += parseFloat(data.destination_total);
        break;
      case 'destination_deposit':
        amount += parseFloat(data.deposit);
        break;
    }

    updateTotal(amount);
  }, [data, updateTotal, payment]);

  useEffect(() => {
    async function fetchData() {
      const data = await Api.getData(jobId, document);

      if (data) {
        setDataValues(data);

        if (data.type === 'storage') {
          if (data.deposit_paid === 0 && data.remaining !== 0) {
            setPaymentField('pickup_payment_type', 'pickup_deposit');
            setPaymentField('storage_payment_type', 'storage_period');
            setPaymentField('pay_for_pickup', true);
            setPaymentField('pay_for_storage', true);
          }
        } else if (parseInt(data.deposit_paid, 10) === 1) {
          setPaymentField('pickup_payment_type', 'pickup_full');
        }
        setPaymentField('job_id', jobId);
      }
    }

    fetchData();
  }, [jobId, setPaymentField, setDataValues]);

  return {
    payment,
    data,
    actions: {
      changePickupPayType,
      changeStoragePayType,
      changeDestinationPayType,
      payForPickup,
      payForStorage,
      payForDestination,
    },
  };
};

export { useForm };

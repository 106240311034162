const makeFormData = (obj) => {
  let newArr = new FormData();

  for (let i in obj) {
    if (obj.hasOwnProperty(i)) {
      if (i === 'email_to') {
        if (Array.isArray(i)) {
          obj[i].forEach((item, key) => {
            newArr.append(`email_to[]`, item);
          });
        } else {
          newArr.append(`email_to[]`, obj[i]);
        }
      } else {
        newArr.append(i, obj[i]);
      }
    }
  }
  return newArr;
};

export default makeFormData;

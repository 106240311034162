export default {
  job_type: 'lead',
  storage_rate: '',
  storage_fees: {},
  storage_rate_type: '',
  move_type: 'local',
  unit_sizes: null,
  calc_type: 'per_cf',
  storage_id: null,
  credit_rate: 0,
  do_not_send: false,
  company_id: '',
  pickup_date: '',
  pickup_time: '8:00 AM',
  move_size_id: '',
  default_rate: 0,
  from_zipcode: '',
  from_city: '',
  from_state: '',
  delivery_date: '',
  delivery_time: '',
  from_required_coi: '',
  to_required_coi: '',
  to_zipcode: '',
  to_city: '',
  to_state: '',
  from_name: '',
  from_email: '',
  from_home_phone: '',
  from_address: '',
  from_building_name: '',
  from_building_id: 0,
  to_address: '',
  to_building_name: '',
  to_building_id: 0,
  chat_id: null,
  call_id: null,
  note: '',
  travel_distance: '',
  pickup_distance: '',
  drop_off_to_office: '',
  hear_about: '',
  rate_type: 1,
  flat_rate: 0,
  labor_type: 'default',
  from_est_time: null,
  to_est_time: null,
  qty_trucks: '',
  to_apartment: '',
  from_apartment: '',
  packing_service: 'basic_service',
  full_packing: false,
  full_packing_rate: null,
  packing_service_description: null,
  to_place_id: '',
  from_place_id: '',
  // add

  building_type: 0,
  building_type_from: null,
  building_type_to: null,
  building_access_start: '',
  building_access_end: '',
  elevator_exist_from: 0,
  elevator_reservation_from_start: '',
  elevator_reservation_from_end: '',
  elevator_from_floor: '',
  distance_elevator_apartment: '',
  distance_elevator_parking: '',
  stairs_from: 0,
  hardwood_protect: false,
  distance_elevator_apartment_from: '',
  distance_elevator_parking_from: '',
  marble_protect_from: 0,
  carpet_protect_from: 0,
  hoisting_from: 0,
  hoisting_note_from: '',
  building_access_to_start: null,
  building_access_to_end: null,
  elevator_exist_to: 0,
  elevator_reservation_to_start: '',
  elevator_reservation_to_end: '',
  elevator_to_floor: '',
  distance_elevator_apartment_to: '',
  distance_elevator_parking_to: '',
  stairs_to: 0,
  hardwood_protect_to: 0,
  hardwood_protect_from: 0,
  marble_protect_to: 0,
  carpet_protect_to: 0,
  hoisting_to: 0,
  hoisting_note_to: '',
  packing: 0,
  packing_note: '',
  fragile: 0,
  fragile_note: '',
  wrap: 0,
  wrap_note: '',
  elevator_from: 0,
  elevator_to: 0,
  apt_finished: 0,
  apt_note: '',
  insurance: false,
  job_inventory: [],
  job_inventory_total: 0,
  total_price: '',
  lead_provider_id: null,
  storage_payment_method: null,

  // local state
  fromEstTime: null,
  toEstTime: null,
};

import axios from 'axios';
import showError from 'utils/errorHandler';

class Api {
  constructor() {
    this.serverApi = process.env.REACT_APP_API_URL;
    this.client = axios.create({ baseURL: this.serverApi });
  }

  checkSign = async (jobId) => {
    try {
      const response = await this.client.post(`/api/customerDocuments/storageContractSignCheck`, {
        job_id: jobId,
      });

      return response;
    } catch (error) {
      console.log(error);
    }
  };

  getImportantTerms = async () => {
    try {
      const response = await this.client.get('api/customer/getImportantTerms');

      return response;
    } catch (error) {
      console.log(error);
    }
  };

  sign = async (jobId, sign) => {
    try {
      await this.client.post(`/api/customerDocuments/saveSign`, {
        sign,
        job_id: jobId,
        document: 'storage_contract',
      });

      return true;
    } catch (error) {
      showError(error);
    }
  };
}

export default new Api();

import { useFormik } from 'formik';
import validationSchema from '../validationSchema';

export const useForm = (params) => {
  const { initialValues, onSubmit } = params;

  const { values, errors, touched, dirty, handleChange, handleSubmit } = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return {
    dirty,
    values,
    errors,
    touched,
    actions: { handleChange, handleSubmit },
  };
};

function mult(a, b) {
  return Number(a) * Number(b);
}

function calcJobTotalPaymentsByPaymentsType(list) {
  const listTotalByPayments = {};

  list.forEach(({ payments }) => {
    const jobTotalByPayments = calcJobTotalPaymentsByPayments(payments);

    Object.entries(jobTotalByPayments).forEach((item) => {
      const [key, value] = item;

      if (listTotalByPayments.hasOwnProperty(key)) {
        listTotalByPayments[key] = listTotalByPayments[key] + value;
        return;
      }

      listTotalByPayments[key] = value;
    });
  });

  return listTotalByPayments;
}

function calcJobTotalPaymentsByPayments(payments) {
  if (!payments || payments.length === 0) return {};

  const jobTotalByPayments = {};

  payments.forEach(({ type, amount }) => {
    if (jobTotalByPayments.hasOwnProperty(type)) {
      jobTotalByPayments[type] = Number(jobTotalByPayments[type]) + Number(amount);
      return;
    }
    jobTotalByPayments[type] = Number(amount);
  });

  return jobTotalByPayments;
}

function getValidPaymentsValue(payment) {
  if (!payment) return '';

  return '$' + payment.toFixed(2);
}

function getPackingCommision(jobList) {
  const jobCount = jobList.filter((i) => i.packing_commission > 0).length;

  const total = jobList.reduce((a, b) => a + b.packing_commission, 0).toFixed(2);
  const max = jobList.reduce((max, p) => (+p.packing_commission > max ? +p.packing_commission : max), +jobList[0].packing_commission).toFixed(2);
  const min = jobList
    .reduce((min, p) => (+p.packing_commission < min && +p.packing_commission !== 0 ? +p.packing_commission : min), Number(max))
    .toFixed(2);
  const avarage = ((total / jobCount) | 0).toFixed(2);

  return { total, min, max, avarage };
}

function getMaterialPrice(jobList) {
  const jobsPackings = jobList.map((job) => job.packing).filter((job) => Array.isArray(job) && job.length > 0);

  const getJobPackingMaterialPrice = (jobPacking) => {
    if (Array.isArray(jobPacking) && jobPacking.length > 0) {
      return jobPacking.reduce((a, b) => a + mult(b.material_price, b.qty), 0);
    }

    return 0;
  };

  const total = jobsPackings.reduce((a, b) => a + getJobPackingMaterialPrice(b), 0).toFixed(2);

  const min = jobsPackings
    .reduce(
      (min, b) => (getJobPackingMaterialPrice(b) < min && getJobPackingMaterialPrice(b) !== 0 ? getJobPackingMaterialPrice(b) : min),
      getJobPackingMaterialPrice(jobsPackings[0])
    )
    .toFixed(2);

  const max = jobsPackings
    .reduce((max, b) => (getJobPackingMaterialPrice(b) > max ? getJobPackingMaterialPrice(b) : max), getJobPackingMaterialPrice(jobsPackings[0]))
    .toFixed(2);

  const avarage = ((Number(total) / jobsPackings.length) | 0).toFixed(2);

  return { total, min, max, avarage };
}

function getPackingSoldPrice(jobList) {
  const jobsPackings = jobList.map((job) => job.packing).filter((job) => Array.isArray(job) && job.length > 0);

  const getJobPackingSoldPrice = (jobPacking) => {
    if (Array.isArray(jobPacking) && jobPacking.length > 0) {
      return jobPacking.reduce((a, b) => a + mult(b.price, b.qty), 0);
    }

    return 0;
  };

  const total = jobsPackings.reduce((a, b) => a + getJobPackingSoldPrice(b), 0).toFixed(2);

  const min = jobsPackings
    .reduce(
      (min, b) => (getJobPackingSoldPrice(b) < min && getJobPackingSoldPrice(b) !== 0 ? getJobPackingSoldPrice(b) : min),
      getJobPackingSoldPrice(jobsPackings[0])
    )
    .toFixed(2);

  const max = jobsPackings
    .reduce((max, b) => (getJobPackingSoldPrice(b) > max ? getJobPackingSoldPrice(b) : max), getJobPackingSoldPrice(jobsPackings[0]))
    .toFixed(2);

  const avarage = ((Number(total) / jobsPackings.length) | 0).toFixed(2);

  return { total, min, max, avarage };
}

function getPackingProfitPrice(materialPrice, packingSoldPrice, packingCommisionPrice) {
  const total = (Number(packingSoldPrice.total) - Number(materialPrice.total) - Number(packingCommisionPrice.total)).toFixed(2);
  const min = (Number(packingSoldPrice.min) - Number(materialPrice.min) - Number(packingCommisionPrice.min)).toFixed(2);
  const max = (Number(packingSoldPrice.max) - Number(materialPrice.max) - Number(packingCommisionPrice.max)).toFixed(2);
  const avarage = (Number(packingSoldPrice.avarage) - Number(materialPrice.avarage) - Number(packingCommisionPrice.avarage)).toFixed(2);

  return { total, min, max, avarage };
}

function getFullPackingProfitPrice(jobList) {
  const calcJobFullPackingProfit = (job) => Number(job.full_packing_rate) * Number(job.hours);

  const total = jobList.reduce((a, b) => a + calcJobFullPackingProfit(b), 0).toFixed(2);

  const initialMax = calcJobFullPackingProfit(jobList[0]);
  const max = jobList.reduce((max, p) => (calcJobFullPackingProfit(p) > max ? calcJobFullPackingProfit(p) : max), initialMax).toFixed(2);

  const min = jobList
    .reduce((min, p) => (calcJobFullPackingProfit(p) < min && calcJobFullPackingProfit(p) !== 0 ? calcJobFullPackingProfit(p) : min), Number(max))
    .toFixed(2);

  const jobWithFullPackingCount = jobList.filter((i) => +i.full_packing_rate > 0).length;

  const avarage = ((total / jobWithFullPackingCount) | 0).toFixed(2);

  return { total, min, max, avarage };
}

export {
  calcJobTotalPaymentsByPaymentsType,
  getValidPaymentsValue,
  getPackingCommision,
  calcJobTotalPaymentsByPayments,
  getMaterialPrice,
  getPackingSoldPrice,
  getPackingProfitPrice,
  getFullPackingProfitPrice,
};

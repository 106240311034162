import React, { memo } from 'react';
import Icon from 'components/Icon';
import { preventArrowUpDown } from 'utils/preventArrowUpDown';

const QuickViewItem = memo((props) => {
  const { item, qty, onChangeQty, onDeleteFromSave } = props;
  const { name, size, price, id } = item;

  const handleChangeQty = ({ target }) => onChangeQty(id, +target.value);

  const deleteItem = () => onDeleteFromSave(id);

  const total = +qty * +price;
  const sizes = JSON.parse(size);

  return (
    <div className="new-table--row">
      <div className="new-table--row--item w40">
        <span>{name}</span>
      </div>
      <div className="new-table--row--item w15 text-center fw-700">
        <span>
          {sizes.width} x {sizes.height} x {sizes.length}
        </span>
      </div>
      <div className="new-table--row--item w10 text-center fw-700">
        <span>${price}</span>
      </div>
      <div className="new-table--row--item w10 text-center">
        <input name="qty" type="number" className="text-center p-0 fw-700" value={qty} onChange={handleChangeQty} onKeyDown={preventArrowUpDown} />
      </div>
      <div className="new-table--row--item w15 text-center fw-700">
        <span>${total}</span>
      </div>
      <div className="new-table--row--item w10 text-center">
        <button type="button" style={{ height: 20 }} onClick={deleteItem}>
          <Icon icon="delete" size={20} style={{ marginRight: 0 }} />
        </button>
      </div>
    </div>
  );
});

export default QuickViewItem;

import React, { memo, useCallback, useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { useParams, useLocation, useHistory, generatePath } from 'react-router-dom';
import { useReadCommunication, useAssignMissedCall, useDeleteCommunication } from 'hooks/query/useCommunications';
import { routePaths } from 'config/routes';
import getAbbr from 'helpers/Abbr';
import Icon from 'components/Icon';
import { useAuth } from 'context/auth';
import { useDefault } from 'hooks/query/useCommon';
import { useModal } from 'context/modal';
import { formatPhoneNumberPlusOneAndDigits, formatPhoneNumberOnlyDigitsAndHyphen } from 'helpers/phoneFormat';
import queryKeys from 'config/queryKeys';

import './CommunicationItem.scss';

const CallItem = memo(({ onChangeParams, ...props }) => {
  const { user } = useAuth();
  const { type } = useParams();
  const history = useHistory();
  const { search } = useLocation();
  const { data: defaultItems = {} } = useDefault();
  const { mutate: assignMissedCall } = useAssignMissedCall();
  const { mutate: readCommunication } = useReadCommunication();
  const { mutate: deleteCommunication } = useDeleteCommunication();

  const queryClient = useQueryClient();

  const { open, close, info, modalNames } = useModal();
  const isTwilio = info(modalNames.twilio).visible;

  const companiesABBR = defaultItems?.companies_abbr;
  const agentsList = defaultItems?.users?.agents;

  const params = useMemo(() => {
    const searchParams = new URLSearchParams(search);

    const result = {};

    for (var pair of searchParams.entries()) {
      result[pair[0]] = pair[1];
    }

    return result;
  }, [search]);

  const handleOpenRecording = useCallback(() => {
    onChangeParams({
      selected: props.id,
      selected_type: 'chat',
    });

    // if (props.entity_type !== 'direct_voicemail' && props.entity_type !== 'general_voicemail') {
    readCommunication(
      {
        from: props.from,
        to: props.to,
        user_id: user.id,
        type: 'chat',
      },
      {
        onSuccess: () => queryClient.invalidateQueries(queryKeys.communications),
      }
    );
  }, [onChangeParams, props.from, props.to, props.id, queryClient, readCommunication, user.id]);

  const handleOpenJob = useCallback(
    (e) => {
      e.stopPropagation();
      if (props.job_id)
        history.push({
          pathname: generatePath(routePaths.JOBS, { id: props.job_id, tab: 'general', type: 'all' }),
        });
    },
    [history, props.job_id]
  );

  const phoneNumber = useMemo(() => {
    const userPhones = user.phones.map((item) => formatPhoneNumberPlusOneAndDigits(Object.values(item)[0]));

    if (userPhones.includes(props.from)) {
      return props.to;
    }
    return props.from;
  }, [user.phones, props.from, props.to]);

  const handleCall = (number, company_id) => {
    assignMissedCall({ call_id: props.id });
    if (isTwilio) {
      close(modalNames.twilio);
    }
    setTimeout(() => {
      open(modalNames.twilio, { number: number, companyID: company_id, callUp: true });
    }, 0);
  };

  const callInfo = useMemo(() => {
    if (props.status === 'callback') {
      return { icon: 'call', title: 'Callback', class: '' };
    }
    if (props.entity_type.match(new RegExp('voicemail', 'gi'))) {
      if (props.read > 0) {
        return { icon: 'assigned', title: 'Answered', class: '' };
      }
      return { icon: 'dropped', title: 'Missed', class: 'icon-col-red' };
    } else if (props.status === 'completed' && props.entity_type === 'outbound_calls') {
      return { icon: 'booked', title: 'Completed', class: '' };
    } else if (props.entity_type.includes('outbound')) {
      return { icon: 'call_forward', title: 'Outbound call', class: '' };
    } else if (props.status === 'completed') {
      return { icon: 'confirmed', title: 'Answered', class: '' };
    }
    return { icon: 'task_unfinished', title: 'Missed', class: '' };
  }, [props.entity_type, props.read, props.status]);

  const handleDeleteCall = useCallback(() => {
    deleteCommunication({
      entity_id: props.id,
      entity_type: 'call',
    });
  }, [deleteCommunication, props.id]);

  const companyInfo = useMemo(() => {
    const { company = [], lead_providers = [] } = defaultItems;
    const info = {
      companyId: '',
      companyName: '',
      leadProvider: '',
    };

    lead_providers.forEach((provider) => {
      if ('+1' + provider.phone.replace(/[^0-9]/g, '') === props.to) {
        info.leadProvider = provider.name;
        info.companyId = provider.company_id;
        info.companyName = getAbbr(provider.company_id, companiesABBR);
      }
    });

    if (info.companyName.length === 0) {
      if (props.company_id) {
        info.companyName = getAbbr(props.company_id, companiesABBR);
        info.companyId = props.company_id;
      } else {
        company.forEach((company) => {
          if ('+1' + company.phone.replace(/[^0-9]/g, '') === props.to) {
            info.companyName = getAbbr(company.id, companiesABBR);
            info.companyId = company.id;
          }
        });
      }
    }

    return info;
  }, [defaultItems, companiesABBR, props.company_id, props.to]);

  const callResult = useMemo(() => {
    if (props.call_result) {
      if (props.call_result.match(new RegExp('Dropped', 'gi'))) {
        return 'Dropped';
      }
      if (props.call_result === 'SPAM') {
        return 'SPAM';
      }
    }

    return '';
  }, [props.call_result]);

  const agentName = useMemo(() => {
    if (!props.user_id || !agentsList) return '';

    const agent = agentsList.find((i) => i.id === props.user_id);

    return agent.first_name.charAt(0) + agent.last_name.charAt(0);
  }, [props.user_id, agentsList]);

  const readRow = props.read === 1 ? 'email_read' : '';

  const to = useMemo(() => {
    if (companyInfo.leadProvider.length > 0) {
      return companyInfo.leadProvider;
    }

    const company = defaultItems.company?.find((item) => '+1' + item.phone.replace(/[^0-9]/g, '') === props.to);

    if (company) {
      return `${company.company_name} GMB`;
    }

    if (props.first_name || props.last_name) {
      return `${props.first_name ?? ''} ${props.last_name ?? ''}`;
    }

    return props.to;
  }, [companyInfo.leadProvider, defaultItems.company, props.to, props.first_name, props.last_name]);

  return (
    <div className={`comm-item border ${+params.selected === props.id ? 'active-row' : readRow}`} key={'call' + props.id}>
      <button className="w5" type="button" onClick={handleOpenRecording}>
        <Icon icon={callInfo.icon} title={callInfo.title} className={callInfo.class} />
      </button>

      <div className="comm-item" onClick={handleOpenRecording}>
        <span className="w10">{companyInfo.companyName}</span>
        <span className="w10" title={companyInfo.leadProvider.length ? 'LP | ' + companyInfo.leadProvider : ''}>
          {agentName}
        </span>

        <b className="w10">
          {callResult.length !== 0 ? (
            callResult
          ) : props.job_title ? (
            <button type="button" onClick={handleOpenJob}>
              {props.job_title}
            </button>
          ) : (
            <button
              type="button"
              onClick={() => {
                open(modalNames.quickForm, {
                  phone: formatPhoneNumberOnlyDigitsAndHyphen(phoneNumber?.slice(2)),
                  companyID: companyInfo.companyId,
                  callId: props.sid,
                });
              }}
              className="w10 main"
            >
              Assign
            </button>
          )}
        </b>

        <b className="w15">{props.from}</b>
        <b className="w15">{to}</b>
        <span className="w20">{props.date}</span>
      </div>

      <button type="button" className="w5" onClick={() => handleCall(phoneNumber, companyInfo.companyId)}>
        <Icon icon="call" title="Call" />
      </button>

      {type === 'lead_communications' && (
        <button type="button" onClick={handleDeleteCall} className="w5">
          <Icon icon="delete" title="Delete" />
        </button>
      )}
    </div>
  );
});

export default CallItem;

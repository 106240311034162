import React, { Fragment } from 'react';
import { useInfo, useTabInfo } from 'hooks/query/useJob';
import { useParams } from 'react-router-dom';
import { getRate } from 'helpers/getAppropriateValue';
import Final from './Final';
import Estimated from './Estimated';

const Resources = ({ type, field }) => {
  const { id, tab } = useParams();

  const {
    data: { calc_info: calcInfo, job_info: jobInfo },
  } = useInfo(id);

  const {
    data: { job_packing: jobPacking, job_fees: jobFees },
  } = useTabInfo(id, tab);

  const hourlyRate = getRate(calcInfo.rate, [calcInfo.manual_rate, calcInfo.credit_rate, calcInfo.full_packing_rate]);

  return (
    <Fragment>
      {type === 'final' ? (
        <Final type={type} rate={hourlyRate} calcInfo={calcInfo} jobInfo={jobInfo} jobPacking={jobPacking} jobFees={jobFees} field={field} />
      ) : (
        <Estimated type={type} rate={hourlyRate} calcInfo={calcInfo} jobInfo={jobInfo} jobPacking={jobPacking} jobFees={jobFees} field={field} />
      )}
    </Fragment>
  );
};

export default Resources;

import React, { Fragment, useMemo } from 'react';

function TotalSummary({ items }) {
  const totalCF = useMemo(() => items.reduce((sum, item) => sum + parseFloat(item.amount), 0), [items]);

  return (
    <Fragment>
      <div className="new-table--row">
        <div className="new-table--row--item">
          <span>Total CF per Inventory:</span>
        </div>

        <div className="new-table--row--item no-side-shadows">
          <span>{totalCF}</span>
        </div>
      </div>

      <div className="new-table--row">
        <div className="new-table--row--item">
          <span>CF To Pound:</span>
        </div>

        <div className="new-table--row--item no-side-shadows">
          <span>{parseFloat(totalCF * 7).toFixed(2)}</span>
        </div>
      </div>
    </Fragment>
  );
}

export default TotalSummary;

import React from 'react';
import ImportantList from './NotesList/ImportantList';
import ImportantDetail from './ImportantDetail';

const ImportantNotes = () => {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-4">
          <ImportantList />
        </div>
        <div className="col-8">
          <ImportantDetail />
        </div>
      </div>
    </div>
  );
};

export default ImportantNotes;

import React from 'react';
import SignatureCanvas from 'react-signature-canvas';
import Modal from 'components/newComponents/Modal';
import { ModalButton } from 'components/newComponents/Buttons';

import './index.scss';

export const Sign = ({ signCanvas, isOpen, onClose, onSubmit }) => {
  return (
    <Modal style={{ width: window.innerWidth - 60, maxWidth: 670 }} header="Sign Document" show={isOpen} onHide={onClose}>
      <div className="storage-document-modal-body">
        <SignatureCanvas ref={signCanvas} canvasProps={{ className: 'sign-canvas' }} />
      </div>
      <div className="footer-buttons">
        <ModalButton title="Cancel" variant="cancel" onClick={onClose} />
        <ModalButton title="Sign" variant="confirm" type="submit" onClick={onSubmit} />
      </div>
    </Modal>
  );
};

import React, { Component } from 'react';
import Icon from 'components/Icon';
import CalendarSubItem from './CalendarSubItem';

class CalendarEventItem extends Component {
  state = { isVisible: false };

  toggleVisible = () => this.setState((prevState) => ({ isVisible: !prevState.isVisible }));
  render() {
    const { isVisible } = this.state;
    return (
      <div className="days-group">
        <div className="day-head" onClick={this.toggleVisible}>
          <Icon icon={isVisible ? 'remove' : 'add'} size={22} />
          <p className="day-head-date">{this.props.day[0].pickup_date.toString().slice(0, 10)}</p>
        </div>
        <div className={`jobs-list container-fluid ${isVisible ? ' open' : ''}`}>
          {this.props.day?.map((item_time) => (
            <CalendarSubItem key={item_time.id} item={item_time} />
          ))}
        </div>
      </div>
    );
  }
}
export default CalendarEventItem;

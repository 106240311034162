import React, { useCallback, useRef, useState, useEffect, useMemo } from 'react';
import { useFormik } from 'formik';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import { alert } from 'components/Alerts';
import { useAssignManagement, useInfo } from './query/useJob';
import { useSuggestFollowUpChain } from 'hooks/useSuggestFollowUpChain';
import { useDefault } from './query/useCommon';
import useOutsideClick from 'hooks/useOutsideClick';
import { useAuth } from 'context/auth';
import RadioButton from 'components/newComponents/RadioButton';
// import Select from 'components/newComponents/Select';

export const useSelectAgent = (mutate, merge = false) => {
  const { user } = useAuth();
  const { id, tab, type } = useParams();

  const { mutateAsync: assignAgent } = useAssignManagement(id, tab);
  const { onMutate: suggestFollowUpChain } = useSuggestFollowUpChain(id, tab, type);

  const {
    data: { users },
  } = useDefault();

  const { data: job } = useInfo(id, { enabled: !!id });

  const info = useMemo(() => {
    if (!job) return null;
    return job.job_info;
  }, [job]);

  const agentsOptions = useMemo(() => {
    return users.agents.map((agent) => ({ value: agent.id, label: `${agent.first_name} ${agent.last_name}` }));
  }, [users.agents]);

  const condition = useMemo(() => {
    return merge ? merge : [7, 21].includes(+info?.status) && +info?.agent_id === 0;
  }, [info, merge]);

  const onMutate = useCallback(
    async (value, options, onSuccess) => {
      let agentId = user.id;

      if (condition) {
        await alert({
          title: 'Assign',
          html: <Form agentsOptions={agentsOptions} user={user} onChangeAgent={(id) => (agentId = id)} />,
          focusConfirm: false,
          allowOutsideClick: false,
        });

        if (!merge) {
          await assignAgent({ agent_id: agentId, job_id: info?.id });
        }
      }

      const data = {
        ...value,
        ...(merge ? { agent_id: agentId } : {}),
      };

      mutate(data, {
        ...options,
        onSuccess: ({ data }) => {
          if (onSuccess) onSuccess({ id: data.job_id, agentId });
          if (options?.onSuccess) options.onSuccess();
          if (merge && agentId !== 0) suggestFollowUpChain(data.job_id);
        },
      });
    },
    [agentsOptions, assignAgent, suggestFollowUpChain, condition, info, merge, mutate, user]
  );

  return { onMutate };
};

const Form = ({ agentsOptions, user, onChangeAgent }) => {
  const [open, setOpen] = useState(false);

  const { setFieldValue, values } = useFormik({
    initialValues: {
      agent_id: user.id,
      assign: 'assign_to_me',
      selected_agent_id: null,
    },
  });

  const assignChangeHandler = useCallback(
    (name, value) => {
      if (value === 'assign_to_me') {
        setFieldValue(name, value);
        setFieldValue('agent_id', user.id);
        setFieldValue('selected_agent_id', null);
        return;
      }
      if (value === 'leave_unassigned') {
        setFieldValue(name, value);
        setFieldValue('agent_id', 0);
        setFieldValue('selected_agent_id', null);
        return;
      }

      if (value === 'assign_to') {
        setFieldValue(name, value);
        setFieldValue('agent_id', agentsOptions[0]?.value ?? null);
        setFieldValue('selected_agent_id', agentsOptions[0]?.value ?? null);
        return;
      }

      setFieldValue('agent_id', value);
      setFieldValue('selected_agent_id', value);
    },
    [agentsOptions, setFieldValue, user.id]
  );

  const selectHandler = useCallback(
    (value) => {
      setFieldValue('agent_id', value);
      setFieldValue('selected_agent_id', value);
      setOpen(false);
    },
    [setFieldValue]
  );

  const toggleOpenSelect = () => {
    if (values['assign'] !== 'assign_to') {
      return;
    }

    setOpen(!open);
  };

  useEffect(() => onChangeAgent(values['agent_id']), [onChangeAgent, values]);

  const selectRef = useRef();
  useOutsideClick(selectRef, () => {
    setOpen(false);
  });

  return (
    <form className="select-agent-form">
      <div className="row my-2">
        <div className="col-6">
          <RadioButton
            name="assign"
            value="assign_to_me"
            title="Assign to me"
            current={values.assign}
            onChange={(e) => assignChangeHandler(e.target.name, e.target.value)}
          />
        </div>
      </div>
      <div className="row my-2">
        <div className="col-6">
          <RadioButton
            name="assign"
            value="leave_unassigned"
            title="Leave Unassigned"
            current={values.assign}
            onChange={(e) => assignChangeHandler(e.target.name, e.target.value)}
          />
        </div>
      </div>

      <div className="row my-2">
        <div className="col-6">
          <RadioButton
            name="assign"
            value="assign_to"
            title="Assign to"
            current={values.assign}
            onChange={(e) => assignChangeHandler(e.target.name, e.target.value)}
          />
        </div>
        <div className="col-6">
          {/* <Select
            name="selected_agent_id"
            options={agentsOptions}
            value={values['selected_agent_id']}
            onChange={(e) => assignChangeHandler('selected_agent_id', e.value)}
            disabled={values.assign !== 'assign_to'}
          /> */}

          <div className="agent-form-select" ref={selectRef}>
            <div className={classNames('agent-form-select_label', { selected: Boolean(values['selected_agent_id']) })} onClick={toggleOpenSelect}>
              <p>{agentsOptions.find((el) => el.value === values['selected_agent_id'])?.label || 'Select...'}</p>
              <span>
                <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false">
                  <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
                </svg>
              </span>
            </div>
            {open && (
              <ul className="agent-form-select_list">
                {agentsOptions.map((option) => {
                  return (
                    <li
                      className={classNames('', { selected: values['selected_agent_id'] === option.value })}
                      key={option.value}
                      onClick={() => selectHandler(option.value)}
                    >
                      <p>{option.label}</p>
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        </div>
      </div>
    </form>
  );
};

import React from 'react';
import moment from 'moment';
import CalendarEventItem from './CalendarEventItem';
import Icon from 'components/Icon';

import './CalendarEvents.scss';

const CalendarEvents = (props) => {
  let groupedArray = [];
  if (props.data.length > 0) {
    let clippedArray = [];

    let start = moment(props.start).unix();
    let end = moment(props.end).unix();

    for (let index of props.data) {
      let currentItemDate = moment(index.pickup_date).unix();
      if (start <= currentItemDate && currentItemDate < end) {
        clippedArray.push(index);
      }
    }

    for (let i = 0; i < clippedArray.length; i++) {
      let arr = [];
      let prop;
      if (typeof clippedArray[i] === 'object') {
        prop = clippedArray[i].pickup_date.toString().slice(0, 10);
        for (let j = 0; j < clippedArray.length; j++) {
          if (typeof clippedArray[j] === 'object' && clippedArray[j].pickup_date.toString().slice(0, 10) === prop) {
            arr.push(clippedArray[j]);
            delete clippedArray[j];
          }
        }
        groupedArray.push(arr);
      }
    }
  }

  return (
    <div className="container-fluid calendar-events">
      <div className="list-header row">
        <div className="col-2 d-flex justify-content-center align-items-center">
          <p>Id</p>
        </div>
        <div className="col-2 d-flex justify-content-center align-items-center">
          <Icon size={20} icon="name" title="Name" />
        </div>
        <div className="col-2 d-flex justify-content-center align-items-center">
          <Icon size={20} icon="cell_phone" title="Phone" />
        </div>
        <div className="col-3 d-flex justify-content-center align-items-center">
          <p>From</p>
        </div>
        <div className="col-3 d-flex justify-content-center align-items-center">
          <p>To</p>
        </div>
      </div>
      {props.data.length > 0 && groupedArray.map((day) => <CalendarEventItem day={day} key={day[0].title} />)}
    </div>
  );
};

export default CalendarEvents;

import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { useManualValues } from 'hooks/query/useJob';
import { useDebounce } from 'hooks/useDebounce';
import Icon from 'components/Icon';

const FinalCalcEstimated = ({ id, jobPacking, calcInfo, ldCalc, jobInfo, relatedJobs }) => {
  const estPackingTotal = useMemo(() => {
    return jobPacking?.estimated.reduce((sum, item) => sum + parseInt(item.qty) * parseFloat(item.price), 0);
  }, [jobPacking.estimated]);

  const [value, setValue] = useState(null);
  const { mutate: changeManual } = useManualValues(id);

  const debounceValue = useDebounce(value);

  const isAllJob = jobInfo?.storage_id === 0;
  const isLongDistanceTypeJob = jobInfo?.type === 5;
  const isPickupJob = relatedJobs?.long_delivery_job !== 0;

  const total = isAllJob ? calcInfo.estimated_total : isPickupJob ? ldCalc?.pickup_total : ldCalc?.delivery_total;

  useEffect(() => {
    if (debounceValue) changeManual({ ...debounceValue, job_id: id });
  }, [changeManual, debounceValue, id]);

  const handleManual = useCallback((event) => {
    setValue({ [event.target.name]: event.target.value });
  }, []);

  return (
    <div className="new-table">
      <div className="new-table--row">
        <div className="new-table--row--item no-side-shadows w5">
          <Icon icon="manual_charge" size={20} title="Add manual charge" />
        </div>
        <div className="new-table--row--item no-side-shadows w95">
          <input type="text" name="est_manual_plus" defaultValue={calcInfo.est_manual_plus} onChange={handleManual} />
        </div>
      </div>

      <div className="new-table--row">
        <div className="new-table--row--item no-side-shadows w5">
          <Icon icon="manual_discount" size={20} title="Add manual discount" />
        </div>
        <div className="new-table--row--item no-side-shadows w95">
          <input type="text" name="est_manual_minus" defaultValue={calcInfo.est_manual_minus} onChange={handleManual} />
        </div>
      </div>

      <div className="new-table--row">
        <div className="new-table--row--item no-side-shadows w5">
          <Icon icon="manual_tips" size={20} title="Add manual tips" />
        </div>
        <div className="new-table--row--item no-side-shadows w95">
          <input type="text" name="tips_amount" defaultValue={calcInfo.tips_amount} onChange={handleManual} />
        </div>
      </div>

      <div className="new-table--row">
        <div className="new-table--row--item no-side-shadows w5">
          <Icon icon="manual_tax" size={20} title="Taxes" />
        </div>
        <div className="new-table--row--item no-side-shadows w95">
          <p>{((calcInfo.sales_tax / 100) * estPackingTotal).toFixed(2)}</p>
        </div>
      </div>

      <div className="new-table--row bcg-light">
        <div className="new-table--row--item no-side-shadows w80">
          <span>Estimated total:</span>
        </div>
        <div className="new-table--row--item no-side-shadows w20">
          <b>${total}</b>
        </div>
      </div>
    </div>
  );
};

export default FinalCalcEstimated;

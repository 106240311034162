import React, { Fragment, useMemo, useCallback, useRef, useEffect } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import * as qs from 'querystring';
import { useModal } from 'context/modal';
import { useInfo, useTabInfo } from 'hooks/query/useJob';
import { useResultCall } from 'hooks/query/useTwilio';
import { useRefuseReasons } from 'hooks/query/useJobs';
import { useDefault } from 'hooks/query/useCommon';
import * as Cards from './CommunicationsItems';
import Icon from 'components/Icon';
import { alert } from 'components/Alerts';
import { communicationsTab } from 'helpers/constants';
import routes from 'config/routes';

import './styles.scss';

const JobCommunicationsHistory = ({ section, isOpen, communications, refetchCommunications }) => {
  const history = useHistory();

  const { open, modalNames } = useModal();

  const { id, tab } = useParams();

  const { mutate: callResult } = useResultCall();

  const {
    data: { statuses },
  } = useDefault();

  const {
    data: { job_info: jobInfo },
  } = useInfo(id);

  const {
    data: { moving_info: movingInfo },
  } = useTabInfo(id, tab);

  const { mutate: refuseReasons } = useRefuseReasons();

  const scroller = useRef(null);

  const openImg = useCallback((img) => {
    return alert({
      imageUrl: img,
      imageWidth: 800,
      imageAlt: 'Custom image',
      animation: false,
    });
  }, []);

  const openVideo = useCallback((video) => {
    return alert({
      html: `<video width="800" height="600" controls><source src=${video} type="video/mp4"/> </video>`,
      animation: false,
    });
  }, []);

  const communicationsMail = useCallback(
    (type, selected = false) => {
      history.push({
        pathname: `${routes.COMMUNICATIONS}/${type}`,
        search: qs.encode({ selected, type }),
      });
    },
    [history]
  );

  const getStatus = useCallback((status) => statuses?.job_statuses[status], [statuses.job_statuses]);

  const callResultHandler = useCallback(
    (result, sid, status = null) => {
      callResult({ call_result: result, call_sid: sid, job_status: status, job_id: id }, { onSuccess: refetchCommunications });
    },
    [callResult, id, refetchCommunications]
  );

  const schedule = useCallback(
    (sid) => {
      callResultHandler('Schedule Follow Up', sid);
    },
    [callResultHandler]
  );

  const confirmed = useCallback(
    (sid) => {
      const allowedStatuses = [1, 2, 10, 18, 11];
      if (allowedStatuses.includes(jobInfo.status)) {
        callResultHandler('Confirmed', sid, 3);
      } else {
        return alert({
          title: 'Operation not allowed.',
          text: 'Status ' + statuses.job_statuses[jobInfo.status] + " can't be changed to Confirmed!",
          type: 'error',
        });
      }
    },
    [callResultHandler, jobInfo.status, statuses.job_statuses]
  );

  const cancel = useCallback(
    async (sid) => {
      const allowedStatuses = [2, 3, 11, 18];
      if (allowedStatuses.includes(jobInfo.status)) {
        const res = await alert({
          title: 'Please fill out the Cancellation reason field',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'OK',
          text: 'Please enter refuse reason',
          input: 'text',
        });

        const reason = {
          job_id: jobInfo.id,
          reason: res.value,
        };
        refuseReasons(reason);
        callResultHandler('Canceled', sid, 18);
      } else {
        return alert({
          title: 'Operation not allowed.',
          text: 'Status ' + statuses.job_statuses[jobInfo.status] + " can't be changed to Cancel!",
          type: 'error',
        });
      }
    },
    [callResultHandler, jobInfo.id, jobInfo.status, refuseReasons, statuses.job_statuses]
  );

  const communicationsList = useMemo(() => {
    if (!communications) return { items: [], futureItems: [] };
    const list = Object.values(communications.list).sort((a, b) => parseFloat(a.time) - parseFloat(b.time));

    return list.reduce(
      (acc, el) => {
        if (el.type.includes('future')) return { ...acc, futureItems: [...acc.futureItems, el] };
        return { ...acc, items: [...acc.items, el] };
      },
      { items: [], futureItems: [] }
    );
  }, [communications]);

  const futureItems = useMemo(() => {
    return communicationsList.futureItems.map((item) => {
      if (item.type === 'sms_reminder_future')
        return (
          <Cards.SmsReminderFuture key={`${item.id}${item.type}`} item={item} communications={communicationsMail} openImg={openImg} isOpen={isOpen} />
        );
      return <Cards.EmailReminderFuture key={`${item.id}${item.type}`} item={item} communicationsMail={communicationsMail} isOpen={isOpen} />;
    });
  }, [communicationsList.futureItems, communicationsMail, isOpen, openImg]);

  const items = useMemo(() => {
    return communicationsList.items.reduce((acc, item, index) => {
      const key = `${item.id}${item.type}${item.id}${index}`;

      switch (item.type) {
        case 'sms_inbound':
          return [...acc, <Cards.SmsInbound key={key} item={item} communications={communicationsMail} openImg={openImg} openVideo={openVideo} />];

        case 'sms_outbound':
          return [...acc, <Cards.SmsOutbound key={key} item={item} communications={communicationsMail} openImg={openImg} openVideo={openVideo} />];

        case 'sms_reminder':
          return [...acc, <Cards.SmsReminder key={key} item={item} communications={communicationsMail} openImg={openImg} isOpen={isOpen} />];

        case 'internal_note':
          return [
            ...acc,
            <Cards.InternalNote key={key} item={item} open={() => open(modalNames.notes, { type: 'internal_notes', jobID: id, tab })} />,
          ];

        case 'invoice_sent':
          return [...acc, <Cards.InvoiceSent key={key} item={item} />];

        case 'invoice_paid':
          return [...acc, <Cards.InvoicePaid key={key} item={item} />];

        case 'invoice_payment_failed':
          return [...acc, <Cards.InvoicePaymentFailed key={key} item={item} />];

        case 'claim_mail_receive':
          return [...acc, <Cards.ClaimMailReceive key={key} item={item} communicationsMail={communicationsMail} isOpen={isOpen} />];

        case 'email_reminder':
          return [...acc, <Cards.EmailReminder key={key} item={item} communicationsMail={communicationsMail} isOpen={isOpen} />];

        case 'extra_stop':
          return [...acc, <Cards.ExtraStop key={key} item={item} open={() => open(modalNames.settings, { type: 'extrastops' })} />];

        case 'agent_note':
          if (item.note_type === 2 && section === communicationsTab.note) {
            return [...acc, <Cards.AgentNoteTo key={key} item={item} open={() => open(modalNames.notes, { type: 'agent_notes', jobID: id, tab })} />];
          }
          return [...acc, <Cards.AgentNote key={key} item={item} open={() => open(modalNames.notes, { type: 'agent_notes', jobID: id, tab })} />];

        case 'tasks':
          return [...acc, <Cards.Tasks key={key} item={item} open={() => open(modalNames.settings, { type: 'tasks' })} />];

        case 'claim_mail_sent':
          return [...acc, <Cards.ClaimMailSent key={key} item={item} communicationsMail={communicationsMail} />];

        case 'email_outbox':
          return [...acc, <Cards.EmailOutbox key={key} item={item} communicationsMail={communicationsMail} />];

        case 'email_inbox':
          return [...acc, <Cards.EmailInbox key={key} item={item} communicationsMail={communicationsMail} />];

        case 'status_change':
          return [...acc, <Cards.StatusChange key={key} item={item} getStatus={getStatus} />];

        case 'rate_change':
          return [...acc, <Cards.RateChange key={key} item={item} getStatus={getStatus} />];

        case 'date_change':
          return [...acc, <Cards.DateChange key={key} item={item} />];

        case 'job_coi':
          return [...acc, <Cards.JobCoi key={key} item={item} />];

        case 'claims': {
          let attachments = [];
          if (item.attachment && item.attachment !== '') {
            attachments = item.attachment.map((att, key) => {
              return (
                <Fragment>
                  <a key={key + 'att'} target="_blank" rel="noreferrer noopener" title="Download" href={'/api/downloadAttachments?path=' + att.path}>
                    <Icon icon="download" title="Save attachment" />
                    {att.name}
                  </a>
                  <br />
                </Fragment>
              );
            });
          }
          return [
            ...acc,
            <Cards.Claims key={key} item={item} attachments={attachments} open={() => open(modalNames.settings, { type: 'claims' })} />,
          ];
        }

        case 'call': {
          if (
            item.from.replace(/^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})/, '$1-$2-$3') ===
            movingInfo.from.home_phone.replace(/^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})/, '$1-$2-$3')
          )
            return [
              ...acc,
              <Cards.CallFrom key={key} item={item} callResult={callResultHandler} schedule={schedule} confirmed={confirmed} cancel={cancel} />,
            ];
          if (
            item.to.replace(/^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})/, '$1-$2-$3') ===
            movingInfo.from.home_phone.replace(/^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})/, '$1-$2-$3')
          ) {
            return [
              ...acc,
              <Cards.CallTo key={key} item={item} callResult={callResultHandler} schedule={schedule} confirmed={confirmed} cancel={cancel} />,
            ];
          }
          return acc;
        }

        default:
          return acc;
      }
    }, []);
  }, [
    callResultHandler,
    cancel,
    communicationsList.items,
    communicationsMail,
    confirmed,
    getStatus,
    isOpen,
    modalNames.notes,
    modalNames.settings,
    movingInfo.from.home_phone,
    open,
    openImg,
    openVideo,
    schedule,
    section,
  ]);

  useEffect(() => {
    if (scroller) scroller.current.scrollTo({ top: scroller.current.scrollHeight, behaivor: 'smooth' });
  }, [items]);

  return (
    <ul className="history-list" ref={scroller}>
      {items}
      <div />
      {isOpen && futureItems.length !== 0 && (
        <div>
          <li key="future" className="history-list-item">
            <div className="timeline">
              <div>Future</div>
            </div>
          </li>
          {futureItems}
        </div>
      )}
    </ul>
  );
};

export default JobCommunicationsHistory;

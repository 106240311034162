import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const ReactSwal = withReactContent(Swal);

export const stepAlert = async (arr, options) => {
  const progressSteps = arr.map((e, i) => i + 1);

  const data = await ReactSwal.mixin({
    ...options,
    progressSteps,
  }).queue(arr);

  return data;
};

export const alert = async (options) => {
  const res = await ReactSwal.fire({
    ...options,
  });

  return res;
};

import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';

import { useDefault } from 'hooks/query/useCommon';
import getAbbr from 'helpers/Abbr';
import getTitle from 'helpers/GetTitle';
import { statusNumberToName } from 'helpers/statuses';
import Icon from 'components/Icon';
import { updateRouteParams } from 'utils/history';
import { routePaths } from 'config/routes';

const LeadJob = ({ data }) => {
  const { data: defaultData } = useDefault();
  const companiesABBR = defaultData?.companies_abbr;

  if (!data) return null;

  const jobLink = updateRouteParams(routePaths.JOBS, { type: 'all', tab: 'general', id: data.id });

  return (
    <div className="reports-table">
      <div className="reports-table-row">
        <div className="reports-table-column reports-table-column--8">
          <Icon icon={statusNumberToName(data.status)} size={24} title={getTitle(data.status)} />
        </div>
        <div className="reports-table-column reports-table-column--8">{getAbbr(data.company_id, companiesABBR)}</div>
        <div className="reports-table-column reports-table-column--8">
          {data.first_name ? data.first_name.slice(0, 1).toUpperCase() + data.last_name.slice(0, 1).toUpperCase() : ''}
        </div>
        <div className="reports-table-column reports-table-column--8">
          <Link to={jobLink} target="_blank" rel="noopener noreferrer">
            {data.title}
          </Link>
        </div>
        <div className="reports-table-column reports-table-column--8">{moment.unix(data.created_at).format('MM/DD/YY LT')}</div>
        <div className="reports-table-column reports-table-column--8">{data.customer_name}</div>
        <div className="reports-table-column reports-table-column--8">{data.zip_from}</div>
        <div className="reports-table-column reports-table-column--8">{data.zip_to}</div>
      </div>
    </div>
  );
};

export default LeadJob;

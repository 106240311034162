import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { matchPath } from 'react-router';
import { useFormik } from 'formik';
import Swal from 'sweetalert2';
import { useEmailSend } from 'hooks/query/useMail';
import { useRefuseReasons } from 'hooks/query/useJobs';
import { routePaths } from 'config/routes';
import { useModal } from 'context/modal';
import { getParams } from 'helpers/jobs';
import { useInfo, useTabInfo, useAddJobCarrier, useChangeStatus, useInventorySave, useGetCarrierDrivers } from 'hooks/query/useJob';
import { useDefault } from 'hooks/query/useCommon';
import { ModalButton } from 'components/newComponents/Buttons';
import Modal from 'components/newComponents/Modal';
import MciEditor from 'components/MciEditor';
import Icon from 'components/Icon';
import CheckBox from 'components/newComponents/CheckBox';
import Input from 'components/newComponents/Input';
import Select from 'components/newComponents/Select';
import { availableStatuses } from 'helpers/statuses';
import { useSelectAgent } from 'hooks/useSelectAgent';
import { prepareDataToSubmit } from 'pages/modal/Inventory/utils';

const ChangeStatus = () => {
  const { info, close, open, modalNames } = useModal();
  const { pathname, search } = useLocation();

  const isVisible = info(modalNames.changeJobStatus).visible;
  const status = info(modalNames.changeJobStatus).params?.status;
  const closeHandler = useCallback(() => close(modalNames.changeJobStatus), [close, modalNames.changeJobStatus]);

  const {
    params: { id, tab },
  } = matchPath(pathname, { path: routePaths.JOBS });

  const {
    data: { job_info: jobInfo },
  } = useInfo(id);

  const isDeliveryTypeJob = jobInfo?.type === 5;

  const {
    data: { statuses, users, mail_templates: mailTemplates, partners, carriers = [] },
  } = useDefault();

  const { data: tabInfoData } = useTabInfo(id, tab, { enabled: Boolean(id) && Boolean(tab) });

  const { mutate: changeStatus } = useChangeStatus(id, getParams(search));

  const { mutateAsync: addJobCarrier } = useAddJobCarrier();

  const { mutate: sendMail } = useEmailSend(id, tab);
  const { mutate: refuseReasons } = useRefuseReasons();

  const { mutate: saveInventory } = useInventorySave(id, tab);

  const { onMutate: updateJob } = useSelectAgent(changeStatus, true);

  const [isEdit, seIsEdit] = useState(false);

  const sendMailHandler = useCallback(
    (values) => {
      if (jobInfo.status !== 1) {
        sendMail(
          { ...values, job_id: id, auto_email: jobInfo.auto_emails === 0 },
          {
            onSuccess: (res) => {
              if (res?.message?.theme === 'error') {
                Swal.fire('Error.', "Email isn't sent. Invalid credentials. Please check your SMTP settings.", 'error');
              }
            },
          }
        );
      }
      closeHandler();
    },
    [closeHandler, id, jobInfo, sendMail]
  );

  const saveStatusHandler = useCallback(
    async (values) => {
      if (values.status === 18 || values.status === 19) {
        let reason = values.status === 19 ? 'Deletion' : 'Cancellation';
        if (values.reason === '') {
          Swal.fire({
            title: 'Please fill out the ' + reason + ' reason field',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'OK',
          });
          return null;
        }
      }

      if (values.status === 23) {
        if (values.reason === '') {
          Swal.fire({
            title: 'Please fill out the dropped reason field',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'OK',
          });
          return null;
        }

        refuseReasons({
          job_id: Number(jobInfo.id),
          reason: values.reason,
        });
      }

      if (values.status === 7 && +jobInfo.agent_id === 0) {
        updateJob({ job_id: Number(jobInfo.id), status: values.status }, { onSuccess: () => closeHandler() });
        return;
      }

      if (values.status === 19) {
        Swal.fire({
          title: 'Do you want to delete ' + jobInfo.title + ' job?',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'OK',
        }).then((result) => {
          if (result.isConfirmed) {
            changeStatus({ job_id: Number(jobInfo.id), status: values.status }, { onSuccess: () => closeHandler() });
          } else {
            return null;
          }
        });
      } else {
        if (status !== values.status && !isEdit) {
          if (parseInt(jobInfo.auto_emails) === 1 && status !== 21 && status !== 1 && jobInfo.status !== 1) {
            if (jobInfo.status === 6) {
              Swal.fire({
                title: 'Would you like to send Feedback email to customer?',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Send',
              }).then((result) => {
                if (result.isConfirmed) {
                  sendMailHandler({ ...values, template: 'feedback' });
                }
              });
            }
            changeStatus({ job_id: Number(jobInfo.id), status: values.status }, { onSuccess: () => closeHandler() });
          }
        } else {
          if (jobInfo.auto_emails === 1) {
            if (jobInfo.status === 6) {
              Swal.fire({
                title: 'Would you like to send Feedback email to customer?',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Send',
              }).then((result) => {
                if (result.isConfirmed) {
                  sendMailHandler({ ...values, template: 'feedback' });
                }
              });
            }
          }

          if (isDeliveryTypeJob && status === 10) {
            if (!values.carrier_id || !values.carrier_driver_id || !values.cf_price || !jobInfo?.id) {
              const title =
                !values.carrier_id && !values.carrier_driver_id && !values.cf_price
                  ? 'Please fill Carrier, Driver and Cf Price fields'
                  : !values.carrier_driver_id && !values.cf_price
                  ? 'Please fill Driver and Cf Price fields'
                  : !values.cf_price
                  ? 'Please fill Cf Price field'
                  : '';

              Swal.fire({
                title: title,
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Ok',
              });
            } else {
              await addJobCarrier({
                carrier_id: values.carrier_id,
                job_id: jobInfo.id,
                carrier_driver_id: values.carrier_driver_id,
                cf_price: values.cf_price,
              });

              await changeStatus({ job_id: Number(jobInfo.id), status: values.status }, { onSuccess: () => closeHandler() });
            }

            return;
          }

          changeStatus({ job_id: Number(jobInfo.id), status: values.status }, { onSuccess: () => closeHandler() });
          return;
        }
        closeHandler();
      }
    },
    [changeStatus, closeHandler, addJobCarrier, isEdit, jobInfo, isDeliveryTypeJob, refuseReasons, sendMailHandler, updateJob, status]
  );

  const { values, setFieldValue, handleChange, handleSubmit } = useFormik({
    onSubmit: saveStatusHandler,
    initialValues: {
      template: null,
      include_pdf: 0,
      template_html: null,
      carrier_id: null,
      carrier_driver_id: null,
      cf_price: '',
      edit_template: 0,
      status: status || jobInfo.status,
      partner_id: jobInfo.partner_id,
      estimator_id: jobInfo.estimator_id,
      reason: jobInfo.status === 19 ? 'Deletion' : jobInfo.status === 23 ? 'Dropped' : 'Cancellation',
    },
  });

  const { data: carrierDriver = [] } = useGetCarrierDrivers(values.carrier_id, { enabled: Boolean(values.carrier_id) });

  const carrierList = carriers.map((carr) => ({
    value: carr.id,
    label: carr.name,
  }));

  const carrierDriversList = carrierDriver.map((driver) => ({
    value: driver.id,
    label: driver.name,
  }));

  const jobStatuses = useMemo(() => {
    const statusesArray = availableStatuses[jobInfo.status];

    return Object.entries(statuses.job_statuses)
      .filter((item) => statusesArray.includes(Number(item[0])))
      .map((status) => ({
        value: +status[0],
        label: status[1],
      }));
  }, [statuses, jobInfo.status]);

  const estimatorsList = useMemo(() => {
    return users.estimators.map((estimator) => ({
      value: +estimator.id,
      label: `${estimator.first_name} ${estimator.last_name}`,
    }));
  }, [users.estimators]);

  const mailTemplatesList = useMemo(() => {
    return mailTemplates.map((template) => ({
      value: template.slug,
      label: template.title,
    }));
  }, [mailTemplates]);

  const partnersList = useMemo(() => {
    return partners.map((partner) => ({
      value: +partner.id,
      label: partner.name,
    }));
  }, [partners]);

  const openInventoryFinalModal = () => {
    open(modalNames.inventory, {
      type: 'final',
      jobID: id,
      companyID: jobInfo?.company_id,
      jobInventory: tabInfoData?.job_inventory.final,
      onSubmit: (data, options) => {
        const prepared = prepareDataToSubmit(data);
        saveInventory(
          {
            job_id: id,
            job_inventory: { estimated: tabInfoData?.job_inventory['final'], final: prepared },
          },
          options
        );
      },
    });
  };

  const openSelectCarrierModal = () => {
    open(modalNames.changeJobCarrier, {
      job_id: id,
      makeApiRequestOnSubmit: false,
      jobCarrier: {
        carrier_driver_id: values.carrier_driver_id,
        carrier_id: values.carrier_id,
        cf_price: values.cf_price,
        job_id: id,
      },
      onSubmit: (data) => {
        setFieldValue('carrier_id', data.carrier_id);
        setFieldValue('carrier_driver_id', data.carrier_driver_id);
        setFieldValue('cf_price', data.cf_price);
      },
    });
  };

  return (
    <Modal style={{ width: 900 }} header="Save Job" show={isVisible} onHide={closeHandler}>
      <form onSubmit={handleSubmit}>
        <div className="new-table">
          <div className="new-table--row">
            <div className="new-table--row--item select">
              <Select
                name="status"
                placeholder="Job Status"
                value={values['status']}
                options={jobStatuses}
                onChange={({ value }) => setFieldValue('status', value)}
              />
            </div>
          </div>

          {values.status === 10 && isDeliveryTypeJob ? (
            <Fragment>
              <div className="new-table--row no-padding">
                <div className="new-table--row--item no-padding">
                  <div className="new-table--row">
                    <div className="new-table--row--item no-side-shadows">
                      Carrier:
                      <span style={{ marginLeft: 10, fontWeight: 700 }}>
                        {carrierList.find((carrier) => carrier.value === values.carrier_id)?.label}
                      </span>
                    </div>

                    <div className="new-table--row--item no-side-shadows">
                      Driver:
                      <span style={{ marginLeft: 10, fontWeight: 700 }}>
                        {carrierDriversList.find((driver) => driver.value === values.carrier_driver_id)?.label}
                      </span>
                    </div>

                    <div className="new-table--row--item no-side-shadows col-3">
                      CF Price: <span style={{ marginLeft: 10, fontWeight: 700 }}>{Number(values.cf_price).toFixed(2)}</span>
                    </div>

                    <div className="new-table--row--item no-side-shadows col-2 justify-content-end">
                      <button className="main flex flex-shrink-0" type="button" onClick={openSelectCarrierModal}>
                        Change Carrier
                        {/* <Icon icon="trucks" size={20} title="Change Carrier" className="icon-col-red" /> */}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Fragment>
          ) : (
            <div className="new-table--row">
              <div className="new-table--row--item select">
                <Select
                  name="partner_id"
                  placeholder="Select partner"
                  value={values['partner_id']}
                  options={partnersList}
                  onChange={({ value }) => setFieldValue('partner_id', value)}
                />
              </div>
            </div>
          )}

          {values.status === 8 && (
            <div className="new-table--row">
              <div className="new-table--row--item select">
                <Select
                  name="estimator_id"
                  placeholder="Job Estimator"
                  value={values['estimator_id']}
                  options={estimatorsList}
                  onChange={({ value }) => setFieldValue('estimator_id', value)}
                />
              </div>
            </div>
          )}
          <div className="new-table--row">
            <div className="new-table--row--item select">
              <Select
                name="template"
                placeholder="Mail templates"
                value={values['template']}
                options={mailTemplatesList}
                onChange={({ value }) => setFieldValue('template', value)}
              />
            </div>
          </div>
          {values.template && (
            <div className="new-table--row">
              <div className="new-table--row--item no-padding">
                <button className="main p20 flex-grow-1" title="Edit" onClick={() => seIsEdit((e) => !e)}>
                  Edit template
                </button>
              </div>
            </div>
          )}

          <div className="new-table--row">
            <div className="new-table--row--item w30">
              <CheckBox
                className="justify-content-between"
                name="include_pdf"
                label=" Include pdf"
                value={values['include_pdf'] === 1}
                onChange={(e) => setFieldValue('include_pdf', e.target.checked ? 1 : 0)}
              />
            </div>
            <div className="new-table--row--item w30">
              <button type="button" disabled onClick={openInventoryFinalModal}>
                <Icon icon="packing" size={20} title="Edit packing" />
                Edit inventory
              </button>
            </div>

            {[18, 19, 23].includes(values.status) && (
              <div className="new-table--row--item w30">
                <Icon icon="packing" size={20} title="Cancel reason" />
                <Input name="reason" value={values['reason']} onChange={handleChange} />
              </div>
            )}
          </div>
          {isEdit && (
            <MciEditor
              handleEditorChange={(e) => {
                setFieldValue('template_html', e);
                setFieldValue('edit_template', 1);
              }}
              value={values.template_html}
            />
          )}
        </div>
        <div className="footer-buttons">
          <ModalButton title="Cancel" variant="cancel" onClick={closeHandler} />
          <ModalButton title="Send" variant="confirm" type="submit" />
        </div>
      </form>
    </Modal>
  );
};

export default ChangeStatus;

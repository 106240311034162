import moment from 'moment';

export function getStorageRateTypeByPeriod(deliveryDate, pickupDate) {
  const a = moment(moment(deliveryDate).format('YYYY-MM-DD'));
  const b = moment(moment(pickupDate).format('YYYY-MM-DD'));

  const isAnnualRate = a.diff(b, 'days') > 365;
  const isMonthlyRate = a.diff(b, 'days') > 30 && a.diff(b, 'days') <= 365;
  const isDailyRate = a.diff(b, 'days') <= 30;

  switch (true) {
    case isAnnualRate:
      return 'annual_rate';
    case isMonthlyRate:
      return 'monthly_rate';
    case isDailyRate:
      return 'daily_rate';
    default:
      return '';
  }
}

export function getStorageRateMultiplierByRateType(storageRateType, deliveryDate, pickupDate) {
  const a = moment(moment(deliveryDate).format('YYYY-MM-DD'));
  const b = moment(moment(pickupDate).format('YYYY-MM-DD'));

  const diffInDays = a.diff(b, 'days');

  switch (storageRateType) {
    case 'annual_rate':
      return Math.ceil(diffInDays / 365);
    case 'monthly_rate':
      return Math.ceil(diffInDays / 30);
    case 'daily_rate':
      return diffInDays;
    default:
      return 0;
  }
}

export function getStorageTypeLable(type) {
  switch (+type) {
    case 1:
      return 'Vaulted';
    case 2:
      return 'Warehouse';
    default:
      return '';
  }
}

export const storagePaymentTypes = [
  { value: 'invoice', label: 'Invoice' },
  { value: 'auto_charge', label: 'Auto Charge' },
  { value: 'manual_invoice', label: 'Manual Invoice' },
];

import React, { useCallback, useEffect, Fragment, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import classNames from 'classnames';
import { useReadCommunication } from 'hooks/query/useJob';
import { useTabInfo, useInfo, useGetCommunicationCounter } from 'hooks/query/useJob';
import { communicationsTab } from 'helpers/constants';
import JobNotes from './JobNotes/JobNotes';
import JobCustomerNotes from './JobNotes/JobCustomerNotes';
import AdditionalInfo from './JobNotes/AdditionalInfo';
import JobCommunications from './JobCommunications';
import TeamCommunications from './TeamCommunications';
import Storage from './Storage';
import Icon from 'components/Icon';
import RoutesContainer from './RoutesContainer';
import queryKeys from 'config/queryKeys';

import './style.scss';

const getTabClassNames = (current, type) => {
  return classNames('communication-counters-item', { 'communication-counters-item__selected': current === type });
};

const JobGeneralContainer = () => {
  const { id, tab } = useParams();

  const queryClient = useQueryClient();

  const {
    data: {
      refuse_reason: refuseReason,
      moving_info: movingInfo,
      customer_feedback: customerFeedback,
      property_manager_reason: propertyManagerReason,
    },
  } = useTabInfo(id, tab);

  const {
    data: { job_info: jobInfo, dates },
  } = useInfo(id);

  const { data: communicationCounter, refetch: onRefreshCounters } = useGetCommunicationCounter(id);
  const { calls, sms, mails, insurance, notes, new: newCounters } = communicationCounter?.communications_counter ?? {};

  const { mutate } = useReadCommunication(id, tab);

  const [isHidden, setIsHidden] = useState(true);

  const [type, setType] = useState(communicationsTab.default);

  const readCommunications = useCallback(() => {
    mutate(
      { job_id: jobInfo.id, type: 'solo' },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([queryKeys.job, id, tab]);
          queryClient.invalidateQueries([queryKeys.communication, id, type]);
        },
      }
    );
  }, [queryClient, mutate, jobInfo.id, id, tab, type]);

  const handleFilter = useCallback((type) => {
    setIsHidden(false);
    setType(type);
  }, []);

  const isStorageTypeJob = jobInfo?.type === 3;

  useEffect(() => {
    const listener = (data) => {
      if (data.job_id !== id) return;
      onRefreshCounters();
    };
    window.socket.on('refresh_job_communication_counter', listener);

    return () => window.socket.off('refresh_job_communication_counter', listener);
  }, [id, onRefreshCounters]);

  return (
    <div className="job-general">
      {!isStorageTypeJob && <RoutesContainer />}

      {isStorageTypeJob && <Storage id={id} tab={tab} jobInfo={jobInfo} dates={dates} movingInfo={movingInfo?.from} />}

      {communicationCounter && (
        <div className="container-fluid job-communication-header">
          <div className="communication">
            <button className="toggle-comm" onClick={() => setIsHidden((pre) => !pre)}>
              <Icon icon={isHidden ? 'add' : 'remove'} title={isHidden ? 'Show more' : 'Show less'} />
            </button>
            <div className="communication-counters">
              <button type="button" className={getTabClassNames('default', type)} onClick={() => handleFilter(communicationsTab.default)}>
                <div>
                  All
                  <span className={classNames('counter', { red: newCounters['call'] || newCounters['sms'] || newCounters['mail'] })}>
                    {calls + sms + mails}
                  </span>
                </div>
              </button>
              <button type="button" className={getTabClassNames('call', type)} onClick={() => handleFilter(communicationsTab.call)}>
                <div>
                  Calls<span className={classNames('counter', { red: newCounters['call'] })}>{calls}</span>
                </div>
              </button>
              <button type="button" className={getTabClassNames('sms', type)} onClick={() => handleFilter(communicationsTab.sms)}>
                <div>
                  SMS<span className={classNames('counter', { red: newCounters['sms'] })}>{sms}</span>
                </div>
              </button>
              <button type="button" className={getTabClassNames('mail', type)} onClick={() => handleFilter(communicationsTab.mail)}>
                <div>
                  Email<span className={classNames('counter', { red: newCounters['mail'] })}>{mails}</span>
                </div>
              </button>
              <button type="button" className={getTabClassNames('reminder', type)} onClick={() => handleFilter(communicationsTab.reminder)}>
                <div>Reminders</div>
              </button>
              <button type="button" className={getTabClassNames('coi', type)} onClick={() => handleFilter(communicationsTab.coi)}>
                <div>
                  Insurance<span className="counter">{insurance}</span>
                </div>
              </button>
              <button type="button" className={getTabClassNames('note', type)} onClick={() => handleFilter(communicationsTab.note)}>
                <div>
                  Notes<span className="counter">{notes}</span>
                </div>
              </button>
              {isStorageTypeJob && (
                <button type="button" className={getTabClassNames('invoices', type)} onClick={() => handleFilter(communicationsTab.invoices)}>
                  <div>Invoices</div>
                </button>
              )}
            </div>
            <button className="mark-read" type="button" onClick={readCommunications}>
              <Icon icon="booked" title="Mark as read" className="tooltip-right" />
            </button>
          </div>
        </div>
      )}

      {!isHidden && (
        <Fragment>
          <JobCommunications type={type} onSetType={setType} />
          <TeamCommunications />
          <div className="additional-info">
            <h3 className="header table-header-shadow">Additional Info:</h3>
            <div className="d-flex">
              <AdditionalInfo type={'from'} />
              {/* <AdditionalInfo type={'to'} /> */}
            </div>
          </div>
        </Fragment>
      )}

      {customerFeedback && (
        <div className="shadow container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="feedback">
                <h3 className="header">CUSTOMER FEEDBACK</h3>
                <div className="feedback-body">
                  <Icon icon="communication" />
                  <p className="p10">{customerFeedback.note} </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {propertyManagerReason.length > 0 && (
        <div className="shadow container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="feedback">
                <h3 className="header">PROPERTY MANAGER NOTE</h3>
                <div className="feedback-body">
                  <Icon icon="communication" />
                  {propertyManagerReason.map((item, index) => (
                    <p key={index} className="p10">
                      {item.note}
                    </p>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {refuseReason && (
        <div className="d-flex flex-column shadow">
          <h3 className="header">REFUSE REASON</h3>
          <div className="d-flex align-item-start p20">
            <Icon icon="communication" style={{ marginRight: 15 }} />
            <p>{refuseReason.reason}</p>
          </div>
        </div>
      )}

      {!isStorageTypeJob && (
        <Fragment>
          <div className="agent-notes">
            <h3 className="header table-header-shadow">Agent notes</h3>
            <div className="d-flex">
              <JobNotes title="From" type={1} />
              <JobNotes title="To" type={2} />
            </div>
          </div>
          <div className="agent-notes">
            <h3 className="header table-header-shadow">Internal notes</h3>
            <JobNotes type={5} />
          </div>
          <JobCustomerNotes />
        </Fragment>
      )}
    </div>
  );
};

export default JobGeneralContainer;

import React from 'react';
import TooltipTitle from 'components/TooltipTitle';
import classNames from 'classnames';

const ResourcesFinal = ({ type, rate, calcInfo, jobInfo, jobPacking, jobFees, field }) => {
  const JobStatus = +jobInfo.status;

  let pickup = 0;

  if (parseFloat(calcInfo.distance_pickup) >= 16.9 && parseFloat(calcInfo.distance_pickup) <= 27) {
    pickup = parseFloat(rate) * 0.5;
  }
  if (parseFloat(calcInfo.distance_pickup) > 27 && parseFloat(calcInfo.distance_pickup) < 60) {
    pickup = parseFloat(rate);
  }
  if (parseFloat(calcInfo.distance_pickup) >= 60) {
    pickup = parseFloat(calcInfo.pickup_time) * parseFloat(rate);
  }

  let distance = parseFloat(calcInfo.travel_time) * parseFloat(rate);

  if (parseFloat(calcInfo.manual_travel_time) !== 0) {
    distance = parseFloat(calcInfo.manual_travel_time) * parseFloat(rate);
  }
  if (parseFloat(calcInfo.manual_pickup_time) !== 0) {
    pickup = parseFloat(calcInfo.manual_pickup_time) * parseFloat(rate);
  }
  if (parseInt(calcInfo.disabled_travel_time) === 1 && parseFloat(calcInfo.estimated_manual_travel_time) === 0) {
    distance = 0.0;
  }
  if (parseInt(calcInfo.disabled_pickup_time) === 1 && parseFloat(calcInfo.estimated_manual_pickup_time) === 0) {
    pickup = 0.0;
  }
  if (parseInt(jobInfo.company_id) === 5) {
    pickup = 0.0;
    distance = distance * 2;
  }

  let final_work_time = parseFloat(calcInfo.labor_time) * parseFloat(rate);

  let wait_charge = ((parseFloat(calcInfo.wait_time) * parseFloat(calcInfo.wait_time_rate)) / 100) * rate;

  const packingTotalFinal = jobPacking[type].reduce((sum, item) => sum + item.qty * parseFloat(item.price), 0);
  const jobFeesTotal = jobFees?.reduce((sum, item) => sum + parseFloat(item.amount), 0) ?? 0;
  let final_total = pickup + distance + final_work_time + packingTotalFinal + jobFeesTotal;

  let tooltip_pickup = `0-16.9 mi - FREE \n 17-26.9 mi - 0.5 * Hourly Rate \n27-60 mi - 1 * Hourly Rate 
        \n 60-400 mi - Miles / 60 * Hourly Rate`;
  let tooltip_travel_time = `Distance hours are \nTravel hours, \n can be changed on FINAL`;
  if (parseInt(jobInfo.company_id) === 5) {
    tooltip_pickup = 'Free';
    tooltip_travel_time = `Distance hours are \n Travel hours(x2),\n can be changed on FINAL`;
  }

  switch (field) {
    default:
      return null;
    case 'office_to_pick_up':
      return (
        <div className={classNames('new-table--row--item justify-content-center w30', { minor: JobStatus !== 6 || !pickup })}>
          <TooltipTitle title={tooltip_pickup}>
            <b> {JobStatus !== 6 ? 'TBD' : `$ ${pickup.toFixed(2)}`} </b>
          </TooltipTitle>
        </div>
      );

    case 'pick_up_to_destination':
      return (
        <div className={classNames('new-table--row--item justify-content-center w30', { minor: JobStatus !== 6 || !distance })}>
          <TooltipTitle title={tooltip_travel_time}>
            <b> {JobStatus !== 6 ? 'TBD' : `$ ${distance.toFixed(2)}`}</b>
          </TooltipTitle>
        </div>
      );

    case 'working_time':
      return (
        <div className={classNames('new-table--row--item justify-content-center w30', { minor: JobStatus !== 6 || !final_work_time })}>
          <TooltipTitle title={`Minimum defined \n Working Hours \n depending on MoveSize`}>
            <b> {JobStatus !== 6 ? 'TBD' : `$ ${final_work_time.toFixed(2)}`} </b>
          </TooltipTitle>
        </div>
      );

    case 'wait_time':
      return (
        <div className={classNames('new-table--row--item justify-content-center w30', { minor: JobStatus !== 6 || !wait_charge })}>
          <b>{JobStatus !== 6 ? 'TBD' : `$ ${wait_charge.toFixed(2)}`}</b>
        </div>
      );

    case 'total':
      return (
        <div className={classNames('new-table--row--item justify-content-center w30', { minor: JobStatus !== 6 || !final_total })}>
          <b>{JobStatus !== 6 ? 'TBD' : `$ ${final_total.toFixed(2)}`}</b>
        </div>
      );

    case 'working_time_final':
      return (
        <div className={classNames('new-table--row--item justify-content-center w30')}>
          <b>${final_work_time.toFixed(2)}</b>
        </div>
      );
  }
};

export default ResourcesFinal;

export const getElevatorData = (data) => {
  const elevatorExist = !!data?.elevator_exist;
  const elevatorConfirm = !!data?.elevator_confirm;
  const elevator = data?.elevator_reservation_start && data?.elevator_reservation_end;
  const elevatorReservation = {
    start: data?.elevator_reservation_start || '',
    end: data?.elevator_reservation_end || '',
  };
  const elevatorStyle = { color: '', title: 'Elevator reservation not required' };

  if (elevatorExist) {
    elevatorStyle.color = 'red';
    elevatorStyle.title = 'Elevator reservation required';
    if (data?.elevator_confirm === 1) {
      elevatorStyle.color = 'green';
      elevatorStyle.title = 'Elevator reservation approved';
    }
    if (data?.elevator_confirm === 2) {
      elevatorStyle.color = 'dark-red';
      elevatorStyle.title = 'Elevator reservation rejected';
    }
  }

  return {
    elevator,
    elevatorExist,
    elevatorConfirm,
    elevatorReservation,
    elevatorStyle,
  };
};

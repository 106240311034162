import React, { memo, useState } from 'react';
import { useFormik } from 'formik';
import { useInternalNotesHeadersSave, useDeleteJobInternalNote } from 'hooks/query/useJob';
import { formatL } from 'helpers/dateHelper';
import Icon from 'components/Icon';

const NoteItem = memo(({ id, tab, note, counter }) => {
  const [isDisabled, setIsDisabled] = useState(true);

  const { mutate: deleteNote } = useDeleteJobInternalNote(id, tab);
  const { mutate: saveNote } = useInternalNotesHeadersSave(id, tab);

  const { values, handleChange } = useFormik({ initialValues: { note: note.note } });

  const handleSaveNote = () => {
    saveNote({ internal_notes: values.note, job_id: id, id: note.id });
    setIsDisabled(true);
  };

  const handleDeleteNote = () => {
    deleteNote({ job_id: id, id: note.id });
    setIsDisabled(true);
  };

  const toggleEdit = () => setIsDisabled((pre) => !pre);

  return (
    <div className="new-table--row">
      <div className="new-table--row--item col-1 no-side-shadows justify-content-center">
        <b>{counter}</b>
      </div>
      <div className="new-table--row--item no-side-shadows justify-content-center">
        <textarea type="text" rows={1} disabled={isDisabled} value={values.note} name="note" onChange={handleChange} />
      </div>
      <div className="new-table--row--item col-1 no-side-shadows justify-content-center">
        <span>{formatL(note.created_at)} </span>
      </div>
      <div className="new-table--row--item col-2 no-side-shadows justify-content-center">
        <button type="button" onClick={isDisabled ? toggleEdit : handleSaveNote}>
          <Icon size={20} icon={isDisabled ? 'split_job' : 'booked'} title={isDisabled ? 'Edit' : 'Save'} />
        </button>
        <button type="button" onClick={isDisabled ? handleDeleteNote : toggleEdit}>
          <Icon size={20} icon={isDisabled ? 'delete' : 'canceled'} title={isDisabled ? 'Delete' : 'Cancel'} />
        </button>
      </div>
    </div>
  );
});

export default NoteItem;

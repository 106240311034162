import React, { useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { matchPath } from 'react-router';
import { routePaths } from 'config/routes';
import { useModal } from 'context/modal';
import { ModalButton } from 'components/newComponents/Buttons';
import Select from 'components/newComponents/Select';
import Modal from 'components/newComponents/Modal';
import Icon from 'components/Icon';
import CrewUnit from './CrewUnit';
import { useFetchData } from './useFetchData';
import { useForm } from './useForm';
import { useAssignCrew, assignCrewTypes } from './useAssignCrew';
import { overlapAlert } from './assignCrewAlerts';

import './style.scss';

const AssignCrew = () => {
  const { pathname } = useLocation();
  const path = matchPath(pathname, { path: routePaths.JOBS });

  const { info, close, modalNames } = useModal();
  const [isMoverVisible, setIsMoverVisible] = useState(false);
  const params = info(modalNames.assignCrew).params;

  const closeHandler = useCallback(() => close(modalNames.assignCrew), [close, modalNames.assignCrew]);

  const isVisible = info(modalNames.assignCrew).visible;

  const { values, actions } = useForm(params);
  const {
    handleGetAssignedCrew,
    handleGetNewCrew,
    handleSelectUnit,
    handleSelectForeman,
    handleChangeUnit,
    handleSelectNewCrew,
    filterAssignedCrew,
    handleAddMover,
  } = actions;

  const { availableUnits, crewOptions, availableMoversOptions, moveSize } = useFetchData(
    {
      date: params.date,
      start_date: params.start_date,
      stop_date: params.stop_date,
      job_id: params.job_id,
      crew_id: values.crew_id,
      company_id: params.company_id,
      move_size_id: params.move_size_id,
    },
    !!params.edit,
    (Boolean(values.crew_id) && values.crew_id !== params.crew_id) || (Boolean(values.crew_id) && !params.edit),
    { handleGetAssignedCrew, handleGetNewCrew }
  );

  const { assignToJobHandler } = useAssignCrew(values, params, { path, closeHandler });

  const formatOptionLabel = useCallback(({ label, overlap }) => {
    return (
      <div className="d-flex align-items-center justify-content-between">
        <span style={{ textAlign: 'left' }}>{label}</span>
        {Boolean(overlap) && <Icon icon="task_unfinished" style={{ marginInline: 10 }} size={18} />}
      </div>
    );
  }, []);

  return (
    <Modal show={isVisible} style={{ width: 650 }} header="Assign Crew" onHide={closeHandler}>
      <div className="new-table assign-crew-modal modal">
        <div className="new-table--row">
          <div className="new-table--row--item no-side-shadows item-row">
            <p className="w15">{params.title}</p>
            <p className="w15">{moveSize}</p>
            <p className="w15">{params.qty_man} man</p>
            <p className="w55" style={{ paddingInline: 8 }}>
              {params.start_date} &mdash; {params.stop_date}
            </p>
          </div>
        </div>

        <div className="new-table--row">
          <div className="new-table--row--item no-side-shadows item-row select">
            <p>Select crew</p>
            <div className="w55" style={{ padding: 0 }}>
              <Select
                name="crew_id"
                value={values.crew_id}
                placeholder="Select crew"
                options={crewOptions}
                formatOptionLabel={formatOptionLabel}
                onChange={async (option) => {
                  if (Boolean(option.overlap)) {
                    const res = await overlapAlert('This crew is assigned to another job and overlaping.');
                    if (res.isDismissed) return;
                  }

                  handleSelectNewCrew(option);
                }}
              />
            </div>
          </div>
        </div>

        <CrewUnit
          crew={values.crew}
          foreman={values.foreman}
          available={availableUnits}
          filterAssignedCrew={filterAssignedCrew}
          formatOptionLabel={formatOptionLabel}
          onSelectUnit={handleSelectUnit}
          onSelectForeman={handleSelectForeman}
          onChangeUnit={handleChangeUnit}
        />

        <div className="new-table--row">
          <div className="new-table--row--item select add-crew">
            <button type="button" className="add-crew--toggle" onClick={() => setIsMoverVisible((pre) => !pre)}>
              <Icon icon={isMoverVisible ? 'remove' : 'add'} size={18} />
              {isMoverVisible ? 'Hide menu' : 'Add mover'}
            </button>
            {isMoverVisible && (
              <div className="add-crew--movers-menu">
                <Select
                  name="additional_mover"
                  placeholder="Type to search"
                  options={filterAssignedCrew(availableMoversOptions)}
                  defaultMenuIsOpen
                  formatOptionLabel={formatOptionLabel}
                  onChange={async (option) => {
                    if (Boolean(option.overlap)) {
                      const res = await overlapAlert(`This ${option.type} is assigned to another job and overlaping.`);
                      if (res.isDismissed) return;
                    }

                    handleAddMover(option);
                    setIsMoverVisible(false);
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="footer-buttons">
        <ModalButton title="Cancel" variant="cancel" onClick={closeHandler} />
        <ModalButton title="Assign and Confirm" variant="details" onClick={() => assignToJobHandler(assignCrewTypes.confirmed)} />
        <ModalButton title="Assign" variant="confirm" onClick={() => assignToJobHandler(assignCrewTypes.assigned)} />
      </div>
    </Modal>
  );
};

export default AssignCrew;

import React, { Fragment, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useTabInfo, useInfo } from 'hooks/query/useJob';
import Icon from 'components/Icon';

const ExpResDriver = (props) => {
  const { id, tab } = useParams();

  const {
    data: { assigned_crew: assignedCrew, job_packing: jobPacking },
  } = useTabInfo(id, tab);

  const {
    data: { job_info: jobInfo, calc_info: calcInfo },
  } = useInfo(id);

  const jobStatus = jobInfo.status;
  const driverInfo = assignedCrew.drivers[0];
  const jobIsDone = jobStatus !== 6;

  const travelTime = useMemo(() => {
    return parseFloat(calcInfo.manual_travel_time) !== 0 ? parseFloat(calcInfo.manual_travel_time) : parseFloat(calcInfo.travel_time);
  }, [calcInfo]);

  const pickupTime = useMemo(() => {
    return parseFloat(calcInfo.manual_pickup_time) !== 0 ? parseFloat(calcInfo.manual_pickup_time) : parseFloat(calcInfo.pickup_time);
  }, [calcInfo]);

  const driverHourlySalary = useMemo(() => {
    return jobIsDone
      ? (travelTime + pickupTime + parseFloat(calcInfo.estimated_time)) * parseFloat(driverInfo?.hourly_salary)
      : (travelTime + pickupTime + parseFloat(calcInfo.labor_time)) * parseFloat(driverInfo?.hourly_salary);
  }, [calcInfo, driverInfo, jobIsDone, pickupTime, travelTime]);

  const finPackingTotal = useMemo(() => {
    return jobIsDone
      ? jobPacking.estimated.reduce((sum, item) => sum + parseInt(item.qty) * parseInt(item.price), 0)
      : jobPacking.final.reduce((sum, item) => sum + parseInt(item.qty) * parseInt(item.price), 0);
  }, [jobIsDone, jobPacking]);

  const commissionValue = useMemo(() => {
    return ((driverInfo?.packing_persent * finPackingTotal) / 100).toFixed(2);
  }, [driverInfo, finPackingTotal]);

  const driverSalary = useMemo(() => {
    return (parseFloat(commissionValue) + parseFloat(driverHourlySalary)).toFixed(2);
  }, [commissionValue, driverHourlySalary]);

  const driverHours = useMemo(() => {
    return jobIsDone ? travelTime + pickupTime + parseFloat(calcInfo.estimated_time) : travelTime + pickupTime + parseFloat(calcInfo.labor_time);
  }, [calcInfo, jobIsDone, pickupTime, travelTime]);

  return (
    <Fragment>
      <div className="new-table--row no-padding bcg-light">
        <div className="new-table--row--item  no-side-shadows">
          <b className="disabled"> Driver </b>
        </div>
        <div className="new-table--row--item  no-side-shadows">
          <Icon className="fb3" icon="hours_total" title="Hours total" size={20} />
          {driverInfo ? (
              <Icon className="fb3" icon="commission" title={ "Packing Commission " + driverInfo?.packing_persent + "% / Hourly Salary $" + driverInfo?.hourly_salary + "/h"} size={20} />
          ) : (
              <Icon className="fb3" icon="commission" title="Packing Commission 0% / Hourly Salary 0%" size={20} />
          )}
          <Icon className="fb3" icon="commission_value" title="Commission value" size={20} />
        </div>
      </div>

      {driverInfo ? (
        <div className="new-table--row no-padding">
          <div className="new-table--row--item  no-side-shadows">
            <b>{driverInfo.name}</b>
          </div>
          <div className="new-table--row--item no-side-shadows">
            <b className="fb3"> {driverHours.toFixed(2)} </b>
            <b className="fb3">
              ${commissionValue} / ${driverHourlySalary.toFixed(2)}
            </b>
            <b className="fb3"> {driverSalary}</b>
          </div>
        </div>
      ) : (
        <div className="new-table--row">
          <div className="new-table--row--item no-side-shadows">
            <b>no driver selected</b>
          </div>
          <div className="new-table--row--item no-side-shadows">
            <b className="fb3"> 0.00 </b>
            <b className="fb3"> 0% / 0% </b>
            <b className="fb3"> 0.00</b>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default ExpResDriver;

import React from 'react';
import classNames from 'classnames';
import Icon from 'components/Icon';
import EditChain from './EditChain';
import EditReminders from './EditReminders';

const ChainItem = (props) => {
  const { chain, activeEditChains, activeChainReminders, onEditClick, onRemindersClick, onDelete, onSave } = props;

  return (
    <div className="new-table">
      <div className={classNames('new-table--row', { 'chain-active': activeEditChains || activeChainReminders })}>
        <div className="new-table--row--item no-side-shadows col-1">{chain.id}</div>
        <div className="new-table--row--item no-side-shadows">{chain.name}</div>
        <div className="new-table--row--item no-side-shadows col-3">{chain.type}</div>
        <div className="new-table--row--item no-side-shadows col-2 justify-content-between">
          <button type="button" className="action" onClick={onEditClick}>
            <Icon icon="reminders_edit" title="Edit" size={20} style={{ marginRight: 0, height: 20 }} />
          </button>
          <button type="button" className="action" onClick={onRemindersClick}>
            <Icon icon="reminders" title="Reminders" size={20} style={{ marginRight: 0, height: 20 }} />
          </button>
          <button type="button" className="action" onClick={onDelete}>
            <Icon icon="delete" title="Delete" size={20} style={{ marginRight: 0, height: 20 }} />
          </button>
        </div>
      </div>

      {activeEditChains && <EditChain data={chain} onSave={onSave} />}

      {activeChainReminders && <EditReminders data={chain} />}
    </div>
  );
};

export default ChainItem;

import React from 'react';
import RadioButton from 'components/newComponents/RadioButton';
import TooltipTitle from 'components/TooltipTitle';
import { useForm } from '../../../hooks/useForm';

function StorageRate({ companies, unit_sizes }) {
  const { values, actions } = useForm();

  return (
    <div className="new-table--row position-relative">
      <div className="new-table--row--item ">
        <TooltipTitle>
          <RadioButton
            value="daily_rate"
            title="Daily Rate"
            name="storage_rate_type"
            current={values['storage_rate_type']}
            onChange={() => actions.handleChangeRateType('daily_rate', values.delivery_date, companies, unit_sizes)}
          />
        </TooltipTitle>
      </div>

      <div className="new-table--row--item ">
        <TooltipTitle>
          <RadioButton
            value="monthly_rate"
            title="Monthly Rate"
            name="storage_rate_type"
            current={values['storage_rate_type']}
            onChange={() => actions.handleChangeRateType('monthly_rate', values.delivery_date, companies, unit_sizes)}
          />
        </TooltipTitle>
      </div>

      <div className="new-table--row--item ">
        <TooltipTitle>
          <RadioButton
            value="annual_rate"
            title="Annual Rate"
            name="storage_rate_type"
            current={values['storage_rate_type']}
            onChange={() => actions.handleChangeRateType('annual_rate', values.delivery_date, companies, unit_sizes)}
          />
        </TooltipTitle>
      </div>

      {actions.isValidHandler('storage_rate_type') && (
        <span style={{ position: 'absolute', bottom: 0, left: 20 }} className="error">
          {actions.isValidHandler('storage_rate_type')}
        </span>
      )}
    </div>
  );
}

export default StorageRate;

const emailTypes = ['inbox_email', 'outbox_email', 'reminders_replied', 'reminders_sent'];

const mailStatuses = [
  { value: '', label: 'All type' },
  { value: 'booked', label: 'Booked' },
  { value: 'confirmed', label: 'Confirmed' },
  { value: 'rescheduled', label: 'Rescheduled' },
  { value: 'canceled', label: 'Canceled' },
];

const inboundCallTypes = [
  { value: '', label: 'All Calls' },
  { value: ['completed'], label: 'Completed' },
  { value: ['ringing', 'no-answer'], label: 'Missed' },
];

const reminderTypes = [
  { value: 'reminders_sent', label: 'Sent' },
  { value: 'scheduled', label: 'Scheduled' },
];

export { emailTypes, mailStatuses, inboundCallTypes, reminderTypes };

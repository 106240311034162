import React, { useMemo, Fragment, useCallback } from 'react';
import { formatTimeAPMeridiem } from 'helpers/dateHelper';
import { useDefault } from 'hooks/query/useCommon';
import Input from 'components/newComponents/Input';
import TextArea from 'components/newComponents/TextArea';
import CheckBox from 'components/newComponents/CheckBox';
import Select from 'components/newComponents/Select';
import TimeSelect from 'components/TimeSelect';
import { preventArrowUpDown } from 'utils/preventArrowUpDown';

const ExtraStopAdditionalItem = ({ index, values, setFieldValue, handleChange, prefix }) => {
  const {
    data: { statuses },
  } = useDefault();

  const stairsSet = useMemo(() => {
    return [...Array(10).keys()].map((_, i) => ({ value: i + 1, label: i + 1 }));
  }, []);

  const buildingTypes = useMemo(() => {
    return statuses.building_type.map((el, index) => ({ value: index, label: el }));
  }, [statuses]);

  const getValue = useCallback((name) => values['additional_info'][index][name], [index, values]);

  return (
    <div className="new-table modal">
      <div className="new-table--row new-table--row__column">
        <div className="new-table--row--item">
          <p>Type of building</p>
        </div>
        <div className="new-table--row--item select">
          <Select
            options={buildingTypes}
            value={getValue('building_type')}
            placeholder="Building type from"
            name={`${prefix}building_type`}
            onChange={({ value }) => setFieldValue(`${prefix}building_type`, value)}
          />
        </div>
      </div>

      {[1, 2, 3, 6].includes(values.building_type) && (
        <Fragment>
          <div className="new-table--row">
            <div className="new-table--row--item">
              <p>Building Access</p>
            </div>
            <div className="new-table--row new-table--row__column">
              <div className="new-table--row--item select">
                <TimeSelect
                  name={`${prefix}building_access_start`}
                  value={getValue('building_access_start')}
                  interval={60}
                  placeholder="Start time"
                  onChange={({ name, date }) => setFieldValue(name, formatTimeAPMeridiem(date))}
                />
              </div>
              <div className="new-table--row--item select">
                <TimeSelect
                  name={`${prefix}building_access_end`}
                  value={getValue('building_access_end')}
                  interval={60}
                  placeholder="End time"
                  onChange={({ name, date }) => setFieldValue(name, formatTimeAPMeridiem(date))}
                />
              </div>
            </div>
          </div>
          <div className="new-table--row">
            <div className="new-table--row--item">
              <CheckBox
                className="justify-content-between"
                name={`${prefix}elevator_exist`}
                label="Elevator Exist?"
                checked={getValue('elevator_exist') === 1}
                onChange={({ target }) => setFieldValue(`${prefix}elevator_exist`, target.checked ? 1 : 0)}
              />
            </div>
          </div>
          {!!getValue('elevator_exist') && (
            <div className="new-table--row">
              <div className="new-table--row--item">
                <p>Elevator Access</p>
              </div>
              <div className="new-table--row new-table--row__column">
                <div className="new-table--row--item select">
                  <TimeSelect
                    name={`${prefix}elevator_reservation_start`}
                    value={getValue('elevator_reservation_start')}
                    interval={60}
                    placeholder="Start time"
                    onChange={({ name, date }) => setFieldValue(name, formatTimeAPMeridiem(date))}
                  />
                </div>
                <div className="new-table--row--item select">
                  <TimeSelect
                    name={`${prefix}elevator_reservation_end`}
                    value={getValue('elevator_reservation_end')}
                    interval={60}
                    placeholder="End time"
                    onChange={({ name, date }) => setFieldValue(name, formatTimeAPMeridiem(date))}
                  />
                </div>
              </div>
            </div>
          )}
          <div className="new-table--row new-table--row__column">
            <div className="new-table--row--item">
              <p>Which floor?</p>
            </div>
            <div className="new-table--row--item">
              <Input
                name={`${prefix}elevator_floor`}
                type="number"
                value={getValue('elevator_floor')}
                placeholder="0"
                onChange={handleChange}
                onKeyDown={preventArrowUpDown}
              />
            </div>
          </div>
          <div className="new-table--row new-table--row__column">
            <div className="new-table--row--item">
              <p>What is a distance from apartment door to the elevator - (Yards):</p>
            </div>
            <div className="new-table--row--item">
              <Input
                name={`${prefix}distance_elevator_apartment`}
                type="number"
                value={getValue('distance_elevator_apartment')}
                placeholder="0"
                onChange={handleChange}
                onKeyDown={preventArrowUpDown}
              />
            </div>
          </div>
        </Fragment>
      )}
      <div className="new-table--row new-table--row__column">
        <div className="new-table--row--item">
          <p>What is the distance from elevator to the loading dock/truck parking - (Yards):</p>
        </div>
        <div className="new-table--row--item">
          <Input
            name={`${prefix}distance_elevator_parking`}
            type="number"
            value={getValue('distance_elevator_parking')}
            placeholder="0"
            onChange={handleChange}
            onKeyDown={preventArrowUpDown}
          />
        </div>
      </div>
      <div className="new-table--row new-table--row__column">
        <div className="new-table--row--item">
          <p>Need floor/wall protection?</p>
        </div>
        <div className="new-table--row--item">
          <div className="d-flex justify-content-between">
            <div className="w20">
              <CheckBox
                className="justify-content-between"
                name={`${prefix}same_building`}
                label="Hardwood"
                checked={getValue('same_building') === 1}
                onChange={({ target }) => setFieldValue(`${prefix}same_building`, target.checked ? 1 : 0)}
              />
            </div>
            <div className="w20">
              <CheckBox
                className="justify-content-between"
                name={`${prefix}marble_protect`}
                label="Marble"
                checked={getValue('marble_protect') === 1}
                onChange={({ target }) => setFieldValue(`${prefix}marble_protect`, target.checked ? 1 : 0)}
              />
            </div>
            <div className="w20">
              <CheckBox
                className="justify-content-between"
                name={`${prefix}carpet_protect`}
                label="Carpet"
                checked={getValue('carpet_protect') === 1}
                onChange={({ target }) => setFieldValue(`${prefix}carpet_protect`, target.checked ? 1 : 0)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="new-table--row">
        <label className="new-table--row--item">
          <CheckBox
            className="justify-content-between"
            name={`${prefix}hoisting`}
            label="Need Hoisting of heavy items?"
            checked={getValue('hoisting') === 1}
            onChange={({ target }) => setFieldValue(`${prefix}hoisting`, target.checked ? 1 : 0)}
          />
        </label>
      </div>
      {!!getValue('hoisting') && (
        <div className="new-table--row">
          <div className="new-table--row--item">
            <TextArea name={`${prefix}hoisting_note`} placeholder="Note..." value={getValue('hoisting_note')} onChange={handleChange} />
          </div>
        </div>
      )}
      <div className="new-table--row new-table--row__column">
        <div className="new-table--row--item">
          <p>Stairs?</p>
        </div>
        <div className="new-table--row--item">
          <Select
            options={stairsSet}
            value={getValue('stairs')}
            placeholder="Building type from"
            name={`${prefix}stairs`}
            onChange={({ value }) => setFieldValue(`${prefix}stairs`, value)}
          />
        </div>
      </div>
    </div>
  );
};

export const ExtraStopAdditional = ({ values, setFieldValue, handleChange }) => {
  return (
    <div className="new-table modal">
      <div className="new-table--row flex-wrap">
        {values.extra_stops.map((extraStop, index) => (
          <div className="new-table--row--item align-items-start" key={index}>
            <ExtraStopAdditionalItem
              index={index}
              values={values}
              key={index.toString()}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
              prefix={`additional_info[${index}]`}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

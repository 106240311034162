import React, { memo, useState, useCallback } from 'react';
import { formatL } from 'helpers/dateHelper';
import { useFormik } from 'formik';
import { useAuth } from 'context/auth';
import { useNotesUpdate } from 'hooks/query/useJob';
import Select from 'components/newComponents/Select';
import { notesOptions } from 'helpers/constants';
import Icon from 'components/Icon';

const NoteItem = memo(({ id, tab, notes, note, counter, author }) => {
  const { user } = useAuth();
  const [isDisabled, setIsDisabled] = useState(true);

  const { mutate: notesUpdate } = useNotesUpdate(id, tab);

  const { values, handleChange, setFieldValue } = useFormik({
    initialValues: {
      note: note.note,
      type: note.type,
    },
  });

  const toggleEdit = () => setIsDisabled((pre) => !pre);

  const saveNote = useCallback(() => {
    notesUpdate({ job_id: id, notes: notes.map((e) => (e.id === note.id ? { ...values, author_id: user.id } : e)) });
  }, [id, note, notes, notesUpdate, user.id, values]);

  const deleteNote = useCallback(() => {
    notesUpdate({ job_id: id, notes: notes.filter((e) => e.id !== note.id) });
  }, [id, note.id, notes, notesUpdate]);

  return (
    <div className="new-table--row">
      <div className="new-table--row--item w15 no-side-shadows justify-content-center">
        <b>{counter}</b>
      </div>
      <div className="new-table--row--item w15 no-side-shadows justify-content-center">
        <input type="text" disabled={isDisabled} value={values.note} name="note" style={{ textAlign: 'center' }} onChange={handleChange} />
      </div>
      <div className="new-table--row--item w15 no-side-shadows select justify-content-center">
        <Select
          value={values.type}
          options={notesOptions}
          isDisabled={isDisabled}
          placeholder="Unset"
          onChange={(option) => setFieldValue('type', option.value)}
        />
      </div>
      <div className="new-table--row--item w15 no-side-shadows justify-content-center">
        <span>{formatL(note.created_at)} </span>
      </div>
      <div className="new-table--row--item w15 no-side-shadows justify-content-center">
        <span> {author}</span>
      </div>
      <div className="new-table--row--item w25 no-side-shadows justify-content-center">
        <button type="button" onClick={isDisabled ? toggleEdit : saveNote}>
          <Icon size={20} icon={isDisabled ? 'split_job' : 'booked'} title={isDisabled ? 'Edit' : 'Save'} />
        </button>
        <button type="button" onClick={isDisabled ? deleteNote : toggleEdit}>
          <Icon size={20} icon={isDisabled ? 'delete' : 'canceled'} title={isDisabled ? 'Delete' : 'Cancel'} />
        </button>
      </div>
    </div>
  );
});

export default NoteItem;

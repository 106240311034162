import React, { Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { useInfo } from 'hooks/query/useJob';
import Resources from './Resources';
import FinalCalc from './FinalCalc';
import Payments from './Payments';
import Payment from './Payment';
import Discount from './Discount';
import Charges from './Charges';
import Insurance from './Insurance';
import Packing from './Packing';
import Inventory from './Inventory';
import Fees from './Fees';
import LongDistanceFees from './LongDistanceFees';
import './style.scss';

const RatePaymentContainer = () => {
  const { id } = useParams();

  const {
    data: { job_info: jobInfo },
  } = useInfo(id);

  const isStorageTypeJob = jobInfo?.type === 3;
  const isLongDistanceTypeJob = jobInfo?.type === 5;
  const isAllJob = jobInfo?.storage_id === 0;

  return (
    <div className="rate-payment-container">
      <div className="estimation-wrapper">
        {!isStorageTypeJob && (
          <div className="new-table resources">
            <div className="new-table--row">
              <div className="new-table--row--item justify-content-center w100">
                <h3 className="header no-padding">Resources</h3>
              </div>
            </div>

            <div className="new-table--row bcg-light">
              <div className="new-table--row--item justify-content-center w40" />
              <div className="new-table--row--item justify-content-center w30">
                <h3 className="header no-padding">ESTIMATED</h3>
              </div>
              <div className="new-table--row--item justify-content-center w30">
                <h3 className="header no-padding">FINAL</h3>
              </div>
            </div>

            {isAllJob && (
              <Fragment>
                {parseInt(jobInfo.rate_type) === 1 ? (
                  <Fragment>
                    <div className="new-table--row">
                      <div className="new-table--row--item justify-content-center w40">
                        <span>Office to Pick Up</span>
                      </div>
                      <Resources type="estimated" field="office_to_pick_up" />
                      <Resources type="final" field="office_to_pick_up" />
                    </div>
                    <div className="new-table--row">
                      <div className="new-table--row--item justify-content-center w40">
                        <span>Pick Up to Destination</span>
                      </div>
                      <Resources type="estimated" field="pick_up_to_destination" />
                      <Resources type="final" field="pick_up_to_destination" />
                    </div>
                    <div className="new-table--row">
                      <div className="new-table--row--item justify-content-center w40">
                        <span>Working time</span>
                      </div>
                      <Resources type="estimated" field="working_time" />
                      <Resources type="final" field="working_time" />
                    </div>
                    <div className="new-table--row">
                      <div className="new-table--row--item justify-content-center w40">
                        <span>Wait Time</span>
                      </div>
                      <Resources type="estimated" field="wait_time" />
                      <Resources type="final" field="wait_time" />
                    </div>
                  </Fragment>
                ) : (
                  <Fragment>
                    <div className="new-table--row">
                      <div className="new-table--row--item justify-content-center w40">
                        <span>Working Time (Labor time * hourly Rate)</span>
                      </div>
                      <Resources type="estimated" field="working_time_estimated" />
                      <Resources type="final" field="working_time_final" />
                    </div>
                  </Fragment>
                )}
              </Fragment>
            )}

            <Inventory />

            <Packing />

            <Insurance />

            <Discount />

            <Fees />

            {isLongDistanceTypeJob && <LongDistanceFees />}

            <Charges />

            {isAllJob && (
              <div className="new-table--row">
                <div className="new-table--row--item justify-content-center w40">
                  <span>TOTAL</span>
                </div>
                <Resources type="estimated" field="total" />
                <Resources type="final" field="total" />
              </div>
            )}
          </div>
        )}

        {!isStorageTypeJob && (
          <div className="est-main">
            <div className="est-main-item">
              <h3 className="header">ESTIMATED</h3>
              <FinalCalc type="estimated" />
            </div>
            <div className="est-main-item">
              <h3 className="header">FINAL</h3>
              <FinalCalc type="final" />
            </div>
          </div>
        )}
      </div>
      <div className="payment-wrapper">
        <Payment />
        <Payments type="local" />
      </div>
    </div>
  );
};

export default RatePaymentContainer;

import Swal from 'sweetalert2';

const showSuccess = (e, cb) => {
  Swal.fire({
    position: 'top-end',
    icon: 'success',
    title: e.message,
    showConfirmButton: false,
    timer: 1500,
  }).then(() => typeof cb === 'function' && cb());
};

export default showSuccess;

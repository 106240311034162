import React from 'react';
import MaskedInput from 'react-maskedinput';

const Input = (props) => {
  const { mask, onChange, name, type, placeholder, value, error, label, ...rest } = props;

  return (
    <label style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', position: 'relative' }}>
      {label && label}
      {mask ? (
        <MaskedInput
          name={name}
          mask={mask}
          value={value}
          onChange={onChange}
          type={type || 'text'}
          placeholder={placeholder}
          style={{ padding: 0, margin: 0 }}
          {...rest}
        />
      ) : (
        <input
          name={name}
          value={value}
          onChange={onChange}
          type={type || 'text'}
          placeholder={placeholder}
          style={{ padding: 0, margin: 0 }}
          {...rest}
        />
      )}

      {error && (
        <span style={{ position: 'absolute', bottom: -20 }} className="error">
          {error}
        </span>
      )}
    </label>
  );
};
export default Input;

import { useCallback } from 'react';
import { useTimeById } from 'hooks/query/useBuilding';
import { useMovingRate } from 'hooks/query/useMoving';
import { useDistanceForm } from 'hooks/query/useCommon';

export const useFetcher = () => {
  const { mutateAsync: getBuildingTimeAsync } = useTimeById();
  const { mutateAsync: getMovingRateAsync } = useMovingRate();
  const { mutateAsync: getDistanceAsync } = useDistanceForm();

  const getBuildingTime = useCallback(
    async (type, id, company_id, move_size_id) => {
      if (!company_id || !move_size_id || !id) return;

      const params = {
        building_id: id,
        company_id,
        move_size_id,
        move_type: type,
      };

      const response = await getBuildingTimeAsync(params);

      return response?.data;
    },
    [getBuildingTimeAsync]
  );

  const getMovingRate = useCallback(
    async (values) => {
      const { move_size_id, defaultNumberMan, pickup_date, pickup_time, company_id, credit_rate, packing_service } = values;

      const response = await getMovingRateAsync({
        move_size_id,
        qty_man: defaultNumberMan,
        pickup_date,
        pickup_time,
        company_id,
        credit_rate,
        packing_service,
      });

      return response?.data;
    },
    [getMovingRateAsync]
  );

  const getDistance = useCallback(
    async (zipFrom, zipTo, companyId) => {
      const params = {
        from: zipFrom,
        to: zipTo,
        company_id: companyId,
      };

      const response = await getDistanceAsync(params);

      return response?.data;
    },
    [getDistanceAsync]
  );

  return {
    getBuildingTime,
    getMovingRate,
    getDistance,
  };
};

export function prepareInitialValues(params = {}) {
  const { carrier_driver_id, carrier_id, cf_price, job_id } = params;

  return {
    job_id,
    cf_price: cf_price ?? '',
    carrier_id: carrier_id ?? null,
    carrier_driver_id: carrier_driver_id ?? null,
  };
}
export function createInitialValues(job_id) {
  return {
    job_id,
    cf_price: '',
    carrier_id: null,
    carrier_driver_id: null,
  };
}

export function prepareCarriersOptions(carriers = []) {
  return carriers.map((carr) => ({
    value: carr.id,
    label: carr.name,
  }));
}

export function prepareCarrierDriversOptions(drivers = []) {
  return drivers.map((driver) => ({
    value: driver.id,
    label: driver.name,
  }));
}

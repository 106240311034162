import { useCallback } from 'react';
import { overlapAlert } from './assignCrewAlerts';
import { useScheduleCreate } from 'hooks/query/useCrew';

const assignCrewTypes = {
  confirmed: 'confirmed',
  assigned: 'assigned',
};

const useAssignCrew = (values, params, data) => {
  const { path, closeHandler } = data;
  const { job_id, start_date, stop_date, show_all, qty_man, onSuccess } = params;

  const { mutate: createSchedule } = useScheduleCreate(job_id, path?.params?.tab, {
    week_start: start_date,
    week_end: stop_date,
    show_all: show_all,
  });

  const createScheduleHandler = useCallback(
    (type) => {
      const { crew: crewRaw, crew_id, foreman } = values;

      const crew = Object.values(crewRaw).filter((e) => e.checked);

      createSchedule(
        { crew, type, job_id, crew_id, foreman },
        {
          onSuccess: () => {
            closeHandler();
            if (onSuccess) onSuccess();
          },
        }
      );
    },
    [closeHandler, createSchedule, job_id, onSuccess, values]
  );

  const assignToJobHandler = useCallback(
    async (type) => {
      const crew = Object.values(values.crew)
        .filter((e) => e.checked)
        .reduce(
          (acc, el) => {
            if (el.unit_type === 'driver') return { ...acc, drivers: acc.drivers + 1 };
            if (el.unit_type === 'truck') return { ...acc, trucks: acc.trucks + 1 };
            if (el.unit_type === 'mover') return { ...acc, movers: acc.movers + 1 };
            return acc;
          },
          { drivers: 0, trucks: 0, movers: 0 }
        );

      if (crew.trucks < 1 || crew.drivers < 1 || crew.drivers + crew.movers < qty_man) {
        const res = await overlapAlert('No driver/truck selected or incorrect number of movers.');
        if (res.isConfirmed) {
          createScheduleHandler(type);
        }
      } else {
        createScheduleHandler(type);
      }
    },
    [values.crew, qty_man, createScheduleHandler]
  );

  return { assignToJobHandler };
};

export { useAssignCrew, assignCrewTypes };

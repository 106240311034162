import React, { Component } from 'react';
import Api from './Api';
import SearchPanel from './components/SearchPanel';
import Preloader from 'components/Preloader';
import PackingList from './components/PackingList';
import QuickView from './components/QuickView';

import './style.scss';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      all_packing: [],
      filtered: [],
      isLoading: true,

      fields: null,
      selected: [],
    };
  }

  componentDidMount() {
    Api.getPacking().then((response) => {
      if (!response) return this.setState({ isLoading: false });
      this.setState({
        all_packing: response.all_packing,
        filtered: response.all_packing,
        job_packing: response.job_packing,
        isLoading: false,
        fields: response.all_packing.reduce((acc, item) => {
          const element = response.job_packing.find((el) => el.pack_id === item.id);
          return { ...acc, [item.id]: element?.qty || 0 };
        }, {}),
        selected: response.job_packing.map((item) => +item.pack_id),
      });
    });
  }

  handleSearch = (value) => {
    if (value.length > 2) {
      const filtered = this.state.all_packing.filter((el) => el.name.toLowerCase().includes(value));
      this.setState({ filtered });
    } else {
      this.setState({ filtered: this.state.all_packing });
    }
  };

  handleAddItem = (id, qty) => {
    const { fields, selected } = this.state;

    if (selected.includes(id)) {
      this.setState({ fields: { ...fields, [id]: +fields[id] + qty } });
    } else {
      this.setState({
        selected: selected.concat(id),
        fields: { ...fields, [id]: fields[id] + qty },
      });
    }
  };

  handleDeleteFromSave = (id) => {
    this.setState((prevState) => ({
      selected: prevState.selected.filter((el) => el !== id),
      fields: {
        ...prevState.fields,
        [id]: 0,
      },
    }));
  };

  handleSavePacking = () => {
    const { selected, all_packing, fields } = this.state;

    const data = selected.map((el) => {
      const item = all_packing.find((pack) => pack.id === el);
      return {
        ...item,
        qty: fields[el],
        new_item: 1,
        pack_id: el,
      };
    });
    this.setState({ isLoading: true });
    return Api.savePacking(data);
  };

  handleClearPacking = () => this.setState({ selected: [] });

  handleChangeQty = (id, qty) => this.setState((prevState) => ({ fields: { ...prevState.fields, [id]: qty } }));

  render() {
    const { selected, isLoading, filtered, all_packing, fields } = this.state;

    return (
      <div className="container container-packing">
        {isLoading && <Preloader />}

        <div className="new-table">
          <h1>Packing</h1>
          <SearchPanel onSearch={this.handleSearch} />
          <PackingList list={filtered} onAddItem={this.handleAddItem} />
        </div>

        <QuickView
          selected={selected}
          fields={fields}
          all_packing={all_packing}
          onDeleteFromSave={this.handleDeleteFromSave}
          onSavePacking={this.handleSavePacking}
          onClearPacking={this.handleClearPacking}
          onChangeQty={this.handleChangeQty}
        />
      </div>
    );
  }
}

export default App;

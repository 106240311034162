import React, { Fragment } from 'react';
import Radio from 'components/newComponents/RadioButton';
import FeesInfo from './FeesInfo';
import { sumFees } from '../utils';

const PaymentStorage = ({ data, payment, actions }) => {
  const { payForPickup, changePickupPayType, payForStorage, changeStoragePayType, changeDestinationPayType, payForDestination } = actions;

  const isPickupPaid = data.remaining <= 0;

  return (
    <div className="new-table">
      <div className="new-table--row new-table--row__column row-border-bottom">
        <div className="new-table--row">
          <div className="new-table--row--item col-3 no-shadow">
            <label className="justify-content-start">
              <input type="checkbox" className="m-0 mr-3" disabled={isPickupPaid} checked={payment.pay_for_pickup} onChange={payForPickup} />
              {isPickupPaid ? <span>Pickup paid.</span> : <span>Pay for Pickup</span>}
            </label>
          </div>
        </div>

        {payment.pay_for_pickup && (
          <Fragment>
            <div className="new-table--row">
              <div className="new-table--row--item col-4 no-shadow vertical-align-top">
                <Radio
                  name="pickup_payment_type"
                  value="pickup_full"
                  title="Pay Minimum -"
                  radioStyles={{ marginRight: 20, marginLeft: 0 }}
                  titleStyles={{ fontWeight: 500 }}
                  current={payment.pickup_payment_type}
                  onChange={changePickupPayType}
                />
              </div>
              <div className="new-table--row--item col-2 no-shadow vertical-align-top">
                <span className="price">${data.remaining}</span>
              </div>
              <div className="new-table--row--item col-6 no-shadow flex-column vertical-align-top">
                <p>Not a Final Total. Additional hour, if needed, is $230, billed every 5 minutes @$19.17</p>
                <p>{data.estimated_time} hrs. minimum. Not a Final Total. Additional time, if needed, charged at 1/4 hour increments</p>
              </div>
            </div>

            {parseInt(data.deposit_paid) === 0 ? (
              <div className="new-table--row">
                <div className="new-table--row--item col-4 no-shadow vertical-align-top">
                  <Radio
                    name="pickup_payment_type"
                    value="pickup_deposit"
                    title="Deposit - "
                    radioStyles={{ marginRight: 20, marginLeft: 0 }}
                    titleStyles={{ fontWeight: 500 }}
                    current={payment.pickup_payment_type}
                    onChange={changePickupPayType}
                  />
                </div>
                <div className="new-table--row--item col-2 no-shadow vertical-align-top">
                  <span className="price">${parseFloat(data.deposit).toFixed(2)}</span>
                </div>
                <div className="new-table--row--item col-6 no-shadow vertical-align-top">
                  <p>Non-Refundable if job canceled by customer less than 3 days prior to moving date</p>
                </div>
              </div>
            ) : (
              <div className="new-table--row">
                <div className="new-table--row--item no-shadow">
                  <Radio
                    name="pickup_payment_type"
                    value="pickup_deposit"
                    disabled
                    title="Deposit - Paid."
                    radioStyles={{ marginRight: 20, marginLeft: 0 }}
                    titleStyles={{ fontWeight: 500 }}
                  />
                </div>
              </div>
            )}
          </Fragment>
        )}
      </div>

      <div className="new-table--row new-table--row__column row-border-bottom">
        <div className="new-table--row">
          <div className="new-table--row--item col-3 no-shadow">
            <label className="justify-content-start">
              <input type="checkbox" className="m-0 mr-3" checked={payment.pay_for_storage} onChange={payForStorage} />
              <span>Pay for Storage</span>
            </label>
          </div>
        </div>

        {payment.pay_for_storage && (
          <Fragment>
            <div className="new-table--row">
              <div className="new-table--row--item col-4 no-shadow vertical-align-top">
                <Radio
                  name="storage_payment_type"
                  value="storage_period"
                  title="First Month"
                  radioStyles={{ marginRight: 20, marginLeft: 0 }}
                  titleStyles={{ fontWeight: 500 }}
                  current={payment.storage_payment_type}
                  onChange={changeStoragePayType}
                />
              </div>
              <div className="new-table--row--item col-2 no-shadow vertical-align-top">
                <span>${Number(data.storage_calc.storage_rate).toFixed(2)}</span>
              </div>
              <div className="new-table--row--item no-shadow vertical-align-top">
                Customer have to pay all fees and Deposit to confirm the move to Storage
              </div>
            </div>
            <FeesInfo data={data.storage_calc} />
            <div className="new-table--row">
              <div className="new-table--row--item col-4 no-shadow no-padding-y" style={{ paddingLeft: 53 }}>
                First Month total
              </div>
              <div className="new-table--row--item col-2 no-shadow no-padding-y">
                <span className="price">${(Number(data.storage_calc.storage_rate) + sumFees(data.storage_calc)).toFixed(2)}</span>
              </div>
            </div>

            {/* storage_deposit */}

            <div className="new-table--row">
              <div className="new-table--row--item col-4 no-shadow vertical-align-top">
                <Radio
                  name="storage_payment_type"
                  value="storage_full"
                  title="Pay Full"
                  radioStyles={{ marginRight: 20, marginLeft: 0 }}
                  titleStyles={{ fontWeight: 500 }}
                  current={payment.storage_payment_type}
                  onChange={changeStoragePayType}
                />
              </div>
              <div className="new-table--row--item col-2 no-shadow vertical-align-top">
                <span className="price">${data.storage_calc.current_amount.toFixed(2)}</span>
              </div>
            </div>
          </Fragment>
        )}
      </div>

      {data.destination_total !== 0 && (
        <div className="new-table--row new-table--row__column row-border-bottom">
          <div className="new-table--row">
            <div className="new-table--row--item col-3 no-shadow">
              <label className="justify-content-start">
                <input type="checkbox" className="m-0 mr-3" checked={payment.pay_for_destination} onChange={payForDestination} />
                <span>Pay for Destination</span>
              </label>
            </div>
          </div>

          {payment.pay_for_destination && (
            <Fragment>
              <div className="new-table--row">
                <div className="new-table--row--item col-4 no-shadow vertical-align-top">
                  <Radio
                    name="destination_payment_type"
                    value="destination_full"
                    title="Pay Minimum -"
                    radioStyles={{ marginRight: 20, marginLeft: 0 }}
                    titleStyles={{ fontWeight: 500 }}
                    current={payment.destination_payment_type}
                    onChange={changeDestinationPayType}
                  />
                </div>
                <div className="new-table--row--item col-2 no-shadow vertical-align-top">
                  <span className="price">${data.destination_remaining}</span>
                </div>
                <div className="new-table--row--item col-6 no-shadow flex-column vertical-align-top">
                  <p>Not a Final Total. Additional hour, if needed, is $230, billed every 5 minutes @$19.17</p>
                  <p>{data.estimated_time} hrs. minimum. Not a Final Total. Additional time, if needed, charged at 1/4 hour increments</p>
                </div>
              </div>

              {parseInt(data.destination_deposit_paid) === 0 ? (
                <div className="new-table--row">
                  <div className="new-table--row--item col-4 no-shadow vertical-align-top">
                    <Radio
                      name="destination_payment_type"
                      value="destination_deposit"
                      title="Deposit - "
                      radioStyles={{ marginRight: 20, marginLeft: 0 }}
                      titleStyles={{ fontWeight: 500 }}
                      current={payment.destination_payment_type}
                      onChange={changeDestinationPayType}
                    />
                  </div>
                  <div className="new-table--row--item col-2 no-shadow vertical-align-top">
                    <span className="price">${parseFloat(data.deposit).toFixed(2)}</span>
                  </div>
                  <div className="new-table--row--item col-6 no-shadow vertical-align-top">
                    <p>Non-Refundable if job canceled by customer less than 3 days prior to moving date</p>
                  </div>
                </div>
              ) : (
                <div className="new-table--row">
                  <div className="new-table--row--item no-shadow">
                    <Radio
                      name="destination_payment_type"
                      value="destination_deposit"
                      title="Deposit - Paid."
                      disabled
                      radioStyles={{ marginRight: 20, marginLeft: 0 }}
                      titleStyles={{ fontWeight: 500 }}
                    />
                  </div>
                </div>
              )}
            </Fragment>
          )}
        </div>
      )}
    </div>
  );
};

export default PaymentStorage;

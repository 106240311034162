import React, { Fragment, useCallback, useMemo } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useDefault } from 'hooks/query/useCommon';
import { useRemindersChain, useReminderChainDelete, useReminderChainSave } from 'hooks/query/useReminder';
import { preventArrowUpDown } from 'utils/preventArrowUpDown';
import { formatTimeAPMeridiem } from 'helpers/dateHelper';
import Select from 'components/newComponents/Select';
import Input from 'components/newComponents/Input';
import TimeSelect from 'components/TimeSelect';
import Icon from 'components/Icon';

const EditReminders = ({ data }) => {
  const { mutate: deleteRemindersChain } = useReminderChainDelete(data.id);
  const { mutate: saveReminderChain } = useReminderChainSave(data.id);

  const { data: remindersChain } = useRemindersChain(data.id);
  const { data: defaultItems } = useDefault();

  const mailTemplateOptions = useMemo(() => {
    if (!defaultItems) return [];

    return defaultItems.mail_templates.map((e) => ({ label: e.title, value: e.slug }));
  }, [defaultItems]);

  const { values, errors, touched, handleSubmit, handleChange, setFieldValue } = useFormik({
    initialValues: {
      chain_id: data.id,
      type: data.type || '',
      reminder: { days: '', template_id: '', time: '8:00 PM' },
    },
    enableReinitialize: true,
    onSubmit: saveReminderChain,
    validationSchema: Yup.object().shape({
      reminder: Yup.object().shape({
        template_id: Yup.string().required('Select template name!'),
        days: Yup.number().required('Enter days!'),
      }),
    }),
  });

  const getDeepValue = (obj, path) => {
    return path.split('.').reduce((acc, name, index) => {
      if (index === 0) return obj?.[name];
      return acc?.[name];
    }, obj);
  };

  const isValidHandler = useCallback(
    (name) => {
      const error = getDeepValue(errors, name);
      if (error && getDeepValue(touched, name)) return error;
      return null;
    },
    [errors, touched]
  );

  return (
    <Fragment>
      {Boolean(remindersChain && remindersChain.length) && (
        <Fragment>
          <div className="new-table--row">
            <div className="new-table--row--item light no-side-shadows col-1">#</div>
            <div className="new-table--row--item light no-side-shadows col-6">Template</div>
            <div className="new-table--row--item light no-side-shadows col-2">Send after</div>
            <div className="new-table--row--item light no-side-shadows col-2">At</div>
            <div className="new-table--row--item light no-side-shadows col-1" />
          </div>

          {remindersChain.map((reminderChain) => (
            <div className="new-table--row" key={reminderChain.id}>
              <div className="new-table--row--item no-side-shadows col-1">{reminderChain.id}</div>
              <div className="new-table--row--item no-side-shadows col-6">{reminderChain.title}</div>
              <div className="new-table--row--item no-side-shadows col-2">{reminderChain.send_after} day(s)</div>
              <div className="new-table--row--item no-side-shadows col-2">{reminderChain.time}</div>
              <div className="new-table--row--item no-side-shadows col-1">
                <button type="button" onClick={() => deleteRemindersChain({ id: reminderChain.id })}>
                  <Icon icon="remove_cross" title="Delete Reminder" size={20} style={{ marginRight: 0, height: 20 }} />
                </button>
              </div>
            </div>
          ))}
        </Fragment>
      )}

      {remindersChain && remindersChain.length === 0 && (
        <div className="new-table--row">
          <div className="new-table--row--item no-side-shadows justify-content-center">No Reminders</div>
        </div>
      )}

      <form>
        <div className="new-table--row">
          <div className="new-table--row--item col-2">Template</div>
          <div className="new-table--row--item select">
            <Select
              options={mailTemplateOptions}
              value={values.reminder.template_id}
              error={isValidHandler('reminder.template_id')}
              onChange={({ value }) => setFieldValue('reminder.template_id', value)}
            />
          </div>
        </div>
        <div className="new-table--row">
          <div className="new-table--row--item col-2">Send after, days</div>
          <div className="new-table--row--item">
            <Input
              type="number"
              placeholder="Days"
              name="reminder.days"
              value={values.reminder.days}
              error={isValidHandler('reminder.days')}
              onChange={handleChange}
              onKeyDown={preventArrowUpDown}
            />
          </div>
        </div>
        <div className="new-table--row">
          <div className="new-table--row--item col-2">At</div>
          <div className="new-table--row--item">
            <TimeSelect
              interval={60}
              placeholder="At"
              name="reminder.time"
              value={values.reminder.time}
              error={isValidHandler('reminder.time')}
              onChange={({ name, date }) => setFieldValue(name, formatTimeAPMeridiem(date))}
            />
          </div>
        </div>
      </form>

      <div className="new-table--row">
        <div className="new-table--row--item justify-content-center">
          <button type="button" className="cancel-chain">
            Cancel
          </button>
        </div>
        <div className="new-table--row--item justify-content-center">
          <button type="button" className="save-chain" onClick={handleSubmit}>
            Save
          </button>
        </div>
      </div>
    </Fragment>
  );
};

export default EditReminders;

import React, { memo } from 'react';
import { useFormik } from 'formik';
import Select from 'components/newComponents/Select';

const Header = memo(() => {
  return <th>Inventory Conditions</th>;
});

const Footer = memo(() => {
  return <td></td>;
});

const Content = memo((props) => {
  const { id, symbols, condition, onSave } = props;

  const { values, setFieldValue } = useFormik({
    enableReinitialize: true,
    initialValues: {
      id,
      condition: condition ? condition.split('/') : ['', '', ''],
    },
  });

  const onChangeConditions = (value, index) => {
    const condition = [...values.condition];
    condition[index] = value;

    setFieldValue('condition', condition);

    onSave({ ...values, condition });
  };

  return (
    <td style={{ padding: 0 }}>
      <div className="d-flex justify-content-between" style={{ gap: 10 }}>
        <Select
          name="condition[0]"
          value={values.condition[0]}
          wrapperStyle={{ width: 110, flexGrow: 1 }}
          options={symbols.descriptiveSymbols}
          placeholder="Desc."
          onChange={({ value }) => onChangeConditions(value, 0)}
        />
        <Select
          name="condition[1]"
          value={values.condition[1]}
          wrapperStyle={{ width: 110, flexGrow: 1 }}
          options={symbols.exceptionSymbols}
          placeholder="Except."
          onChange={({ value }) => onChangeConditions(value, 1)}
        />
        <Select
          name="condition[2]"
          value={values.condition[2]}
          wrapperStyle={{ width: 110, flexGrow: 1 }}
          options={symbols.locationSymbols}
          placeholder="Loc."
          onChange={({ value }) => onChangeConditions(value, 2)}
        />
      </div>
    </td>
  );
});

export { Header, Footer, Content };

import { useFormik } from 'formik';
import validationSchema from '../validationSchema';

export const useForm = (params) => {
  const { initialValues, onSubmit } = params;

  const { values, errors, touched, dirty, handleSubmit, setFieldValue, setValues, handleChange } = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const handleChangeCarrier = ({ value }) => {
    setValues({
      ...values,
      carrier_id: value,
      carrier_driver_id: null,
    });
  };

  return {
    values,
    errors,
    touched,
    dirty,
    actions: {
      handleChange,
      setFieldValue,
      handleChangeCarrier,
      handleSubmit,
    },
  };
};

import React, { Fragment } from 'react';
import Input from 'components/newComponents/Input';

const InventoryTable = (props) => {
  const { values, handleChange, setFieldValue } = props;

  const onChangeStorageRate = (event) => {
    handleChange(event);
    const value = Number(event.target.value);

    if (values.job_inventory_total) {
      setFieldValue('total_price', (value * values.job_inventory_total).toFixed(2));
    }
  };

  const onChangeInventoryTotal = (event) => {
    handleChange(event);
    const value = Number(event.target.value);

    setFieldValue('total_price', (value * values.storage_rate).toFixed(2));
  };

  return (
    <Fragment>
      <div className="new-table--row">
        <div className="new-table--row--item light">Starage Rate</div>
        <div className="new-table--row--item light">Inventory Total CF</div>
        <div className="new-table--row--item light">$ Total</div>
      </div>
      <div className="new-table--row">
        <div className="new-table--row--item">
          <span style={{ marginRight: 10 }}>$</span>
          <Input name="storage_rate" value={values.storage_rate} onChange={onChangeStorageRate} />
        </div>
        <div className="new-table--row--item">
          <span style={{ marginRight: 10 }}>CF</span>
          <Input name="job_inventory_total" value={values.job_inventory_total} onChange={onChangeInventoryTotal} />
        </div>
        <div className="new-table--row--item">
          <span style={{ marginRight: 10 }}>$</span>
          <Input name="total_price" value={values.total_price} onChange={handleChange} />
        </div>
      </div>
    </Fragment>
  );
};

export default InventoryTable;

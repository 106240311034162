export function getSymbolsOptions(statuses) {
  let descriptiveSymbols = [];
  let exceptionSymbols = [];
  let locationSymbols = [];

  if (statuses) {
    descriptiveSymbols = Object.entries(statuses.descriptive_symbols).map(([key, value]) => {
      return {
        label: value,
        value: key,
      };
    });

    exceptionSymbols = Object.entries(statuses.exception_symbols).map(([key, value]) => {
      return {
        label: value,
        value: key,
      };
    });

    locationSymbols = Object.values(statuses.location_symbols).map((value) => {
      return {
        label: value,
        value: value,
      };
    });
  }

  return {
    descriptiveSymbols,
    exceptionSymbols,
    locationSymbols,
  };
}

import React from 'react';
import { useTabInfo } from 'hooks/query/useJob';
import { useParams } from 'react-router-dom';
import Icon from 'components/Icon';

const JobNotes = ({ type, title }) => {
  const { id, tab } = useParams();

  const {
    data: { notes },
  } = useTabInfo(id, tab);

  const internalNotes = notes.filter((item) => item.type === 5);
  const otherNotes = notes.filter((e) => Number(e.type) === Number(type)).filter((item) => item.type !== 5);

  return (
    <div className={type === 5 ? 'w100' : 'w50'}>
      {notes.length > 0 && (
        <div className="new-table">
          {title && <h3 className="header">{title}</h3>}
          {type === 5 ? (
            <div className="p-3">
              {internalNotes.map(({ note, id }, index, array) => (
                <div
                  key={id}
                  className="d-flex justify-content-around align-items-center"
                  style={{ marginBottom: index + 1 === array.length ? 0 : 16 }}
                >
                  <div className="d-flex align-items-center flex-grow-1">
                    <Icon icon="text_notes" title="Notes title" style={{ marginRight: 7 }} />
                    <p className="new-table--row--item">{note}</p>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            otherNotes.map((item) => (
              <div className="new-table--row no-padding" key={item.id}>
                <div className="new-table--row--item">
                  <Icon icon="text_notes" title="Notes title" style={{ marginRight: 7 }} />
                  <p>{item.note}</p>
                </div>
                <p className="new-table--row--item">{item?.created_at}</p>
              </div>
            ))
          )}
        </div>
      )}
    </div>
  );
};

export default JobNotes;

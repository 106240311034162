import React from 'react';
import moment from 'moment';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import { statusNumberToName } from 'helpers/statuses';
import Icon from 'components/Icon';
import history from 'utils/history';
import routes from 'config/routes';

import './CalendarBig.scss';

const CalendarBig = (props) => {
  const localizer = momentLocalizer(moment);

  const handleDoubleClick = (item_id) => () => history.push({ pathname: `${routes.JOBS}/all/${item_id}` });

  const Event = ({ event }) => (
    <div className="description" onDoubleClick={handleDoubleClick(event.id)}>
      <Icon className="description-icon" icon={statusNumberToName(event.status)} size={22} />
      <p className="description-title">{event.title}</p>
      <p className="description-desc">{event.desc}</p>
    </div>
  );

  const EventAgenda = ({ event }) => (
    <span>
      <em>{event.title}</em>
      <p>{event.desc}</p>
    </span>
  );

  const onNavigate = (date) => props.selectNewDate(date);

  return (
    <Calendar
      events={props.data}
      views={['month', 'week', 'day']}
      step={30}
      showMultiDayTimes
      date={props.displayDate}
      onNavigate={onNavigate}
      defaultView="day"
      min={new Date(2017, 10, 0, 6)}
      max={new Date(2017, 10, 0, 23)}
      components={{
        event: Event,
        agenda: { event: EventAgenda },
      }}
      localizer={localizer}
    />
  );
};

export default CalendarBig;

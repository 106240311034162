import React, { Component } from 'react';
import { CardElement } from 'react-stripe-elements';

class CardSection extends Component {
  render() {
    return (
      <div className="stripe-form">
        <p className="stripe-form-header">Card details</p>
        <CardElement
          hidePostalCode={true}
          onChange={this.props.checkValidCardData}
          style={{
            base: {
              fontSize: '11px',
              fontWeight: 700,
              lineHeight: '20px',
              color: '#252B33',
              '::placeholder': {
                color: '#B1C3CC',
              },
            },
            empty: { color: '#B1C3CC' },
            invalid: { color: '#ce1a2c' },
          }}
        />
      </div>
    );
  }
}

export default CardSection;

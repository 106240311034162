import React, { memo, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { withRouter } from 'react-router';
import { getParams } from 'helpers/jobs';
import List from './List';
import ChatCommunications from './ChatCommunications';
import Detail from './Detail';

const Communications = memo(() => {
  const { search } = useLocation();

  const params = useMemo(() => getParams(search), [search]);

  const renderDetails = useMemo(() => {
    switch (params?.selected_type) {
      default:
      case 'reminder':
      case 'inbox_email':
      case 'outbox_email':
      case 'reminders_sent':
      case 'inbox_email_job':
      case 'outbox_email_job':
        return <Detail />;
      case 'chat':
        return <ChatCommunications />;
    }
  }, [params.selected_type]);

  return (
    <div className="page-content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-6">
            <List />
          </div>
          <div className="col-6">{renderDetails}</div>
        </div>
      </div>
    </div>
  );
});

export default withRouter(Communications);

import React, { memo } from 'react';
import Chips from 'react-email-chips';
import './style.scss';

const EmailChips = memo((props) => {
  const { value = [], onChange, required = false, limit = 5, placeholder } = props;

  const pattern = new RegExp(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,5})+$/);

  const emailInput = () => {
    return value.map((item, key) => ({ email: item, valid: !!item.match(pattern), key }));
  };

  return (
    <div className="email-chips">
      <Chips
        limit={limit}
        chips={emailInput(value)}
        pattern={pattern}
        required={required}
        save={(data) => onChange(data)}
        placeholder={placeholder ?? 'Type email and press "Enter"'}
      ></Chips>
    </div>
  );
});

export default EmailChips;

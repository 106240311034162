import React from 'react';
import DatePicker from 'react-datepicker';
import { useField } from 'formik';
import moment from 'moment';

const FormikDate = (props) => {
  const { value, className, minDate, disabled, newChange, placeholder } = props;
  const [, meta, helpers] = useField(props);

  const handleChange = (value) => {
    helpers.setValue(moment(value).format('MM/DD/YYYY'));
    if (newChange) newChange(value);
  };

  return (
    <div className="d-flex flex-column position-relative">
      <DatePicker
        selected={!!value ? new Date(value) : null}
        onChange={handleChange}
        className={className}
        placeholderText={placeholder}
        minDate={minDate}
        disabled={disabled}
      />
      {meta.error && meta.touched && (
        <span style={{ position: 'absolute', bottom: -15 }} className="error">
          {meta.error}
        </span>
      )}
    </div>
  );
};

export default FormikDate;

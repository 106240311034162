import React from 'react';
import ColumnComponent from '../ColumnComponent';

const BookingCharts = ({ list }) => {
  if (list.length === 0) return null;

  let series = [];
  let options = [];

  list.forEach((item) => {
    options.push(item.name);
    series.push(item.jobs.jobs);
  });

  return (
    <div>
      <p>Moving Sizes</p>
      <ColumnComponent options={options} series={series} type="bar" height={500} />
    </div>
  );
};

export default BookingCharts;

import React, { useCallback, Fragment } from 'react';
import { useModal } from 'context/modal';
import { useParams } from 'react-router';
import { useInfo, useTabInfo } from 'hooks/query/useJob';
import { usePackingSave } from 'hooks/query/useJob';
import Icon from 'components/Icon';

const Packing = () => {
  const { open, modalNames } = useModal();

  const { id, tab } = useParams();

  const {
    data: { job_info: jobInfo },
  } = useInfo(id);

  const {
    data: { job_packing: jobPacking },
  } = useTabInfo(id, tab);

  const { mutate: savePacking } = usePackingSave(id, tab);

  const deleteItem = useCallback(
    (type) => {
      savePacking({
        job_packing: {
          estimated: type === 'estimated' ? [] : jobPacking[type],
          final: [],
        },
        job_id: jobInfo.id,
      });
    },
    [jobInfo.id, jobPacking, savePacking]
  );

  const est_packing_items = jobPacking['estimated'].reduce((sum, item) => sum + parseInt(item.qty), 0);
  const fin_packing_items = jobPacking['final'].reduce((sum, item) => sum + parseInt(item.qty), 0);
  const est_packing_total = jobPacking['estimated'].reduce((sum, item) => sum + item.qty * parseFloat(item.price), 0);
  const fin_packing_total = jobPacking['final'].reduce((sum, item) => sum + item.qty * parseFloat(item.price), 0);

  return (
    <Fragment>
      <div className="new-table--row bcg-light">
        <div className="new-table--row--item justify-content-center  w40">
          <b>Packing</b>
        </div>
        <div className="new-table--row--item w30 justify-content-center no-padding">
          <button className="btn p20 flex-grow-1 d-flex text-center" onClick={() => open(modalNames.packing, { type: 'estimated' })}>
            <Icon icon="add" size={16} />
          </button>
        </div>
        <div className="new-table--row--item w30 justify-content-center no-padding">
          <button className="btn p20 flex-grow-1 d-flex text-center" onClick={() => open(modalNames.packing, { type: 'final' })}>
            <Icon icon="add" size={16} />
          </button>
        </div>
      </div>

      {(+est_packing_items !== 0 || +fin_packing_items !== 0) && (
        <Fragment>
          <div className="new-table--row">
            <div className="new-table--row--item justify-content-center w40" />
            <div className="new-table--row--item no-side-shadows w60 justify-content-center no-padding">
              {+est_packing_items !== 0 ? (
                <div className="new-table--row--item w50 justify-content-center no-padding">
                  <div className="new-table--row">
                    <div className="new-table--row--item w70 justify-content-center no-side-shadows">
                      <button className="show-modal" onClick={() => deleteItem('estimated')}>
                        <Icon size={18} icon="delete" title="Delete" />
                      </button>
                      <span>{`${est_packing_items} items`}</span>
                    </div>
                    <div className="new-table--row--item w30 justify-content-center no-side-shadows">
                      <b>${est_packing_total?.toFixed(2)}</b>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="new-table--row" />
              )}

              {+fin_packing_items !== 0 ? (
                <div className="new-table--row--item w50 justify-content-center no-padding">
                  <div className="new-table--row">
                    <div className="new-table--row--item w70 justify-content-center no-side-shadows">
                      <button className="show-modal" onClick={() => deleteItem('final')}>
                        <Icon size={18} icon="delete" title="Delete" />
                      </button>
                      <span>{`${fin_packing_items} items`}</span>
                    </div>
                    <div className="new-table--row--item w30 justify-content-center no-side-shadows">
                      <b>${fin_packing_total?.toFixed(2)}</b>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="new-table--row" />
              )}
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default Packing;

import React from 'react';
import Preloader from 'components/Preloader';
import Chains from './Chains';
import { useChains } from 'hooks/query/useReminder';
import './styles.scss';

const ReminderChains = () => {
  const { data: chains = [], isFetching } = useChains();

  return (
    <div className="container container-reminder">
      {isFetching && <Preloader />}

      <div className="new-table">
        <h1>Reminders</h1>
        <Chains chains={chains} />
      </div>
    </div>
  );
};

export default ReminderChains;

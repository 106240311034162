import React, { Fragment, useCallback, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Pagination from 'react-paginate';
import ReactTooltip from 'react-tooltip';
import TopBar from './TopBar';
import { getParams } from 'helpers/jobs';
import history, { updateRouteParams } from 'utils/history';
import { routePaths } from 'config/routes';
import { useFetchData } from './useFetchData';
import { useJobListBuilder } from './useJobListBuilder';
import { usePageBuilder } from './usePageBuilder';
import './styles.scss';

const Jobs = () => {
  const { pathname, search } = useLocation();
  const { type, id } = useParams();

  const onChangeParams = useCallback(
    (newParams, jobsType) => {
      const params = new URLSearchParams(search);
      Object.keys(newParams).forEach((key) => {
        if (params.has(key)) {
          params.set(key, newParams[key]);
          if (newParams[key] === '') {
            params.delete(key);
          }
          return;
        }
        params.append(key, newParams[key]);
      });

      history.replace({
        pathname: jobsType ? updateRouteParams(routePaths.JOBS, { type: jobsType }) : pathname,
        search: params.toString(),
      });
    },
    [pathname, search]
  );

  const params = useMemo(() => getParams(search), [search]);

  const displayAllJobsOnSinglePage = useMemo(() => {
    if (type === 'new') return true;
    if (Boolean(params.value)) return true;

    return false;
  }, [type, params.value]);

  const { jobs, onRefreshJobList } = useFetchData(type, params);
  const jobList = useJobListBuilder(jobs, id, displayAllJobsOnSinglePage);
  const { currentPage, handlePaginationChange, findPageIndex } = usePageBuilder(jobList, id);

  return (
    <Fragment>
      <TopBar onChangeParams={onChangeParams} onRefreshJobList={onRefreshJobList} />
      <div className="scrollable-container mt-20">{currentPage}</div>
      <Pagination
        pageCount={jobList.length}
        forcePage={findPageIndex()}
        pageRangeDisplayed={5}
        marginPagesDisplayed={2}
        containerClassName="pagination"
        activeClassName="active"
        pageLabelBuilder={(index) => {
          const jobPage = jobList[index - 1];
          const tooltipId = `page-index-tooltip-${index}`;

          return (
            <span
              data-tip
              data-for={tooltipId}
              style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
              {index}
              {jobPage && jobPage.period && (
                <ReactTooltip className="page-index-tooltip" arrowColor="transparent" id={tooltipId} place="top">
                  <p>{jobPage.period}</p>
                  <p>{jobPage.key}</p>
                </ReactTooltip>
              )}
            </span>
          );
        }}
        onPageChange={handlePaginationChange}
      />
    </Fragment>
  );
};

export default Jobs;

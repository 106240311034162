import React, { Fragment } from 'react';
import { FieldArray } from 'formik';
import Select from 'components/newComponents/Select';
import Icon from 'components/Icon';
import { useForm } from '../../../hooks/useForm';

function UnitSizes({ unit_sizes, companies }) {
  const { values, actions } = useForm();

  return (
    <FieldArray
      name="unit_sizes"
      render={({ remove, push }) => (
        <Fragment>
          <div className="new-table--row">
            <div className="new-table--row--item light">Unit Size</div>
          </div>

          {values?.unit_sizes?.map((item, index) => (
            <div className="new-table--row" key={index}>
              <div className="new-table--row--item select">
                <Select
                  placeholder="Unit Size"
                  name={`unit_sizes[${index}]`}
                  value={item}
                  options={unit_sizes}
                  hideSelectedOptions={true}
                  onChange={(data) => actions.handleSelectUnitSize(data, index, companies, unit_sizes)}
                />

                <button
                  type="button"
                  className="remove-btn"
                  onClick={() => {
                    remove(index);

                    if (values.unit_sizes.length === 1) {
                      actions.setFieldValue('storage_rate_type', '');
                      actions.setFieldValue('storage_rate', '');
                      return;
                    }
                  }}
                >
                  <Icon icon="remove" size={18} />
                </button>
              </div>
            </div>
          ))}

          <div className="new-table--row">
            <div className="new-table--row--item position-relative">
              <button type="button" className="add-btn" onClick={() => push(undefined)}>
                <Icon icon="add" size={18} />
                <span>Add Size</span>
              </button>

              {actions.isValidHandler('unit_sizes') && (
                <span style={{ position: 'absolute', bottom: 0, left: 20 }} className="error">
                  {actions.isValidHandler('unit_sizes')}
                </span>
              )}
            </div>
          </div>
        </Fragment>
      )}
    />
  );
}

export default UnitSizes;

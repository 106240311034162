import axios from 'axios';

class Api {
  constructor() {
    this.serverApi = process.env.REACT_APP_API_URL;

    this.client = axios.create({ baseURL: this.serverApi });
    let url = new URL(window.location.href);
    this.job_id = url.searchParams.get('job_id');
  }

  getPacking = async () => {
    if (!this.job_id) return;
    try {
      const response = await this.client.post(`/api/customer/jobs/getCustomerPacking`, { job_id: this.job_id });
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };

  savePacking = async (items) => {
    try {
      const jobId = this.job_id;

      await this.client
        .post(`/api/customer/jobs/saveCustomerPacking`, { job_id: jobId, items })
        .then(function (response) {
          window.location.href = `${window.location.origin}/customer/info/edit-job-thank-you/${jobId}`;
        })
        .catch(function (error) {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
}

export default new Api();

import { useCallback, useState } from 'react';

export const useFormTab = () => {
  const [isMain, setIsMain] = useState(true);

  const toggleTab = useCallback(() => {
    setIsMain((pre) => !pre);
    window.scrollTo(0, 0);
  }, []);

  const handleTabOnSubmit = useCallback((isValid) => {
    if (!isValid) setIsMain(true);
  }, []);

  return {
    isMain,
    actions: {
      toggleTab,
      handleTabOnSubmit,
    },
  };
};

import React, { useMemo } from 'react';
import { useTabInfo } from 'hooks/query/useJob';
import NotesCreateForm from './NotesCreateForm';
import NotesList from './NotesList';

const InternalNotes = ({ id, tab }) => {
  const { data: tabInfoData } = useTabInfo(id, tab);

  const internalNotes = useMemo(() => {
    if (!tabInfoData) return [];

    return tabInfoData.notes.filter((note) => note.type === 5);
  }, [tabInfoData]);

  return (
    <div className="new-table modal">
      <NotesCreateForm jobId={id} tab={tab} />
      <NotesList id={id} tab={tab} list={internalNotes} />
    </div>
  );
};

export default InternalNotes;

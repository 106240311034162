import React, { useState } from 'react';
import { useModal } from 'context/modal';
import { ModalButton } from 'components/newComponents/Buttons';
import Modal from 'components/newComponents/Modal';
import { preventArrowUpDown } from 'utils/preventArrowUpDown';

const PayrollReimbursement = () => {
  const [amount, setAmount] = useState(0);
  const [note, setNote] = useState('');
  const { modalNames, close, info } = useModal();

  const isVisible = info(modalNames.payrollReimbursement).visible;
  const user_id = info(modalNames.payrollReimbursement).params.user_id;
  const user_type = info(modalNames.payrollReimbursement).params.user_type;
  const onSubmit = info(modalNames.payrollReimbursement).params.onSubmit;

  const closeHandler = () => close(modalNames.payrollReimbursement);
  const submitHandler = () => {
    if (!amount || !note) return;

    onSubmit({ user_id, user_type, amount, note });
    closeHandler();
  };

  return (
    <Modal style={{ width: 700 }} header="Add Adjustment" show={isVisible} onHide={closeHandler}>
      <div className="p-3 d-flex align-items-center">
        <span className="mr-2">$</span>
        <input
          style={{ width: '100%' }}
          type="number"
          placeholder="Enter the refund amount"
          onKeyDown={preventArrowUpDown}
          onChange={(e) => setAmount(e.target.value)}
        />
      </div>
      <div className="p-3">
        <input
          className="ml-3 d-flex"
          style={{ width: '100%' }}
          type="text"
          placeholder="Enter the refund note"
          onChange={(e) => setNote(e.target.value)}
        />
      </div>

      <div className="footer-buttons">
        <ModalButton title="Cancel" variant="cancel" onClick={closeHandler} />
        <ModalButton title="Submit" variant="confirm" disabled={!amount || !note} onClick={submitHandler} />
      </div>
    </Modal>
  );
};

export default PayrollReimbursement;

import React, { Fragment } from 'react';
import { formatFullDBDate, formatTimeAPMeridiem } from 'helpers/dateHelper';
import Input from 'components/newComponents/Input';
import TimeSelect from 'components/TimeSelect';
import DateSelect from 'components/DateSelect';
import { useForm } from '../../../hooks/useForm';

function Base() {
  const { values, actions } = useForm();

  return (
    <Fragment>
      <div className="new-table--row">
        <div className="new-table--row--item">
          <Input
            name="from_name"
            value={values['from_name']}
            onChange={actions.handleChange}
            error={actions.isValidHandler('from_name')}
            placeholder="Name"
          />
        </div>
      </div>

      <div className="new-table--row">
        <div className="new-table--row--item">
          <DateSelect
            name="pickup_date"
            selected={values['pickup_date']}
            placeholder="Pick Up Date"
            onChange={({ name, date }) => actions.setFieldValue(name, formatFullDBDate(date))}
            error={actions.isValidHandler('pickup_date')}
            minDate={new Date()}
          />
        </div>

        <div className="new-table--row--item">
          <TimeSelect
            name="pickup_time"
            value={values['pickup_time']}
            placeholder="Pick Up Time"
            onChange={({ name, date }) => actions.setFieldValue(name, formatTimeAPMeridiem(date))}
          />
        </div>
      </div>
    </Fragment>
  );
}

export default Base;

const getJobType = (type_id) => {
  switch (+type_id) {
    case 1:
      return 'LO';
    case 2:
      return 'LD';
    case 3:
      return 'Labor';
    case 4:
      return 'Storage';
    default:
      return '';
  }
};

const getPaymentType = (type_id) => {
  switch (Number(type_id)) {
    case 1:
      return 'Stripe';
    case 2:
      return 'Cash';
    case 3:
      return 'Credit';
    case 5:
      return 'Zelle';
    default:
      return 'Payment Denied';
  }
};

const getRateModifier = (data) => {
  const [rateType, rateValue] = Object.entries(data)[0];
  const rateModifier = { label: '', value: rateValue };

  switch (rateType) {
    case 'afternoon_rate':
      rateModifier.label = 'Afternoon Job';
      break;
    case 'sunday_rate':
      rateModifier.label = 'Sunday Job';
      break;
    case 'foreman_rate':
      rateModifier.label = 'Forman';
      break;
    default:
      rateModifier.label = rateType;
  }

  return rateModifier;
};

const shouldAddCommissionToSalary = (userType) => {
  if (userType === 4 || userType === 'driver') return true;
  if (userType === 5 || userType === 'mover') return true;
  return false;
};

const shouldShowColumn = {
  types: {
    agentType: 1,
    managerType: 2,
    estimatorType: 3,
    driverType: 4,
    moverType: 5,
    customerServiceType: 6,
  },
  total(userType) {
    const types = this.types;
    const shouldShow = [types.estimatorType, types.driverType, types.moverType, types.customerServiceType].includes(Number(userType));

    return shouldShow;
  },
  packingTotal(userType) {
    const shouldShow = [this.types.driverType].includes(Number(userType));

    return shouldShow;
  },
};

export { getJobType, getPaymentType, getRateModifier, shouldAddCommissionToSalary, shouldShowColumn };

import React from 'react';
import PayrollUser from './PayrollUser';
import { NoResultsFound } from '../NoResultsFound';
import { shouldShowColumn } from './payrollUtils';

const PayrollJobList = ({ list, data, handleCreateReimbursement }) => {
  const users = Object.entries(list).filter((user) => user[1].list.length > 0);

  const isDriver = data.user_type === 4;

  return users.length === 0 ? (
    <NoResultsFound />
  ) : (
    <div className="my-3">
      <div className="reports-table">
        <div className="reports-table-row">
          <div className="reports-table-column reports-table-column--18 reports-table-column--title">Name</div>
          <div className="reports-table-column reports-table-column--18 reports-table-column--title">Jobs</div>
          <div className="reports-table-column reports-table-column--18 reports-table-column--title">LO</div>
          <div className="reports-table-column reports-table-column--18 reports-table-column--title">LD</div>
          <div className="reports-table-column reports-table-column--18 reports-table-column--title">Labor</div>
          <div className="reports-table-column reports-table-column--18 reports-table-column--title">Storage</div>
          <div className="reports-table-column reports-table-column--18 reports-table-column--title">Hours</div>
          <div className="reports-table-column reports-table-column--18 reports-table-column--title">Afternoon Hours</div>
          <div className="reports-table-column reports-table-column--18 reports-table-column--title">Week Extra Hours</div>
          {shouldShowColumn.total(data.user_type) && (
            <div className="reports-table-column reports-table-column--18 reports-table-column--title">Total</div>
          )}
          <div className="reports-table-column reports-table-column--18 reports-table-column--title">Completed Total</div>
          <div className="reports-table-column reports-table-column--18 reports-table-column--title">Tips</div>
          <div className="reports-table-column reports-table-column--18 reports-table-column--title">Total Less Tips</div>
          {shouldShowColumn.packingTotal(data.user_type) && (
            <div className="reports-table-column reports-table-column--18 reports-table-column--title">Packing Sold</div>
          )}
          <div className="reports-table-column reports-table-column--18 reports-table-column--title">Commission %</div>
          <div className="reports-table-column reports-table-column--18 reports-table-column--title">
            {isDriver ? 'Packing Commission' : 'Commission Total'}
          </div>
          <div className="reports-table-column reports-table-column--18 reports-table-column--title">Total Salary</div>
          <div className="reports-table-column reports-table-column--18 reports-table-column--title">Reimbursement</div>
        </div>
      </div>
      {users.map((user, index) => (
        <PayrollUser
          key={user[0]}
          index={index}
          data={user[1]}
          user_id={user[0]}
          type={+data.user_type}
          createReimbursement={handleCreateReimbursement}
        />
      ))}
    </div>
  );
};

export default PayrollJobList;

import React, { useMemo, useState, Fragment } from 'react';
import { useHistory, useParams, generatePath } from 'react-router-dom';
import { useFormik } from 'formik';
import JobBanner from 'components/JobBanner';
import MciEditor from 'components/MciEditor';
import Icon from 'components/Icon';
import { useGetNoteInfo, useSaveImportantNote, useDeleteAttachments, useDeleteImportantNote } from 'hooks/query/useImportantNotes';
import routes, { routePaths } from 'config/routes';

function ImportantDetail() {
  const history = useHistory();
  const { id, type } = useParams();

  const [isEditable, setIsEditable] = useState(false);

  const { data } = useGetNoteInfo({ id });
  const { mutate: saveAttachments } = useSaveImportantNote({ id });
  const { mutate: deleteAttachments } = useDeleteAttachments({ id });
  const { mutate: deleteNote } = useDeleteImportantNote();

  const initial = useMemo(() => {
    if (!data || type === 'new') {
      return {
        attachments: [],
        body: '',
        title: '',
      };
    }

    return {
      attachments: data.attachments ?? [],
      body: data.body,
      title: data.title,
    };
  }, [data, type]);

  const { values, handleChange, setFieldValue } = useFormik({
    initialValues: initial,
    enableReinitialize: true,
  });

  const edit = () => setIsEditable(!isEditable);

  const handleEditorChange = (content) => setFieldValue('body', content);

  const handleFileSelect = (event) => {
    if (values.attachments.length) {
      setFieldValue('attachments', [...values.attachments, ...event.target.files]);
      return;
    }
    setFieldValue('attachments', [...event.target.files]);
  };

  const save = () => {
    edit();
    const data = {
      id: id ?? 0,
      title: values.title,
      body: values.body,
      attachments: values.attachments,
    };

    const formData = new FormData();
    for (let i in data) {
      if (data.hasOwnProperty(i)) {
        formData.append(i, data[i]);
      }
    }

    for (let i in data.attachments) {
      if (data.attachments.hasOwnProperty(i)) {
        formData.append(i, data.attachments[i]);
      }
    }

    saveAttachments(formData, {
      onSuccess: ({ data }) => {
        if (!id) {
          history.push({
            pathname: generatePath(routePaths.NOTES, { id: data.id, type: 'edit' }),
          });
        }
      },
    });
  };

  const deleteAttachment = (item) => {
    const fileIsOnServer = Boolean(item.path);

    if (fileIsOnServer) {
      const files = values.attachments.filter((file) => file.path && file.path !== item.path);
      deleteAttachments({ id, path: item.path, attachments: files });
      return;
    }

    setFieldValue(
      'attachments',
      values.attachments.filter((file) => file.name !== item.name)
    );
  };

  const handleDelete = () => {
    deleteNote({ id, attachments: values.attachments }, { onSuccess: () => history.push({ pathname: routes.NOTES }) });
  };

  if (!id && !type) return <JobBanner />;

  return (
    <Fragment>
      <div className="new-table mb-4">
        <div className="new-table--row">
          <div className="new-table--row--item no-padding">
            <button type="button" className="main flex-grow-1 p20" onClick={isEditable ? save : edit}>
              {isEditable ? 'Save' : 'Edit'}
            </button>
          </div>
          <div className="new-table--row--item no-padding">
            <button type="button" className="main flex-grow-1 p20" onClick={handleDelete}>
              Delete
            </button>
          </div>
        </div>
      </div>

      <div className="new-table mb-4">
        <div className="new-table--row">
          <div className="new-table--row--item no-padding">
            <input
              className="flex-grow-1 p20"
              name="title"
              readOnly={!isEditable}
              value={values.title}
              placeholder="Note title"
              onChange={handleChange}
            />
          </div>
        </div>
      </div>

      <div className="new-table">
        <div className="new-table--row">
          <div className="new-table--row--item">
            <MciEditor disabled={!isEditable} handleEditorChange={handleEditorChange} value={values.body} />
          </div>
        </div>
      </div>

      {isEditable && (
        <div className="new-table mb-4">
          <div className="new-table--row">
            <div className="new-table--row--item no-padding">
              <label className="file p20">
                <span>Choose attachments</span>
                <input type="file" multiple="multiple" onChange={handleFileSelect} />
              </label>
            </div>
          </div>
        </div>
      )}

      <h3 className="header">Attachments:</h3>
      <div className="new-table mb-4">
        {values.attachments.length > 0 ? (
          values.attachments.map((item, index) => (
            <div className="new-table--row" key={index + item.name}>
              <div className="new-table--row--item  no-shadow flex-grow-0 w5">
                {item.path && (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`${process.env.REACT_APP_API_URL}/api/mail/downloadAttachments?path=${item.path}`}
                  >
                    <Icon icon="download" size={20} title="Download attachment" style={{ marginRight: 0 }} />
                  </a>
                )}
              </div>
              <div className="new-table--row--item flex-grow-1 no-shadow align-items-start">
                <b className="main">{item.name}</b>
              </div>

              <div className="new-table--row--item w5 no-shadow flex-grow-0">
                <button type="button" onClick={() => deleteAttachment(item)}>
                  <Icon icon="delete" size={20} style={{ marginRight: 0 }} />
                </button>
              </div>
            </div>
          ))
        ) : (
          <div className="new-table--row">
            <div className="new-table--row--item">
              <p>No attachments</p>
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
}

export default ImportantDetail;

import React, { Fragment } from 'react';
import { useForm } from '../../../hooks/useForm';

function EstTime() {
  const { values } = useForm();

  const { fromEstTime, toEstTime } = values;

  return (
    <Fragment>
      <div className="new-table--row">
        {fromEstTime && (
          <div className="new-table--row--item new-table--row--item__column align-items-start">
            <p>
              Estimated building time{' '}
              <b title="min/avg/max">
                {fromEstTime.building.min}/{fromEstTime.building.avg}/{fromEstTime.building.max}
              </b>
            </p>
            <p>
              Jobs <b> {fromEstTime.building.job_count}</b>
            </p>

            <p>
              Estimated move size time{' '}
              <b title="min/avg/max">
                {fromEstTime.move_size.min}/{fromEstTime.move_size.avg}/{fromEstTime.move_size.max}
              </b>
            </p>
            <p>
              Jobs <b> {fromEstTime.move_size.job_count}</b>
            </p>
          </div>
        )}

        {toEstTime && (
          <div className="new-table--row--item new-table--row--item__column align-items-start">
            <p>
              Estimated building time{' '}
              <b title="min/avg/max">
                {toEstTime.building.min}/{toEstTime.building.avg}/{toEstTime.building.max}
              </b>
            </p>
            <p>
              Jobs <b> {toEstTime.building.job_count}</b>
            </p>
            <p>
              Estimated move size time
              <b title="min/avg/max">
                {toEstTime.move_size.min}/{toEstTime.move_size.avg}/{toEstTime.move_size.max}
              </b>
            </p>
            <p>
              Jobs <b> {toEstTime.move_size.job_count}</b>
            </p>
          </div>
        )}
      </div>
    </Fragment>
  );
}

export default EstTime;

import React, { useRef, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';

const MciEditor = (props) => {
  const { handleEditorChange, value, disabled = false, hideMenu = false, hideToolbar = false } = props;

  const [dirty, setDirty] = useState(false);

  const content = useRef(value);

  const onMouseLeave = () => {
    if (dirty) {
      handleEditorChange(content.current);
      setDirty(false);
    }
  };

  const toolbarConfig = `
  undo redo | blocks | bold italic forecolor | 
  alignleft aligncenter alignright alignjustify | 
  bullist numlist outdent indent | removeformat | help
  `;

  return (
    <Editor
      apiKey="a29aesxpfle84ol5bo24n572j5q6ize16y0wrdm8ch0b6ke7"
      disabled={disabled}
      initialValue={value}
      init={{
        toolbar: hideToolbar ? false : toolbarConfig,
        menubar: hideMenu ? false : true,
        statusbar: false,
        convert_urls: false,

        // selector: 'textarea',
        // indentation: '40pt',
        // indent_use_margin: true
      }}
      onEditorChange={(newValue) => (content.current = newValue)}
      onMouseLeave={onMouseLeave}
      onDirty={() => setDirty(true)}
    />
  );
};

export default MciEditor;

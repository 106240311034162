export default {
  fees: 'fees',
  notes: 'notes',
  email: 'email',
  twilio: 'twilio',
  packing: 'packing',
  charges: 'charges',
  newEmail: 'newEmail',
  discount: 'discount',
  followUp: 'followUp',
  quickForm: 'quickForm',
  insurance: 'insurance',
  inventory: 'inventory',
  callScript: 'callScript',
  assignCrew: 'assignCrew',
  smsReminder: 'smsReminder',
  newRemainder: 'newRemainder',
  editReminder: 'editReminder',
  additionalInfo: 'additionalInfo',
  changeJobStatus: 'changeJobStatus',
  dayAvailability: 'dayAvailability',
  insuranceProvider: 'insuranceProvider',
  rescheduleJob: 'rescheduleJob',
  extraStop: 'extraStop',
  jobUpdateToStorage: 'jobUpdateToStorage',
  settings: 'settings',
  localJobInventoryToOngoingStorageJob: 'localJobInventoryToOngoingStorageJob',
  payrollReimbursement: 'payrollReimbursement',
  longDistanceFees: 'longDistanceFees',
  changeJobCarrier: 'changeJobCarrier',
  createCarrierDriver: 'createCarrierDriver',
};

import React, { useCallback, useMemo, Fragment } from 'react';
import { useModal } from 'context/modal';
import { useInfo, useInsuranceUpdate, useTabInfo } from 'hooks/query/useJob';
import { useParams } from 'react-router-dom';
import { useDefault } from 'hooks/query/useCommon';
import Icon from 'components/Icon';

const Insurance = ({ type }) => {
  const { id, tab } = useParams();
  const { modalNames, open } = useModal();

  const {
    data: { insurance },
  } = useDefault();

  const {
    data: { job_info: jobInfo },
  } = useInfo(id);

  const {
    data: { job_insurance: jobInsurance },
  } = useTabInfo(id, tab);

  const { mutate: updateInsurance } = useInsuranceUpdate(id, tab);

  const estPrepared = useMemo(() => {
    return insurance[jobInfo.company_id].reduce((acc, e) => {
      if (jobInsurance['estimated'].find((k) => k.insurance_id === e.id)) return acc.concat(e);
      return acc;
    }, []);
  }, [insurance, jobInfo.company_id, jobInsurance]);

  const finPrepared = useMemo(() => {
    return insurance[jobInfo.company_id].reduce((acc, e) => {
      if (jobInsurance['final'].find((k) => k.insurance_id === e.id)) return acc.concat(e);
      return acc;
    }, []);
  }, [insurance, jobInfo.company_id, jobInsurance]);

  const handleDeleteItem = useCallback(
    (insuranceId, type) => {
      const isFinal = type === 'final';
      const data = isFinal ? finPrepared : estPrepared;
      const preparedData = data.filter((item) => item.id !== insuranceId);
      updateInsurance({
        job_id: id,
        job_insurance: {
          estimated: isFinal ? jobInsurance['estimated'] : preparedData,
          final: preparedData,
        },
      });
    },
    [jobInsurance, updateInsurance, id, finPrepared, estPrepared]
  );

  return (
    <Fragment>
      <div className="new-table--row bcg-light">
        <div className="new-table--row--item justify-content-center w40">
          <b>Insurance</b>
        </div>
        <div className="new-table--row--item w30 justify-content-center no-padding">
          <button className="btn p20 flex-grow-1 d-flex text-center" onClick={() => open(modalNames.insurance, { type: 'estimated' })}>
            <Icon icon="add" size={16} />
          </button>
        </div>
        <div className="new-table--row--item w30 justify-content-center no-padding">
          <button className="btn p20 flex-grow-1 d-flex text-center" onClick={() => open(modalNames.insurance, { type: 'final' })}>
            <Icon icon="add" size={16} />
          </button>
        </div>
      </div>

      {(estPrepared.length !== 0 || finPrepared.length !== 0) && (
        <Fragment>
          <div className="new-table--row">
            <div className="new-table--row--item justify-content-center w40" />
            <div className="new-table--row--item no-side-shadows w60 no-padding align-items-start">
              {estPrepared.length ? (
                <div className="new-table--row--item w50 no-padding flex-direction-column">
                  {estPrepared.map((item) => (
                    <div className="new-table--row with-shadow" key={item.id}>
                      <div className="new-table--row--item w70 justify-content-center no-side-shadows">
                        <button onClick={() => handleDeleteItem(item.id, 'estimated')}>
                          <Icon size={20} icon="delete" title={`Delete estimated insurance`} />
                        </button>
                        {item.insurance_name}
                      </div>
                      <div className="new-table--row--item w30 justify-content-center no-side-shadows">
                        <b>${item.amount}</b>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="new-table--row--item no-side-shadows w50 no-padding flex-direction-column" />
              )}

              {finPrepared.length ? (
                <div className="new-table--row--item w50 no-padding flex-direction-column">
                  {finPrepared.map((item) => (
                    <div className="new-table--row with-shadow" key={item.id}>
                      <div className="new-table--row--item w70 justify-content-center no-side-shadows">
                        <button onClick={() => handleDeleteItem(item.id, 'final')}>
                          <Icon size={20} icon="delete" title={`Delete final insurance`} />
                        </button>
                        {item.insurance_name}
                      </div>
                      <div className="new-table--row--item w30 justify-content-center no-side-shadows">
                        <b>${item.amount}</b>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="new-table--row--item no-side-shadows w50 no-padding flex-direction-column" />
              )}
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default Insurance;

import React, { memo, useState, useCallback, useMemo } from 'react';
import { useParams, useHistory, useLocation, generatePath } from 'react-router-dom';
import classNames from 'classnames';
import { routePaths } from 'config/routes';
import Icon from 'components/Icon';
import getAbbr from 'helpers/Abbr';
import getTitle from 'helpers/GetTitle';
import { usePriority, useGetPopupInfo } from 'hooks/query/useJob';
import { useDefault, useReadAlert } from 'hooks/query/useCommon';
import { useDebounceCallback } from 'hooks/useDebounceCallback';
import { formatMonthDay, formatLT } from 'helpers/dateHelper';
import { statusNumberToName } from 'helpers/statuses';
import { DropdownMenu, ButtonIconItem } from 'components/newComponents/DropDown';
import JobTooltip from './JobTooltip';

const Job = memo(({ job }) => {
  const { id: jobId, type, tab } = useParams();
  const history = useHistory();
  const { search } = useLocation();

  const { mutate: readAlert } = useReadAlert();

  const [tooltipVisible, setTooltipVisible] = useState(false);

  const params = useMemo(() => {
    const searchParams = new URLSearchParams(search);

    const result = {};
    for (let pair of searchParams.entries()) {
      result[pair[0]] = pair[1];
    }

    return result;
  }, [search]);

  const { mutate } = usePriority({ ...params, type });

  const { data: popupInfo, refetch: fetchPopupInfo, isFetching } = useGetPopupInfo(job.id);

  const { data: defaultData } = useDefault();

  const companiesABBR = defaultData?.companies_abbr;

  const isLead = useMemo(() => job.status === 21, [job]);

  const jobPriority = useMemo(() => {
    if (!defaultData) return [];
    return defaultData.statuses.job_priority;
  }, [defaultData]);

  const changePriority = useCallback(
    (priority) => {
      mutate({ ...job, job_id: job.id, priority: jobPriority.findIndex((e) => e === priority) });
    },
    [job, jobPriority, mutate]
  );

  const onClickJobHandler = useCallback(
    (id) => {
      history.replace({
        pathname: generatePath(routePaths.JOBS, { id, tab: tab || 'general', type: type || 'all' }),
        search,
      });
      if (type.includes('alert')) readAlert(id);
    },
    [history, search, tab, type, readAlert]
  );

  const isEmailRead = type === 'new_message' && job.read;

  const fullName = job.agent_first_name && job.agent_last_name ? `${job.agent_first_name.charAt(0)}${job.agent_last_name.charAt(0)}` : '';

  const handleFetchPopupInfo = () => {
    if (popupInfo || isFetching) return;

    fetchPopupInfo();
  };

  const { debounceStart: fetchPopupInfoStart, debounceBreak: fetchPopupInfoBreak } = useDebounceCallback(handleFetchPopupInfo, 500);

  return (
    <div className="day">
      <div
        className="main-job"
        onClick={() => onClickJobHandler(job.id)}
        onMouseEnter={() => {
          fetchPopupInfoStart();
          setTooltipVisible(true);
        }}
        onMouseLeave={() => {
          fetchPopupInfoBreak();
          setTooltipVisible(false);
        }}
      >
        <JobTooltip job={job} additionalInfo={popupInfo} visible={tooltipVisible} />
        <div className={classNames({ email_read: isEmailRead })}>
          <div className={classNames('job-line', { 'active-row': +jobId === +job.id })}>
            <Icon className="w5 tooltip-left" icon={statusNumberToName(job.status)} title={getTitle(job.status, job.partner_name)} size={20} />
            <div className="job-details" data-tip="" data-for={`${job.title}${job.id}`}>
              <div className="w5">
                <span> {getAbbr(job.company_id, companiesABBR)} </span>
              </div>

              {!isLead && <div className="w5">{fullName}</div>}
              <div
                className={classNames({
                  w15: !isLead,
                  w20: isLead,
                })}
              >
                <span>
                  {job.title}
                  {isLead && `- ${formatMonthDay(job.start_date)}`}
                </span>
              </div>
              <div className="w20">
                <span>
                  {formatLT(job.start_date)} - {formatLT(job.stop_date)}
                </span>
              </div>
              <div className={classNames(['text-capitalize', 'w25', 'w25p'], { 'default-jobs-list': isLead })}>
                <span className="job-details__item">{job.customer_name}</span>
              </div>
              <div className="w10 w10p">{job.move_size && <span className="nowrap job-details__item">{job.move_size}</span>}</div>
              <div className="w10">
                <span>{job.from_zip}</span>
              </div>
              <div className="w10">
                <span>{job.to_zip}</span>
              </div>
            </div>
            <DropdownMenu
              wrapperClass="w5 dropdown-status"
              menuWrapperClass="mw-auto job_priority_filter"
              trigger={<Icon icon={jobPriority[job.priority]?.toLowerCase()} title={jobPriority[job.priority]} size={22} className="tooltip-right" />}
              horizontal
            >
              {jobPriority.map((e) => (
                <ButtonIconItem key={e} value={e} onClick={changePriority} size={20} className="tooltip-right" />
              ))}
            </DropdownMenu>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Job;

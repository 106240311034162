import { useMutation, useQuery } from 'react-query';
import { useApi } from 'context/api';
import queryKeys from 'config/queryKeys';

const time = 60 * 60 * 60;

export const useResultCall = (id, tab) => {
  const { twilio } = useApi();

  return useMutation(twilio.saveCallResult);
};

export const useCapabilityToken = (options) => {
  const { twilio } = useApi();

  return useQuery(queryKeys.capabilityToken, twilio.capabilityToken, {
    select: (res) => res.data,
    cacheTime: time,
    staleTime: time,
    ...options,
  });
};

export const useIncomingJobInfo = (options) => {
  const { twilio } = useApi();

  return useMutation(twilio.getIncomingJobInfo);
};

export const useCallbackStatus = () => {
  const { twilio } = useApi();

  return useMutation(twilio.callbackStatus);
};

export const useGeneralCompanyName = () => {
  const { twilio } = useApi();

  return useMutation(twilio.getGeneralCompanyName);
};

export const useRedirectGeneralToConference = () => {
  const { twilio } = useApi();

  return useMutation(twilio.redirectGeneralToConference);
};

export const useRedirectToConference = () => {
  const { twilio } = useApi();

  return useMutation(twilio.redirectToConference);
};

export const usePutOnHold = () => {
  const { twilio } = useApi();

  return useMutation(twilio.putOnHold);
};

export const useRedirectBusyToVoiceMail = () => {
  const { twilio } = useApi();

  return useMutation(twilio.redirectBusyToVoiceMail);
};

export const useRedirectTo = () => {
  const { twilio } = useApi();

  return useMutation(twilio.redirectTo);
};

import React, { useCallback, Fragment } from 'react';
import { useModal } from 'context/modal';
import { useTabInfo, useFeesUpdate } from 'hooks/query/useJob';
import { useParams } from 'react-router-dom';
import { useDefault } from 'hooks/query/useCommon';
import Icon from 'components/Icon';

const Fees = () => {
  const { id, tab } = useParams();
  const { modalNames, open } = useModal();

  const { mutate: updateFees } = useFeesUpdate(id, tab);

  const {
    data: { fees },
  } = useDefault();

  const {
    data: { job_fees: jobFees },
  } = useTabInfo(id, tab);

  const deleteFees = useCallback(
    (currentId) => {
      updateFees({ job_id: id, job_fees: jobFees.filter((el) => el.id !== currentId) });
    },
    [jobFees, updateFees, id]
  );

  return (
    <Fragment>
      <div className="new-table--row bcg-light">
        <div className="new-table--row--item justify-content-center w40">
          <b>Fees</b>
        </div>
        <div className="new-table--row--item w60 justify-content-center no-padding">
          <button className="btn p20 flex-grow-1 d-flex text-center no-margin" onClick={() => open(modalNames.fees)}>
            <Icon icon="add" size={16} />
          </button>
        </div>
      </div>

      {jobFees.length !== 0 && (
        <Fragment>
          <div className="new-table--row">
            <div className="new-table--row--item justify-content-center w40" />
            <div className="new-table--row--item no-side-shadows w60 no-padding align-items-start">
              {jobFees.length ? (
                <div className="new-table--row--item w100 no-padding flex-direction-column">
                  {jobFees.map((item, index) => {
                    const name = fees.find((el) => el.id === item.fee_id);

                    return (
                      <div className="new-table--row with-shadow" key={index}>
                        <div className="new-table--row--item w70 justify-content-center no-side-shadows">
                          <button className="show-modal" onClick={() => deleteFees(item.id)}>
                            <Icon size={20} icon="delete" title={`Delete estimated discount`} />
                          </button>
                          {name?.name}
                        </div>
                        <div className="new-table--row--item w30 justify-content-center no-side-shadows">
                          <b>${item.amount}</b>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className="new-table--row--item no-side-shadows w100 no-padding flex-direction-column" />
              )}
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default Fees;

import FormikCheckbox from './FormikCheckbox';
import FormikSelect from './FormikSelect';
import FormikDate from './FormikDate';
import FormikTime from './FormikTime';
import FormikRadioButton from './FormikRadioButton';
import FormikInput from './FormikInput';
import FormikAutocomplete from './FormikAutocomplete';
import FormikTextarea from './FormikTextarea';
import FormikFileInput from './FormikFileInput';

export default {
  FormikCheckbox,
  FormikSelect,
  FormikDate,
  FormikTime,
  FormikRadioButton,
  FormikInput,
  FormikAutocomplete,
  FormikTextarea,
  FormikFileInput,
};

import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { updateRouteParams } from 'utils/history';
import { routePaths } from 'config/routes';
import { NoResultsFound } from '../NoResultsFound';

const ClaimsList = ({ list }) => {
  const claims = [];

  Object.entries(list).forEach((claim, key) => {
    const claimType = claim[1].type === 2 ? 'To' : 'From';
    const claimName = claim[1].author_first_name ? `${claim[1].author_first_name} ${claim[1].author_last_name}` : 'Customer';
    const claimDate = moment.unix(claim[1].created_at).format('MM/DD/YY');
    const jobLink = updateRouteParams(routePaths.JOBS, { type: 'all', tab: 'general', id: claim[1].id });

    claims.push(
      <div className="reports-table-row" key={key}>
        <div className="reports-table-column reports-table-column--10">{key + 1}</div>
        <div className="reports-table-column reports-table-column--10">
          <Link to={jobLink} target="_blank" rel="noopener noreferrer">
            {claim[1].title}
          </Link>
        </div>
        <div className="reports-table-column reports-table-column--10">{claimType}</div>
        <div className="reports-table-column reports-table-column--10">{claim[1].claim}</div>
        <div className="reports-table-column reports-table-column--10">{claimName}</div>
        <div className="reports-table-column reports-table-column--10">{claimDate}</div>
      </div>
    );
  });

  return list.length === 0 ? (
    <NoResultsFound />
  ) : (
    <div className="report-dashboard">
      <div className="reports-table">
        <div className="reports-table-title">Driver claims</div>
        <div className="reports-table-row">
          <div className="reports-table-column reports-table-column--10 reports-table-column--title">#</div>
          <div className="reports-table-column reports-table-column--10 reports-table-column--title">Job Id</div>
          <div className="reports-table-column reports-table-column--10 reports-table-column--title">Type</div>
          <div className="reports-table-column reports-table-column--10 reports-table-column--title">Claim</div>
          <div className="reports-table-column reports-table-column--10 reports-table-column--title">Author</div>
          <div className="reports-table-column reports-table-column--10 reports-table-column--title">Date/Time</div>
        </div>
        {claims}
      </div>
    </div>
  );
};

export default ClaimsList;

import React, { memo } from 'react';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';

import './Autocomplete.scss';

const FormikAutocomplete = memo((props) => {
  const handleError = (error) => props.form.setFieldError(props.field.name, error);

  const handleChange = (address) => {
    props.form.setFieldTouched(`${props.field.name}.value`);
    props.form.setFieldValue(props.field.name, address);
    // setState({ address });
  };

  const handleSelect = async (address) => {
    try {
      const res = await geocodeByAddress(address);

      const place = res[0];

      // let building = place.address_components.find((item) => item.types.includes('route'))
      //   ? place.address_components.find((item) => item.types.includes('route')).long_name
      //   : '';
      let city = place.address_components.find((item) => item.types.includes('locality'))
        ? place.address_components.find((item) => item.types.includes('locality')).short_name
        : '';
      let zipcode = place.address_components.find((item) => item.types.includes('postal_code'))
        ? place.address_components.find((item) => item.types.includes('postal_code')).short_name
        : '';
      let state = place.address_components.find((item) => item.types.includes('administrative_area_level_1'))
        ? place.address_components.find((item) => item.types.includes('administrative_area_level_1')).short_name
        : '';

      let street_number = place.address_components.find((item) => item.types.includes('street_number'))
        ? place.address_components.find((item) => item.types.includes('street_number')).short_name
        : '';
      let address_short = place.address_components.find((item) => item.types.includes('route'))
        ? place.address_components.find((item) => item.types.includes('route')).short_name
        : '';

      let saveAddress = address.split(',');

      props.form.setFieldValue(props.field.name, saveAddress[0]);
      props.zip && props.form.setFieldValue(props.zip, zipcode);
      props.city && props.form.setFieldValue(props.city, city);
      // props.building && props.form.setFieldValue(props.building, building);
      props.state && props.form.setFieldValue(props.state, state);
      props.full_address && props.form.setFieldValue(props.full_address, place.formatted_address);

      props.onSelectDistance && props.onSelectDistance();
      props.getBuildingAddress && props.getBuildingAddress(street_number, address_short, zipcode, props.type);
    } catch (error) {
      props.form.setFieldError(props.field.name, error);
    }
  };

  const {
    field: { name, value }, // { name, value, onChange, onBlur }
    form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    // classes,
    // label,
    // ...props
  } = props;

  const error = errors[name];
  const touch = touched[name];
  return (
    <div className="autocomplete-wrapper" key={name}>
      <PlacesAutocomplete
        key={name}
        name={name}
        id={name}
        {...props}
        value={value || ''}
        onChange={handleChange}
        onSelect={handleSelect}
        onError={handleError}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <input
              autoComplete="off"
              type="text"
              {...getInputProps({
                placeholder: props.placeholder || 'Search Places ...',
                className: 'location-search-input',
              })}
              disabled={props.disabled}
            />
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion) => (
                <div
                  {...getSuggestionItemProps(suggestion)}
                  className={suggestion.active ? ' suggestion-item suggestion-item--active' : 'suggestion-item'}
                  key={suggestion.placeId}
                >
                  <span>{suggestion.description}</span>
                </div>
              ))}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
      {error && touch && <span className="error">{error}</span>}
    </div>
  );
});

export default FormikAutocomplete;

import { useRef } from 'react';
import { debounce } from 'throttle-debounce';

const useDebounceCallback = (callback, ms = 0) => {
  const handler = useRef();

  const debounceStart = () => {
    handler.current = debounce(ms, callback);
    handler.current();
  };
  const debounceBreak = () => {
    if (handler && handler.current) {
      handler.current.cancel();
    }
  };

  return { debounceStart, debounceBreak };
};

export { useDebounceCallback };

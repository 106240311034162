import React from 'react';

const Radio = ({ name, value, title, current, onChange, disabled = false, radioStyles = {}, titleStyles = {} }) => {
  return (
    <label className="d-flex justify-content-start">
      <input type="radio" name={name} value={value} disabled={disabled} style={radioStyles} checked={current === value} onChange={onChange} />
      <span style={titleStyles}>{title}</span>
    </label>
  );
};

export default Radio;

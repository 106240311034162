import React, { PureComponent } from 'react';
import { DirectionsRenderer, DirectionsService, GoogleMap } from '@react-google-maps/api';

class Directions extends PureComponent {
  state = {
    isLoaded: false,
    response: null,
  };

  directionsCallback = (response) => {
    if (!this.state.isLoaded) {
      if (response !== null) {
        if (response.status === 'OK') {
          this.setState({
            response: response,
            isLoaded: true,
          });
        } else {
        }
      }
    }
  };

  render() {
    return (
      <div className="map">
        <GoogleMap
          // required
          id="direction-example"
          // required
          mapContainerStyle={{ height: '225px', width: '100%' }}
          // required
          zoom={2}
          // required
          center={{ lat: 0, lng: -180 }}
        >
          {this.props.to && this.props.from && (
            <DirectionsService
              options={{
                destination: this.props.to,
                origin: this.props.from,
                travelMode: 'DRIVING',
                waypoints: this.props.waypoints,
              }}
              callback={this.directionsCallback}
            />
          )}
          {this.state.response !== null && <DirectionsRenderer options={{ directions: this.state.response }} />}
        </GoogleMap>
      </div>
    );
  }
}

export default Directions;

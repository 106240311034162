import axios from 'axios';
import showError from 'utils/errorHandler';
import showSuccess from 'utils/successHandler';

class Api {
  constructor() {
    this.serverApi = process.env.REACT_APP_API_URL;
    this.client = axios.create({ baseURL: this.serverApi });
  }

  getData = async (jobId, type) => {
    try {
      return await this.client.get(`/api/customer/elevatorReservation/${jobId}/${type}`);
    } catch (error) {
      showError(error);
    }
  };

  approve = async (jobId, type) => {
    try {
      await this.client.post(`/api/customer/elevatorReservation/approve`, {
        job_id: jobId,
        type: type,
      });
      showSuccess({ message: 'Successfully approved' });
    } catch (error) {
      showError(error);
    }
  };

  reject = async (jobId, type) => {
    try {
      await this.client.post(`/api/customer/elevatorReservation/reject`, {
        job_id: jobId,
        type: type,
      });

      showSuccess({ message: 'Successfully rejected' });
    } catch (error) {
      showError(error);
    }
  };
}

export default new Api();

import React, { useState, useCallback, useMemo } from 'react';
import ReportJob from './ReportJob';
import Pagination from 'react-paginate';

const ReportJobList = ({ jobs }) => {
  const perPage = 20;
  const [page, setPage] = useState(0);

  const [active, setActive] = useState(null);

  const handlePaginationChange = ({ selected }) => setPage(selected);

  const handleClick = useCallback(
    (id) => {
      if (id === active) {
        setActive(null);
        return;
      }

      setActive(id);
    },
    [active]
  );

  const list = useMemo(() => {
    if (!jobs) return [];

    return jobs.map((job) => <ReportJob key={job.job_id} data={job} isActive={active === job.job_id} onClick={() => handleClick(job.job_id)} />);
  }, [jobs, active, handleClick]);

  return (
    <div className="my-3">
      <div className="reports-table">
        <div className="reports-table-row">
          <div className="reports-table-column reports-table-column--12 reports-table-column--title">Job ID</div>
          <div className="reports-table-column reports-table-column--12 reports-table-column--title">Company</div>
          <div className="reports-table-column reports-table-column--12 reports-table-column--title">Date</div>
          <div className="reports-table-column reports-table-column--12 reports-table-column--title">Hours</div>
          <div className="reports-table-column reports-table-column--12 reports-table-column--title">Distance</div>
          <div className="reports-table-column reports-table-column--12 reports-table-column--title">Hourly Rate</div>
          <div className="reports-table-column reports-table-column--12 reports-table-column--title">Total</div>
          <div className="reports-table-column reports-table-column--12 reports-table-column--title">Expenses</div>
          <div className="reports-table-column reports-table-column--12 reports-table-column--title">Workers Salary</div>
          <div className="reports-table-column reports-table-column--12 reports-table-column--title">Sales Commission</div>
          <div className="reports-table-column reports-table-column--12 reports-table-column--title">Profit</div>
          <div className="reports-table-column reports-table-column--12 reports-table-column--title">Payment Type</div>
        </div>
      </div>

      {list.slice(page * perPage, (page + 1) * perPage)}

      <Pagination
        pageCount={Math.ceil(jobs.length / perPage)}
        pageRangeDisplayed={5}
        marginPagesDisplayed={2}
        containerClassName="email-pagination pagination"
        subContainerClassName="pages pagination"
        activeClassName="active"
        onPageChange={handlePaginationChange}
      />
    </div>
  );
};

export default ReportJobList;

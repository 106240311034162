import { useCallback } from 'react';
import { useFormikContext } from 'formik';
import { formatFullDBDate } from 'helpers/dateHelper';
import { findBuilding } from 'helpers/buildings';
import { getStorageRateMultiplierByRateType, getStorageRateTypeByPeriod } from 'helpers/storage';
import { useFetcher } from '../hooks/useFetcher';
import { isFieldValid, getMatchedUnitSizes } from '../utils';

export const useForm = () => {
  const fetcher = useFetcher();

  const { values, errors, touched, setValues, setFieldValue, handleChange } = useFormikContext();

  const isValidHandler = (name) => isFieldValid(errors, touched, name);

  const handleChangeJobType = useCallback(
    (value) => {
      setValues({
        ...values,
        job_type: value,
        move_type: value === 'lead' ? 'local' : value,
        storage_payment_method: value === 'lead' ? null : 'invoice',

        // Reset to default values
        storage_id: null,
        delivery_date: '',
        storage_rate: '',
        calc_type: 'per_cf',
        delivery_time: '',
        rate_type: 1,
        credit_rate: 0,
        default_rate: 0,
        unit_sizes: null,
        storage_rate_type: '',
        storage_fees: {},
        job_inventory: [],
        total_price: '',
        job_inventory_total: null,
      });
    },
    [values, setValues]
  );

  const handleChangeCompany = ({ value }) => {
    setValues({ ...values, company_id: value, storage_id: null });
  };

  const handleChangeStorageFee = useCallback(
    (fee) => {
      let result = {};
      const [key, value] = Object.entries(fee)[0];

      if (values.storage_fees[key]) {
        result = { ...values.storage_fees };
        delete result[key];
      } else {
        result = { ...values.storage_fees, [key]: value };
      }

      setFieldValue('storage_fees', result);
    },
    [setFieldValue, values.storage_fees]
  );

  const handleChangeLaborType = ({ value }) => {
    if (value !== 'default') {
      setValues({
        ...values,
        labor_type: value,
        to_building_name: values?.['from_building_name'] || '',
        to_required_coi: values?.['from_required_coi'] || '',
        to_building_id: values?.['from_building_id'] || 0,
        to_place_id: values?.['from_place_id'] || '',
        to_zipcode: values?.['from_zipcode'] || '',
        to_address: values?.['from_address'] || '',
        to_state: values?.['from_state'] || '',
        to_city: values?.['from_city'] || '',
      });

      return;
    }

    setFieldValue('labor_type', value);
  };

  const handleSaveInventory = useCallback(
    (value, unit_sizes, tariff) => {
      const jobInventoryTotal = value.reduce((accumulator, currentValue) => accumulator + currentValue.qty * currentValue.volume, 0);

      setFieldValue('job_inventory', value);
      setFieldValue('job_inventory_total', jobInventoryTotal);

      if (values.job_type === 'long_distance') {
        const cfPrice = tariff?.cf_price || 0;
        setFieldValue('cf_price', cfPrice);

        const totalPrice = Number(cfPrice) * Number(jobInventoryTotal);
        setFieldValue('total_price', totalPrice.toFixed(2));
      }

      if (values.job_type === 'storage') {
        if (values.calc_type === 'per_cf') {
          const totalPrice = Number(values.storage_rate) * Number(jobInventoryTotal);
          setFieldValue('total_price', totalPrice.toFixed(2));
        }
      }

      const unitSizeIds = getMatchedUnitSizes(unit_sizes, jobInventoryTotal);

      setFieldValue('unit_sizes', unitSizeIds);
    },
    [values.job_type, values.storage_rate, values.calc_type, setFieldValue]
  );

  const handleChangeInventoryTotal = (event, unitSizes) => {
    handleChange(event);
    const value = Number(event.target.value);

    if (values.job_type === 'long_distance') {
      const cf_price = Number(values.cf_price) ?? 0;

      setFieldValue('total_price', (value * cf_price).toFixed(2));
    }

    if (values.job_type === 'storage') {
      const storage_rate = Number(values.storage_rate) ?? 0;

      setFieldValue('total_price', (value * storage_rate).toFixed(2));
    }

    const unitSizeIds = getMatchedUnitSizes(unitSizes, value);

    setFieldValue('unit_sizes', unitSizeIds);
  };

  const handleChangeCFPrice = (event) => {
    handleChange(event);
    const value = Number(event.target.value);

    if (values.job_inventory_total) {
      setFieldValue('total_price', (value * values.job_inventory_total).toFixed(2));
    }
  };

  const handleChangeStorageRate = (event) => {
    handleChange(event);
    const value = Number(event.target.value);

    if (values.job_inventory_total) {
      setFieldValue('total_price', (value * values.job_inventory_total).toFixed(2));
    }
  };

  const handleChangeRateType = (value, deliveryDate, companies, unit_sizes) => {
    setFieldValue('storage_rate_type', value);

    if (values.calc_type === 'per_cf') {
      const ratePerCf = companies.find((company) => company.id === values.company_id)?.rate_per_cf;

      setFieldValue('storage_rate', Number(ratePerCf).toFixed(2));

      if (values.job_inventory_total) {
        setFieldValue('total_price', (Number(ratePerCf) * Number(values.job_inventory_total)).toFixed(2));
      }
    }

    if (values.calc_type === 'per_unit' && values.unit_sizes?.length && values.unit_sizes[0]) {
      const firstUnitSizeId = values.unit_sizes[0];

      const firstUnitSize = unit_sizes.find((size) => size.id === firstUnitSizeId);

      if (firstUnitSize) {
        const storageRate = firstUnitSize[value];

        const multiplier = getStorageRateMultiplierByRateType(value, formatFullDBDate(deliveryDate), values.pickup_date);

        setFieldValue('storage_rate', Number(storageRate).toFixed(2));
        setFieldValue('total_price', (Number(storageRate) * multiplier).toFixed(2));
      }
    }
  };

  const handleZipCode = useCallback(
    (value, name) => {
      setFieldValue(`${name}_city`, value?.city);
      setFieldValue(`${name}_state`, value?.state);
      setFieldValue(`${name}_place_id`, value?.place_id);
      if (!values.labor_type === 'default') {
        setFieldValue('to_city', value?.city);
        setFieldValue('to_state', value?.state);
        setFieldValue(`to_place_id`, value?.place_id);
      }
    },
    [setFieldValue, values.labor_type]
  );

  const handleChangeDeliveryDate = ({ name, date }, companies, unit_sizes) => {
    setFieldValue(name, formatFullDBDate(date));
    const rateType = getStorageRateTypeByPeriod(formatFullDBDate(date), values.pickup_date);

    if (values.unit_sizes) {
      handleChangeRateType(rateType, formatFullDBDate(date), companies, unit_sizes);
    } else {
      setFieldValue('storage_rate_type', rateType);
    }
  };

  const handleChangeCalcType = ({ value }, companies, unit_sizes) => {
    setFieldValue('calc_type', value);

    if (values.move_type !== 'storage') {
      return;
    }

    if (value === 'per_cf') {
      const ratePerCf = companies.find((company) => company.id === values.company_id)?.rate_per_cf;
      setFieldValue('storage_rate', Number(ratePerCf).toFixed(2));

      if (values.job_inventory_total) {
        setFieldValue('total_price', (ratePerCf * values.job_inventory_total).toFixed(2));
      } else {
        setFieldValue('total_price', (0).toFixed(2));
      }
    }

    if (value === 'per_unit' && values.unit_sizes?.length && values.unit_sizes[0]) {
      const firstUnitSizeId = values.unit_sizes[0];

      const firstUnitSize = unit_sizes.find((size) => size.id === firstUnitSizeId);

      if (firstUnitSize) {
        const storageRate = firstUnitSize[values.storage_rate_type];

        const multiplier = getStorageRateMultiplierByRateType(values.storage_rate_type, values.delivery_date, values.pickup_date);

        setFieldValue('storage_rate', Number(storageRate).toFixed(2));
        setFieldValue('total_price', (Number(storageRate) * multiplier).toFixed(2));
      }
    }
  };

  const handleSelectUnitSize = ({ value }, index, companies, unit_sizes) => {
    setFieldValue(`unit_sizes[${index}]`, value);

    if (values.calc_type === 'per_cf') {
      const ratePerCf = companies.find((company) => company.id === values.company_id)?.rate_per_cf;

      setFieldValue('storage_rate', Number(ratePerCf).toFixed(2));

      if (values.job_inventory_total) {
        setFieldValue('total_price', (Number(ratePerCf) * Number(values.job_inventory_total)).toFixed(2));
      }
    }

    if (values.calc_type === 'per_unit' && values.storage_rate_type) {
      const selectedUnitSize = unit_sizes.find((size) => size.id === value);

      if (selectedUnitSize) {
        const storageRate = selectedUnitSize[values.storage_rate_type];

        const multiplier = getStorageRateMultiplierByRateType(values.storage_rate_type, values.delivery_date, values.pickup_date);

        setFieldValue('storage_rate', Number(storageRate).toFixed(2));
        setFieldValue('total_price', (Number(storageRate) * multiplier).toFixed(2));
      }
    }
  };

  const handleChangeCreditRate = useCallback(
    async (event, currentMoveSize) => {
      const creditRate = event.target.checked ? 1 : 0;

      setFieldValue('credit_rate', creditRate);

      const movingRate = await fetcher.getMovingRate({
        ...values,
        defaultNumberMan: currentMoveSize?.defaultNumberMan,
        credit_rate: creditRate,
      });

      if (movingRate) {
        setFieldValue('default_rate', movingRate);
      }
    },
    [fetcher, setFieldValue, values]
  );

  const handleChangePackingService = useCallback(
    async (value, currentMoveSize) => {
      setFieldValue('packing_service', value);

      const movingRate = await fetcher.getMovingRate({
        ...values,
        defaultNumberMan: currentMoveSize?.defaultNumberMan,
        packing_service: value,
      });

      if (movingRate) {
        setFieldValue('default_rate', movingRate);
      }
    },
    [setFieldValue, values, fetcher]
  );

  const handleSelectMoveSize = useCallback(
    async ({ value, defaultNumberMan, defaultTruck, storage_unit_size_id }) => {
      setValues({ ...values, move_size_id: value, qty_trucks: defaultTruck });

      const { from_building_id, to_building_id } = values;

      const movingRate = await fetcher.getMovingRate({ ...values, defaultNumberMan, move_size_id: value });

      if (movingRate) {
        setFieldValue('default_rate', movingRate);
      }

      const fromTime = await fetcher.getBuildingTime(1, from_building_id, values.company_id, value);
      if (fromTime) {
        setFieldValue('fromEstTime', fromTime);
      }

      const toTime = await fetcher.getBuildingTime(2, to_building_id, values.company_id, value);
      if (toTime) {
        setFieldValue('toEstTime', toTime);
      }

      if (values.job_type === 'storage') {
        setFieldValue(`unit_sizes`, [storage_unit_size_id]);
      }
    },
    [fetcher, setFieldValue, setValues, values]
  );

  const setBuildingFieldValue = useCallback(
    async (building, type, place_id) => {
      if (!building) return null;

      setFieldValue(`${type}_address`, building?.building_address || '');
      setFieldValue(`${type}_zipcode`, building?.building_zip || '');
      setFieldValue(`${type}_city`, building?.building_city || '');
      setFieldValue(`${type}_state`, building?.building_state || '');
      setFieldValue(`${type}_building_name`, building?.building_name || '');
      setFieldValue(`${type}_required_coi`, building?.required_coi || 0);
      setFieldValue(`${type}_building_id`, building?.id || 0);
      setFieldValue(`${type}_place_id`, place_id || '');
      setFieldValue(`building_type_${type}`, building?.building_type);

      if (type === 'to') {
        const time = await fetcher.getBuildingTime(2, building?.id, values.company_id, values.move_size_id);

        if (time) {
          setFieldValue('toEstTime', time);
        }
      }

      if (type === 'from') {
        const time = await fetcher.getBuildingTime(1, building?.id, values.company_id, values.move_size_id);
        if (time) {
          setFieldValue('fromEstTime', time);
        }
      }

      if (building?.building_type === 1) setFieldValue(`elevator_exist_${type}`, 1);

      if (!values.labor_type === 'default') {
        setFieldValue(`to_address`, building?.building_address || '');
        setFieldValue(`to_zipcode`, building?.building_zip || '');
        setFieldValue(`to_city`, building?.building_city || '');
        setFieldValue(`to_state`, building?.building_state || '');
        setFieldValue(`to_building_name`, building?.building_name || '');
        setFieldValue(`to_required_coi`, building?.required_coi || 0);
        setFieldValue(`to_building_id`, building?.id || 0);
        setFieldValue(`to_place_id`, place_id || '');
        setFieldValue(`building_type_to`, building?.building_type);
        if (building?.building_type === 1) setFieldValue(`elevator_exist_to`, 1);
      }
    },
    [fetcher, setFieldValue, values.company_id, values.labor_type, values.move_size_id]
  );

  const handleSelectAutocompleteByName = (value, name, building_info) => {
    const info = building_info.find((item) => item.building_name === value);
    const type = name === 'to_building_name' ? 'to' : 'from';

    setBuildingFieldValue(info, type, info?.place_id);
  };

  const onSelectAutoCompleteHandler = useCallback(
    async (address, name, buildingInfo) => {
      Object.keys(address).forEach((key) => {
        if (key === 'address') {
          setFieldValue(`${name}_${key}`, address['street_number'] + ' ' + address[key]);
        } else {
          setFieldValue(`${name}_${key}`, address[key]);
        }
        if (!values.labor_type === 'default') {
          setFieldValue(`to_${key}`, address[key]);
        }
      });

      const building = findBuilding(buildingInfo, address);

      setFieldValue(`${name}_building_name`, '');
      setFieldValue(`${name}_required_coi`, 0);
      setFieldValue(`${name}_building_id`, 0);

      setBuildingFieldValue(building, name, address.place_id);
    },
    [setBuildingFieldValue, setFieldValue, values.labor_type]
  );

  return {
    values,
    errors,
    touched,
    actions: {
      setValues,
      setFieldValue,
      handleChange,
      isValidHandler,
      handleChangeJobType,
      handleChangeCompany,
      handleChangeStorageFee,
      handleChangeLaborType,
      handleSaveInventory,
      handleChangeInventoryTotal,
      handleChangeCFPrice,
      handleChangeStorageRate,
      handleChangeRateType,
      handleZipCode,
      handleChangeDeliveryDate,
      handleChangeCalcType,
      handleSelectUnitSize,
      handleChangeCreditRate,
      handleChangePackingService,
      handleSelectMoveSize,
      setBuildingFieldValue,
      handleSelectAutocompleteByName,
      onSelectAutoCompleteHandler,
    },
  };
};

import React from 'react';
import { useParams } from 'react-router-dom';
import { useInfo, useTabInfo } from 'hooks/query/useJob';
import CrewNotAssigned from './CrewNotAssigned';
import CrewAssignedInfo from './CrewAssignedInfo';

const JobAssignmentsTab = () => {
  const { id, tab } = useParams();

  const {
    data: { job_info: jobInfo },
  } = useInfo(id);

  const {
    data: { assigned_crew: assignedCrew },
  } = useTabInfo(id, tab);

  return (
    <div className="shadow w49">
      <h3 className="header">Personnel</h3>
      {!jobInfo.crew_id ? <CrewNotAssigned /> : <CrewAssignedInfo assignedCrew={assignedCrew} />}
    </div>
  );
};

export default JobAssignmentsTab;

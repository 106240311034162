import React, { useCallback } from 'react';
import { useModal } from 'context/modal';
import { useParams } from 'react-router';
import { useInfo, useTabInfo } from 'hooks/query/useJob';
import { usePackingSave } from 'hooks/query/useJob';
import Icon from 'components/Icon';

const PackingTab = () => {
  const { open, modalNames } = useModal();

  const { id, tab } = useParams();

  const {
    data: { job_info: jobInfo },
  } = useInfo(id);

  const {
    data: { job_packing: jobPacking },
  } = useTabInfo(id, tab);

  const { mutate: savePacking } = usePackingSave(id, tab);

  const deleteItem = useCallback(
    (type, id) => {
      const preparedData = jobPacking[type].filter((e) => e.id !== id);

      savePacking({
        job_packing: {
          estimated: type === 'estimated' ? preparedData : jobPacking[type],
          final: preparedData,
        },
        job_id: jobInfo.id,
      });
    },
    [jobInfo.id, jobPacking, savePacking]
  );

  const estimated_pack_qty = jobPacking.estimated.reduce((sum, item) => sum + parseInt(item.qty), 0);
  const estimated_pack_price = jobPacking.estimated.reduce((sum, item) => sum + item.qty * parseFloat(item.price), 0);

  const final_pack_qty = jobPacking.final.reduce((sum, item) => sum + parseInt(item.qty), 0);
  const final_pack_price = jobPacking.final.reduce((sum, item) => sum + item.qty * parseFloat(item.price), 0);

  const isDelivery = jobInfo?.type === 5;

  return (
    <div className="packing-tab">
      <h3 className="header table-header-shadow">Packing</h3>
      <table>
        <tbody>
          <tr>
            <th>
              <button
                type="button"
                disabled={isDelivery}
                onClick={() => {
                  open(modalNames.packing, { type: 'estimated' });
                }}
              >
                <Icon icon="add" size={16} />
                Packing Est.
              </button>
            </th>
            <th>Price</th>
            <th>Qty</th>
            <th>Total</th>
          </tr>
          {jobPacking?.estimated.map((item) => (
            <tr key={item.id}>
              <td>
                <button type="button" disabled={isDelivery} onClick={() => deleteItem('estimated', item.id)}>
                  <Icon icon="delete" title="Remove item" size={16} />
                </button>
                {item.name}
              </td>
              <td>{item.price}</td>
              <td>{item.qty}</td>
              <td>{(item.qty * item.price).toFixed(2)}</td>
            </tr>
          ))}
          <tr>
            <td />
            <td />
            <td>{estimated_pack_qty}</td>
            <td>{estimated_pack_price.toFixed(2)}</td>
          </tr>
        </tbody>
      </table>
      <table>
        <tbody>
          <tr>
            <th>
              <button
                type="button"
                disabled={isDelivery}
                onClick={() => {
                  open(modalNames.packing, { type: 'final', companyId: jobInfo.company_id, jobPacking });
                }}
              >
                <Icon icon="add" size={16} />
                Packing Act.
              </button>
            </th>
            <th>Price</th>
            <th>Qty</th>
            <th>Total</th>
          </tr>
          {jobPacking?.final.map((item) => (
            <tr key={item.id}>
              <td>
                <button type="button" disabled={isDelivery} onClick={() => deleteItem('final', item.id)}>
                  <Icon icon="delete" title="Remove item" size={16} />
                </button>
                {item.name}
              </td>
              <td>{item.price}</td>
              <td>{item.qty}</td>
              <td>{(item.qty * item.price).toFixed(2)}</td>
            </tr>
          ))}
          <tr>
            <td />
            <td />
            <td>{final_pack_qty}</td>
            <td>{final_pack_price.toFixed(2)}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default PackingTab;

import React, { Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { useInfo, useTabInfo } from 'hooks/query/useJob';
import Final from './Final';
import Estimated from './Estimated';

const FinalCal = ({ type }) => {
  const { id, tab } = useParams();

  const {
    data: { job_info: jobInfo, calc_info: calcInfo, ld_calc: ldCalc, related_jobs },
  } = useInfo(id);

  const {
    data: { job_packing: jobPacking },
  } = useTabInfo(id, tab);

  return (
    <Fragment>
      {type === 'final' ? (
        <Final
          id={id}
          status={jobInfo.status}
          jobPacking={jobPacking}
          calcInfo={calcInfo}
          relatedJobs={related_jobs}
          jobInfo={jobInfo}
          ldCalc={ldCalc}
        />
      ) : (
        <Estimated id={id} jobInfo={jobInfo} ldCalc={ldCalc} jobPacking={jobPacking} relatedJobs={related_jobs} calcInfo={calcInfo} />
      )}
    </Fragment>
  );
};

export default FinalCal;

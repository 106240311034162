import React, { useCallback, useMemo } from 'react';
import { useFormik } from 'formik';
import { useLocation } from 'react-router-dom';
import { matchPath } from 'react-router';
import { routePaths } from 'config/routes';
import { useModal } from 'context/modal';
import { useTabInfo, useLDFeesUpdate } from 'hooks/query/useJob';
import { useDefault } from 'hooks/query/useCommon';
import { ModalButton } from 'components/newComponents/Buttons';
import Modal from 'components/newComponents/Modal';
import FeeItem from './FeeItem';

const LongDistanceFeesModal = () => {
  const { modalNames, close, info } = useModal();
  const { pathname } = useLocation();

  const {
    params: { id, tab },
  } = matchPath(pathname, { path: routePaths.JOBS });

  const {
    data: { ld_fees: defaultItems },
  } = useDefault();

  const {
    data: { ld_job_fees: jobFees },
  } = useTabInfo(id, tab);

  const { mutate: updateFees } = useLDFeesUpdate(id, tab);

  const isVisible = info(modalNames.longDistanceFees).visible;

  const closeHandler = useCallback(() => close(modalNames.longDistanceFees), [close, modalNames.longDistanceFees]);

  const fees = useMemo(() => {
    return jobFees.reduce((acc, e) => {
      return { ...acc, [e.ld_fee_id]: e };
    }, {});
  }, [jobFees]);

  const defaultFees = useMemo(() => {
    return defaultItems.reduce((acc, e) => {
      return { ...acc, [e.id]: { ...e, checked: !!fees[e.id], price: fees[e.id] ? fees[e.id].price : e.price } };
    }, {});
  }, [defaultItems, fees]);

  const updateFeesHandler = useCallback(
    ({ items }) => {
      const prepared = Object.values(items)
        .filter((e) => e.checked)
        .map((e) => ({ job_id: id, ld_fee_id: e.id, price: e.price }));

      updateFees(
        {
          job_id: id,
          ld_job_fees: prepared,
        },
        { onSuccess: closeHandler }
      );
    },
    [updateFees, id, closeHandler]
  );

  const { values, setFieldValue, handleSubmit } = useFormik({
    initialValues: { items: defaultFees },
    onSubmit: updateFeesHandler,
  });

  const onSelectHandler = useCallback(
    (id) => {
      const { [id]: current, ...rest } = values.items;
      const isChecked = !current.checked;

      setFieldValue('items', {
        ...rest,
        [id]: { ...current, checked: isChecked, price: isChecked ? current.price : 0 },
      });
    },
    [setFieldValue, values.items]
  );

  const onChangeHandler = useCallback(
    (id, price) => {
      const { [id]: current, ...rest } = values.items;

      setFieldValue('items', { ...rest, [id]: { ...current, price } });
    },
    [setFieldValue, values.items]
  );

  return (
    <Modal style={{ width: 600 }} header="Long Distance Fees" show={isVisible} onHide={closeHandler}>
      <form onSubmit={handleSubmit}>
        <div className="new-table modal">
          <div className="new-table--row">
            <div className="new-table--row--item">
              <h4 className="subHeader p-0">Name</h4>
            </div>
            <div className="new-table--row--item">
              <h4 className="subHeader p-0">Price</h4>
            </div>
          </div>

          {Object.values(values.items).map((item) => (
            <FeeItem key={item.id} item={item} onSelect={onSelectHandler} handleChange={onChangeHandler} />
          ))}
        </div>
        <div className="footer-buttons">
          <ModalButton title="Cancel" variant="cancel" onClick={closeHandler} />
          <ModalButton title="Save" variant="confirm" type="submit" />
        </div>
      </form>
    </Modal>
  );
};

export default LongDistanceFeesModal;

import React from 'react';

const TextArea = ({ onChange, name, error, value, placeholder, ...rest }) => {
  return (
    <label style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', position: 'relative' }}>
      <textarea style={{ padding: 0, margin: 0 }} name={name} value={value} onChange={onChange} placeholder={placeholder} {...rest} />
      {error && (
        <span style={{ position: 'absolute', bottom: -20 }} className="error">
          {error}
        </span>
      )}
    </label>
  );
};
export default TextArea;

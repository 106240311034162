import React, { Fragment, useEffect, useState, useCallback, useMemo } from 'react';
import { findBuilding } from 'helpers/buildings';
import { useDefault } from 'hooks/query/useCommon';
import { useDistanceForm } from 'hooks/query/useCommon';
import Icon from 'components/Icon';
import SinglePoint from 'components/GoogleMap/SinglePoint';
import Autocomplete from 'components/newComponents/Autocomplete';
import Select from 'components/newComponents/Select';

const ExtraStopItem = ({ index, values, data, remove, setFieldValue, handleChange, recalculate }) => {
  const { data: defaultItems } = useDefault();
  const [isVisible, setIsVisible] = useState(false);

  const typeSet = useMemo(() => {
    return [
      {
        value: 1,
        label: 'From',
      },
      {
        value: 2,
        label: 'To',
      },
    ];
  }, []);

  const onSelectAutoCompleteHandler = useCallback(
    async (address, name) => {
      const building = findBuilding(defaultItems?.building_info, address);

      setFieldValue(`${name}address`, address?.full_address.replace(/\,.*/, '') || '');
      setFieldValue(`${name}building_name`, building?.building_name || '');
      setFieldValue(`${name}required_coi`, !!building);
      setFieldValue(`${name}building_id`, building?.id || 0);
      setFieldValue(`${name}place_id`, address?.place_id);
      setFieldValue(`${name}zip`, building?.building_zip || address?.zipcode);
      setFieldValue(`${name}city`, building?.building_city || address?.city);
      setFieldValue(`${name}state`, building?.building_state || address?.state);
    },
    [defaultItems.building_info, setFieldValue]
  );

  const zipHandler = useCallback(
    (value, name) => {
      setFieldValue(`${name}city`, value?.city);
      setFieldValue(`${name}state`, value?.state);
    },
    [setFieldValue]
  );

  return (
    <div className="new-table modal">
      <div className="new-table--row">
        <div className="new-table--row--item waypoint-inputs">
          <span>Type: </span>
          <Select
            options={typeSet}
            value={values.extra_stops[`${index}`].type}
            placeholder={`Select type`}
            name={`extra_stops[${index}].type`}
            onChange={({ value }) => setFieldValue(`extra_stops[${index}].type`, value)}
          />
        </div>
        <div className="new-table--row--item">
          <Icon icon="address" title="Address" />
          <Autocomplete
            name={`extra_stops[${index}]`}
            placeholder="Search address"
            value={values.extra_stops[`${index}`].address}
            onChange={(value) => setFieldValue(`extra_stops[${index}].address`, value)}
            onSelect={onSelectAutoCompleteHandler}
          />
        </div>
        <div className="new-table--row--item">
          <Icon icon="zip" title="Zip code" />
          <Autocomplete
            mask="11111"
            placeholder="Zip"
            name={`extra_stops[${index}].zip`}
            onBlur={zipHandler}
            value={values.extra_stops[`${index}`].zip}
            shouldFetchSuggestions={false}
            onChange={(value) => setFieldValue(`extra_stops[${index}].zip`, value)}
          />

          <button type="button" onClick={remove}>
            <Icon icon="delete" title="Remove extrastop" />
          </button>
        </div>
      </div>
      <div className="new-table--row">
        <div className="new-table--row--item no-padding">
          <button type="button" onClick={() => setIsVisible((pre) => !pre)} className="main p20 flex-grow-1">
            Map
          </button>
        </div>
      </div>
      {isVisible && (
        <div className="new-table--row mb-5">
          <div className="new-table--row--item no-padding">
            <SinglePoint marker={data.zip} fullWidth mapHeight={350} />
          </div>
        </div>
      )}
    </div>
  );
};

const initialExtraStopAdditional = {
  building_access_end: '',
  building_access_start: '',
  building_type: 0,
  carpet_protect: false,
  distance_elevator_apartment: '',
  distance_elevator_parking: '',
  elevator_exist: 0,
  elevator_floor: '',
  elevator_reservation_end: '',
  elevator_reservation_start: '',
  hardwood_protect: false,
  hoisting: false,
  hoisting_note: '',
  marble_protect: false,
  stairs: '',
};

export const ExtraStop = ({ values, setFieldValue, handleChange, setDistanceList, distanceList }) => {
  const [totalEstimated, setTotalEstimated] = useState(0);
  const [officeToPickup, setOfficeToPickup] = useState(0);
  const [dropOffToOffice, setDropOffToOffice] = useState(0);
  const { mutate: getDistance } = useDistanceForm();

  const getDistanceHandler = useCallback(async () => {
    if (!values.company_id || !values.from_zipcode || !values.to_zipcode) return [];

    if (!values.extra_stops.length) {
      getDistance(
        { from: values.from_zipcode, to: values.to_zipcode, company_id: values.company_id },
        {
          onSuccess: (res) => {
            setTotalEstimated(res.data?.travel_distance || 0);
            setOfficeToPickup(res.data?.pickup_distance || 0);
            setDropOffToOffice(res.data?.drop_off_to_office || 0);
          },
        }
      );
      return;
    }

    const requests = values.extra_stops.reduce((acc, el, index, self) => {
      if (!el.zipcode || el.zipcode.replaceAll('_', '').length !== 5) return acc;
      if (index === 0) acc.push({ from: values.from_zipcode, to: el.zipcode, company_id: values.company_id });
      if (index >= 0 && index < self.length - 1) acc.push({ from: el.zipcode, to: self[index + 1].zipcode, company_id: values.company_id });
      if (self.length - 1 === index) acc.push({ from: el.zipcode, to: values.to_zipcode, company_id: values.company_id });
      return acc;
    }, []);

    requests.forEach((data) => {
      getDistance(data, {
        onSuccess: (res) => {
          const pickupDistance = res.data?.pickup_distance || 0;
          setTotalEstimated((pre) => (pre + pickupDistance).toFixed(2));
          setDistanceList((pre) => [...pre, pickupDistance]);
        },
      });
    });
  }, [getDistance, setDistanceList, values.company_id, values.extra_stops, values.from_zipcode, values.to_zipcode]);

  useEffect(() => {
    getDistanceHandler();
  }, [getDistanceHandler]);

  const removeExtraStopHandler = useCallback(
    (index) => {
      const arr = values.extra_stops;

      setFieldValue('extra_stops', [...arr.slice(0, index), ...arr.slice(index + 1)]);
    },
    [setFieldValue, values.extra_stops]
  );

  const addExtraStopHandler = useCallback(() => {
    setFieldValue('extra_stops', [...values.extra_stops, { address: '', zipcode: '', type: 1, order: (values.extra_stops.length || 0) + 1 }]);
    setFieldValue('additional_info', [...values.additional_info, initialExtraStopAdditional]);
  }, [setFieldValue, values.additional_info, values.extra_stops]);

  return (
    <div className="new-table modal">
      {values.extra_stops.map((extraStop, index) => (
        <ExtraStopItem
          data={extraStop}
          key={index}
          index={index}
          handleChange={handleChange}
          values={values}
          recalculate={getDistanceHandler}
          remove={() => removeExtraStopHandler(index)}
          setFieldValue={setFieldValue}
        />
      ))}
      <div className="new-table--row">
        <div className="new-table--row--item">
          <button type="button" style={{ color: '#b1c3cc' }} onClick={addExtraStopHandler}>
            <Icon icon="add" />
            Add extra stop
          </button>
        </div>
      </div>

      <div className="new-table--row">
        <div className="new-table--row--item new-table--row--item__column align-items-start">
          <p>
            Estimated Travel Distance is <b>{totalEstimated || 0} MILES.</b>
          </p>
          <p>
            Distance from Office to PickUp is <b>{officeToPickup || 0} MILES.</b>
          </p>
          {distanceList.length ? (
            <Fragment>
              {distanceList.map((stop, index) => {
                if (distanceList.length === index + 1) {
                  return (
                    <p key={index + 1}>
                      From extra point {index} to DropOff
                      <b> {stop} MILES</b>
                    </p>
                  );
                }
                return (
                  <p key={index + 1}>
                    Distance to Extra stop {index + 1}
                    <b> {stop || 0} MILES</b>
                  </p>
                );
              })}
            </Fragment>
          ) : (
            ''
          )}
          <p>
            Distance from DropOff to Office is <b>{dropOffToOffice} MILES. </b>
          </p>
        </div>
      </div>
    </div>
  );
};

import React from 'react';
import CheckBox from 'components/newComponents/CheckBox';
import { useForm } from '../../../hooks/useForm';

function StorageFees({ storageFees }) {
  const { values, actions } = useForm();

  return (
    <div className="new-table--row">
      <div className="new-table--row--item">
        <CheckBox
          right={false}
          label={`Delivery Fee (${storageFees.delivery_fee})`}
          name="storage_fees"
          className="justify-content-start"
          checked={values['storage_fees'].delivery_fee === storageFees.delivery_fee}
          onChange={() => actions.handleChangeStorageFee({ delivery_fee: storageFees.delivery_fee })}
        />
        <CheckBox
          right={false}
          label={`Redelivery Fee (${storageFees.redelivery_fee})`}
          name="storage_fees"
          className="justify-content-start"
          checked={values['storage_fees'].redelivery_fee === storageFees.redelivery_fee}
          onChange={() => actions.handleChangeStorageFee({ redelivery_fee: storageFees.redelivery_fee })}
        />
        <CheckBox
          right={false}
          label={`Admin Fee (${storageFees.admin_fee})`}
          name="storage_fees"
          className="justify-content-start"
          checked={values['storage_fees'].admin_fee === storageFees.admin_fee}
          onChange={() => actions.handleChangeStorageFee({ admin_fee: storageFees.admin_fee })}
        />
        <CheckBox
          right={false}
          label={`Hold Fee (${storageFees.hold_fee})`}
          name="storage_fees"
          className="justify-content-start"
          checked={values['storage_fees'].hold_fee === storageFees.hold_fee}
          onChange={() => actions.handleChangeStorageFee({ hold_fee: storageFees.hold_fee })}
        />
        <CheckBox
          right={false}
          label={`Insurance Fee (${storageFees.insurance_fee})`}
          name="storage_fees"
          className="justify-content-start"
          checked={values['storage_fees'].insurance_fee === storageFees.insurance_fee}
          onChange={() => actions.handleChangeStorageFee({ insurance_fee: storageFees.insurance_fee })}
        />
      </div>
    </div>
  );
}

export default StorageFees;

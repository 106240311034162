import { useMutation, useQueryClient } from 'react-query';
import { useApi } from 'context/api';
import queryKeys from 'config/queryKeys';

export const useTaskCreate = (id, tab) => {
  const { task } = useApi();
  const queryClient = useQueryClient();

  return useMutation(task.create, {
    onSuccess: () => queryClient.invalidateQueries([queryKeys.job, id, tab]),
  });
};

export const useTaskUpdate = (id, tab) => {
  const { task } = useApi();
  const queryClient = useQueryClient();

  return useMutation(task.update, { onSuccess: () => queryClient.invalidateQueries([queryKeys.job, id, tab]) });
};

export const useTaskDelete = (id, tab) => {
  const { task } = useApi();
  const queryClient = useQueryClient();

  return useMutation(task.delete, { onSuccess: () => queryClient.invalidateQueries([queryKeys.job, id, tab]) });
};

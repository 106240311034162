export default {
  pickup_date: '',
  pickup_time: '8:00 am',
  follow_up_date: '',
  follow_up_time: '8:00 am',
  delivery_date: '',
  delivery_time: '8:00 am',
  mail_template: 'assigned',
  rate_type: 1,
  flat_rate: 0,
  full_packing_rate: 'customer',
  hear_about: 0,
  move_size_id: '',
  estimated_manual_travel_time: 0.0,
  estimated_manual_pickup_time: 0.0,
  estimated_time: 0.0,
  company_id: '',
  storage_id: 0,
  unit_size_id: 0,
  qty_man: '',
  qty_trucks: '',
  full_packing_rate_value: 0,
  default_rate: 0,
  manual_rate: 0,
  credit_rate: false,
  do_not_send: false,
  deny_self_booking: false,
  storage_rate_type: 'daily',
  delivery_fee: false,
  delivery_fee_value: 0,
  redelivery_fee: false,
  redelivery_fee_value: 0,
  admin_fee: false,
  admin_fee_value: 0,
  hold_fee: false,
  hold_fee_value: 0,
  insurance_fee: false,
  insurance_fee_value: 0,
  storage_daily_rate: 0,
  storage_weekly_rate: 0,
  storage_monthly_rate: 0,
  storage_annual_rate: 0,
  first_month_charge: 0,
  storage_rate: 0,
  storage_deposit: 0,
  i_know_delivery_address: true,
  assignments: '',
  from_name: '',
  to_name: '',
  from_email: '',
  to_email: '',
  from_full_address: '',
  to_full_address: '',
  from_cell_phone: '',
  from_home_phone: '',
  to_cell_phone: '',
  to_home_phone: '',
  from_zipcode: '',
  to_zipcode: '',
  from_state: '',
  to_state: '',
  from_city: '',
  to_city: '',
  from_address: '',
  from_apartment: '',
  to_address: '',
  to_apartment: '',
  to_apt: '',
  from_apt: '',
  from_building_name: '',
  to_building_name: '',
  drop_off_to_office: 0,
  pickup_distance: 0,
  travel_distance: 0,
  extra_stops_distance: [],
  job_discounts: [],
  job_charges: [],
  extra_stop: false,
  from_required_coi: '',
  to_required_coi: '',
  building_type: 0,
  building_type_from: null,
  building_type_to: null,
  building_access_start: '',
  building_access_end: '',
  elevator_exist_from: 0,
  elevator_reservation_start: '',
  elevator_reservation_end: '',
  elevator_from_floor: '',
  distance_elevator_apartment: '',
  distance_elevator_parking: '',
  stairs_from: 0,
  hardwood_protect: false,
  distance_elevator_apartment_from: '',
  distance_elevator_parking_from: '',
  marble_protect_from: 0,
  carpet_protect_from: 0,
  hoisting_from: 0,
  hoisting_note_from: '',
  building_access_to_start: null,
  building_access_to_end: null,
  elevator_exist_to: 0,
  elevator_reservation_to_start: '',
  elevator_reservation_to_end: '',
  elevator_to_floor: '',
  distance_elevator_apartment_to: '',
  distance_elevator_parking_to: '',
  stairs_to: 0,
  hardwood_protect_to: 0,
  hardwood_protect_from: 0,
  marble_protect_to: 0,
  carpet_protect_to: 0,
  hoisting_to: 0,
  hoisting_note_to: '',
  packing: 0,
  packing_note: '',
  fragile: 0,
  fragile_note: '',
  wrap: 0,
  wrap_note: '',
  elevator_from: 0,
  elevator_to: 0,
  apt_finished: 0,
  apt_note: '',
  insurance: false,
  extra_stops: [],
  additional_info: [],
  distanceList: [],
  job_type: 'followUp',
};

import React, { Fragment, useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { useParams, useHistory, generatePath } from 'react-router-dom';
import { useFormik } from 'formik';
import { useTabInfo, useInfo, usePaymentsCreate, useSelfBookingUpdate } from 'hooks/query/useJob';
import { useEmailSend } from 'hooks/query/useMail';
import PaymentEnterTable from './PaymentEnterTable';
import PaymentDetails from './PaymentDetails';
import { routePaths } from 'config/routes';

const Payment = () => {
  const { id, tab } = useParams();
  const {
    data: { calc_info: calcInfo, job_info: jobInfo },
  } = useInfo(id);

  const {
    data: { job_payments: jobPayments },
  } = useTabInfo(id, tab);

  const { mutate: createPayments } = usePaymentsCreate(id, tab);
  const { mutate: updateSelfBooking } = useSelfBookingUpdate(id, tab);
  const { mutate: sendEmail } = useEmailSend(id, tab);

  const { values, setFieldValue, setValues, handleChange } = useFormik({
    initialValues: {
      type: 0,
      amount: 0,
      image: null,
      imagePreviewUrl: null,
    },
  });

  const history = useHistory();

  const isDeposit = useMemo(() => {
    return jobPayments.some((item) => item.deposit);
  }, [jobPayments]);

  const createPaymentHandler = useCallback(
    (inputTape) => {
      if (values.type === inputTape) {
        setValues({ ...values, type: 0, amount: 0, image: null, imagePreviewUrl: null });
      } else {
        setFieldValue('type', inputTape);
      }
    },
    [setFieldValue, setValues, values]
  );

  const handleImageChange = useCallback(
    (e) => {
      e.preventDefault();
      let reader = new FileReader();
      let image = e.target.files[0];
      reader.onloadend = () => {
        setFieldValue('image', image);
        setFieldValue('imagePreviewUrl', reader.result);
      };
      reader.readAsDataURL(image);
    },
    [setFieldValue]
  );

  const submitPaymentHandler = useCallback(() => {
    const data = {
      job_id: jobInfo.id,
      type: values.type,
      amount: values.amount,
      ...(values.image ? { image: values.image } : {}),
    };
    createPayments(data, {
      onSuccess: () => {
        if (+jobInfo.status === 7 || +jobInfo.status === 21) {
          history.push({ pathname: generatePath(routePaths.JOBS, { id: jobInfo.id, tab: 'rate', type: 'all' }) });
        }
      },
    });

    setFieldValue('type', 0);
    setFieldValue('amount', 0);
  }, [createPayments, history, setFieldValue, jobInfo.id, values.amount, jobInfo.status, values.image, values.type]);

  const sendLinkHandler = useCallback(() => {
    const data = {
      link: `${process.env.REACT_APP_API_URL}/api/customerDocuments/payment?job_id=${jobInfo.id}&type=deposit`,
      template: 'payment_link',
      job_id: jobInfo.id,
      edit_template: false,
      template_id: 0,
      template_edited: 0,
      previous_status: jobInfo.status,
      include_pdf: false,
      template_html: '',
      pdf: [],
    };
    sendEmail(data);
  }, [jobInfo.id, jobInfo.status, sendEmail]);

  const allowBookingHandler = useCallback(() => {
    updateSelfBooking({ allow_booking: 1, job_id: jobInfo.id }, { onSuccess: () => sendLinkHandler() });
  }, [jobInfo.id, sendLinkHandler, updateSelfBooking]);

  const denyBookingHandler = useCallback(
    (cancel) => {
      updateSelfBooking({ deny_self_booking: 1, cancel, job_id: jobInfo.id });
    },
    [jobInfo.id, updateSelfBooking]
  );

  const currentDeposit = useMemo(() => {
    return jobPayments?.reduce((sum, item) => {
      if (item.deposit) {
        return sum + +item.amount;
      }
      return sum;
    }, 0);
  }, [jobPayments]);

  return (
    <div className="new-table mb-20 shadow">
      <div className="new-table--row">
        <div className="new-table--row--item justify-content-center no-padding">
          <h3 className="header">PAYMENT</h3>
        </div>
      </div>
      <div className="new-table--row">
        <div className="new-table--row--item justify-content-center">
          <p className="booking">Self booking: {+calcInfo.deny_self_booking === 1 ? 'Denied' : 'Allowed'}</p>
        </div>
      </div>

      {jobInfo.status !== 18 && (
        <div className="new-table--row">
          <div className="new-table--row--item no-padding">
            <button type="button" className="actions not-allow" onClick={() => denyBookingHandler(1)}>
              Deny booking & cancel job
            </button>
          </div>
        </div>
      )}

      {!!calcInfo.allow_booking ? (
        <Fragment>
          <div className="new-table--row">
            <div className="new-table--row--item no-padding">
              <button type="button" className="actions not-allow" onClick={() => denyBookingHandler(0)}>
                Disable booking after expiration
              </button>
            </div>
          </div>
          <div className="new-table--row">
            <div className="new-table--row--item no-padding">
              <button type="button" className="actions info" onClick={sendLinkHandler}>
                Send link to customer
              </button>
            </div>
          </div>
        </Fragment>
      ) : (
        <div className="new-table--row">
          <div className="new-table--row--item no-padding justify-content-center">
            <button type="button" className="actions allow" onClick={allowBookingHandler}>
              Allow booking after expiration
            </button>
          </div>
        </div>
      )}

      <div className="new-table--row buttons">
        <div className="new-table--row--item no-padding ">
          <button type="button" className={classNames('link', { active: values.type === 1 })} onClick={() => createPaymentHandler(1)}>
            CC
          </button>
        </div>
        <div className="new-table--row--item no-padding ">
          <button type="button" className={classNames('link', { active: values.type === 2 })} onClick={() => createPaymentHandler(2)}>
            Cash
          </button>
        </div>
        <div className="new-table--row--item no-padding">
          <button type="button" className={classNames('link', { active: values.type === 3 })} onClick={() => createPaymentHandler(3)}>
            Check
          </button>
        </div>
      </div>

      {(values.type === 1 || values.type === 2) && (
        <PaymentEnterTable
          deposit={currentDeposit}
          isDeposit={isDeposit}
          values={values}
          companyId={jobInfo.company_id}
          disabledDeposit={calcInfo.disabled_deposit}
          handleChange={handleChange}
          submitPayment={submitPaymentHandler}
        />
      )}

      {values.type === 3 && (
        <Fragment>
          <div className="new-table--row">
            {values.imagePreviewUrl ? (
              <div className="new-table--row--item">
                <img src={values.imagePreviewUrl} alt="preview img" />
              </div>
            ) : (
              <div className="new-table--row--item">
                <p className="payment-booking">Check preview</p>
              </div>
            )}
          </div>
          <div className="new-table--row">
            <div className="new-table--row--item">
              <label className="file">
                Choose file
                <input
                  type="file"
                  accept="image/jpeg,image/gif,image/png,image/bmp,application/pdf,image/x-eps"
                  onChange={(e) => handleImageChange(e)}
                />
              </label>
            </div>
          </div>
          <PaymentEnterTable
            deposit={currentDeposit}
            isDeposit={isDeposit}
            values={values}
            companyId={jobInfo.company_id}
            disabledDeposit={calcInfo.disabled_deposit}
            handleChange={handleChange}
            submitPayment={submitPaymentHandler}
          />
        </Fragment>
      )}

      <PaymentDetails deposit={currentDeposit} />
    </div>
  );
};

export default Payment;

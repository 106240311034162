import React from 'react';
import { useField } from 'formik';

const FormikTextarea = (props) => {
  const { newChange, name, ...rest } = props;
  const [field, meta] = useField(props);

  const handleChange = (e) => {
    field.onChange(e);
    if (newChange) newChange(e.target.value);
  };

  return (
    <label style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', position: 'relative' }}>
      <textarea style={{ padding: 0, margin: 0 }} {...rest} {...field} onChange={handleChange} />
      {meta.error && meta.touched && (
        <span style={{ position: 'absolute', bottom: -20 }} className="error">
          {meta.error}
        </span>
      )}
    </label>
  );
};
export default FormikTextarea;

import moment from 'moment';

function changePeriod(type) {
  let from, to;

  switch (type) {
    case 0:
      from = '';
      to = '';
      break;
    case 1: // Today
      from = moment().startOf('day').format('MM/DD/YY');
      to = moment().endOf('day').format('MM/DD/YY');
      break;
    case 2: // Yesterday
      from = moment().add(-1, 'days').startOf('day').format('MM/DD/YY');
      to = moment().add(-1, 'days').endOf('day').format('MM/DD/YY');
      break;
    case 3: // This week
      from = moment().startOf('week').format('MM/DD/YY');
      to = moment().endOf('week').format('MM/DD/YY');
      break;
    case 4: // Last week
      from = moment().isoWeekday(-7).startOf('day').format('MM/DD/YY');
      to = moment().isoWeekday(-1).endOf('day').format('MM/DD/YY');
      break;
    case 5: // Last 2 weeks
      from = moment().isoWeekday(-14).startOf('day').format('MM/DD/YY');
      to = moment().isoWeekday(-1).endOf('day').format('MM/DD/YY');
      break;
    case 6: // Last 30 days
      from = moment().add(-30, 'days').startOf('day').format('MM/DD/YY');
      to = moment().add(-1, 'days').endOf('day').format('MM/DD/YY');
      break;
    case 7: // This Month
      from = moment().startOf('month').format('MM/DD/YY');
      to = moment().endOf('day').format('MM/DD/YY');
      break;
    case 8: // Last Month
      from = moment().startOf('month').add(-1, 'month').format('MM/DD/YY');
      to = moment().endOf('month').add(-1, 'month').format('MM/DD/YY');
      break;
    case 9: // This Year
      from = moment().startOf('year').format('MM/DD/YY');
      to = moment().endOf('day').format('MM/DD/YY');
      break;
    case 10: // Last Year
      from = moment().startOf('year').add(-1, 'year').format('MM/DD/YY');
      to = moment().endOf('year').add(-1, 'year').format('MM/DD/YY');
      break;
    default:
      from = '';
      to = '';
  }
  return { from, to };
}

function getPayrollDatesByPeriod(periodType, currentDateFrom) {
  switch (periodType) {
    case 0:
      return getWeeklyDate(currentDateFrom, 7);
    case 1:
      return getWeeklyDate(currentDateFrom, 14);
    case 2:
      return {
        from: moment(currentDateFrom, 'MM/DD/YY').startOf('month').format('MM/DD/YY'),
        to: moment(currentDateFrom, 'MM/DD/YY').endOf('month').format('MM/DD/YY'),
      };
    case 3:
      return {
        from: moment(currentDateFrom, 'MM/DD/YY').startOf('quarter').format('MM/DD/YY'),
        to: moment(currentDateFrom, 'MM/DD/YY').endOf('quarter').format('MM/DD/YY'),
      };
    default:
      return null;
  }
}

function getWeeklyDate(date, daysToAdd) {
  const from = moment(date).format('MM/DD/YY');
  const to = moment(date).add(daysToAdd, 'days').format('MM/DD/YY');
  return { from, to };
}

function getValueToAdd(periodType) {
  switch (periodType) {
    case 0:
      return { format: 'ms', value: 100 };
    case 1:
      return { format: 'ms', value: 100 };
    case 2:
      return { format: 'd', value: 1 };
    case 3:
      return { format: 'd', value: 1 };
    default:
      return null;
  }
}

function getValueToSubtract(periodType) {
  switch (periodType) {
    case 0:
      return { format: 'd', value: 7 };
    case 1:
      return { format: 'd', value: 14 };
    case 2:
      return { format: 'M', value: 1 };
    case 3:
      return { format: 'Q', value: 1 };
    default:
      return null;
  }
}

function enableDateSelect(periodType, date) {
  switch (periodType) {
    case 0:
      return moment(date).day() === 0;
    case 1:
      return moment(date).day() === 0;
    case 2:
      return moment(date).date() === 1;
    case 3: {
      const current = moment(date).unix();
      const quarterStart = moment(date).startOf('quarter').unix();
      return current === quarterStart;
    }
    default:
      return null;
  }
}

export { changePeriod, getPayrollDatesByPeriod, getWeeklyDate, getValueToAdd, getValueToSubtract, enableDateSelect };

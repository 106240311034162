export const defaultDimension = {
  high: 0,
  width: 0,
  length: 0,
};

export function prepareInitialValues(jobInventoryList) {
  return jobInventoryList.map((item) => {
    return {
      ...item,
      dimension: item.dimension ? item.dimension : defaultDimension,
      condition_dest: item.condition_dest ? item.condition_dest : '//',
      condition: item.condition ? item.condition : '//',
    };
  });
}

export const inventoryByCategories = (list, categories) => {
  return categories.reduce((acc, el, i) => {
    return { ...acc, [el]: list.filter((e) => e.category_id === i) };
  }, {});
};

export function prepareDataToSubmit(data) {
  const prepared = data.map((item) => {
    if (item.custom === 1) {
      const { id, ...data } = item;
      return { ...data, inventory_id: 0 };
    }

    return { ...item, inventory_id: item.inventory_id };
  });

  return prepared;
}

export function getSymbolsOptions(statuses) {
  let descriptiveSymbols = [];
  let exceptionSymbols = [];
  let locationSymbols = [];

  if (statuses) {
    descriptiveSymbols = Object.entries(statuses.descriptive_symbols).map(([key, value]) => {
      return {
        label: value,
        value: key,
      };
    });

    exceptionSymbols = Object.entries(statuses.exception_symbols).map(([key, value]) => {
      return {
        label: value,
        value: key,
      };
    });

    locationSymbols = Object.values(statuses.location_symbols).map((value) => {
      return {
        label: value,
        value: value,
      };
    });
  }

  return {
    descriptiveSymbols,
    exceptionSymbols,
    locationSymbols,
  };
}

import React, { useMemo, useCallback } from 'react';
import moment from 'moment';
import JobColumn from './JobSection/JobColumn';
import JobSection from './JobSection';
import Job from './JobSection/Job';
import { getJobByDate } from 'helpers/jobs';
import { add, formatFullDate } from 'helpers/dateHelper';
import { nameCapitalizer } from 'helpers/nameCapitalizer';
import { hasSelectedJobOnCurrentPage, getWeeklyJobsRanges, getJobsByRange } from './jobListBuilderHelpers';

const useJobListBuilder = (jobs, selectedJobId, displayAllJobsOnSinglePage) => {
  const jobsPeriods = useMemo(() => {
    if (!jobs) return { today: [], tomorrow: [], future: [], past: [] };

    const prepared = jobs.map((job) => ({ ...job, customer_name: nameCapitalizer(job.customer_name) }));

    return {
      today: getJobByDate(prepared, formatFullDate(new Date()), 'isSame'),
      tomorrow: getJobByDate(prepared, add(formatFullDate(new Date()), 1, 'd'), 'isSame'),
      future: getJobByDate(prepared, add(formatFullDate(new Date()), 1, 'd'), 'isAfter'),
      past: getJobByDate(prepared, formatFullDate(new Date()), 'isBefore'),
    };
  }, [jobs]);

  const futurePastJobs = useCallback((job_list, key) => {
    const dates = Array.from(
      job_list.reduce((data, item) => {
        const date = moment(item.pickup_date).format('MM/DD/YY ddd');

        return data.set(date, (data.get(date) ?? []).concat(item));
      }, new Map())
    );

    const sortedByDate = [...dates.sort((a, b) => moment(a[0], 'MM/DD/YY ddd').diff(moment(b[0], 'MM/DD/YY ddd')))];
    if (key === 'past') sortedByDate.reverse();

    return sortedByDate.map(([date, jobs]) => (
      <JobColumn key={date} date={date}>
        {jobs.map((job, i) => (
          <Job key={[date, i].join(':')} job={job} />
        ))}
      </JobColumn>
    ));
  }, []);

  const nearDayJobs = useCallback((job_list) => {
    return job_list.map((job, i) => <Job key={i} job={job} />);
  }, []);

  const getJobs = useCallback(
    (job_list, key, total) => {
      return (
        <JobSection key={key} title={key.charAt(0).toUpperCase() + key.slice(1)} count={total}>
          {['future', 'past'].includes(key) ? futurePastJobs(job_list, key) : nearDayJobs(job_list)}
        </JobSection>
      );
    },
    [futurePastJobs, nearDayJobs]
  );

  const list = useMemo(() => {
    if (!jobsPeriods || !jobs) return [];

    const { today, tomorrow, future, past } = jobsPeriods;

    const pages = [];

    if (displayAllJobsOnSinglePage) {
      return [
        {
          period: '',
          key: '',
          list: [
            today.length ? getJobs(today, 'today', today.length) : null,
            tomorrow.length ? getJobs(tomorrow, 'tomorrow', tomorrow.length) : null,
            future.length ? getJobs(future, 'future', future.length) : null,
            past.length ? getJobs(past, 'past', past.length) : null,
          ],
          hasSelectedJobOnCurrentPage: true,
        },
      ];
    }

    if (today.length) {
      pages.push({
        period: 'today',
        key: moment().format('MM/DD/YYYY'),
        list: [getJobs(today, 'today', today.length)],
        hasSelectedJobOnCurrentPage: hasSelectedJobOnCurrentPage(today, selectedJobId),
      });
    }

    if (tomorrow.length) {
      const todayJobs = pages?.[0]?.list.length && pages[0].list[0];
      const period = pages?.[0]?.period ? `${pages[0].period}/tomorrow` : 'tomorrow';
      const key = pages?.[0]?.key
        ? `${pages[0].key} - ${moment().add(1, 'days').format('MM/DD/YYYY')}`
        : moment().add(1, 'days').format('MM/DD/YYYY');
      const selectedJobOnCurrentPage = pages?.[0]?.hasSelectedJobOnCurrentPage
        ? pages[0].hasSelectedJobOnCurrentPage
        : hasSelectedJobOnCurrentPage(tomorrow, selectedJobId);

      pages[0] = {
        period: period,
        key: key,
        list: [todayJobs, getJobs(tomorrow, 'tomorrow', tomorrow.length)],
        hasSelectedJobOnCurrentPage: selectedJobOnCurrentPage,
      };
    }

    if (future.length) {
      const futureJobsRanges = getWeeklyJobsRanges(future);

      futureJobsRanges.forEach((range) => {
        const prepared = getJobsByRange(future, range);

        pages.push({
          period: 'future',
          key: range,
          list: [getJobs(prepared, 'future', future.length)],
          hasSelectedJobOnCurrentPage: hasSelectedJobOnCurrentPage(prepared, selectedJobId),
        });
      });
    }

    if (past.length) {
      const pastJobsRanges = getWeeklyJobsRanges(past);

      pastJobsRanges.forEach((range) => {
        const prepared = getJobsByRange(past, range);

        pages.push({
          period: 'past',
          key: range,
          list: [getJobs(prepared, 'past', past.length)],
          hasSelectedJobOnCurrentPage: hasSelectedJobOnCurrentPage(prepared, selectedJobId),
        });
      });
    }

    return pages;
  }, [jobs, jobsPeriods, selectedJobId, getJobs]);

  return list;
};

export { useJobListBuilder };

import React, { memo } from 'react';
import { preventArrowUpDown } from 'utils/preventArrowUpDown';

const FeeItem = memo(({ item, handleChange, onSelect }) => (
  <div className="new-table--row">
    <div className="new-table--row--item">
      <label className="start-value">
        <input type="checkbox" name="checked" checked={item.checked} onChange={() => onSelect(item.id)} />
        <b>{item.name}</b>
      </label>
    </div>
    <div className="new-table--row--item">
      <input
        type="number"
        disabled={!item.checked}
        value={item.price}
        onKeyDown={preventArrowUpDown}
        onChange={(e) => handleChange(item.id, e.target.value)}
      />
    </div>
  </div>
));

export default FeeItem;

import React, { useMemo, useCallback } from 'react';
import { useFormik } from 'formik';
import { useParams } from 'react-router-dom';
import { useInfo } from 'hooks/query/useJob';
import { useSelectAgent } from 'hooks/useSelectAgent';
import { useJobTimeUpdate } from 'hooks/query/useJob';
import { getAppropriateValue } from 'helpers/getAppropriateValue';
import Icon from 'components/Icon';

const FinalCalc = () => {
  const { id } = useParams();

  const { mutate } = useJobTimeUpdate(id);
  const { onMutate: updateInfo } = useSelectAgent(mutate);

  const {
    data: { job_info: info, calc_info: calcInfo, related_jobs ,ld_calc},
  } = useInfo(id);

  // const isLongDistanceTypeJob = info?.type === 5;

  // const isPickupJob = related_jobs?.long_delivery_job !== 0;

  const isEnabledTime = useMemo(() => {
    const statuses = [3, 4, 6, 5];
    if (!statuses.includes(info.status)) return true;
    return false;
  }, [info.status]);

  const isEnabledEstimateTime = useMemo(() => {
    const statuses = [7, 2, 3];
    if (!statuses.includes(info.status)) return true;
    return false;
  }, [info.status]);

  const initialEstimateTime = useMemo(() => {
    const estimatedPickupTime = getAppropriateValue(calcInfo.estimated_manual_pickup_time, calcInfo.estimated_pickup_time);
    const estimatedTravelTime = getAppropriateValue(calcInfo.estimated_manual_travel_time, calcInfo.estimated_travel_time);
    const summaryTime = estimatedTravelTime + estimatedPickupTime;

    return {
      estimated_time: calcInfo.estimated_time,
      estimated_pickup_time: estimatedPickupTime?.toFixed(2),
      estimated_travel_time: estimatedTravelTime?.toFixed(2),
      estimated_summary_time: summaryTime?.toFixed(2),
    };
  }, [calcInfo]);

  const initialTime = useMemo(() => {
    const travelTime = getAppropriateValue(calcInfo.manual_travel_time, calcInfo.travel_time);
    const pickupTime = getAppropriateValue(calcInfo.manual_pickup_time, calcInfo.pickup_time);
    const summary = travelTime + pickupTime;

    const showFinal = [3, 4, 5, 6].includes(info.status);

    return {
      labor_time: showFinal ? calcInfo.labor_time : '0.00',
      travel_time: showFinal ? travelTime?.toFixed(2) : '0.00',
      pickup_time: pickupTime?.toFixed(2),
      summary_time: summary?.toFixed(2),
    };
  }, [calcInfo, initialEstimateTime, info.status]);

  const { handleChange, setFieldValue, values, initialValues } = useFormik({
    enableReinitialize: true,
    initialValues: { ...initialTime, ...initialEstimateTime },
  });

  const handleBlur = useCallback(
    (e, name) => {
      const nextValue = e.target.value || initialValues[e.target.name];
      setFieldValue(e.target.name, nextValue);
      updateInfo({ job_id: info.id, [name]: nextValue });
    },
    [info.id, initialValues, setFieldValue, updateInfo]
  );

  return (
    <div className="job_details__item">
      <h3 className="header table-header-shadow">Est/Final</h3>
      <div className="new-table">
        <form>
          <div className="new-table--row">
            <div className="new-table--row--item">
              <Icon icon="labor" title="Labor" size={22} />
              <input
                type="text"
                disabled={isEnabledEstimateTime}
                maxLength={5}
                value={values['estimated_time']}
                name="estimated_time"
                onChange={handleChange}
                onBlur={(e) => handleBlur(e, 'estimated_time')}
              />
            </div>
            <div className="new-table--row--item">
              <input
                type="text"
                disabled={isEnabledTime}
                maxLength={5}
                value={values['labor_time']}
                name="labor_time"
                onChange={handleChange}
                onBlur={(e) => handleBlur(e, 'labor_time')}
              />
            </div>
          </div>
          <div className="new-table--row">
            <div className="new-table--row--item">
              <Icon icon="pickup_time" title="Pickup Time" size={22} />
              <input
                type="text"
                maxLength={5}
                disabled={isEnabledEstimateTime}
                value={values.estimated_pickup_time}
                name="estimated_pickup_time"
                onChange={handleChange}
                onBlur={(e) => handleBlur(e, 'estimated_manual_pickup_time')}
              />
            </div>
            <div className="new-table--row--item">
              <input
                type="text"
                maxLength={5}
                disabled={isEnabledTime}
                value={values.pickup_time}
                name="pickup_time"
                onChange={handleChange}
                onBlur={(e) => handleBlur(e, 'manual_pickup_time')}
              />
            </div>
          </div>
          <div className="new-table--row">
            <div className="new-table--row--item">
              <Icon icon="office_to_pickup" title="Travel time" size={22} />
              <input
                type="text"
                maxLength={5}
                disabled={isEnabledEstimateTime}
                value={values.estimated_travel_time}
                name="estimated_travel_time"
                onChange={handleChange}
                onBlur={(e) => handleBlur(e, 'estimated_manual_travel_time')}
              />
            </div>
            <div className="new-table--row--item">
              <input
                type="text"
                maxLength={5}
                disabled={isEnabledTime}
                value={values.travel_time}
                name="travel_time"
                onChange={handleChange}
                onBlur={(e) => handleBlur(e, 'manual_travel_time')}
              />
            </div>
          </div>
          {/* <div className="new-table--row">
            <div className="new-table--row--item">
              <Icon icon="sum" title="Sum" size={22} />
              <input type="text" disabled value={values.estimated_summary_time} name="estimated_summary_time" />
            </div>
            <div className="new-table--row--item">
              <input type="text" disabled value={values.summary_time} name="summary_time" />
            </div>
          </div> */}

          <div className="new-table--row">
            <div className="new-table--row--item">
              <Icon icon="sum" title="Sum" size={22} />
              <input
                type="text"
                disabled
                readOnly
                value={(Number(values.estimated_time) + Number(values.estimated_travel_time)).toFixed(2)}
                name="estimated_summary_time"
              />
            </div>
            <div className="new-table--row--item">
              <input
                name="summary_time"
                type="text"
                readOnly
                disabled
                value={!isEnabledTime ? (Number(values.labor_time) + Number(values.travel_time)).toFixed(2) : '0.00'}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FinalCalc;

import React from 'react';
import { useReminderDelete } from 'hooks/query/useSMS';
import moment from 'moment';
import Icon from 'components/Icon';

const SmsReminderItem = ({ reminder, jobId }) => {
  const { mutate: deleteSMSReminder } = useReminderDelete(jobId);

  return (
    <div className="new-table--row">
      <div className="new-table--row--item justify-content-center no-side-shadows">
        <p>{reminder.message}</p>
      </div>

      <div className="new-table--row--item justify-content-center no-side-shadows">
        <span>{moment.unix(reminder.send_time).format('l LT')}</span>
      </div>
      <div className="new-table--row--item justify-content-center no-side-shadows">
        {reminder?.sms_id === '' ? (
          <button type="button" onClick={() => deleteSMSReminder({ reminder_id: reminder.id, job_id: jobId })}>
            <Icon icon="delete" size={20} />
          </button>
        ) : (
          <span>Sent</span>
        )}
      </div>
    </div>
  );
};

export default SmsReminderItem;

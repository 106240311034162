import React, { memo, useCallback, useMemo } from 'react';
import classNames from 'classnames';
import Icon from 'components/Icon';
import DropdownMenu from 'components/DropdownMenu';
import { useModal } from 'context/modal';
import { useParams } from 'react-router-dom';
import { useTabInfo } from 'hooks/query/useJob';
import { useDeleteExtraStop } from 'hooks/query/useExtraStops';

const RoutesItem = memo((props) => {
  const {
    type,
    time,
    travelDistance,
    address,
    name,
    stairs,
    packing,
    extraStopClass,
    travelTitle,
    isExtraStop,
    isIntermediateStops,
    extraStopId,
    extraStop,
    onClick,
    options,
    buildingName,
    elevator,
    elevatorExist,
    elevatorConfirm,
    elevatorReservation,
    elevatorStyle,
    isOffice,
  } = props;

  const { id, tab } = useParams();

  const { mutate: deleteExtraStop } = useDeleteExtraStop(id, tab);

  const {
    data: { moving_info: movingInfo, job_insurance_info: jobInsuranceInfo },
  } = useTabInfo(id, tab);

  const { modalNames, open } = useModal();

  const Element = type ? 'button' : 'span';
  const jobType = type === 'from' ? 'estimated' : 'final';

  let insuranceStyle = { color: '', title: 'Insurance' };

  const showPacking = useCallback(
    (e) => {
      e.stopPropagation();
      if (type) open(modalNames.packing, { type: jobType });
    },
    [jobType, modalNames.packing, open, type]
  );

  const showInsurance = useCallback(
    (e) => {
      e.stopPropagation();
      if (type) return open(modalNames.insuranceProvider, { type });
    },
    [modalNames.insuranceProvider, open, type]
  );

  const deleteExtrasStop = useCallback(
    (e) => {
      e.stopPropagation();
      deleteExtraStop({ stop_id: extraStopId, job_id: id });
    },
    [deleteExtraStop, extraStopId, id]
  );

  if (!!type && !isOffice && !isExtraStop) {
    if (movingInfo[type].required_coi === 1) {
      insuranceStyle.color = 'red';
      insuranceStyle.title = 'COI Required';
      if (jobInsuranceInfo[type]) {
        insuranceStyle.color = 'yellow';
        insuranceStyle.title = 'COI Pending';
        if (
          Number(jobInsuranceInfo[type].disabled_wc) === 1
            ? Number(jobInsuranceInfo[type].approved_liability) === 1
            : Number(jobInsuranceInfo[type].approved_liability) === 1 && Number(jobInsuranceInfo[type].approved_wc) === 1
        ) {
          insuranceStyle.color = 'green';
          insuranceStyle.title = 'COI Approved';
        }
      }
    }
  } else if (isExtraStop) {
    if (extraStop.required_coi === 1) {
      insuranceStyle.color = 'red';
      insuranceStyle.title = 'COI Required';
      // TODO add style depending on job insurance
    }
  }

  const renderElevator = useMemo(() => {
    if (elevatorExist) {
      if (elevator)
        return (
          <div className={`new-table--row--item no-side-shadows w15 active text-center min-58 no-margin`}>
            <Element className={`insurance-c ${insuranceStyle.color}`}>
              <Icon size={20} icon="from_elevator" title={elevatorStyle.title} />
            </Element>
            <div>
              <p>{elevatorReservation?.start}</p>
              <p>{elevatorReservation?.end}</p>
            </div>
          </div>
        );

      return (
        <div className={`new-table--row--item no-side-shadows w15 icon-red text-center min-58 no-margin`}>
          <Icon size={20} icon="from_elevator" title="Elevator reservation time required" />
        </div>
      );
    }

    return <div className={`new-table--row--item no-side-shadows w15 min-58`} />;
  }, [elevator, elevatorReservation, elevatorExist]);

  const renderStairs = useMemo(() => {
    if (stairs)
      return (
        <div className={`new-table--row--item no-side-shadows w5 active text-center min-58 no-margin`}>
          <Icon size={20} icon="estimated_stairs" title="Stairs" />
          <span style={{ marginLeft: 5 }}>{stairs}</span>
        </div>
      );

    return <div className={`new-table--row--item no-side-shadows w5 min-58`} />;
  }, [stairs]);

  return (
    <div onClick={onClick} className={`new-table--row waypoint ${extraStopClass}`} style={{ cursor: type && !isOffice ? 'context-menu' : 'default' }}>
      <div className="new-table--row--item w5 no-shadow ">
        {type || isOffice ? (
          <Icon className={classNames('', { 'icon-blue': isIntermediateStops })} size={22} icon="pin" />
        ) : (
          <Icon size={22} icon="pin2" className={classNames('', { 'icon-blue': isIntermediateStops })} />
        )}
      </div>
      <div className="new-table--row--item no-side-shadows w10 text-center">
        <p>{time || ''}</p>
      </div>
      <div className="new-table--row--item no-side-shadows w10 text-center">
        {travelDistance && (
          <p className="nowrap">
            <Icon icon="travel" title={travelTitle} />
            {travelDistance || ''}
          </p>
        )}
      </div>
      <div className="new-table--row--item no-side-shadows w40 text-center">
        <p>{address || ''}</p>
      </div>
      <div className="new-table--row--item no-side-shadows w30 text-center">
        <p>{buildingName || ''}</p>
      </div>
      {/* <div className="new-table--row--item no-side-shadows w10 text-center">
          <p>{zip}</p>
        </div> */}
      <div className="new-table--row--item no-side-shadows w15 text-center">
        <p>{name || ''}</p>
      </div>
      <div className="new-table--row--item no-side-shadows w5 text-center">
        {!isExtraStop && !isOffice && (
          <Element type="button" onClick={showPacking} className={classNames('', { active: packing?.length !== 0 })}>
            <Icon size={20} icon="packing" title={`Packing ${jobType ?? ''}`} />
          </Element>
        )}
      </div>
      <div className="new-table--row--item no-side-shadows w5 text-center">
        {!isOffice ? (
          <Element className={`insurance-c ${insuranceStyle.color}`} type="button" onClick={showInsurance}>
            <Icon size={20} icon="move_out_insurance" title={insuranceStyle.title} />
          </Element>
        ) : (
          ''
        )}
      </div>

      {renderElevator}
      {renderStairs}

      <div className="new-table--row--item no-side-shadows w5 text-center">
        {options && !isOffice ? (
          <DropdownMenu
            listStyle={{ right: 0 }}
            button={{
              iconName: 'put_calls',
              iconTitle: 'Client contact info',
              className: 'contact-info',
              iconClassName: 'tooltip-right',
              iconSize: 20,
            }}
            optionOnClick={() => 1}
            detailsInfo={options}
          />
        ) : (
          !isOffice && (
            <div>
              <button type="button" onClick={deleteExtrasStop}>
                <Icon icon="delete" title="Remove item" size={20} />
              </button>
            </div>
          )
        )}
      </div>
    </div>
  );
});

export default RoutesItem;

import React, { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { matchPath } from 'react-router';
import { routePaths } from 'config/routes';
import { useTabInfo } from 'hooks/query/useJob';
import { useDefault } from 'hooks/query/useCommon';
import ClaimCreateForm from './ClaimCreateForm';
import ClaimItem from './ClaimItem';

const Claims = (props) => {
  const { pathname } = useLocation();

  const {
    params: { id, tab },
  } = matchPath(pathname, { path: routePaths.JOBS });
  const [closed, setClosed] = useState([]);

  const {
    data: { claims, assigned_crew: assignedCrew },
  } = useTabInfo(id, tab);

  const {
    data: { users },
  } = useDefault();

  const preparedData = useMemo(() => {
    if (!claims) return [];
    const ids = [];

    const data = claims.reduce((acc, clime) => {
      const author = users.all.find((user) => user.id === clime.author_id);
      if (clime.closed) ids.push(clime.id);
      return acc.concat({ ...clime, author_id: author?.id || null });
    }, []);

    setClosed(ids);

    return data;
  }, [claims, users.all]);

  return (
    <div className="new-table modal">
      <ClaimCreateForm assignedCrew={assignedCrew} />
      <div className="new-table--row bcg-light">
        <div className="new-table--row--item w10 no-side-shadows justify-content-center">
          <b>Claim #</b>
        </div>
        <div className="new-table--row--item w15 no-side-shadows justify-content-center">
          <b>Description</b>
        </div>
        <div className="new-table--row--item w15 no-side-shadows justify-content-center">
          <b>Type</b>
        </div>
        <div className="new-table--row--item w15 no-side-shadows justify-content-center">
          <b>Date Entered</b>
        </div>
        <div className="new-table--row--item w15 no-side-shadows justify-content-center">
          <b>Due Date</b>
        </div>
        <div className="new-table--row--item w15 no-side-shadows justify-content-center">
          <b>Agent</b>
        </div>
        <div className="new-table--row--item w20 no-side-shadows justify-content-center">
          <b>Actions</b>
        </div>
      </div>
      {preparedData?.map((item, index) => (
        <ClaimItem claim={item} counter={index + 1} key={item.id} closed={closed} />
      ))}
    </div>
  );
};

export default Claims;

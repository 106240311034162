import { Component } from 'react';
import Swal from 'sweetalert2';
import { withRouter } from 'react-router';
const { io } = require('socket.io-client');

class WSNotification extends Component {
  componentDidMount() {
    const { SelectedJob, User, DefaultInfo } = this.props;

    window.socket = io('wss://ws2.imover.biz:8083', {
      reconnect: true,
      transports: ['websocket'],
    });

    window.socket.on('exit', (code) => {
      console.log('exit', code);
      io.close();
    });
    window.socket.on('close', (code) => {
      console.log('close', code);
      io.close();
    });
    window.socket.on('error', (code) => {
      console.log('error', code);
      io.close();
    });

    window.socket.on('invite_to_call', (data) => {
      if (Number(data.agent_id) === Number(User?.id)) {
        this.newNotification('Call invitation!');
        Swal.fire({
          position: 'top-end',
          title: `Call invitation`,
          text: 'From: ' + data.from,
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Join',
          cancelButtonText: 'Close',
          reverseButtons: true,
          backdrop: false,
        }).then((result) => {
          if (result.isConfirmed) {
            if (!document.getElementsByClassName('window-modal')[0].classList.contains('window-modal--visible')) {
              document.getElementsByClassName('window-modal')[0].classList.add('window-modal--visible');
            }
            document.getElementById('call-controls').style.display = 'block';
            document.getElementById('button-pickup').style.display = 'none';
            document.getElementById('incoming-title').style.display = 'none';
            document.getElementById('phone-number').value = data.conference_name;
            document.getElementById('button-call').click();
          }
        });
      }
    });

    window.socket.on('refresh-account', (data) => {
      console.log('refresh-account');
    });

    window.socket.on('new_lead', (data) => {
      this.newNotification('New Lead!', '/client/jobs/lead/' + data.job_id + '/general');
    });

    window.socket.on('job-booked', (data) => {
      if (Number(User?.id) === Number(data.agent_id)) {
        this.newNotification('You have new booked job!', '/client/jobs/new/' + data.job_id + '/general');
      }
    });

    window.socket.on('new_assigned_lead', (data) => {
      if (Number(User?.id) === Number(data.agent_id)) {
        this.newNotification('You have new follow up!!', '/client/jobs/followUp/' + data.job_id + '/general');
      }
    });

    window.socket.on('new_notification', (data) => {
      if (Number(User?.id) === Number(data.user_id) && data.message !== '') {
        if (data.job_id) {
          this.newNotification(data.message, '/client/jobs/all/' + data.job_id + '/general');
        }
      }
    });

    window.socket.on('crew-member-confirmation', (data) => {
      if (User?.manager && DefaultInfo?.workers_trucks) {
        let company_units = null;
        Object.entries(DefaultInfo?.workers_trucks).map((item) => {
          if (item[0] === data.company_id) company_units = item[1];
        });
        const crew_member = company_units[`${data.user_type}s`].find((unit) => Number(unit.id) === Number(data.user_id));
        this.newNotification(
          `${crew_member.name} ${crew_member.userlastname} has
          ${data.status} assignment to job ${data.job_title}`,
          '/client/jobs/all/' + data.job_id + '/exp'
        );
      }
    });

    window.socket.on('new_mail', (data) => {
      if (Number(User?.id) === Number(data.user_id)) {
        if (data.job_id && Number(data.job_id) !== 0) {
          this.newNotification('You have new mail!', '/client/jobs/all/' + data.job_id + '/general');
        } else {
          this.newNotification('You have new mail!', '/client/communications/inbox_email');
        }
      }
    });

    window.socket.on('missed_call', (data) => {
      if (Number(User?.id) === Number(data.user_id)) {
        if (data.job_id && Number(data.job_id) !== 0) {
          this.newNotification('You have missed call!', '/client/jobs/all/' + data.job_id + '/general');
        } else {
          this.newNotification('You have missed call!', '/client/communications/all_my_calls');
        }
      }
    });

    window.socket.on('update_sms', (data) => {
      if (Number(User?.id) === Number(data.user_id) || Number(data.user_id) === 0) {
        if (Number(data.job_id) !== 0) {
          this.newNotification('You have new sms!', '/client/jobs/all/' + data.job_id + '/general');
        } else {
          this.newNotification('You have new sms!', '/client/communications/all');
        }
      }
    });

    window.socket.on('inventory_update', (data) => {
      if (Number(User?.id) === Number(data.agent_id)) {
        this.newNotification(`Job ${data.title} Inventory updated`, '/client/jobs/all/' + data.job_id + '/general');
      }
    });

    window.socket.on('packing_update', (data) => {
      if (Number(User?.id) === Number(data.agent_id)) {
        this.newNotification(`Job ${data.title} Packing updated`, '/client/jobs/all/' + data.job_id + '/general');
      }
    });
  }

  componentDidUpdate(prevProps) {
    const { User, DefaultInfo } = this.props;
    if (prevProps.DefaultInfo !== DefaultInfo) {
      window.socket.on('crew-member-confirmation', (data) => {
        if (User?.manager && DefaultInfo?.workers_trucks) {
          let company_units = null;
          Object.entries(DefaultInfo?.workers_trucks).map((item) => {
            if (item[0] === data.company_id) company_units = item[1];
          });
          const crew_member = company_units[`${data.user_type}s`].find((unit) => Number(unit.id) === Number(data.user_id));
          this.newNotification(
            `${crew_member.name} ${crew_member.userlastname} has
          ${data.status} assignment to job ${data.job_title}`,
            '/client/jobs/all/' + data.job_id + '/exp'
          );
        }
      });
    }
  }

  newNotification = (text, url) => {
    if (!('Notification' in window)) {
      alert('This browser does not support desktop notification');
    } else if (Notification.permission === 'granted') {
      let message = new Notification(text);
      message.onclick = (e) => {
        e.preventDefault();
        window.open(window.location.origin + url, '_blank');
      };
    } else if (Notification.permission !== 'denied') {
      Notification.requestPermission().then((permission) => {
        if (!('permission' in Notification)) {
          Notification.permission = permission;
        }
        if (permission === 'granted') {
          let message = new Notification(text);
          message.onclick = (e) => {
            e.preventDefault();
            window.open(window.location.origin + url, '_blank');
          };
        }
      });
    }
  };
  render() {
    return null;
  }
}

export default withRouter(WSNotification);
